import { GridColDef } from '@mui/x-data-grid-pro';

export const CONTACTS_STATIC_COLUMN_NAMES = {
  NAME: 'name',
  FAVORITE: 'favorite',
  CONTACT: 'contact',
  BIO: 'bio',
  ASSOCIATED_CHATS: 'associated_chats',
  NOTES: 'notes',
  EMPTY: 'empty',
};

export const STATIC_COLUMNS: GridColDef[] = [
  {
    field: CONTACTS_STATIC_COLUMN_NAMES.EMPTY,
    headerName: '',
    width: 16,
    maxWidth: 16,
    minWidth: 16,
    sortable: false,
    headerClassName: 'no-right-border',
  },
  {
    field: CONTACTS_STATIC_COLUMN_NAMES.CONTACT,
    headerName: 'Contact',
    width: 260,
    sortable: false,
  },
  {
    field: CONTACTS_STATIC_COLUMN_NAMES.ASSOCIATED_CHATS,
    headerName: 'Chats',
    width: 260,
    sortable: false,
  },
  {
    field: CONTACTS_STATIC_COLUMN_NAMES.BIO,
    headerName: 'Bio',
    width: 260,
    sortable: false,
  },
  {
    field: CONTACTS_STATIC_COLUMN_NAMES.NOTES,
    headerName: 'Note',
    sortable: false,
    width: 300,
    minWidth: 300,
    flex: 1,
  },
];
