import { SnackBarVariantEnum } from 'components/SnackBar/SnackBar';

import { useDispatch, useSelector } from 'react-redux';
import { useUpdateUserMutation } from 'services/models/api/generated';
import { authState, setUser } from 'services/store/slices/authSlice';
import AnalyticKeys, { trackAction } from 'services/utils/analytics';
import useAuth from './useAuth';
import Translations from 'const/translations/en';
import Icons from 'assets/icons';
import { UserDetails } from 'services/models/domain/user';
import { RootState } from 'services/store/AppStore';

export const useUserDetails = () => {
  const { user } = useSelector(authState);
  const dispatch = useDispatch();
  const [updateUser] = useUpdateUserMutation();

  const updateUserProfile = async (
    updatedUserDetails: UserDetails,
    handleSnackBar?: (title: string, variant: SnackBarVariantEnum, icon: string) => void
  ) => {
    await updateUser({
      variables: {
        input: {
          fullName: updatedUserDetails?.fullName ?? '',
          name: updatedUserDetails?.name ?? '',
          firstName: updatedUserDetails?.firstName ?? '',
          lastName: updatedUserDetails?.lastName ?? '',
          profileImageUrl: updatedUserDetails.profileImageUrl ?? '',
        },
      },
    }).then((response) => {
      if (response.data?.updateUser?.status.isSuccess) {
        handleSnackBar && handleSnackBar(Translations.SUCCESS, SnackBarVariantEnum.success, Icons.ic_confetti);
        if (user?.actingAsMemberOfOrganization) {
          const updatedUser = {
            ...user,
            fullName: updatedUserDetails?.fullName ?? '',
            name: updatedUserDetails?.name ?? '',
            firstName: updatedUserDetails?.firstName ?? '',
            lastName: updatedUserDetails?.lastName ?? '',
            profileImageUrl: updatedUserDetails.profileImageUrl ?? '',
            actingAsMemberOfOrganization: {
              ...user?.actingAsMemberOfOrganization,
              listOfUsersInOrganization: user?.actingAsMemberOfOrganization.listOfUsersInOrganization.map((u) => {
                if (u.oid === user?.oid) {
                  return {
                    ...u,
                    fullName: updatedUserDetails?.fullName ?? '',
                    name: updatedUserDetails?.name ?? '',
                    firstName: updatedUserDetails?.firstName ?? '',
                    lastName: updatedUserDetails?.lastName ?? '',
                    profileImageUrl: updatedUserDetails.profileImageUrl ?? '',
                  };
                }
                return u;
              }),
            },
          };
          dispatch(
            setUser({
              user: updatedUser,
              isFirstTime: false,
              isNameStepCompleted: true,
              isInviteStepCompleted: true,
            })
          );
          trackAction(AnalyticKeys.EDITED_PROFILE, {
            organizationName: user?.actingAsMemberOfOrganization.name,
            organizationId: user?.actingAsMemberOfOrganization.oid,
          });
        }
      } else {
        handleSnackBar &&
          handleSnackBar(Translations.UPLOAD_ERROR, SnackBarVariantEnum.error, Icons.ic_warning_snackbar);
      }
    });
  };

  return {
    updateUserProfile,
  };
};
