import { ReactComponent as PlusIcon } from 'assets/icons/nav/ic_plus.svg';
import { IconButton } from '@mui/joy';
import CellMenu from 'components/ui-library/chat/CellMenu/CellMenu';
import { useState } from 'react';
import { colors } from 'theme/colors';

import ColumnVisibilityControlSelect from './ColumnVisibilityControlSelect';
import ColumnVisibilityControlCreate from './ColumnVisibilityControlCreate';
import { EntityPropertyDefinition, PropertyDefinitionCategoryOption } from 'services/models/domain/entityViews';
import { usePropertyDefinitions } from 'services/hooks/usePropertyDefinitions';
import ColumnVisibilityControlEdit from './ColumnVisibilityControlEdit';
import DeletePropertyModal from 'page/modal/DeletePropertyModal';
import { SxProps } from '@mui/joy/styles/types';

enum VisibilityControlState {
  Select = 'SELECT',
  Create = 'CREATE',
  Edit = 'EDIT',
}

interface ColumnVisibilityControlProps {
  sx?: SxProps;
}

export default function ColumnVisibilityControl(props: ColumnVisibilityControlProps) {
  const { sx } = props;
  const [navigationState, setNavigationState] = useState<VisibilityControlState>(VisibilityControlState.Select);
  const { createPropertyDefinition, updatePropertyDefinition, deletePropertyDefinition } = usePropertyDefinitions();
  const [selectedPropertyDefinition, setSelectedPropertyDefinition] = useState<EntityPropertyDefinition | null>(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setNavigationState(VisibilityControlState.Select);
  };

  const cleanSelectPropertDefintion = () => {
    setSelectedPropertyDefinition(null);
  };

  const onCreatePropertyDefintion = (name: string, category: PropertyDefinitionCategoryOption) => {
    createPropertyDefinition(name, category);
    handleClose();
  };

  const onEditPropertyDefintion = (oid: string, name: string) => {
    updatePropertyDefinition(oid, name);
    cleanSelectPropertDefintion();
    setNavigationState(VisibilityControlState.Select);
  };

  const onDeletePropertyDefinition = (oid: string) => {
    deletePropertyDefinition(oid);
  };

  const onCloseModal = () => {
    setDeleteModalOpen(false);
    cleanSelectPropertDefintion();
  };

  const onDeletePropertyModal = () => {
    if (!!selectedPropertyDefinition) {
      onDeletePropertyDefinition(selectedPropertyDefinition.oid.oid);
    } else {
      console.error('Attempt to delete property! No property was selected!');
    }
  };

  const navigate = () => {
    switch (navigationState) {
      case VisibilityControlState.Select:
        return (
          <ColumnVisibilityControlSelect
            handleClose={handleClose}
            navigateNext={() => setNavigationState(VisibilityControlState.Create)}
            onDeletePropertyDefinition={(pd) => {
              setSelectedPropertyDefinition(pd);
              setDeleteModalOpen(true);
            }}
            onEditPropertyDefinition={(pd) => {
              setSelectedPropertyDefinition(pd);
              setNavigationState(VisibilityControlState.Edit);
            }}
          />
        );
      case VisibilityControlState.Create:
        return (
          <ColumnVisibilityControlCreate
            navigateBack={() => setNavigationState(VisibilityControlState.Select)}
            onCreatePropertyDefinition={onCreatePropertyDefintion}
          />
        );
      case VisibilityControlState.Edit:
        return (
          selectedPropertyDefinition && (
            <ColumnVisibilityControlEdit
              navigateBack={() => {
                cleanSelectPropertDefintion();
                setNavigationState(VisibilityControlState.Select);
              }}
              initialName={selectedPropertyDefinition.name}
              propertyDefinitionOid={selectedPropertyDefinition.oid.oid}
              onEditPropertyDefinition={onEditPropertyDefintion}
            />
          )
        );
    }
  };

  return (
    <>
      <CellMenu
        anchorComponent={
          <IconButton sx={styles.plusIconButton} onClick={handleClick}>
            <PlusIcon width={16} height={16} />
          </IconButton>
        }
        anchorSx={sx}
        sx={styles.menuContainer(navigationState)}
        open={open}
        onClose={handleClose}
      >
        {navigate()}
      </CellMenu>
      <DeletePropertyModal isOpen={isDeleteModalOpen} onClose={onCloseModal} onDelete={onDeletePropertyModal} />
    </>
  );
}

const styles = {
  menuContainer: (navigationState: VisibilityControlState) => ({
    '& .MuiPaper-rounded': {
      borderRadius: '8px',
      width: '190px',
      maxHeight: '90vh',
      overflow: 'hidden',
    },
    '& .MuiMenu-list': {
      padding: '0',
      height: '100%',
      overflow: 'hidden',
    },
  }),
  plusIconButton: {
    backgroundColor: 'transparent',
    '&:focus-visible': {
      outline: 'none',
    },
    '&:hover': { backgroundColor: 'transparent' },
    '&:active': { backgroundColor: 'transparent' },
  },
};
