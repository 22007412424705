import { Box, Button, Grid, Sheet, Stack, Typography } from '@mui/joy';
import { Modal } from '@mui/material';
import Icons from 'assets/icons';
import { SnackBarVariantEnum } from 'components/SnackBar/SnackBar';
import Translations from 'const/translations/en';
import AvatarCropper from 'page/modal/AvatarCrop';
import ProfileDetails from 'page/modal/ProfileDetails';
import styles, { ModalStyle } from 'page/modal/styles';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSnackbarContext from 'services/hooks/useSnackbar';
import { useUserDetails } from 'services/hooks/useUserDetails';
import { useUpdateUserMutation } from 'services/models/api/generated';
import { UserDetails } from 'services/models/domain/user';
import { authState } from 'services/store/slices/authSlice';

export enum UploadPage {
  main,
  cropImage,
}

interface Props {
  isOpen: boolean;
  setIsOpen: Function;
}

const ProfileModal = ({ isOpen, setIsOpen }: Props) => {
  const { user: userDetails } = useSelector(authState);
  const { updateUserProfile } = useUserDetails();
  const [avatarUrl, setAvatarUrl] = useState<any | undefined>('');
  const [previousAvatar, setPreviousAvatar] = useState<any | undefined>(undefined);
  const [page, setPage] = useState<UploadPage>(UploadPage.main);
  const [actionMessage, setActionMessage] = useState<string | undefined>(undefined);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [updateUser] = useUpdateUserMutation();
  const { setSnackbarOpenStatus } = useSnackbarContext();

  const handleSnackBar = (
    message: string,
    variant: SnackBarVariantEnum,
    icon: string,
    subtext?: string,
    actionMessage?: string,
    handleAction?: () => void
  ) => {
    setSnackbarOpenStatus(true, message, variant, icon, subtext);
    setTimeout(() => {
      closeSnackBar();
    }, 5000);
  };

  const closeSnackBar = () => {
    setSnackbarOpenStatus(false, '', SnackBarVariantEnum.info);
    setActionMessage(undefined);
  };

  useEffect(() => {
    setPage(UploadPage.main);
    if (userDetails?.profileImageUrl || userDetails?.profileImageUrl != '') {
      setPreviousAvatar(userDetails?.profileImageUrl);
    }
  }, [userDetails]);

  const snackBarHandleActions = async () => {
    if (actionMessage === 'Discard changes' && unsavedChanges) {
      setIsOpen(false);
      setUnsavedChanges(false);
      closeSnackBar();
    }
    if (actionMessage === 'Undo') {
      await updateUserProfile({ ...userDetails, profileImageUrl: previousAvatar } as UserDetails, handleSnackBar);
      closeSnackBar();
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={(event, reason) => {
          setIsOpen(false);
        }}
        sx={ModalStyle}
      >
        <Sheet variant="plain" sx={styles.profileModalSheet}>
          <Box sx={styles.profileModalBox}>
            {page === UploadPage.main && (
              <Stack direction="row" sx={styles.profileModalStack}>
                <Typography id="modal-title" level="large" textColor="inherit" sx={styles.profileTypography}>
                  Profile
                </Typography>
                <Button
                  onClick={() => {
                    if (unsavedChanges) {
                      setActionMessage('Discard changes');
                      handleSnackBar(
                        Translations.UNSAVED_CHANGES,
                        SnackBarVariantEnum.error,
                        Icons.ic_warning_snackbar,
                        undefined,
                        'Discard changes',
                        snackBarHandleActions
                      );
                    } else {
                      setIsOpen(false);
                    }
                  }}
                  sx={styles.closeButton}
                >
                  <img src={Icons.ic_close_broad} alt="" width={12} height={12} />
                </Button>
              </Stack>
            )}
            {page === UploadPage.cropImage && (
              <Stack direction="row" sx={styles.profileModalStack}>
                <Button onClick={() => setPage(UploadPage.main)} sx={styles.backButton}>
                  <img src={Icons.ic_chevron_left} alt="" width={13} height={13} />
                </Button>
                <Typography id="modal-title" level="large" sx={styles.profileTypography}>
                  Crop Avatar
                </Typography>

                <Button
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  sx={{ ...styles.closeButton, right: '14px' }}
                >
                  <img src={Icons.ic_close_broad} alt="" width={12} height={12} />
                </Button>
              </Stack>
            )}
          </Box>

          <Grid container sx={{ boxShadow: 0, border: '0px', width: '99%' }}>
            {page === UploadPage.main && (
              <Grid xs={12} md={12} lg={12} gap={2}>
                <ProfileDetails
                  user={userDetails}
                  avatar={userDetails?.profileImageUrl}
                  updateUserProfile={async (updatedUserDetails: UserDetails) => {
                    await updateUserProfile({ ...updatedUserDetails });
                    setUnsavedChanges(false);
                    setIsOpen(false);
                  }}
                  updateAvatarUrl={(avatar: any) => {
                    setAvatarUrl(avatar);
                    setPage(UploadPage.cropImage);
                  }}
                  onDelete={async () => {
                    await updateUserProfile(
                      { ...userDetails, profileImageUrl: undefined } as UserDetails,
                      handleSnackBar
                    );
                    setActionMessage('Undo');
                    handleSnackBar(
                      Translations.IMAGE_DELETED,
                      SnackBarVariantEnum.error,
                      Icons.ic_warning_snackbar,
                      undefined,
                      'Undo',
                      snackBarHandleActions
                    );
                  }}
                  setUnsavedChanges={setUnsavedChanges}
                />
              </Grid>
            )}
            {page === UploadPage.cropImage && avatarUrl && (
              <Grid xs={12} md={12} lg={12} gap={2}>
                <AvatarCropper
                  userOid={userDetails!.oid}
                  imgSrc={avatarUrl}
                  onSave={async (avatarUrl: string) => {
                    setPreviousAvatar(avatarUrl);
                    await updateUserProfile(
                      { ...userDetails, profileImageUrl: avatarUrl } as UserDetails,
                      handleSnackBar
                    );
                    setPage(UploadPage.main);
                  }}
                  onCancel={() => {
                    setPage(UploadPage.main);
                  }}
                  onError={() => {
                    handleSnackBar(Translations.UPLOAD_ERROR, SnackBarVariantEnum.error, Icons.ic_warning_snackbar);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Sheet>
      </Modal>
    </>
  );
};

export default ProfileModal;
