import React, { createContext, useContext, useEffect, useReducer } from 'react';
import store from 'services/store/AppStore';
import { setUserStatus } from 'services/store/slices/userTgSlice';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AppContext } from 'services/store/AppContext';
import { TgSyncStatus } from 'page/modal/TgConnectModal/interface';
import { authState } from 'services/store/slices/authSlice';

export type TgSyncState = {
  tgSynced: boolean;
  loading: boolean;
};

const initialState: TgSyncState = {
  tgSynced: false,
  loading: false,
};

const HANDLERS = {
  TG_SYNC: 'TG_SYNC',
  TG_SYNC_LOADING: 'TG_SYNC_LOADING',
} as const;

type ActionPayload = {
  [key in keyof TgSyncState]: TgSyncState[key];
};

type Action = {
  type: keyof typeof HANDLERS;
  payload: ActionPayload;
};

export const reducer = (state: TgSyncState, action: Action) => {
  const { tgSynced, loading } = action.payload;

  switch (action.type) {
    case HANDLERS.TG_SYNC:
      return {
        ...state,
        tgSynced,
      };
    case HANDLERS.TG_SYNC_LOADING:
      return {
        ...state,
        loading,
      };
    default:
      return state;
  }
};

const OnboardingContext = createContext({
  ...initialState,
});

interface Props {
  children: React.ReactNode;
}

const OnboardingProvider = ({ children }: Props) => {
  const { isAuthenticated } = useSelector(authState);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { tgClient } = useContext(AppContext);
  const { clientSync } = useFlags();

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch({
        type: HANDLERS.TG_SYNC,
        payload: {
          ...initialState,
        },
      });
    }
  }, [!isAuthenticated]);

  useEffect(() => {
    async function fetchTgConnectionStatusClientSide() {
      const isUserAuthorized = await tgClient.isAuthorized();
      if (isUserAuthorized) {
        store.dispatch(setUserStatus(TgSyncStatus.CONNECTED));
      }
    }
    if (isAuthenticated && clientSync) {
      fetchTgConnectionStatusClientSide();
    }
  }, [isAuthenticated]);

  return <OnboardingContext.Provider value={state}>{children}</OnboardingContext.Provider>;
};

export { OnboardingContext, OnboardingProvider };
