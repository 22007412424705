import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EntityFilterItem } from 'services/models/domain/entityFilter';
import { RootState } from 'services/store/AppStore';

export type EntityFilterState = {
  filterItems: EntityFilterItem[];
};

const initialState: EntityFilterState = {
  filterItems: [],
};

const entityFilterSlice = createSlice({
  name: 'entityFilter',
  initialState,
  reducers: {
    addEntityFilter: (state, action: PayloadAction<{ filterItem: EntityFilterItem }>) => {
      const itemExists = state.filterItems.find(
        (item) => item.filter.uniqueId === action.payload.filterItem.filter.uniqueId
      );
      if (!itemExists) {
        state.filterItems = [...state.filterItems, action.payload.filterItem];
      } else {
        state.filterItems = state.filterItems.map((item) => {
          if (item.id === action.payload.filterItem.id) {
            return action.payload.filterItem;
          } else {
            return item;
          }
        });
      }
    },
    setEntityFilters: (state, action: PayloadAction<{ filterItems: EntityFilterItem[] }>) => {
      state.filterItems = action.payload.filterItems;
    },
    removeEntityFilter: (state, action: PayloadAction<{ filterUniqueId: string }>) => {
      const filteredItems = state.filterItems.filter((f) => f.filter.uniqueId !== action.payload.filterUniqueId);
      state.filterItems = filteredItems;
    },
    clearEntityFilters: (state) => {
      state.filterItems = [];
    },
  },
});

export const entityFilter = (state: RootState) => state.entityFilter;

export const { addEntityFilter, removeEntityFilter, clearEntityFilters, setEntityFilters } = entityFilterSlice.actions;

export const EntityFilterReducer = entityFilterSlice.reducer;
