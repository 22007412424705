import { Box } from '@mui/joy';
import { styled, SxProps } from '@mui/material';
import { colors } from 'theme/colors';

export const MenuSx: SxProps = {};

export const FlexBoxStyles: SxProps = {
  display: 'flex',
  gap: '6px',
  alignItems: 'center',
  height: '24px',
  padding: '0px 8px',
  width: '100%',
  borderRadius: '4px',
  '&:hover': {
    '& svg': {
      stroke: colors.primary[50],
    },
    color: colors.primary[50],
    background: colors.primary[0],
  },
};

export const TextStyles: SxProps = {
  color: 'palette.text.secondary',
  lineHeight: '16px',
  letterSpacing: '-0.005em',
};

export const TableHeaderStyles: SxProps = {
  width: '100%',
  padding: '0px 16px',
};

export const TableHeaderButton = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ isActive }: { isActive: boolean }) => ({
  width: '100%',
  padding: '0px 16px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: isActive ? colors.primary[50] : colors.neutral[50],
  '& svg': {
    stroke: isActive ? colors.primary[50] : colors.neutral[50],
  },
  '&:hover': {
    color: isActive ? colors.primary[60] : colors.neutral[60],
    '& svg': {
      stroke: isActive ? colors.primary[60] : colors.neutral[60],
    },
    backgroundColor: colors.cellHoverBg,
  },
}));

export const DividerSx: SxProps = {
  '--Divider-lineColor': colors.neutral[5],
  backgroundColor: colors.neutral[5],
};
