import { GridCellProps, GridCell } from '@mui/x-data-grid-pro';

import NameCellContent from 'components/Chats/ChatTableV2/TableCell/customCells/cellsContent/Name/NameCellContent';

export default function NameCell(props: GridCellProps) {
  const { value, ...rest } = props;

  return (
    <GridCell {...rest}>
      <NameCellContent
        id={value.id}
        name={value.name}
        participants={value.participants}
        link={value.link}
        type={value.conversations?.conversationType}
        onClick={value.onClick}
        channelType={value.channelType}
        onParticipantLinkClicked={value.onParticipantLinkClicked}
        origin={value.origin}
      />
    </GridCell>
  );
}
