import { tabClasses } from '@mui/joy/Tab';
import { colors } from 'theme/colors';
import IconButton from '@mui/joy/IconButton';
import { styled } from '@mui/joy';
import { alpha } from '@mui/material';
import { fonts } from 'theme/fonts';

export const headerStyles = {
  verticalDivider: {
    backgroundColor: colors.neutral['10'],
    alignSelf: 'stretch',
    height: '64px',
    marginTop: ' -15px',
    marginLeft: '10px',
  },
  headerBox: {
    px: { xs: 2, md: 4 },
    py: 2,
    gap: 2,
    bgcolor: 'background.componentBg',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.neutral[10]}`,
    position: 'sticky',
    top: 0,
    zIndex: 1000,
  },
  headerItemsLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: 'transparent',
    height: '32px',
  },
  headerItemsRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  button: {
    ml: '5px',
    fontSize: '14px',
    marginRight: '5px',
    lineHeight: `14px`,
  },
  divider: {
    backgroundColor: colors.neutral[10],
    margin: '2px -8px 2px -8px',
  },
  popOver: {
    border: 'none',
    minWidth: 255,
    borderRadius: '8px',
    overflow: 'visible',
    marginTop: '40px',
  },
  newTagBox: {
    display: 'flex',
    padding: '2px 6px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    boxShadow: 'none',
    borderRadius: '100px',
    background: 'var(--primary-50, #3C7ADE)',
  },
  newTagText: {
    color: 'var(--utility-white, #FFF)',
    fontFamily: fonts.regular,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '-0.06px',
  },
  backdrop: {
    color: alpha(colors.neutral[100], 0.2),
    zIndex: (theme: { zIndex: { drawer: number } }) => theme.zIndex.drawer + 1,
    opacity: '0.5',
  },
  tabView: { ml: '5vh', display: 'flex', boxShadow: 'none' },
  tabList: {
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '2.25px',
    gap: '8px',
    border: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    [`& .${tabClasses.root}`]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: `${colors.neutral[50]}`, // not selected tab
      backgroundColor: 'transparent',
      [`&.${tabClasses.selected}`]: {
        color: `${colors.neutral[80]}`, // selected tab
        position: 'relative',
        border: 'none',
        borderRadius: '0px',
        '&::after': {
          content: '""',
          position: 'absolute',
          left: '5px',
          width: `calc(100% - 8px)`,
          bottom: '-18px',
          borderBottom: '1px solid',
        },
      },
    },
  },
  tabListHorizontal: {
    display: 'flex',
    ml: 0,
    width: '100%',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    background: 'transparent',
    boxShadow: 'none',
    padding: '0px 0px',
    alignItems: 'flex-start',
    border: 'none',
    gap: '16px',
    alignSelf: 'stretch',
    [`& .${tabClasses.root}`]: {
      boxShadow: 'none',
      color: `${colors.neutral[80]}`,
      backgroundColor: 'transparent',
      [`&.${tabClasses.selected}`]: {
        position: 'relative',
        border: 'none',
        borderRadius: '0px',
        '&::after': {
          content: '""',
          position: 'absolute',
          left: 0,
          bottom: '-20px',
          width: '100%',
        },
      },
    },
  },
};

export const LinkButton = styled(IconButton)(({ status }: { status: string }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: '0px',
  padding: '0px',
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  outline: 'none',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    color: `${colors.neutral[80]}`, // selected tab
  },
}));

export const BatchMessageButtonStyle = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: '0px',
  padding: '0px',
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  outline: 'none',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    '& .hoverable-content': {
      color: `${colors.neutral[80]}`,
    },
    '& .hoverable-icon': {
      filter: 'brightness(0.5)',
    },
  },
}));

export const StyledIconButton = styled(IconButton)(({ status }: { status: string }) => {
  let boxShadow;

  if (status === 'Telegram Disconnected') {
    boxShadow = `1px 1px 6px 0px #FF000024, 
               -1px -1px 6px 0px #FF000033,
               -1px 1px 6px 0px #FF000033,
               1px -1px 1px 0px #0145DA1A`; // red shadows
  } else if (status === 'Connect Telegram' || status === 'Sync Folders') {
    boxShadow = `4px 4px 12px 0px #013BDA24,
              -4px -4px 12px 0px #01EAE033,
              -4px 4px 12px 0px #01DBE033,
              4px -4px 4px 0px #0145DA1A`; // blue shadows
  } else {
    boxShadow = 'none';
  }

  return {
    cursor: 'pointer',
    justifyContent: 'center',
    alignContent: 'end',
    background:
      status === 'Telegram Disconnected' ? `${colors.trash.background}` : 'var(--joy-palette-primary-solidBg)',
    border:
      status === 'Telegram Disconnected'
        ? `1px solid ${colors.trash.border}`
        : '1px solid var(--joy-palette-primary-outlinedBorder)',
    padding: '7px 7px 7px 7px',
    bottom: '1px',
    right: '2vh',
    boxShadow: boxShadow,
  };
});
