import { GridCellProps, GridCell } from '@mui/x-data-grid-pro';
import AssociatedConversationCell from 'components/TableCells/AssociatedConversations/AssociatedConversationsCell';

export default function AssociatedChatsCell(props: GridCellProps) {
  const { value, ...rest } = props;
  return (
    <GridCell {...rest}>
      <AssociatedConversationCell associatedConversations={value.associatedConversations} />
    </GridCell>
  );
}
