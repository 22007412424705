import { Box, Checkbox, Divider, RadioGroup, Typography } from '@mui/joy';
import { IconsSvg } from 'assets/icons';
import { CheckboxSx, NameSx, OptionSx, UserBoxSx } from 'components/Chats/Filters/PropertyFilter/styles';
import CustomAvatar from 'components/CustomAvatar';
import CellMenuOption from 'components/ui-library/chat/CellMenu/CellMenuOption/CellMenuOption';
import Translations from 'const/translations/en';
import _ from 'lodash';
import { ApplyFilterButton } from 'page/chats/ChatTableFilterBar/FilterOptions/ApplyFilterButton';
import { NoOptionFilterMenu } from 'page/chats/ChatTableFilterBar/FilterOptions/NoOptionFilterMenu';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FilterOption } from 'services/models/domain/entityFilter';
import { DateRangeFilterValue } from 'services/models/domain/entityViews';
import { OrganizationalUser } from 'services/models/domain/user';
import { authState } from 'services/store/slices/authSlice';
import { generateDateRangeFilterValue } from 'services/utils/chatTableFilterUtils';
import { generateColorFromOid } from 'services/utils/helpers';
import { getUsersInitials } from 'services/utils/userPropertyUtils';
import { colors } from 'theme/colors';

export interface FollowupFilterMenuProps {
  filterOption: FilterOption;
  selectedFilterOption?: FilterOption;
  onAssigneesChanged: (userOids: string[], noFollowupAssigned: boolean) => void;
  onDateRangeChanged: (oid: string, from?: Date, to?: Date) => void;
  onClose: () => void;
}

export const FollowupFilterMenu = ({
  selectedFilterOption,
  filterOption,
  onAssigneesChanged,
  onDateRangeChanged,
  onClose,
}: FollowupFilterMenuProps) => {
  const [noFollowupAssigned, setNoFollowUpAssigned] = useState(false);
  const [selectedDateRangeId, setSelectedDateRangeId] = useState<string>('1');
  const [selectedUserOids, setSelectedUserOids] = useState<string[]>([]);
  const [initialValue, setInitialValue] = useState<{
    assignees: string[];
    dateRangeId: string;
    noFollowupAssigned: boolean;
  }>({ assignees: [], dateRangeId: '1', noFollowupAssigned: false });
  const dateRangeFilterValues: DateRangeFilterValue[] = generateDateRangeFilterValue();
  const { user } = useSelector(authState);
  const members = user?.actingAsMemberOfOrganization.listOfUsersInOrganization;

  useEffect(() => {
    let assignees: string[] = [];
    let dateRangeId: string = '1';
    let noFollowupAssigned: boolean = false;

    if (selectedFilterOption && 'value' in selectedFilterOption && 'noFollowupAssigned' in selectedFilterOption.value) {
      noFollowupAssigned = selectedFilterOption.value.noFollowupAssigned;
      setNoFollowUpAssigned(selectedFilterOption.value.noFollowupAssigned);
      if (selectedFilterOption.value.dateRangeId === undefined) {
        dateRangeId = '1';
        setSelectedDateRangeId('1');
        onSelectDateRange(dateRangeFilterValues[0]);
      } else {
        dateRangeId = selectedFilterOption.value.dateRangeId;
        setSelectedDateRangeId(selectedFilterOption.value.dateRangeId);
      }
      if ('followUpsAssignedTo' in selectedFilterOption.value) {
        assignees = selectedFilterOption.value.followUpsAssignedTo;
        setSelectedUserOids(selectedFilterOption.value.followUpsAssignedTo);
      }
    }
    setInitialValue({ assignees, dateRangeId, noFollowupAssigned });
  }, []);

  useEffect(() => {
    if (selectedFilterOption && 'value' in selectedFilterOption && 'noFollowupAssigned' in selectedFilterOption.value) {
      setNoFollowUpAssigned(selectedFilterOption.value.noFollowupAssigned);
      if (selectedFilterOption.value.dateRangeId === undefined) {
        setSelectedDateRangeId('1');
        onSelectDateRange(dateRangeFilterValues[0]);
      } else {
        setSelectedDateRangeId(selectedFilterOption.value.dateRangeId);
      }
      if ('followUpsAssignedTo' in selectedFilterOption.value) {
        setSelectedUserOids(selectedFilterOption.value.followUpsAssignedTo);
      }
    }
  }, [selectedFilterOption]);

  const compareValues = () => {
    return !_.isEqual(initialValue, {
      assignees: selectedUserOids,
      dateRangeId: selectedDateRangeId,
      noFollowupAssigned: noFollowupAssigned,
    });
  };
  const onSelectDateRange = (dateRange: DateRangeFilterValue) => {
    const from = dateRange.from ? new Date(dateRange.from) : undefined;
    const to = dateRange.to ? new Date(dateRange.to) : undefined;
    setSelectedDateRangeId(dateRange.id);
    onDateRangeChanged(dateRange.id, from, to);
  };

  const handleOnClick = (userOid: string) => {
    let updatedUserOids = [];
    if (selectedUserOids.includes(userOid)) {
      updatedUserOids = selectedUserOids.filter((oid) => oid !== userOid);
    } else {
      updatedUserOids = [...selectedUserOids, userOid];
    }
    setSelectedUserOids(updatedUserOids);
    onAssigneesChanged(updatedUserOids, false);
  };

  const renderCheckList = (user: OrganizationalUser) => {
    const { oid, firstName, fullName, name, profileImageUrl } = user;
    const userInitials = getUsersInitials(name, fullName);
    const isChecked = selectedUserOids.includes(oid);
    return (
      <>
        {(firstName || fullName) && (
          <CellMenuOption key={oid} sx={OptionSx} onClick={() => handleOnClick(oid)}>
            <Box sx={UserBoxSx}>
              <CustomAvatar
                index={generateColorFromOid(oid)}
                size={16}
                userInitials={userInitials}
                imgUrl={profileImageUrl}
              />
              <Typography level="tiny" sx={NameSx}>
                {fullName ?? firstName}
              </Typography>
            </Box>
            <Checkbox sx={CheckboxSx} checked={isChecked} />
          </CellMenuOption>
        )}
      </>
    );
  };

  const renderDateRangeFilter = (dateRange: DateRangeFilterValue) => {
    const { id, color, icon, value } = dateRange;
    const Icon = IconsSvg[icon as keyof typeof IconsSvg];
    const isChecked = dateRange.id === selectedDateRangeId;

    return (
      <CellMenuOption key={id} sx={OptionSx} onClick={() => onSelectDateRange(dateRange)} disabled={false}>
        <Box sx={UserBoxSx}>
          <Icon width={14} height={14} stroke={color} style={{ marginTop: '1px', marginLeft: '1px' }} />
          <Typography level="tiny" sx={NameSx}>
            {value}
          </Typography>
        </Box>
        <Checkbox sx={CheckboxSx} checked={isChecked} />
      </CellMenuOption>
    );
  };

  return (
    <>
      <Box sx={{ marginX: '5px' }}>
        <Box sx={style.menuHeader}>
          <Typography level="tiny" sx={{ color: colors.neutral[50] }} onClick={() => {}}>
            {filterOption.title}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ border: '1.3px dashed #fff', background: 'var(--joy-palette-info-plainBorder)' }} />
      <RadioGroup name="platform" sx={{ marginX: '5px' }}>
        {dateRangeFilterValues.map((value: DateRangeFilterValue) => {
          return renderDateRangeFilter(value);
        })}
      </RadioGroup>
      <Divider sx={{ border: '1.3px dashed #fff', background: 'var(--joy-palette-info-plainBorder)' }} />
      <Box sx={{ marginX: '5px' }}>
        <NoOptionFilterMenu
          title={Translations.NO_FOLLOWUPS_SET}
          selected={noFollowupAssigned}
          onChange={(value) => {
            setNoFollowUpAssigned(value);
            onAssigneesChanged([], value);
          }}
        />
      </Box>
      <Divider sx={{ border: '1.3px dashed #fff', background: 'var(--joy-palette-info-plainBorder)' }} />
      <Box sx={{ marginX: '5px' }}>{members?.map(renderCheckList)}</Box>
      {compareValues() && <ApplyFilterButton onClick={onClose} sx={{ width: 'calc(100% - 12px)', margin: '6px' }} />}
    </>
  );
};

const style = {
  menuHeader: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '8px',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
};
