import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ToggleButtonActiveSx, ToggleButtonInactiveSx } from 'page/chats/ChatsTableFilter/styles';
import { PageView } from 'page/chats/interface';
import { commandbarTrackerEvents } from 'services/commandbar/trackEvents';
import { FeatureFlags } from 'services/models/domain/featureFlags';
import { colors } from 'theme/colors';
import { ReactComponent as TableViewIcon } from 'assets/icons/ic_table_view.svg';
import { ReactComponent as KanbanViewIcon } from 'assets/icons/ic_kanban_view.svg';
import { Typography } from '@mui/joy';
import Translations from 'const/translations/en';

export interface PageViewToggleProps {
  currentPageView: PageView;
  onPageViewChanged: (pageView: PageView) => void;
}

export const PageViewToggle = (props: PageViewToggleProps) => {
  const flags = useFlags<FeatureFlags>();

  return (
    <ToggleButtonGroup
      value={props.currentPageView}
      exclusive
      onChange={(_event, value) => {
        if (flags.commandbar && value === PageView.Kanban) commandbarTrackerEvents.pipelineButtonToggle();
        props.onPageViewChanged(value);
      }}
      aria-label="text alignment"
      size="small"
      sx={styles.toggleButtonGroup}
    >
      <ToggleButton
        id="table-toggle-btn"
        value={PageView.Table}
        aria-label="left aligned"
        sx={props.currentPageView === PageView.Table ? ToggleButtonActiveSx : ToggleButtonInactiveSx}
      >
        <TableViewIcon stroke={props.currentPageView === PageView.Table ? colors.neutral[70] : colors.neutral[50]} />
        <Typography level="micro" sx={{ textTransform: 'capitalize' }}>
          {Translations.TABLE}
        </Typography>
      </ToggleButton>
      <ToggleButton
        id="kanban-toggle-btn"
        value={PageView.Kanban}
        aria-label="right aligned"
        sx={props.currentPageView === PageView.Kanban ? ToggleButtonActiveSx : ToggleButtonInactiveSx}
      >
        <KanbanViewIcon stroke={props.currentPageView === PageView.Kanban ? colors.neutral[70] : colors.neutral[50]} />
        <Typography level="micro" sx={{ textTransform: 'capitalize' }}>
          {Translations.PIPELINE}
        </Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const styles = {
  toggleButtonGroup: {
    height: '28px',
    p: '1px',
    backgroundColor: colors.neutral[5],
    border: `1px solid ${colors.neutral[20]}`,
    borderRadius: '6px',
    gap: '4px',
  },
};
