import { SvgIcon, Tooltip } from '@mui/joy';
import { useState } from 'react';
import { SxProps } from '@mui/material';
import { createMailLink, openOutsideLink } from 'services/utils/helpers';

interface SideButtonsProps {
  leftComponent: {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>;
    hoveredIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>;
    description?: JSX.Element | string;
    stroke?: string;
    css: SxProps | undefined;
    link?: string | (() => void);
    onClick?: () => void;
    showFilledIcon?: boolean;
  };
  rightComponent: {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>;
    hoveredIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>;
    description?: JSX.Element | string;
    css: SxProps | undefined;
    stroke?: string;
    link?: string | (() => void);
    onClick?: () => void;
    showFilledIcon?: boolean;
  };
}

type IconType = React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>;

export default function SideButtonsPair({ leftComponent, rightComponent }: SideButtonsProps) {
  const [showFilledIcon, setShowFilledIcon] = useState(false);

  const checkLinkType = (link: string | (() => void)) => {
    if (typeof link === 'function') {
      link();
    } else if (typeof link === 'string') {
      if (link.includes('@')) {
        window.location.href = createMailLink(link);
      } else {
        openOutsideLink(link);
      }
    }
  };

  const onClickRightButton = () => {
    rightComponent.link && checkLinkType(rightComponent.link);
  };

  const onClickLeftButton = () => {
    leftComponent.link && checkLinkType(leftComponent.link);
  };

  const getIconComponent = (icon: IconType, hoveredIcon: IconType | undefined) => {
    if (showFilledIcon) {
      return icon;
    }
    return hoveredIcon || icon;
  };

  return (
    <>
      <Tooltip
        title={leftComponent.description}
        arrow
        placement="top"
        enterDelay={200}
        enterTouchDelay={200}
        enterNextDelay={200}
      >
        <SvgIcon
          component={getIconComponent(leftComponent.icon, leftComponent.hoveredIcon)}
          sx={{ ...leftComponent.css }}
          onClick={leftComponent.onClick || onClickLeftButton}
          onMouseEnter={leftComponent.showFilledIcon ? () => setShowFilledIcon(true) : undefined}
          onMouseLeave={leftComponent.showFilledIcon ? () => setShowFilledIcon(false) : undefined}
          stroke={leftComponent.stroke}
          inheritViewBox
        />
      </Tooltip>
      <Tooltip
        title={rightComponent.description}
        arrow
        placement="top"
        enterDelay={200}
        enterTouchDelay={200}
        enterNextDelay={200}
      >
        <SvgIcon
          component={getIconComponent(rightComponent.icon, rightComponent.hoveredIcon)}
          sx={{
            ...styles.rightButton,
            ...rightComponent.css,
          }}
          onMouseEnter={rightComponent.showFilledIcon ? () => setShowFilledIcon(true) : undefined}
          onMouseLeave={rightComponent.showFilledIcon ? () => setShowFilledIcon(false) : undefined}
          stroke={rightComponent.stroke}
          onClick={rightComponent.onClick || onClickRightButton}
          inheritViewBox
        />
      </Tooltip>
    </>
  );
}

const styles = {
  rightButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};
