import { SxProps } from '@mui/material';
import { colors } from 'theme/colors';

export const ChatTableBoxStyles = (_hasItems: boolean): SxProps => {
  return {
    position: 'relative',
    height: '100%',
    minHeight: 0,
    minWidth: 0,
    '& .MuiDataGrid-root': {
      height: '100%',
      display: 'flex',
      flex: '1 1 auto',
    },
  };
};

export const ChatTableStyles = (hasItems: boolean): SxProps => {
  return {
    borderBottom: hasItems ? undefined : 'none',
    '& .MuiDataGrid-main': {
      height: '100%',
      borderBottom: 'none',
    },
    '& .MuiDataGrid-columnHeader--sorted': {
      '& .MuiSvgIcon-root': {
        color: colors.primary[50],
      },
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .MuiButtonBase-root': {
        padding: 0,
        '&:hover': {
          background: 'transparent !important',
        },
      },
    },
    '& .MuiDataGrid-columnHeader': {
      padding: '0px 0px',
      '& .MuiDataGrid-iconButtonContainer': {
        display: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        width: '100%',
      },
      '& .MuiSvgIcon-root': {
        marginRight: '16px',
      },
    },
    '& .MuiDataGrid-columnHeader--sortable': {
      cursor: 'unset',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeaderTitleContainerContent': {
      width: '100%',
    },
    '& .MuiDataGrid-virtualScroller': {
      /* Hide horizontal scrollbar & gutter at the bottom of the Table View */
      scrollbarWidth: 'thin' /* Firefox */,
      scrollbarColor: `rgba(0, 0, 0, 0.2) transparent` /* Firefox */,
      msOverflowStyle: 'none' /* Internet Explorer 10+ */,
      '&::-webkit-scrollbar': {
        /* WebKit */ height: 0,
      },
    },
  };
};
