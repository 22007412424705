import { IconsSvg } from 'assets/icons';
import { Divider, Select, Option, Typography, FormLabel, Stack, ListItemDecorator, SelectOption } from '@mui/joy';
import { Box, FormControl } from '@mui/material';
import { colors } from 'theme/colors';
import Translations from 'const/translations/en';
import UIButton from 'components/ui-library/Button/UIButton';
import UIInput from 'components/ui-library/Input/UIInput';
import { KeyboardArrowDown } from '@mui/icons-material';
import { PropertyDefinitionCategoryOption } from 'services/models/domain/entityViews';
import { useState } from 'react';
import CellMenuButton from 'components/ui-library/chat/CellMenu/СellMenuButton/CellMenuButton';
import {
  getPropertyDefinitionIcon,
  getPropertyDefinitionName,
  AvailableCategories,
} from 'services/utils/propertyDefinitionCategoryUtils';
import { ColumnVisibilityControlStyles } from 'components/Chats/ChatTableV2/TableHeader/ColumnVisibilityControl/styles';

interface OptionItemProps {
  category: PropertyDefinitionCategoryOption;
}

const OptionItem = ({ category }: OptionItemProps) => {
  const icon = getPropertyDefinitionIcon(category);
  const name = getPropertyDefinitionName(category);
  const IconSvg = IconsSvg[icon];
  return (
    <>
      <ListItemDecorator sx={{ marginRight: '6px' }}>
        <IconSvg stroke={colors.neutral[60]} width={12} height={12} />
      </ListItemDecorator>
      {name}
    </>
  );
};

interface ColumnVisibilityControlCreateProps {
  navigateBack: () => void;
  onCreatePropertyDefinition: (name: string, category: PropertyDefinitionCategoryOption) => void;
}

export default function ColumnVisibilityControlCreate({
  navigateBack,
  onCreatePropertyDefinition,
}: ColumnVisibilityControlCreateProps) {
  const [propertyName, setPropertyName] = useState<string | null>();
  const [propertyCategory, setPropertyCategory] = useState<PropertyDefinitionCategoryOption | null>(
    PropertyDefinitionCategoryOption.Text
  );
  const isPropertyValid = propertyCategory && propertyName;

  const renderValue = (category: SelectOption<PropertyDefinitionCategoryOption> | null) => {
    if (!category) return null;

    setPropertyCategory(category.value);
    return <OptionItem category={category.value} />;
  };

  const onSave = () => {
    if (isPropertyValid) {
      onCreatePropertyDefinition(propertyName, propertyCategory);
    }
  };

  return (
    <>
      <CellMenuButton title={Translations.CANCEL} icon="ic_arrow_left" onClick={navigateBack} />
      <Divider sx={ColumnVisibilityControlStyles.divider} />

      <Stack direction={'column'} spacing={2} sx={ColumnVisibilityControlStyles.container}>
        <FormControl sx={{ gap: '6px' }}>
          <FormLabel>
            <Typography level="micro" sx={ColumnVisibilityControlStyles.text}>
              {Translations.NAME}
            </Typography>
          </FormLabel>
          <UIInput
            placeholder={Translations.CHAT_TABLE_PROPERTY_NAME}
            type="text"
            value={propertyName ?? ''}
            onChange={(e) => setPropertyName(e.target.value)}
            sx={ColumnVisibilityControlStyles.textInput}
            onKeyDown={(e) => e.stopPropagation()}
            autoFocus
          />
        </FormControl>

        <FormControl sx={{ gap: '6px' }}>
          <FormLabel>
            <Typography level="micro" sx={ColumnVisibilityControlStyles.text}>
              {Translations.TYPE}
            </Typography>
          </FormLabel>
          <Select
            variant="soft"
            defaultValue={PropertyDefinitionCategoryOption.Text}
            sx={ColumnVisibilityControlStyles.select}
            componentsProps={{
              listbox: {
                sx: ColumnVisibilityControlStyles.listbox,
              },
            }}
            indicator={<KeyboardArrowDown />}
            renderValue={renderValue}
          >
            {AvailableCategories.map((category, idx) => {
              return (
                <Option key={idx} value={category}>
                  <OptionItem category={category} />
                </Option>
              );
            })}
          </Select>
        </FormControl>
      </Stack>
      <Divider sx={ColumnVisibilityControlStyles.divider} />
      <Box sx={ColumnVisibilityControlStyles.buttonContainer}>
        <UIButton
          fullWidth
          size="sm"
          sx={ColumnVisibilityControlStyles.mainButton}
          onClick={onSave}
          disabled={!isPropertyValid}
        >
          <IconsSvg.ic_check stroke={colors.white} width="14" height="14" />
          <Typography level="tiny">{Translations.SAVE}</Typography>
        </UIButton>
      </Box>
    </>
  );
}
