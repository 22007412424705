import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'services/store/AppStore';

export enum EntityDetailsType {
  contactDetails,
  chatDetails,
}

export interface SidebarState {
  openDrawer: boolean;
  entityType: EntityDetailsType | undefined;
}

const initialState: SidebarState = {
  openDrawer: false,
  entityType: undefined,
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSidebarState: (
      state,
      action: PayloadAction<{ openDrawer: boolean; entityType: EntityDetailsType | undefined }>
    ) => {
      state.openDrawer = action.payload.openDrawer;
      state.entityType = action.payload.entityType;
    },
  },
});

export const { setSidebarState } = sidebarSlice.actions;
export const sidebarState = (state: RootState) => state.sidebar;
export default sidebarSlice.reducer;
