import { KanbanColumn, KanbanEntity } from 'components/Kanban/KanbanBoard';
import _ from 'lodash';
import { EntityPropertyDefinition, OptionPropertyValue } from 'services/models/domain/entityViews';
import { PaginationInfo } from 'services/store/slices/pipelineConversationsSlice';
import { Folder, FolderUser } from 'services/models/domain/folder';
import { Conversation } from 'services/models/domain/conversation';

export const kanbanColumnsForConversations = (
  conversations: Conversation[],
  groupByPropertyDefinition: EntityPropertyDefinition,
  paginationInfos: { [id: string]: PaginationInfo }
) => {
  const columnsMap: Map<string | undefined, Conversation[]> = new Map();
  const propertyMap: Map<string, OptionPropertyValue> = new Map();
  columnsMap.set(undefined, []);

  groupByPropertyDefinition.values.forEach((propertyValue) => {
    if (propertyValue && propertyValue.type === 'OPTION') {
      columnsMap.set(propertyValue.oid.oid, []);
      propertyMap.set(propertyValue.oid.oid, propertyValue);
    }
  });

  conversations.forEach((conversation) => {
    const property = conversation.properties.find((property) => {
      return property && property.propertyDefinitionOid.oid === groupByPropertyDefinition.oid.oid;
    });
    if (property && property.type === 'OPTION') {
      const existing = columnsMap.get(property.oid.oid) || [];
      columnsMap.set(property.oid.oid, [...existing, conversation]);
    } else {
      const existing = columnsMap.get(undefined) || [];
      columnsMap.set(undefined, [...existing, conversation]);
    }
  });
  const columns: KanbanColumn<Conversation>[] = Array.from(columnsMap.entries()).map(([pv, conversations]) => {
    const propertValue = propertyMap.get(pv || '');
    const entities: KanbanEntity<Conversation>[] = conversations.map((c) => {
      return {
        ...c,
        id: c.oid.oid,
      };
    });
    return {
      entities: entities,
      id: propertValue?.oid?.oid,
      title: propertValue?.value || 'No Status',
      color: propertValue?.color || 'black',
      icon: propertValue?.icon || 'ic_circle_dotted',
      ordering: propertValue?.ordering || 0,
      totalNumberOfEntities: paginationInfos[propertValue?.oid.oid || 'undefined']?.total,
    };
  });
  return columns;
};

export const stringArraysEqual = (a?: string[], b?: string[]): boolean => {
  return JSON.stringify(a || []) === JSON.stringify(b || []);
};

export const groupFoldersByUser = (folders: Folder[]): { user: FolderUser; folders: Folder[] }[] => {
  const userMap: Map<string, FolderUser> = new Map();

  folders.forEach((f) => {
    if (f.user) {
      userMap.set(f.user.oid.oid, f.user);
    }
  });
  return _.chain(folders)
    .groupBy((f) => f?.user?.oid.oid)
    .map((folders, userOid) => {
      return {
        user: userMap.get(userOid)!,
        folders: _.chain(folders).sortBy('folderName').value(),
      };
    })
    .sortBy('user.fullName')
    .value();
};
