import { Box } from '@mui/joy';
import { styled, SxProps } from '@mui/material';
import { colors } from 'theme/colors';

export const MenuSx = {
  marginTop: '0px',
  '& .MuiPaper-root': {
    marginLeft: '2px',
    marginTop: '-35px',
  },
};

export const ContainerBoxStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  background: colors.white,
  border: `1px solid ${colors.neutral[5]}`,
  borderRadius: '4px',
  width: '168px',
  boxShadow: '0px 0px 2px rgb(0 0 0 / 10%), 0px 0px 20px rgb(0 0 0 / 8%)',
  color: colors.neutral[60],
  '& svg': {
    stroke: colors.neutral[60],
  },
};

export const InputSectionStyles: SxProps = {
  display: 'flex',
  padding: '8px',
};

export const DeleteSectionStyles: SxProps = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '3px 2px',
};

export const DeleteBtnStyles = (confirmBtnShown: boolean) => {
  return {
    width: '100%',
    justifyContent: 'left',
    paddingLeft: '6px',
    color: colors.neutral[60],
    height: '26px',

    '&:hover': !confirmBtnShown && {
      background: 'rgba(219, 32, 66, 0.05)',
      color: colors.warning,
      '& svg': {
        stroke: colors.warning,
      },
    },

    '&:disabled': {
      background: 'transparent',
      borderColor: 'transparent',
      color: colors.neutral[60],
    },
  };
};

export const ConfirmBtnStyles: SxProps = {
  position: 'absolute',
  right: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '24px',
  minWidth: '24px',
  height: '24px',
  minHeight: '24px',
  borderRadius: '6px',
  backgroundColor: colors.warning,
  border: 'none',
  '& svg': {
    stroke: colors.white,
  },
  cursor: 'pointer',
};

export const SaveBtnStyles: SxProps = {
  width: '100%',
  color: colors.neutral[0],
  background: colors.neutral[100],
  height: '26px',
  '& svg': {
    stroke: colors.neutral[0],
  },
  '& hover': {
    color: colors.neutral[0],
    background: colors.neutral[100],
  },
};

export const CancelBtnStyles: SxProps = {
  width: '100%',
  color: colors.neutral[60],
  height: '26px',
  '&:hover': {
    color: colors.neutral[60],
  },
  '& svg': {
    color: colors.neutral[60],
  },
};

export const GridSectionStyles: SxProps = {
  padding: '2px 2px',
  display: 'flex',
  flexWrap: 'wrap',
};

export const GridIconStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'isColor',
})(({ isActive, isColor }: { isActive: boolean; isColor?: boolean }) => ({
  width: '27px',
  height: '27px',
  margin: '2.7px',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: isActive ? '1' : '0.5',
  '&:hover': {
    background: !isColor ? colors.neutral[5] : undefined,
    '& svg': {
      stroke: colors.neutral[70],
    },
    '& div': {
      opacity: 1,
      borderColor: colors.neutral[40],
    },
  },
  background: isActive ? `${colors.primary[50]} !important` : undefined,
  '& svg': {
    stroke: isActive ? `${colors.white} !important` : undefined,
  },
  '& div': {
    opacity: isActive ? 1 : undefined,
    border: isActive ? `1px solid ${colors.white} !important` : undefined,
  },
}));

export const DividerSx: SxProps = {
  '--Divider-lineColor': colors.neutral[5],
  backgroundColor: colors.neutral[5],
};

export const ColorIcon = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ color }: { color: string }) => ({
  width: '14px',
  height: '14px',
  border: color === colors.white ? `1px solid ${colors.neutral[20]}` : `0px solid ${colors.white}`,
  borderRadius: 2,
  background: color,
  opacity: '0.6',
}));

export const TextStyles: SxProps = {
  color: 'palette.text.secondary',
  lineHeight: '16px',
  letterSpacing: '-0.005em',
};
