import { useState } from 'react';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import { Typography } from '@mui/joy';
import Translations from 'const/translations/en';
import { headerStyles } from 'page/dashboard/header/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Icons from 'assets/icons';
import { NavigationPath, RouterPaths, useNavigation } from 'services/hooks/useNavigation';

export enum TabStyle {
  Vertical,
  Horizontal,
}

interface Props {
  style: TabStyle;
  onChange?: () => void;
}

function NavigationTabs({ style, onChange }: Props) {
  const navigate = useNavigation();
  const flags = useFlags();
  const getCurrentTab = () => {
    const currentPath = window.location.pathname;
    if (currentPath.includes('/chats')) {
      return 0;
    } else if (currentPath.includes('/contacts')) {
      return 1;
    } else if (currentPath === '/reports') {
      return 2;
    }
  };
  const [activeTab, setActiveTab] = useState<number>(getCurrentTab() || 0);

  const handleTabChange = (index: number, path: NavigationPath) => {
    setActiveTab(index);
    navigate(path, { replace: true });
    onChange && onChange();
  };

  const renderTabs = () => {
    return (
      <TabList
        variant="plain"
        sx={
          style === TabStyle.Horizontal
            ? headerStyles.tabList
            : {
                ...headerStyles.tabListHorizontal,
                flexDirection: 'column',
                top: '10vh',
              }
        }
      >
        <Tab
          onChange={() => {
            handleTabChange(0, RouterPaths.Chats);
          }}
        >
          {style === TabStyle.Vertical && <img src={Icons.ic_chats} alt="chats" height={20} width={20} />}
          <Typography level="tiny" sx={{ fontWeight: activeTab === 0 ? 'bold' : 'normal' }}>
            {Translations.CHATS}
          </Typography>
        </Tab>
        {flags?.contactsView && (
          <Tab
            onChange={() => {
              handleTabChange(1, RouterPaths.Contacts);
            }}
          >
            {style === TabStyle.Vertical && <img src={Icons.ic_users} alt="contacts" height={20} width={20} />}
            <Typography level="tiny" sx={{ fontWeight: activeTab === 1 ? 'bold' : 'normal' }}>
              {Translations.CONTACTS}
            </Typography>
          </Tab>
        )}
        {flags?.reportsTab && (
          <Tab
            onChange={() => {
              handleTabChange(2, RouterPaths.Reports);
            }}
          >
            {style === TabStyle.Vertical && <img src={Icons.ic_reports} alt="reports" height={20} width={20} />}
            <Typography level="tiny" sx={{ fontWeight: activeTab === 2 ? 'bold' : 'normal' }}>
              {Translations.DASHBOARD}
            </Typography>
          </Tab>
        )}
      </TabList>
    );
  };

  return (
    <>
      <Tabs
        aria-label="tabs"
        defaultValue={getCurrentTab()}
        sx={style === TabStyle.Horizontal ? headerStyles.tabView : { ml: '1vh' }}
      >
        {renderTabs()}
      </Tabs>
    </>
  );
}

export default NavigationTabs;
