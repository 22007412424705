import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EntityType, useUpdateEntityViewPropertyDefinitionsMutation } from 'services/models/api/generated';
import {
  appendVisiblePropertyDefinition,
  entityViewsState,
  removeVisiblePropertyDefinition,
  updateAllVisiblePropertyDefinitionOids,
} from 'services/store/slices/entityViewsSlice';
import { sideNavState } from 'services/store/slices/sideNavSlice';

export function usePropertiesData() {
  const dispatch = useDispatch();
  const { entityViews } = useSelector(entityViewsState);
  const { selectedItem } = useSelector(sideNavState);

  const currentView = useMemo(() => {
    return entityViews?.views.find((view) => {
      if ('oid' in view && selectedItem) {
        return view.oid.oid === selectedItem.id;
      }
      return false;
    });
  }, [entityViews, selectedItem]);

  // If currentView is undefined, use the default view's propertyDefinitions
  const currentViewPropertyDefinitions = currentView
    ? currentView?.propertyDefinitions
    : entityViews.propertyDefinitions;

  const invisiblePropertyDefinitionsToView = useMemo(() => {
    return currentViewPropertyDefinitions?.filter((pd) => !pd.visible);
  }, [currentViewPropertyDefinitions]);

  const visiblePropertyDefinitionsToView = useMemo(() => {
    return currentViewPropertyDefinitions?.filter((pd) => pd.visible);
  }, [currentViewPropertyDefinitions]);

  const viewOid = currentView && 'oid' in currentView ? currentView.oid.oid : undefined;

  const existingVisiblePropDefOids = useMemo(() => {
    return visiblePropertyDefinitionsToView.map((view) => view.oid.oid);
  }, [visiblePropertyDefinitionsToView]);

  const [updateEntityViewPropertyDefinitions] = useUpdateEntityViewPropertyDefinitionsMutation();

  const updateViewVisiblePropertyDefinitions = useCallback(
    (visiblePropertyDefinitionOids: string[]) => {
      updateEntityViewPropertyDefinitions({
        variables: {
          input: {
            entityType: EntityType.Conversation,
            viewOid: viewOid,
            visiblePropertyDefinitionOids: visiblePropertyDefinitionOids,
          },
        },
      });
    },
    [viewOid]
  );

  const addVisiblePropertyDefinitionToView = useCallback(
    (propertyDefinitionOid: string) => {
      // Update local Redux
      dispatch(
        appendVisiblePropertyDefinition({
          viewOid: viewOid,
          visiblePropertyDefinitionOid: propertyDefinitionOid,
        })
      );
      // Append the property to the list to show in the details page
      if (!entityViews.allVisiblePropertyDefinitionOids.includes(propertyDefinitionOid)) {
        dispatch(
          updateAllVisiblePropertyDefinitionOids({
            allVisiblePropertyDefinitionOids: [...entityViews.allVisiblePropertyDefinitionOids, propertyDefinitionOid],
          })
        );
      }

      // Update graphql backend
      updateViewVisiblePropertyDefinitions([...existingVisiblePropDefOids, propertyDefinitionOid]);
    },
    [existingVisiblePropDefOids, entityViews.allVisiblePropertyDefinitionOids, viewOid]
  );

  const removeVisiblePropertyDefinitionFromView = useCallback(
    (propertyDefinitionOid: string | undefined) => {
      if (propertyDefinitionOid) {
        // Update local Redux
        dispatch(
          removeVisiblePropertyDefinition({
            viewOid: viewOid,
            visiblePropertyDefinitionOid: propertyDefinitionOid,
          })
        );

        const newVisiblePropDefOids = existingVisiblePropDefOids.filter((oid) => oid !== propertyDefinitionOid);
        updateViewVisiblePropertyDefinitions(newVisiblePropDefOids);
      }
    },
    [existingVisiblePropDefOids, viewOid]
  );

  return {
    invisiblePropertyDefinitionsToView,
    addVisiblePropertyDefinitionToView,
    removeVisiblePropertyDefinitionFromView,
  };
}
