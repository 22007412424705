import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, IconButton, Sheet, Stack, Typography } from '@mui/joy';
import format from 'date-fns/format';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import Icons from 'assets/icons';
import UIButton from 'components/ui-library/Button/UIButton';
import AssignFollowUp from 'page/modal/followUpModals/AssignFollowUp/AssignFollowUp';
import Calendar from 'page/modal/followUpModals/FollowUpCalendar/FollowupCalendar';
import { FollowupPages } from 'page/modal/followUpModals/interface';
import { Conversation } from 'services/models/domain/conversation';
import { Followup } from 'services/models/domain/followUps';
import { OrganizationalUser, User } from 'services/models/domain/user';
import { ordinal } from 'services/utils/helpers';

interface Props {
  handleClose: () => void;
  conversation: Conversation;
  user: User;
  onFollowUpUpdated: (from?: Followup, to?: Followup) => void;
}

const UpdateFollowupModal: React.FC<Props> = ({ handleClose, conversation, user, onFollowUpUpdated }) => {
  const [followUp, setFollowUp] = useState<Followup | undefined>(undefined);
  const [assignee, setAssignee] = useState<OrganizationalUser | undefined>(undefined);
  const [date, setDate] = useState<Date | undefined>(undefined);
  const [page, setPage] = useState<FollowupPages>(FollowupPages.initPage);

  useEffect(() => {
    if (conversation.nextFollowup) {
      const existingFollowUp: Followup = conversation.nextFollowup;
      setAssignee(existingFollowUp.assignedToUser);
      setDate(new Date(existingFollowUp.dueAt.utcTimeStampAsString));
      setFollowUp(existingFollowUp);
    } else {
      setAssignee(user); // Default to the signed-in user if not already set
      setDate(undefined);
      setFollowUp(undefined);
    }
    setPage(FollowupPages.initPage);
  }, [conversation.nextFollowup]);

  const dateJs = date ? dayjs(date) : null;

  const onSetNewFollowup = async () => {
    let newFollowUp;
    if (assignee && date) {
      newFollowUp = {
        assignedToUser: assignee,
        createdByUserOid: user!.oid,
        dueAt: {
          utcTimeStampAsString: date.toISOString(),
        },
      };
    }
    onFollowUpUpdated(followUp, newFollowUp);
    setFollowUp(newFollowUp);

    handleClose();
  };

  return (
    <>
      {page === FollowupPages.initPage && (
        <Sheet
          variant="outlined"
          sx={{
            width: '100%',
            borderRadius: '8px',
            maxWidth: '342px',
            minWidth: '342px',
            mt: 0,
          }}
        >
          <Box
            sx={{
              flexDirection: 'row',
              display: 'flex',
              p: '20px 16px 16px',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: '60px',
              borderBottom: '1px solid var(--joy-palette-info-outlinedBorder)',
            }}
          >
            <Typography id="modal-title" level="large" textColor="inherit">
              Follow-up
            </Typography>
            <CloseIcon
              sx={{
                width: '1.3rem',
                height: '1.3rem',
                cursor: 'pointer',
                color: 'info.main',
              }}
              onClick={handleClose}
            />
          </Box>

          <Stack sx={{ p: '12px 0px', justifyContent: 'space-between' }}>
            <ModalMenuItem
              icon={Icons.ic_calendar_event}
              setPage={() => setPage(FollowupPages.calendarPage)}
              title="Date"
              value={dateJs ? format(dateJs.valueOf(), 'MMM dd') + ordinal(dateJs?.get('date')) : 'Not set'}
            />

            {(user?.actingAsMemberOfOrganization?.numberOfUsersInOrganization ?? 0) > 1 && (
              <Divider
                sx={{ border: '.7px dashed #fff', background: 'var(--joy-palette-info-plainBorder)', my: '8px' }}
              />
            )}

            {(user?.actingAsMemberOfOrganization?.numberOfUsersInOrganization ?? 0) > 1 && (
              <ModalMenuItem
                icon={Icons.ic_user}
                setPage={() => setPage(FollowupPages.assigneePage)}
                title="Assigned to"
                value={assignee?.fullName || assignee?.firstName || 'Not set'}
              />
            )}
          </Stack>

          <Box
            sx={{
              borderTop: '1px solid #F4F6F8',
              height: '64px',
              p: '12px 16px 12px 16px',
            }}
          >
            <Stack spacing={2} direction="row">
              <UIButton
                size="md"
                color="warning"
                variant="plain"
                onClick={() => {
                  // handleDeleteFollowup();
                  onFollowUpUpdated(followUp, undefined);
                  handleClose();
                }}
              >
                Delete
              </UIButton>

              <UIButton fullWidth loading={false} onClick={onSetNewFollowup}>
                <Typography level="tiny" sx={{ color: 'white', fontSize: '13px' }}>
                  Save Changes
                </Typography>
              </UIButton>
            </Stack>
          </Box>
        </Sheet>
      )}

      {page === FollowupPages.calendarPage && (
        <Sheet
          variant="outlined"
          sx={{
            width: '100%',
            borderRadius: '8px',
            maxWidth: '342px',
            minWidth: '342px',
          }}
        >
          <Box
            sx={{
              flexDirection: 'row',
              display: 'flex',
              p: '20px 16px 16px 8px',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: '60px',
              borderBottom: '1px solid var(--joy-palette-info-outlinedBorder)',
            }}
          >
            <Stack direction="row" sx={{ alignItems: 'center' }}>
              <IconButton
                onClick={() => setPage(FollowupPages.initPage)}
                sx={{
                  background: 'transparent',
                  minHeight: 'auto',
                  m: 0,
                  '&:hover': { background: 'transparent', opacity: '1' },
                }}
              >
                <img src={Icons.ic_arrow_left} alt="" width={24} height={24} />
              </IconButton>
              <Typography id="modal-title" level="regular" textColor="inherit" sx={{ ml: '13px' }}>
                Date
              </Typography>
            </Stack>
            <CloseIcon
              sx={{ width: '1.3rem', height: '1.3rem', cursor: 'pointer', color: 'info.main' }}
              onClick={handleClose}
            />
          </Box>

          <Box
            sx={{
              p: '16px',
              background: 'var(--joy-palette-info-outlinedBorder)',
              borderRadius: '0px 0px 8px 8px',
            }}
          >
            <Box
              sx={{
                background: 'var(--joy-palette-common-white)',
                borderRadius: '8px',
              }}
            >
              <Calendar
                value={date}
                handleValue={(date?: Date) => {
                  setDate(date);
                  setPage(FollowupPages.initPage);
                }}
              />
            </Box>
          </Box>
        </Sheet>
      )}

      {page === FollowupPages.assigneePage && (
        <Sheet
          variant="outlined"
          sx={{
            width: '100%',
            borderRadius: '8px',
            maxWidth: '342px',
            minWidth: '342px',
          }}
        >
          <Box
            sx={{
              flexDirection: 'row',
              display: 'flex',
              p: '20px 16px 16px 8px',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: '60px',
              borderBottom: '1px solid var(--joy-palette-info-outlinedBorder)',
            }}
          >
            <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
              <IconButton
                onClick={() => setPage(FollowupPages.initPage)}
                sx={{
                  background: 'transparent',
                  minHeight: 'auto',
                  border: 'none',
                  '&:hover': { background: 'transparent', opacity: '1' },
                }}
              >
                <img src={Icons.ic_arrow_left} alt="" width={24} height={24} />
              </IconButton>
              <Typography id="modal-title" level="regular" textColor="inherit" sx={{ ml: 1.5 }}>
                Assigned to
              </Typography>
            </Stack>
            <CloseIcon
              sx={{ width: '1.3rem', height: '1.3rem', cursor: 'pointer', color: 'info.main' }}
              onClick={handleClose}
            />
          </Box>

          <Stack
            sx={{
              p: '16px',
              background: 'var(--joy-palette-info-outlinedBorder)',
              borderRadius: '0px 0px 8px 8px',
            }}
          >
            <AssignFollowUp
              assignee={assignee ?? null}
              onSelectAssignee={(user: User) => {
                setAssignee(user);
                setPage(FollowupPages.initPage);
              }}
              width={'100%'}
            />
          </Stack>
        </Sheet>
      )}
    </>
  );
};

export default UpdateFollowupModal;

interface ModalMenuProps {
  setPage: React.Dispatch<React.SetStateAction<FollowupPages>>;
  value: string;
  title: string;
  icon: string;
}

export function ModalMenuItem({ setPage, value, title, icon }: ModalMenuProps) {
  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '38px',
        p: '0px 16px 0px 16px',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      onClick={() => setPage(FollowupPages.calendarPage)}
    >
      <Stack direction="row" sx={{ alignItems: 'center' }}>
        <img src={icon} alt="" width={18} height={18} />
        <Box sx={{ ml: '14px' }}>
          <Typography level="regular">{title}</Typography>
          <Typography sx={{ color: 'info.main' }} level="tiny">
            {value}
          </Typography>
        </Box>
      </Stack>
      <img src={Icons.ic_chevron_right} alt="" width={16} height={16} />
    </Stack>
  );
}
