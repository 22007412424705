import { styled, SxProps, Theme } from '@mui/material';
import { colors } from 'theme/colors';
import { fonts } from 'theme/fonts';

export const ModalBoxContentStyles: SxProps = {
  flexDirection: 'column',
  display: 'flex',
  padding: '8px 0',
  px: 2,
  py: 1.5,
  gap: '16px',
  alignContent: 'center',
  alignItems: 'center',
};

export const ModalBoxStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'center',
};

export const QrCodeInstructionStyles: SxProps = {
  alignSelf: 'stretch',
  color: colors.neutral[60],
  textAlign: 'center',
  fontSize: '14px',
  fontFamily: fonts.regular,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
  gap: '24px',
};

export const TwoFactorStyles: SxProps = {
  color: colors.neutral[40],
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '16px',
  letterSpacing: '-0.06px',
  textAlign: 'left',
};

export const ManualLoginStyles: SxProps = {
  width: 'min(100% - 32px, 360px)',
  alignItems: 'stretch',
};

export const FormInputRounded: SxProps = {
  borderRadius: '6px',
};

export const FormInputTextStyles: SxProps = {
  backgroundColor: 'var(--joy-palette-primary-plainBorder)',
  borderWidth: '1px',
  paddingTop: '8.5px',
  paddingBottom: '8.5px',
  height: 'auto',

  '.MuiOutlinedInput-root': {
    paddingLeft: '4px',
    borderWidth: '1px',
  },

  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#E6EBEE',
    borderWidth: '1px',
  },

  '.Mui-focused': {
    '.MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: '#B6D5FE !important',
    },
  },

  '.MuiInputBase-input, .JoyInput-input': {
    height: '19px',
    fontSize: '14px',
    borderWidth: '1px',
    fontFamily: fonts.regular,
  },
};

export const MuiTelInputStyles: SxProps<Theme> = {
  ...FormInputTextStyles,
  padding: '0px 0px 0px 0px',

  '.MuiTelInput-Flag img': {
    width: '18px',
    height: '13.4px',
  },
  ...FormInputRounded,
};

export const MuiOtpInputStyles: SxProps = {
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
};

export const ModalContentStackStyles: SxProps<Theme> = {
  padding: '12px 16px 16px 16px',
};

export const CustomDividerStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  height: '24px',
  fontSize: '12px',
  fontFamily: fonts.regular,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
  color: colors.neutral[40],
  alignSelf: 'stretch',

  '&::before, &::after': {
    content: '""',
    flex: 1,
    height: '1px',
    borderBottom: '1px dashed',
    borderColor: colors.neutral[10],
    maxWidth: '184.5px',
  },

  '&::before': {
    marginRight: '8px',
  },

  '&::after': {
    marginLeft: '8px',
  },
};

export const QrCodeBoxStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '24px',
};
export const LinkStyles: SxProps = {
  cursor: 'pointer',
  textDecoration: 'underline',
  fontSize: '14px',
};

export const TelInputCounterBtnStyles: SxProps = {
  width: '90px',
  backgroundColor: '#ECF0F3',
  color: colors.neutral[60],

  ':hover': {
    backgroundColor: '#ECF0F3',
    color: colors.neutral[60],
  },
};

export const TelInputWrapperStyled = styled('div')({
  position: 'relative',
});

export const ResendButtonStyles: SxProps = {
  ...FormInputRounded,
  position: 'absolute',
  top: '4px',
  right: '4px',
  bottom: '4px',
  height: 'auto',
  minHeight: 'auto',
};

export const DividerStyles: SxProps = {
  backgroundColor: colors.neutral[10],
};
