import { GroupAddTwoTone } from '@mui/icons-material';
import { colors } from 'theme/colors';
import { fonts } from 'theme/fonts';

export const ColumnVisibilityControlStyles = {
  selectContainer: {
    justifyContent: 'space-between',
    overflow: 'hidden',
    maxHeight: '90vh',
  },
  selectList: {
    overflow: 'auto',
  },
  divider: { color: colors.neutral[10] },
  dividerText: { color: colors.neutral[40] },
  text: { color: colors.neutral[60] },
  textInput: {
    '&.Joy-focused': {
      border: `2px solid ${colors.primary[50]}`,
    },
  },
  container: { margin: '8px' },
  select: {
    borderRadius: '6px',
    width: '100%',
    minHeight: '32px',
    fontSize: '14px',
    fontFamily: fonts.regular,
    color: colors.neutral[60],
    border: `1px solid ${colors.neutral[10]}`,
    '--List-decorator-size': 'auto',
    marginRight: '6px',
    '.JoySelect-button': {
      fontFamily: 'inherit',
      '&:hover': {
        color: colors.neutral[60],
      },
    },
  },
  listbox: {
    backgroundColor: colors.white,
    marginRight: '6px',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1), 0px 0px 20px rgba(0, 0, 0, 0.08)',
    border: `1px solid ${colors.neutral[15]}`,
    borderRadius: '6px',
    '.JoyOption-root': {
      color: colors.neutral[60],
      fontSize: '14px',
      fontFamily: fonts.regular,
      border: 'none',
    },
    '.Joy-selected, .JoyOption-highlighted': {
      backgroundColor: 'transparent !important',
    },
    '--List-item-minHeight': '27px',
    '--List-decorator-size': 'auto',
  },
  buttonContainer: { margin: '8px' },
  mainButton: {
    background: colors.primary[50],
    gap: '6px',
    border: 'none',
    '&:hover': {
      background: colors.primary[50],
      border: 'none',
    },
  },
  menuItem: {
    display: 'flex',
    gap: '6px',
    padding: '4px 6px',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '4px',
    width: '100%',
    height: '25px',
  },
  menuSubGroup: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 4px',
    gap: '4px',
  },
  subGroupTopBorder: {
    borderTop: `1px solid ${colors.border}`,
  },
  emptyMenu: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '12px 4px',
    gap: '16px',
  },
};
