import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'services/models/domain/user';
import { RootState } from 'services/store/AppStore';

export type AuthState = {
  authToken?: string;
  isAuthenticated?: boolean;
  user: User | null | undefined;
  isInitialized?: boolean;
  authorized?: boolean;
  loading: boolean;
  error?: string | null;
  email: string;
  fullName: string | null;
  isFirstTime: boolean;
  isNameStepCompleted: boolean;
  isInviteStepCompleted: boolean;
  isSigninCompleted: boolean;
};

export const AuthProviders = {
  password: 'Password',
  magicLink: 'Magic Link',
  google: 'Google',
};

///Initial state
const initialState: AuthState = {
  authToken: '',
  isAuthenticated: false,
  user: null,
  isInitialized: false,
  authorized: false,
  loading: false,
  error: '',
  email: '',
  fullName: null,
  isFirstTime: false,
  isNameStepCompleted: false,
  isInviteStepCompleted: false,
  isSigninCompleted: false,
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setInitialize: (
      state,
      action: PayloadAction<{
        isAuthenticated: boolean;
        isInitialized: boolean;
        user: User | null | undefined;
        authorized?: boolean;
        authToken?: string;
      }>
    ) => {
      state.isInitialized = action.payload.isInitialized;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
      state.authorized = action.payload.authorized;
      state.authToken = action.payload.authToken;
    },
    setLogin: (
      state,
      action: PayloadAction<{
        isAuthenticated: boolean;
        user: User | null | undefined;
        authToken: string;
        authorized: boolean;
        isSigninCompleted: boolean;
      }>
    ) => {
      state.authToken = action.payload.authToken;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
      state.authorized = action.payload.authorized;
      state.isSigninCompleted = action.payload.isSigninCompleted;
    },
    setLogout: (state) => {
      state.authToken = undefined;
      state.isAuthenticated = false;
      state.user = undefined;
      state.authorized = false;
      state.isInitialized = true;
      state.isSigninCompleted = false;
    },
    setRegister: (
      state,
      action: PayloadAction<{
        isAuthenticated: boolean;
        user: User | null | undefined;
        authToken: string;
        authorized: boolean;
      }>
    ) => {
      state.authToken = action.payload.authToken;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
      state.authorized = action.payload.authorized;
    },
    setUser: (
      state,
      action: PayloadAction<{
        user?: User | null | undefined;
        email?: string;
        fullName?: string | null;
        isFirstTime: boolean;
        isNameStepCompleted: boolean;
        isInviteStepCompleted: boolean;
      }>
    ) => {
      state.user = action.payload.user || state.user;
      state.email = action.payload.email || '';
      state.fullName = action.payload.fullName || '';
      state.isFirstTime = action.payload.isFirstTime;
      state.isNameStepCompleted = action.payload.isNameStepCompleted;
      state.isInviteStepCompleted = action.payload.isInviteStepCompleted;
    },
    setError: (state, action: PayloadAction<{ error: string | null; isAuthenticated: boolean }>) => {
      state.error = action.payload.error;
      state.isAuthenticated = action.payload.isAuthenticated;
    },
  },
});

export const { setInitialize, setLogin, setLogout, setRegister, setError, setUser } = authSlice.actions;

export const authState = (state: RootState) => state.auth;

export const authReducer = authSlice.reducer;
