import Translations from 'const/translations/en';
import { getLinkDescription } from 'services/utils/conversationUtils';
import { DoubleLineTooltipContents } from 'components/TooltipContents';
import { LinkDescriptionType } from 'services/models/domain/conversation';
import { Typography } from '@mui/material';

interface LinkDescriptionProps {
  origin: string;
  originalLink?: string;
  link?: string;
  channelType?: string;
}

export default function LinkDescriptionComponent({ origin, originalLink, link, channelType }: LinkDescriptionProps) {
  const description = getLinkDescription(origin, originalLink, link, channelType);

  if (typeof description === 'string') {
    return <Typography>{description}</Typography>;
  }

  switch (description.type) {
    case LinkDescriptionType.OpenTelegramTooltip:
      return <Typography>{Translations.OPEN_TELEGRAM_TOOLTIP}</Typography>;
    case LinkDescriptionType.NoTelegramChatLink:
      return (
        <DoubleLineTooltipContents
          primaryText={Translations.NO_CHAT_LINK_TOOLTIP_PRIMARY_MESSAGE}
          secondaryText={Translations.NO_CHAT_LINK_TOOLTIP_SECONDARY_MESSAGE}
        />
      );
    case LinkDescriptionType.NoLinkEntered:
      return (
        <DoubleLineTooltipContents
          primaryText={Translations.NO_LINK_ENTERED}
          secondaryText={Translations.EDIT_CHAT_TO_ENTER_LINK}
        />
      );
    default:
      return <></>;
  }
}
