import { SortDirectionType, PartyType, SortConversationsOnPropertyType } from 'services/models/api/generated';
import { DefaultProperties, EntityViewField, TableViewColumnEntity } from './models/domain/entityViews';
import { ChannelType } from './models/domain/conversation';

export const PAGINATION_LIMIT_STORAGE_KEY = 'chats-pagination-limit';

export const DEFAULT_PER_PAGE = 50;
export const DEFAULT_PER_PAGE_KANBAN = 20;
export const POLL_INTERVAL = 600000;
export const DEFAULT_PAGE_NUMBER = 0;
export const INITIAL_UI_PAGE_NUMBER = DEFAULT_PAGE_NUMBER;
export const LIMIT_PER_PAGE = DEFAULT_PER_PAGE;
export const DEFAULT_SORT_DIRECTION = SortDirectionType.Desc;
export const REFETCH_THRESHOLD = 5;

export const INITIAL_CHAT_FILTER_VALUES = {
  input: {
    search: '',
    filterOn: {
      favorite: false,
      partyScope: PartyType.Organization,
    },
    sortOn: {
      sortOnProperties: [
        {
          sortConversationsOnPropertyType: SortConversationsOnPropertyType.LastFollowupDate,
          sortDirection: DEFAULT_SORT_DIRECTION,
        },
      ],
    },
  },
  criteria: {
    partyScope: PartyType.Organization,
  },
};

export const INITIAL_VISUAL_TABLE_VIEW_COLUMNS = [
  {
    type: TableViewColumnEntity.Default,
    name: EntityViewField.LatestMessage,
  },
  {
    type: TableViewColumnEntity.Property,
    name: DefaultProperties[DefaultProperties.Status],
  },
];

export const FOLLOWUPS_MONTH_RANGE = 6;

export const ChannelWebLinks = {
  [ChannelType.Twitter]: ['t.co', 'twitter.com', 'x.com'],
  [ChannelType.LinkedIn]: ['linkedin.com'],
  [ChannelType.Discord]: ['discord.com', 'discord.gg'],
  [ChannelType.Email]: ['@'],
  [ChannelType.Slack]: ['slack.com/messages', 'slack.com/archives'],
  [ChannelType.Signal]: ['signal.group'],
  [ChannelType.XMTP]: ['xmtp.chat'],
  [ChannelType.Instagram]: ['instagram.com/direct'],
  [ChannelType.WhatsApp]: ['wa.me', 'whatsapp.com/send'],
};
