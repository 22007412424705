import { Box, ListItemDecorator, Typography } from '@mui/joy';
import { MenuItem } from '@mui/material';

import { IconsSvg } from 'assets/icons';
import { colors } from 'theme/colors';
import { CellMenuButtonProps } from 'components/ui-library/chat/CellMenu/СellMenuButton/interface';
import { FlexBoxStyles, OptionsSx, TextStyles } from 'components/ui-library/chat/CellMenu/СellMenuButton/styled';

function renderIcon(icon: string | undefined) {
  const Icon = icon && IconsSvg[icon as keyof typeof IconsSvg];

  if (Icon) {
    return <Icon stroke={colors.neutral[50]} width={12} height={12} />;
  }

  return null;
}

const CellMenuButton = (props: CellMenuButtonProps) => {
  const { sx, icon, title, ...rest } = props;
  const MenuSx = { ...OptionsSx, ...sx };

  return (
    <MenuItem sx={MenuSx} {...rest}>
      <ListItemDecorator>
        <Box sx={FlexBoxStyles}>
          {renderIcon(icon)}
          <Typography level="micro" sx={TextStyles}>
            {title}
          </Typography>
        </Box>
      </ListItemDecorator>
    </MenuItem>
  );
};

export default CellMenuButton;
