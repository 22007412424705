import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TgSyncStatus } from 'page/modal/TgConnectModal/interface';
import { UpsertFolderInput } from 'services/clients/TelegramClient';
import { Folder } from 'services/models/domain/folder';
import { RootState } from 'services/store/AppStore';

export interface UserTGState {
  isOnDemandSyncActive: boolean;
  isOnClientSyncActive: boolean;
  stopSyncing: boolean;
  isLoadingClient: boolean;
  folders: Folder[];
  initialFolders: Folder[];
  telegramFolders?: UpsertFolderInput[];
  foldersLength: number | undefined;
  conversationsSynced: number;
  lastSyncedAt?: number;
  status: string;
}

const initialState: UserTGState = {
  status: TgSyncStatus.WAITING_FOR_CONNECTION,
  lastSyncedAt: undefined,
  isOnDemandSyncActive: false,
  isOnClientSyncActive: false,
  stopSyncing: false,
  isLoadingClient: true,
  folders: [],
  initialFolders: [],
  telegramFolders: undefined,
  foldersLength: undefined,
  conversationsSynced: 0,
};

const userTgSlice = createSlice({
  name: 'userTg',
  initialState,
  reducers: {
    setLastSyncedAt: (state, action: PayloadAction<number>) => {
      state.lastSyncedAt = action.payload;
    },
    setUserStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    setFoldersLength: (state, action: PayloadAction<number>) => {
      state.foldersLength = action.payload;
    },
    setOnDemandSync: (state, action: PayloadAction<boolean>) => {
      state.isOnDemandSyncActive = action.payload;
    },
    setOnClientSync: (state, action: PayloadAction<boolean>) => {
      state.isOnClientSyncActive = action.payload;
    },
    setLoadingClient: (state, action: PayloadAction<boolean>) => {
      state.isLoadingClient = action.payload;
    },
    setStopSyncing: (state, action: PayloadAction<boolean>) => {
      state.stopSyncing = action.payload;
    },
    setFolders: (state, action: PayloadAction<Folder[]>) => {
      state.folders = action.payload;
    },
    setInitialFolders: (state, action: PayloadAction<Folder[]>) => {
      state.initialFolders = action.payload;
    },
    setTelegramFolders: (state, action: PayloadAction<UpsertFolderInput[]>) => {
      state.telegramFolders = action.payload;
    },
    setConversationsSynced: (state, action: PayloadAction<number>) => {
      state.conversationsSynced = action.payload;
    },
    updateFolder: (state, action: PayloadAction<Folder>) => {
      const index = state.folders.findIndex((folder) => folder.oid.oid === action.payload.oid.oid);
      if (index > -1) {
        state.folders[index] = action.payload;
      }
    },
  },
});

export const {
  setUserStatus,
  setLastSyncedAt,
  setOnDemandSync,
  setOnClientSync,
  setFolders,
  setInitialFolders,
  updateFolder,
  setLoadingClient,
  setStopSyncing,
  setFoldersLength,
  setTelegramFolders,
  setConversationsSynced,
} = userTgSlice.actions;

export const userTgState = (state: RootState) => state.userTg;

export default userTgSlice.reducer;
