import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useDebounce } from 'react-use';
import AnalyticKeys, { trackAction } from 'services/utils/analytics';
import { FollowupPages } from 'components/Chats/ChatTableV2/interface';
import Icons from 'assets/icons';
import { ApolloQueryResult } from '@apollo/client/core/types';
import {
  useUpdateFollowupMutation,
  useAddFollowupMutation,
  useAddToFavouriteMutation,
  ChatsPageCoversationsDocument,
  useRemoveFromFavouriteMutation,
  useAssignFollowupMutation,
  SystemTagTypeId,
} from 'services/models/api/generated';
import * as domain from 'services/models/domain/conversation';
import { EntityType } from 'services/models/domain/entityTypes';
import { useDispatch, useSelector } from 'react-redux';
import { updateConversation } from 'services/store/slices/conversationsSlice';
import { setEntity, sidebarEntityDetailsState } from 'services/store/slices/sidebarEntityDetailsSlice';
import { detailsState, setDetailsEntity } from 'services/store/slices/detailsSlice';
import { OrganizationalUser } from 'services/models/domain/user';
import { RootState } from 'services/store/AppStore';
import Translations from 'const/translations/en';

export enum FollowupActionTypes {
  New = 'NEW',
  Reset = 'RESET',
  Done = 'DONE',
}

export type FollowupAction = {
  name: FollowupActionTypes;
  title: string;
  icon: (typeof Icons)[keyof typeof Icons];
};

export function useChatActions(
  conversation: domain.Conversation | undefined,
  refetch?: (variables?: Partial<any>) => Promise<ApolloQueryResult<any>>
) {
  const dispatch = useDispatch();
  const { entityOid: sidebarEntityOid } = useSelector(sidebarEntityDetailsState);
  const { detailsEntityOid } = useSelector(detailsState);
  const user = useSelector((state: RootState) => state.auth.user);

  const utcTimeStampAsString = conversation?.nextFollowup?.dueAt?.utcTimeStampAsString;
  const tempDate = utcTimeStampAsString ? dayjs(utcTimeStampAsString) : null;
  const [date, setDate] = useState<Dayjs | null | undefined>(tempDate);

  const [assignee, setAssignee] = useState<OrganizationalUser | undefined>(conversation?.nextFollowup?.assignedToUser);

  const sidebarHasEntity = conversation?.oid.oid === sidebarEntityOid;
  const detailsPageHasEntity = conversation?.oid.oid === detailsEntityOid;

  useDebounce(
    async () => {
      if (utcTimeStampAsString) {
        setDate(dayjs(utcTimeStampAsString));
      } else {
        setDate(null);
      }

      setAssignee(conversation?.nextFollowup?.assignedToUser);
    },
    200,
    [utcTimeStampAsString, conversation]
  );

  //update followup mutation
  const [updateFollowup, { loading: updateStateLoading }] = useUpdateFollowupMutation({
    onCompleted: async () => {
      refetch && (await refetch());
    },
  });

  //add followup mutation
  const [addFollowup, { loading: addStateLoading }] = useAddFollowupMutation({
    onCompleted: async () => {
      trackAction(AnalyticKeys.ADDED_NEW_FOLLOWUP, {
        organizationId: user?.actingAsMemberOfOrganization.oid,
        organizationName: user?.actingAsMemberOfOrganization.name,
        chatId: conversation?.oid.oid,
        chatName: conversation?.name,
      });

      refetch && (await refetch());
    },
  });

  //add fav mutation
  const [addFav] = useAddToFavouriteMutation({
    refetchQueries: [
      { query: ChatsPageCoversationsDocument }, // DocumentNode object parsed with gql
      'ChatsPage', // Query name
    ],
    onCompleted: async () => {
      refetch && (await refetch());
    },
  });

  //remove fav mutation
  const [removeFav] = useRemoveFromFavouriteMutation({
    refetchQueries: [
      { query: ChatsPageCoversationsDocument }, // DocumentNode object parsed with gql
      'ChatsPage', // Query name
    ],
    onCompleted: async () => {
      refetch && (await refetch());
    },
  });

  //assign followup mutation
  const [assignFollowUp, { loading: assignFollowupLoading }] = useAssignFollowupMutation({
    onCompleted: async () => {
      trackAction(AnalyticKeys.REASSIGNED_FOLLOWUP, {
        organizationId: user?.actingAsMemberOfOrganization.oid,
        organizationName: user?.actingAsMemberOfOrganization.name,
        chatId: conversation?.oid.oid,
        chatName: conversation?.name,
      });

      refetch && (await refetch());
    },
  });

  //handle delete followup ~mark done/clear followup
  const handleDeleteFollowUp = async () => {
    await updateFollowup({
      variables: {
        input: {
          completed: true,
          newDate: {
            utcTimeStampAsString: date?.toDate().toISOString() ?? '',
          },
          oid: conversation?.nextFollowup?.oid || '',
        },
      },
    })
      .then(() => {
        setAssignee(undefined);
        setDate(null);
        trackAction(AnalyticKeys.CLEARED_FOLLOWUP, {
          organizationId: user?.actingAsMemberOfOrganization.oid,
          organizationName: user?.actingAsMemberOfOrganization.name,
          chatId: conversation?.oid.oid,
          chatName: conversation?.name,
        });
      })
      .catch((err) => console.log(err, 'delete followup'));
  };

  //handle remove fav
  const handleRemoveFav = async () => {
    dispatch(
      updateConversation({
        oid: conversation?.oid || { oid: '' },
        favorite: false,
      })
    );
    if (sidebarHasEntity) {
      const updatedConversation = { ...conversation, favorit: false } as domain.Conversation;
      dispatch(setEntity(updatedConversation));
    }
    if (detailsPageHasEntity) {
      const updatedConversation = { ...conversation, favorit: false } as domain.Conversation;
      dispatch(setDetailsEntity(updatedConversation));
    }
    await removeFav({
      variables: {
        input: {
          linkTo: {
            entityTypeId: EntityType.ConversationRecord,
            oid: conversation?.conversationRecordOid.oid || '',
          },
          systemTagTypeId: SystemTagTypeId.Favorite,
          userTagTypeId: '',
        },
      },
    }).then(async (res) => {
      if (res.data?.removeTagFromEntity.status.isSuccess) {
        trackAction(AnalyticKeys.UNFAVORITED_CHAT, {
          organizationId: user?.actingAsMemberOfOrganization.oid,
          organizationName: user?.actingAsMemberOfOrganization.name,
          chatId: conversation?.oid.oid,
          chatName: conversation?.name,
        });
      }
    });
  };

  //handle add fav
  const handleAddFav = async () => {
    dispatch(
      updateConversation({
        oid: conversation?.oid || { oid: '' },
        favorite: true,
      })
    );
    if (sidebarHasEntity) {
      const updatedConversation = { ...conversation, favorit: true } as domain.Conversation;
      dispatch(setEntity(updatedConversation));
    }
    if (detailsPageHasEntity) {
      const updatedConversation = { ...conversation, favorit: true } as domain.Conversation;
      dispatch(setDetailsEntity(updatedConversation));
    }
    await addFav({
      variables: {
        input: {
          linkTo: {
            entityTypeId: EntityType.ConversationRecord,
            oid: conversation?.conversationRecordOid.oid || '',
          },
          systemTagTypeId: SystemTagTypeId.Favorite,
          userTagTypeId: '',
        },
      },
    }).then(async (res) => {
      if (res.data?.addTagToEntity.status.isSuccess) {
        trackAction(AnalyticKeys.FAVORITED_CHAT, {
          organizationId: user?.actingAsMemberOfOrganization.oid,
          organizationName: user?.actingAsMemberOfOrganization.name,
          chatId: conversation?.oid.oid,
          chatName: conversation?.name,
        });
      }
    });
  };

  //handle open chat link
  const handleOpenChatLink = () => {
    if (conversation?.chatLink != null) {
      window.open(conversation?.chatLink, '_blank');
      trackAction(
        conversation?.userIsParticipant ? AnalyticKeys.OPENED_CHAT_LINK : AnalyticKeys.OPENED_CHAT_INVITATION_LINK,
        {
          organizationId: user?.actingAsMemberOfOrganization.oid,
          organizationName: user?.actingAsMemberOfOrganization.name,
          chatId: conversation?.oid.oid,
          chatName: conversation?.name,
          channel: 'Telegram',
        }
      );
    }
  };

  // followup modal popup section //
  const ORG_ACTIONS: FollowupAction[] = [
    {
      name: FollowupActionTypes.Done,
      icon: Icons.ic_close,
      title: Translations.FOLLOWUP_ACTIONS_DONE,
    },
    {
      name: FollowupActionTypes.Reset,
      icon: Icons.ic_calendar_reset,
      title: Translations.FOLLOWUP_ACTIONS_RESET,
    },
    {
      name: FollowupActionTypes.New,
      icon: Icons.ic_calendar_plus,
      title: Translations.FOLLOWUP_ACTIONS_SET,
    },
  ];

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);

  //hanlde open followup popup
  const handlePopUpOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  //hanlde close followup popup
  const handlePopUpClose = () => {
    setAnchorEl(null);
  };

  //hanlde page change for followup modal
  const [page, setPage] = useState<FollowupPages>(FollowupPages.initPage);

  return {
    date,
    handleDeleteFollowUp,
    handleRemoveFav,
    handleAddFav,
    handleOpenChatLink,
    assignee,
    setAssignee,
    open,
    handlePopUpOpen,
    handlePopUpClose,
    anchorEl,
    ORG_ACTIONS,
    user,
    page,
    setPage,
    setDate,
    addStateLoading,
    updateStateLoading,
    assignFollowupLoading,
  };
}
