import { Typography, Box } from '@mui/joy';
import { TimeAgo } from 'services/utils/helpers';
import { CellText, CellSecondText } from 'components/Chats/ChatTableV2/TableCell/customCells/cellsContent/styles';
export interface LastModifiedCellContentProps {
  lastModifiedTime: string;
  lastModifiedByUserName?: string;
}
export default function LastModifiedCellContent({
  lastModifiedTime,
  lastModifiedByUserName,
}: LastModifiedCellContentProps) {
  return (
    <Box>
      {lastModifiedByUserName ? (
        <>
          <Typography level="tiny" sx={CellText}>
            {lastModifiedByUserName}
          </Typography>
          <Typography level="tiny" sx={CellSecondText}>
            {lastModifiedTime && TimeAgo({ toDate: lastModifiedTime })}
          </Typography>
        </>
      ) : (
        <>
          <Typography level="tiny" sx={CellText}>
            {lastModifiedTime && TimeAgo({ toDate: lastModifiedTime })?.split(',')[1]}
          </Typography>
          <Typography level="tiny" sx={CellSecondText}>
            {lastModifiedTime ? TimeAgo({ toDate: lastModifiedTime }).split(',')[0].trimStart() : '_'}
          </Typography>
        </>
      )}
    </Box>
  );
}
