import { IconsSvg } from 'assets/icons';
import { EntityViewField } from 'services/models/domain/entityViews';

export const mapEntityFieldToIcon = (field: EntityViewField) => {
  let icon: keyof typeof IconsSvg;
  switch (field) {
    case EntityViewField.DateAdded:
      icon = 'ic_calendar_1';
      break;
    case EntityViewField.LatestMessage:
      icon = 'ic_today';
      break;
    case EntityViewField.FollowUp:
      icon = 'ic_notification';
      break;
    case EntityViewField.Folder:
      icon = 'ic_folders';
      break;
    case EntityViewField.Note:
      icon = 'ic_abc';
      break;
    case EntityViewField.LastModified:
      icon = 'ic_clock_edit';
      break;
    default:
      icon = 'ic_duplicate';
  }
  return icon;
};
