import { useSelector } from 'react-redux';
import {
  EntityPropertyDefinition,
  OptionPropertyValue,
  PropertyDefinitionDataType,
  TenantUserPropertyValue,
  ValuePropertyValue,
} from 'services/models/domain/entityViews';
import { Conversation } from 'services/models/domain/conversation';
import { entityViewsState } from 'services/store/slices/entityViewsSlice';
import { Box, Stack, Typography } from '@mui/joy';
import PropertyTypeAheadWithBackground from 'components/Chats/ChatTableV2/TableCell/customCells/PropertyTypeahead/PropertyTypeAheadWithBackground';
import DetailsCard from 'components/Details/DetailsCard';
import ParticipantPlaceholder from 'page/details/placeholder/ParticipantPlaceholder';
import Translations from 'const/translations/en';
import { ValueProperty } from 'components/Chats/ChatTableV2/TableCell/customCells/ValueProperty/ValueProperty';
import { colors } from 'theme/colors';
import ColumnVisibilityControl from 'components/Chats/ChatTableV2/TableHeader/ColumnVisibilityControl/ColumnVisibilityControl';

type OptionOrTenantPRopertyValue = OptionPropertyValue | TenantUserPropertyValue;

interface PropertiesDetailsCardProps {
  conversation: Conversation;
  loading: boolean;
}

export default function PropertiesDetailsCard({ conversation, loading }: PropertiesDetailsCardProps) {
  const { entityViews } = useSelector(entityViewsState);
  const detailPropertyDefinitions = (
    entityViews
      ? entityViews.allVisiblePropertyDefinitionOids
          .map((oid) => entityViews.propertyDefinitions.find((pd) => pd.oid.oid === oid))
          .filter((pd) => !!pd)
      : []
  ) as EntityPropertyDefinition[];

  const renderProperties = () => {
    if (!entityViews || entityViews.views.length === 0) {
      return <></>;
    }
    return (
      <Stack direction="row" sx={style.propertiesContainer}>
        {detailPropertyDefinitions.map((pd: EntityPropertyDefinition) => renderProperty(pd))}
      </Stack>
    );
  };

  const renderProperty = (pd: EntityPropertyDefinition) => {
    let value = '';
    let values: string | string[] = [];
    const selectedValues = conversation.properties?.filter((pv) => pv.propertyDefinitionOid.oid === pd.oid.oid);

    if (pd.dataType === PropertyDefinitionDataType.Value && selectedValues.length) {
      value = (selectedValues[0] as ValuePropertyValue).value;
    } else {
      values = (selectedValues as OptionOrTenantPRopertyValue[]).map((v) => v.oid.oid);
    }

    return (
      <Stack direction="row" sx={style.container} key={pd.oid.oid}>
        {pd.dataType === PropertyDefinitionDataType.Value ? (
          <Box sx={style.valuePropertyContainer}>
            <Typography level="tiny" sx={style.title}>
              {pd.name}
            </Typography>
            <Box sx={style.inputContainer}>
              <ValueProperty
                propertyDefinition={pd}
                propertyValue={{
                  propertyDefinitionOid: pd.oid,
                  type: PropertyDefinitionDataType.Value,
                  value: value,
                }}
                entityId={conversation.oid.oid}
                sx={style.valuePropertyInput}
              />
            </Box>
          </Box>
        ) : (
          <PropertyTypeAheadWithBackground propertyDefinition={pd} values={values} entityId={conversation.oid.oid} />
        )}
      </Stack>
    );
  };

  return (
    <DetailsCard title={Translations.PROPERTIES} headerChildren={<ColumnVisibilityControl sx={{ height: 'auto' }} />}>
      {loading && <ParticipantPlaceholder />}
      {!loading && conversation && entityViews.views.length > 0 && renderProperties()}
    </DetailsCard>
  );
}

const style = {
  propertiesContainer: {
    maxHeight: '380px',

    flexWrap: 'wrap',
    gap: '8px 16px',
    overflowY: 'auto',
    scrollbarGutter: 'stable both-edges',
    padding: '8px 12px',
  },
  container: {
    width: 'calc(50% - 8px)',
  },
  valuePropertyContainer: {
    width: '100%',
  },
  title: {
    color: colors.neutral[70],
    marginY: '4px',
  },
  inputContainer: {
    height: '34px',
    fontSize: '14px',
    borderRadius: '6px',
    border: `1px solid ${colors.neutral[15]}`,
    backgroundColor: colors.neutral[5],
  },
  valuePropertyInput: {
    height: '34px',
    marginTop: '-1px',
    borderRadius: '6px',
  },
};
