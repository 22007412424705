import { Divider } from '@mui/joy';
import CellMenuButton from 'components/ui-library/chat/CellMenu/СellMenuButton/CellMenuButton';
import SortOptions from 'components/Chats/ChatTableV2/TableHeader/SortOptions/SortOptions';
import { DividerSx } from 'components/Chats/ChatTableV2/TableHeader/styles';
import Translations from 'const/translations/en';
import { PropertyDefinitionDataType } from 'services/models/domain/entityViews';
import { colors } from 'theme/colors';

export interface HeaderMenuProps {
  field: string;
  isSortingActive: boolean;
  sortable?: boolean;
  hideable?: boolean;
  propertyType?: PropertyDefinitionDataType;
  propertyOid?: string;
  isCustomProperty?: boolean;
  handleMenuClose: () => void;
  onHide: () => void;
  onRename: () => void;
  onEditValues: () => void;
  onDelete: () => void;
}

const HeaderMenu = ({
  isCustomProperty,
  hideable,
  onHide,
  onRename,
  onEditValues,
  onDelete,
  sortable,
  field,
  handleMenuClose,
  isSortingActive,
  propertyType,
  propertyOid,
}: HeaderMenuProps) => {
  const isPropertyOption = propertyType && propertyType === PropertyDefinitionDataType.Option;

  return (
    <>
      {hideable && (
        <CellMenuButton title={Translations.TABLE_HEADER_OPTION_HIDE} icon="ic_hide_column" onClick={onHide} />
      )}

      {sortable && (
        <>
          <Divider sx={DividerSx} />
          <SortOptions
            sortFieldName={field}
            closeMenu={handleMenuClose}
            isSortingActive={isSortingActive}
            isCustomProperty={!!isCustomProperty}
            propertyOid={propertyOid}
          />
        </>
      )}

      {(isCustomProperty || isPropertyOption) && <Divider sx={DividerSx} />}
      {isPropertyOption && (
        <>
          <CellMenuButton
            title={Translations.TABLE_HEADER_OPTIONS_EDIT_VALUE}
            icon="ic_edit_column"
            onClick={onEditValues}
          />
        </>
      )}
      {isCustomProperty && (
        <>
          <CellMenuButton title={Translations.TABLE_HEADER_OPTIONS_EDIT_NAME} icon="ic_edit" onClick={onRename} />
          <CellMenuButton
            title={Translations.TABLE_HEADER_OPTIONS_DELETE}
            icon="ic_trash"
            onClick={onDelete}
            sx={{
              color: colors.warning,
              '& .JoyBox-root': {
                '&:hover': {
                  backgroundColor: `${colors.warning}0D`,
                },
              },
              '& svg': { stroke: `${colors.warning}` },
              '&:hover': {
                color: colors.warning,
                '& svg': { stroke: `${colors.warning}` },
              },
            }}
          />
        </>
      )}
    </>
  );
};

export default HeaderMenu;
