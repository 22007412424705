import { DataGridPro } from '@mui/x-data-grid-pro';
import { styled } from '@mui/joy';
import { colors } from 'theme/colors';
import { fonts } from 'theme/fonts';

export const StyledDataGrid = styled(DataGridPro)(() => ({
  fontFamily: fonts.regular,
  border: '1px solid',
  height: '100%',
  borderColor: colors.neutral[5],
  '& .MuiDataGrid-withBorderColor': {
    borderColor: colors.neutral[5],
  },
  '& .MuiDataGrid-columnHeaders': {
    fontSize: 14,
    color: colors.neutral[50],
  },
  '& .MuiDataGrid-row': {
    '&:hover': {
      background: 'transparent',
    },
  },
  '& .MuiDataGrid-cell': {
    padding: '6px 16px',
    fontSize: 14,
    outline: 'none !important',
    // flexWrap: 'wrap',
    color: colors.neutral[80],
    '&:focus': {
      outline: 'none',
    },
  },
  '& .MuiDataGrid-columnHeader': {
    padding: '0px 16px',
    '&:focus': {
      outline: 'none',
    },
    '&:focus-within': {
      outline: 'none',
    },
  },
  '& .MuiDataGrid-pinnedColumnHeaders--left': {
    boxShadow: 'none',
  },
  '& .MuiDataGrid-pinnedColumns--left': {
    boxShadow: 'none',
  },
  '& .sticky-column-border': {
    borderRight: `2px solid ${colors.neutral[5]}`,
  },
  '& .no-right-border': {
    borderRight: 'none',
  },
  '& .Mui-hovered': {
    backgroundColor: 'unset !important',
  },
  '& .Mui-selected': {
    backgroundColor: `${colors.primary[0]} !important`,
  },
}));
