import { avatarFromName } from 'services/utils/helpers';

export const getUsersInitials = (username?: string, firstName?: string) => {
  if (username) {
    return avatarFromName(username);
  }
  if (firstName) {
    return avatarFromName(firstName);
  }

  return '';
};
