import React, { useEffect, useState } from 'react';
import { GridCellProps, useGridApiContext } from '@mui/x-data-grid-pro';
import { StyledFavoriteCell } from 'components/Chats/ChatTableV2/TableCell/customCells/styles';
import { Checkbox } from '@mui/joy';
import { CheckboxSx } from 'components/FilterCheckList/style';

export default function MultiSelectCell(props: GridCellProps) {
  const { value, rowId, ...rest } = props;
  const [checked, setChecked] = useState(value);
  const apiRef = useGridApiContext();

  useEffect(() => {
    const isSelected = apiRef.current.getSelectedRows().has(rowId);
    setChecked(isSelected);
  }, [apiRef.current.getSelectedRows(), rowId]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = event.target.checked;
    if (newChecked) {
      apiRef.current.selectRow(rowId, true, false);
    } else {
      apiRef.current.selectRow(rowId, false, false);
    }
  };

  return (
    <StyledFavoriteCell {...rest}>
      <Checkbox sx={CheckboxSx(checked, false)} checked={checked} onChange={handleChange} />
    </StyledFavoriteCell>
  );
}
