enum Translations {
  SUCCESS = 'Changes saved successfully',
  UPLOAD_ERROR = 'Save changes failed!',
  IMAGE_DELETED = 'Avatar image deleted',
  UNSAVED_CHANGES = 'Unsaved changes',
  CHAT_PAGE_TITLE = '3RM - Chats',
  PAGE_TITLE_SIGN_UP = '3RM - Sign Up',
  PAGE_TITLE_DETAILS = 'Details',
  NO_CHATS_MESSAGE = 'Your organization doesn’t have any tracked chats yet.',
  NO_CHATS_BY_FILTER = 'No chats match the selected filter',
  CLEAR_FILTERS = 'Clear filters',
  PARTICIPANT_CHIP_TEXT = 'I’m a participant',
  LOADING_REPORTS_TEXT = 'Loading...(It can take up to 15 seconds)',
  FAVORITE_CHIP_TEXT = 'Favorites',
  CHATS = 'Chats',
  TABLE_HEADER_OPTION_HIDE = 'Hide column',
  TABLE_HEADER_SORT_ASC = 'Sort ascending',
  TABLE_HEADER_SORT_DES = 'Sort descending',
  TABLE_HEADER_OPTIONS_EDIT_VALUE = 'Edit property values',
  TABLE_HEADER_OPTIONS_EDIT_NAME = 'Edit property name',
  TABLE_HEADER_OPTIONS_DELETE = 'Delete property',
  CHAT_TABLE_PROPERTY_EDIT = 'Edit Property',
  CHAT_TABLE_PROPERTY_EDIT_BACK = 'Customize',
  CHAT_TABLE_PROPERTY_REMOVE_SORT = 'Remove sorting',
  CHAT_TABLE_PROPERTY_ADD = 'Add',
  CHAT_TABLE_PROPERTY_DELETE = 'Delete',
  CHAT_TABLE_PROPERTY_CANCEL = 'Cancel',
  CHAT_TABLE_PROPERTY_SAVE = 'Save',
  CHAT_STATUS_PROPERTY_EDIT = 'Edit Status property',
  CHAT_TABLE_COLUMN_HIDE = 'Hide Column',
  CHAT_TABLE_CREATE_PROPERTY = 'Create new property',
  CHAT_TABLE_PROPERTY_NAME = 'Property name',
  CHAT_TABLE_PROPERTY_DELETE_PROPERTY = 'Delete property',
  DELETE_PROPERTY_MODAL_TITLE = 'Delete Property',
  DELETE_PROPERTY_MODAL_MESSAGE = 'Are you sure you’d like to delete this property? All of this property’s data will be permanently deleted.',
  DELETE_PROPERTY_MODAL_BUTTON = 'Confirm Deletion',
  PROPERTY_DELETED = 'Property deleted',
  TYPE = 'Type',
  NAME = 'Name',
  ME = 'Me',
  CONTACTS = 'Contacts',
  NO_CONTACTS = 'You dont have any contacts yet',
  SYNC_TELEGRAM_CHATS = 'Sync your telegram chats',
  NO_CONTACTS_MESSAGE = ' to automatically create contacts',
  STATUS_CHIP_TEXT = 'Status',
  OWNER_CHIP_TEXT = 'Owner',
  CATEGORIES_CHIP_TEXT = 'Categories',
  TEXT_CHIP_TEXT = 'Text',
  TEAMMATES_CHIP_TEXT = 'Teammates',
  SINGLE_SELECT_CHIP_TEXT = 'Single-select',
  MULTI_SELECT_CHIP_TEXT = 'Multi-select',
  NO_TELEGRAM_FOLDERS = 'No Telegram folders detected.',
  NO_TELEGRAM_FOLDERS_INSTRUCTION = `1. Open Telegram on your phone or computer
  2. Go to Settings > Chat Folders
  3. Click “Add a custom folder”
  4. Include chats: “Groups”`,
  SYNCING = 'Syncing',
  STOP_SYNCING = 'Stop sync',
  CONFIRM_SYNC_CLIENT_SIDE = 'Confirm sync',
  CONFIRM_SYNC = 'Confirm sync (est: 15 mins)',
  CHATS_SEARCH_PLACEHOLDER = 'Search by chat name',
  CONTACTS_SEARCH_PLACEHOLDER = 'Search by name, Telegram handle or bio',
  SEARCHBAR_WIN_COMMAND = 'Ctrl+K',
  SEARCHBAR_MAC_COMMAND = '⌘K',
  AUTHENTICATION_FORM_STATUS_NOT_ASSOCIATED = 'You are not assocated with an organization',
  AUTHENTICATION_FORM_STATUS_EMAIL_NOT_FOUND = 'Please sign up with your work email',
  AUTHENTICATION_FORM_STATUS_ERROR = 'Something went wrong. Please try again.',
  AUTHENTICATION_FORM_STATUS_EMAIL_REQUIRED = 'Please enter your email',
  AUTHENTICATION_FORM_STATUS_USERNAME_REQUIRED = 'Please enter your name',
  AUTHENTICATION_FORM_STATUS_ORGANIZATION_NAME_REQUIRED = 'Please enter an organization name',
  AUTHENTICATION_FORM_STATUS_PASSWORD_REQUIRED = 'Please enter your password',
  AUTHENTICATION_FORM_STATUS_INVALID_EMAIL = 'Hmm… that email doesn’t look valid',
  AUTHENTICATION_FORM_STATUS_INVALID_ORGANIZATION_NAME_SIZE = 'Organization name must be at least 1 character',
  AUTHENTICATION_FORM_STATUS_INVALID_PASSWORD_SIZE = 'Passwords must have at least 10 character',
  AUTHENTICATION_FORM_STATUS_NAME_PASSWORD_DONT_MATCH = 'The username and password don’t match. Please try again.',
  AUTHENTICATION_FORM_STATUS_ORGANIZATION_EXIST = 'Organization name or website URL already exists',
  DASHBOARD = 'Reports',
  ERROR_DASHBOARD = 'Error loading dashboard, please refresh to try again.',
  NO_RESULTS = 'No result found',
  RESULTS_PER_PAGE = 'Results per page',
  REGISTER_ORG = 'Create a new organization',
  CREATE_ORG = 'Create organization',
  ORG_REGISTRATION_MESSAGE = 'Your teammates can join your teamspace by signing up with their work email',
  REGISTRATION_HEADER_TITLE = 'We found your team!',
  REGISTRATION_HEADER_MESSAGE_ONE = 'Join your teammates in',
  REGISTRATION_HEADER_MESSAGE_TWO = '’s workspace. Share Telegram chats, create pipelines, and more – together.',
  USER_REGISTRATION_MESSAGE = 'Join your teammates in',
  USER_REGISTATION_CREATE_YOUR_ACCOUNT = 'Create your account',
  USER_REGISTRATION_SIGN_UP_METHOD = 'How do you want to sign up?',
  USER_REGISTRATION_SIGN_IN_METHOD = 'How do you want to sign in?',
  USER_REGISTRATION_ORG_CREATED = 'Organization created successfully!',
  USER_REGISTRATION_ORG_NAME = 'Organization name',
  USER_REGISTRATION_ORG_PLACEHOLDER = 'Enter your organization name',
  USER_REGISTER_SENT_LINK_TO = '*We sent the magic link to',
  USER_REGISTER_SENT_LINK_TO_2 = 'change',
  ERROR_CONTACT_SUPPORT = 'Something went wrong, contact support',
  YOUR_NAME_SET = 'Your name is set!',
  IMAGE_UPLOAD_SERVER_ERROR = 'Error uploading image to the server',
  YOUR_WORK_EMAIL = 'Your work email',
  YOUR_NAME = 'Your name',
  PASSWORD = 'Password',
  PLACEHOLDER_DISPLAY_NAME = 'Display name',
  SET_PASSWORD = 'Set a password',
  MIN_TEN_CHARS = 'Minimum of 10 characters',
  SETUP_TITLE_BE_CALLED = 'What do you want to be called?',
  SETUP_MESSAGE = 'This helps your teammates set you follow-up reminders and mention you in notes.',
  LATEST_MESSAGE = 'Latest Message',
  TABLE = 'Table',
  PIPELINE = 'Pipeline',
  NO_ACCOUNT = "Don't have an account?",
  SIGN_UP = 'Sign up',
  WELCOME_BACK_TITLE = 'Welcome back',
  USER_REGISTRATION_WELCOME_MESSAGE = 'Join your teammates in',
  USER_REGISTRATION_WELCOME_MESSAGE_2 = '’s workspace. Share Telegram chats, create pipelines, and more – together.',
  INVITE_TEAM_MESSAGE = 'Send this link to your team so they can join your team’s workspace.',
  INVITE_TEAM_MESSAGE_2 = 'They must use their',
  INVITE_TEAM_MESSAGE_3 = 'email',
  INVITE_TEAM_WARNING = 'Keep in mind, they’ll be able to see the chats you’ve added.',
  INVITE_LINK_COPIED = 'Invite link copied to clipboard',
  COPIED_TO_CLIPBOARD = 'Copied to clipboard',
  ERROR_CONTACTS = 'We’re sorry, there was an issue loading your contacts.',
  ERROR_CONTACTS_MESSAGE = 'Please try again later, and if the issue persists, contact us on our support chat (bottom-right of this window) or at',
  NO_SEARCH_RESULTS = 'No search results',
  CLEAR_SEARCH = 'Clear search',
  CHROM_EXTENSION_LOGIN_SUCCESS = 'You’re logged in to 3RM’s Chrome Extension!',
  CHROM_EXTENSION_LOGIN_FAIL = 'Something went wrong while logging in to the 3RM’s Chrome Extension.',
  CHROME_EXTENSION_LOGIN_DESC = 'Telegram never felt so good',
  ONBOARDING_WELCOME = 'Welcome to 3RM',
  ONBOARDING_WELCOME_MESSAGE = 'Enter your work email to create a new teamspace or to join an existing one.',
  ONBOARDING_MESSAGE = 'Connect your Telegram account to start adding chats to your team’s workspace.',
  ONBOARDING_INSTRUCTION_1 = '1. Open Telegram on your phone',
  ONBOARDING_INSTRUCTION_2 = '2. Go to Settings > Devices > Link Desktop Device',
  ONBOARDING_INSTRUCTION_3 = '3. Scan this image to connect',
  ONBOARDING_SECURITY = 'Learn about how we protect your security and privacy',
  TELEGRAM_SECURITY = 'To preserve your privacy and security, we encrypt your Telegram session token and only bring in chat metadata like the name, participants, and last message time, not the message contents.',
  LOGIN_SEND_EMAIL_FOR_SIGNIN = 'We’ll email you a magic link for a password-free sign in. Alternatively, you can',
  LOGIN_SIGN_UP_MANUALLY_INSTEAD = 'sign up with a password',
  LOGIN_SIGN_IN_MANUALLY_INSTEAD = 'sign in with a password',
  SIGN_IN_WITH_EMIAL = 'Sign in with email',
  REGISTER_SEND_EMAIL_FOR_SIGNIN = 'We’ll email you a magic link for a password-free sign up. Alternatively, you can',
  REGISTER_SIGN_UP_MANUALLY_INSTEAD = 'sign up manually instead',
  SIGN_UP_WITH_EMAIL = 'Sign up with email',
  TC_BLOCK_BY_SIGNING = 'By signing up, you agree to our',
  TC_BLOCK_AND = 'and',
  PRIVACY_POLICY = 'Privacy Policy',
  TERMS_OF_SERVICE = 'Terms of Service',
  MANUAL_SIGN_PRIMARY_MESSAGE = 'Alternatively,',
  MANUAL_SIGN_IN_PASSWORD_FREE = 'sign in password-free',
  MANUAL_SIGN_UP_PASSWORD_FREE = 'sign up password-free',
  MANUAL_SIGN_SECONDARY_MESSAGE = 'via a magic link',
  LEARN_MORE = 'Learn more.',
  TELEGRAM_ENTER_2FA_CODE = 'Please enter your Telegram two-step verification password:',
  TELEGRAM_SUMMARY = "Get a summary of your day's follow-ups, delivered to your Telegram inbox every morning at 9 AM",
  TELEGRAM_MESSAGE = 'The fastest way to add chats to your 3RM teamspace.',
  TELEGRAM_2FA_MESSAGE = 'Please confirm your Telegram 2FA password:',
  TELEGRAM_LOGIN_CODE = 'Code (check your Telegram inbox)',
  TELEGRAM_ALMOST_THERE = "You're almost there...",
  TELEGRAM_FOLDER_SYNC = 'Folder Sync',
  TG_MODAL_TEST_BOT_CONNECTION = 'Test Bot Connection',
  TG_MODAL_TEST_BOT_CONNECTION_SUCCESS = 'Connected',
  TG_MODAL_TEST_BOT_CONNECTION_ERROR = 'Unable to connect',
  TG_MODAL_TEST_BOT_BOT_INFO = '3RM Notifications (@Official_3RM_Bot)',
  TG_MODAL_TEST_BOT_ERROR_MANUAL_CONNECTION = 'Looks like a connection could’t be automatically established. Please try connecting the bot by clicking “Start” at ',
  TG_MODAL_TEST_BOT_ERROR_MANUAL_CONNECTION_CTA = 'this link',
  TG_MODAL_TEST_BOT_ERROR_CONTACT_INFO = 'If you’re still not able to connect, please get in touch with our support team at',
  TG_BOT_TEST_MESSAGE = 'Your 3RM account is connected. You’ll receive 3RM notifications like follow-up reminders here.',
  BOT_CONNECTED = 'Bot connected!',
  BOT_NOT_CONNECTED = 'Bot not connected',
  PHONE_NUMBER_CONNECT = 'Connect using your phone number',
  QR_CODE_CONNECT = 'Connect by scanning a QR code',
  CONNECT = 'Connect Telegram with 3RM',
  TELEGRAM = 'Telegram',
  BIO = 'Bio',
  TELEGRAM_2FA_HELPER = 'Two-Step Verification Password',
  TELEGRAM_2FA_HELPER_MESSAGE = 'This is a password you entered into Telegram. If you need a hint, you can open the Telegram app and go to Settings > Privacy & Security > Two-Step Verification.',
  CLICK_HERE = 'click here',
  NOTES = 'Notes',
  NOTES_PLACEHOLDER = 'Write your note here...',
  NOTES_LAST_EDITED = 'Last edited:',
  SNACKBAR_NOTE_DELETED = 'Note Deleted',
  LINK_COPIED = 'Link copied to clipboard',
  COPY_CONTACT_LINK = 'Copy contact link',
  COPY_CHAT_LINK = 'Copy chat link',
  OPEN_TELEGRAM_TOOLTIP = 'Open in Telegram',
  OPEN_SIDEBAR_TOOLTIP = 'Open in Sidebar',
  TOOLTIP_OPEN_LINK = 'Open the link',
  NO_CONTACT_FOUND = 'No contact found',
  CHAT_NOT_FOUND = 'Chat not found',
  FOLDERS = 'Folders',
  FOLDER = 'Folder',
  SELECT_ALL = 'Select All',
  CLEAR = 'Clear',
  CONFIG = 'Config',
  RECORD = 'Records',
  COMM = 'Comms',
  VIEWS = 'Smart Lists',
  NO_CHAT_LINK_TOOLTIP_PRIMARY_MESSAGE = '😢  No Telegram chat link available',
  NO_CHAT_LINK_TOOLTIP_SECONDARY_MESSAGE = 'This could be due to the chat’s privacy settings.',
  NO_CONTACT_LINK_TOOLTIP_PRIMARY_MESSAGE = '😢  No Telegram chat link available',
  NO_CONTACT_LINK_TOOLTIP_SECONDARY_MESSAGE = 'This could be due to the user’s privacy settings.',
  FOLLOW_UPS = 'Follow-Ups',
  FOLLOW_UPS_DUE = 'Follow-Ups Due',
  FOLLOW_UPS_ASSIGNEES_LIST_TITLE = 'Assigned to',
  FOLLOW_UPS_BUTTON_SCHEDULE_REMINDER = 'Schedule follow-up reminder for %s',
  FOLLOW_UPS_BUTTON_SELECT_PERSON_AND_DATE = 'Select a person and date',
  FOLLOW_UPS_BUTTON_SELECT_DATE = 'Select a date',
  FOLLOW_UPS_COUNTS = '%d follow-ups on this date.',
  FOLLOW_UPS_NO_COUNTS = 'No follow-ups on this date yet.',
  FOLLOW_UPS_YOU_RECIEVE_NOTIFICATION = 'You’ll receive a notification at 9AM in Telegram and by email 🫡',
  FOLLOW_UPS_THEY_RECIEVE_NOTIFICATION = 'They’ll receive a notification at 9AM in Telegram and by email 🫡',
  MY_CHATS = 'My Chats',
  OWNED_BY_ME = 'Owned by Me',
  RECENTLY_ADDED = 'Recently Added',
  FILTERS = 'Filters',
  ADD_FILTER = 'Add Filter',
  TEAM = 'Team',
  MY_INDIVIDUAL_CHATS = 'My 1:1 Chats',
  MY_GROUP_CHATS = 'My Group Chats',
  CELL_NAME_CHAT_TYPE_GROUP = 'Group',
  CELL_NAME_CHAT_TYPE_CHANNEL = 'Channel',
  CELL_NAME_CHAT_TYPE_INDIVIDUAL = '1:1 Chat',
  CELL_NAME_CHAT_TYPE_UNKNOWN = 'Unknown',
  HIDE = 'Hide',
  SHOW = 'Show',
  CONFIRM_DELETE = 'Click to confirm deletion',
  DATE_ADDED = 'Date Added',
  NO_FOLLOWUPS_SET = 'No follow-ups set',
  SET_FOLLOWUP = 'Set a follow-up',
  FOLLOWUP_ACTIONS_DONE = 'Clear',
  FOLLOWUP_ACTIONS_RESET = 'Reset the follow-up date',
  FOLLOWUP_ACTIONS_SET = 'Set a new follow-up date',
  APPLY_FILTER = 'Apply filters',
  DISCARD_CHANGES = 'Discard changes',
  KEEP_EDITING = 'Keep editing',
  UNSAVED_CHANGES_MESSAGE = 'You have unsaved changes.',
  DISCARD_OR_KEEP_MESSAGE = 'Do you want to discard your changes or keep editing?',
  IMAGE_ERROR_TYPE_ERROR = 'Image must be PNG,JPEG OR HEIC',
  IMAGE_ERROR_SIZE_ERROR = 'Must be between 32 * 32 and 8064 * 6048',
  IMAGE_STATUS_SAVED = 'Image Saved',
  IMAGE_STATUS_DELETED = 'Deleting image...',
  IMAGE_DROP_TOOLTIP = 'You can drop an image here',
  CROP_AVATAR = 'Crop Avatar',
  CROP_IMAGE = 'Crop Image',
  TEAMMATES = 'teammates',
  SEND_INVITES = 'Send invites',
  TEAM_INVITE_PLACEHOLDER = 'email@example.com, email2@example.com,...',
  CONTINUE = 'Continue',
  TEAM_INVITE_TITLE = 'Invite the team',
  TEAM_INVITE_SUBTITLE = '3RM works best when used by your whole team.',
  VERIFICATION_LINK_EXP_TITLE = 'Your link timed out',
  VERIFICATION_LINK_EXP_SUBTITLE = 'Sorry about that. Please send a new one to sign up*',
  VERIFICATION_LINK_EXP_SEND_LINK = 'Send new verification link',
  VERIFICATION_MAIL_TITLE = 'Please check your email',
  VERIFICATION_MAIL_SUBTITLE = 'Your verification link should already be there. Make sure to check your spam folder if you can’t find it*',
  VERIFICATION_MAIL_SEND_LINK = 'Send new verification link',
  BACK = 'Back',
  OR = 'OR',
  CANCEL = 'Cancel',
  PROPERTIES = 'Properties',
  PROPERTY_SELECT = 'Select',
  PROPERTY_EDIT = 'Edit property',
  NEW_CHAT = 'New Chat',
  CHAT_TITLE = 'Chat title',
  CREATE = 'Create',
  CHAT = 'Chat',
  DETAILS = 'Details',
  ERROR_SOMETHING_WRONG = 'Something went wrong',
  WORK_EMAIL = 'Work email',
  GET_STARTED = 'Get started',
  SAVE = 'Save',
  PROPERTIES_MODAL_TITLE = 'Chat Properties',
  PROPERTIES_MODAL_DESCRIPTION = 'Choose the properties you want to activate for your team. You can always change this later.',
  NEED_MORE_PROPERTIES = 'Need more properties?',
  LET_US_KNOW = 'Let us know',
  PARTICIPANT_INFO_MESSSAGE = 'Don’t see everyone?',
  PARTICIPANT_TOOLTIP = 'Telegram’s API restrictions on accessing the participants of channels or some group chats may limit 3RM for getting all participants.',
  PARTICIPANT_HERE_WHY = 'Here’s why',
  CHAT_OR_PROJECT_NAME = 'Chat or project name',
  LINK = 'Link',
  LINK_OR_EMAIL_ERROR = 'Please enter a valid link or email',
  OPEN_FULL_PAGE_TOOLTIP = 'Open in full page',
  CLOSE_SIDEBAR_TOOLTIP = 'Close sidebar',
  OPEN_DETAILS_PAGE_TOOLTIP = 'Open in details view',
  JUST_NOW = 'Just now',
  ALL_NOTES = 'All notes',
  WRITTEN_BY_ME = 'Written by me',
  EDIT_CHAT_TO_ENTER_LINK = 'Edit chat to enter link',
  NO_LINK_ENTERED = '🔗 No link entered',
  ARCHIVE = 'Archive',
  UNARCHIVE = 'Unarchive',
  FOLLOWUP_REMINDER = 'Follow-up Reminder',
  ADD_FOLLOWUP_REMINDER = 'Add Follow-up Reminder',
  MEMBERS = 'Members',
  EDIT = 'Edit',
  FAVORITE = 'Favorite',
  CHAT_CREATED = 'Chat created and will be available in',
  BOOK_A_CALL = 'Book a call with us',
  NO_REPORTS_SUBTEXT = `We’re currently working on a new reporting feature to give you unprecedented insight into your team’s
  Telegram. We’d love to chat to help us build the feature perfectly for you.`,
  NO_ITEMS_IN_COLUMN_MENU = 'All properties shown in view!',
  SMART_LIST_VIEW_TEXT = 'Click “+ Add Filter” above to define your smart list!',
  SMART_LIST_VIEW_SUBTEXT = 'Alternatively, select one of the options below to create a pre-defined smart list.',
  //smart list preset options
  SMART_LIST_OPTION_RECENTLY_ACTIVE_TITLE = 'Recently Active',
  SMART_LIST_OPTION_RECENTLY_ACTIVE_TEXT = 'Chats with activity in the past 24 hours',
  SMART_LIST_OPTION_SORTING_TITLE = 'Needs Sorting',
  SMART_LIST_OPTION_SORTING_TEXT = 'Chats added this week that have no status',
  SMART_LIST_OPTION_GOING_COLD_TITLE = 'Going Cold',
  SMART_LIST_OPTION_GOING_COLD_TEXT = 'Chats with a latest message 4-8 weeks ago',
  TELEGRAM_SYNC_ICON_ALT = 'TG sync setting',
  NAVIGATION_ITEM_SEARCHBAR = 'Search Bar (%s)',
  NAVIGATION_ITEM_CHATS = 'Chats',
  NAVIGATION_ITEM_NETWORK = 'Network',
  NAVIGATION_ITEM_REPORTS = 'Reports',
  NAVIGATION_ITEM_CHROME_EXTENSION = 'Install Chrome Extension',
  NAVIGATION_ITEM_TEAMMATES = 'Invite Teammates',
  NAVIGATION_ITEM_BATCH_MSG = 'Batch Message',
  NAVIGATION_ITEM_DETAILS_PAGE = 'Details Page',
  NAVIGATION_ITEM_BADGE_NEW = 'NEW',
  NAVIGATION_ITEM_BADGE_TODO = 'TO-DO',
  NAVIGATION_ITEM_BADGE_EXCLAMATION = '!',
  NAVIGATION_SIDEBAR_LOGO_ALT = '3RM Logo',
  NAVIGATION_SIDEBAR_ORGANIZATION_ITEM_TITLE = "%s's %s",
  NAVIGATION_SIDEBAR_BUTTON_ADD_SMARTLIST = 'Add smart list',
  NAVIGATION_SIDEBAR_SMARTLIST_ITEM_DEFAULT = 'Custom smart list %d',
  NAVIGATION_USER_MENU_ITEM_PROFILE = 'Profile',
  NAVIGATION_USER_MENU_ITEM_SETTINGS = 'Settings',
  NAVIGATION_USER_MENU_ITEM_BLOG = 'Blog',
  NAVIGATION_USER_MENU_ITEM_X = 'Twitter / X',
  NAVIGATION_USER_MENU_ITEM_TELEGRAM = 'Telegram Community',
  NAVIGATION_USER_MENU_ITEM_SIGN_OUT = 'Sign Out',
  EXPORT_CONVERSATIONS = 'Export conversations',
}

export default Translations;
