import React from 'react';
import { createContext } from 'react';
import { IPersistence, InMemoryPersistence, LocalStoragePersistence } from 'services/store/persistence';
import { FilterItem } from 'services/store/persistence/models/FilterItem';
import { ConversationPaginationItem } from 'services/store/persistence/models/ConversationPaginationItem';
import { TgUserSessionString } from 'services/store/persistence/models/SessionString';
import { NavigationItem } from 'page/dashboard/header/SideNav';
import { ITelegramClient, TelegramClient } from 'services/clients/TelegramClient';

export type AppContextState = {
  filterPersistence: IPersistence<FilterItem>;
  conversationPaginationPersistence: IPersistence<ConversationPaginationItem>;
  navigationItemPersistence: IPersistence<NavigationItem>;
  tgSessionStringPersistence: IPersistence<TgUserSessionString>;
  tgClient: ITelegramClient;
};

export function createAppContextInitialState(): AppContextState {
  const tgSessionStringPersistence = new LocalStoragePersistence<TgUserSessionString>(localStorage);
  const tgClient = new TelegramClient(tgSessionStringPersistence);

  return {
    filterPersistence: new LocalStoragePersistence<FilterItem>(localStorage),
    conversationPaginationPersistence: new LocalStoragePersistence<ConversationPaginationItem>(localStorage),
    navigationItemPersistence: new LocalStoragePersistence<NavigationItem>(localStorage),
    tgSessionStringPersistence: tgSessionStringPersistence,
    tgClient: tgClient,
  };
}

export const AppContextInitialState: AppContextState = createAppContextInitialState();

export const AppContext: React.Context<AppContextState> = createContext(AppContextInitialState);
