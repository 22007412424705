import { stringToDescendant, RichTextInputAreaV2, descendantToString, descendantToHtml } from '@3rm-co/ui-library';
import { EntityPropertyDefinition, ValuePropertyValue } from 'services/models/domain/entityViews';
import { useEffect, useMemo, useState } from 'react';
import { Descendant } from 'slate';
import { usePropertyValue } from 'services/hooks/usePropertyValue';
import { EntityType } from 'services/models/domain/entityTypes';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { colors } from 'theme/colors';
import { SxProps } from '@mui/material';
import { Box, Typography } from '@mui/joy';
import { FeatureFlags } from 'services/models/domain/featureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSafeGridApiContext } from 'services/hooks/useSafeGridApiContext';
import { fonts } from 'theme/fonts';
import parse from 'html-react-parser';

interface ValuePropertyProps {
  propertyValue?: ValuePropertyValue;
  propertyDefinition: EntityPropertyDefinition;
  entityId: string;
  sx?: SxProps;
}

export const ValueProperty = (props: ValuePropertyProps) => {
  const flags = useFlags<FeatureFlags>();
  const { propertyValue, propertyDefinition, entityId, sx } = props;
  const valueAsDescedant = useMemo(() => {
    return stringToDescendant(propertyValue?.value ?? '');
  }, [propertyValue?.value]);

  const [propertyText, setPropertyText] = useState<Descendant[]>(valueAsDescedant);
  const { handleUpdatePropertyValue, handleUpdatePropertyValueBatchEntities } = usePropertyValue();
  const apiRef = useSafeGridApiContext();

  const [selected, setSelected] = useState(false);
  const [editState, setEditState] = useState(false);

  const toggleEditState = () => {
    setEditState(!editState);
  };

  useEffect(() => {
    const handleCellClick = (params: any) => {
      const selectedIds = apiRef ? Array.from(apiRef.current.getSelectedRows().keys()) : [];
      const isSelected = selectedIds.includes(entityId) && selectedIds.includes(params.id);
      if (isSelected) {
        const columnField = params.field;
        if (columnField === propertyDefinition.oid.oid) {
          setSelected(true);
        }
      } else {
        setSelected(false);
      }
    };
    if (apiRef) {
      apiRef.current.subscribeEvent('cellClick', handleCellClick);
      apiRef.current.subscribeEvent('cellMouseDown', (params: any) => {
        setSelected(params.columnField === propertyDefinition.oid.oid);
      });
    }
  }, []);

  useEffect(() => {
    setPropertyText(valueAsDescedant);
  }, [propertyValue?.value]);

  useEffect(() => {
    const handleCellClick = (params: GridCellParams) => {
      const isSelected = entityId === params.row.id;
      if (isSelected) {
        const columnField = params.field;
        if (columnField === propertyDefinition.oid.oid) {
          setEditState(true);
        }
      } else {
        setEditState(false);
      }
      if (params.field !== propertyDefinition.oid.oid) {
        setEditState(false);
      }
    };
    if (apiRef) {
      apiRef.current.subscribeEvent('cellClick', handleCellClick);
    }
  }, []);

  const onBlur = () => {
    toggleEditState();
    const changed = descendantToString(propertyText) !== propertyValue?.value;
    if (!changed) {
      return;
    }
    const selectedIds = apiRef ? Array.from(apiRef.current.getSelectedRows().keys()) : [];
    const isSelected = selectedIds.includes(entityId);
    if (isSelected) {
      handleUpdatePropertyValueBatchEntities(
        selectedIds.map((id) => id.toString()),
        EntityType.Conversation,
        propertyDefinition.oid.oid,
        propertyValue?.value,
        descendantToString(propertyText)
      );
    } else {
      handleUpdatePropertyValue(
        entityId,
        EntityType.Conversation,
        propertyDefinition.oid.oid,
        propertyValue?.value,
        descendantToString(propertyText)
      );
    }
  };

  return !editState ? (
    <Box
      sx={{ ...ValuePropertyStyle.textContainer, border: selected ? `2px solid ${colors.primary[50]}` : 'none' }}
      onClick={toggleEditState}
    >
      <Typography level="tiny" sx={ValuePropertyStyle.textStyle}>
        {parse(descendantToHtml({ children: propertyText }))}
      </Typography>
    </Box>
  ) : (
    <RichTextInputAreaV2
      className={'parent-focused'}
      value={propertyText}
      onValueChange={(desc) => {
        if (typeof desc === 'string') {
          setPropertyText(stringToDescendant(desc));
        } else {
          setPropertyText(desc);
        }
      }}
      onBlur={onBlur}
      sx={{
        ...ValuePropertyStyle.inputContainer,
        border:
          selected && flags.batchPropertiesSelection
            ? { border: `2px solid ${colors.primary[50]}`, borderRadius: 0 }
            : 'none',
        ...sx,
      }}
      inputSx={ValuePropertyStyle.input}
      singleLine
      autoFocus
    />
  );
};

const ValuePropertyStyle = {
  inputContainer: {
    width: '100%',
    minHeight: '100%',
    borderRadius: '0px',
    boxShadow: 'none',
    paddingLeft: '10px',
  },
  input: {
    fontFamily: fonts.regular,
    justifyContent: 'center',
    alignItems: 'self-start',
  },
  textContainer: {
    display: 'flex',
    minWidth: '100%',
    height: '100%',
    alignContent: 'center',
    alignItems: 'center',
  },
  textStyle: {
    padding: '0 10px',
    width: '100%',
    fontSize: 'inherit',
  },
};
