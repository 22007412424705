import { Chip, styled } from '@mui/joy';

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'chipSize',
})(({ chipSize }: { chipSize?: string }) => ({
  padding: chipSize === 'xs' ? '2px 8px' : undefined,
  fontSize: chipSize === 'xs' ? '12px' : undefined,
  lineHeight: chipSize === 'xs' ? '12px' : undefined,
  minHeight: chipSize === 'xs' ? '24px' : undefined,
  height: chipSize === 'xs' ? '24px' : undefined,
  width: 'fit-content',
}));
