import { GridCellProps, GridCell } from '@mui/x-data-grid-pro';

import LatestMessageCellContent from 'components/Chats/ChatTableV2/TableCell/customCells/cellsContent/LatestMessage/LatestMessageCellContent';

export default function LatestMessageCell(props: GridCellProps) {
  const { value, ...rest } = props;
  return (
    <GridCell {...rest}>
      <LatestMessageCellContent
        lastestMessageTime={value.lastMessageAt}
        lastMessageSenderParticipantOid={value.lastMessageSenderParticipantOid?.oid ?? ''}
        participants={value.participants}
      />
    </GridCell>
  );
}
