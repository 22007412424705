import { Box, Divider, Sheet } from '@mui/joy';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import { Link } from '@mui/material';

import Icons from 'assets/icons';
import Translations from 'const/translations/en';
import { UserStateProps } from 'contexts/interface';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TgSyncModalProps } from 'page/modal/OnboardingModal/interface';
import {
  DividerStyles,
  LogoBoxStyles,
  ModalBodySheetStyles,
  ModalCloseBoxStyles,
  PrivacyDataLinkStyles,
} from 'page/modal/OnboardingModal/styles';
import { TelegramLoginOrSyncFolders } from 'page/modal/tg-link/TgLink';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { commandbarTrackerEvents } from 'services/commandbar/trackEvents';
import useAuth from 'services/hooks/useAuth';
import { TgLinkContext, useTgLinkAccount } from 'services/hooks/useTgLinkAccount';
import { UserPropertyCategoryType, useUpsertDynamicUserPropertiesMutation } from 'services/models/api/generated';
import { FeatureFlags } from 'services/models/domain/featureFlags';
import { authState } from 'services/store/slices/authSlice';
import AnalyticKeys, { trackAction } from 'services/utils/analytics';
import 'theme/styles/scroll-bar.css';
import { ModalStyle } from '../styles';

/**
 * Main onboarding modal - dispatches to the different pages and steps
 */
export default function OnboardingModal({ open, onClose }: TgSyncModalProps) {
  const tgLinkAccount = useTgLinkAccount();
  const { needPasswordFor2FA, snackbarOpen, snackbarText, snackbarVariant } = tgLinkAccount;

  const { getStateData } = useAuth();
  const { user } = useSelector(authState);

  const flags = useFlags<FeatureFlags>();
  const [updateUserState] = useUpsertDynamicUserPropertiesMutation();

  async function markFirstTime() {
    await updateUserState({
      variables: {
        input: {
          propertyCategoryType: UserPropertyCategoryType.UserState,
          propertyId: UserStateProps.firstTime,
          value: 'false',
        },
      },
    });

    await getStateData();
  }

  useEffect(() => {
    markFirstTime();
  }, [open, updateUserState]);

  async function handleCloseModal() {
    onClose();
    await markFirstTime();
    if (flags.commandbar) commandbarTrackerEvents.userOnboarding();
    trackAction(AnalyticKeys.CLOSED_SETUP_MODAL, {
      organizationId: user?.actingAsMemberOfOrganization.oid,
      organizationName: user?.actingAsMemberOfOrganization.name,
    });
  }

  return (
    <Modal open={open} onClose={handleCloseModal} sx={ModalStyle}>
      <TgLinkContext.Provider value={tgLinkAccount}>
        <Sheet sx={ModalBodySheetStyles}>
          <Box>
            <Box sx={ModalCloseBoxStyles}>
              <ModalClose />
            </Box>
            <Box sx={LogoBoxStyles}>
              <img
                src={require('assets/images/logo.png')}
                alt="logo"
                width="28px"
                height="28px"
                style={{ marginTop: '2px', marginBottom: '2px' }}
              />
              <Typography
                id="modal-title"
                level="large+"
                textColor="inherit"
                fontSize={'24px'}
                lineHeight={'28px'}
                fontWeight="500"
              >
                {Translations.ONBOARDING_WELCOME}
              </Typography>

              <Typography level="regular" textAlign="center" fontSize={'14px'}>
                {!needPasswordFor2FA ? Translations.ONBOARDING_MESSAGE : Translations.TELEGRAM_ENTER_2FA_CODE}
              </Typography>
            </Box>
          </Box>

          <TelegramLoginOrSyncFolders />

          <Divider sx={DividerStyles} />
          <Link href="/3RM – Privacy & Data.pdf" target="_blank" rel="noopener noreferrer" sx={PrivacyDataLinkStyles}>
            {Translations.ONBOARDING_SECURITY}
            <img src={Icons.ic_open_link} alt="Open" style={{ marginLeft: '2px' }} />
          </Link>
        </Sheet>
      </TgLinkContext.Provider>
    </Modal>
  );
}
