import React, { useEffect, useState } from 'react';
import { Box, Sheet, Typography, Stack } from '@mui/joy';
import dayjs from 'dayjs';
import Calendar from 'page/modal/followUpModals/FollowUpCalendar/FollowupCalendar';
import AssignFollowUp from 'page/modal/followUpModals/AssignFollowUp/AssignFollowUp';
import { colors } from 'theme/colors';
import { UIButtonStyled } from 'page/modal/followUpModals/UpdateFollowUpModalDesktop/styles';
import { Followup } from 'services/models/domain/followUps';
import { OrganizationalUser, User } from 'services/models/domain/user';
import { Conversation } from 'services/models/domain/conversation';
import Translations from 'const/translations/en';
import { formatTranslation, getDateString, getFollowupDateRange } from 'services/utils/helpers';
import useUserFollowupCounts from 'services/hooks/useUserFollowupCounts';
import { FOLLOWUPS_MONTH_RANGE } from 'services/constants';

interface Props {
  conversation: Conversation;
  handleClose: () => void;
  onFollowUpUpdated: (from?: Followup, to?: Followup) => void;
  user: User;
}

const UpdateFollowupModalDesktop: React.FC<Props> = ({ handleClose, conversation, onFollowUpUpdated, user }) => {
  const [followUp, setFollowUp] = useState<Followup | undefined>(undefined);
  const [assignee, setAssignee] = useState<OrganizationalUser | undefined>(undefined);
  const [date, setDate] = useState<Date | undefined>(undefined);

  const [assigneeFollowupCount, setAssigneeFollowupCount] = useState(0);
  const [startingDate, setStartingDate] = useState<dayjs.Dayjs>(dayjs());
  const [endingDate, setEndingDate] = useState<dayjs.Dayjs>(dayjs().add(FOLLOWUPS_MONTH_RANGE, 'month'));

  const { getFollowupCounts, data } = useUserFollowupCounts();

  useEffect(() => {
    if (!!assignee && !!date) {
      const dateRange = getFollowupDateRange(date, startingDate, endingDate);
      setStartingDate(dateRange[0]);
      setEndingDate(dateRange[1]);

      getFollowupCounts(assignee.oid, dateRange[0], dateRange[1]);
    }
  }, [assignee, date]);

  useEffect(() => {
    if (!!data && !!date) {
      const followupCounts = data.getUserFollowUpCountsWithinRange.data.followupCounts;
      const currentDate = getDateString(date);
      const count = followupCounts?.find(
        (followupCount) => followupCount.date.utcTimeStampAsString.split('T')[0] === currentDate
      );
      setAssigneeFollowupCount(count?.count ?? 0);
    }
  }, [data, date]);

  useEffect(() => {
    if (conversation.nextFollowup) {
      const existingFollowUp: Followup = conversation.nextFollowup;
      setAssignee(existingFollowUp.assignedToUser);
      setDate(new Date(existingFollowUp.dueAt.utcTimeStampAsString));
      setFollowUp(existingFollowUp);
    } else {
      setAssignee(user); // Default to the signed-in user if not already set
      setDate(undefined);
      setFollowUp(undefined);
    }
  }, [conversation.nextFollowup]);

  const onButtonClick = async () => {
    let newFollowUp;
    if (assignee && date) {
      newFollowUp = {
        assignedToUser: assignee,
        createdByUserOid: user.oid,
        dueAt: {
          utcTimeStampAsString: dayjs(date).toISOString(),
        },
      };
    }
    onFollowUpUpdated(followUp, newFollowUp);
    setFollowUp(newFollowUp);

    handleClose();
  };

  const isLoading = false;

  const usersInOrganization = user?.actingAsMemberOfOrganization?.listOfUsersInOrganization ?? [];
  const isMoreThanOnUserInOrg = usersInOrganization.length > 1;
  const selectBtnText = isMoreThanOnUserInOrg
    ? Translations.FOLLOW_UPS_BUTTON_SELECT_PERSON_AND_DATE
    : Translations.FOLLOW_UPS_BUTTON_SELECT_DATE;

  // If only one person in list, and no assignee selected,
  // he should be selected by default
  useEffect(() => {
    if (usersInOrganization.length === 1 && !assignee) {
      setAssignee(usersInOrganization[0]);
    }
  }, [setAssignee, usersInOrganization, assignee]);

  const dayJsDate = date ? dayjs(date) : undefined;
  return (
    <>
      <Sheet id="followup-modal-desktop" variant="outlined" sx={UpdateFollowUpModalDesktopStyles.sheet}>
        <Stack direction="row" sx={UpdateFollowUpModalDesktopStyles.container}>
          {isMoreThanOnUserInOrg && (
            <Sheet variant="outlined" sx={UpdateFollowUpModalDesktopStyles.assigneeSheet} id="followup-assignee">
              <Box sx={UpdateFollowUpModalDesktopStyles.assigneeTitleContainer}>
                <Typography id="modal-title" level="regular" textColor="inherit">
                  {Translations.FOLLOW_UPS_ASSIGNEES_LIST_TITLE}
                </Typography>
              </Box>
              <Box sx={UpdateFollowUpModalDesktopStyles.assignContainer}>
                <AssignFollowUp
                  assignee={assignee ?? null}
                  onSelectAssignee={(user: User) => {
                    setAssignee(user);
                  }}
                  maxHeight={'281px'}
                  width={'100%'}
                />
              </Box>
            </Sheet>
          )}

          <Box id="followup-calendar" sx={UpdateFollowUpModalDesktopStyles.calendarContainer}>
            <Calendar
              value={date}
              handleValue={(date?: Date) => {
                setDate(date);
              }}
            />
          </Box>
        </Stack>

        <Box sx={UpdateFollowUpModalDesktopStyles.buttonContainer}>
          <UIButtonStyled
            id="followup-schedule-btn"
            fullWidth
            loading={isLoading}
            onClick={onButtonClick}
            disabled={!(date && assignee) || isLoading}
          >
            <Typography level="tiny" sx={UpdateFollowUpModalDesktopStyles.buttonText}>
              {date && assignee
                ? formatTranslation(Translations.FOLLOW_UPS_BUTTON_SCHEDULE_REMINDER, dayJsDate?.format('ddd, MMM DD'))
                : selectBtnText}
            </Typography>
          </UIButtonStyled>
          {date && assignee && (
            <Typography level="micro" sx={UpdateFollowUpModalDesktopStyles.helperText}>
              {assigneeFollowupCount > 0
                ? formatTranslation(Translations.FOLLOW_UPS_COUNTS, assigneeFollowupCount)
                : Translations.FOLLOW_UPS_NO_COUNTS}{' '}
              {user?.oid === assignee?.oid
                ? Translations.FOLLOW_UPS_YOU_RECIEVE_NOTIFICATION
                : Translations.FOLLOW_UPS_THEY_RECIEVE_NOTIFICATION}
            </Typography>
          )}
        </Box>
      </Sheet>
    </>
  );
};

const UpdateFollowUpModalDesktopStyles = {
  sheet: {
    borderRadius: '8px',
    mt: 0,
    minWidth: 395,
    overflow: 'hidden',
  },
  container: {
    p: '12px 16px',
    gap: '8px',
    height: '350px',
    background: 'var(--joy-palette-info-outlinedBorder)',
  },
  assigneeSheet: {
    borderRadius: '8px',
    maxWidth: '200px',
    minWidth: '200px',
  },
  assigneeTitleContainer: {
    p: '12px 12px 8px 16px',
    borderBottom: '1px solid var(--joy-palette-info-outlinedBorder)',
  },
  assignContainer: {
    background: 'var(--joy-palette-common-white)',
    borderRadius: '8px',
  },
  calendarContainer: {
    background: 'var(--joy-palette-common-white)',
    borderRadius: '8px',
    width: '100%',
  },
  buttonContainer: {
    borderTop: '1px solid #F4F6F8',
    p: '12px 16px 12px 16px',
  },
  buttonText: {
    color: 'white',
    fontSize: '15px',
    mb: '0',
  },
  helperText: {
    mt: '8px',
    color: colors.neutral[60],
    textAlign: 'center',
  },
};
export default UpdateFollowupModalDesktop;
