import { GridCellProps, GridCell } from '@mui/x-data-grid-pro';

import { Folder } from 'services/models/domain/folder';
import { Box, Sheet, Tooltip, Typography } from '@mui/joy';
import { Flex1 } from 'theme/flex';
import { CloseHandler } from 'components/Chats/ChatTableV2/ChatTable';
import { colors } from 'theme/colors';
import _ from 'lodash';
import { FolderImage } from 'components/FolderImage/FolderImage';
import { TooltipContainer } from 'components/Chats/ChatTableV2/TableCell/customCells/Folder/style';
import GroupedFolderList from 'components/GroupedFolderList';

export interface FolderCellProps {
  folder: Folder[];
  setOnCloseHandler: (closeHandler?: CloseHandler) => void;
}

export function FolderCell(props: GridCellProps<FolderCellProps>) {
  const { value, ...rest } = props;
  const { folder, setOnCloseHandler } = value!;
  let displayFolder: Folder | undefined = undefined;
  if (folder) {
    displayFolder = _.chain(folder).sortBy('folderName').value()[0];
  }

  const renderTooltip = (folder: Folder[]) => {
    return (
      <Sheet variant="outlined" sx={TooltipContainer}>
        <GroupedFolderList folder={folder} />
      </Sheet>
    );
  };

  return (
    <Tooltip
      title={folder.length > 1 && renderTooltip(folder)}
      arrow
      placement="bottom"
      variant="plain"
      componentsProps={{
        root: { sx: { p: 0, marginTop: '-18px !important' } },
        arrow: { sx: { marginLeft: '-68px' } },
      }}
      enterNextDelay={400}
      enterDelay={400}
    >
      <GridCell {...rest}>
        <Box sx={Flex1}>
          {!!displayFolder && (
            <Box sx={{ ...Flex1 }}>
              <FolderImage image={displayFolder.user.profilePicture} width={22} height={20} />
              <Box sx={{ ...Flex1, ...{ marginTop: '2px' } }}>
                <Typography level="tiny">{displayFolder.folderName}</Typography>
              </Box>
              {folder.length > 1 && (
                <Box sx={{ paddingRight: '5px' }}>
                  <Typography level="tiny" sx={{ color: colors.neutral[60] }}>
                    +{folder.length - 1}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </GridCell>
    </Tooltip>
  );
}
