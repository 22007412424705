import { Box, Typography } from '@mui/joy';
import CustomAvatar from 'components/CustomAvatar';
import { FolderImage } from 'components/FolderImage/FolderImage';
import { Folder, FolderUser } from 'services/models/domain/folder';
import { groupFoldersByUser } from 'services/utils/conversationPageUtils';
import { getUsersInitials } from 'services/utils/helpers';
import { Flex1, FlexCol } from 'theme/flex';

interface GroupedFolderListProps {
  folder: Folder[];
}

export default function GroupedFolderList({ folder }: GroupedFolderListProps) {
  const groupedFolders = groupFoldersByUser(folder);

  const renderUserFolder = (folder: Folder[], user: FolderUser) => {
    return (
      <Box key={user.oid.oid}>
        <Box sx={{ ...Flex1, p: '12px', pb: '4px' }}>
          <CustomAvatar
            size={20}
            userInitials={getUsersInitials(user.fullName || '')}
            imgUrl={user?.profilePicture}
            index={0}
          />
          <Typography level="tiny" sx={{ marginLeft: '10px' }}>
            {user.fullName}
          </Typography>
        </Box>
        <Box sx={{ marginLeft: '16px' }}>{folder.map(renderFolder)}</Box>
      </Box>
    );
  };

  const renderFolder = (f: Folder) => {
    return (
      <Box sx={{ p: '4px', margin: '2px 0 4px' }} key={f.oid.oid}>
        <Box sx={Flex1}>
          <FolderImage width={20} height={18} />
          <Typography level="tiny">{f.folderName}</Typography>
        </Box>
        <Box></Box>
      </Box>
    );
  };
  return (
    <Box sx={{ ...Flex1, ...FlexCol, p: '4' }}>
      {groupedFolders.map(({ user, folders }) => renderUserFolder(folders, user))}
    </Box>
  );
}
