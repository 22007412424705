import { styled, SxProps, Theme } from '@mui/material';
import { colors } from 'theme/colors';
import { fonts } from 'theme/fonts';

export const ModalStyle: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: 1,
  '& .JoyModal-backdrop': {
    background: '#0F1012',
    backdropFilter: 'blur(2px)',
    opacity: 0.9,
  },
};

export const ModalBoxContentStyles: SxProps = {
  flexDirection: 'row',
  display: 'flex',
  p: '12px 16px 16px 16px',
  alignContent: 'center',
  alignItems: 'center',
};

export const ModalBodySheetStyles: SxProps = {
  width: '100%',
  // maxWidth:"420px",
  borderRadius: '8px',
  outline: 'none',
  // paddingBottom: '12px',
  '--joy-shape-borderRadius': '6px',
  transition: 'all 1s ease',
};

export const InviteWrapperStyled = styled('div')({
  position: 'relative',
});

export const ModalContentStackStyles: SxProps<Theme> = {
  padding: '12px 16px 16px 16px',
  borderRadius: '0 0 10px 10px',
  maxHeight: '600px',
  overflowX: 'auto',
  color: 'secondary.dark',
  background: '#F1F4F6',
};

export const InviteLinkBoxStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0.5rem 1rem',
  borderRadius: '8px',
  fontFamily: fonts.regular,
  backgroundColor: '#F7F9FA',
  color: colors.neutral[60],
  fontSize: '14px',
  boxSize: 'border-box',
  width: 'auto',
};
