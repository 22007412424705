import { useDispatch, useSelector } from 'react-redux';
import {
  Contact,
  useAddNoteMutation,
  useDeleteNoteMutation,
  useUpdateNoteMutation,
} from 'services/models/api/generated';
import * as domain from 'services/models/domain/conversation';
import { EntityType } from 'services/models/domain/entityTypes';
import { Note } from 'services/models/domain/note';
import { authState } from 'services/store/slices/authSlice';
import * as contactsSlice from 'services/store/slices/contactsSlice';
import { contactsState } from 'services/store/slices/contactsSlice';
import * as conversationSlice from 'services/store/slices/conversationsSlice';
import { detailsState, setDetailsEntity } from 'services/store/slices/detailsSlice';
import { setEntity, sidebarEntityDetailsState } from 'services/store/slices/sidebarEntityDetailsSlice';
import AnalyticKeys, { trackAction } from 'services/utils/analytics';
import { mapNoteToApi } from 'services/utils/noteUtils';

export const useNotesEntity = (entityOid: string, entityType: EntityType) => {
  const [addNote] = useAddNoteMutation();
  const [updateNote] = useUpdateNoteMutation();
  const [deleteNote] = useDeleteNoteMutation();

  const { user } = useSelector(authState);
  const dispatch = useDispatch();
  const { conversations } = useSelector(conversationSlice.conversationsState);
  const { contacts } = useSelector(contactsState);
  const { entity: sidebarEntity } = useSelector(sidebarEntityDetailsState);
  const { detailsEntity } = useSelector(detailsState);

  const sidebarHasEntity =
    sidebarEntity &&
    ((sidebarEntity as domain.Conversation)?.conversationRecordOid?.oid === entityOid ||
      (sidebarEntity as Contact)?.oid?.oid === entityOid);

  const detailsPageHasEntity =
    detailsEntity &&
    ((detailsEntity as domain.Conversation)?.conversationRecordOid?.oid === entityOid ||
      (detailsEntity as Contact)?.oid?.oid === entityOid);

  const getNotes = (): Note[] => {
    if (sidebarHasEntity) {
      return sidebarEntity?.notes || [];
    }
    if (detailsPageHasEntity) {
      return detailsEntity?.notes || [];
    }
    if (entityType === EntityType.ConversationRecord) {
      return (
        conversations.find((c) => c.oid.oid === entityOid || c.conversationRecordOid.oid === entityOid)?.notes || []
      );
    }
    if (entityType === EntityType.ContactRecord) {
      return contacts.find((c) => c.oid.oid === entityOid)?.notes || [];
    }
    return [];
  };

  const dispatchNotes = (notes: Note[]) => {
    if (entityType === EntityType.ContactRecord) {
      dispatch(
        contactsSlice.updateContact({
          notes: notes.map(mapNoteToApi),
          oid: { oid: entityOid },
        })
      );
    }
    if (entityType === EntityType.ConversationRecord) {
      dispatch(
        conversationSlice.updateConversation({
          notes: notes.map(mapNoteToApi),
          oid: { oid: entityOid },
        })
      );
    }
    if (sidebarHasEntity) {
      const updatedEntity = { ...sidebarEntity, notes: notes.map(mapNoteToApi) } as domain.Conversation | Contact;
      dispatch(setEntity(updatedEntity));
    }
    if (detailsPageHasEntity) {
      const updatedEntity = { ...detailsEntity, notes: notes.map(mapNoteToApi) } as domain.Conversation | Contact;
      dispatch(setDetailsEntity(updatedEntity));
    }
  };

  const handleAddNote = async (noteText: string) => {
    const notes = getNotes();
    const newNote: Note = {
      createdAt: {
        utcTimeStampAsString: new Date(Date.now()).toUTCString(),
      },
      createdByUserOid: {
        oid: user!.oid,
      },
      text: noteText,
      user: user!,
      oid: 'temp',
    };

    dispatchNotes([newNote, ...notes]);
    await addNote({
      variables: {
        input: {
          entityId: { entityTypeId: entityType, oid: entityOid },
          text: noteText,
        },
      },
    }).then((response) => {
      const newNoteOid = response.data?.addNote.data.noteOid;
      if (!newNoteOid) {
        return;
      }
      dispatchNotes([{ ...newNote, oid: newNoteOid!.oid }, ...notes]);
      trackAction(AnalyticKeys.ADDED_NOTE, {
        organizationId: user?.actingAsMemberOfOrganization.oid,
        organizationName: user?.actingAsMemberOfOrganization.name,
        entityOid: entityOid,
      });
    });
  };

  const handleUpdateNote = async (existingNoteOid: string, noteText: string) => {
    const notes = getNotes();
    const updatedNotes = notes.map((note) => {
      if (note.oid === existingNoteOid) {
        return { ...note, text: noteText, updatedAt: { utcTimeStampAsString: new Date(Date.now()).toUTCString() } };
      }
      return note;
    });
    dispatchNotes(updatedNotes);

    await updateNote({
      variables: {
        input: {
          noteOid: existingNoteOid,
          text: noteText,
        },
      },
    }).then((_response) => {
      trackAction(AnalyticKeys.EDITED_NOTE, {
        organizationId: user?.actingAsMemberOfOrganization.oid,
        organizationName: user?.actingAsMemberOfOrganization.name,
        entityOid: entityOid,
      });
    });
  };

  const handleDeleteNote = async (existingNoteOid: string) => {
    const notes = getNotes();
    dispatchNotes(notes.filter((n) => n.oid !== existingNoteOid));
    await deleteNote({ variables: { input: { noteOid: existingNoteOid } } }).then((response) => {
      // enqueueSnackbar('Note Deletd')
      trackAction(AnalyticKeys.DELETED_NOTE, {
        organizationId: user?.actingAsMemberOfOrganization.oid,
        organizationName: user?.actingAsMemberOfOrganization.name,
        entityOid: entityOid,
      });
    });
  };

  return {
    addNewNote: handleAddNote,
    updateNote: handleUpdateNote,
    deleteNote: handleDeleteNote,
  };
};
