import { Box, Stack, Typography } from '@mui/joy';
import TitleCard from 'components/TitleCard';
import { ReactComponent as SidebarIcon } from 'assets/icons/ic_sidebar.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/ic_telegram.svg';
import { DetailsIconStyle, TelegramIconStyle } from 'components/Kanban/styles';
import Translations from 'const/translations/en';
import { DoubleLineTooltipContents } from 'components/TooltipContents';
import PageLink from 'components/PageLink';
import { RouterPaths } from 'services/hooks/useNavigation';
import { ConversationParticipant } from 'services/models/domain/conversation';
import { textEllipse } from 'components/Chats/ChatTableV2/TableCell/customCells/cellsContent/styles';
import { SxProps } from '@mui/joy/styles/types';

interface Props {
  participant: ConversationParticipant;
  onDetailsClicked: () => void;
  sx?: SxProps;
}

export default function ParticipantItem({ participant, onDetailsClicked, sx }: Props) {
  const participantHandle = participant.userHandle && `@${participant.userHandle}`;

  return (
    <Box sx={{ ...ParticipantItemStyles.container, ...sx }}>
      <TitleCard
        title={
          <Stack component="span" direction="row" spacing={0.25} sx={ParticipantItemStyles.item}>
            <PageLink
              to={RouterPaths.createDetailsContactPath(participant.oid.oid)}
              sx={ParticipantItemStyles.nameText}
              openInNewTab
            >
              {`${participant.firstName ?? ''} ${participant.lastName ?? ''}`}
            </PageLink>
            <Typography sx={ParticipantItemStyles.tagText}>{participantHandle}</Typography>
          </Stack>
        }
        leftComponent={{
          icon: TelegramIcon,
          css: TelegramIconStyle(!!participant.userHandle),
          link: participant.userHandle && `https://t.me/${participant.userHandle}`,
          description: participant.userHandle ? (
            Translations.OPEN_TELEGRAM_TOOLTIP
          ) : (
            <DoubleLineTooltipContents
              primaryText={Translations.NO_CONTACT_LINK_TOOLTIP_PRIMARY_MESSAGE}
              secondaryText={Translations.NO_CONTACT_LINK_TOOLTIP_SECONDARY_MESSAGE}
            />
          ),
          showFilledIcon: true,
        }}
        rightComponent={{
          icon: SidebarIcon,
          description: Translations.OPEN_SIDEBAR_TOOLTIP,
          css: DetailsIconStyle,
          link: onDetailsClicked,
        }}
      />
    </Box>
  );
}

const ParticipantItemStyles = {
  container: {
    minHeight: '25px',
    width: '100%',
    p: 0,
  },
  item: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
    color: 'text.primary',
  },
  nameText: {
    flex: '1 1',
    minWidth: '0px',
  },
  tagText: {
    ...textEllipse,
    color: 'text.tertiary',
    flex: '1 1',
    textAlign: 'end',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  } as SxProps,
};
