import { IconsSvg } from 'assets/icons';
import { STICKY_COLUMNS } from 'components/Chats/ChatTableV2/constants';
import { uniqueId } from 'lodash';
import { NavigationItem } from 'page/dashboard/header/SideNav';
import { INITIAL_VISUAL_TABLE_VIEW_COLUMNS } from 'services/constants';
import * as gql from 'services/models/api/generated';
import {
  DateRangeField,
  EntityFilterItem,
  EntityFilterType,
  FilterIdentifier,
  generateFilterId,
} from 'services/models/domain/entityFilter';
import { EntityType } from 'services/models/domain/entityTypes';
import * as domain from 'services/models/domain/entityViews';
import { Folder } from 'services/models/domain/folder';
import { User } from 'services/models/domain/user';

// TODO (AWE): Temporary for now until we have a dropdown to select.
const BACKUP_GROUP_BY_PROPERTY_DEF_OID = 'a5228cdc-ed83-11ed-a05b-0242ac120003';

export const mapEntityViewsToDomain = (
  data: gql.EntityViewsResponseData | undefined,
  user: User,
  folder: Folder[]
): domain.EntityViews => {
  const visiblePropertyDefinitionOids = data?.visiblePropertyDefinitionOids || [];
  const gqlPropertyDefinitionsWithVisibilityEnriched = data?.propertyDefinitions.map((pd) => ({
    ...pd,
    visible: visiblePropertyDefinitionOids.includes(pd.oid.oid) || visiblePropertyDefinitionOids.includes(pd.originOid),
  }));
  const domainPropertyDefinitions = gqlPropertyDefinitionsWithVisibilityEnriched?.map(mapPropertyDefinition);
  const orgVisibleEntityfields = filterOutStickyColumnsAndNone(data?.visibleEntityFields) || [];
  const orgAllVisiblePropertyDefinitionOids = getAllVisiblePropertyDefinitionOids(data);

  const views: domain.EntityView[] | undefined = data?.views.map((entityView) => {
    const viewPropertyDefinitions: domain.EntityPropertyDefinition[] | undefined = domainPropertyDefinitions?.map(
      (vpd) => {
        const visiblePropertyDefinitions =
          entityView.visiblePropertyDefinitionOids ?? orgAllVisiblePropertyDefinitionOids ?? [];
        const visibleToView = visiblePropertyDefinitions.includes(vpd?.oid.oid) ?? false;
        return { ...vpd, visible: visibleToView };
      }
    );
    const entityType = EntityType.Conversation;
    return {
      oid: entityView.oid,
      title: entityView.title,
      icon: entityView.icon,
      displayTypes: entityView.displayTypes,
      criteria: entityView.criteria
        ? mapCriteria(entityView.criteria, entityView.title, user, folder, entityType, domainPropertyDefinitions || [])
        : undefined,
      entityType: entityType,
      // TODO (AWE): Temporary for now until we have a dropdown to select.
      groupByPropertyDefinitionOid: entityView.groupByPropertyDefinitionOid || BACKUP_GROUP_BY_PROPERTY_DEF_OID,
      propertyDefinitions: viewPropertyDefinitions || [],
      amountEntities: entityView.amountEntities,
      type: entityView.viewType,
      visibleEntityFields: filterOutStickyColumnsAndNone(entityView.visibleEntityFields) || orgVisibleEntityfields,
      tableColumnOrder: [],
    };
  });

  return {
    views: views || [],
    propertyDefinitions: domainPropertyDefinitions || [],
    groupByPropertyDefinitionOid: BACKUP_GROUP_BY_PROPERTY_DEF_OID,
    visibleEntityFields: orgVisibleEntityfields,
    tableColumnOrder: [],
    allVisiblePropertyDefinitionOids: getAllVisiblePropertyDefinitionOids(data),
  };
};

const mapCriteria = (
  entityViewCriteria: gql.EntityViewCriteria,
  title: string,
  user: User,
  folder: Folder[],
  entityType: EntityType,
  propertyDefinitions: domain.EntityPropertyDefinition[]
): domain.EntityViewCriteria | undefined => {
  let filterItems: EntityFilterItem[] = [];
  let critiera: domain.EntityViewCriteria = {
    filterItems: filterItems,
  };
  if (entityViewCriteria.filter) {
    const filter: gql.EntityViewCriteriaFilter = entityViewCriteria.filter;
    if (filter.favorite) {
      filterItems.push({
        entityType: entityType as EntityType,
        filter: { favorite: filter.favorite, type: EntityFilterType.Favorite, uniqueId: 'favorite' },
        id: generateFilterId(EntityFilterType.Favorite, FilterIdentifier.Favorite),
        filterOptionIdentifier: FilterIdentifier.Favorite,
      });
    }
    if (filter.archived) {
      filterItems.push({
        entityType: entityType as EntityType,
        filter: { archived: filter.archived, type: EntityFilterType.Archived, uniqueId: 'archived' },
        id: generateFilterId(EntityFilterType.Archived, FilterIdentifier.Archived),
        filterOptionIdentifier: FilterIdentifier.Archived,
      });
    }
    if (filter.conversationType) {
      filterItems.push({
        entityType: entityType as EntityType,
        filter: {
          conversationType: filter.conversationType,
          type: EntityFilterType.ConversationType,
          uniqueId: 'conversation-type',
        },
        id: generateFilterId(EntityFilterType.ConversationType, FilterIdentifier.IndividualOrGroup),
        filterOptionIdentifier: FilterIdentifier.IndividualOrGroup,
      });
    }
    if (filter.folders) {
      let value: string[] = [];
      if (filter.folders.type === 'OwnFolders') {
        value = folder.filter((f) => f.user.oid.oid === user.oid).map((f) => f.oid.oid);
      } else {
        value = filter.folders.folder || [];
      }
      filterItems.push({
        entityType: entityType as EntityType,
        filter: { type: EntityFilterType.Folder, folderOids: value, uniqueId: 'folders' },
        id: generateFilterId(EntityFilterType.Folder, FilterIdentifier.Folder),
        filterOptionIdentifier: FilterIdentifier.Folder,
      });
    }
    if (filter.properties) {
      filter.properties.forEach((property) => {
        let value: string[] | undefined;
        const filterOptionIdentifier = getPropertyFilterIdentifier(propertyDefinitions, property.propertyDefinitionOid);
        if (property.propertyValueOid.type === 'OwnUserAsValue') {
          value = [user.oid];
        } else {
          value = property.propertyValueOid.value;
        }
        filterItems.push({
          entityType: entityType as EntityType,
          filter: {
            type: EntityFilterType.Property,
            propertyDefinitionOid: property.propertyDefinitionOid,
            propertyValueOids: value,
            noValue: false,
            uniqueId: property.propertyDefinitionOid,
          },
          filterOptionIdentifier: filterOptionIdentifier,
          id: generateFilterId(EntityFilterType.Property, filterOptionIdentifier),
        });
      });
    }
    if (filter.noPropertyValues) {
      filter.noPropertyValues.forEach((property) => {
        const filterOptionIdentifier = getPropertyFilterIdentifier(propertyDefinitions, property.propertyDefinitionOid);
        filterItems.push({
          entityType: entityType as EntityType,
          filter: {
            type: EntityFilterType.Property,
            propertyDefinitionOid: property.propertyDefinitionOid,
            propertyValueOids: [],
            noValue: true,
            uniqueId: property.propertyDefinitionOid,
          },
          filterOptionIdentifier: filterOptionIdentifier,
          id: generateFilterId(EntityFilterType.Property, filterOptionIdentifier),
        });
      });
    }
    if (filter.addedByUsers) {
      let value: string[] = [];
      if (filter.addedByUsers.type === 'OwnUserAsValue') {
        value = [user.oid];
      } else {
        value = filter.addedByUsers.value || [];
      }
      filterItems.push({
        entityType: entityType as EntityType,
        filter: {
          type: EntityFilterType.AddedByUser,
          userOids: value,
          uniqueId: 'added-by',
        },
        filterOptionIdentifier: FilterIdentifier.Owner,
        id: generateFilterId(EntityFilterType.AddedByUser, FilterIdentifier.AddedBy),
      });
    }
    if (filter.participants) {
      let value: string[] = [];
      if (filter.participants.type === 'OwnUserAsValue') {
        value = [user.oid];
      } else {
        value = filter.participants.value || [];
      }
      filterItems.push({
        entityType: entityType as EntityType,
        filter: {
          type: EntityFilterType.ChatMember,
          participantOids: value,
          uniqueId: 'chat-members',
        },
        filterOptionIdentifier: FilterIdentifier.ChatMembers,
        id: generateFilterId(EntityFilterType.ChatMember, FilterIdentifier.ChatMembers),
      });
    }
    if (filter.emptyFilterType) {
      filterItems.push({
        entityType: entityType as EntityType,
        filter: {
          type: EntityFilterType.FollowUpsAssignedToUser,
          onField: DateRangeField.LastFollowupDate,
          dateRangeId: '1',
          followUpsAssignedTo: [],
          noAssignee: true,
          uniqueId: 'follow-ups',
        },
        filterOptionIdentifier: FilterIdentifier.FollowUps,
        id: generateFilterId(EntityFilterType.FollowUpsAssignedToUser, FilterIdentifier.FollowUps),
      });
    }
  }
  if (entityViewCriteria.dateRange) {
    entityViewCriteria.dateRange.map((dateRange) => {
      let from: number | undefined = undefined;
      let to: number | undefined = undefined;
      let filterIdentitifier: FilterIdentifier | undefined = undefined;
      switch (dateRange.onField) {
        case gql.DateRangeField.DateAdded: {
          filterIdentitifier = FilterIdentifier.DateAdded;
          break;
        }
        case gql.DateRangeField.LastFollowupDate: {
          filterIdentitifier = FilterIdentifier.FollowUps;
          break;
        }
        case gql.DateRangeField.LastMessageDate: {
          filterIdentitifier = FilterIdentifier.LatestMessage;
          break;
        }
      }
      if (dateRange.type === 'LastDaysRange') {
        const { fromUtc, toUtc } = calculateUtcDateRange(dateRange.days);
        from = fromUtc;
        to = toUtc;
      }
      if (dateRange.type === 'TodayDateRange') {
        const { fromUtc, toUtc } = calculateUtcDateRange(dateRange.days);
        from = fromUtc;
        to = toUtc;
      }
      if (dateRange.type === 'FutureDateRange') {
        const { fromUtc, toUtc } = calculateUtcDateRange(dateRange.days);
        from = fromUtc;
        to = toUtc;
      }
      if (dateRange.type === 'DateRange') {
        from = dateRange.from?.utcTimeStampAsString
          ? new Date(dateRange.from?.utcTimeStampAsString).getTime()
          : undefined;
        to = dateRange.to?.utcTimeStampAsString ? new Date(dateRange.to?.utcTimeStampAsString).getTime() : undefined;
      }
      if (filterIdentitifier === FilterIdentifier.FollowUps) {
        let followupAssignee: string[] = [];
        if (entityViewCriteria.filter && entityViewCriteria.filter.followUpAssignedTo) {
          if (entityViewCriteria.filter.followUpAssignedTo.type === 'OwnUserAsValue') {
            followupAssignee = [user.oid];
          } else {
            followupAssignee = entityViewCriteria.filter.followUpAssignedTo.value;
          }
        }
        filterItems.push({
          entityType: entityType as EntityType,
          filter: {
            type: EntityFilterType.FollowUpsAssignedToUser,
            from: from,
            to: to,
            onField: dateRange.onField as DateRangeField,
            dateRangeId: '1',
            followUpsAssignedTo: followupAssignee,
            noAssignee: followupAssignee.length === 0,
            uniqueId: 'follow-ups',
          },
          filterOptionIdentifier: filterIdentitifier,
          id: generateFilterId(EntityFilterType.FollowUpsAssignedToUser, filterIdentitifier),
        });
      }
      if (filterIdentitifier === FilterIdentifier.DateAdded || filterIdentitifier === FilterIdentifier.LatestMessage) {
        filterItems.push({
          entityType: entityType as EntityType,
          filter: {
            type: EntityFilterType.DateRange,
            from: from,
            to: to,
            days: dateRange.days,
            onField: dateRange.onField as DateRangeField,
            uniqueId: 'date-added',
          },
          filterOptionIdentifier: filterIdentitifier,
          id: generateFilterId(EntityFilterType.DateRange, filterIdentitifier),
        });
      }
    });
  }

  if (entityViewCriteria.sort) {
    critiera = {
      ...critiera,
      sort: {
        direction: entityViewCriteria.sort.direction,
        field: entityViewCriteria.sort.field,
      },
    };
  }
  return critiera;
};

const getPropertyFilterIdentifier = (
  propertyDefinitions: domain.EntityPropertyDefinition[],
  propertyDefinitionOid: string
): FilterIdentifier => {
  const propertyDefinition = propertyDefinitions.find((p) => p.oid.oid === propertyDefinitionOid);
  let filterOptionIdentifier = FilterIdentifier.NoProperty;
  if (propertyDefinition) {
    switch (propertyDefinition.name) {
      case 'Categories':
        filterOptionIdentifier = FilterIdentifier.Category;
        break;
      case 'Owner':
        filterOptionIdentifier = FilterIdentifier.Owner;
        break;
      case 'Status':
        filterOptionIdentifier = FilterIdentifier.Status;
        break;
      default:
        filterOptionIdentifier = FilterIdentifier.Property;
        break;
    }
  }
  return filterOptionIdentifier;
};

const mapPropertyDefinitionIcon = (propertyDefinition: gql.EntityPropertyDefinition): keyof typeof IconsSvg => {
  if (propertyDefinition.icon && propertyDefinition.icon in IconsSvg) {
    return propertyDefinition.icon as keyof typeof IconsSvg;
  }
  switch (propertyDefinition.dataType) {
    case gql.PropertyDefinitionDataType.TenantUser: {
      return 'ic_user';
    }
    case gql.PropertyDefinitionDataType.Value: {
      return 'ic_abc';
    }
    case gql.PropertyDefinitionDataType.Option: {
      switch (propertyDefinition.category) {
        case 'STATUS': {
          return 'ic_status_change';
        }
        default: {
          return 'ic_circle_check';
        }
      }
    }
  }
};

const mapPropertyDefinition = (
  propertyDefinition: gql.EntityPropertyDefinition & { visible: boolean }
): domain.EntityPropertyDefinition => {
  return {
    name: propertyDefinition.name,
    dataType: propertyDefinition.dataType as unknown as domain.PropertyDefinitionDataType,
    oid: { oid: propertyDefinition.oid.oid },
    values: propertyDefinition.values
      .map((value) => mapPropertyValue(value))
      .filter((a) => !!a) as domain.PropertyValue[],
    description: propertyDefinition.description,
    kind: propertyDefinition.kind,
    icon: mapPropertyDefinitionIcon(propertyDefinition),
    visible: propertyDefinition.visible,
    category: propertyDefinition.category,
    ordering: propertyDefinition.ordering,
    organizationOid: propertyDefinition.organizationOid ? { oid: propertyDefinition.organizationOid } : undefined,
    isCustom: propertyDefinition.isCustom,
  };
};

const mapPropertyValue = (propertyValue: gql.PropertyValue): domain.PropertyValue | undefined => {
  if ('__typename' in propertyValue) {
    if (propertyValue.__typename === 'OptionPropertyValue') {
      const value: domain.OptionPropertyValue = {
        oid: { oid: propertyValue.oid.oid },
        ordering: propertyValue.ordering,
        propertyDefinitionOid: { oid: propertyValue.propertyDefinitionOid.oid },
        type: 'OPTION',
        value: propertyValue.value,
        color: propertyValue.color,
        description: propertyValue.description,
        icon: propertyValue.icon,
      };
      return value;
    }
    if (propertyValue.__typename === 'TenantUserPropertyValue') {
      const value: domain.TenantUserPropertyValue = {
        oid: { oid: propertyValue.oid.oid },
        propertyDefinitionOid: { oid: propertyValue.propertyDefinitionOid.oid },
        type: 'TENANT_USER',
        fullName: propertyValue.fullName,
        firstName: propertyValue.firstName,
        lastName: propertyValue.lastName,
        avatar: propertyValue.avatar,
        username: propertyValue.username,
      };
      return value;
    }
  } else if ('propertyDefinitionOid' in propertyValue && 'value' in propertyValue) {
    const value: domain.ValuePropertyValue = {
      propertyDefinitionOid: { oid: propertyValue.propertyDefinitionOid.oid },
      type: 'VALUE',
      value: propertyValue.value,
    };
    return value;
  }
  return undefined;
};

export const mapNavigationItemToDraftEntityView = (
  item: NavigationItem,
  propertyDefinitions: domain.EntityPropertyDefinition[]
): domain.DraftEntityView => {
  const initialPropertyDefinitions = INITIAL_VISUAL_TABLE_VIEW_COLUMNS.filter(
    (column) => column.type === domain.TableViewColumnEntity.Property
  ).map((column) => column.name);
  const initialEntityFields = INITIAL_VISUAL_TABLE_VIEW_COLUMNS.filter(
    (column) => column.type === domain.TableViewColumnEntity.Default
  ).map((column) => column.name) as domain.EntityViewField[];
  return {
    tempOid: { oid: item.id || uniqueId() },
    title: item.title,
    icon: item.icon,
    type: domain.ViewType.Draft,
    entityType: EntityType.Conversation,
    propertyDefinitions: propertyDefinitions.map((pd) => ({
      ...pd,
      visible: initialPropertyDefinitions.includes(pd.name),
    })),
    tableColumnOrder: [],
    visibleEntityFields: initialEntityFields,
  };
};

export const mapFilterItemsToEntityViewCriteria = (filters: EntityFilterItem[]): gql.EntityViewCriteriaInput => {
  let criteria: gql.EntityViewCriteriaInput = {};
  filters.map((filter) => {
    if (filter.filter.type === EntityFilterType.Favorite) {
      criteria = {
        ...criteria,
        filter: {
          ...criteria.filter,
          favorite: filter.filter.favorite,
        },
      };
    }
    if (filter.filter.type === EntityFilterType.ConversationType) {
      criteria = {
        ...criteria,
        filter: {
          ...criteria.filter,
          conversationType: filter.filter.conversationType,
        },
      };
    }
    if (filter.filter.type === EntityFilterType.Folder) {
      criteria = {
        ...criteria,
        filter: {
          ...criteria.filter,
          folders: {
            type: 'SelectedFolders',
            folder: filter.filter.folderOids,
          },
        },
      };
    }
    if (filter.filter.type === EntityFilterType.Property) {
      if (filter.filter.noValue) {
        criteria = {
          ...criteria,
          filter: {
            ...criteria.filter,
            noPropertyValues: [
              ...(criteria.filter?.noPropertyValues || []),
              {
                propertyDefinitionOid: filter.filter.propertyDefinitionOid,
              },
            ],
          },
        };
      } else {
        criteria = {
          ...criteria,
          filter: {
            ...criteria.filter,
            properties: [
              ...(criteria.filter?.properties || []),
              {
                propertyDefinitionOid: filter.filter.propertyDefinitionOid,
                propertyValueOid: {
                  type: 'SelectedValue',
                  value: filter.filter.propertyValueOids,
                },
              },
            ],
          },
        };
      }
    }
    if (filter.filter.type === EntityFilterType.AddedByUser) {
      criteria = {
        ...criteria,
        filter: {
          ...criteria.filter,
          addedByUsers: {
            type: 'SelectedUsers',
            value: filter.filter.userOids,
          },
        },
      };
    }
    if (filter.filter.type === EntityFilterType.ChatMember) {
      criteria = {
        ...criteria,
        filter: {
          ...criteria.filter,
          participants: {
            type: 'SelectedUsers',
            value: filter.filter.participantOids,
          },
        },
      };
    }
    if (filter.filter.type === EntityFilterType.FollowUpsAssignedToUser) {
      let type = gql.DateRangeType.LastDaysRange;
      let days: number | undefined = 365;
      if (filter.filter.from && filter.filter.to) {
        type = gql.DateRangeType.TodayDateRange;
        days = undefined;
      } else if (filter.filter.from && !filter.filter.to) {
        type = gql.DateRangeType.FutureDateRange;
        days = undefined;
      }
      if (filter.filter.noAssignee) {
        criteria = {
          ...criteria,
          filter: {
            ...criteria.filter,
            emptyFilterType: gql.EmptyFilterType.Followup,
          },
        };
      } else {
        criteria = {
          ...criteria,
          filter: {
            ...criteria.filter,
            followUpAssignedTo: {
              type: 'SelectedUsers',
              value: filter.filter.followUpsAssignedTo,
            },
          },
          dateRange: [
            ...(criteria.dateRange || []),
            {
              onField: filter.filter.onField as gql.DateRangeField,
              type: type,
              days: days,
            },
          ],
        };
      }
    }
    if (filter.filter.type === EntityFilterType.DateRange) {
      let type = gql.DateRangeType.LastDaysRange;
      let from = filter.filter.from;
      let to = filter.filter.to;
      if ((to || from) && filter.filter.days === undefined) {
        type = gql.DateRangeType.DateRange;
      } else if (filter.filter.days) {
        type = gql.DateRangeType.LastDaysRange;
        from = undefined;
        to = undefined;
      }
      criteria = {
        ...criteria,
        dateRange: [
          ...(criteria.dateRange || []),
          {
            onField: filter.filter.onField as gql.DateRangeField,
            type: type,
            days: filter.filter.days,
            from: from
              ? {
                  utcTimeStampAsString: new Date(from).toUTCString(),
                }
              : undefined,
            to: to
              ? {
                  utcTimeStampAsString: new Date(to).toUTCString(),
                }
              : undefined,
          },
        ],
      };
    }
    if (filter.filter.type === EntityFilterType.EmptyFilterType) {
      criteria = {
        ...criteria,
        filter: {
          ...criteria.filter,
          emptyFilterType: gql.EmptyFilterType.Followup,
        },
      };
    }
  });
  return criteria;
};

export const calculateUtcDateRange = (daysAgo: number): { fromUtc: number; toUtc: number } => {
  const now = new Date();
  const timezoneOffsetMinutes = now.getTimezoneOffset();
  const timezoneOffsetMilliseconds = timezoneOffsetMinutes * 60 * 1000;

  const from = new Date(now.getTime() - timezoneOffsetMilliseconds);
  from.setDate(from.getDate() - daysAgo);
  from.setHours(0, 0, 0, 0);
  const fromUtc = new Date(from.getTime() + timezoneOffsetMilliseconds).getTime();

  const to = new Date(now.getTime() - timezoneOffsetMilliseconds);
  to.setHours(23, 59, 59, 999);
  const toUtc = new Date(to.getTime() + timezoneOffsetMilliseconds).getTime();

  return { fromUtc, toUtc };
};

export const updateUserInValue = (value: domain.PropertyValue, user: User): domain.PropertyValue => {
  if ('type' in value && value.type === 'TENANT_USER' && value.oid.oid === user?.oid) {
    return {
      ...value,
      username: user.name,
      firstName: user.firstName,
      lastName: user.lastName,
      fullName: user.fullName,
      avatar: user.profileImageUrl,
    };
  }
  return value;
};

export const updateUserInPropertyDefinition = (entityViews: domain.EntityViews, user: User): domain.EntityViews => {
  const updatedProperties = entityViews.propertyDefinitions.map((pd) => {
    return {
      ...pd,
      values: pd.values.map((v) => {
        return updateUserInValue(v, user);
      }),
    };
  });
  return {
    ...entityViews,
    propertyDefinitions: updatedProperties,
  };
};

export const filterOutStickyColumnsAndNone = (columns: gql.EntityViewField[] | undefined) => {
  // TODO (AWE): Create universal sticky columns and dont refer chat table column names
  const stickColumnNames = STICKY_COLUMNS.map((column) => column.field);
  return columns?.filter((column) => !stickColumnNames.includes(column) && column !== gql.EntityViewField.None);
};

export const getAllVisiblePropertyDefinitionOids = (data: gql.EntityViewsResponseData | undefined) => {
  const visiblePropertyDefinitionOids: string[] = [];
  if (data) {
    const accumulator = new Set<string>();
    data.visiblePropertyDefinitionOids?.forEach((oid) => {
      accumulator.add(oid);
    });
    data.views?.forEach((view) => {
      view.visiblePropertyDefinitionOids?.forEach((oid) => {
        accumulator.add(oid);
      });
    });
    accumulator.forEach((oid) => {
      visiblePropertyDefinitionOids.push(oid);
    });
  }
  return visiblePropertyDefinitionOids;
};
