import { colors } from 'theme/colors';

export const followUpContainerStyle = {
  position: 'relative',
  '&:hover': {
    zIndex: 99,
    position: 'relative',
    '& .showActions': { opacity: 1 },
    cursor: 'pointer',
    backgroundColor: 'var(--joy-palette-primary-plainBorder)',
  },
};

export const emptyFollowUpStateStyle = {
  alignItems: 'center',
  flexDirection: 'row',
  cursor: 'pointer',
  gap: '6px',
};

export const detailsPageInnerContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  backgroundColor: colors.neutral[0],
  gap: '12px',
  p: '12px',
  boxShadow: 'none',
  MozBoxShadow: 'none',
  WebkitBoxShadow: 'none',
  width: '100%',
  maxWidth: '1024px',
  borderRadius: '0px',
  marginLeft: 'auto',
  marginRight: 'auto',
};

export const cardContainerStyle = {
  bgcolor: 'background.componentBg',
  borderRadius: 'sm',
};

export const propertiesListStyle = {
  p: '8px 8px 12px 8px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  '--List-decorator-size': '48px',
  '--List-item-minHeight': '1.5rem',
};

export const propertiesListItemStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  p: '0 8px',
  height: '32px',
  minHeight: '32px',
};

export const participantListStyle = {
  p: '12px 16px',
  gap: '8px',
  overflow: 'hidden',
};

export const notesSelectorStyle = {
  px: 1,
  borderRadius: '20px',
  height: '24px',
  width: 'auto',
  backgroundColor: colors.neutral[5],
  borderColor: colors.neutral[15],
  color: 'text.secondary',
  minHeight: '24px',
  fontSize: '13px',
  '&: hover': {
    color: 'text.secondary',
    backgroundColor: colors.neutral[10],
    borderColor: colors.neutral[20],
  },
  '& .JoySelect-indicator': {
    color: 'inherit',
    marginInlineStart: '3px',
    '--Icon-fontSize': '1rem',
  },
};

export const notesSelectorListStyle = {
  p: '1px',
  borderRadius: '8px',
  width: '120px !important',
  alignSelf: 'flex-end',
  '& .Joy-selected': {
    backgroundColor: `${colors.neutral[20]} !important`,
  },
};

export const notesSelectorListItemStyle = {
  borderRadius: '4px',
};

export const dotSpacerStyle = {
  borderRadius: '50%',
  width: '2px',
  height: '2px',
  background: 'var(--joy-palette-info-plainBorder)',
};

export const plusButtonStyle = {
  background: 'transparent',
  width: '12px',
  height: '12px',
  opacity: 0.7,
};

export const noteListStyle = {
  '--List-decorator-size': 'fit-content',
  padding: '8px 0',
  overflow: 'auto',
  maxHeight: { md: '70vh' },
  bgcolor: 'background.componentBg',
};
