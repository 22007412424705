import { useIsTelegramAccountConnectedLazyQuery } from 'services/models/api/generated';
import { useContext } from 'react';
import { TG_SESSION_STRING } from 'services/store/persistence/models/SessionString';
import { AppContext } from 'services/store/AppContext';
import _ from 'lodash';

// !! Namings can be improved
export const useStringSession = () => {
  const { tgSessionStringPersistence } = useContext(AppContext);
  // const [addStringSessionMutation] = useAddStringSessionMutation();

  const [isTelegramAccountConnectedQuery] = useIsTelegramAccountConnectedLazyQuery();
  // persists the session to the db
  const addStringSession = async (stringSession: string) => {
    // tgSessionStringPersistence.setItem(TG_SESSION_STRING, { sessionString: stringSession });
    // const { data } = await addStringSessionMutation({
    //   variables: {
    //     input: {
    //       stringSession,
    //     },
    //   },
    // });
    // return data;
  };
  // get's the session from the client persistence
  const getStringSession = () => {
    const sessionString = tgSessionStringPersistence.getItem(TG_SESSION_STRING);
    return sessionString?.sessionString;
  };
  // sets the session to the client persistence
  const setStringSession = (sessionString: string | null) => {
    tgSessionStringPersistence.setItem(TG_SESSION_STRING, { sessionString });
  };
  // remove the session from the client persistence
  const removeStringSession = () => {
    tgSessionStringPersistence.setItem(TG_SESSION_STRING, { sessionString: null });
  };

  return {
    addStringSession,
    getStringSession,
    setStringSession,
    removeStringSession,
    isTelegramAccountConnected: async () => {
      const result = await isTelegramAccountConnectedQuery();
      return !!result.data?.isTelegramAccountConnected?.data?.isTelegramAccountConnected;
    },
  };
};
