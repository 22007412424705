import React from 'react';
import { UIInputProps } from 'components/ui-library/Input/interface';
import { StyledInput } from 'components/ui-library/Input/styled';

const UIInput = (props: UIInputProps) => {
  const { rounded, size, ...rest } = props;
  const inputSize = size && size != 'xs' ? size : undefined;

  return <StyledInput {...rest} size={inputSize} rounded={rounded} inputSize={size} />;
};

export default UIInput;
