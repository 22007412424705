import { SxProps } from '@mui/material';

export const produceBaseLayoutStyles = (height: number) => {
  return { display: 'flex', justifyContent: 'center', alignItems: height < 900 ? 'flex-start' : 'center' };
};

export const produceStackStylesOne = (height: number, homepath: object | null) => {
  return {
    minHeight: { xs: height, md: '100vh' },
    height: 'auto',
    width: '100%',
    alignItems: 'center',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: { xs: homepath ? 'space-between' : 'flex-end', md: 'center' },
    textAlign: 'center',
  };
};

//for old layout
export const StackTwoStyles: SxProps = {
  alignItems: 'center',
  textAlign: 'center',
  position: 'relative',
  zIndex: 99,
  width: '100%',
};
