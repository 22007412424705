import { useDispatch } from 'react-redux';

import { deletePropertyValue } from 'services/store/slices/conversationPropertiesSlice';
import { DeletePropertyOptionValueInput, useDeletePropertyOptionValueMutation } from 'services/models/api/generated';

export const useDeletePropertyValue = (propertyOptionOid: string, refetch: () => Promise<any>) => {
  const [deletePropertyValueMutation] = useDeletePropertyOptionValueMutation();
  const dispatch = useDispatch();

  const submitDeletePropertyValue = async () => {
    dispatch(deletePropertyValue(propertyOptionOid));

    const input: DeletePropertyOptionValueInput = {
      propertyOptionOid,
    };

    await deletePropertyValueMutation({
      variables: { input },
      onCompleted: () => {
        refetch();
      },
    });
  };

  return {
    submitDeletePropertyValue,
  };
};
