import { GridColDef } from '@mui/x-data-grid-pro';
import { EntityViewField } from 'services/models/domain/entityViews';
import { colors } from 'theme/colors';

export const CHATS_DEFAULT_COLUMN_NAMES = {
  ...EntityViewField,
  VisibilityControl: 'VisibilityControl',
  SelectionColumn: '__check__',
};

export const STICKY_COLUMNS: GridColDef[] = [
  {
    field: CHATS_DEFAULT_COLUMN_NAMES.SelectionColumn,
    headerName: '',
    width: 32,
    maxWidth: 32,
    minWidth: 32,
    sortable: false,
    headerClassName: 'no-right-border',
    hideable: false,
  },
  {
    field: CHATS_DEFAULT_COLUMN_NAMES.Name,
    headerName: 'Name',
    width: 276,
    sortable: false,
    headerClassName: 'sticky-column-border',
    cellClassName: 'sticky-column-border',
    hideable: false,
  },
];

export const DEFAULT_COLUMNS: Record<string, GridColDef> = {
  [CHATS_DEFAULT_COLUMN_NAMES.LatestMessage]: {
    field: CHATS_DEFAULT_COLUMN_NAMES.LatestMessage,
    headerName: 'Latest Message',
    width: 175,
    hideable: true,
  },
  [CHATS_DEFAULT_COLUMN_NAMES.Folder]: {
    field: CHATS_DEFAULT_COLUMN_NAMES.Folder,
    headerName: 'Folder',
    width: 200,
    sortable: false,
    hideable: true,
  },
  [CHATS_DEFAULT_COLUMN_NAMES.FollowUp]: {
    field: CHATS_DEFAULT_COLUMN_NAMES.FollowUp,
    headerName: 'Follow Up',
    width: 200,
    hideable: true,
  },
  [CHATS_DEFAULT_COLUMN_NAMES.Note]: {
    field: CHATS_DEFAULT_COLUMN_NAMES.Note,
    headerName: 'Note',
    width: 400,
    minWidth: 400,
    hideable: true,
  },
  [CHATS_DEFAULT_COLUMN_NAMES.DateAdded]: {
    field: CHATS_DEFAULT_COLUMN_NAMES.DateAdded,
    headerName: 'Date Added',
    width: 170,
    hideable: true,
  },
  [CHATS_DEFAULT_COLUMN_NAMES.LastModified]: {
    field: CHATS_DEFAULT_COLUMN_NAMES.LastModified,
    headerName: 'Last Modified',
    width: 170,
    hideable: true,
  },
  [CHATS_DEFAULT_COLUMN_NAMES.VisibilityControl]: {
    field: CHATS_DEFAULT_COLUMN_NAMES.VisibilityControl,
    flex: 1,
    hideable: false,
    disableReorder: true,
  },
};

export const DEFAULT_NEW_OPTION_FIELDS = {
  color: colors.white,
  value: '',
  description: '',
  icon: 'ic_check',
};
