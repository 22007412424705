import { Box, Typography } from '@mui/joy';
import { IconsSvg } from 'assets/icons';
import UIChip from 'components/ui-library/Chip/UIChip';
import { useEffect, useState } from 'react';
import { colors } from 'theme/colors';

interface Props {
  variant: 'plain' | 'outlined';
  color: 'neutral' | 'primary';
  onClick: () => void;
  title: string;
  icon: string;
  onCloseIconClicked: () => void | undefined;
  showUnsavedStatus: boolean;
  prefix?: string;
  suffix?: string;
}

export default function ToolBarChip({
  variant,
  color,
  onClick,
  icon,
  title,
  onCloseIconClicked,
  prefix,
  suffix,
  showUnsavedStatus,
}: Props) {
  const Icon = IconsSvg[icon as keyof typeof IconsSvg];
  const [showUnsavedDot, setShowUnsavedDot] = useState(false);

  useEffect(() => {
    setShowUnsavedDot(false);
    const timer = setTimeout(() => {
      setShowUnsavedDot(showUnsavedStatus);
    }, 100);

    return () => clearTimeout(timer);
  }, [showUnsavedStatus]);

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box display="flex" flexDirection="row" sx={{ ...styles.chipContainer }}>
        <UIChip
          variant={variant}
          color={color}
          size="sm"
          sx={styles.filterChip}
          key={title}
          startDecorator={
            <Icon width={12} height={12} stroke={colors.primary[50]} style={{ marginTop: '1px', marginLeft: '-2px' }} />
          }
          onClick={() => onClick()}
        >
          <Box sx={{ display: 'flex', gap: '3px' }}>
            {prefix && <Typography level="tiny">{prefix}</Typography>}
            <Typography level="tiny+">{title}</Typography>
            {suffix && <Typography level="tiny">{suffix}</Typography>}
          </Box>
        </UIChip>
        {onCloseIconClicked && (
          <IconsSvg.ic_x
            width={12}
            height={12}
            stroke={colors.primary[50]}
            style={{ marginTop: '2px', marginRight: '5px', cursor: 'pointer' }}
            onClick={() => onCloseIconClicked()}
          />
        )}
        {showUnsavedDot && <Box sx={styles.unsavedStatus} />}
      </Box>
    </Box>
  );
}

const styles = {
  filterChip: {
    borderRadius: '50px',
    fontWeight: 500,
    fontSize: '14px',
    color: colors.primary[50],
  },
  chipContainer: {
    border: `1px solid ${colors.primary[10]}`,
    borderRadius: '15px',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '28px',
    '&: hover': {
      borderColor: colors.primary[20],
    },
  },
  unsavedStatus: {
    backgroundColor: colors.primary[50],
    borderRadius: '50%',
    width: '8px',
    height: '8px',
    alignSelf: 'flex-start',
  },
};
