import { useState } from 'react';
import { Box, Divider, Tooltip, Typography } from '@mui/joy';

import { IconsSvg } from 'assets/icons';
import CellMenu from 'components/ui-library/chat/CellMenu/CellMenu';
import UIInput from 'components/ui-library/Input/UIInput';
import Button from 'components/ui-library/Button/UIButton';
import {
  PROPERTY_COLORS,
  PROPERTY_ICONS,
} from 'components/Chats/ChatTableV2/EditProperty/AddEditPropertyValue/constants';
import { useEditProperty } from 'services/hooks/useEditProperty';
import { useDeletePropertyValue } from 'services/hooks/useDeletePropertyValue';
import { AddEditPropertyValueProps } from 'components/Chats/ChatTableV2/EditProperty/AddEditPropertyValue/interface';
import {
  ColorIcon,
  ContainerBoxStyles,
  GridSectionStyles,
  InputSectionStyles,
  DividerSx,
  GridIconStyled,
  MenuSx,
  DeleteSectionStyles,
  TextStyles,
  DeleteBtnStyles,
  ConfirmBtnStyles,
  SaveBtnStyles,
  CancelBtnStyles,
} from 'components/Chats/ChatTableV2/EditProperty/AddEditPropertyValue/styles';
import Translations from 'const/translations/en';

const TrashIcon = IconsSvg.ic_trash;
const CheckIcon = IconsSvg.ic_check;

const AddEditPropertyValue = ({
  children,
  propertyValue,
  refetch,
  anchorEl,
  setAnchorEl,
}: AddEditPropertyValueProps) => {
  const [confirmDeleteShown, setConfirmDeleteShown] = useState(false);
  const { isActiveField, setField, name } = useEditProperty(propertyValue.option, refetch);
  const { submitDeletePropertyValue } = useDeletePropertyValue(propertyValue.option.oid.oid, refetch);

  const deleteButtonHandler = () => {
    setConfirmDeleteShown(true);
  };

  const disableDeleteButton = !propertyValue.option.oid.oid;

  const propertyIconsWithEmpty = [null, ...PROPERTY_ICONS];

  const isAddingProperty = !!propertyValue.newValueTempId && anchorEl;

  const savePropertyValue = () => {
    setAnchorEl && setAnchorEl(null);
  };

  return (
    <CellMenu
      anchorComponent={children}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={MenuSx}
      anchorEl={anchorEl}
      onClose={() => {
        if (isAddingProperty) {
          submitDeletePropertyValue();
        } else {
          setConfirmDeleteShown(false);
        }
      }}
    >
      <Box sx={ContainerBoxStyles}>
        <Box sx={InputSectionStyles}>
          <UIInput
            value={name}
            size="sm"
            onChange={(e) => setField(e.target.value, 'value')}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </Box>

        <Divider sx={DividerSx} />

        <Box sx={GridSectionStyles}>
          {propertyIconsWithEmpty.map((icon, i) => {
            if (icon === null) {
              return (
                <GridIconStyled
                  key={i}
                  isActive={isActiveField(null, 'icon')}
                  onClick={() => setField(null, 'icon')}
                ></GridIconStyled>
              );
            }
            const Icon = IconsSvg[icon];

            return (
              <GridIconStyled key={i} isActive={isActiveField(icon, 'icon')} onClick={() => setField(icon, 'icon')}>
                <Icon width={14} height={14} />
              </GridIconStyled>
            );
          })}
        </Box>
        <Divider sx={DividerSx} />
        <Box sx={GridSectionStyles}>
          {PROPERTY_COLORS.map((color, i) => (
            <GridIconStyled
              key={i}
              isActive={isActiveField(color, 'color')}
              onClick={() => setField(color, 'color')}
              isColor
            >
              <ColorIcon color={color} />
            </GridIconStyled>
          ))}
        </Box>
        <Divider sx={DividerSx} />
        <Box sx={DeleteSectionStyles}>
          {isAddingProperty && (
            <Button variant="plain" size="sm" sx={CancelBtnStyles} onClick={submitDeletePropertyValue}>
              <Typography level="micro" sx={TextStyles}>
                {Translations.CHAT_TABLE_PROPERTY_CANCEL}
              </Typography>
            </Button>
          )}
          {isAddingProperty && name && (
            <Button
              variant="plain"
              size="sm"
              startDecorator={<CheckIcon width={12} height={12} />}
              sx={SaveBtnStyles}
              onClick={savePropertyValue}
            >
              <Typography level="micro" sx={TextStyles}>
                {Translations.CHAT_TABLE_PROPERTY_SAVE}
              </Typography>
            </Button>
          )}
          {!isAddingProperty && (
            <Button
              variant="plain"
              size="sm"
              startDecorator={<TrashIcon width={12} height={12} />}
              sx={DeleteBtnStyles(confirmDeleteShown)}
              onClick={deleteButtonHandler}
              disabled={disableDeleteButton || confirmDeleteShown}
            >
              <Typography level="micro" sx={TextStyles}>
                {Translations.CHAT_TABLE_PROPERTY_DELETE}
              </Typography>
            </Button>
          )}
          {!isAddingProperty && confirmDeleteShown && (
            <Tooltip
              title={<Typography level="tiny+">{Translations.CONFIRM_DELETE}</Typography>}
              arrow
              open={confirmDeleteShown}
              componentsProps={{
                root: {
                  sx: {
                    p: '6px 12px',
                    borderRadius: '6px',
                  },
                },
              }}
            >
              <Box onClick={submitDeletePropertyValue} sx={ConfirmBtnStyles}>
                <CheckIcon width={14} height={14} />
              </Box>
            </Tooltip>
          )}
        </Box>
      </Box>
    </CellMenu>
  );
};

export default AddEditPropertyValue;
