import { map } from 'lodash';
import * as api from 'services/models/api/generated';
import { TimeStamp } from 'services/models/domain/commonTypes';
import { AssociatedConversation, ContactDetails } from 'services/models/domain/contact';
import { User } from 'services/models/domain/user';
import { updateUserInNote } from './noteUtils';
import { mapOtherMembersInOrganization } from './userUtils';

export const mapContactToDomain = (contact: api.Contact): ContactDetails => {
  return {
    name: contact.name,
    bio: contact.bio,
    oid: { oid: contact.oid.oid },
    participantOid: { oid: contact.participantOid.oid },
    telegramLink: contact.telegramLink,
    telegramHandle: contact.telegramHandle,
    associatedConversations: contact.associatedConversations.map(mapAssociatedConversationsToDomain),
    notes: contact.notes.map((note) => {
      let updatedAt: TimeStamp | undefined = undefined;
      if (note.updatedAt && note.updatedAt.utcTimeStampAsString) {
        updatedAt = {
          utcTimeStampAsString: note.updatedAt.utcTimeStampAsString,
        };
      }
      return {
        createdAt: {
          utcTimeStampAsString: note.createdAt.utcTimeStampAsString,
        },
        createdByUserOid: note.createdByUserOid,
        oid: note.oid,
        text: note.text,
        user: mapOtherMembersInOrganization(note.user),
        updatedAt: updatedAt,
      };
    }),
  };
};

export const mapAssociatedConversationsToDomain = (
  conversation: api.AssociatedConversations
): AssociatedConversation => {
  return {
    conversationOid: { oid: conversation.conversationOid.oid },
    title: conversation.title,
    description: conversation.description,
    chatLink: conversation.chatLink,
  };
};

export const updateUserInContacts = (contacts: ContactDetails[], user: User): ContactDetails[] => {
  return map(contacts, (contact) => {
    return {
      ...contact,
      notes: contact.notes?.map((note) => updateUserInNote(note, user)),
    };
  });
};
