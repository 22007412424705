import { Box, Checkbox } from '@mui/joy';
import { MenuSx, TableHeaderButton, TableHeaderStyles } from 'components/Chats/ChatTableV2/TableHeader/styles';
import CellMenu from 'components/ui-library/chat/CellMenu/CellMenu';
import { useSelector } from 'react-redux';
import { RootState } from 'services/store/AppStore';
import { IconsSvg } from 'assets/icons';
import EditProperty from 'components/Chats/ChatTableV2/EditProperty/EditProperty';
import HeaderMenu from 'components/Chats/ChatTableV2/TableHeader/HeaderMenu/HeaderMenu';
import { getFieldSortStatus } from 'services/utils/conversationTableUtils';
import { useTableHeaderNavigation, TableHeaderNavigationState } from 'services/hooks/useTableHeaderNavigation';
import { usePropertiesData } from 'services/hooks/usePropertiesData';
import ColumnVisibilityControl from 'components/Chats/ChatTableV2/TableHeader/ColumnVisibilityControl/ColumnVisibilityControl';
import { CHATS_DEFAULT_COLUMN_NAMES } from 'components/Chats/ChatTableV2/constants';
import { FeatureFlags } from 'services/models/domain/featureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEntityViewFields } from 'services/hooks/useEntityViewFields';
import { EntityViewField } from 'services/models/domain/entityViews';
import { CheckboxSx } from 'components/FilterCheckList/style';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import ArchiveSnackBar from './ArchiveSnackBar';
import { useState } from 'react';
import DeletePropertyModal from 'page/modal/DeletePropertyModal';
import { usePropertyDefinitions } from 'services/hooks/usePropertyDefinitions';
import { GridColumnHeaderParams } from '@mui/x-data-grid-pro';
import { EntityPropertyDefinition } from 'services/models/domain/entityViews';
import ColumnVisibilityControlEdit from './ColumnVisibilityControl/ColumnVisibilityControlEdit';

const UpIcon = IconsSvg.ic_arrow_up;
const DownIcon = IconsSvg.ic_arrow_down;

export type TableHeaderProps = Omit<GridColumnHeaderParams, 'property' | 'refetchEntity'> & {
  property?: EntityPropertyDefinition;
  refetch?: () => Promise<void>;
};

const TableHeader = (props: TableHeaderProps) => {
  const flags = useFlags<FeatureFlags>();
  const { property, refetch } = props;
  const { sortable, field, headerName, hideable } = props.colDef;
  const { navigate, navigationState } = useTableHeaderNavigation();
  const { removeVisiblePropertyDefinitionFromView } = usePropertiesData();
  const { removeVisibleEntityFieldFromView } = useEntityViewFields();
  const { updatePropertyDefinition, deletePropertyDefinition } = usePropertyDefinitions();
  const isCustomProperty = property?.isCustom;

  const apiRef = useGridApiContext();
  const totalRows = apiRef.current.getRowsCount();
  const selectedRows = apiRef.current.getSelectedRows().size;
  const isChecked = selectedRows === totalRows && totalRows > 0;
  const isIndeterminate = selectedRows > 0 && selectedRows < totalRows;

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const onClick = (selected: boolean) => {
    apiRef.current.selectRows(apiRef.current.getAllRowIds(), selected);
  };

  const navigateBack = () => {
    navigate.Select();
  };

  const onHideColumn = () => {
    if (!!property) {
      removeVisiblePropertyDefinitionFromView(property.oid.oid);
    } else {
      removeVisibleEntityFieldFromView(field as EntityViewField);
    }
  };

  const onDeleteColumn = () => {
    isCustomProperty && setDeleteModalOpen(true);
  };

  const onRename = (oid: string, updatedName: string) => {
    isCustomProperty && updatePropertyDefinition(oid, updatedName);
    navigateBack();
  };

  const onCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const onDelete = () => {
    isCustomProperty && deletePropertyDefinition(property.oid.oid);
  };

  const isContextMenuActive = !!property || sortable || hideable;
  const filterForm = useSelector((state: RootState) => state.chatFilters);
  const { sortOnProperties } = filterForm.input.sortOn;

  const { isSortingActive, isDesc } = getFieldSortStatus(sortOnProperties, field, property);

  const renderArrow = isSortingActive && (
    <>{isDesc ? <DownIcon width={10} height={10} /> : <UpIcon width={10} height={10} />}</>
  );
  if (!flags.multiSelect && props.field === CHATS_DEFAULT_COLUMN_NAMES.SelectionColumn) {
    return (
      <>
        <Checkbox
          sx={{ marginLeft: '15px', ...CheckboxSx(isChecked, isIndeterminate) }}
          checked={isChecked}
          indeterminate={isIndeterminate}
          onChange={(event: any) => {
            onClick(event.target.checked || event.target.indeterminate);
          }}
        />
        <ArchiveSnackBar />
      </>
    );
  }

  if (props.field === CHATS_DEFAULT_COLUMN_NAMES.VisibilityControl) {
    return <ColumnVisibilityControl />;
  }

  const navigations = () => {
    switch (navigationState) {
      case TableHeaderNavigationState.Select:
        return (
          <HeaderMenu
            handleMenuClose={navigateBack}
            isCustomProperty={isCustomProperty}
            hideable={hideable}
            sortable={sortable}
            onEditValues={() => navigate.EditValues()}
            onRename={() => navigate.Rename()}
            onHide={onHideColumn}
            onDelete={onDeleteColumn}
            field={field}
            propertyType={property?.dataType}
            isSortingActive={isSortingActive}
            propertyOid={property?.oid.oid}
          />
        );
      case TableHeaderNavigationState.EditValues:
        return <EditProperty handleBackClick={navigateBack} property={property!} refetch={refetch!} />;
      case TableHeaderNavigationState.Rename:
        return (
          isCustomProperty && (
            <ColumnVisibilityControlEdit
              propertyDefinitionOid={property.oid.oid}
              initialName={property.name}
              navigateBack={navigateBack}
              onEditPropertyDefinition={(oid, updateName) => onRename(oid, updateName)}
            />
          )
        );
    }
  };
  if (isContextMenuActive) {
    return (
      <>
        <CellMenu
          anchorComponent={
            <TableHeaderButton isActive={isSortingActive}>
              <Box>{headerName}</Box>
              {renderArrow}
            </TableHeaderButton>
          }
          sx={MenuSx}
          onClose={navigateBack}
        >
          {navigations()}
        </CellMenu>
        <DeletePropertyModal isOpen={isDeleteModalOpen} onClose={onCloseDeleteModal} onDelete={onDelete} />
      </>
    );
  }

  return <Box sx={TableHeaderStyles}>{headerName}</Box>;
};

export default TableHeader;
