import { Chip, styled } from '@mui/joy';
import { colors } from 'theme/colors';
import { ChatChipStyle } from 'components/ui-library/chat/Chip/interface';

export const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'chipColor' && prop !== 'isWhite' && prop !== 'chipStyle',
})(({ chipColor, isWhite, chipStyle }: { chipColor?: string; isWhite: boolean; chipStyle: ChatChipStyle }) => ({
  '--Chip-gap': '6px',
  padding: '4px 10px',
  fontSize: '12px',
  lineHeight: '12px',
  minHeight: chipStyle === ChatChipStyle.Round ? '24px' : '21px',
  height: chipStyle === ChatChipStyle.Round ? '24px' : '21px',
  borderRadius: chipStyle === ChatChipStyle.Round ? '24px' : '4px',
  width: 'fit-content',
  color: isWhite ? `${colors.neutral[50]}` : `${chipColor} !important`,
  backgroundImage: `linear-gradient(${chipColor}0D, ${chipColor}0D), linear-gradient(${colors.white}, ${colors.white})`,
  border: isWhite ? `1px solid ${colors.neutral[5]} !important` : `1px solid ${chipColor}0F !important`,

  '& button': {
    backgroundColor: 'inherit !important',
    color: 'inherit !important',
  },

  '& .MuiSvgIcon-root': {
    width: 8,
  },

  '& .JoyChip-startDecorator': {
    '--Icon-margin': 0,
    marginRight: 5,
  },

  '& .JoyChip-endDecorator': {
    svg: {
      stroke: isWhite ? `${colors.neutral[50]}` : `${chipColor} !important`,
    },
    pointerEvents: 'auto',
  },
  '& .JoyChip-label': {
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));
