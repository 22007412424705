import { SxProps } from '@mui/material';
import { colors } from 'theme/colors';

export const ContainerBoxStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  background: colors.white,
  width: '100%',
};

export const PropertiesBoxStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '4px 0px',
};

export const DividerSx: SxProps = {
  '--Divider-lineColor': colors.neutral[5],
  backgroundColor: colors.neutral[5],
};
