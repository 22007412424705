import { Box, Modal, ModalClose, Sheet } from '@mui/joy';
import { Backdrop, alpha } from '@mui/material';
import NavigationTabs, { TabStyle } from 'page/dashboard/header/NavigationTabs';
import { ModalStyle } from './styles';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const HamburgerMenu = ({ isOpen, setIsOpen }: Props) => {
  return (
    <>
      <Backdrop sx={styles.backdrop} open={isOpen} />
      <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={ModalStyle}>
        <Sheet sx={styles.sheet}>
          <Box sx={styles.box}>
            <NavigationTabs style={TabStyle.Vertical} onChange={() => setIsOpen(false)} />
            <ModalClose sx={styles.modalClose} />
          </Box>
        </Sheet>
      </Modal>
    </>
  );
};

const styles = {
  backdrop: {
    color: alpha('#0F1012', 0.2),
    zIndex: 999,
    opacity: '0.93px',
    backgroundColor: alpha('#0F1012', 0.93),
  },
  modal: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  sheet: {
    width: { xs: '50%', md: '50%' },
    height: '100%',
    borderRadius: '0px',
    minWidth: '300px',
  },
  box: {
    flexDirection: 'row',
    display: 'flex',
    px: 3,
    py: 1.5,
    position: 'relative',
  },
  modalClose: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

export default HamburgerMenu;
