import Button from 'components/ui-library/Button/UIButton';
import ChatChip from 'components/ui-library/chat/Chip/ChatChip';
import {
  ButtonSx,
  DividerSx,
  MenuSx,
  ChipMarginStyle,
} from 'components/Chats/ChatTableV2/TableCell/customCells/OptionProperty/styles';
import { OptionPropertyProps } from 'components/Chats/ChatTableV2/TableCell/customCells/OptionProperty/interface';
import CellMenu from 'components/ui-library/chat/CellMenu/CellMenu';
import OptionsMenu from 'components/Chats/ChatTableV2/TableCell/customCells/OptionProperty/OptionsMenu/OptionsMenu';
import EditProperty from 'components/Chats/ChatTableV2/EditProperty/EditProperty';
import CellMenuButton from 'components/ui-library/chat/CellMenu/СellMenuButton/CellMenuButton';
import { useMenuNavigation } from 'services/hooks/useMenuNavigation';
import { Divider } from '@mui/joy';
import { OptionPropertyValue, PropertyDefinitionKind } from 'services/models/domain/entityViews';
import { ChatChipStyle } from 'components/ui-library/chat/Chip/interface';
import { MutiRowBox } from 'components/MultiRowBox';

const OptionProperty = ({
  values: initialValue,
  propertyDefinition,
  refetch,
  renderEmpty,
  renderProperty,
  handleSelect,
  handleUnset,
}: OptionPropertyProps) => {
  const selectedValues = initialValue;

  const definitionValues = propertyDefinition.values as OptionPropertyValue[];
  const selectedProperties = definitionValues.filter(({ oid }) => selectedValues.includes(oid.oid));

  const { isEditNav, handleEditClick, handleBackClick } = useMenuNavigation();

  const isMulti = propertyDefinition.kind === PropertyDefinitionKind.MultiValue;

  const renderPropertyChip = (propertyValue: OptionPropertyValue) => {
    return (
      <ChatChip
        key={`${propertyValue.oid.oid}-chip`}
        chipColor={propertyValue.color}
        icon={propertyValue.icon}
        sx={ChipMarginStyle(isMulti)}
        chipStyle={isMulti ? ChatChipStyle.Edged : ChatChipStyle.Round}
      >
        {propertyValue.value}
      </ChatChip>
    );
  };
  return (
    <CellMenu
      onClose={handleBackClick}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchorComponent={
        renderEmpty && selectedProperties.length === 0 ? (
          renderEmpty()
        ) : renderProperty ? (
          renderProperty(definitionValues, selectedValues)
        ) : (
          <Button id="property-menu-button" aria-haspopup="true" variant="plain" sx={ButtonSx}>
            <MutiRowBox maxLines={2} showMultiLines={isMulti}>
              {selectedProperties.map(renderPropertyChip)}
            </MutiRowBox>
          </Button>
        )
      }
      sx={MenuSx}
    >
      {(handleMenuClose) =>
        !isEditNav ? (
          <div>
            <OptionsMenu
              handleSelect={handleSelect}
              handleMenuClose={handleMenuClose}
              handleUnset={handleUnset}
              definitionValues={definitionValues}
              selectedProperties={selectedProperties}
              propertyDefinition={propertyDefinition}
            />
            <Divider sx={DividerSx} />
            <CellMenuButton title="Edit property" icon="ic_edit_column" onClick={handleEditClick} />
          </div>
        ) : (
          <EditProperty handleBackClick={handleBackClick} property={propertyDefinition} refetch={refetch} />
        )
      }
    </CellMenu>
  );
};

export default OptionProperty;
