import { styled } from '@mui/material';

export const MobileModalButton = styled('div')(() => ({
  height: 3,
  width: 48,
  borderRadius: 100,
  background: '#C0C9D0',
  paddingLeft: 'auto',
  paddingRight: 'auto',
  paddingTop: 12,
  paddingBottom: 26,
}));

export const NotesContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'start',
  flexDirection: 'column',
  gap: 16,
  background: 'white',
  borderRadius: 10,
  padding: '8px 4px 8px 0',
  overflow: 'scroll',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  width: '400px',
  minWidth: '350px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  maxHeight: '85vh',
  '& .MuiBackdrop-root': {
    borderRadius: 10,
  },
  '@media screen and (max-width: 600px)': {
    padding: '12px 16px 16px 16px',
    width: '100%',
    minWidth: '100%',
    height: '75%',
    maxHeight: '75%',
    borderRadius: 0,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    '& .MuiBackdrop-root': {
      borderRadius: 0,
    },
  },
}));
