import { Box, Typography } from '@mui/joy';
import { useRef, useState } from 'react';
import { EntityFilterItem, FilterIdentifier, FilterOption } from 'services/models/domain/entityFilter';
import { IconsSvg } from 'assets/icons';
import UIChip from 'components/ui-library/Chip/UIChip';
import CellMenu from 'components/ui-library/chat/CellMenu/CellMenu';
import { MenuSx } from 'components/ui-library/chat/CellMenu/styled';
import Translations from 'const/translations/en';
import _ from 'lodash';
import { FilterOptionMenu } from 'page/chats/ChatTableFilterBar/FilterOptionMenu';
import ToolBarChip from 'page/chats/ChatTableFilterBar/ToolBarChip';
import { addValueForFilterOptionWithFilterItem } from 'services/utils/chatTableFilterUtils';
import { colors } from 'theme/colors';
import { useFilterOptions } from 'services/hooks/useFilterOptions';

interface FilterToolBarProps {
  activeFilters: EntityFilterItem[];
  filterOptions: FilterOption[][];
  presetFilters?: EntityFilterItem[];
  onSelectFilterOption: (filterOption: FilterOption) => void;
  onRemoveFilterItem: (filterItem: EntityFilterItem) => void;
}

export default function FilterToolBar({
  activeFilters,
  onSelectFilterOption,
  filterOptions,
  onRemoveFilterItem,
  presetFilters,
}: FilterToolBarProps) {
  const { getIconForFilterItem, getLabelForFilterItem } = useFilterOptions();

  const [showFilterOptionList, setShowFilterOptionList] = useState<boolean>(false);
  const [selectedFilterOption, setSelectedFilterOption] = useState<FilterOption | undefined>(undefined);
  const ref = useRef<HTMLElement>(null);

  const [element, setElement] = useState<HTMLElement | null>(null);

  const getEnrichedFilterOption = (item: EntityFilterItem) => {
    const filterOption = filterOptions.flat().find((fo) => fo.uniqueId === item.filter.uniqueId);
    let enrichedFilterOption = filterOption;
    if (filterOption) {
      if (selectedFilterOption && 'value' in selectedFilterOption) {
        enrichedFilterOption = selectedFilterOption;
      } else {
        enrichedFilterOption = addValueForFilterOptionWithFilterItem(filterOption, item);
      }
    }
    return enrichedFilterOption;
  };

  const getStatusForFilterItem = (item: EntityFilterItem) => {
    if (!presetFilters || presetFilters.length === 0) return false;
    const presetFilter = presetFilters.find((pf) => pf.filterOptionIdentifier === item.filterOptionIdentifier);
    return !_.isEqual(item.filter, presetFilter?.filter);
  };

  const renderUIChip = (item: EntityFilterItem) => {
    const label = getLabelForFilterItem(item);
    const icon = getIconForFilterItem(item);
    const open = selectedFilterOption?.identifier === item.filterOptionIdentifier && !showFilterOptionList;
    const enrichedFilterOption = getEnrichedFilterOption(item);
    const unsavedState = getStatusForFilterItem(item);
    const chip = (
      <ToolBarChip
        color={'neutral'}
        variant={'plain'}
        icon={icon}
        title={label.title}
        prefix={label.prefix}
        showUnsavedStatus={unsavedState}
        onClick={() => {
          if (item.filterOptionIdentifier === FilterIdentifier.Favorite) return;
          setSelectedFilterOption(enrichedFilterOption);
        }}
        onCloseIconClicked={() => {
          onRemoveFilterItem(item);
        }}
      />
    );
    return (
      <CellMenu
        onClose={() => {
          onCloseMenu();
        }}
        open={open}
        anchorComponent={chip}
        sx={{
          ...MenuSx,
          maxHeight: 'calc(100vh - 150px)',
          '& .MuiPopover-paper': {
            borderRadius: '6px',
          },
        }}
      >
        {renderFilterOptionMenu(enrichedFilterOption)}
      </CellMenu>
    );
  };

  const onCloseMenu = () => {
    if (selectedFilterOption) {
      onSelectFilterOption(selectedFilterOption);
    }
    setSelectedFilterOption(undefined);
    setShowFilterOptionList(false);
  };

  const renderFilterOptionMenu = (enrichedFilterOption?: FilterOption) => {
    return (
      <FilterOptionMenu
        filterOptions={filterOptions}
        selectedFilterOption={enrichedFilterOption}
        onSelectFilterOption={(filterOption) => {
          if ('subItems' in filterOption) {
            setSelectedFilterOption(filterOption);
          } else {
            onSelectFilterOption(filterOption);
            setShowFilterOptionList(false);
            setSelectedFilterOption(undefined);
          }
        }}
        onClose={onCloseMenu}
      />
    );
  };

  const renderFilterSelector = () => {
    const Icon = IconsSvg['ic_plus' as keyof typeof IconsSvg];
    const chip = (
      <UIChip
        id="add-filter-btn"
        variant={'plain'}
        color={'neutral'}
        size="md"
        sx={{ ...styles.filterChipButton, border: `1px solid ${colors.neutral[10]}` }}
        startDecorator={<Icon width={12} height={12} stroke={colors.neutral[50]} style={{ marginTop: '1px' }} />}
        onClick={() => {
          setShowFilterOptionList(true);
        }}
      >
        <Typography level="tiny" sx={{ color: colors.neutral[50] }}>
          {Translations.ADD_FILTER}
        </Typography>
      </UIChip>
    );

    return (
      <Box ref={ref}>
        <CellMenu
          onClose={() => {
            onCloseMenu();
          }}
          open={showFilterOptionList}
          anchorEl={element ?? undefined}
          anchorComponent={chip}
          sx={{
            ...MenuSx,
            maxHeight: 'calc(100vh - 150px)',
            '& .MuiPopover-paper': {
              borderRadius: '6px',
            },
          }}
        >
          {showFilterOptionList && renderFilterOptionMenu(selectedFilterOption)}
        </CellMenu>
      </Box>
    );
  };

  return (
    <>
      {activeFilters.map((filterItem, index) => (
        <Box key={filterItem.id} sx={{ marginRight: '8px' }}>
          {renderUIChip(filterItem)}
        </Box>
      ))}
      {renderFilterSelector()}
    </>
  );
}

const styles = {
  filterChip: {
    borderRadius: '50px',
    fontWeight: 500,
    fontSize: '14px',
    color: colors.primary[50],
  },
  filterMenuContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    alignContent: 'center',
    paddingY: '6px',
    gap: '16px',
  },
  activeFilterContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    alignItems: 'center',
    gap: '10px',
    width: '100%',
  },
  chipContainer: {
    border: `1px solid ${colors.primary[10]}`,
    borderRadius: '15px',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '28px',
    marginX: '5px',
    '&: hover': {
      borderColor: colors.primary[20],
    },
  },
  filterChipButton: {
    alignSelf: 'center',
    borderRadius: '15px',
    marginRight: '8px',
    fontWeight: 200,
    height: '28px',
    border: `1px solid ${colors.neutral[10]}`,
    fontSize: '14px',
  },
};
