import { colors } from 'theme/colors';

export const sidebarStyles = (isMobile: boolean) => {
  return {
    drawer: {
      '& .MuiDrawer-paper': {
        width: isMobile ? '100%' : '560px',
        backgroundColor: `${colors.neutral[0]}`,
        boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.2)',
        borderLeft: `2px solid ${colors.neutral[5]}`,
        height: '100%',
      },
    },
  };
};
