import { SxProps } from '@mui/material';
import { colors } from 'theme/colors';
import React from 'react';

export const CheckboxSx = (checked: boolean, indeterminate: boolean): SxProps => {
  if (indeterminate || checked) {
    const style = {
      '--Icon-color': colors.white,
      '& .JoyCheckbox-checkbox.Joy-checked': {
        background: colors.primary[50],
        border: 'none !important',
      },
      borderRadius: '5px',
      '& svg': {
        color: `${colors.white} `,
      },
    };
    if (indeterminate) {
      return {
        ...style,
        background: colors.primary[50],
        border: 'none !important',
      };
    }
    return style;
  }
  return {};
};

export const CircleStyle: React.CSSProperties = {
  width: '5px',
  height: '5px',
  backgroundColor: colors.neutral[20],
  borderRadius: '10px',
  marginTop: 'auto',
  position: 'relative',
  margin: '8px',
};

export const ClickableTextStyle: SxProps = {
  '&:hover': {
    textDecoration: 'underline',
  },
  cursor: 'pointer',
  marginLeft: '5px',
  p: 1,
  color: colors.primary[50],
  textDecoration: 'underline',
};

export const DividerSx: SxProps = {
  '--Divider-lineColor': colors.neutral[5],
  backgroundColor: colors.neutral[5],
};

export const FilterCheckListContainerSx: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
  marginRight: '2px',
  marginLeft: '15px',
  marginTop: '4px',
};
