export type TgSyncModalProps = {
  open: boolean;
  onClose: () => void;
};

export enum TgConnectSteps {
  INITIATE = 'INITIATE',
  CONNECT = 'CONNECT',
  TG_2FA = 'TG_2FA',
  FOLDER_SYNC = 'FOLDER_SYNC',
}

export enum TgModalState {
  INIT = 'INIT',
  BOT = 'BOT',
}

export enum TgBotTestState {
  INIT = 'INIT',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum TgSyncStatus {
  CONNECT = 'Connect Telegram',
  CONNECTED = 'Telegram Connection',
  CONNECTED_NOT_SYNCED = 'Sync Folders',
  SYNCING = 'Syncing Folders...',
  SYNCED = 'ago',
  WAITING_FOR_CONNECTION = 'Waiting for connection',
  SYNC_QUEUED = 'Sync Queued',
  DISCONNECTED = 'Telegram Disconnected',
}

export const COMPLETED = 'COMPLETED';
export const CONNECTED = 'CONNECTED';
