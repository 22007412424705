import { User } from 'services/models/domain/user';

enum MetaTags {
  Synced = 'synced',
  FirstTime = 'firstTime',
  ChromeExtensionInstalled = 'chromeExtensionInstalled',
  SignedInCompleted = 'signedInCompleted',
}

//default event states
export const defaultMetadata = (user: User | null | undefined, isFirstTime: boolean) => ({
  fullName: user?.fullName,
  email: user?.email,
  [MetaTags.Synced]: false,
  [MetaTags.FirstTime]: isFirstTime,
  [MetaTags.ChromeExtensionInstalled]: false,
  [MetaTags.SignedInCompleted]: false,
});

const addSyncMetadata = () => window.CommandBar.addMetadata(MetaTags.Synced, true);
const addChromeExtensionInstalled = () => window.CommandBar.addMetadata(MetaTags.ChromeExtensionInstalled, true);
const addSignIn = () => window.CommandBar.addMetadata(MetaTags.SignedInCompleted, true);

export const commandbarAddMetadata = {
  [MetaTags.Synced]: addSyncMetadata,
  [MetaTags.ChromeExtensionInstalled]: addChromeExtensionInstalled,
  [MetaTags.SignedInCompleted]: addSignIn,
};
