import { GridCellProps } from '@mui/x-data-grid-pro';
import { Followup } from 'services/models/domain/followUps';
import FollowUpCellContent from 'components/Chats/ChatTableV2/TableCell/customCells/cellsContent/FollowUp/FollowUpCellContent';
import { useFollowups } from 'services/hooks/useFollowups';
import { GridCellEditable } from 'components/Chats/ChatTableV2/TableCell/styles';

export default function FollowUpCell(props: GridCellProps) {
  const { value, ...rest } = props;
  const { conversation, setOnCloseHandler } = value;
  const { updateFollowup } = useFollowups(conversation);
  const onFollowUpUpdated = (from?: Followup, to?: Followup) => {
    updateFollowup(from, to);
  };

  return (
    <GridCellEditable {...rest}>
      <FollowUpCellContent
        conversation={conversation}
        onFollowUpUpdated={onFollowUpUpdated}
        setOnCloseHandler={setOnCloseHandler}
      />
    </GridCellEditable>
  );
}
