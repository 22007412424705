import { Box, Button, CardContent, Typography } from '@mui/joy';
import {
  DetailsIconStyle,
  EntitySubTitleStyle,
  EntityTitleStyle,
  KanbanCardHeaderStyle,
  KanBanCardStyle,
  LinkIconsContainerStyle,
  TelegramIconStyle,
} from 'components/Kanban/styles';
import Translations from 'const/translations/en';
import { NotesTime, generateColorFromOid, getUsersInitials } from 'services/utils/helpers';
import {
  EntityPropertyDefinition,
  OptionPropertyValue,
  TenantUserPropertyValue,
  PropertyDefinitionDataType,
  PropertyDefinitionKind,
} from 'services/models/domain/entityViews';
import { KanbanCardProps } from 'components/Kanban/KanbanBoard';
import { useChatActions } from 'services/hooks/useChatActions';
import { useMemo, useState } from 'react';
import { colors } from 'theme/colors';
import { ReactComponent as SidebarIcon } from 'assets/icons/ic_sidebar.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/ic_telegram.svg';
import {
  ButtonNameSx,
  ButtonSx,
  CategoriesBoxSx,
  CategoriesTextSx,
  KanbanCardContentSx,
  KanbanCardItemSx,
  UserBoxSx,
} from 'components/Kanban/styles';
import CustomAvatar from 'components/CustomAvatar';
import { Flex1, FlexCol } from 'theme/flex';
import { useSidebar } from 'services/hooks/useSidebar';
import { EntityDetailsType } from 'services/store/slices/sidebarSlice';
import { ReactComponent as Chevron } from 'assets/icons/ic_chevron_down.svg';
import UserProperty from 'components/Chats/ChatTableV2/TableCell/customCells/UserProperty/UserProperty';
import OptionProperty from 'components/Chats/ChatTableV2/TableCell/customCells/OptionProperty/OptionProperty';
import { EntityType } from 'services/models/domain/entityTypes';
import { usePropertyValue } from 'services/hooks/usePropertyValue';
import SideButtonsPair from 'components/SideButtonsPair';
import { Conversation, OriginTypes } from 'services/models/domain/conversation';
import { getChannelFilledIcon, getChannelIcon, getChannelIconColor } from 'services/utils/channelUtils';
import { useTelegramChatLink } from 'services/hooks/useTelegramChatLink';
import LinkDescriptionComponent from 'components/LinkDescriptions';
import PageLink from 'components/PageLink';
import { RouterPaths } from 'services/hooks/useNavigation';
import { ValueProperty } from 'components/Chats/ChatTableV2/TableCell/customCells/ValueProperty/ValueProperty';

interface ConversationCardProps extends KanbanCardProps<Conversation> {
  propertyDefinitionsToShow: EntityPropertyDefinition[];
  propertyDefinitions: EntityPropertyDefinition[];
}

export const ConversationKanbanCard = (props: ConversationCardProps) => {
  const { entity, propertyDefinitions, propertyDefinitionsToShow } = props;
  const [showToggleButtons, setShowToggleButtons] = useState(false);
  const { handleUpdatePropertyOption } = usePropertyValue();
  const { handleOpenChatLink } = useChatActions(entity);
  const { openSidebar } = useSidebar();
  const { telegramChatLink } = useTelegramChatLink(entity.chatLink, entity.conversationType, entity.participants || []);
  const channelPropertyDefinitionOid = propertyDefinitions.find((p) => p.name === 'Channel')?.oid.oid;
  const channelProperty = entity?.properties?.find(
    (p) => p.propertyDefinitionOid.oid === channelPropertyDefinitionOid
  ) as OptionPropertyValue | undefined;
  const channelType = channelProperty?.value;
  const link = entity.chatLink;

  const getLinkDescription = () => {
    return (
      <LinkDescriptionComponent
        origin={entity.conversationOrigin}
        originalLink={telegramChatLink}
        link={link}
        channelType={channelType}
      />
    );
  };

  const selectedProperties = useMemo(() => {
    return propertyDefinitionsToShow.map((pd) => {
      const selectedPropertyValues = entity.properties
        .filter((value) => value.propertyDefinitionOid.oid === pd.oid.oid)
        .map((filteredValue) => {
          if (filteredValue.type === PropertyDefinitionDataType.Value) {
            return filteredValue.value;
          }
          return filteredValue.oid.oid;
        });
      return {
        name: pd.name,
        type: pd.dataType,
        values: selectedPropertyValues,
        propertyDefinition: pd,
      };
    });
  }, [propertyDefinitionsToShow, entity.properties]);

  const updateEntity = {
    entityType: EntityType.Conversation,
    id: entity.oid.oid,
  };

  return (
    <CardContent sx={KanBanCardStyle} role="note">
      <div onMouseEnter={() => setShowToggleButtons(true)} onMouseLeave={() => setShowToggleButtons(false)}>
        <div style={KanbanCardHeaderStyle}>
          <div style={{ ...Flex1, ...FlexCol }}>
            <PageLink to={RouterPaths.createDetailsChatPath(entity.oid.oid)} openInNewTab>
              <Typography level="tiny+" sx={EntityTitleStyle}>
                {entity.name}
              </Typography>
            </PageLink>
            <Typography level="micro" sx={EntitySubTitleStyle}>
              {Translations.LATEST_MESSAGE}:{' '}
              {entity?.lastMessageAt?.utcTimeStampAsString
                ? NotesTime({ toDate: entity?.lastMessageAt?.utcTimeStampAsString })
                : '-'}
            </Typography>
          </div>
          {showToggleButtons && (
            <div style={LinkIconsContainerStyle}>
              <SideButtonsPair
                leftComponent={{
                  icon:
                    entity?.conversationOrigin === OriginTypes.TelegramSync
                      ? TelegramIcon
                      : getChannelFilledIcon(link ? channelType : undefined),
                  hoveredIcon:
                    entity?.conversationOrigin === OriginTypes.TelegramSync
                      ? TelegramIcon
                      : getChannelIcon(link ? channelType : undefined),
                  description: getLinkDescription(),
                  stroke:
                    entity?.conversationOrigin !== OriginTypes.TelegramSync
                      ? getChannelIconColor(link ? channelType : undefined, telegramChatLink ? true : false)
                      : undefined,
                  css: TelegramIconStyle(!!telegramChatLink),
                  link: telegramChatLink,
                  showFilledIcon: true,
                }}
                rightComponent={{
                  icon: SidebarIcon,
                  description: Translations.OPEN_SIDEBAR_TOOLTIP,
                  css: DetailsIconStyle,
                  link: () => {
                    openSidebar(entity.oid.oid, EntityDetailsType.chatDetails);
                  },
                }}
              />
            </div>
          )}
        </div>
        <Box sx={KanbanCardContentSx}>
          {selectedProperties.map((property) => (
            <KanbanCardItem title={property.name} key={property.propertyDefinition.oid.oid}>
              {property.type === PropertyDefinitionDataType.TenantUser && (
                <UserProperty
                  values={property.values}
                  refetch={() => Promise.resolve()}
                  entityId={entity.oid.oid}
                  propertyDefinition={property.propertyDefinition}
                  renderEmpty={renderEmpty}
                  renderProperty={renderUser}
                  handleSet={async (propValueId: string) => {
                    handleUpdatePropertyOption(property.propertyDefinition, updateEntity, property.values, [
                      propValueId,
                    ]);
                  }}
                  handleUnset={async (propValueId: string) => {
                    handleUpdatePropertyOption(property.propertyDefinition, updateEntity, property.values, []);
                  }}
                />
              )}
              {property.type === PropertyDefinitionDataType.Option && (
                <OptionProperty
                  values={property.values}
                  refetch={() => Promise.resolve()}
                  propertyDefinition={property.propertyDefinition}
                  renderEmpty={renderEmpty}
                  renderProperty={renderCategories}
                  handleSelect={async (propValueId: string) => {
                    const isMultiSelectKind = property.propertyDefinition.kind === PropertyDefinitionKind.MultiValue;
                    handleUpdatePropertyOption(
                      property.propertyDefinition,
                      updateEntity,
                      property.values,
                      isMultiSelectKind ? [...property.values, propValueId] : [propValueId]
                    );
                  }}
                  handleUnset={async (propValueId: string) => {
                    const isMultiSelectKind = property.propertyDefinition.kind === PropertyDefinitionKind.MultiValue;
                    handleUpdatePropertyOption(
                      property.propertyDefinition,
                      updateEntity,
                      property.values,
                      isMultiSelectKind ? property.values.filter((p) => p !== propValueId) : []
                    );
                  }}
                />
              )}
              {property.type === PropertyDefinitionDataType.Value && (
                <Box sx={KanbanCardStyles.propertyContainer}>
                  <ValueProperty
                    propertyDefinition={property.propertyDefinition}
                    propertyValue={{
                      propertyDefinitionOid: property.propertyDefinition.oid,
                      type: property.type,
                      value: property.values[0],
                    }}
                    entityId={entity.oid.oid}
                    sx={KanbanCardStyles.propertyInput}
                  />
                </Box>
              )}
            </KanbanCardItem>
          ))}
        </Box>
      </div>
    </CardContent>
  );
};

const renderEmpty = () => (
  <Button id="property-menu-button" aria-haspopup="true" variant="plain" sx={ButtonSx}>
    <Box sx={UserBoxSx}>
      <Typography level="micro">Select</Typography>
      <Chevron height={11} width={11} stroke={colors.neutral[40]} />
    </Box>
  </Button>
);

const renderUser = (propertyValue: TenantUserPropertyValue) => {
  const userInitials = getUsersInitials(propertyValue?.fullName, propertyValue?.fullName);
  return (
    <Button id="property-menu-button" aria-haspopup="true" variant="plain" sx={ButtonSx} key={propertyValue.oid.oid}>
      <Box sx={UserBoxSx}>
        <CustomAvatar
          index={generateColorFromOid(propertyValue.oid.oid ?? '')}
          size={16}
          userInitials={userInitials}
          imgUrl={propertyValue.avatar}
        />
        <Typography level="micro" sx={ButtonNameSx}>
          {propertyValue.fullName}
        </Typography>
      </Box>
    </Button>
  );
};

const renderCategories = (properties: OptionPropertyValue[], valueOids: string[]) => {
  const badgeText = valueOids
    .map((valueOid) => properties.find((p) => p.oid.oid === valueOid)?.value)
    .filter((v) => !!v)
    .join(', ');

  return (
    <Box sx={CategoriesBoxSx}>
      <Typography level="micro" sx={CategoriesTextSx}>
        {badgeText}
      </Typography>
    </Box>
  );
};

interface KanbanCardItemProps {
  title: string;
  children: React.ReactNode;
}

const KanbanCardItem = ({ children, title }: KanbanCardItemProps) => {
  return (
    <Box sx={KanbanCardItemSx}>
      <Typography level="micro" sx={{ color: colors.neutral[50] }}>
        {title}
      </Typography>
      {children}
    </Box>
  );
};

const KanbanCardStyles = {
  propertyContainer: {
    height: '24px',
    maxWidth: '100px',
    minWidth: '68px',
    border: `1px solid ${colors.neutral[10]}`,
    borderRadius: '8px',
    fontSize: '12px',
  },
  propertyInput: {
    minWidth: '68px',
    padding: '0px 8px',
    border: `1px solid ${colors.neutral[10]}`,
    maxWidth: '100px',
    borderRadius: '8px',
    '& span': {
      fontSize: '12px !important',
    },
  },
};
