import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/joy';
import Translations from 'const/translations/en';
import { conversationsState, setLimitPerPage, setPage } from 'services/store/slices/conversationsSlice';
import ChatTableV2 from 'components/Chats/ChatTableV2/ChatTable';
import Page from 'components/Page';
import Onboarding from 'page/chats/Onboarding/Onboarding';
import { PageView } from 'page/chats/interface';
import PagePagination from 'components/PagePagination/PagePagination';
import Icons, { IconsSvg } from 'assets/icons';
import NoData from 'components/NoData';
import { entityViewsState } from 'services/store/slices/entityViewsSlice';
import { LocalStorageValues } from 'services/utils/interface';
import { IChatFilters, chatFilters, clearFilter } from 'services/store/slices/conversationsFilterSlice';
import { KanbanView } from 'page/chats/KanbanView';
import { addConversations } from 'services/store/slices/pipelineConversationsSlice';
import { useFetchPipelineConversations } from 'services/hooks/useFetchPipelineConversations';
import { DEFAULT_PER_PAGE_KANBAN } from 'services/constants';
import _ from 'lodash';
import { Flex1, FlexCol } from 'theme/flex';
import { TableViewMetadata } from 'components/Chats/ChatTableV2/interface';
import { useSidebar } from 'services/hooks/useSidebar';
import { useFetchConversations } from 'services/hooks/useFetchConversations';
import { sideNavState } from 'services/store/slices/sideNavSlice';
import { useNavigationSidebar } from 'services/hooks/useNavigationSidebar';
import { CHAT_RECORDS, iconMapping } from 'services/utils/dashboardHeaderUtils';
import { colors } from 'theme/colors';
import { useTgConnectModalControl } from 'contexts/TgConnectModalContext';
import { DraftEntityView, EntityView, ViewDisplayType, ViewType } from 'services/models/domain/entityViews';
import { ChatTableFilterBar } from 'page/chats/ChatTableFilterBar/ChatTableFilterBar';
import { entityFilter } from 'services/store/slices/entityFilterSlice';
import { EntityType } from 'services/models/domain/entityTypes';
import { useEntityViews } from 'services/hooks/useEntityViews';
import SmartListView from './SmartListView/SmartListView';
import { useNavigationTableEntites } from 'services/hooks/useNavigationTableEntities';
import { EntityDetailsType } from 'services/store/slices/sidebarSlice';
import {
  isTableNavigationEnd,
  isTableNavigationStart,
  tableEntitiesNavigationState,
} from 'services/store/slices/tableEntitiesNavigationSlice';

export const getIcon = (icon: string) => {
  let Icon = iconMapping['dollarFile'];
  if (icon in IconsSvg) {
    Icon = IconsSvg[icon as keyof typeof IconsSvg];
  }
  if (icon in iconMapping) {
    Icon = iconMapping[icon as keyof typeof iconMapping];
  }
  return Icon;
};

const hasActiveFilter = (filter: IChatFilters): boolean => {
  const propertyValues = filter.input.filterOn.propertyValues || [];
  const { search } = filter.input;
  const folders = filter.input.filterOn.folderOids || [];

  return (search && search !== '') || propertyValues.length > 0 || folders.length > 0;
};

function ChatV2Properties() {
  const [pageView, setPageView] = useState(
    (localStorage.getItem(LocalStorageValues.PageView) as PageView) || PageView.Table
  );
  const { selectedItem } = useSelector(sideNavState);
  const { toggleTgConnectModal } = useTgConnectModalControl();

  const handlePageView = (newPageView: PageView) => {
    if (newPageView) {
      setPageView(newPageView);
      localStorage.setItem(LocalStorageValues.PageView, newPageView);
    }
  };

  const [tableViewMetadata, setTableViewMetadata] = useState<TableViewMetadata>({ scrollPositionY: 0 });

  useFetchConversations();

  const { openSidebar, closeSidebar, setSidebarLoading } = useSidebar();
  const { setTableNavigation } = useNavigationTableEntites();
  const dispatch = useDispatch();
  const { clearSelectedNavigationItem, setSelectedNavigationItem } = useNavigationSidebar();
  const { filterItems } = useSelector(entityFilter);
  const conversationFilterItems = filterItems.filter((fi) => fi.entityType === EntityType.Conversation);
  const tableConversationsState = useSelector(conversationsState);
  const { getEntityViewByOid } = useEntityViews();
  const { entityViews } = useSelector(entityViewsState);
  const { fetchList, hasNextList, hasPreviousList } = useSelector(tableEntitiesNavigationState);
  const isNavigationStart = useSelector(isTableNavigationStart);
  const isNavigationEnd = useSelector(isTableNavigationEnd);

  const selectedEntityView: DraftEntityView | EntityView | undefined = useMemo(() => {
    let entity = selectedItem.id ? getEntityViewByOid(selectedItem.id) : undefined;
    if (!entity && (selectedItem.id === CHAT_RECORDS || selectedItem.id === undefined)) {
      entity = {
        oid: { oid: CHAT_RECORDS },
        title: CHAT_RECORDS,
        entityType: EntityType.Conversation,
        displayTypes: [ViewDisplayType.Table, ViewDisplayType.Kanban],
        groupByPropertyDefinitionOid: entityViews.groupByPropertyDefinitionOid,
        propertyDefinitions: entityViews.propertyDefinitions,
        type: ViewType.Default,
        visibleEntityFields: entityViews.visibleEntityFields,
        tableColumnOrder: entityViews.tableColumnOrder,
      };
    }
    return entity;
  }, [entityViews, selectedItem.id]);

  const kanbanView = {
    ...selectedEntityView,
    propertyDefinitions: selectedEntityView?.propertyDefinitions,
  } as EntityView;
  const tableViewView = {
    ...selectedEntityView,
    propertyDefinitions: selectedEntityView?.propertyDefinitions,
  } as EntityView;

  const filter = useSelector(chatFilters);
  const onPageChange = (page: number) => {
    dispatch(setPage(page - 1));
  };

  const onLimitChange = (limit: number) => {
    dispatch(setLimitPerPage(limit));
  };

  const onClearFilters = () => {
    clearSelectedNavigationItem();
    dispatch(clearFilter());
  };

  const refetchEntityViews = () => {
    // TODO (AWE): Check if we really need it
  };

  const refetchConversations = () => {
    // TODO (AWE): Check if we really need it
  };

  const groupByPropertyDefinition = entityViews.propertyDefinitions.find(
    (pd) => pd.oid.oid === kanbanView?.groupByPropertyDefinitionOid
  );
  const canShowKanban = kanbanView && groupByPropertyDefinition;
  const hasConversations =
    tableConversationsState.totalConversations !== 0 && tableConversationsState.conversations.length !== 0;

  const showSmartListView = selectedEntityView?.type === ViewType.Draft && conversationFilterItems.length === 0;

  const { loadConversationsByPropertyValue } = useFetchPipelineConversations(
    DEFAULT_PER_PAGE_KANBAN,
    groupByPropertyDefinition
  );

  const setChatsAsNavigationItem = () => {
    setSelectedNavigationItem({
      icon: 'chats',
      title: 'Chats',
      id: CHAT_RECORDS,
    });
  };

  useEffect(() => {
    return () => {
      closeSidebar();
      setTableNavigation([], EntityDetailsType.chatDetails, false, false);
    };
  }, []);

  useEffect(() => {
    const hasNextPage = (tableConversationsState.currentPage || 0) + 1 < tableConversationsState.pagesCount;
    const hasPreviousPage = (tableConversationsState.currentPage || 0) > 0;
    setSidebarLoading(tableConversationsState.loading);
    if (!tableConversationsState.loading) {
      setTableNavigation(
        tableConversationsState.conversations,
        EntityDetailsType.chatDetails,
        hasNextPage,
        hasPreviousPage
      );
    }
  }, [tableConversationsState.conversations]);

  useEffect(() => {
    if (fetchList) {
      const currentPage = (tableConversationsState.currentPage || 0) + 1;
      if (isNavigationStart && hasPreviousList) {
        onPageChange(currentPage - 1);
      } else if (isNavigationEnd && hasNextList) {
        onPageChange(currentPage + 1);
      }
    }
  }, [fetchList]);

  const renderNoFavoritesOverlay = () => {
    const Icon = getIcon(selectedItem.icon);
    return (
      <NoData
        icon={<Icon height={18} width={18} stroke={colors.neutral['80']} />}
        title={`No favorites yet!`}
        titleSx={styles.overlaytitle}
        message={
          <Typography level="tiny" sx={styles.overlayMessage}>
            <>
              {`To start adding favorites, go to your team's `}
              <Typography
                level="tiny"
                component="span"
                sx={styles.overlayUnderlinedText}
                onClick={setChatsAsNavigationItem}
              >
                {`Chats`}
                <br />
              </Typography>
              {` and click the star icon by your most important chats.`}
            </>
          </Typography>
        }
      />
    );
  };

  const renderNoRecentChatsOverlay = () => {
    const Icon = getIcon(selectedItem.icon);
    return (
      <NoData
        icon={<Icon height={18} width={18} stroke={colors.neutral['80']} />}
        title={`No Recently Added Chats`}
        titleSx={styles.overlaytitle}
        message={
          <Typography level="tiny" sx={styles.overlayMessage}>
            <>
              {`No chat added in the past 7 days. Add new chats by`}
              <br />
              {`including them in in the `}
              <Typography
                level="tiny"
                component="span"
                sx={styles.overlayUnderlinedText}
                onClick={() => {
                  toggleTgConnectModal(true);
                }}
              >
                {`Telegram folders you have`}
                <br />
                {`synced with 3RM`}
              </Typography>
            </>
          </Typography>
        }
      />
    );
  };
  const renderNoOwnerChatsOverlay = () => {
    const Icon = getIcon(selectedItem.icon);
    return (
      <NoData
        icon={<Icon height={18} width={18} stroke={colors.neutral['80']} />}
        title={`You're not the owner of any chats yet`}
        titleSx={styles.overlaytitle}
        message={
          <Typography level="tiny" sx={styles.overlayMessage}>
            <>
              {`To start assigning yourself ownership of chats, head to`}
              <br />
              {`your team's `}
              <Typography
                level="tiny"
                component="span"
                sx={styles.overlayUnderlinedText}
                onClick={setChatsAsNavigationItem}
              >
                {`Chats`}
              </Typography>
            </>
          </Typography>
        }
      />
    );
  };

  const renderNoFollowUpsDueOverlay = () => {
    const Icon = iconMapping['confetti'];
    return (
      <NoData
        icon={<Icon height={22} width={22} stroke={colors.neutral['80']} />}
        title={`No Follow-Ups Due`}
        titleSx={styles.overlaytitle}
        message={
          <Typography level="tiny" sx={styles.overlayMessage}>
            <>
              {`Hooray! You have no overdue follow-ups. To start adding`}
              <br />
              {`new follow-ups, head over to your team’s `}
              <Typography
                level="tiny"
                component="span"
                sx={styles.overlayUnderlinedText}
                onClick={setChatsAsNavigationItem}
              >
                {`Chats`}
              </Typography>
            </>
          </Typography>
        }
      />
    );
  };

  const renderNoMyChatsOverlay = () => {
    const Icon = getIcon(selectedItem.icon);
    const title = selectedItem.title.includes('1:1') ? '1:1' : 'group';
    return (
      <NoData
        icon={<Icon height={22} width={22} stroke={colors.neutral['80']} />}
        title={`You have not added any ${title} chats yet`}
        titleSx={styles.overlaytitle}
        message={
          <Typography level="tiny" sx={styles.overlayMessage}>
            <>
              {`To start adding chats, make sure you've `}
              <Typography
                level="tiny"
                component="span"
                sx={styles.overlayUnderlinedText}
                onClick={() => {
                  toggleTgConnectModal(true);
                }}
              >
                {`connected your `}
                <br />
                {`Telegram account`}
              </Typography>
              {` and synced your first folder!`}
            </>
          </Typography>
        }
      />
    );
  };
  const renderActieFilterOverlay = () => {
    return (
      <NoData
        title={<img src={Icons.ic_chatbubble_strike} alt="ic_link" />}
        titleSx={{ color: 'info.main' }}
        message={
          <Typography level="tiny" sx={styles.overlayMessage}>
            {Translations.NO_CHATS_BY_FILTER}.{' '}
            <Typography
              level="tiny"
              sx={{ ...styles.overlayMessage, textDecoration: 'underline', cursor: 'pointer' }}
              onClick={onClearFilters}
            >
              {Translations.CLEAR_FILTERS}.
            </Typography>
          </Typography>
        }
      />
    );
  };

  const overlayRenderers = {
    [Translations.FAVORITE_CHIP_TEXT]: renderNoFavoritesOverlay,
    [Translations.MY_CHATS]: renderNoMyChatsOverlay,
    [Translations.OWNED_BY_ME]: renderNoOwnerChatsOverlay,
    [Translations.RECENTLY_ADDED]: renderNoRecentChatsOverlay,
    [Translations.FOLLOW_UPS_DUE]: renderNoFollowUpsDueOverlay,
    [Translations.MY_INDIVIDUAL_CHATS]: renderNoMyChatsOverlay,
    [Translations.MY_GROUP_CHATS]: renderNoMyChatsOverlay,
  };

  const renderNoData = () => {
    const renderer = overlayRenderers[selectedItem.title as keyof typeof overlayRenderers];
    if (renderer) {
      return renderer();
    } else if (hasActiveFilter(filter) || conversationFilterItems.length > 0) {
      return renderActieFilterOverlay();
    } else {
      return (
        <NoData
          title={Translations.NO_CHATS_MESSAGE}
          titleSx={{ color: 'info.main' }}
          message={
            <Typography level="tiny" sx={styles.overlayMessage}>
              <>
                {`To start, `}
                <Typography
                  level="tiny"
                  component="span"
                  sx={styles.overlayUnderlinedText}
                  onClick={() => {
                    toggleTgConnectModal(true);
                  }}
                >
                  {`connected your Telegram account`}
                </Typography>
              </>
            </Typography>
          }
        />
      );
    }
  };

  const renderContent = () => {
    if (!tableConversationsState.loading && !hasConversations) {
      return renderNoData();
    }
    if (pageView === PageView.Table) {
      return showSmartListView ? (
        <SmartListView />
      ) : (
        <div style={{ ...Flex1, ...FlexCol, overflow: 'auto' }}>
          <ChatTableV2
            loading={tableConversationsState.loading}
            properties={tableViewView?.propertyDefinitions?.filter((pd) => pd.visible) ?? []}
            entityViewFields={tableViewView?.visibleEntityFields ?? []}
            channelProperty={entityViews.propertyDefinitions.find((pd) => pd.name === 'Channel')}
            conversations={tableConversationsState.conversations}
            refetchConversations={refetchConversations}
            refetchEntityView={refetchEntityViews}
            onUnmount={(positionY) => {
              if (positionY !== tableViewMetadata.scrollPositionY) {
                setTableViewMetadata({ scrollPositionY: positionY });
              }
            }}
            openSidebar={openSidebar}
            metadata={tableViewMetadata}
          />
          {hasConversations && (
            <PagePagination
              pagesCount={tableConversationsState.pagesCount}
              onPageChange={onPageChange}
              setLimitPerPage={onLimitChange}
              currentPage={(tableConversationsState.currentPage || 0) + 1}
              limitPerPage={tableConversationsState.limitPerPage}
              totalResultsCount={tableConversationsState.totalConversations || 0}
            />
          )}
        </div>
      );
    }
    if (pageView === PageView.Kanban) {
      if (canShowKanban) {
        return (
          <KanbanView
            entityView={kanbanView}
            onEndReached={async (column) => {
              const newConversations = await loadConversationsByPropertyValue(column.entities.length, column.id);
              dispatch(
                addConversations({
                  conversations: newConversations,
                })
              );
            }}
          />
        );
      }
    }
  };

  return (
    <div data-test="chats-title">
      <Page title={Translations.CHAT_PAGE_TITLE}>
        <div style={{ ...Flex1, ...FlexCol, height: '100vh' }}>
          <div style={{ overflowY: 'auto' }} id="chats-header">
            <ChatTableFilterBar currentPageView={pageView} onPageViewChanged={handlePageView} />
          </div>
          <div style={{ ...Flex1, overflowY: 'auto' }}>{renderContent()}</div>
        </div>
      </Page>
      <Onboarding />
    </div>
  );
}

export default ChatV2Properties;

const styles = {
  overlaytitle: {
    color: colors.neutral['80'],
    fontWeight: 800,
    fontSize: '20px',
  },
  overlayMessage: {
    color: 'info.main',
    textAlign: 'center',
  },
  overlayUnderlinedText: {
    color: colors.blue,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
};
