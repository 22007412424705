import React, { useState, DragEvent, ChangeEvent } from 'react';
import { Box, Divider, Sheet, TextField, Typography, Grid, Stack } from '@mui/joy';
import AppButton from 'components/Buttons/Button';
import { avatarFromName, generateColorFromOid } from 'services/utils/helpers';
import Icons from 'assets/icons';
import CustomAvatar from 'components/CustomAvatar';
import { colors } from 'theme/colors';
import styles from 'page/modal/styles';
import { User, UserDetails } from 'services/models/domain/user';

interface Props {
  user: User | undefined | null;
  avatar: string | undefined;
  updateUserProfile: (user: UserDetails) => void;
  updateAvatarUrl: (url: string) => void;
  onDelete: () => void;
  setUnsavedChanges: (value: boolean) => void;
}

export enum ImageErrors {
  TypeError = 'Image must be PNG,JPEG OR HEIC',
  SizeError = 'Must be between 32 * 32 and 8064 * 6048',
}

const ProfileDetails = ({ user, updateUserProfile, updateAvatarUrl, avatar, onDelete, setUnsavedChanges }: Props) => {
  const [fullName, setFullName] = React.useState<string | undefined>('');
  const [displayName, setDisplayName] = React.useState<string | undefined>('');
  const [_dragActive, setDragActive] = React.useState(false);
  const [imageError, setImageError] = useState<string>('');

  React.useEffect(() => {
    setFullName(user?.fullName ?? '');
    setDisplayName(user?.name ?? '');
  }, [user]);

  const handleDrag = function (e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const isValidImageSize = (width: number, height: number) => {
    return width >= 32 && height >= 32 && width <= 8064 && height <= 6048;
  };

  const isValidImageType = (fileType: string) => {
    return fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/heic';
  };

  const handleDrop = function (e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      updateAvatarUrl(URL.createObjectURL(e.dataTransfer.files[0]));
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      setImage(file);
    }
  };

  const setImage = async (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event: any) => {
      const image = new Image();
      image.src = event.target.result;
      image.onload = () => {
        const { width, height } = image;
        if (!isValidImageSize(width, height)) {
          setImageError(ImageErrors.SizeError);
        } else if (!isValidImageType(file.type)) {
          setImageError(ImageErrors.TypeError);
        } else {
          setImageError('');
          updateAvatarUrl(URL.createObjectURL(file));
        }
      };
    };
  };

  const handleDeleteClick = (e: React.MouseEvent) => {
    onDelete();
  };

  return (
    <>
      <div
        id="drag-file-element"
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <Sheet
          variant="plain"
          sx={{
            width: { xs: '100%', md: '100%' },
            borderRadius: '8px',
            marginLeft: '2px',
          }}
        >
          <Grid container sx={{ width: '100%' }}>
            <Grid xs={12} md={12} lg={12} gap={2}>
              <Box sx={{ marginTop: '15px' }}>
                <Sheet sx={styles.avatarContainer}>
                  <Stack direction="column" sx={{ display: 'flex', position: 'relative' }}>
                    <CustomAvatar
                      index={generateColorFromOid(user?.oid ?? '')}
                      size={65}
                      userInitials={avatarFromName(user?.fullName ?? user?.firstName ?? '')}
                      imgUrl={avatar}
                    />
                    {avatar && (
                      <Box sx={styles.avatarBox} onClick={handleDeleteClick}>
                        <img
                          src={Icons.ic_trash}
                          alt="file"
                          height={12}
                          width={12}
                          style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                        />
                      </Box>
                    )}
                  </Stack>
                  <Stack direction="column" sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box
                      sx={{
                        ...styles.imageUploadContainer,
                        height: imageError ? 45 : 65,
                        marginLeft: avatar ? 2 : 2,
                      }}
                    >
                      <img src={Icons.ic_file_plus} alt="file" height={24} width={24} />
                      <Typography level="tiny">
                        &nbsp;Drop file here or&nbsp;
                        <label style={{ color: colors.blue, textDecoration: 'underline', cursor: 'pointer' }}>
                          browse
                          <input
                            type="file"
                            id="upload-button"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleChange}
                          />
                        </label>
                      </Typography>
                    </Box>
                    {imageError && (
                      <Typography level="tiny" sx={styles.imageError}>
                        {imageError}
                      </Typography>
                    )}
                  </Stack>
                </Sheet>
              </Box>
            </Grid>
          </Grid>

          <>
            <Box sx={{ width: 'auto' }}>
              <Grid container>
                <Grid xs={12} md={6} lg={6} gap={2}>
                  <TextField
                    size="md"
                    label="Full name"
                    value={fullName ?? ''}
                    placeholder="Full name"
                    sx={{ px: 2, py: 1, flexDirection: 'column !important' }}
                    disabled={false}
                    onChange={(evt) => {
                      setUnsavedChanges(true);
                      setFullName(evt.currentTarget.value);
                    }}
                  />
                </Grid>
                <Grid xs={12} md={6} lg={6} gap={2}>
                  <TextField
                    size="md"
                    label="Display name"
                    value={displayName ?? ''}
                    placeholder="Display name"
                    sx={{ px: 2, py: 1, flexDirection: 'column !important' }}
                    disabled={false}
                    onChange={(evt) => {
                      setUnsavedChanges(true);
                      setDisplayName(evt.currentTarget.value);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ border: '1px solid var(--joy-palette-primary-plainBorder)' }} />
            <Box sx={{ p: 1.5 }}>
              <AppButton
                fullWidth
                onClick={() => {
                  if (user) {
                    updateUserProfile({
                      oid: user.oid,
                      firstName: user.firstName ?? '',
                      lastName: user.lastName ?? '',
                      profileImageUrl: avatar ?? '',
                      name: displayName ?? '',
                      fullName: fullName ?? '',
                    });
                  }
                }}
                sx={{ minHeight: '32px' }}
              >
                Save changes
              </AppButton>
            </Box>
          </>
        </Sheet>
      </div>
    </>
  );
};

export default ProfileDetails;
