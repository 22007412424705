import { Skeleton } from '@mui/material';
import { Box, useTheme } from '@mui/joy';

const FollowupPlaceholder = () => {
  const theme = useTheme();
  return (
    <Box sx={{ p: '15px 20px', display: 'flex', position: 'relative' }}>
      <Skeleton variant="circular" height={21} width={21} sx={{ alignSelf: 'center' }} />
      <Box sx={{ display: 'flex', width: '90%', ml: '5px', alignSelf: 'center' }}>
        <Skeleton variant="text" sx={{ fontSize: theme.typography.regular, width: '40%', mr: '10px' }} />
        <Skeleton variant="text" sx={{ fontSize: theme.typography.regular, width: '30%' }} />
      </Box>
    </Box>
  );
};

export default FollowupPlaceholder;
