import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackBarVariantEnum } from 'components/SnackBar/SnackBar';
import { RootState } from 'services/store/AppStore';

export interface SnackbarState {
  open: boolean;
  text: string;
  subtext?: string;
  variant: SnackBarVariantEnum;
  icon?: string;
  actionMessage?: string;
  emoji?: string;
  backgroundColor?: string;
  mainTextColor?: string;
  subTextColor?: string;
}

const initialState: SnackbarState = {
  open: false,
  text: '',
  variant: SnackBarVariantEnum.info,
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setSnackbarState: (
      state,
      action: PayloadAction<{
        snackbarOpen: boolean;
        snackbarText: string;
        snackbarVariant: SnackBarVariantEnum;
        snackbarSubtext?: string;
        icon?: string;
        actionMessage?: string;
        emoji?: string;
        backgroundColor?: string;
        mainTextColor?: string;
        subTextColor?: string;
      }>
    ) => {
      state.open = action.payload.snackbarOpen;
      state.text = action.payload.snackbarText;
      state.variant = action.payload.snackbarVariant;
      state.subtext = action.payload.snackbarSubtext;
      state.icon = action.payload.icon;
      state.actionMessage = action.payload.actionMessage;
      state.emoji = action.payload.emoji;
      state.backgroundColor = action.payload.backgroundColor;
      state.mainTextColor = action.payload.mainTextColor;
      state.subTextColor = action.payload.subTextColor;
    },
  },
});

export const { setSnackbarState } = snackbarSlice.actions;
export const snackbarState = (state: RootState) => state.snackbar;
export default snackbarSlice.reducer;
