export const identifyUser = (uid: string, payload: any) => {
  window.analytics.identify(uid, payload);
};

export const trackAction = (key: string, payload: any) => {
  window.analytics.track(key, payload);
};

export default class AnalyticKeys {
  public static readonly ANALYTIC_KEY = 'ANALYTIC_KEY';
  public static readonly SIGNED_UP = 'Signed Up';
  public static readonly SIGNED_IN = 'Signed In';
  public static readonly ERROR_SIGNING_UP = 'Error on Sign Up';
  public static readonly ERROR_SIGNING_IN = 'Error on Sign In';
  public static readonly CONNECTED_TELEGRAM_BOT = 'Connected To Telegram Bot';
  public static readonly CONNECTED_TELEGRAM_ACCOUNT = 'Connected Telegram Account';
  public static readonly ERROR_CONNECTING_TELEGRAM_ACCOUNT = 'Error Connecting Telegram Account';
  public static readonly DISCONNECTED_TELEGRAM_ACCOUNT = 'Disconnected Telegram Account';
  public static readonly EDITED_PROFILE = 'Edited their profile';
  public static readonly SEARCHED_CHAT = 'Searched chats';
  public static readonly SORTED_CHAT = 'Sorted chats';
  public static readonly FAVORITED_CHAT = 'Favorited a chat';
  public static readonly UNFAVORITED_CHAT = 'Unfavorited a chat';
  public static readonly DELETED_CHAT = 'Deleted Chat';
  public static readonly CLEARED_FOLLOWUP = 'Cleared a follow-up';
  public static readonly RESET_FOLLOW_DATE = 'Reset a follow-up date';
  public static readonly ADDED_NEW_FOLLOWUP = 'Added a new follow-up';
  public static readonly LOGGED_OUT = 'Signed Out';
  public static readonly OPENED_CHAT_LINK = 'Opened chat link';
  public static readonly OPENED_CHAT_INVITATION_LINK = 'Opened chat invitation link';
  public static readonly SORTED_CHAT_BY_PARTICIPANT = 'Sorted chat by participant';
  public static readonly REASSIGNED_FOLLOWUP = 'Reassigned a follow-up';
  public static readonly ERROR_SAVING_FOLLOWUP = 'Error on saving follow-up';
  public static readonly ADDED_NOTE = 'Added a note';
  public static readonly EDITED_NOTE = 'Edited a note';
  public static readonly DELETED_NOTE = 'Deleted a note';
  public static readonly USER_SENT_TELEGRAM_OTP = 'User sent Telegram OTP';
  public static readonly SYNCED_FOLDER = 'Synced a folder';
  public static readonly UNSYNCED_FOLDER = 'Unsynced a folder';
  public static readonly ERROR_SYNCING_FOLDER = 'Error syncing a folder';
  public static readonly TELEGRAM_ACCOUNT_CONNECTED = 'Telegram account connected';
  public static readonly MADE_SYNC_REQUEST = 'Made a sync request';
  public static readonly ENTERED_EMAIL = 'Entered email';
  public static readonly ENTERED_NAME = 'Entered name';
  public static readonly UPLOADED_IMAGE = 'Uploaded image';
  public static readonly VERIFIED_EMAIL = 'Verified email';
  public static readonly CLOSED_SETUP_MODAL = 'Closed set-up modal';
  public static readonly SET_FILTER_ITEM = 'Set a filter item';
  public static readonly UNSET_FILTER_ITEM = 'Unset a filter item';
  public static readonly ADDED_SMART_LIST = 'Added a smart list';
  public static readonly DELETED_SMART_LIST = 'Deleted a smart list';
  public static readonly EDITED_SMART_LIST = 'Edited a smart list';
  public static readonly OPENED_SMART_LIST = 'Opened a smart list';
}
