import { SxProps } from '@mui/material';
import { colors } from 'theme/colors';

export const listItemsSx: SxProps = {
  justifyContent: 'space-between',
  padding: '8px 4px',
};

export const listItemEmpty: SxProps = {
  minHeight: '160px',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
};

export const NoFoldersInstructionBoxStyles: SxProps = {
  background: colors.primary[0],
  border: `1px solid ${colors.primary[5]}`,
  borderRadius: '12px',
  padding: '8px 16px',
};

export const NoFoldersInstructionTextStyle: SxProps = {
  fontSize: '14px',
  color: colors.primary[40],
  whiteSpace: 'pre-line',
};
