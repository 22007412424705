import { Skeleton } from '@mui/material';
import { Stack, useTheme } from '@mui/joy';
import { SxProps } from '@mui/joy/styles/types';

interface SinglePlaceholderProps {
  width?: number;
  sx?: SxProps;
}
const SinglePlaceholder = ({ width = 70, sx }: SinglePlaceholderProps) => {
  const theme = useTheme();
  return (
    <Stack direction={'row'} sx={{ p: '8px 16px', ...sx }}>
      <Skeleton variant="text" sx={{ fontSize: theme.typography.regular, width: `${width}%` }} />
    </Stack>
  );
};

export default SinglePlaceholder;
