import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useStringSession } from 'services/hooks/useStringSession';
import { AppContext } from 'services/store/AppContext';
import { setLoadingClient, setUserStatus } from 'services/store/slices/userTgSlice';
import { useTelegramSync } from 'services/hooks/useTelegramSync';
import * as Sentry from '@sentry/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTelegramLiveEvents } from 'services/hooks/useTelegramLiveEvents';
import { TgSyncStatus } from 'page/modal/TgConnectModal/interface';

export const RECONNECT_TIMEOUT_MS = 3000;

export const useTelegramClient = () => {
  const dispatch = useDispatch();
  const { getStringSession, removeStringSession, isTelegramAccountConnected } = useStringSession();
  const { tgClient } = useContext(AppContext);
  const flags = useFlags();
  const { fetchTelegramFolders, syncFolders } = useTelegramSync();
  const { initListeners } = useTelegramLiveEvents();

  const reestablishConnection = async (timeoutMs: number) => {
    localStorage.removeItem('GramJs:apiCache');
    await tgClient.destroyClient();
    await new Promise((res) => setTimeout(res, timeoutMs));
    await establishConnection();
  };

  window.alert = function (message: string) {
    if (message.includes('Missing MTProto Entity')) {
      Sentry.captureException(new Error('window.alert: MTProto Entity: ' + message));
    } else {
      Sentry.captureException(new Error('window.alert: ' + message));
    }
    reestablishConnection(RECONNECT_TIMEOUT_MS);
  };

  // UNLOCK USER when unmount components
  useEffect(() => {
    return () => {
      window.addEventListener('beforeunload', async function (e) {
        await tgClient.destroyClient();
      });
    };
  });

  const establishConnection = async () => {
    // TODO (AWE): Disabled for testing
    dispatch(setUserStatus(TgSyncStatus.WAITING_FOR_CONNECTION));
    if (!flags.clientSync) {
      const isConnected = await isTelegramAccountConnected();
      dispatch(setLoadingClient(false));
      if (!isConnected) {
        tgClient.init();
        dispatch(setUserStatus(TgSyncStatus.DISCONNECTED));
      }
      return;
    }
    const sessionString = getStringSession();
    if (sessionString) {
      tgClient.init();
      const connected = await tgClient.connect();
      const isAuthorized = await tgClient.isAuthorized();

      if (!connected || !isAuthorized) {
        removeStringSession();
        await tgClient.destroyClient();
        tgClient.init();
        dispatch(setUserStatus(TgSyncStatus.DISCONNECTED));
        dispatch(setLoadingClient(false));
        return;
      }

      dispatch(setUserStatus(TgSyncStatus.CONNECTED));
      const telegramFolders = await fetchTelegramFolders();
      syncFolders({ telegramFolders });

      Sentry.setContext('telegramClient', {
        connected: true,
      });
      initListeners();
      return;
    } else {
      dispatch(setLoadingClient(false));
      tgClient.init();
      dispatch(setUserStatus(TgSyncStatus.DISCONNECTED));
    }
  };

  return { reestablishConnection, establishConnection };
};
