import { Box, Divider } from '@mui/joy';
import Typography from '@mui/joy/Typography';

const FilterDivider = () => {
  return (
    <Box sx={styles.container}>
      <Divider sx={styles.divider} />
      <Typography level="tiny" sx={{ marginX: '10px', alignSelf: 'center' }}>
        OR
      </Typography>
      <Divider sx={styles.divider} />
    </Box>
  );
};

export default FilterDivider;

const styles = {
  divider: {
    flexGrow: 1,
    alignSelf: 'center',
    border: '1px',
    background: 'var(--joy-palette-info-plainBorder)',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginY: '20px',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginX: '10px',
  },
};
