import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from 'services/store/AppStore';
import {
  SortDirectionType,
  SortConversationsOnPropertyType,
  FilterConversationsOnPropertyInput,
  FilterOnPropertyValue,
  ConversationsQueryInput,
  SortConversationsOnPropertiesInput,
  ChatsPageCoversationsQueryVariables,
  PartyType,
  DateRangeInput,
  ConversationType,
} from 'services/models/api/generated';
import { EmptyFilterType } from 'services/models/domain/entityViews';

export const DEFAULT_SORT_DIRECTION = SortDirectionType.Desc;
export const DEFAULT_SORT_FIELD = SortConversationsOnPropertyType.LastMessageDate;
export const FILTER_FORM_STORAGE_KEY = 'chats-filter-form';
export const DEFAULT_IS_PARTICIPANT = false;
export const DEFAULT_SHOW_FAVOURITE = false;
export const DEFAULT_SHOW_ARCHIVED = false;

export type FilterOnInput = Omit<FilterConversationsOnPropertyInput, 'favorite' | 'propertyValue'> & {
  favorite: boolean | undefined;
  archived: boolean;
  propertyValues?: FilterOnPropertyValue[] | undefined;
};

export type InputFilter = Omit<ConversationsQueryInput, 'pageRequest' | 'sortOn' | 'filterOn'> & {
  search: string;
  sortOn: SortConversationsOnPropertiesInput;
  filterOn: FilterOnInput;
  dateRange: DateRangeInput[] | undefined;
};

export type IChatFilters = {
  input: InputFilter;
  criteria: ChatsPageCoversationsQueryVariables['criteria'];
  reload: boolean;
  initial: boolean;
};

const initialState: IChatFilters = {
  input: {
    search: '',
    filterOn: {
      favorite: DEFAULT_SHOW_FAVOURITE,
      archived: DEFAULT_SHOW_ARCHIVED,
      partyScope: PartyType.Organization,
    },
    sortOn: {
      sortOnProperties: [
        {
          sortConversationsOnPropertyType: DEFAULT_SORT_FIELD,
          sortDirection: DEFAULT_SORT_DIRECTION,
        },
      ],
    },
    dateRange: undefined,
  },
  criteria: {
    partyScope: PartyType.Organization,
  },
  reload: false,
  initial: true,
};

const chatFiltersSlice = createSlice({
  name: 'chatFilters',
  initialState,
  reducers: {
    setIsParticipant: (state, action: PayloadAction<{ isParticipant: boolean }>) => {
      const { isParticipant } = action.payload;
      state.input.filterOn.partyScope = PartyType.Organization;
    },
    setFavorite: (state, action: PayloadAction<{ favorite: boolean }>) => {
      const { favorite } = action.payload;
      state.input.filterOn.favorite = favorite;
    },
    setArchived: (state, action: PayloadAction<{ archived: boolean }>) => {
      const { archived } = action.payload;
      state.input.filterOn.archived = archived;
    },
    setPropertyFilters: (state, action: PayloadAction<{ oid: string; propertyDefinitionOid: string }[]>) => {
      const { filterOn } = state.input;
      const newPropertyValues: FilterOnPropertyValue[] = action.payload.map(({ oid, propertyDefinitionOid }) => {
        return {
          oid: oid,
          propertyDefinitionOid: propertyDefinitionOid,
        };
      });
      state.input.filterOn = {
        ...filterOn,
        propertyValues: newPropertyValues,
      };
    },
    setFilterFolderOids: (state, action: PayloadAction<{ folderOids: string[] }>) => {
      const { folderOids } = action.payload;
      state.input.filterOn.folderOids = folderOids;
    },
    setFollowUpAssignedTo: (state, action: PayloadAction<{ followUpAssignedTo?: string[] }>) => {
      if (action.payload.followUpAssignedTo) {
        state.input.filterOn.followUpAssignedTo = action.payload.followUpAssignedTo;
      } else {
        delete state.input.filterOn.followUpAssignedTo;
      }
    },
    setConversationType: (
      state,
      action: PayloadAction<{ conversationType?: ConversationType; partyScope?: PartyType }>
    ) => {
      state.input.filterOn.conversationType = action.payload.conversationType;
      if (action.payload.partyScope) {
        state.input.filterOn.partyScope = action.payload.partyScope;
      } else {
        state.input.filterOn.partyScope = PartyType.Organization;
      }
    },
    setAddedByUser: (state, action: PayloadAction<{ addedByUser?: string[] }>) => {
      if (action.payload.addedByUser && action.payload.addedByUser.length > 0) {
        state.input.filterOn.addedByUsers = action.payload.addedByUser;
      } else {
        delete state.input.filterOn.addedByUsers;
      }
    },
    setParticipants: (state, action: PayloadAction<{ participant?: string[] }>) => {
      if (action.payload.participant && action.payload.participant.length > 0) {
        state.input.filterOn.usersAreParticipants = action.payload.participant;
      } else {
        delete state.input.filterOn.usersAreParticipants;
      }
    },
    setPropertyFilter: (state, action: PayloadAction<{ oid: string; propertyDefinitionOid: string }>) => {
      const { oid, propertyDefinitionOid } = action.payload;
      const { filterOn } = state.input;
      const existing = state.input.filterOn.propertyValues?.find((p) => p.oid === action.payload.oid);
      if (existing) {
        return;
      }
      const newPropertyValues = filterOn.propertyValues ? filterOn.propertyValues : [];
      newPropertyValues.push({ oid, propertyDefinitionOid });
      state.input.filterOn = {
        ...filterOn,
        propertyValues: newPropertyValues,
      };
    },
    unsetPropertyFilter: (state, action: PayloadAction<{ oid: string; propertyDefinitionOid: string }>) => {
      const { oid, propertyDefinitionOid } = action.payload;
      const newPropertyValues = state.input.filterOn.propertyValues?.filter(
        (value) => value.oid !== oid || value.propertyDefinitionOid !== propertyDefinitionOid
      );

      if (newPropertyValues?.length === 0) {
        delete state.input.filterOn.propertyValues;
      } else {
        state.input.filterOn.propertyValues = newPropertyValues;
      }
    },
    setFieldValue: (state, action: PayloadAction<{ path: string; value: any }>) => {
      const { path, value } = action.payload;
      const newFormValue = _.set(state, path, value);
      state = newFormValue;
    },
    setDateRange: (state, action: PayloadAction<{ dateRangeInput: DateRangeInput | undefined; set: boolean }>) => {
      const { dateRangeInput, set } = action.payload;
      let { dateRange } = state.input;

      if (set) {
        if (dateRangeInput) {
          const index = dateRange?.findIndex((range) => range.field === dateRangeInput.field);

          if (index !== undefined && index !== -1) {
            dateRange![index] = dateRangeInput;
          } else {
            dateRange = dateRange ? [...dateRange, dateRangeInput] : [dateRangeInput];
          }
        }
      } else {
        dateRange = dateRange?.filter((range) => range.field !== dateRangeInput?.field) || [];
      }
      state.input.dateRange = dateRange;
    },

    setNoPropertyAssigned: (state, action: PayloadAction<{ noPropertyValue: string; set: boolean }>) => {
      const { noPropertyValues } = state.input.filterOn;
      const { noPropertyValue, set } = action.payload;
      if (set) {
        const alreadyExists = noPropertyValues?.some((p) => p.propertyDefinitionOid === noPropertyValue);
        if (!alreadyExists) {
          state.input.filterOn.noPropertyValues = noPropertyValues
            ? [...noPropertyValues, { propertyDefinitionOid: noPropertyValue }]
            : [{ propertyDefinitionOid: noPropertyValue }];
        }
      } else {
        state.input.filterOn.noPropertyValues = noPropertyValues?.filter(
          (p) => p.propertyDefinitionOid !== noPropertyValue
        );
      }
    },

    setEmptyFilterType: (state, action: PayloadAction<{ emptyFilterType: EmptyFilterType | undefined }>) => {
      const { emptyFilterType } = action.payload;
      state.input.filterOn.emptyFilterType = emptyFilterType;
    },
    setReload: (state, action: PayloadAction<{ reload: boolean }>) => {
      state.reload = !state.reload;
    },
    clearFilter: (state, _: PayloadAction<void>) => {
      state.input.filterOn = {
        favorite: DEFAULT_SHOW_FAVOURITE,
        archived: DEFAULT_SHOW_ARCHIVED,
        partyScope: PartyType.Organization,
      };
      state.input.search = '';
      state.input.dateRange = undefined;
    },
    handleSortByField: (
      state,
      action: PayloadAction<{ sortByField: SortConversationsOnPropertyType; sortDirection?: SortDirectionType }>
    ) => {
      const { input } = state;
      const sortProperty = input.sortOn.sortOnProperties[0];
      const { sortDirection, sortConversationsOnPropertyType } = sortProperty;
      let nextSortDirection: SortDirectionType;
      const { sortByField } = action.payload;

      if (action.payload.sortDirection) {
        nextSortDirection = action.payload.sortDirection;
      } else if (sortByField !== sortConversationsOnPropertyType) {
        nextSortDirection = DEFAULT_SORT_DIRECTION;
      } else {
        nextSortDirection = sortDirection === SortDirectionType.Asc ? SortDirectionType.Desc : SortDirectionType.Asc;
      }

      state.input = {
        ...input,
        sortOn: {
          sortOnProperties: [
            {
              sortConversationsOnPropertyType: sortByField,
              sortDirection: nextSortDirection,
            },
          ],
        },
      };
    },
  },
});

export const {
  setIsParticipant,
  setFavorite,
  setFieldValue,
  handleSortByField,
  setPropertyFilter,
  unsetPropertyFilter,
  setPropertyFilters,
  setEmptyFilterType,
  setReload,
  clearFilter,
  setFilterFolderOids,
  setDateRange,
  setFollowUpAssignedTo,
  setConversationType,
  setAddedByUser,
  setParticipants,
  setNoPropertyAssigned,
  setArchived,
} = chatFiltersSlice.actions;

export const chatFilters = (state: RootState) => state.chatFilters;

export default chatFiltersSlice.reducer;
