import { Box, Typography } from '@mui/joy';
import { colors } from 'theme/colors';

interface DoubleLineTooltipContentsProps {
  primaryText: string;
  secondaryText: string;
}

export function DoubleLineTooltipContents({ primaryText, secondaryText }: DoubleLineTooltipContentsProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '2px 8px',
        borderRadius: '10px',
      }}
    >
      <Typography level="micro+" sx={{ color: colors.white }}>
        {primaryText}
      </Typography>
      <Typography level="micro" sx={{ color: colors.neutral[30] }}>
        {secondaryText}
      </Typography>
    </Box>
  );
}
