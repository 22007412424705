import { SelectedPropertyValue } from 'components/PropertiesList';
import { EntityPropertyDefinition, PropertyValue } from 'services/models/domain/entityViews';

export const groupSelectedPropertyValuesByPropertyDefinition = (properties: SelectedPropertyValue[]) => {
  return properties.reduce((acc: { [key: string]: string[] }, { oid, propertyDefinitionOid }) => {
    if (!acc[propertyDefinitionOid]) {
      acc[propertyDefinitionOid] = [];
    }
    acc[propertyDefinitionOid].push(oid);
    return acc;
  }, {});
};

export const mapSelectedPropertyValuesToProperties = (
  properties: SelectedPropertyValue[],
  propertyDefinition: EntityPropertyDefinition
): PropertyValue[] => {
  const propertyValueDetails: (PropertyValue | undefined)[] = properties.map(({ oid, propertyDefinitionOid }) => {
    const value = propertyDefinition.values.find((value) => {
      if ('oid' in value) {
        return value.oid.oid === oid;
      }
    });
    if (!value) return;

    return value;
  });

  return propertyValueDetails.filter((pv): boolean => pv !== undefined) as PropertyValue[];
};

export const arePropertyValueIdsEqual = (array1: (string | undefined)[], array2: string[]): boolean => {
  if (array1.length !== array2.length) {
    return false;
  }
  const sortedArray1 = array1.slice().sort((a, b) => {
    if (a === undefined) return -1;
    if (b === undefined) return 1;
    return a.localeCompare(b);
  });
  const sortedArray2 = array2.slice().sort();
  for (let i = 0; i < sortedArray1.length; i++) {
    if (sortedArray1[i] !== sortedArray2[i]) {
      return false;
    }
  }
  return true;
};
