import { Box, Button, Typography } from '@mui/joy';
import {
  EntityPropertyDefinition,
  OptionPropertyValue,
  PropertyDefinitionDataType,
  PropertyDefinitionKind,
  TenantUserPropertyValue,
} from 'services/models/domain/entityViews';
import EditProperty from 'components/Chats/ChatTableV2/EditProperty/EditProperty';
import TypeAheadInput, { TypeAheadEntity } from 'components/TypeAheadInput';
import { MutiRowBox } from 'components/MultiRowBox';
import { useMemo } from 'react';
import PropertyChip from 'components/Chats/ChatTableV2/TableCell/customCells/PropertyTypeahead/PropertyChip';
import { useTypeAheadPropertyValues } from 'services/hooks/useTypeAheadPropertyValues';
import { colors } from 'theme/colors';
import { IconsSvg } from 'assets/icons';
import Translations from 'const/translations/en';

interface PropertyTypeAheadProps {
  propertyDefinition: EntityPropertyDefinition;
  values: string[];
  entityId: string;
  handleChange?<T>(values: TypeAheadEntity<T>[]): void;
  height?: number;
}

export default function PropertyTypeAheadWithBackground({
  propertyDefinition,
  values: initialValueOids,
  entityId,
  handleChange,
  height,
}: PropertyTypeAheadProps) {
  const { updatePropertyValues, filterOptions, getTextFromOption, createOption } = useTypeAheadPropertyValues(
    entityId,
    initialValueOids,
    propertyDefinition
  );

  const definitionValues = propertyDefinition.values as (OptionPropertyValue | TenantUserPropertyValue)[];
  const selectedProperties = useMemo(() => {
    return definitionValues.filter(({ oid }) => initialValueOids?.includes(oid.oid));
  }, [definitionValues, initialValueOids]);

  const isMultiValue = propertyDefinition.kind === PropertyDefinitionKind.MultiValue;
  const isOptionDataType = propertyDefinition.dataType === PropertyDefinitionDataType.Option;

  return (
    <TypeAheadInput
      isMultiValue={isMultiValue}
      isEditable={isOptionDataType}
      containerSX={style.container}
      showChevronDown={true}
      height={height ?? 40}
      anchorComponent={(handleOpen) => (
        <>
          <Typography level="tiny" sx={{ color: colors.neutral[70], marginY: '4px' }}>
            {propertyDefinition.name}
          </Typography>
          <Button
            size="sm"
            id="property-menu-button"
            aria-haspopup="true"
            variant="plain"
            sx={style.cellButton}
            onClick={handleOpen}
          >
            {selectedProperties.length < 1 ? (
              <Typography level="tiny" sx={{ color: colors.neutral[40], padding: '0px 10px' }}>
                {Translations.PROPERTY_SELECT}
              </Typography>
            ) : (
              <MutiRowBox maxLines={2} showMultiLines={isMultiValue}>
                {selectedProperties.map((sp) => (
                  <PropertyChip key={sp.oid.oid} propertyValue={sp} isMultiValue={isMultiValue} />
                ))}
              </MutiRowBox>
            )}
            <IconsSvg.ic_chevron_down width={14} height={14} stroke={colors.neutral[50]} />
          </Button>
        </>
      )}
      options={definitionValues}
      initialValues={selectedProperties}
      EditItemComponent={
        isOptionDataType
          ? (handleBackClick) => (
              <EditProperty
                handleBackClick={handleBackClick}
                property={propertyDefinition}
                refetch={() => Promise.resolve()}
              />
            )
          : undefined
      }
      renderItemChip={(propertyValue, tagProps) => {
        if (typeof propertyValue === 'string') {
          return <span>{propertyValue}</span>;
        }
        return (
          <PropertyChip
            key={propertyValue.oid.oid}
            propertyValue={propertyValue}
            tagProps={tagProps}
            isMultiValue={isMultiValue}
          />
        );
      }}
      getTextFromOption={getTextFromOption}
      onAutocompleteClose={handleChange ?? updatePropertyValues}
      filterOptions={filterOptions}
      createOption={createOption}
    />
  );
}

const style = {
  cellButton: {
    width: '100%',
    minHeight: '34px',
    justifyContent: 'space-between',
    border: `1px solid ${colors.neutral[20]}`,
    borderRadius: '6px',
    backgroundColor: colors.neutral[5],
    '&:focus': {
      border: `1px solid ${colors.neutral[20]}`,
      borderRadius: '6px',
      backgroundColor: colors.neutral[5],
    },
    '&:hover': {
      border: `1px solid ${colors.neutral[20]}`,
      borderRadius: '6px',
      backgroundColor: colors.neutral[5],
    },
    padding: '0 10px 0 0',
    overflow: 'hidden',
  },
  container: {
    border: 'none',
  },
};
