import { Box } from '@mui/joy';
import Typography from '@mui/joy/Typography';
import Icons from 'assets/icons';
import { SxProps } from '@mui/material';

interface Props {
  title: JSX.Element | string;
  titleSx?: SxProps;
  message: JSX.Element | string;
  icon?: string | JSX.Element;
}

const NoData = ({ title, message, icon, titleSx }: Props) => {
  return (
    <Box
      sx={{
        margin: '6rem 50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '1rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '30vh',
      }}
    >
      {typeof icon === 'string' ? <img src={icon} width={20} height={20} alt="" /> : <>{icon}</>}
      <Typography level="tiny" sx={{ ...titleSx, textAlign: 'center' }}>
        {title}
      </Typography>
      <Typography level="tiny" justifyContent="center" sx={{ textAlign: 'center', color: 'info.main' }}>
        {message}
      </Typography>
    </Box>
  );
};

export default NoData;
