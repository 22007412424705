import { ReactComponent as UserSvg } from 'assets/icons/clean-svg/ic_user.svg';
import { ReactComponent as ConfettiSvg } from 'assets/icons/clean-svg/ic_confetti.svg';
import { ReactComponent as CheckSvg } from 'assets/icons/clean-svg/ic_check.svg';
import { ReactComponent as PresentationSvg } from 'assets/icons/clean-svg/ic_presentation.svg';
import { ReactComponent as DashboardSvg } from 'assets/icons/clean-svg/ic_reports.svg';
import { ReactComponent as PlayerPlaySvg } from 'assets/icons/clean-svg/ic_player_play.svg';
import { ReactComponent as EditColumnSvg } from 'assets/icons/clean-svg/ic_edit_column.svg';
import { ReactComponent as HideColumnSvg } from 'assets/icons/clean-svg/ic_hide_column.svg';
import { ReactComponent as SortArrowUpSvg } from 'assets/icons/clean-svg/ic_sort_arrow_up.svg';
import { ReactComponent as SortArrowDownSvg } from 'assets/icons/clean-svg/ic_sort_arrow_down.svg';
import { ReactComponent as ArrowRightSvg } from 'assets/icons/clean-svg/ic_arrow_right.svg';
import { ReactComponent as ArrowUpSvg } from 'assets/icons/clean-svg/ic_arrow_up.svg';
import { ReactComponent as ArrowDownSvg } from 'assets/icons/clean-svg/ic_arrow_down.svg';
import { ReactComponent as ArrowLeftSvg } from 'assets/icons/clean-svg/ic_arrow_left.svg';
import { ReactComponent as ChevronRightSvg } from 'assets/icons/clean-svg/ic_chevron_right.svg';
import { ReactComponent as MinusSvg } from 'assets/icons/clean-svg/ic_minus.svg';
import { ReactComponent as PlusSvg } from 'assets/icons/clean-svg/ic_plus.svg';
import { ReactComponent as TrashSvg } from 'assets/icons/clean-svg/ic_trash.svg';
import { ReactComponent as ActivitySvg } from 'assets/icons/clean-svg/ic_activity.svg';
import { ReactComponent as AlarmSvg } from 'assets/icons/clean-svg/ic_alarm.svg';
import { ReactComponent as BeachSvg } from 'assets/icons/clean-svg/ic_beach.svg';
import { ReactComponent as BellSvg } from 'assets/icons/clean-svg/ic_bell.svg';
import { ReactComponent as BrandTelegramSvg } from 'assets/icons/clean-svg/ic_brand_telegram.svg';
import { ReactComponent as CactusSvg } from 'assets/icons/clean-svg/ic_cactus.svg';
import { ReactComponent as CannabisSvg } from 'assets/icons/clean-svg/ic_cannabis.svg';
import { ReactComponent as ChecksSvg } from 'assets/icons/clean-svg/ic_checks.svg';
import { ReactComponent as CircleDottedSvg } from 'assets/icons/clean-svg/ic_circle_dotted.svg';
import { ReactComponent as CoffeeSvg } from 'assets/icons/clean-svg/ic_coffee.svg';
import { ReactComponent as CoinSvg } from 'assets/icons/clean-svg/ic_coin.svg';
import { ReactComponent as DiscountSvg } from 'assets/icons/clean-svg/ic_discount.svg';
import { ReactComponent as FileDollarSvg } from 'assets/icons/clean-svg/ic_file_dollar.svg';
import { ReactComponent as LoaderSvg } from 'assets/icons/clean-svg/ic_loader.svg';
import { ReactComponent as LogoutSvg } from 'assets/icons/clean-svg/ic_logout.svg';
import { ReactComponent as Number0Svg } from 'assets/icons/clean-svg/ic_number_0.svg';
import { ReactComponent as Number1Svg } from 'assets/icons/clean-svg/ic_number_1.svg';
import { ReactComponent as Number2Svg } from 'assets/icons/clean-svg/ic_number_2.svg';
import { ReactComponent as Number3Svg } from 'assets/icons/clean-svg/ic_number_3.svg';
import { ReactComponent as Number4Svg } from 'assets/icons/clean-svg/ic_number_4.svg';
import { ReactComponent as Number5Svg } from 'assets/icons/clean-svg/ic_number_5.svg';
import { ReactComponent as Number6Svg } from 'assets/icons/clean-svg/ic_number_6.svg';
import { ReactComponent as Number7Svg } from 'assets/icons/clean-svg/ic_number_7.svg';
import { ReactComponent as Number8Svg } from 'assets/icons/clean-svg/ic_number_8.svg';
import { ReactComponent as Number9Svg } from 'assets/icons/clean-svg/ic_number_9.svg';
import { ReactComponent as PigMoneySvg } from 'assets/icons/clean-svg/ic_pig_money.svg';
import { ReactComponent as PlantSvg } from 'assets/icons/clean-svg/ic_plant.svg';
import { ReactComponent as RunSvg } from 'assets/icons/clean-svg/ic_run.svg';
import { ReactComponent as TriangleSvg } from 'assets/icons/clean-svg/ic_triangle.svg';
import { ReactComponent as XSvg } from 'assets/icons/clean-svg/ic_x.svg';
import { ReactComponent as GridVerticalSvg } from 'assets/icons/clean-svg/ic_grid_vertical.svg';
import { ReactComponent as CrownSvg } from 'assets/icons/clean-svg/ic_crown.svg';
import { ReactComponent as StatusChangeSvg } from 'assets/icons/clean-svg/ic_status_change.svg';
import { ReactComponent as ChevronDownSvg } from 'assets/icons/clean-svg/ic_chevron_down.svg';
import { ReactComponent as ChatSvg } from 'assets/icons/ic_chat_bubble.svg';
import { ReactComponent as StarSvg } from 'assets/icons/ic_star_outline.svg';
import { ReactComponent as MessageSvg } from 'assets/icons/ic_message.svg';
import { ReactComponent as ContactSvg } from 'assets/icons/ic_participants.svg';
import { ReactComponent as CalendarSvg } from 'assets/icons/ic_calendar.svg';
import { ReactComponent as ChromeSvg } from 'assets/icons/ic_chrome.svg';
import { ReactComponent as InviteTeammatesSvg } from 'assets/icons/ic_invite_teammate.svg';
import { ReactComponent as ChevronsLeft } from 'assets/icons/ic_chevrons_left.svg';
import { ReactComponent as ChevronsRight } from 'assets/icons/ic_chevrons_right.svg';
import { ReactComponent as Network } from 'assets/icons/ic_network.svg';
import { ReactComponent as BriefCase } from 'assets/icons/ic_briefcase.svg';
import { ReactComponent as MessageDialog } from 'assets/icons/ic_message_dialog.svg';
import { ReactComponent as Triangle } from 'assets/icons/ic_triangle.svg';
import { ReactComponent as Clock } from 'assets/icons/ic_clock.svg';
import { ReactComponent as OpenLink } from 'assets/icons/ic_open_link.svg';
import { ReactComponent as Telegram } from 'assets/icons/ic_tg_sync_connected.svg';
import { ReactComponent as MessageSmiley } from 'assets/icons/ic_message_smiley.svg';
import { ReactComponent as StarFilled } from 'assets/icons/nav/ic_star.svg';
import { ReactComponent as HeartHandShake } from 'assets/icons/ic_heart_handshake.svg';
import { ReactComponent as UserGroups } from 'assets/icons/ic_user_groups.svg';
import { ReactComponent as Speakerphone } from 'assets/icons/ic_speakerphone.svg';
import { ReactComponent as Question } from 'assets/icons/ic_question.svg';
import { ReactComponent as Filter } from 'assets/icons/ic_filter.svg';
import { ReactComponent as Return } from 'assets/icons/ic_return.svg';
import { ReactComponent as BorderNone } from 'assets/icons/ic_border_none.svg';
import { ReactComponent as Upcoming } from 'assets/icons/ic_upcoming.svg';
import { ReactComponent as Today } from 'assets/icons/ic_today.svg';
import { ReactComponent as Overdue } from 'assets/icons/ic_overdue.svg';
import { ReactComponent as SquareCircle } from 'assets/icons/ic_square_circle.svg';
import { ReactComponent as Category } from 'assets/icons/ic_category.svg';
import { ReactComponent as Folders } from 'assets/icons/ic_folders.svg';
import { ReactComponent as UserPlus } from 'assets/icons/nav/ic_user_plus.svg';
import { ReactComponent as Users } from 'assets/icons/ic_users.svg';
import { ReactComponent as DollarFile } from 'assets/icons/ic_dollar_file.svg';
import { ReactComponent as CheckLight } from 'assets/icons/ic_check_light.svg';
import { ReactComponent as Duplicate } from 'assets/icons/ic_duplicate.svg';
import { ReactComponent as Search } from 'assets/icons/ic_search.svg';
import { ReactComponent as Edit } from 'assets/icons/ic_pen.svg';
import { ReactComponent as Dots } from 'assets/icons/ic_dots.svg';
import { ReactComponent as ImageAdd } from 'assets/icons/ic_image_add.svg';
import { ReactComponent as BuildingSkyscraper } from 'assets/icons/ic_building_skyscraper.svg';
import { ReactComponent as ListDetails } from 'assets/icons/ic_list_details.svg';
import { ReactComponent as OpenFull } from 'assets/icons/ic_open_full.svg';
import { ReactComponent as Sidebar } from 'assets/icons/ic_sidebar.svg';
import { ReactComponent as Archive } from 'assets/icons/ic_archive.svg';
import { ReactComponent as Close } from 'assets/icons/clean-svg/ic_close.svg';
import { ReactComponent as Copy } from 'assets/icons/ic_copy.svg';
import { ReactComponent as Notification } from 'assets/icons/ic_notification.svg';
import { ReactComponent as ABC } from 'assets/icons/ic_abc.svg';
import { ReactComponent as CalendarOne } from 'assets/icons/ic_calendar_1.svg';
import { ReactComponent as CircleCheck } from 'assets/icons/ic_circle_check.svg';
import { ReactComponent as ThreeCircles } from 'assets/icons/ic_circles.svg';
import { ReactComponent as TagOff } from 'assets/icons/ic_tag_off.svg';
import { ReactComponent as ClockEdit } from 'assets/icons/ic_clock_edit.svg';
import { ReactComponent as CloseBroad } from 'assets/icons/ic_close_broad.svg';

const Icons = {
  ic_document: require('assets/icons/ic_document.svg').default,
  ic_paper_plane: require('assets/icons/ic_paper_plane.svg').default,
  ic_send: require('assets/icons/ic_send.svg').default,
  ic_calendar_reset: require('assets/icons/ic_calendar_reset.svg').default,
  ic_close: require('assets/icons/ic_close.svg').default,
  ic_search: require('assets/icons/ic_search.svg').default,
  ic_pen: require('assets/icons/ic_pen.svg').default,
  ic_message: require('assets/icons/ic_message.svg').default,
  ic_check: require('assets/icons/ic_check.svg').default,
  ic_file_plus: require('assets/icons/ic_file_plus.svg').default,
  ic_open_link: require('assets/icons/ic_open_link.svg').default,
  ic_no_link: require('assets/icons/ic_no_link.svg').default,
  ic_chat_bubble: require('assets/icons/ic_chat_bubble.svg').default,
  ic_clock: require('assets/icons/nav/ic_clock.svg').default,
  ic_star: require('assets/icons/nav/ic_star.svg').default,
  ic_plus: require('assets/icons/nav/ic_plus.svg').default,
  ic_right_arrow: require('assets/icons/ic_right_arrow.svg').default,
  ic_calendar_plus: require('assets/icons/ic_calendar_plus.svg').default,
  ic_star_filled: require('assets/icons/ic_star_filled.svg').default,
  ic_star_outlined: require('assets/icons/ic_star_outline.svg').default,
  ic_warning: require('assets/icons/ic_warning.svg').default,
  ic_warning_snackbar: require('assets/icons/ic_warning_snackbar.svg').default,
  ic_participants: require('assets/icons/ic_participants.svg').default,
  ic_participants_filled: require('assets/icons/ic_participants_filled.svg').default,
  ic_user: require('assets/icons/ic_user.svg').default,
  ic_arrow_left: require('assets/icons/ic_arrow_left.svg').default,
  ic_telegram: require('assets/icons/ic_telegram.svg').default,
  ic_telegram_color: require('assets/icons/ic_telegram_color.svg').default,
  ic_sort: require('assets/icons/ic_sort.svg').default,
  ic_arrow_forward: require('assets/icons/ic_arrow_forward.svg').default,
  ic_add_notes: require('assets/icons/ic_add_notes.svg').default,
  ic_chevron_down: require('assets/icons/ic_chevron_down.svg').default,
  ic_logout: require('assets/icons/ic_logout.svg').default,
  ic_settings: require('assets/icons/ic_settings.svg').default,
  ic_open_link_dark: require('assets/icons/ic_open_link_dark.svg').default,
  ic_chevron_right: require('assets/icons/ic_chevron_right.svg').default,
  ic_chevron_left: require('assets/icons/ic_chevron_left.svg').default,
  ic_calendar_event: require('assets/icons/ic_calendar_event.svg').default,
  ic_dots: require('assets/icons/ic_more_vert.svg').default,
  ic_trash: require('assets/icons/ic_trash.svg').default,
  ic_telegram_outlined: require('assets/icons/ic_telegram.svg').default,
  ic_edit: require('assets/icons/ic_edit.svg').default,
  ic_tabler_icon_refresh: require('assets/icons/ic_tabler_icon_refresh.svg').default,
  ic_tabler_icon_refresh_off: require('assets/icons/ic_tabler_icon_refresh_off.svg').default,
  ic_no_folder: require('assets/icons/ic_no_folder.svg').default,
  ic_no_bot: require('assets/icons/ic_no_bot.svg').default,
  ic_bot: require('assets/icons/ic_bot.svg').default,
  ic_image_upload: require('assets/icons/ic_image_upload.svg').default,
  ic_plane_small: require('assets/icons/ic_plane_small.svg').default,
  ic_triangle_alert: require('assets/icons/ic_triangle_alert.svg').default,
  ic_confetti: require('assets/icons/ic_confetti.svg').default,
  ic_close_broad: require('assets/icons/ic_close_broad.svg').default,
  ic_tg_sync_connected: require('assets/icons/ic_tg_sync_connected.svg').default,
  ic_tg_sync_disconnected: require('assets/icons/ic_tg_sync_disconnected.svg').default,
  ic_presentation: require('assets/icons/ic_presentation.svg').default,
  ic_player_play: require('assets/icons/ic_player_play.svg').default,
  ic_checks: require('assets/icons/ic_checks.svg').default,
  ic_hamburger: require('assets/icons/ic_hamburger.svg').default,
  ic_users: require('assets/icons/ic_users.svg').default,
  ic_reports: require('assets/icons/ic_reports.svg').default,
  ic_chats: require('assets/icons/ic_chats.svg').default,
  ic_no_chat: require('assets/icons/ic_no_chat.svg').default,
  ic_chatbubble_strike: require('assets/icons/ic_chatbubble_strike.svg').default,
  ic_copy: require('assets/icons/ic_copy.svg').default,
  ic_user_strike: require('assets/icons/ic_user_strike.svg').default,
  ic_user_search: require('assets/icons/ic_user_search.svg').default,
  ic_invite_teammate: require('assets/icons/ic_invite_teammate.svg').default,
  ic_link: require('assets/icons/ic_link.svg').default,
  ic_chrome: require('assets/icons/ic_chrome.svg').default,
  ic_book: require('assets/icons/ic_book.svg').default,
  ic_sidebar: require('assets/icons/ic_sidebar.svg').default,
  ic_friends: require('assets/icons/ic_friends.svg').default,
  ic_twitter: require('assets/icons/ic_twitter.svg').default,
  ic_address_book_off: require('assets/icons/ic_address_book_off.svg').default,
  ic_folders: require('assets/icons/ic_folders.svg').default,
  ic_rt_bold: require('assets/icons/ic_rt_bold.svg').default,
  ic_rt_italic: require('assets/icons/ic_rt_italic.svg').default,
  ic_rt_image: require('assets/icons/ic_rt_image.svg').default,
  ic_rt_link: require('assets/icons/ic_rt_link.svg').default,
  ic_rt_emoji: require('assets/icons/ic_rt_emoji.svg').default,
  ic_logo: require('assets/icons/logo.svg').default,
  ic_loader: require('assets/icons/ic_loader.svg').default,
  ic_check_light: require('assets/icons/ic_check_light.svg').default,
  ic_image_add: require('assets/icons/ic_image_add.svg').default,
  ic_building_skyscraper: require('assets/icons/ic_building_skyscraper.svg').default,
  ic_list_details: require('assets/icons/ic_list_details.svg').default,
  ic_open_full: require('assets/icons/ic_open_full.svg').default,
};

export const IconsSvg = {
  ic_user: UserSvg,
  ic_confetti: ConfettiSvg,
  ic_check: CheckSvg,
  ic_checks: ChecksSvg,
  ic_presentation: PresentationSvg,
  ic_reports: DashboardSvg,
  ic_player_play: PlayerPlaySvg,
  ic_edit_column: EditColumnSvg,
  ic_hide_column: HideColumnSvg,
  ic_sort_arrow_up: SortArrowUpSvg,
  ic_sort_arrow_down: SortArrowDownSvg,
  ic_arrow_right: ArrowRightSvg,
  ic_arrow_up: ArrowUpSvg,
  ic_arrow_down: ArrowDownSvg,
  ic_arrow_left: ArrowLeftSvg,
  ic_chevron_right: ChevronRightSvg,
  ic_minus: MinusSvg,
  ic_plus: PlusSvg,
  ic_trash: TrashSvg,
  ic_number_0: Number0Svg,
  ic_number_1: Number1Svg,
  ic_number_2: Number2Svg,
  ic_number_3: Number3Svg,
  ic_number_4: Number4Svg,
  ic_number_5: Number5Svg,
  ic_number_6: Number6Svg,
  ic_number_7: Number7Svg,
  ic_number_8: Number8Svg,
  ic_number_9: Number9Svg,
  ic_activity: ActivitySvg,
  ic_alarm: AlarmSvg,
  ic_beach: BeachSvg,
  ic_bell: BellSvg,
  ic_brand_telegram: BrandTelegramSvg,
  ic_cactus: CactusSvg,
  ic_cannabis: CannabisSvg,
  ic_circle_dotted: CircleDottedSvg,
  ic_coffee: CoffeeSvg,
  ic_coin: CoinSvg,
  ic_discount: DiscountSvg,
  ic_file_dollar: FileDollarSvg,
  ic_loader: LoaderSvg,
  ic_logout: LogoutSvg,
  ic_pig_money: PigMoneySvg,
  ic_plant: PlantSvg,
  ic_run: RunSvg,
  ic_triangle: TriangleSvg,
  ic_x: XSvg,
  ic_grid_vertical: GridVerticalSvg,
  ic_crown: CrownSvg,
  ic_status_change: StatusChangeSvg,
  ic_chevron_down: ChevronDownSvg,
  ic_chat: ChatSvg,
  ic_star: StarSvg,
  ic_message: MessageSvg,
  ic_contact: ContactSvg,
  ic_calendar: CalendarSvg,
  ic_chrome: ChromeSvg,
  ic_invite_teammates: InviteTeammatesSvg,
  ic_chevrons_left: ChevronsLeft,
  ic_chevrons_right: ChevronsRight,
  ic_network: Network,
  ic_briefcase: BriefCase,
  ic_message_dialog: MessageDialog,
  ic_warning_triangle: Triangle,
  ic_clock_active: Clock,
  ic_open_link: OpenLink,
  ic_telegram: Telegram,
  ic_message_smiley: MessageSmiley,
  ic_star_filled: StarFilled,
  ic_heart_handshake: HeartHandShake,
  ic_user_groups: UserGroups,
  ic_speakerphone: Speakerphone,
  ic_question: Question,
  ic_filter: Filter,
  ic_return: Return,
  ic_border_none: BorderNone,
  ic_upcoming: Upcoming,
  ic_today: Today,
  ic_overdue: Overdue,
  ic_square_circle: SquareCircle,
  ic_category: Category,
  ic_folders: Folders,
  ic_clock: Clock,
  ic_user_plus: UserPlus,
  ic_users: Users,
  ic_dollar_file: DollarFile,
  ic_check_light: CheckLight,
  ic_duplicate: Duplicate,
  ic_search: Search,
  ic_edit: Edit,
  ic_dots: Dots,
  ic_imageAdd: ImageAdd,
  ic_buildingSkyscraper: BuildingSkyscraper,
  ic_list_details: ListDetails,
  ic_open_full: OpenFull,
  ic_sidebar: Sidebar,
  ic_archive: Archive,
  ic_close: Close,
  ic_copy: Copy,
  ic_notification: Notification,
  ic_abc: ABC,
  ic_calendar_1: CalendarOne,
  ic_circle_check: CircleCheck,
  ic_three_circles: ThreeCircles,
  ic_tag_off: TagOff,
  ic_clock_edit: ClockEdit,
  ic_close_broad: CloseBroad,
};

export default Icons;
