import { List } from '@mui/joy';
import DetailsCard from 'components/Details/DetailsCard';
import ParticipantItem from 'components/Details/ParticipantItem';
import ParticipantPlaceholder from 'page/details/placeholder/ParticipantPlaceholder';
import { participantListStyle } from 'page/details/styles';
import ParticipantInfo from 'components/Details/ParticipantInfo';
import { EntityDetailsType } from 'services/store/slices/sidebarSlice';
import { useSidebar } from 'services/hooks/useSidebar';
import Translations from 'const/translations/en';
import { ConversationParticipant } from 'services/models/domain/conversation';

interface MembersDetialsCardProps {
  participants: ConversationParticipant[] | undefined;
  loading: boolean;
}
export default function MembersDetialsCard({ participants, loading }: MembersDetialsCardProps) {
  const { openSidebar } = useSidebar();

  return (
    <DetailsCard title={Translations.MEMBERS}>
      {loading && <ParticipantPlaceholder />}
      {!loading && participants && participants.length > 0 && (
        <List sx={participantListStyle}>
          {participants.map((p: ConversationParticipant, key: number) => (
            <ParticipantItem
              participant={p}
              key={key}
              onDetailsClicked={() => openSidebar(p.oid.oid, EntityDetailsType.contactDetails)}
            />
          ))}
        </List>
      )}
      {ParticipantInfo()}
    </DetailsCard>
  );
}
