import { SxProps } from '@mui/material';
import { colors } from 'theme/colors';

export const MenuSx: SxProps = {
  '--joy-shadows-8': '0px 0px 2px rgba(0, 0, 0, 0.1), 0px 0px 20px rgba(0, 0, 0, 0.08)',
  '.MuiMenu-list': {
    padding: 0,
    minWidth: 144,
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1), 0px 0px 20px rgba(0, 0, 0, 0.08)',
  },
  '.MuiPaper-root': {
    border: `1px solid ${colors.neutral[5]} !important`,
  },
};

export const AnchorSx: SxProps = {
  width: '100%',
  height: '100%',
};

export const OptionsSx: SxProps = {
  padding: '8px',
  alignItems: 'left',
  maxHeight: 'fit-content !important',
  minHeight: 'fit-content !important',
  '&:hover': {
    background: `${colors.neutral[0]}`,
  },
};
