import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { AppContext } from 'services/store/AppContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { userTgState } from 'services/store/slices/userTgSlice';
import { useSelector } from 'react-redux';
import { authState } from 'services/store/slices/authSlice';

export type TgSyncState = {
  tgSynced: boolean;
  loading: boolean;
};

const initialState: TgSyncState = {
  tgSynced: false,
  loading: false,
};

const HANDLERS = {
  TG_SYNC: 'TG_SYNC',
  TG_SYNC_LOADING: 'TG_SYNC_LOADING',
} as const;

type ActionPayload = {
  [key in keyof TgSyncState]: TgSyncState[key];
};

type Action = {
  type: keyof typeof HANDLERS;
  payload: ActionPayload;
};

export const reducer = (state: TgSyncState, action: Action) => {
  const { tgSynced, loading } = action.payload;

  switch (action.type) {
    case HANDLERS.TG_SYNC:
      return {
        ...state,
        tgSynced,
      };
    case HANDLERS.TG_SYNC_LOADING:
      return {
        ...state,
        loading,
      };
    default:
      return state;
  }
};

const TgSyncContext = createContext({
  ...initialState,
});

interface Props {
  children: React.ReactNode;
}

const TgSyncProvider = ({ children }: Props) => {
  const { isAuthenticated } = useSelector(authState);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { tgClient } = useContext(AppContext);
  const { clientSync } = useFlags();
  const { status } = useSelector(userTgState);

  const setTgSyncStatus = useCallback(
    (isTgSynced: boolean) => {
      dispatch({
        type: HANDLERS.TG_SYNC,
        payload: {
          ...state,
          tgSynced: isTgSynced,
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch({
        type: HANDLERS.TG_SYNC,
        payload: {
          ...initialState,
        },
      });
    }
  }, [!isAuthenticated]);

  useEffect(() => {
    // async function fetchTgConnectStatus() {
    //   const { data } = await fetchIsTelegramAccountConnected();
    //   const isConnectedData = data?.isTelegramAccountConnected?.data;
    //   const isTelegramAccountConnected = isConnectedData?.isTelegramAccountConnected ?? false;
    //   setTgSyncStatus(isTelegramAccountConnected);
    // }

    async function fetchTgConnectionStatusClientSide() {
      const isUserAuth = await tgClient.isAuthorized();
      setTgSyncStatus(isUserAuth);
    }

    // if (!clientSync) fetchTgConnectStatus();
    if (clientSync) fetchTgConnectionStatusClientSide();
  }, [isAuthenticated, status]);

  const stateMemo = useMemo(
    () => ({
      ...state,
      setTgSyncStatus,
    }),
    [state, setTgSyncStatus]
  );

  return <TgSyncContext.Provider value={stateMemo}>{children}</TgSyncContext.Provider>;
};

export { TgSyncContext, TgSyncProvider };
