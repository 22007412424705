import { Box } from '@mui/joy';
import CustomAvatar from 'components/CustomAvatar';
import { FilterCheckList, Item, ItemGroup } from 'components/FilterCheckList/FilterCheckList';
import { FolderImage } from 'components/FolderImage/FolderImage';
import { FilterOption } from 'services/models/domain/entityFilter';
import { getUsersInitials } from 'services/utils/helpers';
import { groupFoldersByUser } from 'services/utils/conversationPageUtils';
import { useSelector } from 'react-redux';
import { foldersState } from 'services/store/slices/foldersSlice';
import { useEffect, useState } from 'react';
import { ApplyFilterButton } from 'page/chats/ChatTableFilterBar/FilterOptions/ApplyFilterButton';
import _ from 'lodash';

export interface FolderFilterMenuProps {
  filterOptions: FilterOption[];
  selectedFilter?: FilterOption;
  onChanged: (folderOids: string[]) => void;
  onClose: () => void;
}

export const FolderFilterMenu = (props: FolderFilterMenuProps) => {
  const { folders } = useSelector(foldersState);
  const [selectedFolderOids, setSelectedFolderOids] = useState<string[]>([]);
  const [initialValue, setInitialValue] = useState<string[]>([]);

  const groupedFolders = groupFoldersByUser(folders);

  useEffect(() => {
    if (props.selectedFilter && 'value' in props.selectedFilter) {
      const filter = props.selectedFilter.value;
      if ('folderOids' in filter) {
        setInitialValue(filter.folderOids);
        setSelectedFolderOids(filter.folderOids);
      }
    }
  }, []);

  useEffect(() => {
    if (props.selectedFilter && 'value' in props.selectedFilter) {
      const filter = props.selectedFilter.value;
      if ('folderOids' in filter) {
        setSelectedFolderOids(filter.folderOids);
      }
    }
  }, [props.selectedFilter]);

  const groups: ItemGroup[] = groupedFolders.map(({ user, folders }) => {
    const items: Item[] = folders.map((f) => {
      return {
        id: f.oid.oid,
        title: f.folderName,
        icon: <FolderImage width={16} height={15} />,
      };
    });
    return {
      items: items,
      title: user.fullName,
      icon: (
        <CustomAvatar imgUrl={user.profilePicture} userInitials={getUsersInitials(user.fullName)} index={0} size={20} />
      ),
    };
  });

  return (
    <Box sx={{ width: '230px' }}>
      <FilterCheckList
        groups={groups}
        checkedIds={selectedFolderOids}
        onChangeChecked={(ids) => {
          props.onChanged(ids);
          setSelectedFolderOids(ids);
        }}
      />
      {!_.isEqual(initialValue, selectedFolderOids) && (
        <ApplyFilterButton
          onClick={props.onClose}
          sx={{ width: 'calc(100% - 23px)', margin: '12px', marginLeft: '13px' }}
        />
      )}
    </Box>
  );
};
