import { IconButton } from '@mui/joy';
import Icons from 'assets/icons';
import MenuOption from 'components/ui-library/chat/CellMenu/CellMenuOption/CellMenuOption';
import ChatChip from 'components/ui-library/chat/Chip/ChatChip';
import {
  CloseIconSx,
  SelectedMenuItemSx,
} from 'components/Chats/ChatTableV2/TableCell/customCells/OptionProperty/styles';
import { OptionsMenuProps } from 'components/Chats/ChatTableV2/TableCell/customCells/OptionProperty/OptionsMenu/interface';
import { OptionPropertyValue, PropertyDefinitionKind } from 'services/models/domain/entityViews';
import { ChatChipStyle } from 'components/ui-library/chat/Chip/interface';

const OptionsMenu = ({
  selectedProperties,
  definitionValues,
  handleMenuClose,
  handleSelect,
  handleUnset,
  propertyDefinition,
}: OptionsMenuProps) => {
  const renderPropertyOptions = () =>
    definitionValues.map((propertValue) => {
      const { oid, color, value, icon } = propertValue;

      return (
        <MenuOption key={oid.oid} onClick={() => handleSetClick(oid.oid)}>
          <ChatChip
            chipColor={color}
            icon={icon}
            chipStyle={
              propertyDefinition.kind === PropertyDefinitionKind.MultiValue ? ChatChipStyle.Edged : ChatChipStyle.Round
            }
          >
            {value}
          </ChatChip>
        </MenuOption>
      );
    });

  const handleSetClick = (propertyValueOid: string) => {
    handleSelect(propertyValueOid);
    if (propertyDefinition.kind === PropertyDefinitionKind.SingleValue) {
      handleMenuClose();
    }
  };

  const handleUnsetClick = (propertyValueOid: string) => () => {
    handleUnset(propertyValueOid);
    if (propertyDefinition.kind === PropertyDefinitionKind.SingleValue) {
      handleMenuClose();
    }
  };

  const renderSelectedOption = (propertyValue: OptionPropertyValue, index: number) => {
    return (
      <MenuOption key={propertyValue.oid.oid} sx={index === selectedProperties.length - 1 ? SelectedMenuItemSx : {}}>
        <ChatChip
          chipColor={propertyValue.color}
          icon={propertyValue.icon}
          chipStyle={
            propertyDefinition.kind === PropertyDefinitionKind.MultiValue ? ChatChipStyle.Edged : ChatChipStyle.Round
          }
        >
          {propertyValue.value}
        </ChatChip>

        <IconButton
          size="sm"
          variant="plain"
          color="primary"
          onClick={handleUnsetClick(propertyValue.oid.oid)}
          sx={CloseIconSx}
        >
          <img width={16} height={16} alt={'close'} src={Icons.ic_close} />
        </IconButton>
      </MenuOption>
    );
  };

  return (
    <>
      {selectedProperties.map(renderSelectedOption)}
      {renderPropertyOptions()}
    </>
  );
};

export default OptionsMenu;
