import { GridCell } from '@mui/x-data-grid-pro';
import { styled } from '@mui/material';
import { colors } from 'theme/colors';

export const GridCellEditable = styled(GridCell)({
  padding: '0 !important',
  paddingLeft: '.5px',
  paddingBottom: '0.5px',
  '&:hover': {
    background: `${colors.cellHoverBg}`,
    '& #followup-hover-message, & #notes-hover-message': {
      display: 'flex',
    },
  },
});
