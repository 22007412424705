import { Box, SvgIcon } from '@mui/joy';
import Icons from 'assets/icons';
import { ReactComponent as SearchIcon } from 'assets/icons/ic_search.svg';
import Input from '@mui/joy/Input';
import { IconButton, SxProps } from '@mui/material';
import { colors } from 'theme/colors';

export type SearchBarProps = {
  onChange: (value: string) => void;
  value: string;
  placeholder: string;
  sx: SxProps | undefined;
  onClear: () => void;
  onBlur?: () => void;
  autofocus?: boolean;
};

const SearchBar = ({ onChange, placeholder, value, sx, onClear, onBlur, autofocus }: SearchBarProps) => (
  <Box id="search-bar">
    <Input
      size="md"
      placeholder={placeholder}
      autoFocus={autofocus}
      onBlur={onBlur}
      startDecorator={<SearchIcon width={12} height={12} stroke={colors.neutral[40]} />}
      value={value}
      onChange={(evt) => {
        onChange(evt.target.value);
      }}
      sx={sx}
      endDecorator={
        value !== '' && (
          <IconButton sx={{ width: '2px' }} onClick={onClear}>
            <img src={Icons.ic_close} alt="ic_close" width={18} height={18} />
          </IconButton>
        )
      }
    />
  </Box>
);

export default SearchBar;
