import { useState } from 'react';

import { Typography, ListItem } from '@mui/joy';
import { Stack } from '@mui/material';

import Icons from 'assets/icons';
import { listItemsSx } from 'page/modal/TgConnectModal/TgFolderSync/TgFolderItem/styles';
import { TgFolderItemProps } from 'page/modal/TgConnectModal/TgFolderSync/TgFolderItem/interface';
import Switch from '@mui/joy/Switch';

const TgFolderItem = ({ folder, updateFolder, initialSyncState, active }: TgFolderItemProps) => {
  const { oid, folderName, synced } = folder;
  const [isSynced, setIsSynced] = useState(synced);
  const handleToggleFolderSync = () => {
    const nextSyncedState = !isSynced;
    setIsSynced(nextSyncedState);
    updateFolder({ ...folder, synced: nextSyncedState });
  };

  return (
    <ListItem sx={{ ...listItemsSx, backgroundColor: isSynced ? '#F9FCFF' : '' }} key={oid.oid}>
      <Stack sx={{ flexDirection: 'row' }} gap={1}>
        {isSynced ? (
          <img src={Icons.ic_tabler_icon_refresh} alt="Tg folder sync" />
        ) : (
          <img src={Icons.ic_tabler_icon_refresh_off} alt="Tg folder sync" />
        )}
        <Stack>
          <Typography level="tiny">{folderName}</Typography>
          <Typography level="legalMicro">{initialSyncState ? 'Synced' : 'Unsynced'}</Typography>
        </Stack>
      </Stack>

      <Switch checked={isSynced} onChange={handleToggleFolderSync} disabled={!active} />
    </ListItem>
  );
};

export default TgFolderItem;
