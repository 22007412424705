import { Box, Checkbox, Divider, Typography } from '@mui/joy';
import { CheckboxSx, CircleStyle, ClickableTextStyle, DividerSx } from 'components/FilterCheckList/style';
import { Flex1, FlexCol } from 'theme/flex';
import { useState } from 'react';
import { IconsSvg } from 'assets/icons';
import { colors } from 'theme/colors';
import Translations from 'const/translations/en';
import { useFlags } from 'launchdarkly-react-client-sdk';

export type Item = {
  id: string;
  title: string;
  icon?: JSX.Element;
};
export type ItemGroup = {
  items: Item[];
  title: string;
  icon?: JSX.Element;
};

export type FilterCheckListProps = {
  groups: ItemGroup[];
  onChangeChecked: (ids: string[]) => void;
  checkedIds: string[];
};

const Chevron = IconsSvg.ic_chevron_down;

export const FilterCheckList = (props: FilterCheckListProps) => {
  const onSelectAll = () => {
    props.onChangeChecked(props.groups.flatMap((g) => g.items).map((i) => i.id));
  };

  const renderItems = () => {
    return (
      <Box sx={{ maxHeight: '420px', overflowY: 'scroll', paddingRight: '5px' }}>
        {props.groups.map((group, index) => {
          return (
            <ItemGroupRow
              key={`group-item-${index}`}
              group={group}
              checkedIds={props.checkedIds}
              onCheck={(ids) => props.onChangeChecked([...props.checkedIds, ...ids])}
              onUncheck={(ids) => props.onChangeChecked(props.checkedIds.filter((i) => !ids.includes(i)))}
            />
          );
        })}
      </Box>
    );
  };
  const renderFolderFilterCheckList = () => {
    return (
      <Box flexDirection={'row'}>
        <Box>
          <Box sx={{ ...Flex1, padding: '10px' }}>
            <Box
              sx={{
                display: 'flex',
                marginLeft: '6px',
                alignItems: 'center',
              }}
            >
              <Typography level="tiny" sx={{ color: colors.neutral[50] }} onClick={() => {}}>
                {Translations.FOLDERS}
              </Typography>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'row'}
              sx={{ width: '100%', justifyContent: 'flex-end', marginRight: '-2px' }}
            >
              <Typography level="tiny" sx={{ ...ClickableTextStyle, marginLeft: '5px' }} onClick={onSelectAll}>
                {Translations.SELECT_ALL}
              </Typography>
              <Typography level="tiny" sx={ClickableTextStyle} onClick={() => props.onChangeChecked([])}>
                {Translations.CLEAR}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Divider sx={{ border: '1.3px dashed #fff', background: 'var(--joy-palette-info-plainBorder)' }} />
        {renderItems()}
      </Box>
    );
  };

  return <Box sx={{ ...Flex1, ...FlexCol }}>{renderFolderFilterCheckList()}</Box>;
};

const ItemGroupRow = (props: {
  group: ItemGroup;
  checkedIds: string[];
  onCheck: (ids: string[]) => void;
  onUncheck: (ids: string[]) => void;
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);

  const renderItem = (item: Item) => {
    const checked = props.checkedIds.includes(item.id);
    return (
      <Box sx={{ margin: '5px', ...Flex1 }} key={item.id}>
        <Box>{item.icon}</Box>
        <Box sx={{ ...Flex1, marginRight: '10px' }}>
          <Typography level="tiny">{item.title}</Typography>
        </Box>
        <Box sx={{ marginTop: '2px' }}>
          <Checkbox
            sx={CheckboxSx(checked, false)}
            checked={checked}
            onChange={(event) => {
              if (event.target.checked) {
                props.onCheck([item.id]);
              } else {
                props.onUncheck([item.id]);
              }
            }}
          />
        </Box>
      </Box>
    );
  };

  const checked =
    props.group.items.filter((item) => props.checkedIds.includes(item.id)).length === props.group.items.length;
  const indeterminate = props.group.items.filter((item) => props.checkedIds.includes(item.id)).length > 0 && !checked;

  const onChange = (itemChecked: boolean) => {
    if (itemChecked) {
      props.onCheck(props.group.items.map((i) => i.id));
    } else {
      props.onUncheck(props.group.items.map((i) => i.id));
    }
  };
  return (
    <Box sx={{ ...Flex1, ...FlexCol }}>
      <Box sx={{ ...Flex1, padding: '5px' }}>
        <Box sx={{ marginLeft: '5px' }}>
          <Chevron
            height={11}
            width={11}
            stroke={colors.neutral[40]}
            style={{
              marginRight: '5px',
              marginLeft: '5px',
              transform: `rotate(${collapsed ? '-90' : '0'}deg)`,
              cursor: 'pointer',
            }}
            onClick={() => setCollapsed(!collapsed)}
          />
        </Box>
        <Box sx={{ marginLeft: '2px' }}>{props.group.icon}</Box>
        <Box sx={{ ...Flex1 }}>
          <Typography level="tiny" sx={{ marginLeft: '10px' }}>
            {props.group.title}
          </Typography>
        </Box>
        <Box sx={{ marginTop: '2px' }}>
          <Checkbox
            sx={CheckboxSx(checked, indeterminate)}
            indeterminate={indeterminate}
            checked={checked}
            onChange={(event) => onChange(event.target.checked)}
          />
        </Box>
      </Box>

      <Box sx={{ marginLeft: '40px', ...Flex1, ...FlexCol }}>{!collapsed && props.group.items.map(renderItem)}</Box>
    </Box>
  );
};
