import React from 'react';

export const Flex1: React.CSSProperties = {
  display: 'flex',
  flex: '1 1',
};

export const Flex2: React.CSSProperties = {
  display: 'flex',
  flex: '2 2',
};

export const Flex3: React.CSSProperties = {
  display: 'flex',
  flex: '3 3',
};

export const Flex4: React.CSSProperties = {
  display: 'flex',
  flex: '4 4',
};

export const FlexRow: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
};

export const FlexCol: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
};
