import { Stack, Typography, Button } from '@mui/joy';
import { IconsSvg } from 'assets/icons';
import Translations from 'const/translations/en';
import { useEntityFilter } from 'services/hooks/useEntityFilter';
import { useFilterOptions } from 'services/hooks/useFilterOptions';
import { FilterIdentifier, FilterOption } from 'services/models/domain/entityFilter';
import { EntityType } from 'services/models/domain/entityTypes';
import { getFilterForFilterOption } from 'services/utils/chatTableFilterUtils';
import { colors } from 'theme/colors';
import { useSelector } from 'react-redux';
import { sideNavState } from 'services/store/slices/sideNavSlice';
import { useEntityViews } from 'services/hooks/useEntityViews';
import { useCallback } from 'react';
import { SmartListOptionItem, SmartListOptionType, smartListOptions } from './SmartListViewOptions';

export default function SmartListView() {
  const { selectedItem } = useSelector(sideNavState);
  const { filterOptions } = useFilterOptions();
  const { addEntityFilterItem } = useEntityFilter();
  const { updateDraftEntityView } = useEntityViews();

  const getLatestMessageFilterOptionInDays = useCallback(
    (inDays: number) => {
      const lastMessageFilterOption = filterOptions
        .flat()
        .find((fo) => fo.identifier === FilterIdentifier.LatestMessage);
      const fromDate = new Date();
      fromDate.setDate(fromDate.getDate() - inDays);

      if (!!lastMessageFilterOption) {
        const filterOption = {
          ...lastMessageFilterOption,
          value: {
            days: inDays > 7 ? undefined : inDays,
            from: fromDate,
            to: new Date(),
          },
        } as FilterOption;
        return filterOption;
      }
      return;
    },
    [filterOptions]
  );

  const addFilterItem = (filterOption: FilterOption) => {
    const entityFilter = getFilterForFilterOption(filterOption);
    if (entityFilter) {
      addEntityFilterItem(entityFilter, EntityType.Conversation, filterOption.identifier);
    }
  };

  const optionAction = (presetOption: SmartListOptionItem) => {
    switch (presetOption.type) {
      case SmartListOptionType.RecentlyActive:
        return () => {
          const filterOption = getLatestMessageFilterOptionInDays(1);
          if (filterOption) {
            addFilterItem(filterOption);
          }
          updateDraftEntityView(selectedItem?.id || '', presetOption.title, presetOption.icon);
        };
      case SmartListOptionType.Sorting:
        return () => {
          const filterOption = getLatestMessageFilterOptionInDays(7);
          if (filterOption) {
            addFilterItem(filterOption);
          }

          const statusOption = filterOptions
            .flat()
            .find((fo) => fo.identifier === FilterIdentifier.Property && fo.title === 'Status');
          if (!!statusOption) {
            const filterOption = {
              ...statusOption,
              value: {
                noPropertyDefinitionOid: statusOption.uniqueId,
                propertyDefinitionOid: statusOption.uniqueId,
                propertyValueOids: [],
              },
            } as FilterOption;
            addFilterItem(filterOption);
          }
          updateDraftEntityView(selectedItem?.id || '', presetOption.title, presetOption.icon);
        };
      case SmartListOptionType.GoingCold:
        return () => {
          const filterOption = getLatestMessageFilterOptionInDays(61);
          if (filterOption) {
            addFilterItem(filterOption);
          }
          updateDraftEntityView(selectedItem?.id || '', presetOption.title, presetOption.icon);
        };
    }
  };

  return (
    <Stack direction={'column'} spacing={4} sx={SmartListViewStyles.container}>
      <Stack direction={'column'} spacing={2} sx={SmartListViewStyles.headerContainer}>
        <IconsSvg.ic_list_details stroke={colors.neutral[100]} width={20} height={20} />
        <Stack direction={'column'} spacing={1} sx={SmartListViewStyles.headerTextContainer}>
          <Typography level="regular+">{Translations.SMART_LIST_VIEW_TEXT}</Typography>
          <Typography level="tiny" sx={{ width: '70%' }}>
            {Translations.SMART_LIST_VIEW_SUBTEXT}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction={'column'} spacing={1} sx={SmartListViewStyles.optionsContainer}>
        {smartListOptions.map((smartListOption, idx) => {
          return (
            <Button
              key={idx}
              variant="outlined"
              size="sm"
              sx={SmartListViewStyles.optionButton}
              onClick={optionAction(smartListOption)}
            >
              <Typography level={'tiny+'} sx={SmartListViewStyles.optionTitle}>
                {smartListOption.emoji} {smartListOption.title}
              </Typography>
              <Typography level={'tiny'}>{smartListOption.text}</Typography>
            </Button>
          );
        })}
      </Stack>
    </Stack>
  );
}

const SmartListViewStyles = {
  container: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerContainer: {
    width: '370px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerTextContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  optionsContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  optionButton: {
    width: '320px',
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    alignItems: 'start',
    p: '12px',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1), 0px 0px 20px rgba(0, 0, 0, 0.09)',
  },
  optionTitle: {
    color: colors.neutral[80],
  },
};
