import { Folder } from 'services/models/domain/folder';
import { OID, TimeStamp } from './commonTypes';
import { PropertyValue } from './entityViews';
import { Followup } from './followUps';
import { Note } from './note';

export enum ConversationOrigin {
  Manual = 'MANUAL',
  TelegramSync = 'TELEGRAM_SYNC',
}

export type Conversation = {
  chatLink?: string;
  conversationOrigin: OriginTypes;
  conversationRecordOid: OID;
  conversationType: ConversationType;
  createdAt: TimeStamp;
  description?: string;
  favorit?: boolean;
  followups: Followup[];
  internalConversationId: string;
  isArchived: boolean;
  lastMessageAt?: TimeStamp;
  lastMessageSenderParticipantOid?: OID;
  lastModifiedAt: TimeStamp;
  lastModifiedBy: OID;
  name: string;
  nextFollowup?: Followup;
  notes: Note[];
  oid: OID;
  participants: ConversationParticipant[];
  properties: PropertyValue[];
  userIsParticipant?: boolean;
  folders: Folder[];
};

export enum ConversationType {
  Group = 'GROUP',
  Individual = 'INDIVIDUAL',
  Channel = 'CHANNEL',
}

export enum ChannelType {
  Twitter = 'Twitter',
  Telegram = 'Telegram',
  Discord = 'Discord',
  Instagram = 'Instagram',
  LinkedIn = 'LinkedIn',
  WhatsApp = 'WhatsApp',
  Email = 'Email',
  Signal = 'Signal',
  XMTP = 'XMTP',
  Slack = 'Slack',
}

export enum OriginTypes {
  TelegramSync = 'TELEGRAM_SYNC',
  Manual = 'MANUAL',
}

export enum LinkDescriptionType {
  OpenTelegramTooltip = 'OPEN_TELEGRAM_TOOLTIP',
  NoTelegramChatLink = 'NO_TELEGRAM_CHAT_LINK',
  NoLinkEntered = 'NO_LINK_ENTERED',
}

export type ConversationParticipant = {
  firstName?: string;
  internalParticipantId?: string;
  lastName?: string;
  name?: string;
  oid: OID;
  userHandle?: string;
  userOid?: OID;
};

export enum ConversationColumns {
  Name = 'name',
  Favorite = 'favorit',
  LastModified = 'lastModifiedAt',
  DateAdded = 'createdAt',
  LatestMessage = 'lastMessageAt',
  Note = 'notes',
  Participants = 'participants',
}
