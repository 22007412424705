import { RecordTags } from 'services/commandbar/useCommandbarRecords';

export type ChatRecord = {
  id: string;
  name: string;
  favorit: boolean;
  description: string;
  conversationType: string;
  conversationOrigin: string;
  isArchived: boolean;
};

export type ContactRecord = {
  id: string;
  name: string;
  bio?: string;
  telegramHandle?: string;
  telegramLink?: string;
  profileImageUrl?: string;
};

export enum NoteType {
  Conversation = 'CONVERSATION_RECORD',
  Contact = 'CONTACT_RECORD',
}

export type NoteRecord = {
  name: string;
  text: string;
  entityId: string;
  entityType: NoteType;
};

export type SearchRecords = {
  [RecordTags.Contacts]: ContactRecord[];
  [RecordTags.Chats]: ChatRecord[];
  [RecordTags.Notes]: NoteRecord[];
};

export type CallbackArgsOpenChatSidebar = {
  record: ChatRecord;
};

export type CallbackArgsOpenContactSidebar = {
  record: ContactRecord;
};

export type CallbackArgsOpenNoteSidebar = {
  record: NoteRecord;
};
