import LoadingScreen from 'components/LoadingScreen';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RouterPaths } from 'services/hooks/useNavigation';
import { authState } from 'services/store/slices/authSlice';
import { LocalStorageValues } from 'services/utils/interface';

interface Props {
  children: React.ReactNode;
}

export default function GuestGuard({ children }: Props) {
  const { isAuthenticated, isInitialized, user, isNameStepCompleted } = useSelector(authState);

  const token = localStorage.getItem(LocalStorageValues.AuthToken);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (isAuthenticated && !user?.fullName && !isNameStepCompleted) {
    return <Navigate to={RouterPaths.Setup} replace={true} />;
  }

  if (isAuthenticated && user) {
    return <Navigate to={RouterPaths.Chats} />;
  }

  if (!isAuthenticated && !user && token) {
    <Navigate to={RouterPaths.Login} />;
  }

  return <>{children}</>;
}
