import { useContext, useEffect, useRef } from 'react';
import { AppContext } from 'services/store/AppContext';
import * as gql from 'services/models/api/generated';
import { usePublishEvents } from 'services/hooks/usePublishEvents';
import * as domain from 'services/models/domain/folder';
import { useDispatch, useSelector } from 'react-redux';
import * as tgSync from 'services/store/slices/userTgSlice';
import AnalyticKeys, { trackAction } from 'services/utils/analytics';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { UpsertFolderInput } from 'services/clients/TelegramClient';
import * as Sentry from '@sentry/react';
import useSnackbarContext from 'services/hooks/useSnackbar';
import { SnackBarVariantEnum } from 'components/SnackBar/SnackBar';
import { SNACKBAR_OPEN_TIME } from 'services/utils/constants';
import { TgSyncStatus } from 'page/modal/TgConnectModal/interface';
import Icons from 'assets/icons';
import { commandbarAddMetadata } from 'services/commandbar/metadata';
import { authState } from 'services/store/slices/authSlice';
import { foldersState, setFolders } from 'services/store/slices/foldersSlice';
import { useStringSession } from './useStringSession';
import { update } from 'lodash';

const CHATS_SYNCED_EVENT_STEP_SIZE = 5;
const POLL_ON_DEMAND_STATUS_INTERVAL_MS = 10000;

export const useTelegramSync = (initialSync: boolean = false) => {
  const flags = useFlags();
  const { user } = useSelector(authState);
  const { tgClient } = useContext(AppContext);
  const {
    folders,
    initialFolders,
    telegramFolders: stateTelegramFolders,
    conversationsSynced,
    isOnClientSyncActive,
  } = useSelector(tgSync.userTgState);
  const { folders: orgFolders } = useSelector(foldersState);
  const [updateUserFolders] = gql.useUpdateUserFoldersMutation();
  const dispatch = useDispatch();
  const { setSnackbarOpenStatus } = useSnackbarContext();
  // Start Api
  const { addFolderToQueue, addConversationToQueue } = usePublishEvents();

  const [toggleFolderSync, { error }] = gql.useToggleTgFolderSyncMutation();
  const { data: onDemandStatusData, loading } = gql.useGetOnDemandSyncStatusQuery({
    variables: {},
    pollInterval: POLL_ON_DEMAND_STATUS_INTERVAL_MS,
    skip: !user?.oid,
  });

  useEffect(() => {
    if (isOnClientSyncActive && flags.clientSync) {
      setSnackbarOpenStatus(
        true,
        `Syncing chats`,
        SnackBarVariantEnum.sync,
        undefined,
        `${conversationsSynced} synced`
      );
    }
  }, [conversationsSynced]);

  useEffect(() => {
    if (!loading && onDemandStatusData) {
      const isSyncing = onDemandStatusData?.getOnDemandSyncStatus.data.isSyncing;
      dispatch(tgSync.setOnDemandSync(!!isSyncing));
    }
  }, [onDemandStatusData, loading]);

  const syncFolders = async (
    input?: { telegramFolders?: UpsertFolderInput[]; domainFolders?: domain.Folder[] },
    getAll?: boolean
  ): Promise<number> => {
    const syncFolders = await syncFoldersWithTgFolders(
      input?.domainFolders ?? folders,
      input?.telegramFolders ?? stateTelegramFolders
    );
    const updateFoldersF = syncFolders.filter((folder) => {
      const initialFolder = initialFolders.find((f) => folder.oid.oid === f.oid.oid);
      if (initialFolder) {
        return initialFolder.synced !== folder.synced;
      }
      return true;
    });

    if (updateFoldersF.length === 0) {
      return 0;
    }
    updateFoldersF.map(async (folder) => {
      await toggleFolderSync({
        variables: { input: { folderId: folder.oid.oid, isSynced: folder.synced } },
      });
    });

    const folderMap = new Map<string, domain.Folder>();
    const folderKey = (f: domain.Folder) => `${f.oid.oid}-${f.externalId}`;
    orgFolders.forEach((f) => folderMap.set(folderKey(f), f));
    syncFolders.forEach((folder) => {
      if (folder.synced) {
        folderMap.set(folderKey(folder), folder);
      } else {
        const folderToDelete = Array.from(folderMap.values()).find((f) => f.oid.oid === folder.oid.oid);
        if (folderToDelete) {
          folderMap.delete(folderKey(folderToDelete));
        }
      }
    });

    dispatch(setFolders(Array.from(folderMap.values())));
    const foldersToSync = syncFolders.filter((f) => f.synced);
    if (foldersToSync.length === 0) {
      dispatch(tgSync.setLoadingClient(false));

      return 0;
    }

    const aquiredSession = await aquireTelegramSession();
    if (!aquiredSession) {
      dispatch(tgSync.setLoadingClient(false));
      return 0;
    }

    dispatch(tgSync.setOnClientSync(true));
    dispatch(tgSync.setOnDemandSync(true));
    setSnackbarOpenStatus(true, `Syncing Chats`, SnackBarVariantEnum.sync, undefined, '0 synced');
    await Promise.all(updateFoldersF);

    const lastUpdate = new Date().toISOString();

    const conversationsSource = tgClient.getConversationsGen(foldersToSync);
    let nextConversation = await conversationsSource.next();
    if (foldersToSync.length > 0) {
      dispatch(tgSync.setUserStatus(TgSyncStatus.SYNCING));
    }
    let chatsSynced = 0;
    dispatch(tgSync.setConversationsSynced(chatsSynced));
    while (!nextConversation.done) {
      try {
        const conversation = nextConversation.value;
        if (conversation.type === 'INDIVIDUAL' && (conversation.title === undefined || conversation.title === '')) {
          nextConversation = await conversationsSource.next();
          continue;
        }
        await addConversationToQueue({
          title: conversation.title,
          conversationType: conversation.type,
          externalId: conversation.secondaryId,
          folderOids: conversation.folderOID,
          lastMessageAt: conversation.lastMessageAt
            ? new Date(Number(conversation.lastMessageAt) * 1000).toISOString()
            : undefined,
          lastMessageSenderExternalId: conversation.lastMessageSender,
          lastSyncedAt: lastUpdate,
        });
        chatsSynced++;

        if (chatsSynced % CHATS_SYNCED_EVENT_STEP_SIZE === 0) {
          dispatch(tgSync.setConversationsSynced(chatsSynced));
        }
      } catch (error: any) {
        Sentry.setContext('telegramSync', {
          conversationId: nextConversation.value.secondaryId,
          conversationOid: nextConversation.value.folderOID,
          folderId: nextConversation.value.folderOID,
        });
        Sentry.captureException(new Error(`Error syncing telegram conversation: ${error.message}`));
        Sentry.setContext('telegramSync', {});
        console.error(error);
      } finally {
        nextConversation = await conversationsSource.next();
      }
    }

    dispatch(tgSync.setConversationsSynced(chatsSynced));
    dispatch(tgSync.setOnClientSync(false));
    dispatch(tgSync.setOnDemandSync(false));

    if (flags.commandbar) commandbarAddMetadata.synced();

    setSnackbarOpenStatus(true, 'Chats Synced', SnackBarVariantEnum.greenSuccess, Icons.ic_check_light);
    setTimeout(() => {
      setSnackbarOpenStatus(false, '', SnackBarVariantEnum.greenSuccess);
    }, SNACKBAR_OPEN_TIME);

    if (foldersToSync.length > 0) {
      dispatch(tgSync.setUserStatus(TgSyncStatus.CONNECTED));
    }
    return chatsSynced;
  };

  const aquireTelegramSession = async (): Promise<boolean> => {
    const isConnected = await tgClient.isUserConnected();
    if (!isConnected) {
      console.warn(`Unable to aquire Telegram session, client is not connected.`, { isConnected });
      return false;
    }
    const isAuthorized = await tgClient.isAuthorized();
    if (!isAuthorized) {
      console.warn(`Unable to aquire Telegram session, client is not authorized.`);
      return false;
    }
    await tgClient.getFolders();
    return true;
  };

  const fetchTelegramFolders = async (scheduleMs?: number) => {
    if (!tgClient.isUserConnected()) {
      return;
    }
    const foldersSource = tgClient.getFoldersGen();
    let nextFolder = await foldersSource.next();
    let telegramFolders: UpsertFolderInput[] = [];
    while (!nextFolder.done) {
      try {
        const folder = nextFolder.value;

        telegramFolders.push(folder);
        await addFolderToQueue({
          ...folder,
          isDeleted: false,
        });
      } catch (error: any) {
        Sentry.setContext('telegramSync', {
          folderId: nextFolder.value.externalId,
        });
        Sentry.captureException(new Error(`Error syncing telegram folder: ${error.message}`));
        console.error(error);
        Sentry.setContext('telegramSync', {});
      } finally {
        nextFolder = await foldersSource.next();
      }
    }

    updateUserFolders({
      variables: {
        input: telegramFolders.map((folder) => ({
          internalFolderId: folder.externalId,
          folderName: folder.folderName,
        })),
      },
    });

    dispatch(tgSync.setLoadingClient(false));

    dispatch(tgSync.setTelegramFolders(telegramFolders));
    dispatch(tgSync.setFoldersLength(folders.length));
    if (scheduleMs) {
      setTimeout(() => fetchTelegramFolders, scheduleMs);
    }
    return telegramFolders;
  };

  const syncFoldersWithTgFolders = async (
    folders: domain.Folder[],
    telegramFolders?: UpsertFolderInput[]
  ): Promise<domain.Folder[]> => {
    const lastUpdate = new Date().toISOString();
    if (telegramFolders !== undefined) {
      let removedFolders: domain.Folder[] = [];
      const filteredFolders = folders.filter((folder) => {
        const telegramFolder = telegramFolders.find((tf) => tf.externalId === folder.externalId);
        if (!telegramFolder) {
          removedFolders.push(folder);
        }
        return !!telegramFolder;
      });
      const removeFoldersF = removedFolders.map(async (folderToRemove) => {
        await addFolderToQueue({
          externalId: folderToRemove.externalId,
          isDeleted: true,
          lastSyncedAt: lastUpdate,
        });
      });
      dispatch(tgSync.setInitialFolders(filteredFolders));
      dispatch(tgSync.setFolders(filteredFolders));
      await Promise.all(removeFoldersF);
      return filteredFolders;
    } else {
      dispatch(tgSync.setInitialFolders(folders));
      dispatch(tgSync.setFolders(folders));
      return folders;
    }
  };

  const setFolderShouldSync = async (folder: domain.Folder) => {
    const { oid, folderName, synced } = folder;

    dispatch(tgSync.updateFolder(folder));
    if (synced && !error) {
      trackAction(AnalyticKeys.UNSYNCED_FOLDER, {
        organizationId: user?.actingAsMemberOfOrganization.oid,
        organizationName: user?.actingAsMemberOfOrganization.name,
        folderId: oid.oid,
        folderName: folderName,
      });
    } else if (!synced && !error) {
      trackAction(AnalyticKeys.SYNCED_FOLDER, {
        organizationId: user?.actingAsMemberOfOrganization.oid,
        organizationName: user?.actingAsMemberOfOrganization.name,
        folderId: oid.oid,
        folderName: folderName,
      });
    }

    if (error) {
      trackAction(AnalyticKeys.ERROR_SYNCING_FOLDER, {
        organizationId: user?.actingAsMemberOfOrganization.oid,
        organizationName: user?.actingAsMemberOfOrganization.name,
        folderId: oid.oid,
        folderName: folderName,
      });
    }
  };

  const hasUnsyncedChanges = () => {
    const changedFolder = folders.find((folder) => {
      const initialFolder = initialFolders.find((f) => f.oid.oid === folder.oid.oid);
      return initialFolder && folder.synced !== initialFolder.synced;
    });
    return !!changedFolder;
  };

  return {
    fetchTelegramFolders,
    setFolderShouldSync,
    syncFolders,
    hasUnsyncedChanges,
    syncFoldersWithTgFolders,
  };
};
