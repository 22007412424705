import { Box, Typography } from '@mui/joy';
import UsersMenu from 'components/Chats/ChatTableV2/TableCell/customCells/UserProperty/UsersMenu/UsersMenu';
import { UserPropertyProps } from 'components/Chats/ChatTableV2/TableCell/customCells/UserProperty/interface';
import {
  ButtonNameSx,
  ButtonSx,
  MenuSx,
  UserBoxSx,
} from 'components/Chats/ChatTableV2/TableCell/customCells/UserProperty/styles';
import CustomAvatar from 'components/CustomAvatar';
import Button from 'components/ui-library/Button/UIButton';
import CellMenu from 'components/ui-library/chat/CellMenu/CellMenu';
import { useSelector } from 'react-redux';
import { TenantUserPropertyValue } from 'services/models/domain/entityViews';
import { authState } from 'services/store/slices/authSlice';
import { generateColorFromOid } from 'services/utils/helpers';
import { getUsersInitials } from 'services/utils/userPropertyUtils';

const UserProperty = ({
  values: initialValues,
  propertyDefinition,
  renderEmpty,
  renderProperty,
  handleSet,
  handleUnset,
}: UserPropertyProps) => {
  const selectedValues = initialValues;
  const { user } = useSelector(authState);

  const definitionValues = propertyDefinition.values as TenantUserPropertyValue[];
  const selectedUsers = definitionValues.filter(({ oid }) => selectedValues.includes(oid.oid));

  const renderUser = (propertyValue: TenantUserPropertyValue) => {
    const userInitials = getUsersInitials(propertyValue?.fullName, propertyValue?.fullName);
    return (
      <Box sx={UserBoxSx} key={`${propertyValue.oid.oid}-customAvatar`}>
        <CustomAvatar
          index={generateColorFromOid(propertyValue.oid.oid ?? '')}
          size={20}
          userInitials={userInitials}
          imgUrl={propertyValue.avatar}
        />
        <Typography level="tiny" sx={ButtonNameSx}>
          {propertyValue.fullName}
        </Typography>
      </Box>
    );
  };

  return (
    <CellMenu
      sx={MenuSx}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchorComponent={
        renderEmpty && selectedUsers.length === 0 ? (
          renderEmpty()
        ) : renderProperty ? (
          selectedUsers.map(renderProperty)
        ) : (
          <Button id="property-menu-button" aria-haspopup="true" variant="plain" sx={ButtonSx}>
            {selectedUsers.map(renderUser)}
          </Button>
        )
      }
    >
      {(handleMenuClose) => (
        <UsersMenu
          handleSelect={handleSet}
          handleMenuClose={handleMenuClose}
          handleUnset={handleUnset}
          definitionValues={definitionValues}
          selectedUsers={selectedUsers}
          authUser={user}
        />
      )}
    </CellMenu>
  );
};

export default UserProperty;
