import React, { useCallback, useContext, useEffect, useState } from 'react';

import AnalyticKeys, { trackAction } from 'services/utils/analytics';
// import useAuth from 'services/hooks/useAuth';
import useSnackbarContext from 'services/hooks/useSnackbar';
import useTgSyncContext from 'services/hooks/useTgSync';
import { AppContext } from 'services/store/AppContext';
import { useStringSession } from 'services/hooks/useStringSession';
import { setLoadingClient, setUserStatus, userTgState } from 'services/store/slices/userTgSlice';
// import store, { RootState } from 'services/store/AppStore';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { SnackBarVariantEnum } from 'components/SnackBar/SnackBar';
import { useTelegramSync } from 'services/hooks/useTelegramSync';
import { useFetchFolders } from 'services/hooks/useFetchFolders';
import { TgSyncStatus } from 'page/modal/TgConnectModal/interface';
// import { useEventBusContext } from 'contexts/EventBusContext';
import { authState } from 'services/store/slices/authSlice';

export enum TgConnectSteps {
  INITIATE = 'INITIATE',
  CONNECT = 'CONNECT',
  FOLDER_SYNC = 'FOLDER_SYNC',
}

type TgLinkAccount = ReturnType<typeof useTgLinkAccount>;
export const TgLinkContext = React.createContext<TgLinkAccount | null>(null);

export function useTgLinkAccount() {
  const { tgClient: telegramClient } = useContext(AppContext);
  const { isLoadingClient } = useSelector(userTgState);

  const { user } = useSelector(authState);
  const [formStep, setFormStep] = useState<TgConnectSteps>(TgConnectSteps.INITIATE);
  const [needPasswordFor2FA, setNeedPasswordFor2FA] = useState<boolean>(false);

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [otpCode, setOtpCode] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [qrCode, setQrCode] = useState<string>('');
  const [requestedBotLink, setRequestedBotLink] = useState<boolean>(false);
  const [hint, setHint] = useState<string | undefined>(undefined);

  const { snackbarOpen, snackbarText, snackbarVariant, setSnackbarOpenStatus } = useSnackbarContext();

  const { tgSynced } = useTgSyncContext();
  const [isBotConnected, _setIsBotConnected] = useState<boolean>(user?.chatBotLinkInfo?.isLinked ?? false);
  const [isCodeInvalid, setIsCodeInvalid] = useState<boolean>(false);
  const { setStringSession } = useStringSession();
  const { fetchTelegramFolders } = useTelegramSync();
  const { refetch: refetchFolders } = useFetchFolders();
  // const { sendLockTelegramSessionMessage } = useEventBusContext();
  const flag = useFlags();

  const dispatch = useDispatch();

  useEffect(() => {
    _setIsBotConnected(user?.chatBotLinkInfo?.isLinked ?? false);
  }, [user]);

  const handleSnackBar = (message: string, variant: SnackBarVariantEnum, icon?: string) => {
    setSnackbarOpenStatus(true, message, variant, icon);
    setTimeout(() => {
      setSnackbarOpenStatus(false, message, SnackBarVariantEnum.info);
    }, 5000);
  };

  useEffect(() => {
    if (tgSynced) {
      setFormStep(TgConnectSteps.FOLDER_SYNC);
    }
  }, [tgSynced]);

  const updateUserTgStatusClientSide = async () => {
    try {
      const isAuthorized = await telegramClient.isAuthorized();
      if (isAuthorized) {
        // TODO (BLS) add lastSyncedAt and status
        await fetchTelegramFolders();
        await refetchFolders(5000);
        dispatch(setUserStatus(TgSyncStatus.CONNECTED));
      }
    } catch (e) {
      // TODO: error ignored review
    }
  };

  async function handleSuccessfulTelegramConnection() {
    setNeedPasswordFor2FA(false);
    const { stringSession } = telegramClient.getUserLoginInfo();
    dispatch(setLoadingClient(true));
    if (requestedBotLink) {
      return;
    }
    dispatch(setUserStatus(TgSyncStatus.CONNECTED));
    setStringSession(stringSession);
    // await addStringSession(stringSession);
    // sendLockTelegramSessionMessage();

    try {
      await updateUserTgStatusClientSide();
      await loginIntoBot();
      setFormStep(TgConnectSteps.FOLDER_SYNC);
      trackAction(AnalyticKeys.CONNECTED_TELEGRAM_ACCOUNT, {
        organizationId: user?.actingAsMemberOfOrganization.oid,
        organizationName: user?.actingAsMemberOfOrganization.name,
      });

      setIsCodeInvalid(true);
      setQrCode('');
    } catch (error: any) {
      handleTelegramConnectionError(error.message);
    }
  }

  function handleTelegramConnectionError(errorMessage: string) {
    trackAction(AnalyticKeys.ERROR_CONNECTING_TELEGRAM_ACCOUNT, {
      organizationId: user?.actingAsMemberOfOrganization.oid,
      organizationName: user?.actingAsMemberOfOrganization.name,
    });
    handleSnackBar(errorMessage ?? 'Something went wrong', SnackBarVariantEnum.error);

    return errorMessage;
  }

  const connectWithPasswordTg = useCallback(async () => {
    // 2FA is for a phone number + code login
    if (password) {
      const loginResult = await telegramClient.handlePasswordLogin({ password });

      if (loginResult.success) {
        handleSuccessfulTelegramConnection();
      } else {
        return handleTelegramConnectionError(loginResult.message);
      }
    }
  }, [password]);

  const isWrongOtp = () => {
    if (isCodeInvalid) {
      return 'Incorrect passcode';
    }
    return null;
  };

  /**
   * Attempts to complete the connection to Telegram by validating whether the QR code has been scanned and authorized
   */
  async function connectWithQr() {
    if (flag.clientSync && isLoadingClient) {
      return;
    }
    telegramClient
      .qrLoginHandler({
        setQrCode,
        setPasswordHint: setHint,
        setNeedPassword: setNeedPasswordFor2FA,
      })
      .then((r) => {
        if (r.success) {
          handleSuccessfulTelegramConnection();
        } else {
          console.error('failed $$$$$$$$$$', r.message);
          if (r.message !== 'LOGIN_CANCELLED') return handleTelegramConnectionError(r.message);
        }
      });
  }

  async function loginIntoBot(): Promise<boolean> {
    if (requestedBotLink) return true;

    setRequestedBotLink(true);
    const message = user?.chatBotLinkInfo?.link;
    if (message) {
      const payload = message.split('?start=')[1];
      const tgBot = message.split('?start=')[0].split('t.me/')[1].toLowerCase();
      const result = await telegramClient.sendMessage(tgBot, `/start?${payload}`, true);
      if (result) {
        trackAction(AnalyticKeys.CONNECTED_TELEGRAM_BOT, {
          organizationId: user?.actingAsMemberOfOrganization.oid,
          organizationName: user?.actingAsMemberOfOrganization.name,
        });
        setTimeout(async () => {
          setRequestedBotLink(false);
          await telegramClient.deleteMessage(Array.from({ length: 2 }, (_, i) => result.id + i));
        }, 1000);
        return true;
      }
    }
    return false;
  }

  const sendOTP = async () => {
    if (phoneNumber) {
      const result = await telegramClient.getLoginCode(phoneNumber);
      if (result) {
        setFormStep(TgConnectSteps.CONNECT);
      } else {
        return handleTelegramConnectionError("Couldn't send OTP");
      }
    }
  };

  const connectWithPhoneNumber = async () => {
    if (otpCode) {
      const result = await telegramClient.loginByPhoneNumber(phoneNumber, setHint, otpCode);
      if (result.success) {
        handleSuccessfulTelegramConnection();
      } else {
        setNeedPasswordFor2FA(true);
      }
    }
  };

  return {
    formStep,
    phoneNumber,
    setPhoneNumber,
    otpCode,
    setOtpCode,
    sendOTP,
    connectWithPhoneNumber,
    needPasswordFor2FA,
    password,
    setPassword,
    connectWithPasswordTg,
    isWrongOtp: isWrongOtp(),
    snackbarOpen,
    snackbarText,
    snackbarVariant,
    connectWithQr,
    hint,
    qrCode,
    isBotConnected,
  };
}

/**
 * Shared single Telegram link context, as all components needs to use the same instance of it to function properly
 */
export function useTgLinkContext(): TgLinkAccount {
  const context = useContext(TgLinkContext);

  if (context === null) {
    throw new Error('Invalid Telegram context, have you provided <TgLinkContext.Provider>?');
  }

  return context;
}
