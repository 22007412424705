import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { commandbarTrackerEvents } from 'services/commandbar/trackEvents';
import useOnboardingContext from 'services/hooks/useOnboarding';
import { FeatureFlags } from 'services/models/domain/featureFlags';
import { authState } from 'services/store/slices/authSlice';

function useOnboardingModalControl() {
  const { tgSynced } = useOnboardingContext();
  const { isFirstTime } = useSelector(authState);
  const flags = useFlags<FeatureFlags>();

  // modal is open if it's users first time
  if (flags.commandbar && !isFirstTime) {
    commandbarTrackerEvents.userOnboarding();
  }
  const [isOnboardingModalOpen, toggleOnboardingModal] = useState(isFirstTime);

  return {
    tgSynced,
    isOnboardingModalOpen,
    toggleOnboardingModal,
  };
}

export default useOnboardingModalControl;
