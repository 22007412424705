import { IconsSvg } from 'assets/icons';
import { ReactComponent as TagOffIcon } from 'assets/icons/ic_tag_off.svg';
import { Box, Divider, Stack, Typography } from '@mui/joy';
import { MenuItem } from '@mui/material';
import { colors } from 'theme/colors';
import { usePropertiesData } from 'services/hooks/usePropertiesData';
import { useEntityViewFields } from 'services/hooks/useEntityViewFields';
import { mapEntityFieldToIcon } from 'services/utils/entityViewFieldUtils';
import { EntityPropertyDefinition } from 'services/models/domain/entityViews';
import Translations from 'const/translations/en';
import UIButton from 'components/ui-library/Button/UIButton';
import PositionedMenu, { MenuItemType } from './PositionedMenu';
import { useState } from 'react';
import { ColumnVisibilityControlStyles } from 'components/Chats/ChatTableV2/TableHeader/ColumnVisibilityControl/styles';
import { SxProps } from '@mui/joy/styles/types';

type ColumnVisibilityControlMenuItem = {
  oid: string;
  name: string;
  icon: keyof typeof IconsSvg;
  onClick: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  showMenu?: boolean;
};

type MenuItemWithIconProps = ColumnVisibilityControlMenuItem;

function MenuItemWithIcon({ name, icon, onClick, onDelete, onEdit, showMenu }: MenuItemWithIconProps) {
  const Icon = IconsSvg[icon];
  const [isHover, setHover] = useState(false);
  const menuItems: MenuItemType[] = [
    {
      name: Translations.CHAT_TABLE_PROPERTY_EDIT,
      icon: 'ic_edit',
      onClick: () => onEdit?.(),
    },
    {
      name: Translations.CHAT_TABLE_PROPERTY_DELETE_PROPERTY,
      icon: 'ic_trash',
      onClick: () => onDelete?.(),
      iconColor: colors.warning,
      sx: {
        color: colors.warning,
        '&:hover': {
          color: colors.warning,
          backgroundColor: `${colors.warning}0D`, // 5% opacity
        },
      },
    },
  ];

  return (
    <MenuItem
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={ColumnVisibilityControlStyles.menuItem}
    >
      <Stack direction="row" sx={{ alignItems: 'center', flex: '1 1' }} spacing={1} onClick={onClick}>
        <Icon stroke={colors.neutral[50]} width={12} height={12} />
        <Typography level="micro" sx={{ color: colors.neutral[80] }}>
          {name}
        </Typography>
      </Stack>
      {isHover && showMenu && (
        <Box sx={{ width: 'fit-content', flexShrink: 1 }}>
          <PositionedMenu
            anchorComponent={<IconsSvg.ic_dots stroke={colors.neutral[50]} width={12} height={12} />}
            menuItems={menuItems}
          />
        </Box>
      )}
    </MenuItem>
  );
}

type MenuItemSubGroupProps = {
  subGroup: MenuItemWithIconProps[];
  sx?: SxProps;
};

function MenuSubGroup({ subGroup, sx }: MenuItemSubGroupProps) {
  return (
    <Box sx={{ ...ColumnVisibilityControlStyles.menuSubGroup, ...sx } as SxProps}>
      {subGroup.map((item) => (
        <MenuItemWithIcon {...item} key={item.oid} />
      ))}
    </Box>
  );
}

interface ColumnVisibilityControlSelectProps {
  handleClose: () => void;
  navigateNext: () => void;
  onEditPropertyDefinition: (propertyDefinition: EntityPropertyDefinition) => void;
  onDeletePropertyDefinition: (propertyDefinitionOid: EntityPropertyDefinition) => void;
}

export default function ColumnVisibilityControlSelect({
  handleClose,
  navigateNext,
  onEditPropertyDefinition,
  onDeletePropertyDefinition,
}: ColumnVisibilityControlSelectProps) {
  const { invisiblePropertyDefinitionsToView, addVisiblePropertyDefinitionToView } = usePropertiesData();
  const { invisibleEntityFieldsToView, addVisibleEntityFieldToView } = useEntityViewFields();
  // Remove LastModified from the list of invisible fields when feature merged
  const invisibleEntityFields: ColumnVisibilityControlMenuItem[] = invisibleEntityFieldsToView.map((field) => {
    return {
      oid: field,
      name: field,
      icon: mapEntityFieldToIcon(field),
      onClick: () => {
        addVisibleEntityFieldToView(field);
        handleClose();
      },
    };
  });
  let invisiblePropDefs: ColumnVisibilityControlMenuItem[] = [];
  let invisibleDefaultPropDefs: ColumnVisibilityControlMenuItem[] = [];

  invisiblePropertyDefinitionsToView?.forEach((pd) => {
    if (!pd.isCustom) {
      invisibleDefaultPropDefs.push({
        oid: pd.oid.oid,
        name: pd.name,
        icon: pd.icon,
        onClick: () => {
          addVisiblePropertyDefinitionToView(pd.oid.oid);
          handleClose();
        },
      });
    } else {
      invisiblePropDefs.push({
        oid: pd.oid.oid,
        name: pd.name,
        icon: pd.icon,
        showMenu: true,
        onClick: () => {
          addVisiblePropertyDefinitionToView(pd.oid.oid);
          handleClose();
        },
        onDelete: () => {
          onDeletePropertyDefinition(pd);
        },
        onEdit: () => {
          onEditPropertyDefinition(pd);
        },
      });
    }
  });
  const invisibleDefaultcolumns = [...invisibleEntityFields, ...invisibleDefaultPropDefs];

  return (
    <Stack direction="column" sx={ColumnVisibilityControlStyles.selectContainer}>
      <Box sx={ColumnVisibilityControlStyles.selectList}>
        {invisibleDefaultcolumns.length <= 0 && invisiblePropDefs.length <= 0 && (
          <Box sx={ColumnVisibilityControlStyles.emptyMenu}>
            <TagOffIcon />
            <Typography level="micro">{Translations.NO_ITEMS_IN_COLUMN_MENU}</Typography>
          </Box>
        )}
        {invisibleDefaultcolumns.length > 0 && <MenuSubGroup subGroup={invisibleDefaultcolumns} />}
        {invisiblePropDefs.length > 0 && (
          <MenuSubGroup subGroup={invisiblePropDefs} sx={ColumnVisibilityControlStyles.subGroupTopBorder} />
        )}
      </Box>
      <Box>
        <Divider sx={ColumnVisibilityControlStyles.divider}>
          <Typography level="micro" sx={ColumnVisibilityControlStyles.dividerText}>
            {Translations.OR}
          </Typography>
        </Divider>
        <Box sx={ColumnVisibilityControlStyles.container}>
          <UIButton fullWidth size="sm" onClick={navigateNext} sx={ColumnVisibilityControlStyles.mainButton}>
            <IconsSvg.ic_plus stroke="white" width="14" height="14" />
            <Typography level="tiny">{Translations.CHAT_TABLE_CREATE_PROPERTY}</Typography>
          </UIButton>
        </Box>
      </Box>
    </Stack>
  );
}
