import { Box, Theme, useMediaQuery } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import BaseLayout from 'page/Base';
import Header from 'page/dashboard/header/Header';
import { SideNav } from 'page/dashboard/header/SideNav';
import Sidebar from 'page/dashboard/sidebar/Sidebar';
import CreateChatModal from 'page/modal/CreateChatModal';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { commandbarAddMetadata } from 'services/commandbar/metadata';
import { useCommandBar } from 'services/commandbar/useCommandBar';
import { useChromeExtenstionStatus } from 'services/hooks/useChromeExtensionStatus';
import { useConversations } from 'services/hooks/useConversations';
import { FeatureFlags } from 'services/models/domain/featureFlags';
import { RootState } from 'services/store/AppStore';
import { authState } from 'services/store/slices/authSlice';

function DashboardLayout() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [isDrawerOpen, setDrawerOpen] = useState(!isMobile);
  const { openModal, conversation } = useSelector((state: RootState) => state.manualConversation);
  const { setCreateChatModalState } = useConversations();
  const { isChromeExtensionInstalled } = useChromeExtenstionStatus();
  const flags = useFlags<FeatureFlags>();
  const { isSigninCompleted } = useSelector(authState);

  useCommandBar();

  useEffect(() => {
    if (flags.commandbar) {
      if (isChromeExtensionInstalled) {
        commandbarAddMetadata.chromeExtensionInstalled();
      }
      if (isSigninCompleted) {
        commandbarAddMetadata.signedInCompleted();
      }
    }
  }, []);

  const renderContentWithSidebar = () => {
    return (
      <BaseLayout
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            sm: isDrawerOpen ? '200px minmax(400px, 1fr)' : '60px minmax(400px, 1fr)',
            md: isDrawerOpen ? '232px minmax(450px, 1fr)' : '55px minmax(450px, 1fr)',
          },
          gridTemplateRows: 'auto 1fr',
          gridTemplateAreas: `
              "header main"
              "sidebar main"
            `,
          transition: 'grid-template-columns 225ms cubic-bezier(0.4, 0, 0.2, 1)',
        }}
      >
        <Box sx={{ gridArea: 'header', flexShrink: '0' }}>
          <SideNav openDrawer={isDrawerOpen} setOpenDrawer={setDrawerOpen} />
        </Box>

        <Box sx={{ gridArea: 'main', flex: '1 1 auto' }}>
          <Outlet />
        </Box>
        <Box sx={{ gridArea: 'sidebar' }}>
          <Sidebar />
        </Box>
      </BaseLayout>
    );
  };

  const renderViewMobileContent = () => {
    return (
      <BaseLayout
        sx={{
          gridTemplateColumns: {
            xs: '1fr',
            sm: 'minmax(64px, 200px) minmax(450px, 1fr)',
            md: 'minmax(160px, 300px) minmax(600px, 1fr) minmax(300px, 420px)',
          },
        }}
      >
        <>
          <Header
            setDrawerOpen={(open: boolean) => {
              setDrawerOpen(true);
            }}
          />
          <SideNav openDrawer={isDrawerOpen} setOpenDrawer={setDrawerOpen} />
        </>
        <Outlet />
        <Sidebar />
      </BaseLayout>
    );
  };
  return (
    <>
      {isMobile && renderViewMobileContent()}
      {!isMobile && renderContentWithSidebar()}
      <CreateChatModal isOpen={openModal} setIsOpen={setCreateChatModalState} conversation={conversation} />
    </>
  );
}

export default DashboardLayout;
