import { DEFAULT_COLUMNS } from 'components/Chats/ChatTableV2/constants';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEntityViewFieldsMutation } from 'services/models/api/generated';
import { EntityType } from 'services/models/domain/entityTypes';
import { EntityViewField } from 'services/models/domain/entityViews';
import { entityViewsState, updateVisibleEntityFields } from 'services/store/slices/entityViewsSlice';
import { sideNavState } from 'services/store/slices/sideNavSlice';

const allHideableEntityFields = Object.values(DEFAULT_COLUMNS)
  .filter((column) => column.hideable)
  .map((column) => column.field as EntityViewField);

export function useEntityViewFields() {
  const dispatch = useDispatch();
  const { entityViews } = useSelector(entityViewsState);
  const { selectedItem } = useSelector(sideNavState);

  const currentView = useMemo(() => {
    return entityViews?.views.find((view) => {
      if ('oid' in view && selectedItem) {
        return view.oid.oid === selectedItem.id;
      }
      return false;
    });
  }, [entityViews, selectedItem]);

  // If currentView is undefined, use the default view's propertyDefinitions
  const visibleEntityFieldsToView = currentView ? currentView?.visibleEntityFields : entityViews.visibleEntityFields;

  const invisibleEntityFieldsToView = useMemo(() => {
    return allHideableEntityFields.filter((field) => !visibleEntityFieldsToView?.includes(field));
  }, [allHideableEntityFields, visibleEntityFieldsToView]);

  const viewOid = currentView && 'oid' in currentView ? currentView.oid.oid : undefined;

  const [updateEntityViewFields] = useUpdateEntityViewFieldsMutation();

  const updateViewVisibleEntityFields = useCallback(
    (viewOid: string | undefined, visibleEntityFieldsToUpdate: EntityViewField[]) => {
      // Update local Redux
      dispatch(
        updateVisibleEntityFields({
          viewOid: viewOid,
          visibleEntityFields: visibleEntityFieldsToUpdate,
        })
      );

      // Update graphql backend
      updateEntityViewFields({
        variables: {
          input: {
            entityType: EntityType.Conversation,
            viewOid: viewOid,
            fields: visibleEntityFieldsToUpdate,
          },
        },
      });
    },
    []
  );

  const addVisibleEntityFieldToView = useCallback(
    (entityViewField: EntityViewField) => {
      const newVisibleEntityFields = [...visibleEntityFieldsToView, entityViewField];

      updateViewVisibleEntityFields(viewOid, newVisibleEntityFields);
    },
    [visibleEntityFieldsToView, viewOid]
  );

  const removeVisibleEntityFieldFromView = useCallback(
    (entityViewField: EntityViewField) => {
      const newVisibleEntityFields = visibleEntityFieldsToView.filter((field) => field !== entityViewField);

      updateViewVisibleEntityFields(viewOid, newVisibleEntityFields);
    },
    [visibleEntityFieldsToView, viewOid]
  );

  return {
    invisibleEntityFieldsToView,
    addVisibleEntityFieldToView,
    removeVisibleEntityFieldFromView,
  };
}
