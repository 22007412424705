import { SxProps } from '@mui/material';
import { colors } from 'theme/colors';

export const OptionsSx: SxProps = {
  alignItems: 'left',
  padding: '4px',
  minHeight: 'fit-content !important',
  maxHeight: 'fit-content !important',
  background: 'transparent !important',
  '&:hover': {
    background: `${colors.neutral[0]}`,
  },
  '& .JoyListItemDecorator-root': {
    width: '100%',
  },
};

export const FlexBoxStyles: SxProps = {
  display: 'flex',
  gap: '6px',
  alignItems: 'center',
  height: '28px',
  padding: '0px 8px',
  width: '100%',
  borderRadius: '4px',
  '&:hover': {
    '& svg': { stroke: `${colors.neutral[80]}` },
    background: colors.neutral[0],
  },
};

export const TextStyles: SxProps = {
  color: 'palette.text.secondary',
  lineHeight: '16px',
  letterSpacing: '-0.005em',
};
