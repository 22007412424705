import { Select, styled, Theme } from '@mui/joy';
import { SxProps } from '@mui/material';
import { colors } from 'theme/colors';

export const StyledSelect = styled(Select)(({ theme }: { theme: Theme }) => ({
  width: 'fit-content',
  [theme.breakpoints.down('md')]: {
    '--Select-gap': 0,
  },
}));

export const RootBoxSx: SxProps = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexGrow: 1,
  position: 'relative',
  height: '64px',
  padding: '16px 48px',
};

export const DropdownBoxSx: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  position: 'absolute',
};

export const DropdownStackSx: SxProps = {
  alignItems: 'center',
  justifyContent: 'center',
};

export const DropdownTypoSx: SxProps = {
  textAlign: 'center',
};

export const PagesStackSx: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
};

export const ResultsCountSx: SxProps = {
  color: colors.neutral[50],
};

export const DotSpacerStyle = {
  width: '2px',
  height: '2px',
  borderRadius: '50%',
  margin: '4px',
  backgroundColor: colors.neutral[30],
};
