import { Box, Sheet, Modal, Typography } from '@mui/joy';
import { ModalStyle } from 'page/modal/styles';
import { colors } from 'theme/colors';
import UIButton from 'components/ui-library/Button/UIButton';
import Translations from 'const/translations/en';

interface Props {
  isOpen: boolean;
  setIsOpen: Function;
  onDiscard: () => void;
}

const EntityViewsModal = ({ isOpen, setIsOpen, onDiscard }: Props) => {
  return (
    <Modal open={isOpen} onClose={(event, reason) => setIsOpen(false)} sx={ModalStyle}>
      <Sheet
        variant="plain"
        sx={{
          width: { xs: '80%', md: '440px' },
          borderRadius: '8px',
          border: 'none',
        }}
      >
        <Box sx={styles.container}>
          <Typography
            id="modal-title"
            level="large"
            sx={{ fontWeight: '900', color: colors.neutral[90], marginY: '10px' }}
          >
            {Translations.UNSAVED_CHANGES_MESSAGE}
          </Typography>
          <Typography id="modal-title" level="regular" textColor="inherit">
            {Translations.DISCARD_OR_KEEP_MESSAGE}
          </Typography>
          <Box display={'flex'} flexDirection={'row'} sx={{ marginTop: '30px' }}>
            <UIButton
              size="xs"
              sx={styles.uiButton}
              onClick={() => {
                setIsOpen(false);
                onDiscard();
              }}
            >
              <Typography level="tiny" sx={{ color: 'red', marginLeft: '5px', display: 'inline', fontWeight: 900 }}>
                {Translations.DISCARD_CHANGES}
              </Typography>
            </UIButton>
            <UIButton size="xs" sx={styles.uiButton} onClick={() => setIsOpen(false)}>
              <Typography
                level="tiny"
                sx={{ color: colors.neutral[50], marginLeft: '5px', display: 'inline', fontWeight: 900 }}
              >
                {Translations.KEEP_EDITING}
              </Typography>
            </UIButton>
          </Box>
        </Box>
      </Sheet>
    </Modal>
  );
};

export default EntityViewsModal;

const styles = {
  uiButton: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.white}`,
    height: '32px',
    width: 'fit-content',
    marginLeft: '15px',
    '&:hover': { backgroundColor: colors.neutral[10], border: `1px solid ${colors.neutral[20]}` },
  },
  container: {
    flexDirection: 'column',
    display: 'flex',
    px: 3,
    py: 1.5,
    justifyContent: ' flex-start',
    marginY: '10px',
  },
};
