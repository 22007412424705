import * as gql from 'services/models/api/generated';
import { Note } from 'services/models/domain/note';
import { mapOtherMembersInOrganization, mapUserToDomain } from './userUtils';
import { TimeStamp } from 'services/models/domain/commonTypes';
import { User } from 'services/models/domain/user';

export const mapNoteToApi = (note: Note): gql.Note => {
  let updatedAt: gql.Date | undefined = undefined;
  if (note.updatedAt) {
    updatedAt = {
      utcTimeStampAsString: note.updatedAt.utcTimeStampAsString,
    };
  }
  return {
    createdAt: {
      utcTimeStampAsString: note.createdAt.utcTimeStampAsString,
    },
    createdByUserOid: {
      oid: note.createdByUserOid.oid,
    },
    oid: note.oid,
    text: note.text,
    user: mapUserToDomain(note.user),
    //@ts-ignore gql model allows undefined. The codegen sets everything to required so we can safely ignore the error.
    updatedAt: updatedAt,
  };
};

export const mapNoteFromApi = (note: gql.Note): Note => {
  let updatedAt: TimeStamp | undefined = undefined;
  if (note.updatedAt && note.updatedAt.utcTimeStampAsString) {
    updatedAt = {
      utcTimeStampAsString: note.updatedAt.utcTimeStampAsString,
    };
  }
  return {
    createdAt: {
      utcTimeStampAsString: note.createdAt.utcTimeStampAsString,
    },
    createdByUserOid: note.createdByUserOid,
    oid: note.oid,
    text: note.text,
    user: mapOtherMembersInOrganization(note.user),
    updatedAt: updatedAt,
  };
};

export const updateUserInNote = (note: Note, user: User): Note => {
  if (note.createdByUserOid.oid === user.oid) {
    return { ...note, user: user };
  }
  return note;
};
