import { Box, Typography } from '@mui/joy';

import CustomAvatar from 'components/CustomAvatar';
import MenuOption from 'components/ui-library/chat/CellMenu/CellMenuOption/CellMenuOption';
import { generateColorFromOid } from 'services/utils/helpers';
import { getUsersInitials } from 'services/utils/userPropertyUtils';
import { UserMenuItemProps } from 'components/Chats/ChatTableV2/TableCell/customCells/UserProperty/UsersMenu/UserMenuItem/interface';
import { MenuItemSx, NameSx, UserBoxSx } from 'components/Chats/ChatTableV2/TableCell/customCells/UserProperty/styles';

const UserMenuItem = ({ user, authUser, handleSelect, handleMenuClose }: UserMenuItemProps) => {
  const { oid, avatar, firstName, fullName, username } = user;

  const userInitials = getUsersInitials(username, fullName);

  const handleClick = () => {
    handleSelect(oid.oid);
    handleMenuClose();
  };

  return (
    <>
      {(firstName || fullName) && (
        <MenuOption key={oid.oid} onClick={handleClick} sx={MenuItemSx}>
          <Box sx={UserBoxSx}>
            <CustomAvatar index={generateColorFromOid(oid.oid)} size={20} userInitials={userInitials} imgUrl={avatar} />
            <Typography level="tiny" sx={NameSx}>
              {fullName ?? firstName}
            </Typography>
          </Box>
        </MenuOption>
      )}
    </>
  );
};

export default UserMenuItem;
