import { SxProps } from '@mui/material';
import { colors } from 'theme/colors';

export const FolderIcon: SxProps = {
  clipPath: 'polygon(0% 14%, 23% 14%, 36% 29%, 99% 30%, 99% 99%, 0% 99%)',
  backgroundColor: colors.neutral[30],
  borderRadius: '2px',
  height: '20px',
  width: '22px',
};

export const FolderIconImage: React.CSSProperties = {
  clipPath: 'polygon(0% 14%, 23% 14%, 36% 29%, 99% 30%, 99% 99%, 0% 99%)',
  borderRadius: '2px',
  height: '20px',
  width: '22px',
};
