import { extendTheme } from '@mui/joy/styles';
import colors from '@mui/joy/colors';
import { experimental_extendTheme as extendMuiTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { colors as colours } from 'theme/colors';
import { fonts } from 'theme/fonts';

declare module '@mui/joy/styles' {
  interface PaletteBackground {
    appBody: string;
    componentBg: string;
  }
}

declare module '@mui/joy/styles' {
  interface TypographySystemOverrides {
    display1: false;
    display2: false;
    // h1: false;
    // h2: false;
    h3: false;
    h4: false;
    h5: false;
    h6: false;
    body1: false;
    body2: false;
    body3: false;
    body4: false;
    body5: false;

    h1: true;
    h2: true;
    'large+': true;
    large: true;
    'regular+': true;
    regular: true;
    'tiny+': true;
    tiny: true;
    'micro+': true;
    micro: true;
    legalMicro: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    stickyColumnBreakpoint: true;
  }
}

const muiTheme = extendMuiTheme({
  cssVarPrefix: 'joy',
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: colours.neutral[90],
          light: colours.white,
          dark: '#2477E5',
        },
        secondary: {
          main: colours.neutral[100],
          light: '#F6F8F980',
          dark: colours.neutral[80],
        },
        error: {
          main: colours.warning,
          light: '#DB204280',
          // dark: '',
        },
        warning: {
          main: colors.yellow[200],
          // light: '',
          // dark: '',
        },
        info: {
          main: '#72777E',
          light: '#E5E6E7',
          dark: '#99A0A8',
        },
        success: {
          main: colours.success,
          //light: ' #17874D0D',
          //light: ' #fff',
          // dark: '',
        },
        common: {
          black: '#09090D',
          white: colours.white,
        },
        // grey: colors.grey,
        divider: '#F6F8F980',
        text: {
          primary: '#1A1A22',
          secondary: colors.grey[600],
        },
      },
    },
    dark: {
      palette: {
        primary: {
          main: colours.neutral[90],
          light: colours.white,
          dark: '#2477E5',
        },
        secondary: {
          main: colours.neutral[100],
          light: '#F6F8F980',
          dark: colours.neutral[80],
        },
        error: {
          main: colours.warning,
          light: '#DB204280',
          // dark: '',
        },
        warning: {
          main: colors.yellow[200],
          // light: '',
          // dark: '',
        },
        info: {
          main: '#72777E',
          light: '#E5E6E7',
          dark: '#99A0A8',
        },
        success: {
          main: colours.success,
          //light: ' #17874D0D',
          //light: ' #fff',
          // dark: '',
        },
        common: {
          black: '#09090D',
          white: colours.white,
        },
        // grey: colors.grey,
        divider: '#F6F8F980',
        text: {
          primary: '#1A1A22',
          secondary: colors.grey[600],
        },
      },
    },
  },
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '--joy-palette-info-outlinedBorder': colours.neutral['5'],
          borderTop: '1px solid var(--joy-palette-info-outlinedBorder)',
          fontWeight: '600',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          '--joy-palette-info-outlinedBorder': colours.neutral['5'],
          borderRight: '1px solid var(--joy-palette-info-outlinedBorder)',
          borderBottom: '1px solid var(--joy-palette-info-outlinedBorder)',
          paddingLeft: '16px',
          paddingRight: '16px',
        },
      },
    },
  },
});

const joyTheme = extendTheme({
  cssVarPrefix: 'joy',
  colorSchemes: {
    light: {
      palette: {
        background: {
          appBody: 'var(--joy-palette-neutral-50)',
          componentBg: 'var(--joy-palette-common-white)',
        },
        primary: {
          solidColor: '#373A3F',
          solidActiveBg: '#F8FBFF',
          solidActiveBorder: '#E7F1FF',
          solidBg: '#F3F5F7',
          outlinedBorder: '#E7EBEF',
          plainBorder: '#F9FAFB',
          outlinedColor: '#1769D4',
          plainActiveBg: 'black',
          plainHoverBg: colours.neutral[80],
          plainBg: colours.neutral[5],
        },
        text: {
          primary: colours.neutral[80],
          secondary: colours.neutral[60],
          tertiary: colours.neutral[50],
        },
        info: {
          solidBg: '#D9D9D9',
          solidActiveBg: '#6d6969',
          outlinedBorder: '#F4F6F8',
          plainBg: '#52575E',
          plainBorder: '#ECF0F3',
          solidDisabledColor: '#c9cfd4',
          solidColor: '#858585',
          softBg: colours.white,
          softBorder: colours.border,
          plainActiveBg: colours.neutral[90],
          plainHoverBg: colours.neutral[80],
          solidHoverBg: 'rgba(0, 0, 0, 0.05)',
          softHoverBg: colours.neutral[15],
        },
      },
    },
    dark: {
      palette: {
        background: {
          appBody: 'var(--joy-palette-common-black)',
          componentBg: colours.neutral[80],
        },
        primary: {
          solidColor: colours.white,
          outlinedBorder: colours.neutral[50],
          plainBorder: colours.neutral[50],
          solidActiveBg: 'rgba(255, 255, 255, 0.05)',
          outlinedColor: '#69ADF1',
          plainActiveBg: 'white',
          plainHoverBg: colours.neutral[15],
          plainBg: colours.neutral[70],
        },
        text: {
          primary: colours.white,
          secondary: colours.neutral[20],
          tertiary: colours.neutral[30],
        },
        info: {
          outlinedBorder: 'rgba(255, 255, 255, 0.05)',
          plainBorder: 'rgba(255, 255, 255, 0.05)',
          solidBg: colours.neutral[40],
          solidDisabledColor: colours.neutral[40],
          solidColor: colours.white,
          softBg: colours.neutral[70],
          softBorder: 'rgba(255, 255, 255, 0.05)',
          plainActiveBg: colours.neutral[60],
          plainHoverBg: colours.neutral[70],
          solidHoverBg: 'rgba(255, 255, 255, 0.05)',
          softHoverBg: colours.neutral[60],
        },
      },
    },
  },
  fontFamily: {
    display: fonts.bold,
    body: fonts.regular,
  },
  components: {
    JoyCircularProgress: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.color === 'neutral' && {
            '--CircularProgress-track-color': colours.neutral[15],
            '--CircularProgress-progress-color': colours.neutral[30],
          }),
          ...(ownerState.color === 'info' && {
            '--CircularProgress-track-color': colours.primary[0],
            '--CircularProgress-progress-color': colours.primary[30],
          }),
          border: 'none',
        }),
      },
    },
    JoyTooltip: {
      defaultProps: {
        size: 'sm',
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'solid' && {
            borderRadius: '4px',
            backgroundColor: colours.neutral[80],
            color: colours.white,
          }),
          ...(ownerState.variant === 'plain' && {
            borderRadius: '0px',
            backgroundColor: 'transparent',
            color: colours.white,
            boxShadow: 'none',
          }),
        }),
        arrow: ({ ownerState, _theme }) => ({
          ...(ownerState.variant === 'solid' && {
            '&:before': {
              borderTopColor: colours.neutral[80],
              borderRightColor: colours.neutral[80],
              borderBottomColor: colours.neutral[80],
              borderLeftColor: colours.neutral[80],
            },
          }),
        }),
      },
    },
    JoyRadio: {
      defaultProps: {
        size: 'sm',
      },
      styleOverrides: {
        root: ({}) => ({}),
        radio: ({ ownerState }) => ({
          ...(ownerState.checked === false &&
            ownerState.disabled === false && {
              borderColor: '#DDE3E7',
              background: 'transparent',
              '&:hover': {
                borderColor: '#C0C9D0',
                background: 'transparent',
              },
            }),
          ...(ownerState.checked === true &&
            ownerState.disabled === false && {
              borderColor: '#DBECFD',
              background: 'transparent',
              '&:hover': {
                borderColor: '#BDDEFB',
                background: 'transparent',
              },
            }),
          ...(ownerState.checked === false &&
            ownerState.disabled === true && {
              borderColor: '#DDE3E7 !important',
              background: '#E7EBEF',
            }),
          ...(ownerState.checked === true &&
            ownerState.disabled === true && {
              borderColor: '#DBECFD !important',
              background: '#DBECFD',
            }),
        }),
        icon: ({ ownerState }) => ({
          ...(ownerState.checked === true &&
            ownerState.disabled === false && {
              color: '#3C7ADE',
              '&:hover': {
                color: '#3C7ADE',
              },
            }),
          ...(ownerState.checked === true &&
            ownerState.disabled === true && {
              color: '#8DC3F7 !important',
            }),
        }),
      },
    },
    JoyCheckbox: {
      defaultProps: {
        size: 'sm',
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          '& svg': {
            ...(ownerState.checked === true &&
              ownerState.disabled === false && {
                color: '#3C7ADE',
                '&:hover': {
                  color: '#3C7ADE',
                },
              }),
            ...(ownerState.checked === true &&
              ownerState.disabled === true && {
                color: '#8DC3F7 !important',
              }),
          },
        }),
        checkbox: ({ ownerState }) => ({
          ...(ownerState.checked === false &&
            ownerState.disabled === false && {
              borderColor: '#DDE3E7',
              background: 'transparent',
              '&:hover': {
                borderColor: '#C0C9D0',
                background: 'transparent',
              },
            }),
          ...(ownerState.checked === true &&
            ownerState.disabled === false && {
              border: '1px solid #DBECFD !important',
              background: 'transparent',
              '&:hover': {
                border: ' 1px solid #BDDEFB !important',
                background: 'transparent',
              },
            }),
          ...(ownerState.checked === false &&
            ownerState.disabled === true && {
              borderColor: '#DDE3E7 !important',
              background: '#E7EBEF !important',
            }),
          ...(ownerState.checked === true &&
            ownerState.disabled === true && {
              borderColor: '#DBECFD !important',
              background: '#DBECFD !important',
            }),
        }),
      },
    },
    JoyIconButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'outlined' && {
            border: '1px solid #E7F1FF',
            backgroundColor: '#F8FBFF',
            color: '#2477E5',
            '&:hover': {
              border: '1px solid #E7F1FF',
              backgroundColor: '#F8FBFF',
              color: '#2477E5',
              opacity: '0.8',
            },
          }),
          ...(ownerState.variant === 'plain' &&
            ownerState.color === 'primary' && {
              color: '#797F87',
              backgroundColor: 'transparent',
              border: '1px solid',
              borderColor: 'transparent',
              '&:hover': {
                color: '#545960',
                backgroundColor: 'transparent',
                border: '1px solid',
                borderColor: 'transparent',
              },
            }),
          ...(ownerState.variant === 'plain' &&
            ownerState.color === 'warning' && {
              color: '#DB2042',
              backgroundColor: 'transparent',
              border: '1px solid',
              borderColor: 'transparent',
              '&:hover': {
                color: '#DB2042',
                backgroundColor: 'transparent',
                border: '1px solid',
                borderColor: 'transparent',
              },
            }),
        }),
      },
    },
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'solid' &&
            ownerState.color === 'primary' && {
              fontFamily: theme.fontFamily.display,
              backgroundColor: colours.neutral[90],
              color: colours.white,
              border: '1px solid',
              borderColor: colours.neutral[90],
              '&:hover': {
                backgroundColor: colours.neutral[100],
                color: colours.white,
                border: '1px solid',
                borderColor: colours.neutral[100],
              },
            }),
          ...(ownerState.variant === 'solid' &&
            ownerState.color === 'warning' && {
              fontFamily: theme.fontFamily.display,
              backgroundColor: colours.warning,
              color: colours.white,
              border: '1px solid',
              borderColor: colours.warning,
              '&:hover': {
                color: colours.white,
                backgroundColor: colours.warning,
                opacity: '0.8',
                border: '1px solid',
                borderColor: colours.warning,
              },
            }),
          ...(ownerState.variant === 'soft' &&
            ownerState.color === 'primary' && {
              border: '1px solid',
              fontFamily: theme.fontFamily.display,
              borderColor: '#E7EBEF',
              backgroundColor: colours.neutral[5],
              color: '#545960',
              '&:hover': {
                backgroundColor: '#F1F4F6',
                borderColor: '#DDE3E7 !important',
                border: '1px solid',
                color: colours.neutral[70],
              },
            }),
          ...(ownerState.variant === 'soft' &&
            ownerState.color === 'info' && {
              fontFamily: theme.fontFamily.display,
              backgroundColor: colours.primary[0],
              color: colours.primary[50],
              border: '1px solid',
              borderColor: '#DBECFD',
              '&:hover': {
                backgroundColor: '#DBECFD',
                color: colours.primary[60],
                border: '1px solid',
                borderColor: '#BDDEFB',
              },
            }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'primary' && {
              border: '1px solid',
              borderColor: '#E7EBEF',
              fontFamily: theme.fontFamily.display,
              color: '#797F87',
              '&:hover': {
                color: '#545960',
                border: '1px solid',
                borderColor: '#DDE3E7',
                backgroundColor: 'transparent',
              },
            }),
          ...(ownerState.variant === 'plain' &&
            ownerState.color === 'primary' && {
              fontFamily: theme.fontFamily.display,
              color: '#797F87',
              backgroundColor: 'transparent',
              border: '1px solid',
              borderColor: 'transparent',
              '&:hover': {
                color: '#545960',
                backgroundColor: 'transparent',
                border: '1px solid',
                borderColor: 'transparent',
              },
            }),
          ...(ownerState.variant === 'plain' &&
            ownerState.color === 'warning' && {
              fontFamily: theme.fontFamily.display,
              color: '#DB2042',
              backgroundColor: 'transparent',
              border: '1px solid',
              borderColor: 'transparent',
              '&:hover': {
                color: '#DB2042',
                backgroundColor: 'transparent',
                border: '1px solid',
                borderColor: 'transparent',
              },
            }),
          ...(ownerState.disabled === true &&
            (ownerState.color === 'warning' || ownerState.color === 'primary' || ownerState.color === 'info') && {
              color: '#9FA6AE',
              borderColor: '#E7EBEF',
              backgroundColor: '#E7EBEF',
              '&:hover': {
                color: '#9FA6AE',
                borderColor: '#E7EBEF',
                backgroundColor: '#E7EBEF',
              },
            }),
          ...(ownerState.size === 'lg' && {
            padding: '9px 20px',
            fontSize: 16,
            fontWeight: 400,
          }),
          ...(ownerState.size === 'md' && {
            padding: '9px 14px',
            fontSize: 14,
            fontWeight: 400,
          }),
          ...(ownerState.size === 'sm' && {
            padding: '9px 11px',
            fontSize: 14,
            fontWeight: 400,
          }),
          ...(ownerState.disabled === true &&
            ownerState.loading === true && {
              '& span': {
                display: 'none',
              },
            }),
          ...(ownerState.variant === 'solid' &&
            ownerState.color === 'primary' &&
            ownerState.disabled === true &&
            ownerState.loading === true && {
              fontFamily: theme.fontFamily.display,
              backgroundColor: `${colours.neutral[90]} !important`,
              color: colours.white,
              border: '1px solid',
              borderColor: colours.neutral[90],
              '&:hover': {
                backgroundColor: colours.neutral[100],
                color: colours.white,
                border: '1px solid',
                borderColor: colours.neutral[100],
              },
            }),
          ...(ownerState.variant === 'solid' &&
            ownerState.color === 'warning' &&
            ownerState.disabled === true &&
            ownerState.loading === true && {
              fontFamily: theme.fontFamily.display,
              backgroundColor: `${colours.warning} !important`,
              color: colours.white,
              border: '1px solid',
              borderColor: colours.warning,
              '&:hover': {
                color: colours.white,
                backgroundColor: colours.warning,
                opacity: '0.8',
                border: '1px solid',
                borderColor: colours.warning,
              },
            }),
          ...(ownerState.variant === 'soft' &&
            ownerState.color === 'primary' &&
            ownerState.disabled === true &&
            ownerState.loading === true && {
              border: '1px solid',
              fontFamily: theme.fontFamily.display,
              borderColor: '#E7EBEF',
              backgroundColor: `${colours.neutral[5]} !important`,
              color: '#545960',
              '&:hover': {
                backgroundColor: '#F1F4F6',
                borderColor: '#DDE3E7 !important',
                border: '1px solid',
                color: colours.neutral[70],
              },
            }),
          ...(ownerState.variant === 'soft' &&
            ownerState.color === 'info' &&
            ownerState.disabled === true &&
            ownerState.loading === true && {
              fontFamily: theme.fontFamily.display,
              backgroundColor: `${colours.primary[0]} !important`,
              color: colours.primary[50],
              border: '1px solid',
              borderColor: '#DBECFD',
              '&:hover': {
                backgroundColor: '#DBECFD',
                color: colours.primary[60],
                border: '1px solid',
                borderColor: '#BDDEFB',
              },
            }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'primary' &&
            ownerState.disabled === true &&
            ownerState.loading === true && {
              border: '1px solid',
              borderColor: '#E7EBEF !important',
              fontFamily: theme.fontFamily.display,
              color: '#797F87',
              backgroundColor: 'transparent !important',
              '&:hover': {
                color: '#545960',
                border: '1px solid',
                borderColor: '#DDE3E7',
                backgroundColor: 'transparent',
              },
            }),
          ...(ownerState.variant === 'plain' &&
            ownerState.color === 'primary' &&
            ownerState.disabled === true &&
            ownerState.loading === true && {
              fontFamily: theme.fontFamily.display,
              color: '#797F87',
              backgroundColor: 'transparent',
              border: '1px solid',
              borderColor: 'transparent',
              '&:hover': {
                color: '#545960',
                backgroundColor: 'transparent',
                border: '1px solid',
                borderColor: 'transparent',
              },
            }),
          ...(ownerState.variant === 'plain' &&
            ownerState.color === 'warning' &&
            ownerState.disabled === true &&
            ownerState.loading === true && {
              fontFamily: theme.fontFamily.display,
              color: '#DB2042',
              backgroundColor: 'transparent',
              border: '1px solid',
              borderColor: 'transparent',
              '&:hover': {
                color: '#DB2042',
                backgroundColor: 'transparent',
                border: '1px solid',
                borderColor: 'transparent',
              },
            }),
        }),
      },
    },
    JoyInput: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          border: '1px solid #DDE3E7',
          background: '#F3F5F7',
          borderRadius: '6px',
          color: '#545960',
          ...(ownerState.error === true && {
            border: '1px solid #DB2042',
          }),
          ...(ownerState.size === 'lg' && {
            minHeight: 'auto',
            height: 40,
            padding: '8px 12px',
            fontSize: 16,
            fontWeight: 400,
          }),
          ...(ownerState.size === 'md' && {
            minHeight: 'auto',
            height: 32,
            padding: '6px 10px',
            fontSize: 14,
            fontWeight: 400,
          }),
          ...(ownerState.size === 'sm' && {
            minHeight: 'auto',
            height: 28,
            padding: '4px 10px',
            fontSize: 14,
            fontWeight: 400,
          }),
        }),
      },
    },
    JoyListDivider: {
      styleOverrides: {
        root: ({}) => ({
          backgroundColor: 'var(--joy-palette-primary-plainBorder)',
        }),
      },
    },
    JoySheet: {
      styleOverrides: {
        root: ({}) => ({
          borderColor: 'var(--joy-palette-primary-plainBorder)',
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.03)',
          borderRadius: '10px',
        }),
      },
    },
    JoySwitch: {
      defaultProps: {
        size: 'md',
      },
      styleOverrides: {
        root: ({}) => ({
          borderColor: 'var(--joy-palette-primary-plainBorder)',
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.03)',
        }),
        track: ({ ownerState }) => ({
          ...(ownerState.variant === 'solid' &&
            ownerState.size === 'md' && {
              opacity: 1,
              background: '#C9CFD4',
              borderRadius: '100px',
              '--Switch-track-height': '20px',
              '--Switch-track-width': '33px',
              width: '33px',
              height: '20px',
              '&.Joy-checked': {
                background: '#549BF7',
                border: '1px solid #549BF7',
              },
            }),
          ...(ownerState.variant === 'solid' &&
            ownerState.size === 'lg' && {
              opacity: 1,
              background: '#C9CFD4',
              borderRadius: '100px',
              '--Switch-track-height': '24px',
              '--Switch-track-width': '40px',
              width: '40px',
              height: '24px',
              '&.Joy-checked': {
                background: '#549BF7',
                border: '1px solid #549BF7',
              },
            }),
          ...(ownerState.variant === 'solid' &&
            ownerState.size === 'sm' && {
              opacity: 1,
              background: '#C9CFD4',
              borderRadius: '100px',
              '--Switch-track-height': '16px',
              '--Switch-track-width': '26px',
              width: '26px',
              height: '16px',
              '&.Joy-checked': {
                background: '#549BF7',
                border: '1px solid #549BF7',
              },
            }),
          ...(ownerState.variant === 'outlined' && {
            opacity: 1,
            background: '#C9CFD4',
            borderRadius: '6px',
            width: '33px',
            height: '20px',
            '&.Joy-checked': {
              background: '#E7F1FF',
              border: '1px solid #549BF7',
            },
          }),
          ...(ownerState.disabled && {
            opacity: 1,
            background: colours.neutral[15],
            borderRadius: '100px',
            '--Switch-track-height': '20px',
            '--Switch-track-width': '33px',
            width: '33px',
            height: '20px',
            '&.Joy-checked': {
              background: colours.primary[15],
              border: `1px solid ${colours.primary[15]}`,
            },
          }),
        }),
        thumb: ({ ownerState }) => ({
          ...(ownerState.variant === 'solid' &&
            ownerState.size === 'md' && {
              background: colours.white,
              border: '1px solid #C9CFD4',
              borderRadius: '100px',
              boxShadow: 'none',
              width: '14px',
              height: '14px',
              '--Switch-thumb-width': '14px',
              '&.Joy-checked': {
                border: '1px solid #549BF7',
              },
            }),
          ...(ownerState.variant === 'solid' &&
            ownerState.size === 'lg' && {
              background: colours.white,
              border: '1px solid #C9CFD4',
              borderRadius: '100px',
              boxShadow: 'none',
              width: '18px',
              height: '18px',
              '--Switch-thumb-width': '18px',
              '&.Joy-checked': {
                border: '1px solid #549BF7',
              },
            }),
          ...(ownerState.variant === 'solid' &&
            ownerState.size === 'sm' && {
              background: colours.white,
              border: '1px solid #C9CFD4',
              borderRadius: '100px',
              boxShadow: 'none',
              width: '11px',
              height: '11px',
              '--Switch-thumb-width': '11px',
              '&.Joy-checked': {
                border: '1px solid #549BF7',
              },
            }),
          ...(ownerState.variant === 'outlined' && {
            background: 'var(--joy-palette-primary-light)',
            border: '1px solid #C9CFD4',
            borderRadius: '4px',
            boxShadow: 'none',
            width: '14px',
            height: '14px',
            top: '9.5px',
            left: '10px',
            '&.Joy-checked': {
              left: '22px',
              background: ' #549BF7',
              border: '1px solid #E7F1FF',
            },
          }),
          ...(ownerState.disabled && {
            opacity: 1,
            background: colours.white,
            width: '11px',
            height: '11px',
            border: 'none',
            '&.Joy-checked': {
              background: colours.white,
              border: 'none',
            },
          }),
        }),
      },
    },
    JoySelect: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: '100px',
          ...(ownerState.size === 'sm' && {
            minHeight: '30px',
            fontSize: '12px',
          }),
        }),
      },
    },
    JoyTabs: {
      defaultProps: {
        variant: 'solid',
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          width: 'fit-content',
          background: 'transparent',
        }),
      },
    },
    JoyTabList: {
      defaultProps: {
        variant: 'solid',
      },
      styleOverrides: {
        root: ({ ownerState, _theme }) => ({
          border: '1px solid #DDE3E7',
          background: '#F3F5F7',
          padding: 2,
          width: 'fit-content',
          '--List-gap': 4,
          ...(ownerState.size === 'lg' && {
            '--List-item-minHeight': '30px',
            '--List-item-fontSize': '16px',
            '--List-item-paddingY': '8px',
            '--List-item-paddingLeft': '8px',
            '--List-item-paddingRight': '8px',
            '--Icon-fontSize': '20px',
          }),
          ...(ownerState.size === 'md' && {
            '--List-item-minHeight': '28px',
            '--List-item-fontSize': '14px',
            '--List-item-paddingY': '6px',
            '--List-item-paddingLeft': '6px',
            '--List-item-paddingRight': '6px',
            '--Icon-fontSize': '18px',
          }),
          ...(ownerState.size === 'sm' && {
            '--List-item-minHeight': '24px',
            '--List-item-fontSize': '14px',
            '--List-item-paddingY': '4px',
            '--List-item-paddingLeft': '4px',
            '--List-item-paddingRight': '4px',
            '--Icon-fontSize': '18px',
          }),
          ...(ownerState.variant === 'soft' && {
            borderRadius: '100px',
            border: '1px solid #DDE3E7',
            background: '#F3F5F7',
            padding: 2,
            width: 'fit-content',
            '--List-gap': 4,
            '--List-item-radius': '100px',
          }),
          ...(ownerState.variant === 'solid' && {
            borderRadius: '8px',
            border: '1px solid #DDE3E7',
            background: '#F3F5F7',
            padding: 2,
            width: 'fit-content',
            '--List-gap': 4,
            '--List-item-radius': '6px',
          }),
        }),
      },
    },
    JoyTab: {
      styleOverrides: {
        root: ({ ownerState, _theme }) => ({
          boxShadow: 'none',
          ...(ownerState.selected === true && {
            background: '#FFFFFF',
            border: '1px solid #E7EBEF',
            '&:hover': {
              background: '#FFFFFF',
            },
          }),
          ...(ownerState.selected === false && {
            color: '#797F87',
            border: '1px solid transparent',
            '&:hover': {
              background: 'transparent',
            },
          }),
        }),
      },
    },
    JoyTypography: {
      defaultProps: {
        fontWeight: 500,
        level: 'regular',
        levelMapping: {
          h1: 'h1',
          h2: 'h2',
          'large+': 'h3',
          large: 'h3',
          'regular+': 'p',
          regular: 'p',
          'tiny+': 'p',
          tiny: 'p',
          'micro+': 'p',
          micro: 'p',
          legalMicro: 'p',
        },
      },
      styleOverrides: {
        root: ({ theme }) => ({
          fontFamily: theme.fontFamily.body,
        }),
      },
    },
    JoyChip: {
      defaultProps: { variant: 'outlined' },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          fontFamily: theme.fontFamily.body,
          cursor: 'pointer',
          '& button': {
            backgroundColor: 'inherit !important',
            borderColor: 'transparent !important',
            color: 'inherit !important',
          },
          ...(ownerState.variant === 'soft' &&
            ownerState.color === 'info' && {
              backgroundColor: colours.primary[0],
              color: colours.primary[50],
              border: '1px solid',
              borderColor: '#DBECFD',
              '&:hover': {
                backgroundColor: colours.primary[0],
                color: colours.primary[60],
                border: '1px solid',
                borderColor: '#BDDEFB',
              },
            }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'primary' && {
              border: '1px solid !important',
              borderColor: '#E7EBEF !important',
              color: '#797F87 !important',
              backgroundColor: 'transparent !important',
              '&:hover': {
                color: '#545960 !important',
                border: '1px solid !important',
                borderColor: '#DDE3E7 !important',
                backgroundColor: 'transparent !important',
              },
            }),
          ...(ownerState.size === 'lg' && {
            minHeight: 'auto',
            height: 40,
            padding: '8px 12px',
            fontSize: 16,
            fontWeight: 400,
          }),
          ...(ownerState.size === 'md' && {
            minHeight: 'auto',
            height: 32,
            padding: '6px 10px',
            fontSize: 14,
            fontWeight: 400,
          }),
          ...(ownerState.size === 'sm' && {
            minHeight: 'auto',
            height: 28,
            padding: '4px 10px',
            fontSize: 14,
            fontWeight: 400,
          }),
        }),
      },
    },
    JoyTextarea: {
      styleOverrides: {
        root: () => ({
          '--Textarea-focusedThickness': 0,
          '--joy-radius': 6,
          color: colours.neutral['80'],
          backgroundColor: colours.neutral['5'],
          fontSize: 14,
          lineHeight: '18px',

          '&.Joy-focused': {
            '--joy-palette-neutral-outlinedBorder': colours.primary['10'],
          },
        }),
      },
    },
  },
  typography: {
    // @ts-ignore
    display1: undefined,
    display2: undefined,
    // h1: undefined,
    // h2: undefined,
    h3: undefined,
    h4: undefined,
    h5: undefined,
    h6: undefined,
    body1: undefined,
    body2: undefined,
    body3: undefined,
    body4: undefined,
    body5: undefined,

    h1: {
      fontSize: '36px',
      lineHeight: '40px',
      letterSpacing: '-0.03em',
      fontFamily: `${fonts.bold} !important`,
      fontWeight: '400 !important',
      color: 'var(--joy-palette-secondary-dark) !important',
    },

    h2: {
      fontSize: '24px',
      lineHeight: '28px',
      letterSpacing: '-0.03em',
      fontFamily: `${fonts.bold} !important`,
    },

    'large+': {
      fontSize: '18px',
      lineHeight: '24px',
      letterSpacing: '-0.03em',
      fontFamily: `${fonts.bold} !important`,
    },
    large: { fontSize: '18px', lineHeight: '24px', letterSpacing: '-0.03em' },

    'regular+': {
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: '-0.01em',
      fontFamily: `${fonts.bold} !important`,
    },
    regular: { fontSize: '16px', lineHeight: '20px', letterSpacing: '-0.01em' },

    'tiny+': {
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: '-0.01em',
      fontFamily: `${fonts.bold} !important`,
    },
    tiny: { fontSize: '14px', lineHeight: '18px', letterSpacing: '-0.01em' },

    'micro+': {
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '-0.005em',
      fontFamily: `${fonts.bold} !important`,
    },
    micro: { fontSize: '12px', lineHeight: '16px', letterSpacing: '-0.005em' },

    legalMicro: {
      fontSize: '10px',
      lineHeight: '12px',
      letterSpacing: '-0.01em',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      stickyColumnBreakpoint: 800,
    },
  },
});

const theme = deepmerge(muiTheme, joyTheme);

export default theme;
