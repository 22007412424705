import jwtDecode from 'jwt-decode';
import { JwtPayload } from 'jwt-decode';
//import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import dayjs, { Dayjs } from 'dayjs';
import { format } from 'date-fns';
import { LocalStorageValues } from 'services/utils/interface';
import { OtherUserInOrganization } from 'services/models/domain/user';
import * as util from 'util';
import { FOLLOWUPS_MONTH_RANGE } from 'services/constants';

const isValidToken = (authToken: string) => {
  if (!authToken) {
    return false;
  }
  const decoded: JwtPayload = jwtDecode(authToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp ?? 0 > currentTime;
};

const handleTokenExpired = (exp: number) => {
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert('Token expired');

    localStorage.removeItem(LocalStorageValues.AuthToken);

    window.location.href = '/login';
  }, timeLeft);
};

///can choose to handle removing token from local storage here
///or in the component that calls this function when the token is
///invalid or has expired
const setAuthToken = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem(LocalStorageValues.AuthToken, accessToken);
  } else {
    localStorage.removeItem(LocalStorageValues.AuthToken);
  }
};

const avatarFromName = (name: string) => {
  return name
    ?.match(/\b(\w)/g)
    ?.slice(0, 2)
    ?.join('')
    .toUpperCase()
    .toString();
};

export const getUsersInitials = (username?: string, firstName?: string) => {
  return avatarFromName(username ?? firstName ?? '') || '';
};

type TimeAgoProps = {
  toDate: string | number | Date;
  fromDate?: Date;
};

const TimeAgo = ({ toDate }: TimeAgoProps) => {
  dayjs.extend(utc);
  dayjs.extend(relativeTime);
  dayjs.extend(updateLocale);

  let date = dayjs.utc(toDate).local();
  if (dayjs(date).get('year') !== dayjs(Date.now()).year()) {
    return dayjs(date).format('hh:mm A, MMM DD YYYY');
  }

  if (dayjs(Date.now()).diff(date, 'hour') >= 2) {
    return dayjs(date).format('hh:mm A, MMM DD');
  }
  if (dayjs(Date.now()).diff(date, 'second') < 60) {
    return 'Just now';
  }
  return dayjs(date).fromNow();
};

const NotesTime = ({ toDate }: TimeAgoProps) => {
  dayjs.extend(utc);
  dayjs.extend(relativeTime);
  dayjs.extend(updateLocale);
  let daysOfTheWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  let date = dayjs.utc(toDate).local();

  // date is from today and less than a minute ago
  if (dayjs(Date.now()).diff(date, 'second') < 59) {
    return 'Just now';
  }

  // date is less than an hour ago
  if (dayjs(Date.now()).diff(date, 'minute') < 59) {
    if (dayjs(Date.now()).diff(date, 'minute') > 1) {
      return `${dayjs(Date.now()).diff(date, 'minute')} minutes ago`;
    }

    return `${dayjs(Date.now()).diff(date, 'minute')} minute ago`;
  }

  // date is from today but over an hour ago
  if (dayjs(Date.now()).isSame(date, 'day')) {
    return dayjs(date).format('hh:mm A');
  }

  // date is from this year and from this week and not from today
  if (dayjs(Date.now()).diff(date, 'day') <= 5 && dayjs(Date.now()).diff(date, 'hour') > 24) {
    return daysOfTheWeek[dayjs(date).get('day')];
  }

  // date is from this year
  if (dayjs(Date.now()).get('year') === dayjs(Date.now()).year()) {
    return dayjs(date).format('MMM DD');
  }

  // date is not from this year
  if (dayjs(date).get('year') !== dayjs(Date.now()).year()) {
    return dayjs(date).format('MMM DD YYYY');
  }

  return dayjs(date).fromNow();
};

const HoverNotesTime = ({ toDate }: TimeAgoProps) => {
  dayjs.extend(utc);
  dayjs.extend(relativeTime);
  dayjs.extend(updateLocale);

  let date = dayjs.utc(toDate).local();
  if (dayjs(date).get('year') !== dayjs(Date.now()).year()) {
    return dayjs(date).format('MMM DD YYYY, hh:mm A');
  }

  return dayjs(date).format('MMM DD, hh:mm A');
};

const getTimeZone = () => {
  const { timeZone: zone } = Intl.DateTimeFormat().resolvedOptions();
  return `${zone}`;
};

const truncateParticipants = (items: string[]) => {
  if (items.length == 3) {
    return `${items
      .slice(0, 2)
      .map((participant: string) => participant)
      .join(', ')} and ${items[2]}`;
  } else if (items.length > 3) {
    return `${items
      .slice(0, 2)
      .map((participant: string) => participant)
      .join(', ')} + ${items.length - 2} others`;
  } else if (items.length < 3) {
    return items.map((participant: string) => participant).join(', ');
  }
  return '';
};

const english_ordinal_rules = new Intl.PluralRules('en', { type: 'ordinal' });
const suffixes = {
  one: 'st',
  two: 'nd',
  few: 'rd',
  other: 'th',
  many: 'th',
  zero: '_',
};

function ordinal(number: number) {
  const category = english_ordinal_rules.select(number);
  const suffix = suffixes[category];
  return suffix;
}

function generateColorFromOid(oid: string): number {
  if (oid) {
    const temp = oid.replace(/[^a-zA-Z0-9]/g, '');
    var num = parseInt(temp, 16);
    if (parseInt(num.toString(), 10) <= 10) {
      return parseInt(num.toString(), 10);
    }
  }
  return 1;
}

function getNearDateName(date: Date | Dayjs): string {
  dayjs.extend(utc);
  dayjs.extend(relativeTime);
  dayjs.extend(updateLocale);
  const today = dayjs().local().startOf('day').valueOf();
  const yesterday = dayjs(new Date().setDate(new Date().getDate() - 1))
    .local()
    .startOf('day')
    .valueOf();
  const tomorrow = dayjs(new Date().setDate(new Date().getDate() + 1))
    .local()
    .startOf('day')
    .valueOf();

  let currentDay;
  if (dayjs.isDayjs(date)) {
    currentDay = date?.local().startOf('day').valueOf();
  } else {
    currentDay = dayjs(date).local().startOf('day').valueOf();
  }

  let initialDate;
  if (dayjs.isDayjs(date)) {
    initialDate = date?.toDate();
  } else {
    initialDate = date;
  }

  if (currentDay === yesterday) return 'Yesterday';
  else if (currentDay === today) return 'Today';
  else if (currentDay === tomorrow) return 'Tomorrow';
  else return format(initialDate, 'EEE, MMM dd');
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getCountryCode(): string {
  return 'countryCode';
}

function isDateBefore(date: Date | Dayjs): boolean {
  dayjs.extend(utc);
  dayjs.extend(relativeTime);
  dayjs.extend(updateLocale);
  const today = dayjs().local();

  let currentDay;
  if (dayjs.isDayjs(date)) {
    currentDay = date?.local();
  } else {
    currentDay = dayjs(date).local();
  }

  return currentDay.isBefore(today, 'day');
}

function getDateString(date: Date | Dayjs): string {
  return date instanceof Date ? date.toISOString().split('T')[0] : date.format().split('T')[0];
}

function getFollowupDateRange(currentDate: Date, startingDate: dayjs.Dayjs, endingDate: dayjs.Dayjs) {
  const initialRangeEndDate = dayjs().add(FOLLOWUPS_MONTH_RANGE, 'month');

  let fromDate = startingDate;
  let toDate = endingDate;
  const day = dayjs(currentDate);
  if (day < fromDate || day > toDate) {
    const halfRangePeriod = FOLLOWUPS_MONTH_RANGE / 2;
    fromDate = dayjs(currentDate.getTime()).subtract(halfRangePeriod, 'month');
    toDate = dayjs(currentDate.getTime()).add(halfRangePeriod, 'month');
  } else if (toDate !== initialRangeEndDate && day < initialRangeEndDate) {
    fromDate = dayjs();
    toDate = initialRangeEndDate;
  }
  return [fromDate, toDate];
}

function toKebabCase(sentence: string): string {
  if (sentence.length) {
    const matches = sentence.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g);
    return matches && matches.length ? matches.join('-').toLowerCase() : '';
  }
  return sentence;
}

function addWebPrefix(imageUrl: string) {
  return imageUrl ? `${process.env.REACT_APP_IMAGE_BASE_URL}/${imageUrl}` : '';
}

function openOutsideLink(link: string) {
  window.open(link, '_blank');
}

function mapToLabel(arr: OtherUserInOrganization) {
  return {
    oid: arr.oid,
    name: arr.fullName,
    image: arr.profileImageUrl,
  };
}

const sortUsers = (users: OtherUserInOrganization[], mainUserOid: string) => {
  return users.reduce<OtherUserInOrganization[]>((acc, orgUser) => {
    if ((orgUser.fullName || orgUser.firstName) && orgUser.oid === mainUserOid) {
      acc.unshift(orgUser);
    } else {
      acc.push(orgUser);
    }
    return acc;
  }, []);
};

const formatTranslation = (format: string, ...params: any[]) => {
  return util.format(format, ...params);
};

const createMailLink = (link: string) => {
  return `mailto:${link}`;
};

export {
  isValidToken,
  setAuthToken,
  handleTokenExpired,
  avatarFromName,
  TimeAgo,
  NotesTime,
  HoverNotesTime,
  getTimeZone,
  truncateParticipants,
  ordinal,
  generateColorFromOid,
  getNearDateName,
  isDateBefore,
  getDateString,
  getFollowupDateRange,
  toKebabCase,
  addWebPrefix,
  openOutsideLink,
  mapToLabel,
  sortUsers,
  formatTranslation,
  createMailLink,
};
