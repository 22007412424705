import dayjs from 'dayjs';
import { useGetUserFollowUpCountsWithinRangeLazyQuery } from 'services/models/api/generated';
import { getDateString } from 'services/utils/helpers';

export default function useUserFollowupCounts() {
  const [getUserFollowupCounts, { data, loading }] = useGetUserFollowUpCountsWithinRangeLazyQuery();

  const getFollowupCounts = async (userOid: string, fromDate: dayjs.Dayjs, toDate: dayjs.Dayjs) => {
    await getUserFollowupCounts({
      variables: {
        input: {
          oid: userOid,
          from: {
            utcTimeStampAsString: getDateString(fromDate),
          },
          to: {
            utcTimeStampAsString: getDateString(toDate),
          },
        },
      },
    });
  };

  return {
    getFollowupCounts,
    data,
    loading,
  };
}
