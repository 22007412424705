import { DateRange } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';

const formatDate = (date: DateTime | null) => {
  return date ? date.toFormat('LLL dd') : undefined;
};

export const getDateRangeString = (value: DateRange<DateTime>) => {
  const from = value[0]?.startOf('day');
  const to = value[1]?.endOf('day');

  if (from && to && !from.hasSame(to, 'day')) {
    return undefined;
  }

  const fromFormatted = from ? from.toFormat('LLL dd') : undefined;
  const toFormatted = to ? to.toFormat('LLL dd') : undefined;

  if (fromFormatted && toFormatted) {
    return fromFormatted;
  } else if (fromFormatted || toFormatted) {
    return fromFormatted || toFormatted;
  }

  return undefined;
};

export const getFormattedDateRange = (fromDate?: Date | null, toDate?: Date | null, days?: number) => {
  if (days) {
    if (days === 1) {
      return 'Past 24h';
    }
    if (days === 7) {
      return 'Past week';
    }
    if (days === 90) {
      return 'Past 3m';
    }
    return `Past ${days}d`;
  }
  const from = fromDate ? DateTime.fromJSDate(fromDate) : undefined;
  const to = toDate ? DateTime.fromJSDate(toDate) : undefined;
  const now = DateTime.local();
  if (from && !to) {
    return `Since ${formatDate(from)}`;
  } else if (to && !from) {
    return `Before ${formatDate(to)}`;
  } else if (from && to) {
    if (from.hasSame(to, 'day')) {
      if (from.hasSame(now, 'day')) {
        return 'Today';
      } else {
        return formatDate(from);
      }
    } else {
      const isPastWeek = from.plus({ days: 7 }).hasSame(to, 'day') && to <= now;
      if (isPastWeek) {
        return 'Past week';
      } else {
        return `${formatDate(from)} - ${formatDate(to)}`;
      }
    }
  } else {
    return '';
  }
};

export const getDateRangeIdentifiers = (fromDate?: Date | null, toDate?: Date | null) => {
  const from = fromDate ? DateTime.fromJSDate(fromDate) : undefined;
  const to = toDate ? DateTime.fromJSDate(toDate) : undefined;
  const now = DateTime.local();
  if (from && !to) {
    return 'Upcoming';
  } else if (to && !from) {
    return 'Overdue';
  } else if (from && to) {
    if (from.hasSame(to, 'day') && from.hasSame(now, 'day')) {
      return 'Today';
    }
  }
};

export const convertToUTCTimestampStrings = (dateRange: DateRange<DateTime>): string[] => {
  return dateRange.map((date) => (date ? date.toISO() : null)) as string[];
};
