import { useMemo, useState } from 'react';
import { CsvBuilder } from 'filefy';
import { useSelector } from 'react-redux';
import { conversationsState } from 'services/store/slices/conversationsSlice';
import { Conversation, ConversationColumns } from 'services/models/domain/conversation';
import { entityViewsState } from 'services/store/slices/entityViewsSlice';
import { EntityPropertyDefinition, EntityView, EntityViewField } from 'services/models/domain/entityViews';
import { getDescendantTextValue, stringToDescendant } from '@3rm-co/ui-library';
import { Note } from 'services/models/domain/note';
import { TimeStamp } from 'services/models/domain/commonTypes';
import { ConversationParticipant } from 'services/models/domain/conversation';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from 'services/models/domain/featureFlags';

export const useExportConversations = () => {
  const flags = useFlags<FeatureFlags>();
  const [isExporting, setIsExporting] = useState(false);

  const { conversations } = useSelector(conversationsState);
  const { entityViews } = useSelector(entityViewsState);

  const mapEntityViewFieldsToConversationColumn = (entityField: EntityViewField): keyof Conversation | undefined => {
    switch (entityField) {
      case EntityViewField.Name:
        return ConversationColumns.Name;
      case EntityViewField.Favorite:
        return ConversationColumns.Favorite;
      case EntityViewField.LastModified:
        return ConversationColumns.LastModified;
      case EntityViewField.DateAdded:
        return ConversationColumns.DateAdded;
      case EntityViewField.LatestMessage:
        return ConversationColumns.LatestMessage;
      case EntityViewField.Note:
        return ConversationColumns.Note;
      default:
        return undefined;
    }
  };

  const handleNoteColumn = (conversation: Conversation): string => {
    if (conversation.notes.length > 0) {
      const note = conversation.notes[0];
      return getDescendantTextValue(stringToDescendant(note.text));
    }
    return '';
  };

  const handleTimestampColumn = (field: TimeStamp): string => {
    return field.utcTimeStampAsString;
  };

  const handleParticipantsColumn = (participants: ConversationParticipant[]): string => {
    return participants
      .map((participant) => `${participant.firstName} ${participant.lastName} (@${participant.userHandle})`)
      .join(', ');
  };

  const handlePropertyColumn = (column: string, conversation: Conversation): string => {
    if (conversation.properties.length === 0) {
      return '';
    }
    const propertyDef = entityViews.propertyDefinitions.find((def) => def.name === column);
    const property = conversation.properties.find((prop) => prop.propertyDefinitionOid.oid === propertyDef?.oid.oid);

    if (property) {
      if (property.type === 'OPTION') {
        return property.value;
      } else if (property.type === 'TENANT_USER') {
        return property.fullName || '';
      } else {
        return getDescendantTextValue(stringToDescendant(property.value));
      }
    }
    return '';
  };

  const mapConversationColumnToValue = (column: string, conversation: Conversation): string => {
    if (column in conversation) {
      switch (column) {
        case ConversationColumns.Note:
          return handleNoteColumn(conversation);
        case ConversationColumns.LastModified:
        case ConversationColumns.DateAdded:
        case ConversationColumns.LatestMessage:
          return handleTimestampColumn(conversation[column as keyof Conversation] as TimeStamp);
        case ConversationColumns.Participants:
          return handleParticipantsColumn(conversation.participants);
        default:
          return conversation[column as keyof Conversation]?.toString() ?? '';
      }
    } else {
      return handlePropertyColumn(column, conversation);
    }
  };

  const getBaseConversationColumns = (entityView?: EntityView) => {
    const entityViewFields = entityView ? entityView.visibleEntityFields : entityViews.visibleEntityFields;
    const baseColumns = entityViewFields.reduce((acc, field) => {
      const column = mapEntityViewFieldsToConversationColumn(field);
      if (column) {
        acc.push(column);
      }
      return acc;
    }, [] as string[]);

    const visiblePropertyDefinitionOids =
      entityView?.propertyDefinitions.map((property) => property.oid.oid) ??
      entityViews.allVisiblePropertyDefinitionOids;
    const propertDefinitions = entityViews.propertyDefinitions.reduce<EntityPropertyDefinition[]>((acc, pd) => {
      if (visiblePropertyDefinitionOids.includes(pd.oid.oid)) {
        acc.push(pd);
      }
      return acc;
    }, []);

    const propertyDefColumns = propertDefinitions.map((pd) => pd.name);
    return ['name', ...baseColumns, 'participants', ...propertyDefColumns];
  };

  const getConversationRows = (conversations: Conversation[], baseColumns: string[]): string[][] => {
    const result: string[][] = conversations.map((conversation) => {
      return baseColumns.map((column) => {
        const value = mapConversationColumnToValue(column, conversation);
        return wrapText(value);
      });
    });
    return result;
  };

  const wrapText = (text: string, maxLineLength: number = 50): string => {
    if (!text) return text;
    const regex = new RegExp(`(.{1,${maxLineLength}})(\\s|$)`, 'g');
    return text.match(regex)?.join('\n') || text;
  };

  const exportConversations = (entityView?: EntityView) => {
    setIsExporting(true);

    const baseColumns = getBaseConversationColumns(entityView);

    const columns = [...baseColumns];
    new CsvBuilder(`${new Date().toISOString()}.csv`)
      .setColumns(columns)
      .addRows(getConversationRows(conversations, columns))
      .exportFile();
  };

  const canExportConversations = useMemo(() => {
    return flags.csvExports && conversations.length > 0;
  }, [conversations]);

  return {
    exportConversations,
    isExporting,
    canExportConversations,
  };
};
