import * as gql from 'services/models/api/generated';
import { PropertyDefinitionDataType, TenantUserPropertyValue } from 'services/models/domain/entityViews';
import { OrganizationalUser, OtherUserInOrganization, User } from 'services/models/domain/user';

export const mapGqlUserToDomainUser = (apiUser: gql.User): User => {
  return {
    actingAsMemberOfOrganization: {
      ...apiUser.actingAsMemberOfOrganization,
      name: apiUser.actingAsMemberOfOrganization.name,
      listOfUsersInOrganization: apiUser.actingAsMemberOfOrganization.listOfUsersInOrganization
        ? apiUser.actingAsMemberOfOrganization.listOfUsersInOrganization.map(mapOtherMembersInOrganization)
        : [],
    },
    chatBotLinkInfo: {
      isLinked: apiUser.chatBotLinkInfo?.isLinked,
      link: apiUser.chatBotLinkInfo?.link,
      linkedAt: apiUser.chatBotLinkInfo?.linkedAt
        ? {
            utcTimeStampAsString: apiUser.chatBotLinkInfo.linkedAt.utcTimeStampAsString,
          }
        : undefined,
      linkingToken: apiUser.chatBotLinkInfo?.linkingToken,
      oid: { oid: apiUser?.chatBotLinkInfo?.oid.oid },
    },
    email: apiUser.email || '',
    firstName: apiUser.firstName || '',
    fullName: apiUser.fullName || '',
    lastName: apiUser.lastName || '',
    name: apiUser.name || '',
    oid: apiUser.oid,
    profileImageUrl: apiUser.profileImageUrl,
    timezone: apiUser.timezone,
  };
};

export const mapOtherMembersInOrganization = (apiUser: gql.User): OtherUserInOrganization => {
  return {
    email: apiUser.email,
    firstName: apiUser.firstName,
    fullName: apiUser.fullName || '',
    lastName: apiUser.lastName,
    name: apiUser.name,
    oid: apiUser.oid,
    profileImageUrl: apiUser.profileImageUrl,
    chatBotLinkInfo: apiUser.chatBotLinkInfo
      ? {
          isLinked: apiUser.chatBotLinkInfo.isLinked,
        }
      : undefined,
  };
};

export const mapUserToDomain = (user: OrganizationalUser): gql.User => {
  const apiUser: gql.User = {
    __typename: 'User',
    email: user.email || '',
    firstName: user.firstName || '',
    fullName: user.fullName || '',
    lastName: user.lastName || '',
    name: user.name || '',
    oid: user.oid,
    profileImageUrl: user.profileImageUrl || '',
    timezone: '',
    chatBotLinkInfo: {} as gql.ChatbotLinkInfo,
    actingAsMemberOfOrganization: {} as gql.TenantOrganization,
    clipboardItems: [],
  };
  return apiUser;
};

export const mapUserToTenantUser = (
  user: OrganizationalUser,
  belongsToPropertyDefinitionOid: string
): TenantUserPropertyValue => ({
  oid: {
    oid: user.oid,
  },
  firstName: user.firstName,
  lastName: user.lastName,
  fullName: user.fullName,
  avatar: user.profileImageUrl,
  propertyDefinitionOid: {
    oid: belongsToPropertyDefinitionOid,
  },
  type: PropertyDefinitionDataType.TenantUser,
});
