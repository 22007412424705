import { DragDropContext, Draggable, DropResult, Droppable } from '@hello-pangea/dnd';
import { Box, CircularProgress, Typography } from '@mui/joy';
import { IconsSvg } from 'assets/icons';
import {
  EmptyWidth,
  KanbanBoardContainerStyle,
  KanbanBoardHeaderStyle,
  KanbanBoardStyle,
  KanbanCardContainerStyle,
  KanbanColumnContainerStyle,
  KanbanColumnContentStyle,
  KanbanColumnDividerStyle,
  KanbanColumnHeaderStyle,
  KanbanColumnInnerContainerStyle,
  KanbanColumnStyle,
  KanbanHeaderMenuStyle,
} from 'components/Kanban/styles';
import CellMenu from 'components/ui-library/chat/CellMenu/CellMenu';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { colors } from 'theme/colors';

export type KanbanEntity<T> = T & { id: string };

export type KanbanColumn<T> = {
  entities: KanbanEntity<T>[];
  title: string;
  id: string | undefined;
  color: string;
  icon: string;
  ordering: number;
  totalNumberOfEntities?: number;
};

export interface KanbanBoardProps<T> {
  columns: KanbanColumn<T>[];
  onEntityChangeColumn: (fromId: string, toId: string, entityId: string) => void;
  renderCard: (entity: KanbanEntity<T>, index: number) => JSX.Element;
  onEndReached: (column: KanbanColumn<T>) => void;
  renderEditColumnHeader: () => JSX.Element;
  handleHeaderMenuClose: () => void;
}

export function KanbanBoard<T>(props: KanbanBoardProps<T>) {
  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    props.onEntityChangeColumn(result.source.droppableId, result.destination.droppableId, result.draggableId);
  };
  const sortedColumns = _.sortBy(props.columns, 'ordering');
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box sx={KanbanBoardStyle} id="kanban-board">
        <Box sx={KanbanBoardHeaderStyle}></Box>

        <Box sx={KanbanBoardContainerStyle} id="kanabn-column-container" role="grid">
          <Box sx={EmptyWidth}></Box>

          {sortedColumns.map((column) => {
            return (
              <KanbanColumn
                column={column}
                renderCard={props.renderCard}
                onEndReached={() => {
                  props.onEndReached(column);
                }}
                key={column.id || 'undefined'}
                renderEditColumnHeader={props.renderEditColumnHeader}
                handleHeaderMenuClose={props.handleHeaderMenuClose}
              />
            );
          })}
        </Box>
      </Box>
    </DragDropContext>
  );
}

export interface KanbanColumnProps<T> {
  column: KanbanColumn<T>;
  renderCard: (entity: KanbanEntity<T>, index: number) => JSX.Element;
  onEndReached: () => void;
  renderEditColumnHeader: () => JSX.Element;
  handleHeaderMenuClose: () => void;
}

function KanbanColumn<T>(props: KanbanColumnProps<T>) {
  const [isLoading, setIsLoading] = useState(true);
  const [hovered, setHovered] = useState(false);
  const [isHeaderMenuOpen, setIsHeaderMenuOpen] = useState(false);
  const columnHeaderColor = props.column.color === colors.white ? colors.neutral[50] : props.column.color;

  useEffect(() => {
    if (props.column.totalNumberOfEntities !== undefined) {
      setIsLoading(false);
    }
    if (props.column.totalNumberOfEntities === undefined) {
      setIsLoading(true);
    }
  }, [props.column.entities.length, props.column.totalNumberOfEntities, props.column.entities]);

  const renderIcon = (icon: string, color: string) => {
    const Icon = icon && IconsSvg[icon as keyof typeof IconsSvg];
    if (Icon) {
      return <Icon stroke={color} width={11} height={11} />;
    }
    return null;
  };

  const handleScroll = (e: any) => {
    const { scrollHeight } = e.target;
    const { scrollTop } = e.target;
    const { clientHeight } = e.target;
    const value = Math.abs(scrollHeight - (scrollTop + clientHeight));
    const bottom = value <= 1;
    if (bottom) {
      if (props.column.entities.length < (props.column.totalNumberOfEntities || 0) && !isLoading) {
        setIsLoading(true);
        props.onEndReached();
      }
    }
  };

  return (
    <Droppable droppableId={props.column.id || 'undefined'} key={props.column.id || 'undefined'}>
      {(provided, snapshot) => {
        const isDraggingOverOrigin = snapshot.draggingOverWith === snapshot.draggingFromThisWith;
        return (
          <Box
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={KanbanColumnStyle(snapshot.isDraggingOver)}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            role="gridcell"
          >
            <div>
              <CellMenu
                anchorComponent={
                  <Box sx={KanbanColumnHeaderStyle(isHeaderMenuOpen)}>
                    {renderIcon(props.column.icon, columnHeaderColor)}
                    <Typography level="micro" sx={{ color: `${columnHeaderColor}`, ml: 1, flex: 1 }}>
                      {props.column.title}
                    </Typography>
                    <Typography level="micro" sx={{ color: `${columnHeaderColor}`, ml: 2 }}>
                      {props.column.totalNumberOfEntities || 0}
                    </Typography>
                  </Box>
                }
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                onClose={props.handleHeaderMenuClose}
                setOpen={setIsHeaderMenuOpen}
                sx={KanbanHeaderMenuStyle}
              >
                {props.renderEditColumnHeader()}
              </CellMenu>
            </div>

            <Box sx={KanbanColumnContainerStyle(snapshot.isDraggingOver, hovered)}>
              <Box
                sx={KanbanColumnInnerContainerStyle(snapshot.isDraggingOver, hovered, isDraggingOverOrigin)}
                onScroll={handleScroll}
              >
                <Box sx={KanbanColumnContentStyle(snapshot.isDraggingOver, hovered)}>
                  <div style={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
                    {props.column.entities.map((entity, index) => {
                      return (
                        <Draggable draggableId={entity.id} index={index} key={entity.id}>
                          {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <div style={KanbanCardContainerStyle(snapshot.isDragging)}>
                                {props.renderCard(entity, index)}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {isLoading && (
                      <div style={{ padding: '15px', flex: 1 }}>
                        <CircularProgress
                          color="neutral"
                          size="sm"
                          sx={{ marginLeft: 'auto', marginRight: 'auto', display: 'flex' }}
                        />
                      </div>
                    )}
                    {provided.placeholder}
                  </div>
                </Box>
              </Box>
              <div style={KanbanColumnDividerStyle} />
            </Box>
          </Box>
        );
      }}
    </Droppable>
  );
}

export interface KanbanCardProps<T> {
  entity: KanbanEntity<T>;
  index: number;
}
