import { ChatRecord, ContactRecord, NoteRecord, SearchRecords } from 'services/commandbar/recordTypes';
import {
  PartyType,
  useChatsPageCoversationsLazyQuery,
  useContactsLazyQuery,
  useSearchLazyQuery,
} from 'services/models/api/generated';
import { POLL_INTERVAL } from 'services/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from 'services/models/domain/featureFlags';
import { mappingNoteData, mappingOid } from 'services/commandbar/utils';
import { CallbackTags } from 'services/commandbar/useCommandbarCallbacks';

const COMMANDBAR_QUERY_LIMIT = 15;

export enum RecordTags {
  Chats = 'chats',
  Contacts = 'contacts',
  Notes = 'notes',
}

export const useCommandbarRecords = () => {
  const flags = useFlags<FeatureFlags>();

  const fetchPolicy = {
    initialFetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    pollInterval: POLL_INTERVAL,
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  } as const;

  const [getChatsPageConversations] = useChatsPageCoversationsLazyQuery(fetchPolicy);
  const [getContacts] = useContactsLazyQuery(fetchPolicy);
  const [search] = useSearchLazyQuery(fetchPolicy);

  const onSearchChats = async (search: string | undefined) => {
    let newData;
    if (search) {
      newData = await getChatsPageConversations({
        variables: {
          input: {
            search,
            pageRequest: {
              limit: COMMANDBAR_QUERY_LIMIT,
            },
          },
          criteria: { partyScope: PartyType.Organization },
        },
      });
    }
    return (
      (newData?.data?.conversations.data.conversations.map((c) => ({
        name: c.name,
        id: c.oid.oid,
      })) as ChatRecord[]) || []
    );
  };

  const onSearchContacts = async (search: string | undefined) => {
    let newData;
    if (search) {
      newData = await getContacts({
        variables: { input: { pageRequest: { limit: COMMANDBAR_QUERY_LIMIT, pageNumber: 1 }, search } },
      });
    }
    return (
      (newData?.data?.contacts.data.contacts.map((c) => ({
        name: c.name,
        bio: c.bio,
        telegramHandle: c.telegramHandle,
        id: c.participantOid.oid,
      })) as ContactRecord[]) || []
    );
  };

  const onInputChange = async (query: string): Promise<SearchRecords> => {
    const onSearch = async (searchText: string) => {
      return await search({
        variables: { input: { text: searchText } },
      });
    };

    const searchData = (await onSearch(query)).data?.search;

    return {
      [RecordTags.Contacts]: searchData?.contacts.map(mappingOid) as ContactRecord[],
      [RecordTags.Chats]: searchData?.conversations.map(mappingOid) as ChatRecord[],
      [RecordTags.Notes]: searchData?.notes.map(mappingNoteData) as NoteRecord[],
    };
  };

  return () => {
    if (flags.newSearch) {
      window.CommandBar.addRecords(RecordTags.Contacts, [], {
        labelKey: 'name',
        descriptionKey: 'bio',
        searchableFields: ['name', 'bio', 'telegramHandle'],
        defaultIcon: 'link',
      });

      window.CommandBar.addRecords(RecordTags.Chats, [], {
        labelKey: 'name',
        searchableFields: ['name'],
        defaultIcon: 'message',
      });

      window.CommandBar.addRecords(RecordTags.Notes, [], {
        labelKey: 'text',
        descriptionKey: 'name',
        searchableFields: ['text'],
        defaultIcon: 'document',
      });

      // Add a search endpoint
      window.CommandBar.addMultiSearch(onInputChange, [RecordTags.Contacts, RecordTags.Chats, RecordTags.Notes]);

      window.CommandBar.addRecordAction(RecordTags.Notes, {
        text: 'Open Notes Sidebar',
        name: 'open_notes_sidebar',
        template: {
          type: 'callback',
          value: CallbackTags.OpenNoteSidebar,
        },
      });
    } else {
      window.CommandBar.addRecords(RecordTags.Chats, [], {
        labelKey: 'name',
        searchableFields: ['name'],
        defaultIcon: '💬',
        onInputChange: onSearchChats,
      });

      window.CommandBar.addRecords(RecordTags.Contacts, [], {
        labelKey: 'name',
        descriptionKey: 'bio',
        defaultIcon: 'link',
        searchableFields: ['name', 'bio', 'telegramHandle'],
        onInputChange: onSearchContacts,
      });
    }

    window.CommandBar.addRecordAction(RecordTags.Chats, {
      text: 'Open Chats Sidebar',
      name: 'open_chats_sidebar',
      template: {
        type: 'callback',
        value: CallbackTags.OpenChatSidebar,
      },
    });

    window.CommandBar.addRecordAction(RecordTags.Contacts, {
      text: 'Open Contacts Sidebar',
      name: 'open_contacts_sidebar',
      template: {
        type: 'callback',
        value: CallbackTags.OpenContactSidebar,
      },
    });
  };
};
