import { Box, Button, Stack, Typography } from '@mui/joy';
import { IconsSvg } from 'assets/icons';
import DetailsHeader from 'components/Details/DetailsHeader';
import Translations from 'const/translations/en';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ActiveDatePlaceHolder from 'page/details/placeholder/ActiveDatePlaceholder';
import ConversationName from 'page/details/placeholder/ConversationNamePlaceholder';
import { useEffect, useState } from 'react';
import { useChatActions } from 'services/hooks/useChatActions';
import { useConversations } from 'services/hooks/useConversations';
import { Conversation } from 'services/models/domain/conversation';
import { FeatureFlags } from 'services/models/domain/featureFlags';
import { TimeAgo } from 'services/utils/helpers';
import { colors } from 'theme/colors';

interface Props {
  conversation: Conversation | undefined;
  loading: boolean;
}

export default function ChatSidebarHeader({ conversation, loading }: Props) {
  const { handleRemoveFav, handleAddFav } = useChatActions(conversation);
  const flags = useFlags<FeatureFlags>();
  const favorite = conversation?.favorit;
  const StarIcon = favorite ? IconsSvg.ic_star_filled : IconsSvg.ic_star;
  const { setConversationArchiveState } = useConversations();
  const [archived, setArchived] = useState(false);
  const { setCreateChatModalState } = useConversations();
  const lastMessageSender = conversation?.participants?.find(
    (p) => p.oid.oid === conversation?.lastMessageSenderParticipantOid?.oid
  );
  const senderName = lastMessageSender?.name ?? lastMessageSender?.firstName ?? '';

  useEffect(() => {
    conversation && setArchived(conversation.isArchived);
  }, [conversation]);

  return (
    <>
      <DetailsHeader>
        {loading || conversation ? (
          <Box
            sx={{
              backgroundColor: 'primary.light',
            }}
          >
            <Stack sx={{ justifyContent: 'space-between', flexDirection: 'column' }}>
              <Stack direction={'row'} sx={{ alignItems: 'center', gap: '4px', paddingBottom: '10px' }}>
                <Typography level="tiny" sx={{ color: 'var(--joy-palette-info-main)' }}>
                  {'Lastest Message: '}
                </Typography>
                {loading ? (
                  <ActiveDatePlaceHolder />
                ) : (
                  <Typography level="tiny" sx={{ color: 'secondary.main' }}>
                    {conversation?.lastMessageAt?.utcTimeStampAsString
                      ? `${senderName} ${TimeAgo({ toDate: conversation?.lastMessageAt?.utcTimeStampAsString })}`
                      : '–'}
                  </Typography>
                )}
              </Stack>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  mt: { xs: 2, md: 0 },
                  flexWrap: 'wrap',
                  gap: '12px',
                }}
              >
                {loading ? <ConversationName /> : <Typography level={'h2'}>{conversation?.name}</Typography>}
                <Box sx={{ display: 'flex' }} gap={1}>
                  <Button
                    variant="outlined"
                    size="sm"
                    sx={styles.headerButton(favorite || false)}
                    startDecorator={
                      <StarIcon width={13} height={13} stroke={colors.neutral[40]} style={{ marginTop: '1px' }} />
                    }
                    onClick={favorite ? handleRemoveFav : handleAddFav}
                  >
                    <Typography level="tiny" sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                      {Translations.FAVORITE}
                    </Typography>
                  </Button>
                  {flags.archiveChats && (
                    <Button
                      variant="outlined"
                      size="sm"
                      sx={styles.headerButton(false)}
                      startDecorator={
                        <IconsSvg.ic_archive
                          width={14}
                          height={14}
                          stroke={colors.neutral[40]}
                          style={{ marginTop: '1px' }}
                        />
                      }
                      onClick={() => {
                        conversation !== undefined &&
                          setConversationArchiveState({ ...conversation, folders: [] }, !archived);
                        setArchived(!archived);
                      }}
                    >
                      <Typography level="tiny" sx={{ display: 'flex', alignItems: 'center', gap: '3.5px' }}>
                        {archived ? Translations.UNARCHIVE : Translations.ARCHIVE}
                      </Typography>
                    </Button>
                  )}
                  {conversation && conversation.conversationOrigin === 'MANUAL' && (
                    <Button
                      variant="outlined"
                      size="sm"
                      sx={styles.headerButton(false)}
                      startDecorator={
                        <IconsSvg.ic_edit
                          width={14}
                          height={14}
                          stroke={colors.neutral[40]}
                          style={{ marginTop: '1px' }}
                        />
                      }
                      onClick={() => setCreateChatModalState(true, conversation)}
                    >
                      <Typography level="tiny" sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        {Translations.EDIT}
                      </Typography>
                    </Button>
                  )}
                </Box>
              </Box>
            </Stack>
          </Box>
        ) : (
          <></>
        )}
      </DetailsHeader>
    </>
  );
}

const styles = {
  headerButton: (favorite: boolean) => ({
    alignSelf: 'center',
    borderRadius: 20,
    color: favorite ? 'primary.dark' : 'info.dark',
    borderColor: favorite ? 'var(--joy-palette-primary-solidActiveBorder)' : 'info.light',
    background: favorite ? 'var(--joy-palette-primary-solidActiveBg)' : 'transparent',
    padding: '3px 9px 3px 5px',
    fontSize: (theme: any) => theme.typography.tiny,
    minHeight: '24px',
    '&:hover': {
      color: favorite ? 'primary.dark' : 'info.dark',
      background: favorite ? 'var(--joy-palette-primary-solidActiveBg)' : 'transparent',
      borderColor: favorite ? '#BDDEFB' : 'info.light',
    },
  }),
};
