import { Box, Typography } from '@mui/joy';
import { AutocompleteGetTagProps } from '@mui/material';
import {
  OptionPropertyValue,
  PropertyDefinitionDataType,
  TenantUserPropertyValue,
} from 'services/models/domain/entityViews';
import { generateColorFromOid, getUsersInitials } from 'services/utils/helpers';
import CustomAvatar from 'components/CustomAvatar';
import ChatChip from 'components/ui-library/chat/Chip/ChatChip';
import { ChatChipStyle } from 'components/ui-library/chat/Chip/interface';
import { ReactComponent as CloseIcon } from 'assets/icons/ic_close.svg';
import { colors } from 'theme/colors';

interface PropertyChipProps {
  propertyValue: OptionPropertyValue | TenantUserPropertyValue;
  isMultiValue: boolean;
  tagProps?: ReturnType<AutocompleteGetTagProps>;
}

export default function PropertyChip({ propertyValue, isMultiValue, tagProps }: PropertyChipProps) {
  const isDeleteIconShown = !!tagProps;
  if (propertyValue.type === PropertyDefinitionDataType.Option) {
    return (
      <ChatChip
        chipColor={propertyValue.color}
        icon={propertyValue.icon}
        sx={style.optionChip(isDeleteIconShown)}
        chipStyle={isMultiValue ? ChatChipStyle.Edged : ChatChipStyle.Round}
        endDecorator={isDeleteIconShown && <CloseIcon onClick={tagProps.onDelete} style={style.closeIcon} />}
      >
        <Typography level="tiny" sx={style.optionChipText}>
          {propertyValue.value}
        </Typography>
      </ChatChip>
    );
  }

  return (
    <Box sx={style.userChip(isDeleteIconShown)}>
      <CustomAvatar
        index={generateColorFromOid(propertyValue.oid.oid ?? '')}
        size={20}
        userInitials={getUsersInitials(propertyValue?.fullName, propertyValue?.firstName)}
        imgUrl={propertyValue.avatar}
      />
      <Typography level="tiny" sx={style.userIconText}>
        {propertyValue.fullName}
      </Typography>
      {isDeleteIconShown && <CloseIcon onClick={tagProps.onDelete} style={style.closeIcon} />}
    </Box>
  );
}

const style = {
  optionChip: (isDeleteIconShown: boolean) => ({
    margin: '2px',
    cursor: isDeleteIconShown ? 'auto' : 'pointer',
    '& svg:hover': {
      backgroundColor: colors.neutral[15],
    },
    width: '99%',
    maxWidth: 'fit-content',
    overflow: 'hidden',
    alignItems: 'center',
    '.JoyChip-label': {
      display: 'flex',
    },
  }),
  optionChipText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  userChip: (isDeleteIconShown: boolean) => ({
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    padding: '4px',
    borderRadius: '24px',
    border: isDeleteIconShown ? `1px solid ${colors.neutral[15]}` : 'none',
    '& svg:hover': {
      backgroundColor: colors.neutral[15],
    },
    margin: '2px',
    overflow: 'hidden',
  }),
  userIconText: {
    maxWidth: 'fit-content',
    color: 'secondary.dark',
    lineHeight: '16px',
    letterSpacing: '-0.005em',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  closeIcon: {
    width: '14px',
    height: '14px',
    cursor: 'pointer',
    borderRadius: '4px',
  },
};
