import { ListItemDecorator } from '@mui/joy';
import { MenuItem, MenuItemProps } from '@mui/material';

import { OptionsSx } from 'components/ui-library/chat/CellMenu/styled';

const CellMenuOption = (props: MenuItemProps) => {
  const { sx, children, ...rest } = props;
  const MenuSx = { ...OptionsSx, ...sx };

  return (
    <MenuItem sx={MenuSx} {...rest}>
      <ListItemDecorator>{children}</ListItemDecorator>
    </MenuItem>
  );
};

export default CellMenuOption;
