import { Box, useTheme } from '@mui/joy';
import { Skeleton } from '@mui/material';

const ActiveDatePlaceHolder = () => {
  const theme = useTheme();
  return (
    <Box>
      <Skeleton variant="text" sx={{ fontSize: theme.typography.legalMicro }}></Skeleton>
    </Box>
  );
};

export default ActiveDatePlaceHolder;
