import { Stack, Tooltip, Typography } from '@mui/joy';
import { Box, SvgIcon } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/clean-svg/ic_close.svg';
import { EntityDetailsType, sidebarState } from 'services/store/slices/sidebarSlice';
import { useSelector } from 'react-redux';
import { useSidebar } from 'services/hooks/useSidebar';
import { DetailsIconStyle, TelegramIconStyle } from 'components/Kanban/styles';
import { IconsSvg } from 'assets/icons';
import { sidebarEntityDetailsState } from 'services/store/slices/sidebarEntityDetailsSlice';
import Translations from 'const/translations/en';
import { colors } from 'theme/colors';
import { Conversation, OriginTypes } from 'services/models/domain/conversation';
import { NavigationPath, RouterPaths } from 'services/hooks/useNavigation';
import SideButtonsPair from 'components/SideButtonsPair';
import { useCopyLink } from 'services/hooks/useCopyLink';
import { getChannelFilledIcon, getChannelIcon, getChannelIconColor } from 'services/utils/channelUtils';
import LinkDescriptionComponent from 'components/LinkDescriptions';
import { useTelegramChatLink } from 'services/hooks/useTelegramChatLink';
import { entityViewsState } from 'services/store/slices/entityViewsSlice';
import { OptionPropertyValue } from 'services/models/domain/entityViews';
import { ReactComponent as TelegramIcon } from 'assets/icons/ic_telegram.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/ic_check.svg';
import PageLink from 'components/PageLink';
import { useNavigationTableEntites } from 'services/hooks/useNavigationTableEntities';
import {
  isTableNavigationStart,
  isTableNavigationEnd,
  tableEntitiesNavigationState,
} from 'services/store/slices/tableEntitiesNavigationSlice';

interface DetailsHeaderProps {
  children?: JSX.Element | JSX.Element[];
}

export default function DetailsHeader({ children }: DetailsHeaderProps) {
  const { closeSidebar } = useSidebar();
  const { entityOid, entity } = useSelector(sidebarEntityDetailsState);
  const { goToNextEntity, goToPreviousEntity } = useNavigationTableEntites();
  const { entityType } = useSelector(sidebarState);
  const { hasNextList, hasPreviousList, outsideOfNavigation } = useSelector(tableEntitiesNavigationState);
  const isNavigationStart = useSelector(isTableNavigationStart);
  const isNavigationEnd = useSelector(isTableNavigationEnd);

  const { linkCopied, copyLink } = useCopyLink();
  const conversation = entity as Conversation | undefined;
  const link = conversation?.chatLink;
  const { telegramChatLink } = useTelegramChatLink(
    conversation?.chatLink,
    conversation?.conversationType,
    conversation?.participants || []
  );
  const { entityViews } = useSelector(entityViewsState);
  const channelPropertyDefinitionOid = entityViews.propertyDefinitions.find((p) => p.name === 'Channel')?.oid.oid;
  const channelProperty = conversation?.properties?.find(
    (p) => p.propertyDefinitionOid.oid === channelPropertyDefinitionOid
  ) as OptionPropertyValue | undefined;
  const channelType = channelProperty?.value;

  const canNavigateToPrevioius = !outsideOfNavigation && (!isNavigationStart || hasPreviousList);
  const canNavigateToNext = !outsideOfNavigation && (!isNavigationEnd || hasNextList);

  let navigateToPage: NavigationPath = RouterPaths.Chats;

  switch (entityType) {
    case EntityDetailsType.chatDetails:
      if (entityOid) navigateToPage = RouterPaths.createDetailsChatPath(entityOid);
      break;
    case EntityDetailsType.contactDetails:
      if (entityOid) navigateToPage = RouterPaths.createDetailsContactPath(entityOid);
      break;
    default:
      break;
  }
  const getLinkDescription = () => {
    return (
      <LinkDescriptionComponent
        origin={conversation?.conversationOrigin || ''}
        originalLink={telegramChatLink}
        link={link}
        channelType={channelType}
      />
    );
  };

  const renderSidebuttons = () => {
    return (
      <SideButtonsPair
        rightComponent={{
          icon:
            conversation?.conversationOrigin === OriginTypes.TelegramSync
              ? TelegramIcon
              : getChannelFilledIcon(link ? channelType : undefined),
          hoveredIcon:
            conversation?.conversationOrigin === OriginTypes.TelegramSync
              ? TelegramIcon
              : getChannelIcon(link ? channelType : undefined),
          description: getLinkDescription(),
          stroke:
            conversation?.conversationOrigin !== OriginTypes.TelegramSync
              ? getChannelIconColor(link ? channelType : undefined, telegramChatLink ? true : false)
              : undefined,
          css: { ...TelegramIconStyle(!!telegramChatLink), padding: '6px' },
          link: telegramChatLink,
          showFilledIcon: true,
        }}
        leftComponent={{
          icon: IconsSvg.ic_copy,
          description: linkCopied ? (
            <Typography level="micro" sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <CheckIcon stroke={colors.neutral[0]} width={12} height={12} />
              {Translations.LINK_COPIED}
            </Typography>
          ) : (
            <Typography level="micro" sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              {Translations.COPY_CHAT_LINK}
            </Typography>
          ),
          css: {
            ...TelegramIconStyle(!!telegramChatLink),
            marginRight: '5px',
            '&:hover': {
              '& path': {
                stroke: colors.neutral[80],
              },
            },
          },
          link: () => copyLink(EntityDetailsType.chatDetails, conversation?.oid.oid),
          stroke: colors.neutral[40],
        }}
      />
    );
  };
  return (
    <Box sx={styles.headerContainer}>
      <Stack direction="column" spacing={4} sx={{ p: '24px', pb: '12px' }}>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <SvgIcon
            component={CloseIcon}
            sx={{ ...DetailsIconStyle, padding: '4px', border: 'none', marginLeft: '-8px' }}
            stroke={colors.neutral[40]}
            width={11}
            height={11}
            onClick={() => {
              closeSidebar();
            }}
          />
          <Stack direction={'row'} spacing={0.75} sx={{ alignItems: 'center' }}>
            {canNavigateToPrevioius && (
              <SvgIcon
                component={IconsSvg.ic_arrow_left}
                sx={{ ...DetailsIconStyle, padding: '6px' }}
                width={11}
                height={11}
                stroke={colors.neutral[40]}
                onClick={goToPreviousEntity}
              />
            )}
            {canNavigateToNext && (
              <SvgIcon
                component={IconsSvg.ic_arrow_right}
                sx={{ ...DetailsIconStyle, padding: '6px' }}
                width={11}
                height={11}
                stroke={colors.neutral[40]}
                onClick={goToNextEntity}
              />
            )}
            {(canNavigateToPrevioius || canNavigateToNext) && (
              <Box sx={{ width: '20px' }}>
                <Box
                  sx={{
                    width: '3px',
                    height: '3px',
                    borderRadius: '50%',
                    background: colors.neutral[40],
                    margin: 'auto',
                  }}
                />
              </Box>
            )}
            <Tooltip
              title={Translations.OPEN_FULL_PAGE_TOOLTIP}
              arrow
              placement="bottom"
              enterDelay={200}
              enterTouchDelay={200}
              enterNextDelay={200}
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <PageLink to={navigateToPage} openInNewTab>
                <SvgIcon
                  component={IconsSvg.ic_open_full}
                  sx={{ ...DetailsIconStyle, padding: '6px' }}
                  width={11}
                  height={11}
                  stroke={colors.neutral[40]}
                />
              </PageLink>
            </Tooltip>
            {entityType === EntityDetailsType.chatDetails && renderSidebuttons()}
          </Stack>
        </Stack>
        {children}
      </Stack>
    </Box>
  );
}

const styles = {
  headerContainer: {
    backgroundColor: `#fff`,
  },
};
