import { Box, Checkbox, Divider, Typography } from '@mui/joy';
import { CheckboxSx, NameSx, OptionSx, UserBoxSx } from 'components/Chats/Filters/PropertyFilter/styles';
import CustomAvatar from 'components/CustomAvatar';
import CellMenuOption from 'components/ui-library/chat/CellMenu/CellMenuOption/CellMenuOption';
import _ from 'lodash';
import { ApplyFilterButton } from 'page/chats/ChatTableFilterBar/FilterOptions/ApplyFilterButton';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FilterOption } from 'services/models/domain/entityFilter';
import { OrganizationalUser } from 'services/models/domain/user';
import { authState } from 'services/store/slices/authSlice';
import { generateColorFromOid, getUsersInitials } from 'services/utils/helpers';
import { colors } from 'theme/colors';

export interface UserFilterMenuProps {
  filterOption: FilterOption;
  selectedFilter?: FilterOption;
  onChange: (userOids: string[]) => void;
  onClose: () => void;
}

export const UserFilterMenu = (props: UserFilterMenuProps) => {
  const [selectedUserOids, setSelectedUserOids] = useState<string[]>([]);
  const [initialValue, setInitialValue] = useState<string[]>([]);
  const { user } = useSelector(authState);
  const members = user?.actingAsMemberOfOrganization.listOfUsersInOrganization;

  useEffect(() => {
    if (props.selectedFilter && 'value' in props.selectedFilter) {
      if ('userOids' in props.selectedFilter.value) {
        setInitialValue(props.selectedFilter.value.userOids);
        setSelectedUserOids(props.selectedFilter.value.userOids);
      }
      if ('participantOids' in props.selectedFilter.value) {
        setInitialValue(props.selectedFilter.value.participantOids);
        setSelectedUserOids(props.selectedFilter.value.participantOids);
      }
    }
  }, []);

  useEffect(() => {
    if (props.selectedFilter && 'value' in props.selectedFilter) {
      if ('userOids' in props.selectedFilter.value) {
        setSelectedUserOids(props.selectedFilter.value.userOids);
      }
      if ('participantOids' in props.selectedFilter.value) {
        setSelectedUserOids(props.selectedFilter.value.participantOids);
      }
      if ('followUpsAssignedTo' in props.selectedFilter.value) {
        setSelectedUserOids(props.selectedFilter.value.followUpsAssignedTo);
      }
    }
  }, [props.selectedFilter]);

  const handleOnClick = (userOid: string) => {
    let updatedUserOids = [];
    if (selectedUserOids.includes(userOid)) {
      updatedUserOids = selectedUserOids.filter((oid) => oid !== userOid);
    } else {
      updatedUserOids = [...selectedUserOids, userOid];
    }
    setSelectedUserOids(updatedUserOids);
    props.onChange(updatedUserOids);
  };

  const renderCheckList = (user: OrganizationalUser) => {
    const { oid, firstName, fullName, name, profileImageUrl } = user;
    const userInitials = getUsersInitials(name, fullName);
    const isChecked = selectedUserOids.includes(oid);
    return (
      <>
        {(firstName || fullName) && (
          <CellMenuOption key={oid} sx={OptionSx} onClick={() => handleOnClick(oid)}>
            <Box sx={UserBoxSx}>
              <CustomAvatar
                index={generateColorFromOid(oid)}
                size={16}
                userInitials={userInitials}
                imgUrl={profileImageUrl}
              />
              <Typography level="tiny" sx={NameSx}>
                {fullName ?? firstName}
              </Typography>
            </Box>
            <Checkbox sx={CheckboxSx} checked={isChecked} />
          </CellMenuOption>
        )}
      </>
    );
  };

  return (
    <Box sx={{ marginX: '5px' }}>
      <Box sx={style.menuHeader}>
        <Typography level="tiny" sx={{ color: colors.neutral[50] }} onClick={() => {}}>
          {props.filterOption.title}
        </Typography>
      </Box>
      <Divider sx={{ border: '1.3px dashed #fff', background: 'var(--joy-palette-info-plainBorder)' }} />
      {members?.map(renderCheckList)}
      {!_.isEqual(initialValue, selectedUserOids) && (
        <ApplyFilterButton onClick={props.onClose} sx={{ width: 'calc(100% - 20px)', margin: '10px' }} />
      )}
    </Box>
  );
};

const style = {
  menuHeader: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '8px',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
};
