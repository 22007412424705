import { ReactComponent as TelegramIcon } from 'assets/icons/ic_telegram.svg';
import { ReactComponent as DefaultChannelIcon } from 'assets/icons/channelIcons/ic_default_platform.svg';
import { ReactComponent as Discord } from 'assets/icons/channelIcons/ic_discord.svg';
import { ReactComponent as Twitter } from 'assets/icons/channelIcons/ic_twitter.svg';
import { ReactComponent as Email } from 'assets/icons/channelIcons/ic_email.svg';
import { ReactComponent as Signal } from 'assets/icons/channelIcons/ic_signal.svg';
import { ReactComponent as XMTP } from 'assets/icons/channelIcons/ic_xmtp.svg';
import { ReactComponent as Instagram } from 'assets/icons/channelIcons/ic_instagram.svg';
import { ReactComponent as Whatsapp } from 'assets/icons/channelIcons/ic_whatsapp.svg';
import { ReactComponent as DiscordFilled } from 'assets/icons/channelIcons/ic_discord_filled.svg';
import { ReactComponent as TwitterFilled } from 'assets/icons/channelIcons/ic_twitter_filled.svg';
import { ReactComponent as EmailFilled } from 'assets/icons/channelIcons/ic_email_filled.svg';
import { ReactComponent as SignalFilled } from 'assets/icons/channelIcons/ic_signal_filled.svg';
import { ReactComponent as XMTPFilled } from 'assets/icons/channelIcons/ic_xmtp_filled.svg';
import { ReactComponent as InstagramFilled } from 'assets/icons/channelIcons/ic_instagram_filled.svg';
import { ReactComponent as WhatsappFilled } from 'assets/icons/channelIcons/ic_whatsapp_filled.svg';
import { ChannelType } from 'services/models/domain/conversation';
import { colors } from 'theme/colors';

export const getChannelIcon = (channelType: string | undefined) => {
  switch (channelType) {
    case ChannelType.Twitter:
      return Twitter;
    case ChannelType.Telegram:
      return TelegramIcon;
    case ChannelType.Discord:
      return Discord;
    case ChannelType.Instagram:
      return Instagram;
    case ChannelType.WhatsApp:
      return Whatsapp;
    case ChannelType.Email:
      return Email;
    case ChannelType.Signal:
      return Signal;
    case ChannelType.XMTP:
      return XMTP;
    case undefined:
      return DefaultChannelIcon;
    default:
      return DefaultChannelIcon;
  }
};

export const getChannelFilledIcon = (channelType: string | undefined) => {
  switch (channelType) {
    case ChannelType.Twitter:
      return TwitterFilled;
    case ChannelType.Telegram:
      return TelegramIcon;
    case ChannelType.Discord:
      return DiscordFilled;
    case ChannelType.Instagram:
      return InstagramFilled;
    case ChannelType.WhatsApp:
      return WhatsappFilled;
    case ChannelType.Email:
      return EmailFilled;
    case ChannelType.Signal:
      return SignalFilled;
    case ChannelType.XMTP:
      return XMTPFilled;
    case undefined:
      return DefaultChannelIcon;
    default:
      return DefaultChannelIcon;
  }
};

export const getChannelIconColor = (channelType: string | undefined, enabled: boolean) => {
  switch (channelType) {
    case ChannelType.Telegram:
      return enabled ? colors.telegramLogo : colors.neutral[30];
    case undefined:
      return enabled ? colors.neutral[40] : colors.neutral[30];
    default:
      return undefined;
  }
};
