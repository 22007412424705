import { Box, useTheme } from '@mui/joy';
import { Skeleton } from '@mui/material';

const ConversationName = () => {
  const theme = useTheme();
  return (
    <Box>
      <Skeleton variant="text" sx={{ fontSize: { md: '25px', sm: theme.typography.large }, width: `200px` }}></Skeleton>
    </Box>
  );
};

export default ConversationName;
