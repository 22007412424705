import { GridCellProps } from '@mui/x-data-grid-pro';

import { useChatActions } from 'services/hooks/useChatActions';
import { StyledFavoriteCell } from 'components/Chats/ChatTableV2/TableCell/customCells/styles';
import FavoriteCellContent from 'components/Chats/ChatTableV2/TableCell/customCells/cellsContent/Favorite/FavoriteCellContent';

export default function FavoriteCell(props: GridCellProps) {
  const { value, ...rest } = props;
  const { handleRemoveFav, handleAddFav } = useChatActions(value.conversation, value.refetch);

  return (
    <StyledFavoriteCell {...rest}>
      <FavoriteCellContent
        favorite={value.conversation.favorit}
        handleRemoveFav={handleRemoveFav}
        handleAddFav={handleAddFav}
      />
    </StyledFavoriteCell>
  );
}
