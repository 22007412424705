import { Skeleton } from '@mui/material';
import { Box } from '@mui/joy';
import { SxProps } from '@mui/joy/styles/types';

interface AvatarPlaceholderProps {
  size?: number;
  sx?: SxProps;
}

const AvatarPlaceholder = ({ size = 19, sx }: AvatarPlaceholderProps) => {
  return (
    <Box sx={sx}>
      <Skeleton variant="circular" height={size} width={size} />
    </Box>
  );
};

export default AvatarPlaceholder;
