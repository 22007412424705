import { Box, Divider, IconButton, Sheet } from '@mui/joy';
import { Backdrop } from '@mui/material';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { LDProps } from 'launchdarkly-react-client-sdk/lib/withLDConsumer';
import React, { useEffect, useState } from 'react';

import Icons from 'assets/icons';
import CustomAvatar from 'components/CustomAvatar';
import PopOver from 'components/PopOver';
import AppLinks from 'const/links';
import NavigationTabs, { TabStyle } from 'page/dashboard/header/NavigationTabs';
import BatchMessageButton from 'page/dashboard/header/components/BatchMessageButton';
import ProfileItem from 'page/dashboard/header/components/ProfileItem';
import { headerStyles } from 'page/dashboard/header/styles';
import HamburgerMenu from 'page/modal/HamburgerMenu';
import InviteTeamModal from 'page/modal/InviteTeamModal/InviteTeamModal';
import ProfileModal from 'page/modal/ProfileModal';
import SettingsModal from 'page/modal/SettingsModal';
import { useSelector } from 'react-redux';
import useAuth from 'services/hooks/useAuth';
import { useConversationFilters } from 'services/hooks/useConversationFilters';
import { useFetchEntityViews } from 'services/hooks/useFetchEntityViews';
import { RouterPaths, useNavigation } from 'services/hooks/useNavigation';
import useResponsive from 'services/hooks/useResponsive';
import { authState } from 'services/store/slices/authSlice';
import { iconMapping } from 'services/utils/dashboardHeaderUtils';
import { avatarFromName, generateColorFromOid } from 'services/utils/helpers';
import { colors } from 'theme/colors';

interface HeaderProps {
  setDrawerOpen?: (isOpen: boolean) => void;
}
export function Header({ flags, setDrawerOpen }: LDProps & HeaderProps) {
  const { user: userDetails } = useSelector(authState);
  const [openProfile, setOpenProfile] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openInvite, setOpenInvite] = useState(false);
  const { fetchUser, logout } = useAuth();
  const [openHamburger, setOpenHamburger] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isDesktop = useResponsive('up', 'sm');
  const ChromeIcon = iconMapping['chromeExtension'];
  const UserIcon = iconMapping['inviteTeammates'];
  const navigate = useNavigation();

  useConversationFilters();
  useFetchEntityViews();

  useEffect(() => {
    fetchUser();
  }, []);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDropdownMenuClick = (path: string) => {
    window.open(path, '_blank');
  };

  const open = Boolean(anchorEl);

  const onHamburgerClick = () => {
    if (setDrawerOpen) {
      setDrawerOpen(true);
    } else {
      setOpenHamburger(!openHamburger);
    }
  };

  return (
    <React.Fragment>
      <Box component="header" sx={headerStyles.headerBox}>
        <Box sx={headerStyles.headerItemsLeft}>
          {!isDesktop ? (
            <Box>
              <IconButton
                size="sm"
                variant="plain"
                color="primary"
                onClick={() => onHamburgerClick()}
                sx={{ justifyContent: 'flex-start', padding: '0px' }}
              >
                <img width={22} height={22} alt={'close'} src={Icons.ic_hamburger} />
              </IconButton>

              {setDrawerOpen === undefined && <HamburgerMenu isOpen={openHamburger} setIsOpen={setOpenHamburger} />}
            </Box>
          ) : (
            <>
              <img
                src={require('assets/images/logo.png')}
                alt="logo"
                width="22px"
                height="22px"
                style={{ marginTop: '2px' }}
              />
              <NavigationTabs style={TabStyle.Horizontal} />
              <BatchMessageButton />
            </>
          )}
          <Divider sx={headerStyles.verticalDivider} orientation="vertical" />
        </Box>

        <Box sx={headerStyles.headerItemsRight} aria-owns={open ? 'over-popover' : undefined} aria-haspopup="true">
          <Box sx={{ mr: '2px' }} onClick={handlePopoverOpen}>
            <CustomAvatar
              userInitials={avatarFromName(userDetails?.fullName ?? '')}
              index={generateColorFromOid(userDetails?.oid ?? '')}
              imgUrl={userDetails?.profileImageUrl}
              size={28}
              solid={true}
            />
          </Box>
          <img src={Icons.ic_chevron_down} alt="ic_chevron_down" />
        </Box>

        <PopOver
          id="over-popover"
          arrow="top-right"
          disableArrow={false}
          sx={headerStyles.popOver}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={handleClose}
        >
          <Sheet variant="plain" sx={{ minWidth: '180px', backgroundColor: 'primary.light', p: 1 }}>
            <ProfileItem
              title="Profile"
              icon={Icons.ic_user}
              onClick={() => {
                setOpenProfile(true);
                handleClose();
              }}
            />
            <ProfileItem
              title="Invite Teammates"
              icon={<UserIcon height={18} width={18} stroke={colors.neutral[60]} />}
              onClick={() => {
                setOpenInvite(true);
                handleClose();
              }}
            />
            <ProfileItem
              title="Chrome Extension"
              icon={<ChromeIcon height={18} width={18} stroke={colors.neutral[60]} />}
              isNew
              onClick={() => {
                handleDropdownMenuClick(AppLinks.chromeExtensionLink);
                handleClose();
              }}
            />
            {flags?.showUserNotificationSettings ? (
              <ProfileItem
                title="Settings"
                icon={Icons.ic_settings}
                onClick={() => {
                  setOpenSettings(true);
                  handleClose();
                }}
              />
            ) : null}
            <Divider sx={headerStyles.divider} />
            <ProfileItem
              title="Blog"
              icon={Icons.ic_book}
              onClick={() => {
                handleDropdownMenuClick(AppLinks.blogLink);
                handleClose();
              }}
            />
            <ProfileItem
              title="Twitter / X"
              icon={Icons.ic_twitter}
              onClick={() => {
                handleDropdownMenuClick(AppLinks.twitterLink);
                handleClose();
              }}
            />
            <ProfileItem
              title="Telegram Community"
              icon={Icons.ic_friends}
              onClick={() => {
                handleDropdownMenuClick(AppLinks.telegramGroupLink);
                handleClose();
              }}
            />
            <Divider sx={headerStyles.divider} />
            <ProfileItem
              title="Sign Out"
              icon={Icons.ic_logout}
              onClick={async () => {
                await logout();
                navigate(RouterPaths.Login);
              }}
            />
          </Sheet>
        </PopOver>

        <Backdrop sx={headerStyles.backdrop} open={open} />
        <ProfileModal isOpen={openProfile} setIsOpen={setOpenProfile} />
        <InviteTeamModal isOpen={openInvite} setIsOpen={setOpenInvite} />
        <SettingsModal isOpen={openSettings} setIsOpen={setOpenSettings} />
      </Box>
    </React.Fragment>
  );
}

export default withLDConsumer()(Header);
