import { colors } from 'theme/colors';
import { SxProps, Theme, alpha } from '@mui/material';

const styles = {
  cropperBackground: {
    position: 'relative',
    width: '100%',
    height: '240px',
    backgroundImage: `
          linear-gradient(45deg, ${colors.neutral[15]} 25%, transparent 25%),
          linear-gradient(45deg, transparent 75%, ${colors.neutral[15]}  75%),
          linear-gradient(45deg, transparent 75%, ${colors.neutral[15]}  75%),
          linear-gradient(45deg, ${colors.neutral[15]}  25%, ${colors.neutral[0]} 25%)`,
    backgroundSize: `30px 30px`,
    backgroundPosition: `0 0, 0 0, 15px 15px, 15px 15px`,
    borderRadius: 10,
    border: `1px solid ${alpha('#000000', 0.02)}`,
    objectFit: 'cover',
  },
  cropperContainer: {
    borderRadius: 10,
    margin: { xs: '16px 8px', md: '16px' },
    width: { xs: 'calc(100% - 16px)', md: '96%' },
    minWidth: 290,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    paddingRight: { xs: '0', md: 0.5 },
  },
  buttonsGrid: {
    marginX: '16px',
    marginY: '2px',
    width: { xs: 'calc(100% - 20px)', md: '96.3%' },
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonStack: { display: 'flex', justifyContent: 'flex-end', paddingRight: { xs: 0.8, md: 0.5 } },
  cancelButton: {
    backgroundColor: colors.white,
    color: colors.neutral[15],
    marginRight: '10px',
    border: `1px solid ${alpha(colors.neutral[15], 1)}`,
    minHeight: '32px',
  },
  imageError: {
    color: colors.warning,
    ml: 2.5,
    fontSize: '13px',
    '@media (max-width: 500px)': {
      fontSize: '10px',
    },
  },
  imageUploadContainer: {
    display: 'flex',
    flexDirection: 'row',
    border: `1.5px dashed ${colors.neutral[15]}`,
    width: 'auto',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarContainer: {
    marginX: 1.9,
    display: 'grid',
    gridTemplateColumns: {
      xs: '60px 1fr',
      sm: 'minmax(60px, 60px) minmax(1fr, 1fr)',
      md: 'minmax(60px, 60px) minmax(1fr, 1fr)',
    },
    gridTemplateRows: '64px 1fr',
    alignItems: 'center',
  },
  avatarBox: {
    position: 'absolute',
    bottom: 0,
    right: '-10px',
    backgroundColor: colors.trash.background,
    borderRadius: '40px',
    bborderColor: colors.trash.border,
    border: `2px solid ${alpha(colors.trash.border, 0.3)}`,
    padding: '2px',
    height: '25px',
    width: '25px',
  },
  profileModalBox: {
    flexDirection: 'row',
    display: 'flex',
    px: 1.5,
    py: 1.5,
    alignContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid #F9FAFB',
  },
  profileModalBackDrop: {
    zIndex: (theme: Theme) => theme.zIndex.modal + 1,
    opacity: '0.93px',
    backgroundColor: alpha('#0F1012', 0.93),
    color: alpha('#0F1012', 0.2),
  },
  profileModalSheet: {
    width: { xs: '100%', md: '550px' },
    borderRadius: '8px',
  },
  profileModalDialog: {
    '& .MuiPaper-root': {
      borderRadius: '12px',
    },
  },
  profileModalStack: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
  },
  profileTypography: {
    ml: 0.5,
  },
  closeButton: {
    position: 'absolute',
    top: '15px',
    right: '20px',
    background: 'transparent',
    padding: '4px',
    minHeight: 'auto',
    alignItems: 'flex-end',
    border: 'none',
    '&:hover': {
      background: colors.neutral[5],
      opacity: '1',
      border: 'none',
      color: colors.black,
    },
  },
  backButton: {
    background: 'transparent',
    padding: '4px',
    marginTop: '2px',
    marginLeft: '-1px',
    minHeight: 'auto',
    alignItems: 'flex-start',
    border: 'none',
    '&:hover': {
      background: colors.neutral[5],
      opacity: '1',
      border: 'none',
      color: colors.black,
    },
  },
};

export default styles;

export const BackdropStyle: SxProps = {
  zIndex: '1500',
  top: '0px',
  left: '0px',
  bottom: '0px',
  right: '0px',
  backgroundColor: '#13131380',
  position: 'fixed',
  overflow: 'hidden',
  backdropFilter: 'blur(3px)',
  '& .JoyModal-backdrop': {
    background: '#0F1012',
    backdropFilter: 'blur(2px)',
    opacity: 0.9,
  },
};

export const ModalStyle: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: 1,
  '& .JoyModal-backdrop': {
    background: '#0F1012',
    backdropFilter: 'blur(2px)',
    opacity: 0.9,
  },
};
