import { SxProps } from '@mui/material';
import { fonts } from 'theme/fonts';
import { colors } from 'theme/colors';
import { TgSyncStatus } from 'page/modal/TgConnectModal/interface';

export const rootBoxSx: SxProps = {
  px: { xs: 2, md: 4 },
};

export const headerTypoSx: SxProps = {
  pt: '1px',
  px: '5px',
  color: 'secondary.dark',
  fontWeight: '600',
  fontSize: '24px',
  fontFamily: fonts.regular,
  marginLeft: '2px',
  alignSelf: 'center',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: { xs: '150px', sm: '200px', md: '100%' },
  overflow: 'hidden',
  lineHeight: 'normal',
};

export const filterRootStackSx: SxProps = {
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'space-between',
  py: '12px',
};

export const filterInnerStackSx: SxProps = {
  justifyContent: 'flex-start',
  alignContent: 'center',
};

export const filterInnerStack2Sx: SxProps = {
  justifyContent: 'center',
  alignContent: 'end',
};

export const emptyBoxSx: SxProps = {
  backgroundColor: colors.neutral[30],
  borderRadius: '50%',
  width: '2px',
  height: '2px',
  mx: '10px',
  alignSelf: 'center',
};

export const favoriteChipSx: SxProps = {
  alignSelf: 'center',
  borderRadius: '50px',
  marginRight: '8px',
  fontWeight: 200,
};

export const participantChipSx: SxProps = {
  alignSelf: 'center',
};

export const SearchBarSx: SxProps = {
  borderRadius: '100px',
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
  padding: '4px 12px',
  '&.Joy-disabled': {
    color: colors.neutral['50'],
  },
  '& .JoyInput-startDecorator': {
    margin: 0,
  },
  height: '28px',
};

export const folderFilterBoxSx: SxProps = {
  height: 'fit-content',
};

export const filterDialogBtnSx: SxProps = {
  alignSelf: 'center',
};

export const tgConnectBtnSx = (status: string): SxProps => ({
  background:
    status === TgSyncStatus.DISCONNECTED ? `${colors.trash.background}` : 'var(--joy-palette-primary-solidBg)',
  border:
    status === TgSyncStatus.DISCONNECTED
      ? `1px solid ${colors.trash.border}`
      : '1px solid var(--joy-palette-primary-outlinedBorder)',
  padding: '0 4px 0 8px',
});

export const tgStatusTextSx = (status: string): SxProps => ({
  ml: '5px',
  fontSize: '14px',
  marginRight: '5px',
  color: `${status === TgSyncStatus.DISCONNECTED ? colors.warning : colors.neutral[50]}`,
  lineHeight: `14px`,
});

export const FilterSx: SxProps = {
  margin: '0px 0px 0px 8px',
};

export const ToggleButtonActiveSx: SxProps = {
  gap: '8px',
  p: '9px',
  alignItems: 'center',
  backgroundColor: 'white !important',
  border: 'none',
  height: '24px',
  borderRadius: '4px !important',
};

export const ToggleButtonInactiveSx: SxProps = {
  gap: '8px',
  p: '9px',
  alignItems: 'center',
  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: '4px !important',
};
