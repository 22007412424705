import { Divider, Typography } from '@mui/joy';
import { colors } from 'theme/colors';
import UIButton from 'components/ui-library/Button/UIButton';
import { Box, SxProps } from '@mui/material';
import { DividerSx } from 'components/FilterCheckList/style';
import Translations from 'const/translations/en';

export interface ApplyFilterButtonProps {
  onClick: () => void;
  sx?: SxProps;
}

export const ApplyFilterButton = (props: ApplyFilterButtonProps) => {
  return (
    <Box>
      <Divider sx={DividerSx} />
      <UIButton
        size="sm"
        sx={{
          justifyContent: 'center',
          backgroundColor: colors.neutral[90],
          ':hover': {
            backgroundColor: colors.neutral[90],
          },
          ...props.sx,
          marginY: '10px',
        }}
        variant="plain"
        onClick={() => props.onClick()}
      >
        <Typography level="tiny+" sx={{ color: 'white' }}>
          {Translations.APPLY_FILTER}
        </Typography>
      </UIButton>
    </Box>
  );
};
