import { Typography, Box, Stack } from '@mui/joy';
import CustomAvatar from 'components/CustomAvatar';
import DetailsHeader from 'components/Details/DetailsHeader';
import Translations from 'const/translations/en';
import { avatarFromName, generateColorFromOid } from 'services/utils/helpers';
import ConversationName from 'page/details/placeholder/ConversationNamePlaceholder';
import DetailsHeaderDoubleIcons from 'components/Details/DetailsHeaderDoubleIcons';
import { EntityDetailsType } from 'services/store/slices/sidebarSlice';
import { fonts } from 'theme/fonts';
import { ContactDetails } from 'services/models/domain/contact';
import AvatarPlaceholder from 'page/details/placeholder/AvatarPlaceholder';
import SinglePlaceholder from 'page/details/placeholder/SinglePlaceholder';

interface ContactDetailsHeaderProps {
  contact: ContactDetails | undefined;
  loading: boolean;
}

export default function ContactSidebarHeader({ contact, loading }: ContactDetailsHeaderProps) {
  return (
    <DetailsHeader>
      {(loading || contact) && (
        <Stack direction={'row'} spacing={2} sx={styles.headerTextContainer}>
          {loading ? (
            <AvatarPlaceholder size={66} />
          ) : (
            <CustomAvatar
              userInitials={avatarFromName(contact?.name ?? '')}
              index={generateColorFromOid(contact?.oid.oid ?? '')}
              size={66}
              solid={true}
            />
          )}
          <Stack
            direction={'column'}
            sx={{
              gap: '6px',
              width: '100%',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {loading ? (
                <ConversationName />
              ) : (
                <Typography level="h2" sx={{ color: 'text.primary' }}>
                  {contact?.name}
                </Typography>
              )}
              <Stack direction={'row'} spacing={1}>
                <DetailsHeaderDoubleIcons
                  telegramLink={contact?.telegramLink}
                  noLinkPrimaryMessage={Translations.NO_CONTACT_LINK_TOOLTIP_PRIMARY_MESSAGE}
                  noLinkSecondaryMessage={Translations.NO_CONTACT_LINK_TOOLTIP_SECONDARY_MESSAGE}
                  copyLinkMessage={Translations.COPY_CONTACT_LINK}
                  entityOid={contact?.oid.oid}
                  entityDetailsType={EntityDetailsType.contactDetails}
                  disabled={loading}
                />
              </Stack>
            </Box>
            {loading ? (
              <SinglePlaceholder width={50} sx={{ p: 0 }} />
            ) : (
              <Typography level="regular" sx={{ color: 'text.tertiary' }}>
                {contact?.telegramHandle ? `@${contact?.telegramHandle}` : ``}
              </Typography>
            )}
          </Stack>
        </Stack>
      )}
    </DetailsHeader>
  );
}

const styles = {
  headerTextContainer: {
    alignItems: 'center',
    marginTop: '16px',
    marginLeft: '-2px',
  },
  headerText: {
    color: 'black',
    fontSize: '24px',
    fontWeight: '500',
    fontFamily: fonts.regular,
    height: 'auto',
    whiteSpace: 'normal',
    overflow: 'hidden',
    width: '320px',
    lineHeight: '1',
    paddingBottom: '5px',
  },
};
