import { Sheet } from '@mui/joy';
import Icons from 'assets/icons';
import AssociatedChatsDetailsCard from 'components/Details/AssociatedChatsDetailsCard';
import BioDetailsCard from 'components/Details/BioDetailsCard';
import NotesDetailsCard from 'components/Details/NotesDetailsCard';
import NoData from 'components/NoData';
import ContactSidebarHeader from 'components/Sidebar/ContactDetailsHeader';
import Translations from 'const/translations/en';
import { detailsPageInnerContainerStyle } from 'page/details/styles';
import { useEffect, useState } from 'react';
import { useFetchSidebarEntityDetails } from 'services/hooks/useFetchSideBarEntityDetails';
import { PartyType } from 'services/models/api/generated';
import { ContactDetails } from 'services/models/domain/contact';
import { EntityType } from 'services/models/domain/entityTypes';

export default function SidebarContactDetail() {
  const [partyScopeFilter, setPartyScopeFilter] = useState<PartyType>(PartyType.Organization);
  const { entity, loading } = useFetchSidebarEntityDetails(partyScopeFilter);

  const [contact, setContact] = useState<ContactDetails>();

  useEffect(() => {
    if (entity) {
      setContact(entity as ContactDetails);
    }
  }, [entity]);

  return (
    <>
      <ContactSidebarHeader contact={contact} loading={loading} />
      {loading || contact ? (
        <Sheet variant="soft" sx={detailsPageInnerContainerStyle}>
          {contact?.bio && <BioDetailsCard bio={contact.bio} loading={loading} />}
          {contact?.associatedConversations && contact?.associatedConversations.length > 0 && (
            <AssociatedChatsDetailsCard associatedChats={contact?.associatedConversations} loading={loading} />
          )}
          {contact && (
            <NotesDetailsCard
              entityOid={contact?.oid.oid}
              entityType={EntityType.ContactRecord}
              notes={contact?.notes}
              partyScopeFilter={partyScopeFilter}
              setPartyScopeFilter={setPartyScopeFilter}
              loading={loading}
            />
          )}
        </Sheet>
      ) : (
        <NoData
          title={<img src={Icons.ic_no_chat} alt="" />}
          titleSx={{ color: 'info.main' }}
          message={Translations.NO_CONTACT_FOUND}
        />
      )}
    </>
  );
}
