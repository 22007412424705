import { IconsSvg } from 'assets/icons';
import { colors } from 'theme/colors';

type IconSvgKey = keyof typeof IconsSvg;

export const PROPERTY_ICONS: IconSvgKey[] = [
  'ic_check',
  'ic_checks',
  'ic_user',
  'ic_presentation',
  'ic_file_dollar',
  'ic_x',
  'ic_activity',
  'ic_logout',
  'ic_coffee',
  'ic_discount',
  'ic_plant',
  'ic_coin',
  'ic_loader',
  'ic_circle_dotted',
  'ic_beach',
  'ic_cactus',
  'ic_pig_money',
  'ic_brand_telegram',
  'ic_bell',
  'ic_alarm',
  'ic_number_1',
  'ic_number_2',
  'ic_number_3',
  'ic_number_4',
  'ic_number_5',
  'ic_number_6',
  'ic_number_7',
  'ic_number_8',
  'ic_number_9',
  'ic_number_0',
  'ic_run',
  'ic_triangle',
  'ic_plus',
  'ic_minus',
  'ic_trash',
];

export const PROPERTY_COLORS = [
  colors.white,
  colors['neutral']['60'],
  '#BF1111',
  '#DA880D',
  '#8FA605',
  '#CE09D2',
  '#3D09D2',
  colors['primary']['50'],
  '#06B4A9',
  '#1FBC05',
];
