import React from 'react';
import Popover from '@mui/material/Popover';
import { styled, Theme } from '@mui/joy';

interface ArrowProps {
  arrow: string;
  theme?: Theme;
}

const ArrowStyle = styled('span')(({ arrow, theme }: ArrowProps) => {
  const SIZE = 12;

  const POSITION = -(SIZE / 2);

  const borderStyle = `solid .1px ${theme?.palette.neutral[500]}`;

  const topStyle = {
    borderRadius: '0 0 3px 0',
    top: POSITION,
    borderBottom: borderStyle,
    borderRight: borderStyle,
  };
  const bottomStyle = {
    borderRadius: '3px 0 0 0',
    bottom: POSITION,
    borderTop: borderStyle,
    borderLeft: borderStyle,
  };
  const leftStyle = {
    borderRadius: '0 3px 0 0',
    left: POSITION,
    borderTop: borderStyle,
    borderRight: borderStyle,
  };
  const rightStyle = {
    borderRadius: '0 0 0 3px',
    right: POSITION,
    borderBottom: borderStyle,
    borderLeft: borderStyle,
  };

  return {
    tabIndex: 1,
    zIndex: 99,
    width: SIZE,
    height: SIZE,
    content: "''",
    display: 'block',
    position: 'absolute',
    transform: 'rotate(-135deg)',
    background: theme?.palette.background.body,
    // Top
    ...(arrow === 'top-left' && { ...topStyle, left: 20 }),
    ...(arrow === 'top-center' && { ...topStyle, left: 0, right: 0, margin: 'auto' }),
    ...(arrow === 'top-right' && { ...topStyle, right: 20 }),
    // Bottom
    ...(arrow === 'bottom-left' && { ...bottomStyle, left: 20 }),
    ...(arrow === 'bottom-center' && { ...bottomStyle, left: 0, right: 0, margin: 'auto' }),
    ...(arrow === 'bottom-right' && { ...bottomStyle, right: 20 }),
    // Left
    ...(arrow === 'left-top' && { ...leftStyle, top: 20 }),
    ...(arrow === 'left-center' && { ...leftStyle, top: 0, bottom: 0, margin: 'auto' }),
    ...(arrow === 'left-bottom' && { ...leftStyle, bottom: 20 }),
    // Right
    ...(arrow === 'right-top' && { ...rightStyle, top: 20 }),
    ...(arrow === 'right-center' && { ...rightStyle, top: 0, bottom: 0, margin: 'auto' }),
    ...(arrow === 'right-bottom' && { ...rightStyle, bottom: 20 }),
  };
});

interface PopOverProps {
  sx: any;
  children: React.ReactNode;
  disableArrow: boolean;
  arrow:
    | 'top-left'
    | 'top-center'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-center'
    | 'bottom-right'
    | 'left-top'
    | 'left-center'
    | 'left-bottom';
  open: boolean;
  [others: string]: any;
}

export default function PopOver({ children, arrow = 'top-right', disableArrow, sx, open, ...other }: PopOverProps) {
  return (
    <Popover
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      PaperProps={{
        sx: {
          overflow: 'inherit',
          backgroundColor: 'transparent',
          transition: 'all 0.2s ease',
          boxShadow: 0,
          ...sx,
        },
      }}
      {...other}
    >
      {!disableArrow && <ArrowStyle arrow={arrow} />}
      {children}
    </Popover>
  );
}
