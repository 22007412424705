import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'services/store/AppStore';
import {
  DraftEntityView,
  EntityView,
  PropertyValue,
  EntityViews,
  EntityPropertyDefinition,
  EntityViewField,
  TableViewColumnOrder,
} from 'services/models/domain/entityViews';
import { CHAT_RECORDS } from 'services/utils/dashboardHeaderUtils';

export interface EntityViewsState {
  entityViews: EntityViews;
  editMode: boolean;
}

const initialState: EntityViewsState = {
  entityViews: {
    views: [],
    propertyDefinitions: [],
    groupByPropertyDefinitionOid: undefined,
    visibleEntityFields: [],
    tableColumnOrder: [],
    allVisiblePropertyDefinitionOids: [],
  },
  editMode: false,
};

export type UpdatePropertyValuesPayload = {
  propertyValues: PropertyValue[];
  propertyDefinitionOid: { oid: string };
};

export type AppendPropertyValuesPayload = {
  propertyValues: PropertyValue[];
  propertyDefinitionOid: { oid: string };
};

export type AppendPropertyValuePayload = {
  propertyValue: PropertyValue;
};

export type updateVisiblePropertyDefinitionsPayload = {
  viewOid: string | undefined;
  visiblePropertyDefinitionOids: string[];
};

export type appendVisiblePropertyDefinitionPayload = {
  viewOid: string | undefined;
  visiblePropertyDefinitionOid: string;
};

export type removeVisiblePropertyDefinitionPayload = {
  viewOid: string | undefined;
  visiblePropertyDefinitionOid: string;
};

export type updateAllVisiblePropertyDefinitionOidsPayload = {
  allVisiblePropertyDefinitionOids: string[];
};

export type updateVisibleEntityFieldsPayload = {
  viewOid: string | undefined;
  visibleEntityFields: EntityViewField[];
};

export type UpdateTableColumnOrderPayload = {
  viewOid: string | undefined;
  tableColumnOrder: TableViewColumnOrder;
};

export type AddPropertyDefinitionPayload = {
  propertyDefinition: EntityPropertyDefinition;
};

export type UpdatePropertyDefinitionPayload = {
  propertyDefinitionOid: string;
  updatedName: string;
};

export type DeletePropertyDefinitionPayload = {
  propertyDefinitionOid: string;
};

const entityViewsSlice = createSlice({
  name: 'entityViews',
  initialState,
  reducers: {
    setEntityViews: (state, action: PayloadAction<EntityViews>) => {
      state.entityViews.views = action.payload.views;
      state.entityViews.propertyDefinitions = action.payload.propertyDefinitions;
      state.entityViews.groupByPropertyDefinitionOid = action.payload.groupByPropertyDefinitionOid;
      state.entityViews.visibleEntityFields = action.payload.visibleEntityFields;
      state.entityViews.tableColumnOrder = action.payload.tableColumnOrder;
      state.entityViews.allVisiblePropertyDefinitionOids = action.payload.allVisiblePropertyDefinitionOids;
    },
    updatePropertyValues: (state, action: PayloadAction<UpdatePropertyValuesPayload>) => {
      state.entityViews.views = state.entityViews.views.map((entityView) => {
        const updatedPropertyDefinitions = entityView.propertyDefinitions.map(
          (propertyDefinition: EntityPropertyDefinition) => {
            if (propertyDefinition.oid.oid === action.payload.propertyDefinitionOid.oid) {
              return {
                ...propertyDefinition,
                values: action.payload.propertyValues,
              };
            }
            return propertyDefinition;
          }
        );

        return {
          ...entityView,
          propertyDefinitions: updatedPropertyDefinitions,
        };
      });
      state.entityViews.propertyDefinitions.forEach((propertyDefinition) => {
        if (propertyDefinition.oid.oid === action.payload.propertyDefinitionOid.oid) {
          propertyDefinition.values = action.payload.propertyValues;
        }
      });
    },
    appendPropertyValues: (state, action: PayloadAction<AppendPropertyValuesPayload>) => {
      const { propertyDefinitionOid, propertyValues } = action.payload;
      state.entityViews.views.forEach((entityView) => {
        entityView.propertyDefinitions.forEach((propertyDefinition) => {
          if (propertyDefinition.oid.oid === propertyDefinitionOid.oid) {
            propertyDefinition.values = [...propertyDefinition.values, ...propertyValues];
          }
        });
      });
      state.entityViews.propertyDefinitions.forEach((propertyDefinition) => {
        if (propertyDefinition.oid.oid === propertyDefinitionOid.oid) {
          propertyDefinition.values = [...propertyDefinition.values, ...propertyValues];
        }
      });
    },
    appendPropertyValue: (state, action: PayloadAction<AppendPropertyValuePayload>) => {
      const { propertyValue } = action.payload;
      state.entityViews.views.forEach((entityView) => {
        entityView.propertyDefinitions.forEach((propertyDefinition) => {
          if (propertyDefinition.oid.oid === propertyValue.propertyDefinitionOid.oid) {
            propertyDefinition.values.push(propertyValue);
          }
        });
      });
      state.entityViews.propertyDefinitions.forEach((propertyDefinition) => {
        if (propertyDefinition.oid.oid === propertyValue.propertyDefinitionOid.oid) {
          propertyDefinition.values.push(propertyValue);
        }
      });
    },
    addEntityView: (state, action: PayloadAction<DraftEntityView>) => {
      state.entityViews.views = [...state.entityViews.views, action.payload];
    },
    updateEntityView: (
      state,
      action: PayloadAction<{ oid: { oid: string }; updatedEntityView: DraftEntityView | EntityView }>
    ) => {
      const { oid, updatedEntityView } = action.payload;
      state.entityViews.views = state.entityViews.views.map((entityView) => {
        if ('tempOid' in entityView && 'tempOid' in updatedEntityView) {
          if (entityView.tempOid.oid === updatedEntityView.tempOid.oid) {
            return {
              ...updatedEntityView,
              oid,
            };
          }
        }
        if ('oid' in entityView && 'oid' in updatedEntityView) {
          if (entityView.oid.oid === updatedEntityView.oid.oid) {
            return updatedEntityView;
          }
        }
        return entityView;
      });
    },
    removeEntityView: (state, action: PayloadAction<{ identifier: string }>) => {
      state.entityViews.views = state.entityViews.views.filter((entityView) => {
        if ('oid' in entityView) {
          return entityView.oid.oid !== action.payload.identifier;
        }
        if ('tempOid' in entityView) {
          return entityView.tempOid.oid !== action.payload.identifier;
        }
      });
    },
    clearEntityViews: (state) => {
      return { ...initialState };
    },
    updateEditMode: (state, action: PayloadAction<boolean>) => {
      state.editMode = action.payload;
    },
    updateVisiblePropertyDefinitions: (state, action: PayloadAction<updateVisiblePropertyDefinitionsPayload>) => {
      // If viewOid is undefined, it's the default(all chats) view
      if (action.payload.viewOid === undefined || action.payload.viewOid === CHAT_RECORDS) {
        state.entityViews.propertyDefinitions = state.entityViews.propertyDefinitions.map((pd) => ({
          ...pd,
          visible: action.payload.visiblePropertyDefinitionOids.includes(pd.oid.oid),
        }));
      } else {
        state.entityViews.views.forEach((view) => {
          if ((view as EntityView).oid.oid === action.payload.viewOid) {
            view.propertyDefinitions.forEach((pd) => {
              if (action.payload.visiblePropertyDefinitionOids.includes(pd.oid.oid)) {
                pd.visible = true;
              } else {
                pd.visible = false;
              }
            });
          }
        });
      }
    },
    appendVisiblePropertyDefinition: (state, action: PayloadAction<appendVisiblePropertyDefinitionPayload>) => {
      let foundPropDef;
      if (action.payload.viewOid === undefined || action.payload.viewOid === CHAT_RECORDS) {
        foundPropDef = state.entityViews.propertyDefinitions.find(
          (pd) => pd.oid.oid === action.payload.visiblePropertyDefinitionOid
        );
      } else {
        state.entityViews.views.forEach((view) => {
          if ('oid' in view && view.oid.oid === action.payload.viewOid) {
            foundPropDef = view.propertyDefinitions.find(
              (pd) => pd.oid.oid === action.payload.visiblePropertyDefinitionOid
            );
          }
        });
      }
      if (foundPropDef) {
        foundPropDef.visible = true;
      }
    },
    removeVisiblePropertyDefinition: (state, action: PayloadAction<removeVisiblePropertyDefinitionPayload>) => {
      let foundPropDef;
      if (action.payload.viewOid === undefined || action.payload.viewOid === CHAT_RECORDS) {
        foundPropDef = state.entityViews.propertyDefinitions.find(
          (pd) => pd.oid.oid === action.payload.visiblePropertyDefinitionOid
        );
      } else {
        state.entityViews.views.forEach((view) => {
          if ('oid' in view && view.oid.oid === action.payload.viewOid) {
            foundPropDef = view.propertyDefinitions.find(
              (pd) => pd.oid.oid === action.payload.visiblePropertyDefinitionOid
            );
          }
        });
      }
      if (foundPropDef) {
        foundPropDef.visible = false;
      }
    },
    updateAllVisiblePropertyDefinitionOids: (
      state,
      action: PayloadAction<updateAllVisiblePropertyDefinitionOidsPayload>
    ) => {
      state.entityViews.allVisiblePropertyDefinitionOids = action.payload.allVisiblePropertyDefinitionOids;
    },
    updateVisibleEntityFields: (state, action: PayloadAction<updateVisibleEntityFieldsPayload>) => {
      // If viewOid is undefined, it's the default(all chats) view
      if (action.payload.viewOid === undefined || action.payload.viewOid === CHAT_RECORDS) {
        state.entityViews.visibleEntityFields = action.payload.visibleEntityFields;
      } else {
        const view = state.entityViews.views.find((view) => {
          if ('oid' in view) {
            return view.oid.oid === action.payload.viewOid;
          }
          return false;
        });
        if (view) {
          view.visibleEntityFields = action.payload.visibleEntityFields;
        }
      }
    },
    updateTableColumnOrder: (state, action: PayloadAction<UpdateTableColumnOrderPayload>) => {
      if (action.payload.viewOid === undefined || action.payload.viewOid === CHAT_RECORDS) {
        state.entityViews.tableColumnOrder = action.payload.tableColumnOrder;
      } else {
        const view = state.entityViews.views.find((view) => {
          if ('oid' in view) {
            return view.oid.oid === action.payload.viewOid;
          }
          return false;
        });
        if (view) {
          view.tableColumnOrder = action.payload.tableColumnOrder;
        }
      }
    },
    addPropertyDefinition: (state, action: PayloadAction<AddPropertyDefinitionPayload>) => {
      const { propertyDefinition } = action.payload;
      state.entityViews.views.forEach((view) => view.propertyDefinitions.push(propertyDefinition));
      state.entityViews.propertyDefinitions.push(propertyDefinition);
    },
    updatePropertyDefinition: (state, action: PayloadAction<UpdatePropertyDefinitionPayload>) => {
      const { propertyDefinitionOid, updatedName } = action.payload;
      // in views
      state.entityViews.views.forEach((view) => {
        const viewPropertyDefinition = view?.propertyDefinitions.find(
          (propertyDefinition) => propertyDefinition.oid.oid === propertyDefinitionOid
        );
        if (viewPropertyDefinition) {
          viewPropertyDefinition.name = updatedName;
          viewPropertyDefinition.description = updatedName;
        }
      });
      //in propertyDefinitions
      const propertyDefinition = state.entityViews.propertyDefinitions.find(
        (propertyDefinition) => propertyDefinition.oid.oid === propertyDefinitionOid
      );
      if (propertyDefinition) {
        propertyDefinition.name = updatedName;
        propertyDefinition.description = updatedName;
      }
    },
    deletePropertyDefinition: (state, action: PayloadAction<DeletePropertyDefinitionPayload>) => {
      const { propertyDefinitionOid } = action.payload;
      //in views
      state.entityViews.views.forEach((view) => {
        view.propertyDefinitions = view.propertyDefinitions.filter(
          (propertyDefinition) => propertyDefinition.oid.oid !== propertyDefinitionOid
        );
      });

      //in propertyDefinitions
      state.entityViews.propertyDefinitions = state.entityViews.propertyDefinitions.filter(
        (propertyDefinition) => propertyDefinition.oid.oid !== propertyDefinitionOid
      );
    },
  },
});

export const {
  setEntityViews,
  clearEntityViews,
  updatePropertyValues,
  appendPropertyValues,
  addEntityView,
  removeEntityView,
  updateEntityView,
  appendPropertyValue,
  updateEditMode,
  updateVisiblePropertyDefinitions,
  updateVisibleEntityFields,
  updateTableColumnOrder,
  updateAllVisiblePropertyDefinitionOids,
  appendVisiblePropertyDefinition,
  removeVisiblePropertyDefinition,
  addPropertyDefinition,
  updatePropertyDefinition,
  deletePropertyDefinition,
} = entityViewsSlice.actions;

export const entityViewsState = (state: RootState) => state.entityViews;

export const entityViewsReducer = entityViewsSlice.reducer;
