import { Tooltip, IconButton, Modal, ModalDialog } from '@mui/joy';
import { FollowupActionRootStyle, tooltipStyle } from 'components/Details/FollowupComponent/styles';
import { FollowupAction, FollowupActionTypes, useChatActions } from 'services/hooks/useChatActions';
import useResponsive from 'services/hooks/useResponsive';
import UpdateFollowUpModal from 'page/modal/followUpModals/UpdateFollowUpModal/UpdateFollowupModal';
import 'theme/styles/calendar.css';
import UpdateFollowUpModalDesktop from 'page/modal/followUpModals/UpdateFollowUpModalDesktop/UpdateFollowupModalDesktop';
import { Followup } from 'services/models/domain/followUps';
import { ModalStyle } from 'page/modal/styles';
import { useSelector } from 'react-redux';
import { Conversation } from 'services/models/domain/conversation';
import { authState } from 'services/store/slices/authSlice';
import { Backdrop } from '@mui/material';
import { BackdropStyle } from 'page/modal/styles';

interface FollowupActionsProps {
  [others: string]: any;
  conversation: Conversation;
  open: boolean;
  handlePopupOpen: () => void;
  handlePopupClose: () => void;
  onFollowUpUpdated: (from?: Followup, to?: Followup) => void;
}

export default function FollowupActions({
  conversation,
  open,
  handlePopupOpen,
  handlePopupClose,
  onFollowUpUpdated,
  ...others
}: FollowupActionsProps) {
  const { ORG_ACTIONS } = useChatActions(conversation);
  const followUp = conversation?.nextFollowup;

  const isDesktop = useResponsive('up', 'sm');
  const { user } = useSelector(authState);
  const actions = followUp || open ? ORG_ACTIONS : [];

  const actionButton = (action: FollowupAction) => {
    switch (action.name) {
      case FollowupActionTypes.Reset:
        return <FollowupActionButton action={action} onClick={handlePopupOpen} />;
      case FollowupActionTypes.Done:
        return (
          <FollowupActionButton
            action={action}
            onClick={() => {
              const existingFollowUp = conversation.nextFollowup || undefined;
              onFollowUpUpdated(existingFollowUp, undefined);
            }}
          />
        );
      default:
        return;
    }
  };

  return (
    <>
      <FollowupActionRootStyle {...others}>
        {actions.map((action) => {
          return <div key={action.name}>{actionButton(action)}</div>;
        })}
      </FollowupActionRootStyle>
      <Modal open={open} onClose={handlePopupClose}>
        <ModalDialog sx={ModalStyle}>
          {isDesktop ? (
            <UpdateFollowUpModalDesktop
              handleClose={handlePopupClose}
              conversation={conversation}
              user={user!}
              onFollowUpUpdated={onFollowUpUpdated}
            />
          ) : (
            <UpdateFollowUpModal
              handleClose={handlePopupClose}
              conversation={conversation}
              user={user!}
              onFollowUpUpdated={onFollowUpUpdated}
            />
          )}
        </ModalDialog>
      </Modal>
      <Backdrop open={open} sx={BackdropStyle} onClick={handlePopupClose} />
    </>
  );
}

interface FollowupActionButton {
  action: FollowupAction;
  onClick: () => void;
}

function FollowupActionButton(props: FollowupActionButton) {
  const { action, onClick } = props;

  return (
    <Tooltip
      title={action.title}
      arrow
      placement="top"
      enterDelay={400}
      enterTouchDelay={400}
      enterNextDelay={400}
      componentsProps={{ ...tooltipStyle }}
    >
      <IconButton size="sm" variant="plain" color="primary" sx={FollowupActionButtonStyle.button} onClick={onClick}>
        <img src={action.icon} width={16} height={16} alt="" />
      </IconButton>
    </Tooltip>
  );
}

const FollowupActionButtonStyle = {
  button: {
    borderRadius: '0px',
    height: '24px',
    width: '24px',
    '&:hover': { backgroundColor: 'transparent' },
    border: '0px transparent',
  },
};
