import { Box, Button, Typography } from '@mui/joy';
import Snackbar from '@mui/material/Snackbar';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { IconsSvg } from 'assets/icons';
import Translations from 'const/translations/en';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useConversations } from 'services/hooks/useConversations';
import { sideNavState } from 'services/store/slices/sideNavSlice';
import { colors } from 'theme/colors';

const ArchiveSnackBar = () => {
  const apiRef = useGridApiContext();
  const { setArchiveStateForSelectedConversations } = useConversations();
  const [open, setOpen] = useState(false);
  const { selectedItem } = useSelector(sideNavState);
  const selectedRows = apiRef.current.getSelectedRows().size;
  const message = selectedItem.title === 'Archived Chats' ? Translations.UNARCHIVE : Translations.ARCHIVE;

  useEffect(() => {
    setOpen(selectedRows > 0);
  }, [selectedRows]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      message={
        <Typography level="tiny+" sx={{ display: 'flex', alignItems: 'center', gap: '3.5px', marginLeft: '4px' }}>
          {selectedRows === 1 ? `${selectedRows} Chat Selected` : `${selectedRows} Chats Selected`}
        </Typography>
      }
      ContentProps={{
        sx: styles.snackbar,
      }}
      action={
        <Box>
          <Button
            size="sm"
            sx={styles.actionButton1}
            startDecorator={
              <IconsSvg.ic_archive
                width={14}
                height={14}
                stroke={'white'}
                style={{ marginTop: '1px', marginLeft: '1px' }}
              />
            }
            onClick={() => {
              const selectedConversations = apiRef.current.getSelectedRows();
              const conversationRecordOids = Array.from(selectedConversations, ([key]) => key.toString());
              setArchiveStateForSelectedConversations(conversationRecordOids, selectedItem.title !== 'Archived Chats');
            }}
          >
            <Typography level="tiny" sx={{ display: 'flex', alignItems: 'center', marginLeft: '-3px' }}>
              {message}
            </Typography>
          </Button>
          <Button
            size="sm"
            sx={styles.actionButton2}
            startDecorator={
              <IconsSvg.ic_close width={17} height={17} stroke={'white'} style={{ transform: 'translateY(1px)' }} />
            }
            onClick={() => {
              setOpen(false);
              apiRef.current.selectRows(apiRef.current.getAllRowIds(), false);
            }}
          ></Button>
        </Box>
      }
    />
  );
};

export default ArchiveSnackBar;

const styles = {
  actionButton1: {
    alignSelf: 'center',
    borderRadius: 20,
    color: 'white',
    opacity: 1,
    padding: '3px 9px 3px 5px',
    fontWeight: 100,
    fontSize: (theme: any) => theme.typography.tiny,
    minHeight: '24px',
    '&:hover': {
      border: `1px solid rgba(255, 255, 255, 0.20)`,
      background: `rgba(255, 255, 255, 0.20)`,
      boxshadow: `0px 0px 2px 0px rgba(0, 0, 0, 0.03)`,
      cursor: 'pointer',
    },
    marginRight: '2px',
    marginTop: '10px',
    cursor: 'pointer',
    border: `1px solid rgba(255, 255, 255, 0.20)`,
    background: `rgba(255, 255, 255, 0.20)`,
    boxshadow: `0px 0px 2px 0px rgba(0, 0, 0, 0.03)`,
  },
  actionButton2: {
    alignSelf: 'flex-end',
    borderRadius: 20,
    color: colors.neutral[90],
    background: 'transparent',
    marginRight: '2px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    marginX: '5px',
    padding: '0',
    minHeight: '24px',
    '&:hover': {
      backgroundColor: 'transparent',
      border: 'none',
      marginX: '5px',
      padding: '0',
    },
  },
  snackbar: {
    marginBottom: '-12px',
    color: 'primary.light',
    backgroundColor: colors.primary[60],
    minWidth: 'auto !important',
    height: 'auto',
    padding: ' 0 13px',
    borderRadius: '24px',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.07)',
    border: '1px solid #fff',
    maxWidth: 'fit-content',
    '& .MuiSnackbarContent-message': {
      padding: '0',
    },
  },
};
