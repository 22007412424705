import { FormHelperText, FormLabel } from '@mui/joy';
import { styled } from '@mui/material';
import { colors } from 'theme/colors';

export const StyledLabel = styled(FormLabel, {
  shouldForwardProp: (prop) => prop !== 'inputSize' && prop !== 'error',
})(({ inputSize, error }: { inputSize?: string; error?: boolean }) => ({
  fontSize: inputSize === 'lg' ? '16px' : inputSize === 'md' ? '14px' : inputSize === 'sm' ? '14px' : '12px',
  color: !error ? `${colors.neutral[60]} !important` : `${colors.warning} !important`,
}));

export const StyledUnderText = styled(FormHelperText, {
  shouldForwardProp: (prop) => prop !== 'error',
})(({ error }: { error?: boolean }) => ({
  fontSize: '12px',
  color: !error ? `${colors.neutral[40]} !important` : `${colors.warning} !important`,
}));
