import { Box, Divider, Link, Sheet, Stack, Typography } from '@mui/joy';
import Icons from 'assets/icons';
import { SnackBarVariantEnum } from 'components/SnackBar/SnackBar';
import UIButton from 'components/ui-library/Button/UIButton';
import AppLinks from 'const/links';
import { useSelector } from 'react-redux';
import useResponsive from 'services/hooks/useResponsive';
import useSnackbarContext from 'services/hooks/useSnackbar';
import { authState } from 'services/store/slices/authSlice';
import { createMailLink } from 'services/utils/helpers';

const ConnectBot = () => {
  const isDesktop = useResponsive('up', 'md');
  const { user } = useSelector(authState);
  const { setSnackbarOpenStatus } = useSnackbarContext();

  const handleSnackBar = (message: string, variant: SnackBarVariantEnum, icon: string) => {
    setSnackbarOpenStatus(true, message, variant, icon);
    setTimeout(() => {
      setSnackbarOpenStatus(false, '', SnackBarVariantEnum.info);
    }, 5000);
  };

  return (
    <Stack>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          pb: 2,
          alignItems: { xs: 'flex-start', sm: 'center' },
          p: '16px',
        }}
        gap={1}
      >
        <Typography level="regular" sx={{ color: 'var(--joy-palette-info-plainBg)' }}>
          To get started, add the 3RM bot
        </Typography>
        <UIButton
          size="md"
          onClick={() => {
            if (!user?.chatBotLinkInfo?.link) {
              handleSnackBar(
                'Something seems to be wrong 🧐, please contact support',
                SnackBarVariantEnum.error,
                Icons.ic_warning_snackbar
              );
            } else {
              window.open(user?.chatBotLinkInfo?.link, '_blank');
            }
          }}
        >
          {isDesktop ? 'Open @Official_3RM_Bot' : 'Open bot in Telegram'}
        </UIButton>
      </Box>
      <Divider sx={{ border: '.7px dashed #fff', background: 'var(--joy-palette-info-plainBorder)' }} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          p: '16px',
          alignItems: { xs: 'flex-start', sm: 'center' },

          mb: 1,
        }}
      >
        <Box sx={{ mr: { xs: 0, sm: 2 } }}>
          <Typography
            startDecorator={<img src={Icons.ic_arrow_forward} alt="ic_arrow_forward" />}
            sx={{ color: 'info.dark', mb: '4px' }}
            level="micro"
          >
            In Telegram,
          </Typography>
          <Typography level="regular" sx={{ color: 'var(--joy-palette-info-plainBg)' }}>
            Click “Send Message"
          </Typography>
        </Box>

        <Sheet
          sx={{
            borderRadius: 20,
            width: { xs: 180, sm: 280 },
            flexBasis: { xs: 0 },
            boxShadow: 'none',
            border: 'none',
            mt: { xs: 1, sm: 0 },
          }}
        >
          <img
            src={require('assets/images/telegram.png')}
            alt="screenshot"
            style={{ borderRadius: 10, width: isDesktop ? 280 : 180, border: '1px solid #ECF0F3' }}
          />
          <Typography level="legalMicro" sx={{ color: 'info.main' }}>
            Screenshot
          </Typography>
        </Sheet>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          pb: 2,
          alignItems: { xs: 'flex-start', sm: 'center' },
          mb: 1,
          p: '16px',
        }}
      >
        <Box sx={{ mr: { xs: 0, sm: 2 } }}>
          <Typography
            startDecorator={<img src={Icons.ic_arrow_forward} alt="ic_arrow_forward" />}
            sx={{ color: 'info.dark', mb: '4px' }}
            level="micro"
          >
            In the 3RM_Bot chat,
          </Typography>
          <Typography level="regular" sx={{ color: 'var(--joy-palette-info-plainBg)' }}>
            Click “Start”
          </Typography>
        </Box>
        <Sheet
          sx={{
            borderRadius: 'md',
            width: { xs: 180, sm: 280 },
            flexBasis: { xs: 0 },
            sm: 280,
            boxShadow: 'none',
            border: 'none',
            mt: { xs: 1, sm: 0 },
          }}
        >
          <img
            src={require('assets/images/telegram_start.png')}
            alt="screenshot"
            style={{ borderRadius: 10, width: isDesktop ? 280 : 180 }}
          />
          <Typography level="legalMicro" sx={{ color: 'info.main' }}>
            Screenshot
          </Typography>
        </Sheet>
      </Box>

      <Divider sx={{ backgroundColor: '#F1F4F6' }} />

      <Sheet sx={{ pt: 1, p: '16px' }}>
        <Typography level="tiny" sx={{ color: 'info.dark' }}>
          Having trouble? Check out our{' '}
          <Link
            href={AppLinks.howToSetTelegramBot}
            target={'_blank'}
            sx={{ color: '#3C7ADE', textDecoration: 'underline', textDecorationColor: '#3C7ADE' }}
          >
            support article
          </Link>{' '}
          on connecting your Telegram account or{' '}
          <Link
            href={createMailLink(AppLinks.supportEmail)}
            sx={{ color: '#3C7ADE', textDecoration: 'underline', textDecorationColor: '#3C7ADE' }}
          >
            get in touch
          </Link>{' '}
          with our support team.
        </Typography>
      </Sheet>
    </Stack>
  );
};

export default ConnectBot;
