import { BUTTON_COLORS } from 'components/ui-library/Button/constant';

export const getSpinnerColor = (variant: string | undefined, color: string | undefined) => {
  if (variant === 'solid' || variant === undefined) {
    return BUTTON_COLORS.solid.primary.text;
  } else if (variant === 'soft') {
    if (color === 'primary' || color === undefined) {
      return BUTTON_COLORS.soft.primary.text;
    } else if (color === 'info') {
      return BUTTON_COLORS.soft.info.text;
    }
  } else if (variant === 'outlined') {
    if (color === 'primary') {
      return BUTTON_COLORS.outlined.primary.text;
    }
  } else if (variant === 'plain') {
    if (color === 'primary') {
      return BUTTON_COLORS.plain.primary.text;
    } else if (color === 'warning') {
      return BUTTON_COLORS.plain.warning.text;
    }
  }
};
