import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContactDetails } from 'services/models/domain/contact';
import { Conversation } from 'services/models/domain/conversation';
import { setSidebarEntityOid } from 'services/store/slices/sidebarEntityDetailsSlice';
import { EntityDetailsType, sidebarState } from 'services/store/slices/sidebarSlice';
import {
  tableEntitiesNavigationState,
  setEntityOids,
  goNextEntity,
  goPreviousEntity,
  toggleFetchList,
  goFirstEntity,
  goLastEntity,
  isTableNavigationStart,
  isTableNavigationEnd,
} from 'services/store/slices/tableEntitiesNavigationSlice';

export const useNavigationTableEntites = () => {
  const dispatch = useDispatch();
  const { entityType } = useSelector(sidebarState);
  const { entityOid, fetchList } = useSelector(tableEntitiesNavigationState);
  const isNavigationStart = useSelector(isTableNavigationStart);
  const isNavigationEnd = useSelector(isTableNavigationEnd);

  const setTableNavigation = (
    entities: Conversation[] | ContactDetails[],
    type: EntityDetailsType,
    hasNextPage: boolean,
    hasPreviousPage: boolean
  ) => {
    const entityOids = entities.map((entity) =>
      type === EntityDetailsType.contactDetails ? (entity as ContactDetails).participantOid.oid : entity.oid.oid
    );
    dispatch(setEntityOids({ entityOids, hasNextList: hasNextPage, hasPreviousList: hasPreviousPage }));
    if (fetchList) {
      dispatch(toggleFetchList());
      if (entities.length > 1) {
        if (isNavigationStart) {
          dispatch(goLastEntity());
        }
        if (isNavigationEnd) {
          dispatch(goFirstEntity());
        }
      }
    }
  };

  const goToNextEntity = () => {
    if (!isNavigationEnd) {
      dispatch(goNextEntity());
      return;
    }
    dispatch(toggleFetchList());
  };

  const goToPreviousEntity = () => {
    if (!isNavigationStart) {
      dispatch(goPreviousEntity());
      return;
    }
    dispatch(toggleFetchList());
  };

  useEffect(() => {
    dispatch(setSidebarEntityOid({ oid: entityOid, type: entityType }));
  }, [entityOid]);

  return {
    setTableNavigation,
    goToNextEntity,
    goToPreviousEntity,
  };
};
