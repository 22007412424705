import { useDispatch, useSelector } from 'react-redux';

import { DropResult } from '@hello-pangea/dnd';
import {
  PropertyValue,
  UpdatePropertyOptionValueInput,
  UpdatePropertyOptionValuesInput,
  useUpdatePropertyOptionValuesMutation,
} from 'services/models/api/generated';
import { EntityPropertyDefinition } from 'services/models/domain/entityViews';
import { updateOrdering } from 'services/store/slices/conversationPropertiesSlice';
import { chatFilters, setReload } from 'services/store/slices/conversationsFilterSlice';

export const useUpdatePropertyOptionOrder = (
  refetch: () => Promise<void>,
  propertyDefinition: EntityPropertyDefinition
) => {
  const [updatePropertyOptions] = useUpdatePropertyOptionValuesMutation();
  const dispatch = useDispatch();
  const chatFilter = useSelector(chatFilters);

  const updateOrders = async (propertyValues: PropertyValue[]) => {
    const updates: UpdatePropertyOptionValueInput[] = propertyValues
      .map((pv) => {
        if ('ordering' in pv) {
          const update: UpdatePropertyOptionValueInput = {
            propertyOptionOid: pv.oid.oid,
            ordering: pv.ordering,
            propertyDefinitionOid: propertyDefinition.oid.oid,
          };
          return update;
        } else {
          return undefined;
        }
      })
      .filter((a) => !!a) as UpdatePropertyOptionValueInput[];

    const input: UpdatePropertyOptionValuesInput = {
      updates,
    };
    await updatePropertyOptions({
      variables: { input },
      onCompleted: (res) => {
        const isSuccess = res?.updatePropertyOptionValues.status.isSuccess;

        if (isSuccess) {
          refetch();
        }
      },
    });
  };

  const handleDragEnd = (propertyValues: PropertyValue[]) => async (result: DropResult) => {
    const { destination, source, draggableId } = result;
    // If dropped outside droppable, destination will be null. Then return
    if (!destination) {
      return;
    }
    // If dropped at the same place, return
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const orderingTo = Number(destination.index) - 1;
    const orderingFrom = Number(source.index) - 1;
    const arraymove = (arr: any[], fromIndex: number, toIndex: number) => {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    };
    const updated = [...propertyValues];
    arraymove(updated, orderingFrom, orderingTo);
    const sortedAndUpdated = updated.map((pv, index) => {
      if ('ordering' in pv) {
        return {
          ...pv,
          ordering: index + 1,
        };
      } else {
        return pv;
      }
    });

    dispatch(updateOrdering({ updatedValues: sortedAndUpdated }));
    const sortOnPropertyDefs = chatFilter.input.sortOn.sortOnProperties.map((p) => p.propertyDefinitionOid);
    if (sortOnPropertyDefs.includes(propertyDefinition.oid.oid)) {
      dispatch(setReload({ reload: true }));
    }

    await updateOrders(sortedAndUpdated);
  };

  return {
    handleDragEnd,
  };
};
