import Box, { BoxProps } from '@mui/joy/Box';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { SxProps } from '@mui/joy/styles/types';

interface PageProps {
  box?: BoxProps;
  title?: string;
  meta?: JSX.Element;
  children?: React.ReactNode;
  sx?: SxProps;
}

const defaults: PageProps = {
  title: 'W3RM',
};

const Page: React.FC<PageProps> = (props) => (
  <>
    <Helmet>
      <title>{`${props.title}`}</title>
      {props.meta}
    </Helmet>
    <Box component="main" className="Main" {...props.box} sx={{ p: 0, ...props.sx }}>
      {props.children}
    </Box>
  </>
);

Page.defaultProps = defaults;

export default Page;
