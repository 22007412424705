import { Theme } from '@mui/joy';
import { useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailsDrawer, { DrawerAnchor } from 'components/Details/DetailsDrawer';
import { EntityDetailsType, sidebarState } from 'services/store/slices/sidebarSlice';
import { useSidebar } from 'services/hooks/useSidebar';
import { useEffect } from 'react';
import { sidebarStyles } from 'page/dashboard/sidebar/styles';
import SidebarContactDetail from './SidebarContactDetail';
import SidebarChatDetails from './SidebarChatDetails';

const renderSidebarContent = (entityType: EntityDetailsType | undefined) => {
  switch (entityType) {
    case EntityDetailsType.contactDetails:
      return <SidebarContactDetail />;
    case EntityDetailsType.chatDetails:
      return <SidebarChatDetails />;
    default:
      return <></>;
  }
};

export default function Sidebar() {
  const { openDrawer, entityType } = useSelector(sidebarState);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { closeSidebar } = useSidebar();
  const handleKeyDown = (event: { code: string }) => {
    if (event.code === 'Escape') {
      closeSidebar();
    }
  };

  useEffect(() => {
    if (openDrawer) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [openDrawer]);

  return (
    <DetailsDrawer
      isOpen={openDrawer}
      anchor={DrawerAnchor.RIGHT}
      style={sidebarStyles(isMobile).drawer}
      onClose={closeSidebar}
    >
      {renderSidebarContent(entityType)}
    </DetailsDrawer>
  );
}
