import { Box, Chip, Typography } from '@mui/joy';
import { SxProps } from '@mui/joy/styles/types';
import Icons from 'assets/icons';
import Translations from 'const/translations/en';
import { getIcon } from 'page/chats/ChatV2Properties';
import { NavigationItem } from 'page/dashboard/header/SideNav';
import { CONNECT_TELEGRAM, iconMapping } from 'services/utils/dashboardHeaderUtils';
import { toKebabCase } from 'services/utils/helpers';
import { colors } from 'theme/colors';
import { Flex1 } from 'theme/flex';

interface NavigationItemChipProps {
  item: NavigationItem;
  isSelected?: boolean;
  isOpen: boolean;
  onClick: (item: NavigationItem) => void;
  sx?: SxProps;
}

export default function NavigationItemChip(props: NavigationItemChipProps) {
  const { item, isSelected, isOpen, onClick, sx } = props;
  const { title, icon, id, badge, secondaryIcon } = item;
  const Icon = getIcon(icon);
  const SecondaryIcon = iconMapping[item.secondaryIcon ?? ''];

  const handleOnClick = () => {
    onClick(item);
  };

  const endDecorator = () => (
    <>
      {badge && (
        <Box sx={NavigationItemChipStyles.badgeStyle}>
          <Typography level="micro" sx={NavigationItemChipStyles.badgeText(badge.color)} textColor={colors.white}>
            {badge.text}
          </Typography>
        </Box>
      )}
      {secondaryIcon && (
        <SecondaryIcon height={18} width={18} stroke={colors.neutral[60]} style={NavigationItemChipStyles.endIcon} />
      )}
    </>
  );

  return (
    <Box
      sx={{ ...NavigationItemChipStyles.chipContainer(!!isSelected, isOpen), ...sx } as SxProps}
      key={`${item.id}`}
      id={`section-item-${toKebabCase(item.id || 'undefined')}`}
      role="listitem"
    >
      <Chip
        variant={'plain'}
        color={'neutral'}
        onClick={handleOnClick}
        sx={Flex1}
        componentsProps={NavigationItemChipStyles.chipComponent}
        startDecorator={
          id === CONNECT_TELEGRAM ? (
            <img
              src={Icons.ic_tg_sync_connected}
              width={15}
              height={15}
              alt={Translations.TELEGRAM_SYNC_ICON_ALT}
              style={NavigationItemChipStyles.syncIcon}
            />
          ) : (
            <Icon
              height={18}
              width={18}
              stroke={isSelected ? colors.neutral['80'] : colors.neutral[50]}
              style={NavigationItemChipStyles.icon(isOpen)}
            />
          )
        }
        endDecorator={isOpen && endDecorator()}
      >
        {isOpen && (
          <Typography level="inherit" sx={NavigationItemChipStyles.title(!!isSelected)}>
            {title}
          </Typography>
        )}
      </Chip>
    </Box>
  );
}

const NavigationItemChipStyles = {
  chipContainer: (selected: boolean, openDrawer: boolean): SxProps => ({
    backgroundColor: selected ? colors.neutral['10'] : '',
    borderRadius: '10px',
    border: `1px solid ${selected ? colors.neutral['15'] : colors.transparent}`,
    width: openDrawer ? '98%' : undefined,
    ':hover': {
      backgroundColor: colors.neutral['5'],
    },
  }),
  chipComponent: {
    action: {
      sx: {
        width: '100%',
      },
    },
    label: {
      sx: {
        width: '100%',
        overflow: 'hidden',
      },
    },
    startDecorator: {
      sx: {
        marginRight: 0,
      },
    },
  },
  syncIcon: {
    margin: '2px 8px 0 0',
  },
  icon: (isOpen: boolean) => ({
    margin: !isOpen ? '0 2px 0 0px' : '0 8px 0 -2px',
  }),
  badgeStyle: {
    borderRadius: '10px',
    minWidth: '16px',
    height: '16px',
    marginRight: '-3px',
  },
  badgeText: (color: string): SxProps => ({
    backgroundColor: color,
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 4px 0px',
  }),
  endIcon: {
    marginTop: '2px',
    marginRight: '-6px',
  },
  title: (isSelected: boolean) => ({
    lineHeight: '18px',
    letterSpacing: '-0.14px',
    paddingBottom: '2px',
    color: isSelected ? colors.neutral['80'] : colors.neutral[70],
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),
};
