import { Box, Divider, Modal, ModalClose, Sheet, Stack, Switch, Typography } from '@mui/joy';
import Icons from 'assets/icons';
import { ModalStyle } from 'page/modal/styles';

interface Props {
  isOpen: boolean;
  setIsOpen: Function;
}

const SettingsModal = ({ isOpen, setIsOpen }: Props) => {
  return (
    <Modal open={isOpen} onClose={(event, reason) => setIsOpen(false)} sx={ModalStyle} hideBackdrop>
      <Sheet
        variant="outlined"
        sx={{
          width: { xs: '80%', md: '440px' },

          borderRadius: '8px',
        }}
      >
        <Box
          sx={{
            flexDirection: 'row',
            display: 'flex',
            px: 3,
            py: 1.5,
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography id="modal-title" level="large" textColor="inherit">
            Settings
          </Typography>
          <ModalClose />
        </Box>
        <Divider sx={{ border: '1px solid var(--joy-palette-primary-plainBorder)' }} />

        <Stack
          spacing={2}
          sx={{
            px: 3,
            py: 1.5,
          }}
        >
          <Typography level="regular">Follow-up Notifications</Typography>
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Box>
              <Typography level="tiny">Email</Typography>
              <Typography sx={{ color: 'info.main' }} level="micro">
                <img src={Icons.ic_arrow_forward} alt="arrow" />
                jan@3rm.co
              </Typography>
            </Box>
            <Switch checked={true} />
          </Stack>
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Box>
              <Typography level="tiny">Telegram</Typography>
              <Typography sx={{ color: 'info.main' }} level="micro">
                <img src={Icons.ic_arrow_forward} alt="arrow" />
                @janrosen
              </Typography>
            </Box>
            <Switch checked={true} />
          </Stack>
        </Stack>
      </Sheet>
    </Modal>
  );
};

export default SettingsModal;
