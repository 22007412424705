import React from 'react';
import { LazyMotion } from 'framer-motion';

const loadFeatures = () => import('components/features').then((res) => res.default);

interface Props {
  children: React.ReactNode;
}

const MotionLazyContainer = ({ children }: Props) => (
  <LazyMotion strict features={loadFeatures}>
    {children}
  </LazyMotion>
);

export default MotionLazyContainer;
