export const CONVERSATION_FILTER_STORAGE_KEY = 'CONVERSATION_FILTER';

export enum PartyType {
  Organization = 'ORGANIZATION',
  User = 'USER',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SortConversationsOnPropertyType {
  LastFollowupDate = 'LAST_FOLLOWUP_DATE',
  LastMessageDate = 'LAST_MESSAGE_DATE',
  DateAdded = 'DATE_ADDED',
}

export type FilterItem = {
  search: string;
  filterOn: FilterItemFilterOn;
  sortOn: FilterItemSortOn[];
};

export type FilterItemFilterOn = {
  favorite: boolean;
  archived: boolean;
  partyScope?: PartyType;
  propertyValues: FilterItemFilterOnPropertyValue[];
  folderOids: string[];
};

export type FilterItemFilterOnPropertyValue = {
  oid: string;
  propertyDefinitionOid: string;
};

export type FilterItemSortOn = {
  propertyDefinitionOid?: string;
  sortDirection: SortDirection;
  onPropertyType?: SortConversationsOnPropertyType;
};
