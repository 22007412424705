import { Sheet } from '@mui/joy';
import { styled } from '@mui/joy/styles';

export const FollowupActionRootStyle = styled('div')(({ theme }) => ({
  height: 40,
  zIndex: 0,
  opacity: 0,
  margin: 'auto',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  top: theme.spacing(1),
  right: theme.spacing(1),
  bottom: theme.spacing(1),
  justifyContent: 'right',
  padding: theme.spacing(0, 0.75),
  transition: 'width .35s ease-in-out',
}));

export const ButtonRow = styled(Sheet)(({ theme }) => ({
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.vars.palette.text.tertiary,
  maxWidth: '400px',
}));

export const tooltipStyle = {
  root: {
    sx: { backgroundColor: 'secondary.dark' },
  },
  arrow: {
    sx: {
      position: 'relative',

      '&::before': {
        backgroundColor: 'secondary.dark',
        content: '""',
        display: 'block',
        position: 'absolute',
        width: 8,
        height: 8,
        top: 0.5,
        transform: 'rotate(45deg)',
        left: 'calc(50% - 5px)',
        borderRadius: '1px',
        boxShadow: 'none',
      },
      '&::after': {
        backgroundColor: 'secondary.dark',
        content: '""',
        display: 'block',
        position: 'absolute',
        width: 8,
        height: 8,
        top: 0.5,
        transform: 'rotate(45deg)',
        left: 'calc(50% - 5px)',
        borderRadius: '1px',
        boxShadow: 'none',
      },
    },
  },
};
