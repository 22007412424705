import { Typography } from '@mui/joy';
import { CellSecondText, CellText } from 'components/Chats/ChatTableV2/TableCell/customCells/cellsContent/styles';
import CustomAvatar from 'components/CustomAvatar';
import { avatarFromName, generateColorFromOid } from 'services/utils/helpers';
import { ReactComponent as SidebarIcon } from 'assets/icons/ic_sidebar.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/ic_telegram.svg';
import { Stack, SxProps } from '@mui/material';
import { DetailsIconStyle, TelegramIconStyle } from 'components/Kanban/styles';
import { DoubleLineTooltipContents } from 'components/TooltipContents';
import Translations from 'const/translations/en';
import TitleCard from 'components/TitleCard';
import PageLink from 'components/PageLink';
import { RouterPaths } from 'services/hooks/useNavigation';
interface ContactCellContentProps {
  oid: string;
  name: string;
  link?: string;
  handle: string;
  onClick: () => void;
  avatar?: string;
  nameSx?: SxProps;
  handleSx?: SxProps;
  avatarSize?: number;
}

export default function ContactCellContent({
  oid,
  name,
  link,
  handle,
  avatar,
  onClick,
  nameSx,
  handleSx,
  avatarSize,
}: ContactCellContentProps) {
  return (
    <TitleCard
      title={
        <Stack direction="row" component="span" alignItems="center">
          <CustomAvatar
            userInitials={avatarFromName(name)}
            index={generateColorFromOid(oid)}
            imgUrl={avatar}
            size={avatarSize || 28}
            solid={true}
          />
          <Stack direction="column" ml={1.5} sx={{ flex: '1', minWidth: 0 }}>
            <PageLink to={RouterPaths.createDetailsContactPath(oid)} openInNewTab>
              <Typography level="tiny" sx={nameSx ? ({ ...CellText, ...nameSx } as SxProps) : CellText}>
                {name}
              </Typography>
            </PageLink>
            <Typography level="tiny" sx={handleSx ? ({ ...CellSecondText, ...handleSx } as SxProps) : CellSecondText}>
              {handle}
            </Typography>
          </Stack>
        </Stack>
      }
      leftComponent={{
        icon: TelegramIcon,
        description: !!link ? (
          Translations.OPEN_TELEGRAM_TOOLTIP
        ) : (
          <DoubleLineTooltipContents
            primaryText={Translations.NO_CONTACT_LINK_TOOLTIP_PRIMARY_MESSAGE}
            secondaryText={Translations.NO_CONTACT_LINK_TOOLTIP_SECONDARY_MESSAGE}
          />
        ),
        css: TelegramIconStyle(!!link),
        link: link,
        showFilledIcon: true,
      }}
      rightComponent={{
        icon: SidebarIcon,
        description: Translations.OPEN_SIDEBAR_TOOLTIP,
        css: DetailsIconStyle,
        link: onClick,
      }}
    />
  );
}
