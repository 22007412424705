import { Box, IconButton, Typography } from '@mui/joy';
import Icons from 'assets/icons';
import { generateColorFromOid } from 'services/utils/helpers';
import CustomAvatar from 'components/CustomAvatar';
import MenuOption from 'components/ui-library/chat/CellMenu/CellMenuOption/CellMenuOption';
import {
  CloseIconSx,
  NameSx,
  SelectedMenuItemSx,
  UserBoxSx,
} from 'components/Chats/ChatTableV2/TableCell/customCells/UserProperty/styles';
import { getUsersInitials } from 'services/utils/userPropertyUtils';
import { UsersMenuProps } from 'components/Chats/ChatTableV2/TableCell/customCells/UserProperty/UsersMenu/interface';
import UserMenuItem from 'components/Chats/ChatTableV2/TableCell/customCells/UserProperty/UsersMenu/UserMenuItem/UserMenuItem';
import { TenantUserPropertyValue } from 'services/models/domain/entityViews';

const UsersMenu = ({
  selectedUsers,
  definitionValues,
  handleMenuClose,
  handleSelect,
  handleUnset,
  authUser,
}: UsersMenuProps) => {
  const handleUnsetClick = (propertyValue: TenantUserPropertyValue) => {
    handleUnset(propertyValue.oid.oid);
    handleMenuClose();
  };

  const renderUser = (propertyValue: TenantUserPropertyValue) => {
    return (
      <MenuOption key={propertyValue.oid.oid} sx={SelectedMenuItemSx}>
        <Box sx={UserBoxSx}>
          <CustomAvatar
            index={generateColorFromOid(propertyValue.oid.oid)}
            size={16}
            userInitials={getUsersInitials(propertyValue.fullName, propertyValue.fullName)}
            imgUrl={propertyValue.avatar}
          />
          <Typography level="tiny" sx={NameSx}>
            {propertyValue.fullName}
          </Typography>
        </Box>

        <IconButton
          size="sm"
          variant="plain"
          color="primary"
          onClick={() => handleUnsetClick(propertyValue)}
          sx={CloseIconSx}
        >
          <img width={16} height={16} alt={'close'} src={Icons.ic_close} />
        </IconButton>
      </MenuOption>
    );
  };

  return (
    <>
      {selectedUsers.map(renderUser)}
      {definitionValues.map((definition) => (
        <UserMenuItem
          key={definition.oid.oid}
          user={definition}
          authUser={authUser}
          handleSelect={() => handleSelect(definition.oid.oid)}
          handleMenuClose={handleMenuClose}
        />
      ))}
    </>
  );
};

export default UsersMenu;
