import { FilterOptionsState, createFilterOptions } from '@mui/material';
import { TypeAheadEntity } from 'components/TypeAheadInput';
import _ from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePropertyValue } from 'services/hooks/usePropertyValue';
import { AddPropertyOptionValueInput, useAddPropertyOptionValueMutation } from 'services/models/api/generated';
import { EntityType } from 'services/models/domain/entityTypes';
import {
  EntityPropertyDefinition,
  OptionPropertyValue,
  PropertyDefinitionDataType,
  PropertyValue,
  TenantUserPropertyValue,
} from 'services/models/domain/entityViews';
import { conversationsState } from 'services/store/slices/conversationsSlice';
import { appendPropertyValue } from 'services/store/slices/entityViewsSlice';
import { useSafeGridApiContext } from './useSafeGridApiContext';

const filter = createFilterOptions<TypeAheadEntity<OptionPropertyValue | TenantUserPropertyValue>>();

export function useTypeAheadPropertyValues(
  entityId: string,
  initialValueOids: string[],
  propertyDefinition: EntityPropertyDefinition
) {
  const dispatch = useDispatch();
  const apiRef = useSafeGridApiContext();

  const [addPropertyOption] = useAddPropertyOptionValueMutation();
  const { handleUpdatePropertyOption, handleUpdatePropertyOptionForBatchEntities } = usePropertyValue();
  const { propertiesSelectorOpen } = useSelector(conversationsState);

  const updateEntity = {
    entityType: EntityType.Conversation,
    id: entityId,
  };

  const createOption = useCallback(async (option: TypeAheadEntity<OptionPropertyValue | TenantUserPropertyValue>) => {
    if (option.type === 'TENANT_USER') {
      return;
    }
    const valueMappedToApi: AddPropertyOptionValueInput = {
      color: option.color,
      propertyDefinitionOid: option.propertyDefinitionOid.oid,
      description: '',
      value: option.value,
      ordering: option.ordering,
    };
    const res = await addPropertyOption({
      variables: { input: valueMappedToApi },
      onCompleted: (res) => {
        const isSuccess = res?.addPropertyOptionValue.status.isSuccess;
        if (isSuccess) {
          const oid = res.addPropertyOptionValue.data.addedOid;
          dispatch(
            appendPropertyValue({
              propertyValue: { ...option, oid: { oid }, isTempOption: false } as PropertyValue,
            })
          );
        }
      },
    });

    return res.data?.addPropertyOptionValue.data.addedOid;
  }, []);

  const updatePropertyValues = useCallback(
    async (values: (OptionPropertyValue | TenantUserPropertyValue)[]) => {
      const valueOids = values.map((value) => value.oid.oid);
      const valuesNotChanged = _.isEqual(valueOids, initialValueOids);
      const selectedIds = apiRef ? Array.from(apiRef.current.getSelectedRows().keys()) : [];
      if (valuesNotChanged) {
        return;
      }
      if (selectedIds.includes(updateEntity.id)) {
        await handleUpdatePropertyOptionForBatchEntities(propertyDefinition, updateEntity, initialValueOids, valueOids);
      } else {
        handleUpdatePropertyOption(propertyDefinition, updateEntity, initialValueOids, valueOids);
      }
    },
    [
      updateEntity,
      propertyDefinition,
      initialValueOids,
      handleUpdatePropertyOption,
      handleUpdatePropertyOptionForBatchEntities,
    ]
  );

  const filterOptions = (
    options: TypeAheadEntity<OptionPropertyValue | TenantUserPropertyValue>[],
    state: FilterOptionsState<TypeAheadEntity<OptionPropertyValue | TenantUserPropertyValue>>
  ) => {
    const filtered = filter(options, state);

    const { inputValue } = state;

    const isExisting = options.some((option) => {
      if (option.type === PropertyDefinitionDataType.Option) {
        return inputValue === option.value;
      }
      return inputValue === option.fullName;
    });

    if (inputValue !== '' && !isExisting && propertyDefinition.dataType === PropertyDefinitionDataType.Option) {
      const tempOption: TypeAheadEntity<OptionPropertyValue> = {
        oid: { oid: `${Date.now()}` },
        value: inputValue,
        propertyDefinitionOid: propertyDefinition.oid,
        ordering: options.length + 1,
        type: propertyDefinition.dataType,
        isTempOption: true,
      };
      filtered.push(tempOption);
    }

    return filtered;
  };

  const getTextFromOption = (option: OptionPropertyValue | TenantUserPropertyValue | string) => {
    if (typeof option === 'string') {
      return option;
    }
    if (option.type === PropertyDefinitionDataType.TenantUser) {
      return option.fullName || '';
    }
    return option.value;
  };

  return {
    updatePropertyValues,
    filterOptions,
    getTextFromOption,
    createOption,
    propertiesSelectorOpen,
  };
}
