import { Box, Typography } from '@mui/joy';
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteGetTagProps,
  List,
  ListItem,
  Popover,
  SxProps,
  styled,
} from '@mui/material';
import { KeyboardEvent, MouseEvent, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { AutocompleteInputChangeReason } from '@mui/material/useAutocomplete';
import { AutocompleteCloseReason, FilterOptionsState, useAutocomplete } from '@mui/base';
import { colors } from 'theme/colors';
import { useMenuNavigation } from 'services/hooks/useMenuNavigation';
import CellMenuButton from 'components/ui-library/chat/CellMenu/СellMenuButton/CellMenuButton';
import { Oid } from 'services/models/api/generated';
import { IconsSvg } from 'assets/icons';
import { fonts } from 'theme/fonts';
import Translations from 'const/translations/en';

export type TypeAheadEntity<T> = T & { oid: Oid; isTempOption?: boolean };

interface TypeAheadInputProps<T> {
  isMultiValue: boolean;
  isEditable: boolean;
  anchorComponent: (handleOpen: (e: MouseEvent<HTMLButtonElement>) => void) => JSX.Element;
  options: TypeAheadEntity<T>[];
  initialValues: TypeAheadEntity<T>[];
  EditItemComponent: ((handleEditClose: () => void) => JSX.Element) | undefined;
  renderItemChip: (option: TypeAheadEntity<T> | string, tagProps?: ReturnType<AutocompleteGetTagProps>) => JSX.Element;
  getTextFromOption: (option: TypeAheadEntity<T> | string) => string;
  onAutocompleteClose: (values: TypeAheadEntity<T>[]) => void;
  filterOptions: (options: TypeAheadEntity<T>[], state: FilterOptionsState<TypeAheadEntity<T>>) => TypeAheadEntity<T>[];
  createOption: (option: TypeAheadEntity<T>) => Promise<string | undefined>;
  containerSX?: SxProps;
  showChevronDown?: boolean;
  height?: number;
  onClose?: () => void;
}

const DEFAULT_CONTAINER_WIDTH = 194;

export default function TypeAheadInput<T>({
  isMultiValue,
  isEditable,
  anchorComponent,
  options,
  initialValues,
  EditItemComponent,
  renderItemChip,
  getTextFromOption,
  onAutocompleteClose,
  filterOptions,
  createOption,
  containerSX,
  showChevronDown,
  height,
  onClose,
}: TypeAheadInputProps<T>) {
  const { isEditNav, handleEditClick, handleBackClick, setPropertiesSelectorState } = useMenuNavigation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const componentWidth = anchorEl?.getBoundingClientRect().width ?? DEFAULT_CONTAINER_WIDTH;

  const handleOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setPropertiesSelectorState(true);
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setPropertiesSelectorState(false);
    setAnchorEl(null);
    handleBackClick();
    onClose && onClose();
  }, [setAnchorEl, handleBackClick, anchorEl]);

  const handleKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.stopPropagation();
        handleClose();
      }
    },
    [handleClose]
  );

  return (
    <Box sx={style.container}>
      {anchorComponent(handleOpen)}
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        sx={style.popOver}
        transitionDuration={0}
        onClose={() => {
          setPropertiesSelectorState(false);
          handleClose();
        }}
      >
        <Box sx={{ ...style.innerContainer(componentWidth), ...containerSX }}>
          {EditItemComponent && isEditNav && EditItemComponent(handleBackClick)}
          {!isEditNav && (
            <>
              <CustomAutocomplete
                isMultiValue={isMultiValue}
                isEditable={isEditable}
                handleContainerClose={handleClose}
                options={options}
                initialValues={initialValues}
                renderItemChip={renderItemChip}
                getTextFromOption={getTextFromOption}
                handleKeydown={handleKeydown}
                onAutocompleteClose={onAutocompleteClose}
                filterOptions={filterOptions}
                createOption={createOption}
                showChevronDown={showChevronDown}
                height={height}
              />
              {EditItemComponent && (
                <CellMenuButton title={Translations.PROPERTY_EDIT} icon="ic_edit_column" onClick={handleEditClick} />
              )}
            </>
          )}
        </Box>
      </Popover>
    </Box>
  );
}
interface CustomAutocompleteProps<T> {
  isMultiValue: boolean;
  isEditable: boolean;
  handleContainerClose: () => void;
  options: TypeAheadEntity<T>[];
  initialValues: TypeAheadEntity<T>[];
  renderItemChip: (option: TypeAheadEntity<T> | string, tagProps?: ReturnType<AutocompleteGetTagProps>) => JSX.Element;
  getTextFromOption: (option: TypeAheadEntity<T> | string) => string;
  handleKeydown: (event: KeyboardEvent) => void;
  onAutocompleteClose: (values: TypeAheadEntity<T>[]) => void;
  filterOptions: (options: TypeAheadEntity<T>[], state: FilterOptionsState<TypeAheadEntity<T>>) => TypeAheadEntity<T>[];
  createOption: (option: TypeAheadEntity<T>) => Promise<string | undefined>;
  showChevronDown?: boolean;
  height?: number;
}

function CustomAutocomplete<T>({
  isMultiValue,
  isEditable,
  handleContainerClose,
  options,
  initialValues,
  renderItemChip,
  getTextFromOption,
  handleKeydown,
  onAutocompleteClose,
  filterOptions,
  createOption,
  showChevronDown,
  height,
}: CustomAutocompleteProps<T>) {
  const [inputValue, setInputValue] = useState('');
  const [selectedValues, setSelectedValues] = useState(initialValues);

  const onInputChange = useCallback(
    (_event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
      if (reason === 'input') {
        setInputValue(value);
      }
    },
    [setInputValue]
  );

  const onChange = useCallback(
    async (
      _event: React.SyntheticEvent<Element, Event>,
      value: (TypeAheadEntity<T> | string)[],
      reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<TypeAheadEntity<T>>
    ) => {
      const copiedValues = [...value];
      const lastSelected = value.length > 0 ? value[value.length - 1] : undefined;
      const shouldCreateOption = lastSelected && typeof lastSelected !== 'string' && lastSelected.isTempOption;

      let lastSelectedWithNewOid = lastSelected;

      if (shouldCreateOption) {
        const newOid = await createOption(lastSelected);
        lastSelectedWithNewOid = newOid ? { ...lastSelected, oid: { oid: newOid }, isTempOption: false } : lastSelected;
        copiedValues[copiedValues.length - 1] = lastSelectedWithNewOid;
      }

      const shouldOverwriteValue = !isMultiValue && copiedValues.length > 1;
      const newValues = shouldOverwriteValue ? [lastSelected] : copiedValues;
      setSelectedValues(newValues as TypeAheadEntity<T>[]);

      if (!isMultiValue && reason === 'selectOption' && !shouldCreateOption) {
        handleContainerClose();
      }
      setInputValue('');
    },
    [handleContainerClose, isMultiValue, setSelectedValues]
  );

  const onClose = useCallback(
    (_event: React.SyntheticEvent<Element, Event>, _reason: AutocompleteCloseReason) => {
      onAutocompleteClose(selectedValues);
    },
    [onAutocompleteClose, selectedValues]
  );

  const { getRootProps, getInputProps, getTagProps, getListboxProps, getOptionProps, groupedOptions, value } =
    useAutocomplete({
      filterSelectedOptions: true,
      autoHighlight: true,
      multiple: true,
      disableCloseOnSelect: true,
      open: true,
      openOnFocus: true,
      selectOnFocus: true,
      clearOnBlur: true,
      handleHomeEndKeys: true,
      options,
      value: selectedValues,
      onChange,
      onClose,
      inputValue,
      onInputChange,
      getOptionLabel: getTextFromOption,
      // When freeSolo mode is enabled, the value type needs to include 'string'.
      // Ref: https://mui.com/material-ui/react-autocomplete/#free-solo
      freeSolo: isEditable,
      filterOptions,
      isOptionEqualToValue: (option, value) => option.oid.oid === value.oid.oid,
    });

  // This is a workaround to get input ref to make the input element focused on mount.
  // The type of getInputProps is incorrect (ref is missing from returned value) in the current MUI version
  // Therefore, coercing it to the correct type to be able to call focus() on mount
  const { ref: inputRef, ...restInputProps } = (
    getInputProps as () => React.InputHTMLAttributes<HTMLInputElement> & { ref: React.Ref<HTMLInputElement> }
  )();

  useEffect(() => {
    if (inputRef && (inputRef as RefObject<HTMLInputElement>)?.current?.focus) {
      (inputRef as RefObject<HTMLInputElement>)!.current!.focus();
    }
  }, [inputRef]);

  const checkIfValueExists = (currentOption: TypeAheadEntity<T>) => {
    const value = currentOption.isTempOption ? inputValue : getTextFromOption(currentOption);
    const exists = options.some((option) => getTextFromOption(option).toLowerCase() === value.toLowerCase());
    return exists;
  };

  return (
    <Box>
      <Box
        {...getRootProps()}
        sx={{
          ...style.inputBox,
          ...(showChevronDown ? style.inputContainer : {}),
        }}
      >
        {value.map((option, index) => renderItemChip(option, getTagProps({ index })))}
        <Input
          {...restInputProps}
          ref={inputRef}
          onKeyDown={handleKeydown}
          autoFocus
          sx={{ width: '100%', backgroundColor: showChevronDown ? colors.neutral[5] : colors.white }}
        />
        {showChevronDown && <IconsSvg.ic_chevron_down width={14} height={14} stroke={colors.neutral[50]} />}
      </Box>
      {groupedOptions.length > 0 && (
        <List {...getListboxProps()} sx={style.list(height ?? 40)}>
          {(groupedOptions as TypeAheadEntity<T>[]).map((option, index) => {
            if ((option.isTempOption && checkIfValueExists(option)) || (option.isTempOption && index !== 0)) {
              return;
            }
            return (
              <ListItem {...getOptionProps({ option, index })} key={option.oid.oid} sx={style.listItem}>
                {option.isTempOption && <Typography level="micro" paddingX="4px">{`Create`}</Typography>}
                {renderItemChip(option)}
              </ListItem>
            );
          })}
        </List>
      )}
    </Box>
  );
}

const Input = styled('input')({
  width: '100%',
  flex: '1 1 30px',
  border: 'none',
  '&:focus-visible': {
    outline: 'none',
  },
  padding: 0,
  margin: '6px 4px',
  fontSize: '12px',
  caretColor: colors.inputCaret,
});

const style = {
  container: {
    width: '100%',
    height: '100%',
  },
  popOver: {
    width: '100%',
    zIndex: 9999,
    '& .MuiPaper-root': {
      boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1), 0px 0px 20px rgba(0, 0, 0, 0.09)',
      borderRadius: '6px',
      fontFamily: fonts.regular,
      marginTop: '-0.5px',
    },
  },
  innerContainer: (width: number) => ({
    border: `1px solid ${colors.neutral[5]}`,
    borderRadius: '6px',
    width: `${width}px`,
  }),
  inputBox: {
    width: '100%',
    padding: '0 5px 0 0',
    minHeight: '52px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    borderBottom: `1px solid ${colors.neutral[5]}`,
    cursor: 'text',
    overflow: 'hidden',
  },
  inputContainer: {
    border: `1px solid ${colors.neutral[20]}`,
    borderRadius: '6px',
    backgroundColor: colors.neutral[5],
    minHeight: '34px',
  },
  list: (height: number) => ({
    maxHeight: `${height}vh`,
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    padding: '4px',
    gap: '2px',
    borderBottom: `1px solid ${colors.neutral[5]}`,
  }),
  listItem: {
    padding: '4px',
    flexShrink: 0,
    cursor: 'pointer',
    '&.Mui-focused,&.Mui-focusVisible': {
      borderRadius: '4px',
      backgroundColor: colors.neutral[0],
    },
    display: 'flex',
    alignItem: 'center',
    gap: '6px',
  },
};
