import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Conversation } from 'services/models/domain/conversation';
import { RootState } from 'services/store/AppStore';

export interface SidebarState {
  openModal: boolean;
  conversation: Conversation | undefined;
}

const initialState: SidebarState = {
  openModal: false,
  conversation: undefined,
};

const manualConversationSlice = createSlice({
  name: 'manualConversation',
  initialState,
  reducers: {
    setCreateConversationModalState: (
      state,
      action: PayloadAction<{ openModal: boolean; conversation?: Conversation }>
    ) => {
      state.openModal = action.payload.openModal;
      state.conversation = action.payload.conversation || undefined;
    },
  },
});

export const { setCreateConversationModalState } = manualConversationSlice.actions;
export const manualConversationState = (state: RootState) => state.manualConversation;
export default manualConversationSlice.reducer;
