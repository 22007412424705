import { Box, RadioGroup, Typography } from '@mui/joy';
import { Divider } from '@mui/material';
import PropertyFilterItem from 'components/Chats/Filters/PropertyFilter/PropertyFilterItem/PropertyFilterItem';
import { PropertyFilterProps } from 'components/Chats/Filters/PropertyFilter/interface';
import Translations from 'const/translations/en';
import { useRef } from 'react';
import { OptionPropertyValue, TenantUserPropertyValue } from 'services/models/domain/entityViews';
import { colors } from 'theme/colors';

const FilterDivider = () => (
  <Box sx={{ marginY: '5px' }}>
    <Divider sx={{ border: '1.3px dashed #fff', background: 'var(--joy-palette-info-plainBorder)' }} />
  </Box>
);
const PropertyFilter = ({
  type,
  propertyDefinition,
  sx,
  onSelect,
  onUnselect,
  selectedOids,
  onClose,
  onClear,
  filterOption,
}: PropertyFilterProps) => {
  // TODO (AWE): Get rid of filter form usage. This way we might be able to make it independent from chat filters
  const definitionValues = propertyDefinition.values as OptionPropertyValue[] | TenantUserPropertyValue[];
  const cellMenuRef = useRef<HTMLElement | null>(null);

  const renderDefinitionValues = () => {
    return (
      <RadioGroup name="platform">
        {definitionValues?.map((value) => {
          const isSelected = selectedOids.includes(value.oid.oid);
          return (
            <PropertyFilterItem
              key={value.oid.oid}
              propertyValue={value}
              onSelect={(value) => {
                onSelect(value);
              }}
              onUnselect={(value) => {
                onUnselect(value);
              }}
              isChecked={isSelected}
            />
          );
        })}
      </RadioGroup>
    );
  };

  const renderFilterHeader = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingTop: '5px',
          marginX: '2px',
        }}
      >
        {filterOption && (
          <Typography level="tiny" sx={{ p: 1, color: colors.neutral[50] }}>
            {filterOption?.title}
          </Typography>
        )}
        <Typography
          level="tiny"
          sx={{
            p: 1,
            color: colors.primary[50],
            textDecoration: 'underline',
            cursor: 'pointer',
            transition: '0.1s ease-in-out curve',
          }}
          onClick={onClear}
        >
          {Translations.CLEAR}
        </Typography>
      </Box>
    );
  };
  const renderPropertyDefinitionFilter = () => {
    return (
      <Box flexDirection="column" ref={cellMenuRef}>
        {filterOption && renderFilterHeader()}
        {filterOption && <FilterDivider />}
        {renderDefinitionValues()}
      </Box>
    );
  };

  return <>{renderPropertyDefinitionFilter()}</>;
};

export default PropertyFilter;
