export interface IPersistence<T> {
  setItem(key: string, item: T): void;
  getItem(key: string): T | null;
  getItemOr(key: string, fallback: T): T;
}

export class LocalStoragePersistence<T> implements IPersistence<T> {
  private localStorage: Storage;
  constructor(localStorage: Storage) {
    this.localStorage = localStorage;
  }
  setItem(key: string, item: T): void {
    if (!item) {
      return;
    }
    const itemJson = JSON.stringify(item);
    this.localStorage.setItem(key, itemJson);
  }

  getItem(key: string): T | null {
    const itemJson = this.localStorage.getItem(key);
    if (!itemJson) {
      return null;
    }
    const item: T = JSON.parse(itemJson);
    return item;
  }

  getItemOr(key: string, fallback: T): T {
    const item = this.getItem(key);
    if (item === null) {
      return fallback;
    }
    return item;
  }
}

export class InMemoryPersistence<T> implements IPersistence<T> {
  private storage: Map<string, T>;
  constructor() {
    this.storage = new Map<string, T>();
  }
  setItem(key: string, item: T): void {
    this.storage.set(key, item);
  }
  getItem(key: string): T | null {
    return this.storage.get(key) ?? null;
  }
  getItemOr(key: string, fallback: T): T {
    return this.storage.get(key) ?? fallback;
  }
}
