import { useFlags } from 'launchdarkly-react-client-sdk';

import OnboardingModal from 'page/modal/OnboardingModal/onboardingModal';
import useOnboardingModalControl from 'services/hooks/useOnboardingModalControl';

const Onboarding = () => {
  const flags = useFlags();
  const { isOnboardingModalOpen, toggleOnboardingModal } = useOnboardingModalControl();

  const tgSyncIsOn = !!flags?.telegramSyncConnectAndSyncEnabled;

  if (!tgSyncIsOn) return null;

  return <OnboardingModal open={isOnboardingModalOpen} onClose={() => toggleOnboardingModal(false)} />;
};

export default Onboarding;
