enum TrackEventTags {
  UserOnboarding = 'userOnboarding',
  PipelineButtonToggle = 'pipelineButtonToggle',
  TelegramStatusDisconnected = 'telegramStatusDisconnected',
}

//default event states
export const setDefaultTrackEvents = () => {
  window.CommandBar.trackEvent(TrackEventTags.UserOnboarding, { startChecklist: false });
  window.CommandBar.trackEvent(TrackEventTags.PipelineButtonToggle, { clicked: false });
  window.CommandBar.trackEvent(TrackEventTags.TelegramStatusDisconnected, { disconnected: false });
};

//events
const onboardingTrackEvent = () =>
  window.CommandBar.trackEvent(TrackEventTags.UserOnboarding, { startChecklist: true });
const pipelineTrackEvent = () => window.CommandBar.trackEvent(TrackEventTags.PipelineButtonToggle, { clicked: true });
const telegramStatusDisconnected = (isDiconnected: boolean) =>
  window.CommandBar.trackEvent(TrackEventTags.TelegramStatusDisconnected, { disconnected: isDiconnected });

export const commandbarTrackerEvents = {
  [TrackEventTags.UserOnboarding]: onboardingTrackEvent,
  [TrackEventTags.PipelineButtonToggle]: pipelineTrackEvent,
  [TrackEventTags.TelegramStatusDisconnected]: telegramStatusDisconnected,
};
