import { Box, Stack, Typography } from '@mui/joy';
import { SxProps } from '@mui/joy/styles/types';
import { colors } from 'theme/colors';
import NavigationItemChip from './NavigationItemChip';
import { NavigationItem } from 'page/dashboard/header/SideNav';
import { ReactNode } from 'react';

interface NavifationSectionProps {
  title: string;
  isOpen: boolean;
  navigationItems: NavigationItem[];
  selectedId: string | undefined;
  onClick: (item: NavigationItem) => void;
  endBox?: ReactNode;
  sx?: SxProps;
}

export default function NavigationSection(props: NavifationSectionProps) {
  const { title, isOpen, navigationItems, selectedId, onClick, endBox, sx } = props;
  return (
    <Box sx={{ ...NavigationSectionStyle.sectionBox, ...sx }}>
      <Stack direction="row" role="region" sx={NavigationSectionStyle.headerContainer}>
        <Typography level="micro" sx={NavigationSectionStyle.headerText(isOpen)}>
          {title.toUpperCase()}
        </Typography>
      </Stack>

      {navigationItems.map((item) => (
        <NavigationItemChip
          key={item?.id}
          item={item}
          isSelected={item?.id === selectedId}
          isOpen={isOpen}
          onClick={onClick}
        />
      ))}
      {endBox}
    </Box>
  );
}

const NavigationSectionStyle = {
  sectionBox: {
    width: '100%',
  },
  headerContainer: {
    cursor: 'default',
  },
  headerText: (isOpen: boolean) => ({
    marginY: '10px',
    color: isOpen ? colors.neutral['50'] : 'transparent',
    whiteSpace: 'nowrap',
    marginLeft: '8px',
  }),
};
