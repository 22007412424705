import { GridCellProps, GridCell } from '@mui/x-data-grid-pro';

import { Typography } from '@mui/joy';
import { TimeAgo } from 'services/utils/helpers';

export type DateAddedCellProps = {
  createdAt: string;
};

export function DateAddedCell(props: GridCellProps<DateAddedCellProps>) {
  const { value, ...rest } = props;
  const date = new Date(Date.parse(value!.createdAt));
  const timeAgo = TimeAgo({ toDate: date });
  return (
    <GridCell {...rest}>
      <Typography level="tiny">{timeAgo}</Typography>
    </GridCell>
  );
}
