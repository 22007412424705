import { Box, Divider } from '@mui/joy';
import PropertyFilter from 'components/Chats/Filters/PropertyFilter/PropertyFilter';
import _ from 'lodash';
import { ApplyFilterButton } from 'page/chats/ChatTableFilterBar/FilterOptions/ApplyFilterButton';
import { NoOptionFilterMenu } from 'page/chats/ChatTableFilterBar/FilterOptions/NoOptionFilterMenu';
import { FilterSx } from 'page/chats/ChatsTableFilter/styles';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FilterOption } from 'services/models/domain/entityFilter';
import { EntityPropertyDefinition } from 'services/models/domain/entityViews';
import { entityViewsState } from 'services/store/slices/entityViewsSlice';

export interface PropertyFilterMenuProps {
  filterOption: FilterOption;
  selectedFilter?: FilterOption;
  onChange: (propertyDefinitionOid: string, propertyValueOids: string[], noPropertyValue: boolean) => void;
  onClose: () => void;
}

export const PropertyFilterMenu = (props: PropertyFilterMenuProps) => {
  const { entityViews } = useSelector(entityViewsState);
  const [selectedPropertyValues, setSelectedPropertyValues] = useState<string[]>([]);
  const [noPropertyValueSet, setNoPropertyValueSet] = useState<boolean>(false);
  const [initialValue, setInitialValue] = useState<{ propertyValues: string[]; noPropertyValue: boolean }>({
    propertyValues: [],
    noPropertyValue: false,
  });
  let propertyDefinition: EntityPropertyDefinition | undefined;

  if (
    entityViews.views.length > 0 &&
    'subItems' in props.filterOption &&
    'value' in props.filterOption.subItems[0][0] &&
    'propertyDefinitionOid' in props.filterOption.subItems[0][0].value
  ) {
    const { propertyDefinitionOid } = props.filterOption.subItems[0][0].value;
    // TODO (AWE): Select correct entity view and have a default.
    propertyDefinition = entityViews.propertyDefinitions.find((pd) => pd.oid.oid === propertyDefinitionOid);
  }

  useEffect(() => {
    if (
      props.selectedFilter &&
      'value' in props.selectedFilter &&
      'propertyDefinitionOid' in props.selectedFilter.value
    ) {
      const selectedValues = props.selectedFilter.value.propertyValueOids;
      setInitialValue({
        propertyValues: selectedValues,
        noPropertyValue: !!props.selectedFilter.value.noPropertyDefinitionOid,
      });
      setSelectedPropertyValues(selectedValues);
      setNoPropertyValueSet(!!props.selectedFilter.value.noPropertyDefinitionOid);
    }
  }, []);

  useEffect(() => {
    if (
      props.selectedFilter &&
      'value' in props.selectedFilter &&
      'propertyDefinitionOid' in props.selectedFilter.value
    ) {
      const selectedValues = props.selectedFilter.value.propertyValueOids;
      setSelectedPropertyValues(selectedValues);
      setNoPropertyValueSet(!!props.selectedFilter.value.noPropertyDefinitionOid);
    }
  }, [props.selectedFilter]);

  if (!propertyDefinition) {
    return <></>;
  }

  const title = '';

  return (
    <Box sx={{ marginX: '5px' }}>
      <PropertyFilter
        key={propertyDefinition.oid.oid}
        type={propertyDefinition.dataType}
        propertyDefinition={propertyDefinition}
        sx={FilterSx}
        selectedOids={selectedPropertyValues}
        onSelect={(propertyValue) => {
          const updatedValues = [...selectedPropertyValues, propertyValue.oid.oid];
          setSelectedPropertyValues(updatedValues);
          props.onChange(propertyDefinition!.oid.oid, updatedValues, false);
        }}
        onUnselect={(propertyValue) => {
          const updatedValues = selectedPropertyValues.filter((oid) => oid !== propertyValue.oid.oid);
          setSelectedPropertyValues(updatedValues);
          props.onChange(propertyDefinition!.oid.oid, updatedValues, false);
        }}
        onClose={() => {}}
        title={title}
        onClear={() => {
          setSelectedPropertyValues([]);
          props.onChange(propertyDefinition!.oid.oid, [], false);
        }}
        filterOption={props.selectedFilter}
      />
      <Divider sx={{ border: '1.3px dashed #fff', background: 'var(--joy-palette-info-plainBorder)' }} />
      <NoOptionFilterMenu
        selected={noPropertyValueSet}
        title={`No ${propertyDefinition.name}`}
        onChange={(value) => {
          props.onChange(propertyDefinition!.oid.oid, [], value);
        }}
      />
      {!_.isEqual(initialValue, { propertyValues: selectedPropertyValues, noPropertyValue: noPropertyValueSet }) && (
        <ApplyFilterButton onClick={props.onClose} sx={{ width: 'calc(100% - 12px)', margin: '6px' }} />
      )}
    </Box>
  );
};
