import { IconsSvg } from 'assets/icons';
import Translations from 'const/translations/en';
import { ConversationType } from 'services/models/domain/conversation';

export const getChatTypeIcon = (chatType: ConversationType): keyof typeof IconsSvg => {
  switch (chatType) {
    case ConversationType.Individual:
      return 'ic_heart_handshake';
    case ConversationType.Group:
      return 'ic_user_groups';
    case ConversationType.Channel:
      return 'ic_speakerphone';
    default:
      return 'ic_question';
  }
};

export const getChatTypeName = (chatType: ConversationType): string => {
  switch (chatType) {
    case ConversationType.Individual:
      return Translations.CELL_NAME_CHAT_TYPE_INDIVIDUAL;
    case ConversationType.Group:
      return Translations.CELL_NAME_CHAT_TYPE_GROUP;
    case ConversationType.Channel:
      return Translations.CELL_NAME_CHAT_TYPE_CHANNEL;
    default:
      return Translations.CELL_NAME_CHAT_TYPE_UNKNOWN;
  }
};
