import { MenuItem, Typography, MenuList, Stack } from '@mui/joy';
import { SxProps } from '@mui/joy/styles/types';
import { IconsSvg } from 'assets/icons';
import CellMenu from 'components/ui-library/chat/CellMenu/CellMenu';
import { ReactNode } from 'react';
import { colors } from 'theme/colors';

export type MenuItemType = {
  name: string;
  icon: keyof typeof IconsSvg;
  onClick: () => void;
  iconColor?: string;
  sx?: SxProps;
};

interface PositionedMenuProps {
  menuItems: MenuItemType[];
  anchorComponent: ReactNode;
}

export default function PositionedMenu({ menuItems, anchorComponent }: PositionedMenuProps) {
  return (
    <CellMenu
      sx={{ backgroundColor: 'transparent' }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchorComponent={anchorComponent}
    >
      <MenuList sx={styles.menuList}>
        {menuItems.map((item, idx) => {
          const Icon = IconsSvg[item.icon];
          return (
            <MenuItem key={idx} onClick={item.onClick} sx={{ ...styles.menuItem, ...item?.sx }}>
              <Stack direction="row" spacing={1} sx={styles.itemContainer}>
                {!!Icon && <Icon stroke={item?.iconColor ?? colors.neutral[50]} width={12} height={12} />}
                <Typography level="micro">{item.name}</Typography>
              </Stack>
            </MenuItem>
          );
        })}
      </MenuList>
    </CellMenu>
  );
}

const styles = {
  menuList: {
    padding: '4px',
  },
  menuItem: {
    color: colors.neutral[50],
    height: '28px',
    padding: '4px 8px',
    borderRadius: '6px',
  },
  itemContainer: { alignItems: 'center' },
};
