import { useState } from 'react';

export enum TableHeaderNavigationState {
  Select = 'Select',
  EditValues = 'EditValues',
  Rename = 'Rename',
}

type navigate = {
  [value in TableHeaderNavigationState]: () => void;
};

export const useTableHeaderNavigation = () => {
  const [navigationState, setNavigationState] = useState(TableHeaderNavigationState.Select);

  const navigate: navigate = {
    [TableHeaderNavigationState.Select]: () => setNavigationState(TableHeaderNavigationState.Select),
    [TableHeaderNavigationState.EditValues]: () => setNavigationState(TableHeaderNavigationState.EditValues),
    [TableHeaderNavigationState.Rename]: () => setNavigationState(TableHeaderNavigationState.Rename),
  };

  return {
    navigate,
    navigationState,
  };
};
