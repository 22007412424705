import React from 'react';
import { UIFormControlProps } from 'components/ui-library/FormControl/interface';
import { FormControl } from '@mui/material';
import { StyledLabel, StyledUnderText } from 'components/ui-library/FormControl/styled';

const UIFormControl = (props: UIFormControlProps) => {
  const { label, size, underText, children, error, sx } = props;

  return (
    <FormControl sx={sx}>
      {label && (
        <StyledLabel inputSize={size} error={error}>
          {label}
        </StyledLabel>
      )}
      {children}
      {underText && <StyledUnderText error={error}>{underText}</StyledUnderText>}
    </FormControl>
  );
};

export default UIFormControl;
