import { SxProps } from '@mui/material';
import { colors } from 'theme/colors';

export const MenuSx: SxProps = {
  display: 'flex',
  alignContent: 'flex-start',
  flexWrap: 'wrap',
};

export const ButtonSx: SxProps = {
  width: '100%',
  height: '100%',
  alignContent: 'center',
  justifyContent: 'flex-start',
  padding: '0px',
  paddingLeft: '10px',
  display: 'flex',
};

export const SelectedMenuItemSx: SxProps = {
  borderBottom: `1px solid ${colors['neutral']['10']}`,
  alignItems: 'space-between',
  '&:hover': {
    background: 'transparent',
  },
};

export const CloseIconSx: SxProps = {
  position: 'absolute',
  right: 0,
};

export const DividerSx: SxProps = {
  '--Divider-lineColor': colors.neutral[5],
  backgroundColor: colors.neutral[5],
};

export const ChipMarginStyle = (hasMulti: boolean): SxProps => {
  if (hasMulti) {
    return {
      marginLeft: '2px',
      marginRight: '2px',
      marginBottom: '2px',
      marginTop: '2px',
    };
  }
  return {
    marginLeft: '2px',
    marginRight: '2px',
    marginBottom: '1px',
  };
};
