import { TableCellProps } from '@mui/material';
import { SortConversationsOnPropertyType } from 'services/models/api/generated';

export type ChatHeaderCellType = '' | 'chat' | 'latest-message' | 'follow-up' | 'telegram-link' | 'note';

export type ChatHeaderCell = {
  id: ChatHeaderCellType;
  label: string | null;
  align: TableCellProps['align'];
  icon: string | null;
  sortByType?: SortConversationsOnPropertyType;
};

export enum PageView {
  Table = 'table',
  Kanban = 'kanban',
}
