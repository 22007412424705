import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';

import { editPropertyValue } from 'services/store/slices/conversationPropertiesSlice';
import { REQUEST_DELAY } from 'components/Chats/ChatTableV2/EditProperty/AddEditPropertyValue/hooks/constants';
import {
  OptionPropertyValue,
  useUpdatePropertyOptionValueMutation,
  UpdatePropertyOptionValueInput,
} from 'services/models/api/generated';

export const useEditProperty = (propertyOption: OptionPropertyValue, refetch: () => Promise<void>) => {
  const [updatePropertyOption] = useUpdatePropertyOptionValueMutation();
  const dispatch = useDispatch();

  const updateSubmit = async (updatedOption: OptionPropertyValue) => {
    const input: UpdatePropertyOptionValueInput = {
      color: updatedOption.color,
      description: updatedOption.description,
      icon: updatedOption.icon,
      ordering: updatedOption.ordering,
      propertyOptionOid: updatedOption.oid.oid,
      value: updatedOption.value,
      propertyDefinitionOid: propertyOption.propertyDefinitionOid.oid,
    };

    await updatePropertyOption({
      variables: { input },
      onCompleted: (res) => {
        const isSuccess = res?.updatePropertyOptionValue.status.isSuccess;

        if (isSuccess) {
          refetch();
        }
      },
    });
  };

  const updateDebounce = useMemo(() => {
    return debounce(async (propertyOption: OptionPropertyValue) => {
      await updateSubmit(propertyOption);
    }, REQUEST_DELAY);
  }, []);

  const isActiveField = (value: string | null, field: keyof OptionPropertyValue) => {
    return propertyOption[field] === value;
  };

  const setField = (value: string | null, field: keyof OptionPropertyValue) => {
    const nextPropertyOptionState = {
      ...propertyOption,
      [field]: value,
    };
    dispatch(editPropertyValue(nextPropertyOptionState));

    if (propertyOption.oid.oid) {
      updateDebounce(nextPropertyOptionState);
    }
  };

  return {
    isActiveField,
    setField,
    name: propertyOption.value,
  };
};
