import { useDispatch } from 'react-redux';
import { setLoading } from 'services/store/slices/sidebarEntityDetailsSlice';
import { EntityDetailsType, setSidebarState } from 'services/store/slices/sidebarSlice';
import { setTableNavigationEntityOid } from 'services/store/slices/tableEntitiesNavigationSlice';

export const useSidebar = () => {
  const dispatch = useDispatch();

  const openSidebar = (entityOid: string | undefined, entityType: EntityDetailsType | undefined) => {
    dispatch(setTableNavigationEntityOid({ entityOid }));
    dispatch(setSidebarState({ openDrawer: true, entityType }));
  };

  const closeSidebar = () => {
    dispatch(setTableNavigationEntityOid({ entityOid: undefined }));
    dispatch(setSidebarState({ openDrawer: false, entityType: undefined }));
  };

  const setSidebarLoading = (isLoading: boolean) => {
    dispatch(setLoading(isLoading));
  };

  return {
    openSidebar,
    closeSidebar,
    setSidebarLoading,
  };
};
