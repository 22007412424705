import { Box } from '@mui/joy';
import { IconsSvg } from 'assets/icons';
import DetailsCard from 'components/Details/DetailsCard';
import PageLink from 'components/PageLink';
import TitleCard from 'components/TitleCard';
import { DoubleLineTooltipContents } from 'components/TooltipContents';
import { DetailsIconStyle, TelegramIconStyle } from 'components/Kanban/styles';
import Translations from 'const/translations/en';
import { RouterPaths } from 'services/hooks/useNavigation';
import { useSidebar } from 'services/hooks/useSidebar';
import { EntityDetailsType } from 'services/store/slices/sidebarSlice';
import { AssociatedConversation } from 'services/models/domain/contact';
import SinglePlaceholder from 'page/details/placeholder/SinglePlaceholder';

interface AssociatedChatsDetailsCardProps {
  associatedChats: AssociatedConversation[] | undefined;
  loading?: boolean;
}

export default function AssociatedChatsDetailsCard({ associatedChats, loading }: AssociatedChatsDetailsCardProps) {
  const { openSidebar } = useSidebar();

  return (
    <DetailsCard title={Translations.CHATS}>
      {loading ? (
        <SinglePlaceholder width={40} />
      ) : (
        <Box sx={styles.chatsContainer}>
          {associatedChats?.map((chat: AssociatedConversation) => (
            <Box key={chat.conversationOid.oid} sx={styles.associatedChats}>
              <TitleCard
                title={
                  <PageLink to={RouterPaths.createDetailsChatPath(chat.conversationOid.oid)}>{chat.title}</PageLink>
                }
                leftComponent={{
                  icon: IconsSvg.ic_telegram,
                  css: TelegramIconStyle(!!chat.chatLink),
                  link: chat.chatLink,
                  description: chat.chatLink ? (
                    Translations.OPEN_TELEGRAM_TOOLTIP
                  ) : (
                    <DoubleLineTooltipContents
                      primaryText={Translations.NO_CHAT_LINK_TOOLTIP_PRIMARY_MESSAGE}
                      secondaryText={Translations.NO_CHAT_LINK_TOOLTIP_SECONDARY_MESSAGE}
                    />
                  ),
                  showFilledIcon: true,
                }}
                rightComponent={{
                  icon: IconsSvg.ic_sidebar,
                  description: Translations.OPEN_SIDEBAR_TOOLTIP,
                  css: DetailsIconStyle,
                  link: () => openSidebar(chat.conversationOid.oid, EntityDetailsType.chatDetails),
                }}
              />
            </Box>
          ))}
        </Box>
      )}
    </DetailsCard>
  );
}

const styles = {
  chatsContainer: {
    display: `flex`,
    padding: `12px 16px`,
    paddingRight: `12px`,
    maxHeight: `161px`,
    overflowY: `auto`,
    flexDirection: `column`,
  },
  associatedChats: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px',
    ':not(:last-child)': {
      marginBottom: '16px',
    },
  },
};
