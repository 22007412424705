import { Box, Stack } from '@mui/joy';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Children } from 'react';
import _ from 'lodash';

export interface MutiRowBoxProps {
  children: ReactNode;
  maxLines: number;
  showMultiLines: boolean;
}

const groupIntoRows = (
  nodes: ReactNode[],
  widthMap: Map<string, number>,
  maxWidth: number,
  maxRows: number
): ReactNode[][] => {
  if (maxWidth === 0) {
    return [[...nodes]];
  }

  let currentWidth = 15;
  let currentGroup: ReactNode[] = [];
  let groups: ReactNode[][] = [];

  nodes.forEach((node) => {
    // @ts-ignore
    const nodeWidth = widthMap.get(node.key) || 0;
    const newWidth = currentWidth + nodeWidth + 5;
    if (newWidth >= maxWidth && groups.length + 1 < maxRows) {
      groups = [...groups, currentGroup];
      currentGroup = [];
      currentWidth = 0;
    } else {
      currentWidth = newWidth;
    }
    currentGroup = [...currentGroup, node];
  });
  groups = [...groups, currentGroup];

  return groups;
};

export const MutiRowBox = (props: MutiRowBoxProps) => {
  const [widthMap, setWidthMap] = useState<Map<string, number>>(new Map());
  const [rows, setRows] = useState<ReactNode[][]>([]);
  const children = Children.toArray(props.children);
  const [componentWidth, setComponentWidth] = useState(0);
  const ref = useRef(null);
  useEffect(() => {
    // @ts-ignore
    const width = ref?.current?.offsetWidth || 0;
    setComponentWidth(width);
  }, [ref.current]);

  useEffect(() => {
    setRows(groupIntoRows(children, widthMap, componentWidth, props.maxLines));
  }, [widthMap, componentWidth, props.children]);

  const renderRow = (childs: ReactNode[], index: Number) => {
    return (
      <Stack direction="row" id={`${index}-Row`} key={`${index}-renderRow`} sx={MutiRowBoxStyles.row}>
        {childs.map((child, index) => renderItem(child, index))}
      </Stack>
    );
  };

  const renderItem = (child: ReactNode, index: number) => {
    return (
      <RowItem
        onWidthChanged={(width, key) => {
          const updatedMap = widthMap.set(key, width);
          setWidthMap(updatedMap);
        }}
        id={`${index}-RowItem`}
        key={`${index}-RowItem`}
      >
        {child}
      </RowItem>
    );
  };

  return (
    <Stack direction={props.showMultiLines ? 'column' : 'row'} sx={MutiRowBoxStyles.mutliRowContainer} ref={ref}>
      {rows.map((childs, index) => renderRow(childs, index))}
    </Stack>
  );
};

const RowItem = (props: { onWidthChanged: (width: number, key: string) => void; children: ReactNode; id: string }) => {
  const ref = useRef(null);

  useEffect(() => {
    // TODO (AWE): Get rid of ts-ignore and have propert types for children and ref
    // @ts-ignore
    const width = ref?.current?.offsetWidth || 0;
    // @ts-ignore Children should have key.
    props.onWidthChanged(width, props.children?.key);
  }, [ref.current]);
  return (
    <Box ref={ref} key={props.id} sx={MutiRowBoxStyles.rowItem}>
      {props.children}
    </Box>
  );
};

const MutiRowBoxStyles = {
  mutliRowContainer: {
    width: '100%',
    padding: '0px',
    alignContent: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden',
  },
  row: {
    overflow: 'hidden',
  },
  rowItem: {
    width: '100%',
    maxWidth: 'fit-content',
    overflow: 'hidden',
  },
};
