import { useContext } from 'react';
import { usePublishEvents } from 'services/hooks/usePublishEvents';
import { ConversationType } from 'services/models/domain/conversation';
import { AppContext } from 'services/store/AppContext';
import store from 'services/store/AppStore';

type ConversationDataType = {
  lastMessageAt: string;
  externalId: string;
};

export const useTelegramLiveEvents = () => {
  const { tgClient } = useContext(AppContext);
  const { addFolderToQueue, addConversationToQueue } = usePublishEvents();
  // const { fetchTelegramFolders, syncFolders, syncFoldersWithTgFolders } = useTelegramSync(true);
  // const { folders, telegramFolders, initialFolders } = useSelector(tgSync.userTgState);

  const events = {
    updateNewChannelMessage: 'UpdateNewChannelMessage',
    updateShortChatMessage: 'UpdateShortChatMessage',
    updateShortMessage: 'UpdateShortMessage',
    updateDialogFilter: 'UpdateDialogFilter',
    updateChannel: 'UpdateChannel',
    updateDeletedChannelMessages: 'UpdateDeletedChannelMessages',
  };

  const compareProperties = (dynamicFolderFilter: any, folder: any): boolean => {
    for (const prop in dynamicFolderFilter) {
      if (dynamicFolderFilter[prop] !== folder[prop]) {
        return true; // Return true as soon as a difference is found
      }
    }
    return false; // Return false if no differences were found
  };

  const initListeners = async () => {
    // if (!tgClient) return;
    // tgClient.initLiveSync(async (event: any) => {
    //   switch (event.className) {
    //     case events.updateShortMessage:
    //       await handleNewMessageUser(event);
    //       break;
    //     case events.updateNewChannelMessage:
    //       await handleNewMessageChannel(event);
    //       break;
    //     case events.updateShortChatMessage:
    //       await handleNewChatMessage(event);
    //       break;
    //     case events.updateDialogFilter:
    //       // await handleFolderChange(event);
    //       break;
    //     case events.updateChannel:
    //       // await handleChannelChange(event);
    //       break;
    //     default:
    //       break;
    //   }
    // });
    console.debug('[CLIENT-SYNC] Telegram live sync initialized');
  };

  const isIncludedPeer = (conversationData: ConversationDataType) => {
    const foldersStorage = localStorage.getItem('Telegram:Folders');
    const reduxFolders = store.getState().userTg.folders;

    for (const folder of JSON.parse(foldersStorage || '[]')) {
      if (folder.includedPeers.includes(conversationData.externalId)) {
        const folderSynced = reduxFolders.find((f) => f.synced && f.externalId === folder.id.toString());
        if (folderSynced) return true;
      }
    }
    return false;
  };

  const isDynamicProperty = async (
    conversationData: ConversationDataType,
    conversationClassName: string
  ): Promise<boolean> => {
    const foldersStorage = JSON.parse(localStorage.getItem('Telegram:Folders') || '[]');
    const reduxFolders = store.getState().userTg.folders;

    const peerData = await tgClient.getInputEntity(conversationData.externalId);

    let onDynamicProperty = false;

    switch (conversationClassName) {
      case 'InputPeerUser':
        if (!peerData || peerData.className !== 'InputPeerUser') return false;

        const fullUser = await tgClient.getFullUser({
          userId: peerData.userId,
          accessHash: peerData.accessHash,
        });
        if (!fullUser || fullUser.users[0].className !== 'User') return false;

        for (const folder of reduxFolders) {
          if (!foldersStorage) return false;
          const folderSynced = JSON.parse(foldersStorage).find((f: any) => {
            return f.id.toString() === folder.externalId && folder.synced;
          });

          if (!folderSynced) continue;
          if (
            (folderSynced.contacts && !!fullUser.users[0].contact) ||
            (folderSynced.nonContacts && !fullUser.users[0].contact)
          ) {
            onDynamicProperty = true;
          }
        }
        break;
      case 'InputPeerChannel':
        if (!peerData || peerData.className !== 'InputPeerChannel') return false;

        const fullChannel = await tgClient.getFullChannelInfo({
          channelId: peerData.channelId,
          accessHash: peerData.accessHash,
        });

        if (!fullChannel || fullChannel.chats[0].className !== 'Channel') return false;

        for (const folder of reduxFolders) {
          if (!foldersStorage) return false;
          const folderSynced = foldersStorage.find((f: any) => {
            return f.id.toString() === folder.externalId && folder.synced;
          });

          if (!folderSynced) continue;

          if (
            (folderSynced.broadcasts && fullChannel.chats[0].broadcast) ||
            (folderSynced.groups && fullChannel.chats[0].megagroup)
          ) {
            onDynamicProperty = true;
          }
        }
        break;
      case 'InputPeerChat':
        if (!peerData || peerData.className !== 'InputPeerChat') return false;

        const fullChat = await tgClient.getFullChat(peerData.chatId);

        if (!fullChat || fullChat.chats[0].className !== 'Chat') return false;

        for (const folder of reduxFolders) {
          if (!foldersStorage) return false;
          const folderSynced = foldersStorage.find((f: any) => {
            return f.id.toString() === folder.externalId && folder.synced;
          });

          if (!folderSynced) continue;

          if (folderSynced.groups && !fullChat.chats[0].deactivated) {
            onDynamicProperty = true;
          }
        }
        break;
      default:
        break;
    }

    return onDynamicProperty;
  };

  const handleNewMessageUser = async (event: any) => {
    let shouldSend;
    const conversationData = {
      lastMessageAt: new Date(event.date * 1000).toISOString(),
      externalId: event.userId.toString(),
      conversationType: ConversationType.Individual,
    };

    // Find if the conversation is in any of the synced folders
    shouldSend = isIncludedPeer(conversationData);

    // If not shouldSend, check if its inside any DynamicProperty
    if (!shouldSend) {
      shouldSend = await isDynamicProperty(conversationData, 'InputPeerUser');
    }

    if (shouldSend) {
      addConversationToQueue(conversationData);
    }
  };

  const handleNewMessageChannel = async (event: any) => {
    let shouldSend;
    const conversationData = {
      lastMessageAt: new Date(event.message.date * 1000).toISOString(),
      externalId: event.message.peerId.channelId.toString(),
    };

    // Find if the conversation is in any of the synced folders
    shouldSend = isIncludedPeer(conversationData);

    // If not shouldSend, check if its inside any DynamicProperty
    if (!shouldSend) {
      shouldSend = await isDynamicProperty(conversationData, 'InputPeerChannel');
    }

    if (shouldSend) {
      addConversationToQueue(conversationData);
    }
  };

  const handleNewChatMessage = async (event: any) => {
    let shouldSend;
    const conversationData = {
      lastMessageAt: new Date(event.date * 1000).toISOString(),
      externalId: event.chatId.toString(),
    };

    shouldSend = isIncludedPeer(conversationData);

    if (!shouldSend) {
      shouldSend = await isDynamicProperty(conversationData, 'InputPeerChat');
    }

    if (shouldSend) {
      addConversationToQueue(conversationData);
    }
  };

  const handleFolderChange = async (event: any) => {
    console.debug('folder change', event);
  };

  const handleChannelChange = async (event: any) => {
    console.debug('channel change', event);
  };

  return {
    initListeners,
  };
};

/*
- Get old status of the channel
- Get old included peers

 */
