import { useWindowSize } from 'react-use';
import { Outlet, useMatch } from 'react-router-dom';
import { Stack } from '@mui/joy';
import BaseLayout from 'page/Base';
import { produceBaseLayoutStyles, produceStackStylesOne } from 'page/auth/styles';

function AuthLayout() {
  const homepath = useMatch('/');
  const { height } = useWindowSize();

  return (
    <BaseLayout sx={produceBaseLayoutStyles(height)}>
      <Stack direction="row" sx={produceStackStylesOne(height, homepath)}>
        <Outlet />
      </Stack>
    </BaseLayout>
  );
}

export default AuthLayout;
