export type ChromeMessageResponse = {
  success: boolean;
};

export enum ChromeExtensionMessageType {
  Login = 'CHROME_EXTENSION_LOGIN',
  InstallCheck = 'CHROME_EXTENSION_INSTALL_CHECK',
}

export interface IChromeExtensionClient {
  sendMessage(message: string): Promise<ChromeMessageResponse>;
  isChromeRuntime(): boolean;
}

export class ChromeExtensionClient implements IChromeExtensionClient {
  isChromeRuntime(): boolean {
    return !!window.chrome?.runtime;
  }
  sendMessage(message: any): Promise<ChromeMessageResponse> {
    const responseF: Promise<ChromeMessageResponse> = new Promise((resolve, reject) => {
      if (window.chrome?.runtime) {
        window.chrome.runtime.sendMessage(
          process.env.REACT_APP_CHROME_EXTENSION_ID!,
          message,
          (response: ChromeMessageResponse) => {
            resolve(response);
          }
        );
      } else {
        reject('Not a chrome runtime');
      }
    });
    return responseF;
  }
}
