import { Box, ListItem, ListItemContent, ListItemDecorator, Stack, Tooltip, Typography } from '@mui/joy';
import {
  avatarFromName,
  generateColorFromOid,
  HoverNotesTime,
  mapToLabel,
  NotesTime,
  sortUsers,
} from 'services/utils/helpers';
import 'theme/styles/tooltip.css';
import { ReactComponent as IcHistory } from 'assets/icons/ic_history.svg';
import useSnackbarContext from 'services/hooks/useSnackbar';
import { SnackBarVariantEnum } from 'components/SnackBar/SnackBar';
import React, { useEffect, useMemo, useState } from 'react';
import Icons from 'assets/icons';
import { EntityType } from 'services/models/domain/entityTypes';
import { useNotesEntity } from 'services/hooks/useNotesEntity';
import { Descendant } from 'slate';
import {
  descendantToHtml,
  descendantToString,
  emptyMessage,
  RichTextInputArea,
  stringToDescendant,
} from '@3rm-co/ui-library';
import parse from 'html-react-parser';
import Translations from 'const/translations/en';
import CustomAvatar from 'components/CustomAvatar';
import NotesActions from 'components/NotesItem/NotesActions';
import { Note } from 'services/models/domain/note';
import { useSelector } from 'react-redux';
import { authState } from 'services/store/slices/authSlice';

export type NoteState = {
  isEditing: boolean;
  shouldHideOthers: boolean;
  selectedNote: Note | null;
};

interface Props {
  note: Note;
  setNoteState: React.Dispatch<React.SetStateAction<NoteState>>;
  state: NoteState;
  entityType: EntityType;
  entityOid: string;
}

export default function NotesItem({ note, state, entityType, entityOid, setNoteState }: Props) {
  const { user } = useSelector(authState);

  const [newNotesText, setNewNotesText] = useState<Descendant[]>(stringToDescendant(note?.text ?? ''));

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { updateNote, deleteNote } = useNotesEntity(entityOid, entityType);

  useEffect(() => {
    setNewNotesText(stringToDescendant(note.text));
  }, [note.text]);

  const { setSnackbarOpenStatus } = useSnackbarContext();

  const handleEditAction = () => {
    setIsEditing(true);
    setNoteState({ ...state, shouldHideOthers: true, selectedNote: note });
  };

  const handleCancel = () => {
    setIsEditing(false);
    setNoteState({ ...state, shouldHideOthers: false, selectedNote: null });
    setNewNotesText(stringToDescendant(note.text));
  };

  const handleDeleteAction = async () => {
    setNoteState({ ...state, shouldHideOthers: false, selectedNote: null });
    setIsEditing(false);
    deleteNote(note.oid);
    handleSnackBar(Translations.SNACKBAR_NOTE_DELETED, SnackBarVariantEnum.error, Icons.ic_warning_snackbar);
  };

  const handleEdit = async () => {
    updateNote(note.oid, descendantToString(newNotesText));
    setIsEditing(false);
    setNoteState({ ...state, shouldHideOthers: false, selectedNote: null });
  };

  const handleSnackBar = (message: string, variant: SnackBarVariantEnum, icon: string) => {
    setSnackbarOpenStatus(true, message, variant, icon);
    setTimeout(() => {
      setSnackbarOpenStatus(false, '', SnackBarVariantEnum.info);
    }, 5000);
  };

  const noteInDescendant = stringToDescendant(note?.text ?? '');
  const noteInHtml = parse(`<div class="rte-text">${descendantToHtml({ children: noteInDescendant })}</div>`);

  const orgUsers = user?.actingAsMemberOfOrganization?.listOfUsersInOrganization;
  const sortedOrgUsers = useMemo(
    () => (orgUsers?.length && user?.oid ? sortUsers(orgUsers, user.oid).map(mapToLabel) : []),
    [orgUsers, user?.oid]
  );

  const onSaveClick = () => {
    if (descendantToString(newNotesText) === descendantToString(emptyMessage)) {
      handleCancel();
    } else {
      handleEdit();
    }
  };

  return (
    <ListItem
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        gap: '8px',
        padding: '8px 12px 8px 16px',
        gridTemplateColumns: 'minmax(100px, 50px) 30px 1fr',
        opacity: !state.shouldHideOthers || state.selectedNote?.oid === note.oid ? 1 : 0.3,
        pointerEvents: !state.shouldHideOthers || state.selectedNote?.oid === note.oid ? 'auto' : 'none',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'var(--joy-palette-primary-plainBorder)',
          cursor: 'pointer',
          '& .showActions': { opacity: 1 },
        },
        borderBottom: isEditing ? '1px dashed #ECF0F3' : '0px',
        overflow: 'hidden !important',
        overflowWrap: 'break-word',
        overflowBlock: 'auto',
      }}
    >
      <ListItemDecorator sx={{ flexShrink: 0 }}>
        <CustomAvatar
          index={generateColorFromOid(note.createdByUserOid.oid ?? '')}
          size={16}
          userInitials={avatarFromName(note.user?.fullName ?? '')}
          imgUrl={note.user?.profileImageUrl}
        />
      </ListItemDecorator>
      <ListItemContent sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '4px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            level="micro"
            sx={{ color: 'secondary.dark' }}
            endDecorator={
              note.updatedAt?.utcTimeStampAsString && (
                <Tooltip
                  title={
                    <Typography>
                      {Translations.NOTES_LAST_EDITED}{' '}
                      {HoverNotesTime({ toDate: note?.updatedAt?.utcTimeStampAsString })}
                    </Typography>
                  }
                  arrow
                  placement="top"
                  componentsProps={{
                    root: { sx: { p: '6px 12px' } },
                    arrow: {
                      sx: {
                        position: 'relative',
                        '&::before': {
                          backgroundColor: 'secondary.dark',
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          width: 8,
                          height: 8,
                          top: -1,
                          transform: 'rotate(45deg)',
                          left: 'calc(50% - 6px)',
                        },
                        '&::after': {
                          backgroundColor: 'secondary.dark',
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          width: 8,
                          height: 8,
                          top: -1,
                          transform: 'rotate(45deg)',
                          left: 'calc(50% - 6px)',
                        },
                      },
                    },
                  }}
                >
                  <IcHistory />
                </Tooltip>
              )
            }
          >
            {note?.user?.fullName}
          </Typography>
          <Box
            sx={{
              mx: '4px',
              borderRadius: '50%',
              width: '2px',
              height: '2px',
              background: 'var(--joy-palette-info-plainBorder)',
            }}
          />
          <Tooltip
            arrow
            placement="top-start"
            enterDelay={400}
            enterTouchDelay={400}
            enterNextDelay={400}
            variant={'solid'}
            title={
              note?.createdAt?.utcTimeStampAsString
                ? HoverNotesTime({ toDate: note?.createdAt?.utcTimeStampAsString })
                : '_'
            }
            componentsProps={{
              root: { sx: { p: '6px 12px' } },
              arrow: {
                sx: {
                  position: 'relative',
                  width: '100px',
                  '&::before': {
                    backgroundColor: 'secondary.dark',
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    width: 8,
                    height: 8,
                    top: -1,
                    transform: 'rotate(45deg)',
                    left: 'calc(50% - 20px)',
                  },
                  '&::after': {
                    backgroundColor: 'secondary.dark',
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    width: 8,
                    height: 8,
                    top: -1,
                    transform: 'rotate(45deg)',
                    left: 'calc(50% - 20px)',
                  },
                },
              },
            }}
          >
            <Typography level="micro" sx={{ color: 'info.main', width: 'auto' }}>
              {note?.createdAt?.utcTimeStampAsString
                ? NotesTime({ toDate: note?.createdAt?.utcTimeStampAsString })
                : '_'}
            </Typography>
          </Tooltip>
        </Box>
        <Stack direction={'column'} sx={{ justifyContent: 'space-between', alignItems: 'center', display: 'block' }}>
          {!isEditing ? (
            <Box
              sx={{
                whiteSpace: 'pre-wrap',
                fontSize: '14px',
                lineHeight: '18px',
                letterSpacing: '-0.01em',
                '& p': {
                  minHeight: '18px',
                },
              }}
            >
              {noteInHtml}
            </Box>
          ) : (
            <RichTextInputArea
              loadedValue={note.text}
              setValue={setNewNotesText}
              listOfLabels={sortedOrgUsers}
              placeholderText={Translations.NOTES_PLACEHOLDER}
              className={'parent-focused'}
              onSaveClick={onSaveClick}
              onCancelClick={handleCancel}
              onSaveEnterHotkey={onSaveClick}
              showToolbar
            />
          )}
        </Stack>
      </ListItemContent>
      {!isEditing && !state.shouldHideOthers && user?.oid === note.createdByUserOid.oid && (
        <NotesActions className="showActions" handleEdit={handleEditAction} handleDelete={handleDeleteAction} />
      )}
    </ListItem>
  );
}
