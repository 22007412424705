import {
  SortConversationsOnPropertyInput,
  SortConversationsOnPropertyType,
  SortDirectionType,
} from 'services/models/api/generated';
import { EntityPropertyDefinition } from 'services/models/domain/entityViews';
import { CHATS_DEFAULT_COLUMN_NAMES } from 'components/Chats/ChatTableV2/constants';

export const getSortPropertyTypeByField = (fieldName: string) => {
  switch (fieldName) {
    case CHATS_DEFAULT_COLUMN_NAMES.FollowUp:
      return SortConversationsOnPropertyType.LastFollowupDate;
    case CHATS_DEFAULT_COLUMN_NAMES.LatestMessage:
      return SortConversationsOnPropertyType.LastMessageDate;
    case CHATS_DEFAULT_COLUMN_NAMES.DateAdded:
      return SortConversationsOnPropertyType.DateAdded;
    case CHATS_DEFAULT_COLUMN_NAMES.Note:
      return SortConversationsOnPropertyType.NoteCreated;
    default:
      return null;
  }
};

export const getFieldBySort = (sortTypeName: string) => {
  switch (sortTypeName) {
    case SortConversationsOnPropertyType.LastFollowupDate:
      return CHATS_DEFAULT_COLUMN_NAMES.FollowUp;
    case SortConversationsOnPropertyType.LastMessageDate:
      return CHATS_DEFAULT_COLUMN_NAMES.LatestMessage;
    case CHATS_DEFAULT_COLUMN_NAMES.DateAdded:
      return SortConversationsOnPropertyType.DateAdded;
    default:
      return sortTypeName;
  }
};

export const getFieldSortStatus = (
  sortOnProperties: SortConversationsOnPropertyInput[],
  field: string,
  property?: EntityPropertyDefinition
) => {
  let isSortingActive = false;
  let isDesc = false;

  if (sortOnProperties && sortOnProperties.length) {
    sortOnProperties.map((sortingItem) => {
      const sortPropertyOid = sortingItem.propertyDefinitionOid;
      const sortPropertyType = sortingItem.sortConversationsOnPropertyType;
      const sortPropertyTypeByField = getSortPropertyTypeByField(field);

      const isSortedByPropertyOid = Boolean(sortPropertyOid) && sortPropertyOid === property?.oid.oid;
      const isSortByPropertyType = Boolean(sortPropertyType) && sortPropertyType === sortPropertyTypeByField;

      if (isSortedByPropertyOid || isSortByPropertyType) {
        isSortingActive = true;
        isDesc = sortingItem.sortDirection === SortDirectionType.Desc;
      }
    });
  }

  return { isSortingActive, isDesc };
};
