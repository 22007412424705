import { Box } from '@mui/joy';
import { Menu } from '@mui/material';

import { CellMenuProps } from 'components/ui-library/chat/CellMenu/interface';
import { MenuSx, AnchorSx } from 'components/ui-library/chat/CellMenu/styled';
import { useMenuState } from 'services/hooks/useCellMenuState';
import { useEffect } from 'react';

const CellMenu = (props: CellMenuProps) => {
  const { open, anchorEl, handleCellClick, handleMenuClose, setAnchorEl } = useMenuState();
  const {
    anchorOrigin,
    transformOrigin,
    id,
    sx,
    anchorSx,
    anchorComponent,
    children,
    onClose,
    anchorEl: propAnchorEl,
    setOpen,
    ...rest
  } = props;
  const sxProp = { ...MenuSx, ...sx };

  const renderChildren = () => {
    if (Array.isArray(children)) {
      return children.map((child, index) => {
        return typeof child === 'function' ? child(handleMenuClose) : <Box key={`${index}-cellMenu`}>{child}</Box>;
      });
    } else {
      return typeof children === 'function' ? children(handleMenuClose) : children;
    }
  };

  const onMenuClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    onClose && onClose(event, reason);
    handleMenuClose();
    if (props.onClose) {
      props.onClose();
    }
  };

  useEffect(() => {
    if (setOpen) {
      setOpen(open);
    }
  }, [open]);

  useEffect(() => {
    setAnchorEl(propAnchorEl as HTMLElement | null);
  }, [propAnchorEl]);

  return (
    <>
      <Menu
        id={id && 'property-menu'}
        anchorEl={anchorEl}
        open={open}
        aria-labelledby="property-menu-button"
        anchorOrigin={
          anchorOrigin ?? {
            vertical: 'bottom',
            horizontal: 'left',
          }
        }
        transformOrigin={
          transformOrigin ?? {
            vertical: 'top',
            horizontal: 'left',
          }
        }
        {...rest}
        onClose={onMenuClose}
        sx={{ ...sxProp, zIndex: 999999 }}
        transitionDuration={0}
      >
        {renderChildren()}
      </Menu>
      <Box sx={anchorSx || AnchorSx} onClick={handleCellClick}>
        {anchorComponent}
      </Box>
    </>
  );
};

export default CellMenu;
