import { SxProps } from '@mui/material';
import { colors } from 'theme/colors';

export const TooltipContainer: SxProps = {
  width: '200px',
  display: 'flex',
  padding: '4px',
  flex: '1 1 auto',
  background: colors.white,
  boxShadow: '0.5px 4px 10px rgba(0,0,0,.12)',
};

export const FolderIcon: SxProps = {
  clipPath: 'polygon(0% 14%, 23% 14%, 36% 29%, 99% 30%, 99% 99%, 0% 99%)',
  backgroundColor: colors.primary[20],
  height: '20px',
  width: '22px',
};

export const FolderIconImage: React.CSSProperties = {
  clipPath: 'polygon(0% 14%, 23% 14%, 36% 29%, 99% 30%, 99% 99%, 0% 99%)',
  height: '20px',
  width: '22px',
};
