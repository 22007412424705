import { Box, Modal, ModalClose, Sheet, TextField, Typography } from '@mui/joy';
import Icons from 'assets/icons';
import AppButton from 'components/Buttons/Button';
import PropertyTypeAheadWithBackground from 'components/Chats/ChatTableV2/TableCell/customCells/PropertyTypeahead/PropertyTypeAheadWithBackground';
import { SelectedPropertyValue } from 'components/PropertiesList';
import { SnackBarVariantEnum } from 'components/SnackBar/SnackBar';
import Translations from 'const/translations/en';
import { ModalStyle } from 'page/modal/styles';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useConversations } from 'services/hooks/useConversations';
import useSnackbarContext from 'services/hooks/useSnackbar';
import { ConversationOrigin } from 'services/models/api/generated';
import { Conversation } from 'services/models/domain/conversation';
import { EntityPropertyDefinition, OptionPropertyValue } from 'services/models/domain/entityViews';
import { authState } from 'services/store/slices/authSlice';
import { entityViewsState } from 'services/store/slices/entityViewsSlice';
import { isValidEmail, isValidLink } from 'services/utils/conversationUtils';
import { colors } from 'theme/colors';

interface Props {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  conversation?: Conversation;
}

const CreateChatModal = ({ isOpen, setIsOpen, conversation }: Props) => {
  const { user } = useSelector(authState);

  const { entityViews } = useSelector(entityViewsState);
  const channelPropertyDefinition: EntityPropertyDefinition | undefined = entityViews.propertyDefinitions.find(
    (pd) => pd.name === 'Channel'
  );
  const { createConversation, setChannelFromLink, editConversation } = useConversations();
  const [title, setTitle] = useState<string | undefined>();
  const [propertyValueOids, setPropertyValueOids] = useState<SelectedPropertyValue[]>([]);
  const [link, setLink] = useState<string | undefined>();
  const [isValidLinkOrEmail, setIsValidLinkOrEmail] = useState<boolean>(true);
  const { setSnackbarOpenStatus } = useSnackbarContext();

  useEffect(() => {
    if (conversation) {
      setTitle(conversation.name);
      setLink(conversation.chatLink);
      setPredefinedProperties('Channel');
    }
  }, [conversation]);

  const handleSnackBar = (message: string, variant: SnackBarVariantEnum, icon: string) => {
    setSnackbarOpenStatus(true, message, variant, icon);
    setTimeout(() => {
      setSnackbarOpenStatus(false, '', variant);
    }, 5000);
  };

  const setPredefinedProperties = (propertyName: string) => {
    if (entityViews.views.length === 0) return;
    const channelPropertyDefinitionOid = channelPropertyDefinition?.oid.oid;
    const channelProperty = conversation?.properties?.find(
      (p) => p.propertyDefinitionOid.oid === channelPropertyDefinitionOid
    ) as OptionPropertyValue | undefined;
    if (channelProperty?.oid.oid && channelPropertyDefinitionOid) {
      setPropertyValueOids([
        {
          oid: channelProperty?.oid.oid,
          propertyDefinitionOid: channelPropertyDefinitionOid,
        },
      ]);
    }
  };

  const onCreateClick = async () => {
    if (title && channelPropertyDefinition) {
      setIsOpen(false);
      conversation
        ? await editConversation(
            conversation,
            ConversationOrigin.Manual,
            title,
            propertyValueOids,
            channelPropertyDefinition,
            link
          )
        : await createConversation(title, propertyValueOids, channelPropertyDefinition, link);
      !conversation &&
        handleSnackBar(
          `${Translations.CHAT_CREATED} ${user?.actingAsMemberOfOrganization.name}'s chats`,
          SnackBarVariantEnum.greenSuccess,
          Icons.ic_check_light
        );
      setTitle(undefined);
    }
  };

  const canCreateConversation = () => {
    return !title && title !== '';
  };

  const onClose = () => {
    setIsOpen(false);
    setTitle(undefined);
    setPropertyValueOids([]);
    setLink(undefined);
    setIsValidLinkOrEmail(true);
  };

  const assignChannelFromLink = (link: string | undefined) => {
    if (link) {
      if (!isValidLink(link) && !isValidEmail(link)) {
        setIsValidLinkOrEmail(false);
      } else {
        setIsValidLinkOrEmail(true);
        const propertyValue = channelPropertyDefinition && setChannelFromLink(channelPropertyDefinition, link);
        if (propertyValue?.oid && propertyValue.propertyDefinitionOid) {
          setPropertyValueOids([propertyValue]);
        } else {
          setPropertyValueOids([]);
        }
      }
    }
  };

  const onTextInputBlur = () => {
    if (title === '') {
      setTitle(undefined);
    }
    assignChannelFromLink(link);
  };

  const renderHeader = () => {
    return (
      <Box sx={styles.headerContainer}>
        <Typography id="modal-title" level="large+" sx={{ color: colors.neutral[70], marginY: '10px' }}>
          {`${Translations.CREATE} ${Translations.CHAT}`}
        </Typography>
        <ModalClose sx={styles.modalClose} />
      </Box>
    );
  };

  const renderProperty = (pd: EntityPropertyDefinition) => {
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        sx={{ marginRight: '16px', marginBottom: '8px', width: 'calc(50% - 16px)' }}
        key={pd.oid.oid}
      >
        <PropertyTypeAheadWithBackground
          propertyDefinition={pd}
          values={propertyValueOids.filter((pv) => pv.propertyDefinitionOid === pd.oid.oid).map((pv) => pv.oid)}
          entityId={conversation?.oid.oid || ''}
          handleChange={(selectedValues) => {
            const filteredValues = propertyValueOids.filter((oid) => oid.propertyDefinitionOid !== pd.oid.oid);
            const uniqueAfterOids = selectedValues.map((sv) => sv.oid.oid);
            const updated = [
              ...filteredValues,
              ...uniqueAfterOids.map((oid) => {
                return { oid: oid, propertyDefinitionOid: pd.oid.oid };
              }),
            ];
            setPropertyValueOids(updated);
          }}
          height={20}
        />
      </Box>
    );
  };

  const renderProperties = (propertyDefinition: EntityPropertyDefinition | undefined) => {
    if (!propertyDefinition) {
      return <></>;
    }
    return (
      <Box sx={{ display: 'flex', maxHeight: '380px', overflowY: 'scroll', flexWrap: 'wrap', marginLeft: '12px' }}>
        {renderProperty(propertyDefinition)}
      </Box>
    );
  };

  const renderInput = (
    title: string,
    placeholder: string,
    input: string | undefined,
    onChange: (value: string) => void,
    required?: boolean,
    error?: boolean,
    errorText?: string
  ) => {
    return (
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{ marginRight: '16px', marginBottom: '8px', marginLeft: '12px' }}
      >
        <Box display={'flex'}>
          <Typography level="tiny" sx={{ color: error ? colors.warning : colors.neutral[70], marginY: '4px' }}>
            {title}
          </Typography>
          {required && (
            <Typography level="tiny" sx={{ color: colors.neutral[50], marginY: '4px', marginLeft: '1px' }}>
              {` *`}
            </Typography>
          )}
        </Box>
        <TextField
          size="md"
          value={input}
          error={error}
          color={error ? 'danger' : undefined}
          placeholder={placeholder}
          sx={{ flexDirection: 'column !important' }}
          disabled={false}
          onChange={(evt) => onChange(evt.currentTarget.value)}
          onBlur={onTextInputBlur}
        />
        {errorText && error && (
          <Typography level="tiny" sx={{ color: colors.warning, marginY: '4px' }}>
            {errorText}
          </Typography>
        )}
      </Box>
    );
  };

  const renderDetails = () => {
    return (
      <Box sx={{ marginBottom: '12px' }}>
        {renderInput(`${Translations.CHAT_TITLE} `, Translations.CHAT_OR_PROJECT_NAME, title, setTitle, true)}
        {renderInput(
          `${Translations.CHAT} ${Translations.LINK}`,
          Translations.LINK,
          link,
          setLink,
          false,
          !isValidLinkOrEmail,
          Translations.LINK_OR_EMAIL_ERROR
        )}
        {entityViews &&
          entityViews.views.length > 0 &&
          channelPropertyDefinition &&
          renderProperties(channelPropertyDefinition)}
      </Box>
    );
  };
  return (
    <>
      <Modal open={isOpen} onClose={(event, reason) => onClose()} sx={ModalStyle}>
        <Sheet variant="plain" sx={styles.sheet}>
          <Box sx={styles.container}>
            {renderHeader()}
            <Box sx={{ backgroundColor: colors.navigationBarBackground, pt: 1, px: 1 }}>
              <Box sx={styles.card}>
                {renderDetails()}
                <Box sx={styles.ButtonContainer}>
                  <AppButton
                    onClick={onCreateClick}
                    fullWidth
                    sx={{ minHeight: '32px' }}
                    disabled={canCreateConversation()}
                  >
                    {conversation ? Translations.EDIT : Translations.CREATE}
                  </AppButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Sheet>
      </Modal>
    </>
  );
};

export default CreateChatModal;

const styles = {
  container: {
    justifyContent: ' flex-start',
    borderRadius: '8px',
    marginBottom: '8px',
    bgcolor: 'background.componentBg',
  },
  sheet: {
    width: { xs: '95%', md: '560px' },
    borderRadius: '8px',
    border: 'none',
  },
  card: {
    bgcolor: 'background.componentBg',
    borderRadius: '8px',
    border: `1px solid ${colors.neutral[10]}`,
    boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.05)',
  },
  modalClose: {
    marginY: '10px',
    justifyContent: 'flex-end',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  headerContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    px: 1.5,
  },
  ButtonContainer: { display: 'flex', justifyContent: 'flex-end', px: 1.5, pb: 1.5, marginRight: '2px' },
};
