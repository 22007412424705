import { useDispatch, useSelector } from 'react-redux';
import { Followup } from 'services/models/domain/followUps';
import * as conversationSlice from 'services/store/slices/conversationsSlice';
import * as gql from 'services/models/api/generated';
import { chatFilters } from 'services/store/slices/conversationsFilterSlice';
import AnalyticKeys, { trackAction } from 'services/utils/analytics';
import { setEntity, sidebarEntityDetailsState } from 'services/store/slices/sidebarEntityDetailsSlice';
import { detailsState, setDetailsEntity } from 'services/store/slices/detailsSlice';
import { Conversation } from 'services/models/domain/conversation';
import { mapUserToDomain } from 'services/utils/userUtils';
import { authState } from 'services/store/slices/authSlice';
import { getDateString } from 'services/utils/helpers';
import dayjs from 'dayjs';
import { FOLLOWUPS_MONTH_RANGE } from 'services/constants';
import { ApolloCache } from '@apollo/client';
import { useMemo } from 'react';

export const useFollowups = (conversation?: Conversation) => {
  const dispatch = useDispatch();
  const { user } = useSelector(authState);
  const filters = useSelector(chatFilters);
  const { entityOid: sidebarEntityOid } = useSelector(sidebarEntityDetailsState);
  const { detailsEntityOid } = useSelector(detailsState);
  const dateRange = useMemo(
    () => [getDateString(dayjs()), getDateString(dayjs().add(FOLLOWUPS_MONTH_RANGE, 'month'))],
    [dayjs()]
  );

  const readFollowupCountQuery = (
    cache: ApolloCache<any>,
    userOid: string,
    dateRange: string[]
  ): gql.GetUserFollowUpCountsWithinRangeQuery | null => {
    return cache.readQuery<gql.GetUserFollowUpCountsWithinRangeQuery>({
      query: gql.GetUserFollowUpCountsWithinRangeDocument,
      variables: {
        input: {
          oid: userOid,
          from: {
            utcTimeStampAsString: dateRange[0],
          },
          to: {
            utcTimeStampAsString: dateRange[1],
          },
        },
      },
    });
  };

  const writeFollowupCountQuery = (
    cache: ApolloCache<any>,
    query: gql.GetUserFollowUpCountsWithinRangeQuery,
    newFollowupCounts: gql.FollowUpCountsByDay[],
    userOid: string,
    dateRange: string[]
  ) => {
    cache.writeQuery({
      query: gql.GetUserFollowUpCountsWithinRangeDocument,
      data: {
        ...query,
        getUserFollowUpCountsWithinRange: {
          ...query.getUserFollowUpCountsWithinRange,
          data: {
            ...query.getUserFollowUpCountsWithinRange.data,
            followupCounts: newFollowupCounts,
          },
        },
      },
      variables: {
        input: {
          oid: userOid,
          from: {
            utcTimeStampAsString: dateRange[0],
          },
          to: {
            utcTimeStampAsString: dateRange[1],
          },
        },
      },
    });
  };

  const addFollowupMutation = gql.useAddFollowupMutation();
  const assignFollowUpMutation = gql.useAssignFollowupMutation({
    update(cache, _result, context) {
      const userOid: string = context.context?.userOid;
      const dueAtTimestamp: string = context.context?.dueAtTimestamp;

      const query = readFollowupCountQuery(cache, userOid, dateRange);
      if (!query) {
        return;
      }
      const { followupCounts } = query.getUserFollowUpCountsWithinRange.data;
      const newFollowupCounts = [...followupCounts];

      const nextDate = dueAtTimestamp.split('T')[0];
      const nextDateIdx = newFollowupCounts.findIndex(
        (followupCount) => followupCount.date.utcTimeStampAsString.split('T')[0] === nextDate
      );
      if (nextDateIdx >= 0) {
        let nextFollowupCount = newFollowupCounts[nextDateIdx];
        nextFollowupCount = {
          ...nextFollowupCount,
          count: nextFollowupCount.count + 1,
        };
        newFollowupCounts[nextDateIdx] = nextFollowupCount;
      } else {
        const nextFollowupCount: gql.FollowUpCountsByDay = {
          __typename: 'FollowUpCountsByDay',
          count: 1,
          date: {
            __typename: 'Date',
            utcTimeStampAsString: nextDate,
          },
        };
        newFollowupCounts.push(nextFollowupCount);
      }
      writeFollowupCountQuery(cache, query, newFollowupCounts, userOid, dateRange);
    },
  });

  const updateFollowupMutation = gql.useUpdateFollowupMutation({
    update(cache, _result, context) {
      const userOid: string = context.context?.userOid;
      const fromDueAtTimestamp: string = context.context?.fromDueAtTimestamp;

      const query = readFollowupCountQuery(cache, userOid, dateRange);
      if (!query) {
        return;
      }
      const { followupCounts } = query.getUserFollowUpCountsWithinRange.data;
      const newFollowupCounts = [...followupCounts];

      const previousDate = fromDueAtTimestamp.split('T')[0];
      const previousDateIdx = newFollowupCounts?.findIndex(
        (followupCount) => followupCount.date.utcTimeStampAsString.split('T')[0] === previousDate
      );
      if (previousDateIdx >= 0) {
        let previousFollowupCount = newFollowupCounts[previousDateIdx];
        previousFollowupCount = {
          ...previousFollowupCount,
          count: previousFollowupCount.count - 1,
        };
        newFollowupCounts[previousDateIdx] = previousFollowupCount;
      }
      writeFollowupCountQuery(cache, query, newFollowupCounts, userOid, dateRange);
    },
  });

  const sidebarHasEntity = conversation?.oid.oid === sidebarEntityOid;
  const detailsPageHasEntity = conversation?.oid.oid === detailsEntityOid;

  const assignFollowup = async (followupOid: string, userOid: string, dueAtTimestamp: string): Promise<boolean> => {
    await assignFollowUpMutation[0]({
      variables: {
        input: { followUpOid: followupOid, userOid: userOid },
      },
      context: {
        userOid,
        dueAtTimestamp,
      },
    });
    return true;
  };

  const updateFollowup = async (from?: Followup, to?: Followup) => {
    if (!conversation) {
      return;
    }
    if (!from && to) {
      const newTempFollowup: Followup = {
        assignedToUser: mapUserToDomain(to.assignedToUser),
        createdByUserOid: user!.oid,
        dueAt: to.dueAt,
      };
      dispatch(
        conversationSlice.updateConversation({
          oid: conversation.oid,
          nextFollowup: newTempFollowup,
        })
      );
      if (sidebarHasEntity) {
        const updatedConversation = { ...conversation, nextFollowup: newTempFollowup };
        dispatch(setEntity(updatedConversation));
      }
      if (detailsPageHasEntity) {
        const updatedConversation = { ...conversation, nextFollowup: newTempFollowup };
        dispatch(setDetailsEntity(updatedConversation));
      }
      if (
        filters.input.sortOn.sortOnProperties[0].sortConversationsOnPropertyType ===
        gql.SortConversationsOnPropertyType.LastFollowupDate
      ) {
        dispatch(
          conversationSlice.sortConversations({
            sortOnProperty: conversationSlice.SortConversationsByPropertyType.LastFollowupDate,
          })
        );
      }

      const addResponse = await addFollowupMutation[0]({
        variables: {
          input: {
            addTo: {
              entityTypeId: gql.EntityType.Conversation,
              oid: conversation.conversationRecordOid.oid,
            },
            followUpAt: {
              utcTimeStampAsString: to.dueAt.utcTimeStampAsString,
            },
          },
        },
      });
      trackAction(AnalyticKeys.ADDED_NEW_FOLLOWUP, {
        organizationId: user?.actingAsMemberOfOrganization.oid,
        organizationName: user?.actingAsMemberOfOrganization.name,
        chatId: conversation.oid.oid,
        chatName: conversation.name,
      });
      if (addResponse.data?.addFollowup.data.oid) {
        await assignFollowup(
          addResponse.data.addFollowup.data.oid.oid,
          to.assignedToUser.oid,
          to.dueAt.utcTimeStampAsString
        );
        const newFollowup: Followup = {
          ...newTempFollowup,
          oid: addResponse.data.addFollowup.data.oid.oid,
        };
        dispatch(
          conversationSlice.updateConversation({
            oid: conversation.oid,
            nextFollowup: newFollowup,
          })
        );
      }
    }
    if (from && !to) {
      dispatch(
        conversationSlice.updateConversation({
          oid: conversation.oid,
          nextFollowup: undefined,
        })
      );
      if (sidebarHasEntity) {
        const updatedConversation = { ...conversation, nextFollowup: undefined };
        // @ts-ignore
        dispatch(setEntity(updatedConversation));
      }
      if (detailsPageHasEntity) {
        const updatedConversation = { ...conversation, nextFollowup: undefined };
        // @ts-ignore
        dispatch(setDetailsEntity(updatedConversation));
      }
      if (
        filters.input.sortOn.sortOnProperties[0].sortConversationsOnPropertyType ===
        gql.SortConversationsOnPropertyType.LastFollowupDate
      ) {
        dispatch(
          conversationSlice.sortConversations({
            sortOnProperty: conversationSlice.SortConversationsByPropertyType.LastFollowupDate,
          })
        );
      }
      if (conversation.nextFollowup?.oid) {
        await updateFollowupMutation[0]({
          variables: {
            input: {
              completed: true,
              newDate: {
                utcTimeStampAsString: from.dueAt.utcTimeStampAsString,
              },
              oid: conversation.nextFollowup?.oid,
            },
          },
          context: {
            userOid: from.assignedToUser.oid,
            fromDueAtTimestamp: from.dueAt.utcTimeStampAsString,
          },
        });
      }
      trackAction(AnalyticKeys.CLEARED_FOLLOWUP, {
        organizationId: user?.actingAsMemberOfOrganization.oid,
        organizationName: user?.actingAsMemberOfOrganization.name,
        chatId: conversation.oid.oid,
        chatName: conversation.name,
      });
    }
    if (from && to) {
      const newTempFollowup: Followup = {
        ...conversation.nextFollowup,
        dueAt: to.dueAt,
        assignedToUser: mapUserToDomain(to.assignedToUser),
        createdByUserOid: to.createdByUserOid,
      };
      dispatch(
        conversationSlice.updateConversation({
          oid: conversation.oid,
          nextFollowup: newTempFollowup,
        })
      );
      if (sidebarHasEntity) {
        const updatedConversation = { ...conversation, nextFollowup: newTempFollowup };
        dispatch(setEntity(updatedConversation));
      }
      if (detailsPageHasEntity) {
        const updatedConversation = { ...conversation, nextFollowup: newTempFollowup };
        dispatch(setDetailsEntity(updatedConversation));
      }
      if (
        filters.input.sortOn.sortOnProperties[0].sortConversationsOnPropertyType ===
        gql.SortConversationsOnPropertyType.LastFollowupDate
      ) {
        dispatch(
          conversationSlice.sortConversations({
            sortOnProperty: conversationSlice.SortConversationsByPropertyType.LastFollowupDate,
          })
        );
      }
      await assignFollowup(from.oid!, to.assignedToUser.oid, to.dueAt.utcTimeStampAsString);
      if (conversation.nextFollowup?.oid) {
        await updateFollowupMutation[0]({
          variables: {
            input: {
              completed: false,
              newDate: {
                utcTimeStampAsString: to.dueAt.utcTimeStampAsString,
              },
              oid: conversation.nextFollowup?.oid || '',
            },
          },
          context: {
            userOid: from.assignedToUser.oid,
            fromDueAtTimestamp: from.dueAt.utcTimeStampAsString,
          },
        });
      }
      if (to.dueAt !== from.dueAt) {
        trackAction(AnalyticKeys.RESET_FOLLOW_DATE, {
          organizationId: user?.actingAsMemberOfOrganization.oid,
          organizationName: user?.actingAsMemberOfOrganization.name,
          chatId: conversation.oid.oid,
          chatName: conversation.name,
        });
      }
      if (to.assignedToUser.oid !== from.assignedToUser.oid) {
        trackAction(AnalyticKeys.REASSIGNED_FOLLOWUP, {
          organizationId: user?.actingAsMemberOfOrganization.oid,
          organizationName: user?.actingAsMemberOfOrganization.name,
          chatId: conversation.oid.oid,
          chatName: conversation.name,
        });
      }
    }
  };

  return {
    updateFollowup,
  };
};
