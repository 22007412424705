import { Box, Chip, Divider, Typography } from '@mui/joy';
import { IconsSvg } from 'assets/icons';
import { Flex1, FlexRow } from 'theme/flex';
import { colors } from 'theme/colors';
import { useEffect, useState } from 'react';
import { PropertyFilterMenu } from 'page/chats/ChatTableFilterBar/FilterOptions/PropertyFilterMenu';
import FilterDateSelector from 'components/DateRangeSelector';
import { DateRangeField, FilterIdentifier, FilterOption } from 'services/models/domain/entityFilter';
import { FolderFilterMenu } from 'page/chats/ChatTableFilterBar/FilterOptions/FolderFilterMenu';
import { UserFilterMenu } from 'page/chats/ChatTableFilterBar/FilterOptions/UserFilterMenu';
import { FollowupFilterMenu } from 'page/chats/ChatTableFilterBar/FilterOptions/FollowupFilterMenu';
import { toKebabCase } from 'services/utils/helpers';
export interface FilterOptionMenuProps {
  selectedFilterOption?: FilterOption;
  filterOptions: FilterOption[][];
  onSelectFilterOption: (filterItem: FilterOption) => void;
  onClose: () => void;
}

export const FilterOptionMenu = (props: FilterOptionMenuProps) => {
  const [selectedFilterOption, setSelectedFilterOption] = useState<FilterOption | undefined>(undefined);

  useEffect(() => {
    setSelectedFilterOption(props.selectedFilterOption);
  }, [props.selectedFilterOption]);

  const renderFilterOption = (filterOption: FilterOption) => {
    const { identifier, title, icon } = filterOption;
    const Icon = IconsSvg[icon as keyof typeof IconsSvg];

    const handleOnClick = () => {
      props.onSelectFilterOption(filterOption);
      setSelectedFilterOption(filterOption);
    };

    return (
      <Box
        id={`filter-option-${toKebabCase(identifier)}`}
        role="menuitem"
        sx={{
          ...Flex1,
          ...FlexRow,
          '&:hover': {
            background: `${colors.neutral[0]}`,
          },
        }}
        key={filterOption.identifier}
      >
        <Chip variant={'plain'} color={'neutral'} onClick={handleOnClick} sx={Flex1}>
          <Box sx={Flex1}>
            <Icon height={15} width={15} stroke={colors.neutral[60]} style={{ marginTop: '2px' }} />

            <Typography
              level="inherit"
              sx={{
                lineHeight: '18px',
                letterSpacing: '-0.14px',
                color: colors.neutral[60],
                marginLeft: '8px',
              }}
            >
              {title}
            </Typography>
          </Box>
        </Chip>
      </Box>
    );
  };

  const renderFilterOptions = (filterOptions: FilterOption[][]) => {
    return (
      <Box flexDirection="column">
        {filterOptions.map((filterOptionsGroup, index) => {
          return (
            <>
              {!!selectedFilterOption && (
                <>
                  <Box sx={style.menuHeader}>
                    <Typography level="tiny" sx={{ color: colors.neutral[50] }} onClick={() => {}}>
                      {selectedFilterOption.title}
                    </Typography>
                  </Box>
                  <Divider sx={{ border: '1.3px dashed #fff', background: 'var(--joy-palette-info-plainBorder)' }} />
                </>
              )}
              {filterOptionsGroup.map(renderFilterOption)}
              {index < filterOptions.length - 1 && (
                <Divider sx={{ border: '1px solid var(--joy-palette-primary-plainBorder)' }} />
              )}
            </>
          );
        })}
      </Box>
    );
  };

  const filterOptions =
    selectedFilterOption && 'subItems' in selectedFilterOption ? selectedFilterOption.subItems : props.filterOptions;

  const selectFilterOption = (filterOption: FilterOption) => {
    props.onSelectFilterOption(filterOption);
  };

  const renderSelectedFilterOption = (): JSX.Element => {
    if (selectedFilterOption) {
      if (
        [
          FilterIdentifier.Category,
          FilterIdentifier.Status,
          FilterIdentifier.Owner,
          FilterIdentifier.Property,
        ].includes(selectedFilterOption?.identifier)
      ) {
        return (
          <>
            <PropertyFilterMenu
              filterOption={selectedFilterOption}
              selectedFilter={selectedFilterOption}
              onChange={(propertyDefinition: string, propertyValueOids: string[], noPropertyValueSet: boolean) => {
                const updatedFilterOption: FilterOption = {
                  ...selectedFilterOption,
                  value: {
                    propertyDefinitionOid: propertyDefinition,
                    propertyValueOids: propertyValueOids,
                    noPropertyDefinitionOid: noPropertyValueSet ? propertyDefinition : undefined,
                  },
                };
                selectFilterOption(updatedFilterOption);
              }}
              onClose={props.onClose}
            />
          </>
        );
      }

      if ([FilterIdentifier.ChatMembers, FilterIdentifier.AddedBy].includes(selectedFilterOption?.identifier)) {
        if ('subItems' in selectedFilterOption) {
          return (
            <>
              <UserFilterMenu
                filterOption={selectedFilterOption}
                selectedFilter={selectedFilterOption}
                onChange={(userOids: string[]) => {
                  let valueProperty = {};
                  if (selectedFilterOption.identifier === FilterIdentifier.ChatMembers) {
                    valueProperty = { participantOids: userOids };
                  } else if (selectedFilterOption.identifier === FilterIdentifier.AddedBy) {
                    valueProperty = { userOids: userOids };
                  }
                  const updatedFilterOption: FilterOption = {
                    ...selectedFilterOption,
                    value: valueProperty,
                  };
                  selectFilterOption(updatedFilterOption);
                }}
                onClose={props.onClose}
              />
            </>
          );
        }
      }

      if (selectedFilterOption?.identifier === FilterIdentifier.FollowUps) {
        if ('subItems' in selectedFilterOption) {
          return (
            <FollowupFilterMenu
              filterOption={selectedFilterOption}
              selectedFilterOption={selectedFilterOption}
              onAssigneesChanged={(userOids, noFollowupAssigned) => {
                // TODO (AWE): Fix
                const updatedFilterOption: FilterOption = {
                  ...selectedFilterOption,
                  value: {
                    // @ts-ignore
                    ...selectedFilterOption.value,
                    followUpsAssignedTo: userOids,
                    noFollowupAssigned: noFollowupAssigned,
                  },
                };
                selectFilterOption(updatedFilterOption);
              }}
              onDateRangeChanged={(dateRangeId, from, to) => {
                // TODO (AWE): Fix
                const updatedFilterOption: FilterOption = {
                  ...selectedFilterOption,
                  value: {
                    // @ts-ignore
                    ...selectedFilterOption.value,
                    dateRangeId: dateRangeId,
                    onField: DateRangeField.LastFollowupDate,
                    from: from,
                    to: to,
                  },
                };
                selectFilterOption(updatedFilterOption);
              }}
              onClose={props.onClose}
            />
          );
        }
      }

      if (selectedFilterOption?.identifier === FilterIdentifier.Folder) {
        if ('subItems' in selectedFilterOption) {
          return (
            <FolderFilterMenu
              filterOptions={selectedFilterOption.subItems.flat()}
              selectedFilter={selectedFilterOption}
              onChanged={(selectedFolderOids) => {
                const updatedFilterOption: FilterOption = {
                  ...selectedFilterOption,
                  value: {
                    folderOids: selectedFolderOids,
                  },
                };
                selectFilterOption(updatedFilterOption);
              }}
              onClose={props.onClose}
            />
          );
        }
        return <></>;
      }

      if (
        [FilterIdentifier.DateAdded, FilterIdentifier.LatestMessage, FilterIdentifier].includes(
          selectedFilterOption.identifier
        )
      ) {
        let dateRange: { from?: Date; to?: Date } | undefined = undefined;

        if ('value' in selectedFilterOption) {
          const { value } = selectedFilterOption;
          if ('from' in value || 'to' in value) {
            dateRange = {
              from: value.from,
              to: value.to,
            };
          }
        }
        return (
          <FilterDateSelector
            title={selectedFilterOption.title}
            dateRange={dateRange}
            onDatesSelected={(value, days?: number) => {
              const updatedOption: FilterOption = {
                ...selectedFilterOption,
                value: { from: value[0]?.toJSDate(), to: value[1]?.toJSDate(), days: days },
              };
              selectFilterOption(updatedOption);
            }}
            onClose={props.onClose}
          />
        );
      }
    }
    return renderFilterOptions(filterOptions);
  };

  return <Box>{renderSelectedFilterOption()}</Box>;
};

const style = {
  menuHeader: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '15px',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
};
