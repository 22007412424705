import {
  Api,
  ChannelIdentifier,
  CuredConversation,
  ITelegramConnection,
  LoginResponse,
  TelegramConnection,
  TelegramFilters,
  UserIdentifier,
} from '@3rm-co/sync-client';
import { Folder } from 'services/models/domain/folder';
import { IPersistence } from 'services/store/persistence';
import { TG_SESSION_STRING, TgUserSessionString } from 'services/store/persistence/models/SessionString';

const DEFAULT_COOLDOWN = 3000;

export interface QrLoginHandlerProps {
  setQrCode: (qrCode: string) => void;
  setPasswordHint: (hint: string | undefined) => void;
  setNeedPassword: (needPassword: boolean) => void;
}

export interface PasswordLoginHandlerProps {
  password: string;
}

export type UpsertFolderInput = {
  folderName: string;
  externalId: string;
  lastSyncedAt: string;
};

export type TelegramFolderStorage = {
  id: number;
  contacts: boolean;
  nonContacts: boolean;
  groups: boolean;
  broadcasts: boolean;
  includedPeers: string;
  excludedPeers: string;
};

export interface ITelegramClient {
  initLiveSync(eventHandler: (event: any) => Promise<void>): void;
  isAuthorized(): Promise<boolean>;
  isUserConnected(): Promise<boolean>;
  destroyClient(): Promise<void>;
  connect(): Promise<boolean>;
  getFolders(): Promise<TelegramFolderStorage[]>;
  getFoldersGen(): AsyncGenerator<UpsertFolderInput>;
  getFullChannelInfo(channel: ChannelIdentifier): Promise<Api.messages.ChatFull | null>;
  getFullUser(userInput: UserIdentifier): Promise<Api.users.UserFull | null | undefined>;
  getFullChat(chatId: any): Promise<Api.messages.ChatFull | null | undefined>;
  getConversationsGen(oldersData: Folder[], hours?: number): AsyncGenerator<CuredConversation>;
  getInputEntity(chatId: string): Promise<Api.TypeInputPeer>;
  getUserLoginInfo(): { stringSession: string; phoneNumber: string };
  handlePasswordLogin(props: PasswordLoginHandlerProps): Promise<LoginResponse>;
  qrLoginHandler(props: QrLoginHandlerProps): Promise<LoginResponse>;
  sendMessage(peer: string, message: string, silent: boolean): Promise<Api.Message | undefined>;
  deleteMessage(messageId: number[]): Promise<boolean>;
  getLoginCode(phoneNumber: string, bySMS?: boolean): Promise<boolean>;
  loginByPhoneNumber(
    phoneNumber: string,
    hintSetter: (hint: string | undefined) => void,
    code: string
  ): Promise<LoginResponse>;
  //getSessionString(): string | undefined;
  //setSessionString(sessionString?: string): void;
  getFolderDynamicFilters(folder: any): Promise<TelegramFilters | undefined>;
  init(): void;
}

export class TelegramClient implements ITelegramClient {
  private clientInstance?: ITelegramConnection;
  private tgClientId: number = Number(process.env.REACT_APP_TELEGRAM_API_ID);
  private tgClientHash: string = String(process.env.REACT_APP_TELEGRAM_API_HASH).trim();
  private isConnected: boolean = false;
  private tgSessionStringPersistence: IPersistence<TgUserSessionString>;

  constructor(tgSessionStringPersistence: IPersistence<TgUserSessionString>) {
    localStorage.removeItem('GramJs:apiCache');
    this.tgSessionStringPersistence = tgSessionStringPersistence;
  }

  initLiveSync(eventHandler: (event: any) => Promise<void>): void {
    if (!this.clientInstance) return;
    this.clientInstance.addEventListeners(eventHandler);
  }

  init() {
    if (this.clientInstance) {
      return;
    }
    // Create the client with the locally stored stringSession
    this.clientInstance = new TelegramConnection(
      {
        apiId: this.tgClientId,
        apiHash: this.tgClientHash,
      },
      {
        stringSession: this.tgSessionStringPersistence.getItem(TG_SESSION_STRING)?.sessionString ?? undefined,
        isNode: false,
      }
    );
  }

  qrLoginHandler = async (props: QrLoginHandlerProps): Promise<LoginResponse> => {
    const { setQrCode, setPasswordHint, setNeedPassword } = props;
    if (this.clientInstance === undefined)
      return {
        success: false,
        message: 'Client not initialized',
      };

    try {
      const result = await this.clientInstance.loginByQrCode(setQrCode, setPasswordHint, setNeedPassword);
      if (result.success) {
        await this.storeSessionString();
      }
      return result;
    } catch (e) {
      return {
        success: false,
        message: 'Error on loginByQrCode',
      };
    }
  };

  getLoginCode = async (phoneNumber: string, bySMS?: boolean): Promise<boolean> => {
    if (this.clientInstance === undefined) return false;

    try {
      return await this.clientInstance.getCodePhoneNumberLogin(phoneNumber, !!bySMS);
    } catch (e) {
      return false;
    }
  };

  loginByPhoneNumber = async (
    phoneNumber: string,
    hintSetter: (hint: string | undefined) => void,
    code: string
  ): Promise<LoginResponse> => {
    if (this.clientInstance === undefined) return { success: false, message: 'Client not initialized' };
    try {
      const result = await this.clientInstance.loginByPhoneNumber(phoneNumber, hintSetter, code);
      if (result.success) {
        await this.storeSessionString();
      }
      return result;
    } catch (e) {
      return { success: false, message: 'Error trying to login' };
    }
  };

  handlePasswordLogin = async (props: PasswordLoginHandlerProps): Promise<LoginResponse> => {
    const { password } = props;
    if (this.clientInstance === undefined) return { success: false, message: 'Client not initialized' };

    try {
      const result = await this.clientInstance.loginByPassword(password);

      if (result) {
        await this.storeSessionString();
      }
      return result;
    } catch (e) {
      return { success: false, message: 'Error trying to login' };
    }
  };

  private storeSessionString = async () => {
    if (!this.clientInstance) {
      throw Error('Client instance not created. Please call init');
    }
    const sessionString = this.clientInstance.getSessionString();
    this.tgSessionStringPersistence.setItem(TG_SESSION_STRING, { sessionString });
  };

  getUserLoginInfo = () => {
    if (!this.clientInstance) {
      throw Error('Client instance not created. Please call init');
    }
    if (!this.clientInstance.user?.phone) {
      return { stringSession: '', phoneNumber: '' };
    }
    return {
      stringSession: this.clientInstance.getSessionString(),
      phoneNumber: this.clientInstance.user.phone,
    };
  };

  sendMessage = async (peer: string, message: string, silent: boolean) => {
    if (!this.clientInstance) {
      throw Error('Client instance not created. Please call init');
    }
    return await this.clientInstance.sendMessage(peer, message, silent);
  };

  deleteMessage = async (messageId: number[]) => {
    if (!this.clientInstance) {
      throw Error('Client instance not created. Please call init');
    }
    return await this.clientInstance.deleteMessage(messageId);
  };

  disconnect = async () => {
    if (!this.clientInstance) {
      throw Error('Client instance not created. Please call init');
    }
    return await this.clientInstance.disconnect();
  };

  isUserConnected = async () => {
    if (!this.clientInstance) {
      return false;
    }
    return this.isConnected;
  };

  connect = async () => {
    if (!this.clientInstance) {
      throw Error('Client instance not created. Please call init');
    }
    await this.clientInstance.connect();
    this.isConnected = true;
    return true;
  };

  isAuthorized = async () => {
    if (!this.clientInstance || !this.isUserConnected()) {
      return false;
    }
    return await this.clientInstance.isUserAuthorized();
  };

  async *getFoldersGen(): AsyncGenerator<UpsertFolderInput> {
    if (!this.clientInstance) {
      throw Error('Client instance not created. Please call init');
    }
    const lastSyncedAt = Math.floor(Date.now()).toString();
    const folders = await this.clientInstance.getFolders();
    for (const folder of folders) {
      yield {
        folderName: folder.title,
        externalId: folder.id.toString(),
        lastSyncedAt: new Date(Number(lastSyncedAt)).toISOString(),
      };
    }
  }

  async getFolders(): Promise<TelegramFolderStorage[]> {
    if (!this.clientInstance) {
      throw Error('Client instance not created. Please call init');
    }
    if (!(await this.isAuthorized())) {
      throw Error('User Not Authorized');
    }

    const folders = ((await this.clientInstance.getFolders()) as Api.DialogFilter[]).map((folder) => {
      return {
        id: folder.id,
        contacts: !!folder.contacts,
        nonContacts: !!folder.nonContacts,
        groups: !!folder.groups,
        broadcasts: !!folder.broadcasts,
        includedPeers:
          folder.includePeers &&
          folder.includePeers
            .map((peer) => {
              switch (peer.className) {
                case 'InputPeerChat':
                  return peer.chatId.toString();
                case 'InputPeerUser':
                  return peer.userId.toString();
                case 'InputPeerChannel':
                  return peer.channelId.toString();
                default:
                  return '';
              }
            })
            .toString(),
        excludedPeers:
          folder.excludePeers &&
          folder.excludePeers
            .map((peer) => {
              switch (peer.className) {
                case 'InputPeerChat':
                  return peer.chatId.toString();
                case 'InputPeerUser':
                  return peer.userId.toString();
                case 'InputPeerChannel':
                  return peer.channelId.toString();
                default:
                  return '';
              }
            })
            .toString(),
      };
    });
    localStorage.setItem('Telegram:Folders', JSON.stringify(folders));
    console.debug("[CLIENT-SYNC] Telegram's folders updated");
    return folders;
  }

  getConversationsGen(foldersData: Folder[], hours?: number): AsyncGenerator<CuredConversation> {
    if (!this.clientInstance) {
      throw Error('Client instance not created. Please call init');
    }
    localStorage.removeItem('GramJs:apiCache');
    return this.clientInstance.scrapChatList(foldersData, hours ? hours : undefined);
  }

  getInputEntity = async (chatId: string): Promise<Api.TypeInputPeer> => {
    if (!this.clientInstance) {
      throw Error('Client instance not created. Please call init');
    }
    return await this.clientInstance.getInputEntity(chatId);
  };

  getFullChannelInfo = async (channel: ChannelIdentifier): Promise<Api.messages.ChatFull | null> => {
    if (!this.clientInstance) {
      return null;
    }
    return this.clientInstance?.getFullChannel(channel);
  };

  getFullUser = async (userInput: UserIdentifier) => {
    return this.clientInstance?.getFullUserInfo(userInput);
  };

  getFullChat = async (chatId: any): Promise<Api.messages.ChatFull | null | undefined> => {
    return this.clientInstance?.getFullChat(chatId);
  };

  async destroyClient(): Promise<void> {
    if (this.clientInstance) {
      await this.clientInstance.disconnect();
      const success = await this.clientInstance.destroy();
      return success;
    }
  }

  getFolderDynamicFilters = async (folder: any) => {
    return this.clientInstance?.getFolderFilters(folder);
  };
}
