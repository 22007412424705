import { useEffect, useState } from 'react';
import { Box, IconButton, Modal, ModalDialog, Stack, Tooltip, Typography } from '@mui/joy';
import Icons from 'assets/icons';
import {
  FollowUpButtonStyled,
  FollowUpButtonTextStyled,
  EndIconsContainer,
  EndIconButtonStyles,
  FollowUpOverdueStyled,
  FollowUpDateContainerStyled,
  FollowUpContentStyles,
  EmptyCellText,
  CellBox,
  renderFollowupCellContentStyles,
} from 'components/Chats/ChatTableV2/TableCell/customCells/cellsContent/styles';
import { avatarFromName, generateColorFromOid, getNearDateName, isDateBefore } from 'services/utils/helpers';
import useResponsive from 'services/hooks/useResponsive';
import CustomAvatar from 'components/CustomAvatar';
import UpdateFollowupModal from 'page/modal/followUpModals/UpdateFollowUpModal/UpdateFollowupModal';
import UpdateFollowupModalDesktop from 'page/modal/followUpModals/UpdateFollowUpModalDesktop/UpdateFollowupModalDesktop';
import { Followup } from 'services/models/domain/followUps';
import { CloseHandler } from 'components/Chats/ChatTableV2/ChatTable';
import { ModalStyle } from 'page/modal/styles';
import { useSelector } from 'react-redux';
import { OrganizationalUser } from 'services/models/domain/user';
import { Conversation } from 'services/models/domain/conversation';
import { authState } from 'services/store/slices/authSlice';
import Translations from 'const/translations/en';

export interface FollowUpCellContentProps {
  conversation: Conversation;
  onFollowUpUpdated: (from?: Followup, to?: Followup) => void;
  setOnCloseHandler: (closeHandler?: CloseHandler) => void;
}

export default function FollowUpCellContent({
  conversation,
  onFollowUpUpdated,
  setOnCloseHandler,
}: FollowUpCellContentProps) {
  const { user } = useSelector(authState);
  const [followUp, setFollowUp] = useState<Followup | undefined>(undefined);
  const [assignee, setAssignee] = useState<OrganizationalUser | undefined>(undefined);
  const [date, setDate] = useState<Date | undefined>(undefined);

  useEffect(() => {
    if (conversation.nextFollowup) {
      const existingFollowUp: Followup = conversation.nextFollowup;
      setAssignee(existingFollowUp.assignedToUser);
      setDate(new Date(existingFollowUp.dueAt.utcTimeStampAsString));
      setFollowUp(existingFollowUp);
    } else {
      setAssignee(undefined);
      setDate(undefined);
      setFollowUp(undefined);
    }
  }, [conversation.nextFollowup]);

  const [modalOpen, setModalOpen] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const formattedDate = date ? getNearDateName(date) : '';
  const isDesktop = useResponsive('up', 'sm');
  const membersCount = user?.actingAsMemberOfOrganization?.numberOfUsersInOrganization ?? 0;

  const onOpenPopup = () => {
    setModalOpen(true);
    setOnCloseHandler((e) => {
      onClosePopup();
    });
  };

  const onClosePopup = () => {
    setModalOpen(false);
    setOnCloseHandler(undefined);
  };

  const renderFollowupCellContent = (
    <Box sx={renderFollowupCellContentStyles} onClick={onOpenPopup}>
      {date && (
        <FollowUpButtonStyled flexDirection="row" date={Boolean(date)} className={date ? undefined : 'showActions'}>
          {assignee && membersCount > 1 && (
            <CustomAvatar
              index={generateColorFromOid(assignee?.oid ?? '')}
              size={20}
              sx={{ mr: '8px' }}
              userInitials={avatarFromName(assignee.firstName || assignee.fullName)}
              imgUrl={assignee?.profileImageUrl}
            />
          )}
          <FollowUpDateContainerStyled isDate={Boolean(date)}>
            <FollowUpButtonTextStyled level="tiny">{formattedDate}</FollowUpButtonTextStyled>
            {date && isDateBefore(date) && <FollowUpOverdueStyled>!</FollowUpOverdueStyled>}
          </FollowUpDateContainerStyled>
        </FollowUpButtonStyled>
      )}
      {!date && isDesktop && (
        <Stack direction="row" spacing={1} sx={{ p: 2, display: 'none' }} id={'followup-hover-message'}>
          <img src={Icons.ic_calendar_plus} width={16} height={16} alt="" />
          <Typography sx={EmptyCellText} level="tiny">
            {Translations.SET_FOLLOWUP}
          </Typography>
        </Stack>
      )}
    </Box>
  );

  return (
    <Box
      onMouseOver={() => setShowButton(true)}
      onMouseLeave={() => setShowButton(false)}
      display="flex"
      sx={FollowUpContentStyles}
    >
      <Box display="flex" flexDirection="row" sx={CellBox}>
        {isDesktop ? (
          <Tooltip
            open={modalOpen}
            variant="plain"
            title={
              <UpdateFollowupModalDesktop
                handleClose={onClosePopup}
                conversation={conversation}
                onFollowUpUpdated={onFollowUpUpdated}
                user={user!}
              />
            }
            placement="bottom"
            arrow
            componentsProps={{ root: { sx: { p: 0 } } }}
          >
            {renderFollowupCellContent}
          </Tooltip>
        ) : (
          renderFollowupCellContent
        )}
      </Box>
      {!isDesktop && (
        <Modal open={modalOpen} onClose={onClosePopup} hideBackdrop>
          <ModalDialog sx={ModalStyle}>
            <UpdateFollowupModal
              handleClose={onClosePopup}
              conversation={conversation}
              user={user!}
              onFollowUpUpdated={onFollowUpUpdated}
            />
          </ModalDialog>
        </Modal>
      )}

      {(showButton || !isDesktop) && (
        <EndIconsContainer>
          {date && (
            <Tooltip
              title={Translations.FOLLOWUP_ACTIONS_RESET}
              arrow
              placement="top"
              enterDelay={400}
              enterTouchDelay={400}
              enterNextDelay={400}
            >
              <IconButton
                size="sm"
                variant="plain"
                color="primary"
                sx={EndIconButtonStyles}
                onClick={() => {
                  onFollowUpUpdated(followUp, undefined);
                }}
              >
                <img width={16} height={16} alt={'close'} src={Icons.ic_close} />
              </IconButton>
            </Tooltip>
          )}
        </EndIconsContainer>
      )}
    </Box>
  );
}
