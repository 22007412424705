import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SortConversationsOnPropertyInput, SortDirectionType } from 'services/models/api/generated';
import { AppContext } from 'services/store/AppContext';
import {
  CONVERSATION_FILTER_STORAGE_KEY,
  FilterItem,
  SortConversationsOnPropertyType,
  SortDirection,
} from 'services/store/persistence/models/FilterItem';
import {
  DEFAULT_SORT_FIELD,
  chatFilters,
  setFavorite,
  setFieldValue,
  setFilterFolderOids,
  setIsParticipant,
  setPropertyFilter,
} from 'services/store/slices/conversationsFilterSlice';

export function useConversationFilters() {
  const context = useContext(AppContext);
  const dispatch = useDispatch();

  useEffect(() => {
    const persistenceFilter = context.filterPersistence.getItem(CONVERSATION_FILTER_STORAGE_KEY);

    if (persistenceFilter) {
      dispatch(
        setFieldValue({
          path: 'input.search',
          value: persistenceFilter.search,
        })
      );
      dispatch(setIsParticipant({ isParticipant: false }));
      dispatch(
        setFavorite({
          favorite: persistenceFilter.filterOn.favorite,
        })
      );
      dispatch(setFilterFolderOids({ folderOids: persistenceFilter.filterOn.folderOids }));
      persistenceFilter.filterOn.propertyValues.forEach((propertyValue) => {
        dispatch(
          setPropertyFilter({
            oid: propertyValue.oid,
            propertyDefinitionOid: propertyValue.propertyDefinitionOid,
          })
        );
      });
      const values: SortConversationsOnPropertyInput[] =
        (persistenceFilter.sortOn
          ?.map((sortOn) => {
            if (sortOn.propertyDefinitionOid) {
              return {
                propertyDefinitionOid: sortOn.propertyDefinitionOid,
                sortDirection: sortOn.sortDirection as unknown as SortDirectionType,
              };
            } else if (sortOn.onPropertyType) {
              return {
                sortConversationsOnPropertyType: sortOn.onPropertyType || DEFAULT_SORT_FIELD,
                sortDirection: sortOn.sortDirection as unknown as SortDirectionType,
              };
            }
            return undefined;
          })
          .filter((p) => !!p) as SortConversationsOnPropertyInput[]) || [];
      if (values.length > 0) {
        dispatch(setFieldValue({ path: 'input.sortOn.sortOnProperties', value: values }));
      }
    }
    dispatch(
      setFieldValue({
        path: 'initial',
        value: false,
      })
    );
  }, []);

  const filter = useSelector(chatFilters);
  useEffect(() => {
    const filterItem: FilterItem = {
      filterOn: {
        favorite: filter.input.filterOn.favorite || false,
        archived: filter.input.filterOn.archived || false,
        partyScope: filter.input.filterOn.partyScope,
        propertyValues:
          filter.input.filterOn.propertyValues?.map((propertyValue) => {
            return {
              oid: propertyValue.oid,
              propertyDefinitionOid: propertyValue.propertyDefinitionOid,
            };
          }) || [],
        folderOids: filter.input.filterOn.folderOids || [],
      },
      sortOn: filter.input.sortOn.sortOnProperties.map((sortOn) => {
        return {
          sortDirection: sortOn.sortDirection as unknown as SortDirection,
          propertyDefinitionOid: sortOn.propertyDefinitionOid,
          onPropertyType: sortOn.sortConversationsOnPropertyType as unknown as SortConversationsOnPropertyType,
        };
      }),
      search: filter.input.search,
    };
    if (!filter.initial) {
      context.filterPersistence.setItem(CONVERSATION_FILTER_STORAGE_KEY, filterItem);
    }
  }, [
    filter.input.search,
    filter.input.filterOn.favorite,
    filter.input.filterOn.archived,
    filter.input.filterOn.propertyValues,
    filter.input.filterOn.partyScope,
    filter.input.filterOn.folderOids,
    filter.input.sortOn,
  ]);
}
