import { ChipProps } from '@mui/joy';

export enum ChatChipStyle {
  Round,
  Edged,
}

export type ChatChipProps = Omit<ChipProps, 'chipColor' | 'icon' | 'size'> & {
  chipColor?: string;
  icon?: string;
  chipStyle?: ChatChipStyle;
};
