import { Typography } from '@mui/joy';
import Icons from 'assets/icons';
import AppLinks from 'const/links';
import { BatchMessageButtonStyle, headerStyles } from 'page/dashboard/header/styles';
import { openOutsideLink } from 'services/utils/helpers';
import { colors } from 'theme/colors';

export default function BatchMessageButton() {
  const handleBatchMessageClick = () => {
    openOutsideLink(AppLinks.batchMessageLink);
  };

  return (
    <>
      <BatchMessageButtonStyle size="sm" variant="outlined" onClick={handleBatchMessageClick}>
        <img src={Icons.ic_open_link} width={16} height={16} alt="Batch Message" className="hoverable-icon" />
        <Typography
          id="modal-title"
          level="h1"
          color="danger"
          className="hoverable-content"
          sx={[headerStyles.button, { marginLeft: '10px', padding: '0px', color: `${colors.neutral[50]}` }]}
        >
          Batch Message
        </Typography>
      </BatchMessageButtonStyle>
    </>
  );
}
