import { SnackBarVariantEnum } from 'components/SnackBar/SnackBar';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'services/store/AppStore';
import { setSnackbarState } from 'services/store/slices/snackbarSlice';

function useSnackbarContext() {
  const dispatch = useDispatch();
  const { open, text, variant, subtext } = useSelector((state: RootState) => state.snackbar);

  const setSnackbarOpenStatus = (
    isOpen: boolean,
    snackbarText: string,
    snackbarVariant: SnackBarVariantEnum,
    icon?: string,
    snackbarSubtext?: string,
    actionMessage?: string,
    emoji?: string,
    backgroundColor?: string,
    mainTextColor?: string,
    subTextColor?: string
  ) => {
    dispatch(
      setSnackbarState({
        snackbarOpen: isOpen,
        snackbarText,
        snackbarVariant,
        snackbarSubtext,
        icon,
        actionMessage,
        emoji,
        backgroundColor,
        mainTextColor,
        subTextColor,
      })
    );
  };

  return {
    snackbarOpen: open,
    snackbarText: text,
    snackbarVariant: variant,
    setSnackbarOpenStatus,
    snackbarSubtext: subtext,
  };
}

export default useSnackbarContext;
