import { Box, Stack, Sheet, Typography, Modal, ModalClose } from '@mui/joy';
import Icons, { IconsSvg } from 'assets/icons';
import 'theme/styles/scroll-bar.css';
import { SnackBarVariantEnum } from 'components/SnackBar/SnackBar';
import useSnackbarContext from 'services/hooks/useSnackbar';
import { colors } from 'theme/colors';
import Translations from 'const/translations/en';
import UIButton from 'components/ui-library/Button/UIButton';

interface DeletePropertyModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export default function DeletePropertyModal({ isOpen, onClose, onDelete }: DeletePropertyModalProps) {
  const { setSnackbarOpenStatus } = useSnackbarContext();

  const handleSnackBar = (message: string, variant: SnackBarVariantEnum, icon: string) => {
    setSnackbarOpenStatus(true, message, variant, icon);
    setTimeout(() => {
      setSnackbarOpenStatus(false, '', SnackBarVariantEnum.info);
    }, 5000);
  };

  return (
    <Modal open={isOpen} onClose={onClose} component="div" role="dialog" sx={styles.modalStyle}>
      <Sheet sx={styles.modalContainer}>
        <Box sx={styles.modalCloseContainer}>
          <ModalClose sx={styles.modalClose} />
        </Box>
        <Stack direction="column" spacing={2} sx={styles.mainContainer}>
          <Stack sx={styles.iconContainer}>
            <IconsSvg.ic_warning_triangle stroke={colors.neutral[0]} width={19.2} height={19.2} />
          </Stack>
          <Stack direction="column" spacing={1}>
            <Typography level="h2" sx={{ color: colors.neutral[80] }}>
              {Translations.DELETE_PROPERTY_MODAL_TITLE}
            </Typography>
            <Typography level="tiny" sx={{ color: colors.neutral[50] }}>
              {Translations.DELETE_PROPERTY_MODAL_MESSAGE}
            </Typography>
          </Stack>
        </Stack>
        <Box sx={styles.buttonContainer}>
          <UIButton
            fullWidth
            size="sm"
            sx={styles.button}
            onClick={() => {
              onClose();
              onDelete();
              handleSnackBar(Translations.PROPERTY_DELETED, SnackBarVariantEnum.error, Icons.ic_warning_snackbar);
            }}
          >
            <Typography level="regular">{Translations.DELETE_PROPERTY_MODAL_BUTTON}</Typography>
          </UIButton>
        </Box>
      </Sheet>
    </Modal>
  );
}

const styles = {
  modalStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: 1,
    zIndex: 9999999,
    '& .JoyModal-backdrop': {
      background: '#0F1012',
      backdropFilter: 'blur(2px)',
      opacity: 0.9,
    },
  },
  modalContainer: { maxWidth: '400px', maxHeight: '272px' },
  modalCloseContainer: { margin: '16px', height: '20px' },
  modalClose: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  mainContainer: { textAlign: 'center', alignItems: 'center', paddingTop: '16px', paddingBottom: '24px' },
  iconContainer: {
    width: '32px',
    height: '32px',
    backgroundColor: '#F14040',
    borderRadius: '6.4px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: { padding: '12px 20px', width: '100%' },
  button: {
    background: '#F14040',
    gap: '6px',
    border: 'none',
    '&:hover': {
      background: '#F14040',
      border: 'none',
    },
  },
};
