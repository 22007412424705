import { Tooltip, Typography } from '@mui/joy';
import { Stack } from '@mui/material';
import { IconsSvg } from 'assets/icons';
import { ReactComponent as SidebarIcon } from 'assets/icons/ic_sidebar.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/ic_telegram.svg';
import { NameCellContentProps } from 'components/Chats/ChatTableV2/TableCell/customCells/cellsContent/Name/interface';
import {
  CellSecondTextWithTooltip,
  CellText,
  ParticipantsContainer,
  detailsPageTooltipStyle,
} from 'components/Chats/ChatTableV2/TableCell/customCells/cellsContent/styles';
import ParticipantItem from 'components/Details/ParticipantItem';
import { DetailsIconStyle, TelegramIconStyle } from 'components/Kanban/styles';
import LinkDescriptionComponent from 'components/LinkDescriptions';
import PageLink from 'components/PageLink';
import TitleCard from 'components/TitleCard';
import Translations from 'const/translations/en';
import { useSelector } from 'react-redux';
import { RouterPaths } from 'services/hooks/useNavigation';
import { useTelegramChatLink } from 'services/hooks/useTelegramChatLink';
import { ConversationParticipant, ConversationType, OriginTypes } from 'services/models/domain/conversation';
import { authState } from 'services/store/slices/authSlice';
import { getChannelFilledIcon, getChannelIcon, getChannelIconColor } from 'services/utils/channelUtils';
import { getChatTypeIcon, getChatTypeName } from 'services/utils/nameCellUtils';
import { colors } from 'theme/colors';

export default function NameCellContent({
  id,
  name,
  participants,
  link,
  type,
  channelType,
  onClick,
  onParticipantLinkClicked,
  origin,
}: NameCellContentProps) {
  const { user } = useSelector(authState);
  // temporarily show Channel type conversations as Group type
  const chatIconName = getChatTypeIcon(type as ConversationType);
  const ChatIcon = IconsSvg[chatIconName];
  const chatTypeToShow = getChatTypeName(type as ConversationType);
  const { telegramChatLink } = useTelegramChatLink(link, type, participants);

  let participantstoShow: string;
  switch (type) {
    case ConversationType.Channel:
      participantstoShow = '';
      break;
    case ConversationType.Group:
      participantstoShow =
        participants.length > 0 ? `${participants.length} member${participants.length === 1 ? '' : 's'}` : '';
      break;
    case ConversationType.Individual:
      if (participants.length > 1) {
        const isFirstParticipantFromMyOrg = user?.actingAsMemberOfOrganization.listOfUsersInOrganization.some(
          (u) => u.oid === participants[0].userOid?.oid
        );
        const participantIndex = isFirstParticipantFromMyOrg ? 0 : 1;
        participantstoShow = participants[participantIndex]?.name || participants[participantIndex]?.firstName || '';
        break;
      } else {
        participantstoShow = '';
        break;
      }

    default:
      participantstoShow = '';
      break;
  }

  const getLinkDescription = () => {
    return (
      <LinkDescriptionComponent origin={origin} originalLink={telegramChatLink} link={link} channelType={channelType} />
    );
  };

  return (
    <TitleCard
      title={
        <Stack direction={'column'} component="span">
          <PageLink to={RouterPaths.createDetailsChatPath(id)} openInNewTab>
            <Tooltip
              title={<Typography level="tiny">{Translations.OPEN_DETAILS_PAGE_TOOLTIP}</Typography>}
              arrow
              placement="right"
              enterDelay={1000}
              enterTouchDelay={1000}
              enterNextDelay={700}
              componentsProps={detailsPageTooltipStyle}
            >
              <Typography level="tiny" sx={CellText}>
                {name}
              </Typography>
            </Tooltip>
          </PageLink>
          <Tooltip
            title={
              participants &&
              participants.length > 0 && (
                <Stack
                  direction={'column'}
                  spacing={1}
                  sx={{ ...ParticipantsContainer, width: participants.length > 6 ? '285px' : '278px' }}
                >
                  {participants.map((participant: ConversationParticipant, idx) => {
                    return (
                      <ParticipantItem
                        key={idx}
                        participant={participant}
                        onDetailsClicked={() => {
                          onParticipantLinkClicked(participant.oid.oid);
                        }}
                        sx={{ fontSize: '14px' }}
                      />
                    );
                  })}
                </Stack>
              )
            }
            arrow
            variant="plain"
            placement="bottom-start"
            enterDelay={400}
            enterTouchDelay={400}
            enterNextDelay={400}
            componentsProps={{
              root: {
                sx: {
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                },
              },
            }}
          >
            <Typography level="tiny" sx={CellSecondTextWithTooltip}>
              <ChatIcon height={12} width={12} stroke={colors.neutral[50]} />
              {chatTypeToShow}
              {participantstoShow && (
                <span
                  style={{ width: '2px', height: '2px', borderRadius: '50%', backgroundColor: colors.neutral[30] }}
                />
              )}
              {participantstoShow}
            </Typography>
          </Tooltip>
        </Stack>
      }
      leftComponent={{
        icon: origin === OriginTypes.TelegramSync ? TelegramIcon : getChannelFilledIcon(link ? channelType : undefined),
        hoveredIcon:
          origin === OriginTypes.TelegramSync ? TelegramIcon : getChannelIcon(link ? channelType : undefined),
        description: getLinkDescription(),
        stroke:
          origin !== OriginTypes.TelegramSync
            ? getChannelIconColor(link ? channelType : undefined, telegramChatLink ? true : false)
            : undefined,
        css: { ...TelegramIconStyle(!!telegramChatLink), padding: '6px' },
        link: telegramChatLink,
        showFilledIcon: true,
      }}
      rightComponent={{
        icon: SidebarIcon,
        description: Translations.OPEN_SIDEBAR_TOOLTIP,
        css: DetailsIconStyle,
        link: onClick,
      }}
    />
  );
}
