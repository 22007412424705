import { useDispatch, useSelector } from 'react-redux';

import { useEffect } from 'react';
import * as gql from 'services/models/api/generated';
import * as domain from 'services/models/domain/entityViews';
import { setEntityViews } from 'services/store/slices/entityViewsSlice';
import { EntityType } from 'services/models/domain/entityTypes';
import { mapEntityViewsToDomain, updateUserInPropertyDefinition } from 'services/utils/entityViewUtils';
import { foldersState } from 'services/store/slices/foldersSlice';
import { authState } from 'services/store/slices/authSlice';

const VIEW_FETCH_VARIABLES = {
  input: {
    entityTypes: [EntityType.Conversation as gql.EntityType],
  },
};

export function useFetchEntityViews() {
  const dispatch = useDispatch();
  const { user } = useSelector(authState);
  const { folders } = useSelector(foldersState);

  const [fetchedEntityViews, { data, loading }] = gql.useEntityViewsLazyQuery({
    variables: VIEW_FETCH_VARIABLES,
    fetchPolicy: 'no-cache',
    initialFetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (fetchedEntityViews && fetchedEntityViews.length > 0 && user) {
      const entityViewsData: gql.EntityViewsResponseData = data?.entityViews.data as gql.EntityViewsResponseData;
      const entityViews: domain.EntityViews = mapEntityViewsToDomain(entityViewsData, user!, folders);
      const updatedEntityViews = user ? updateUserInPropertyDefinition(entityViews, user) : entityViews;
      dispatch(setEntityViews(updatedEntityViews));
    }
  }, [data, loading, user]);

  useEffect(() => {
    fetchedEntityViews();
  }, []);
}
