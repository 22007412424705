import { Typography } from '@mui/joy';
import { styled } from '@mui/joy/styles';
import { Box, SxProps } from '@mui/material';
import { GridCell } from '@mui/x-data-grid-pro';
import { colors } from 'theme/colors';
import { fonts } from 'theme/fonts';

export const PageLinkStyle = (isHovered: boolean): React.CSSProperties => {
  return {
    textDecoration: isHovered ? 'underline' : 'none',
    color: 'inherit',
    cursor: 'pointer',
    maxWidth: 'fit-content',
  };
};

export const textEllipse: SxProps = {
  width: '100%',
  maxWidth: 'fit-content',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: 'inherit',
};

export const NotesTooltipStyles = {
  root: {
    sx: {
      p: 0,
    },
  },
};

export const linkTooltipStyle = {
  root: {
    sx: {
      maxWidth: '250px',
      p: '8px',
    },
  },
};

export const NotesBoxStyles: SxProps = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '12px',
};

export const NotesInnerBoxStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  minWidth: 0,
};

export const StyledFavoriteCell = styled(GridCell)(() => ({
  padding: '6px 0px 6px 16px !important',
  borderRight: 'none !important',
}));

export const FlexColumn: SxProps = {
  display: 'flex',
  flexDirection: 'column',
};

export const FlexRow: SxProps = {
  display: 'flex',
  flexDirection: 'row',
};

export const CellText: SxProps = {
  color: 'secondary.dark',
  fontSize: '14px',
  lineHeight: '18px',
  letterSpacing: '-0.01em',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxHeight: '18px',
  maxWidth: 'fit-content',
  ...textEllipse,
};

export const CellSecondText: SxProps = {
  ...CellText,
  color: colors.neutral[50],
  marginTop: '2px',
  lineHeight: '18px',
};

export const CellSecondTextWithTooltip: SxProps = {
  ...CellSecondText,
  maxWidth: 'fit-content',
  display: 'flex',
  gap: '4px',
  alignItems: 'center',
};

export const EmptyCellText: SxProps = {
  color: 'neutral.500',
  fontSize: '14px',
  lineHeight: '18px',
};

export const CellBox: SxProps = {
  width: '100%',
  height: 43,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
};

export const NotesCellBox: SxProps = {
  ...CellBox,
  paddingLeft: '16px',
  paddingRight: '12px',
};

export const ParticipantsContainer: SxProps = {
  p: '12px 16px',
  width: '280px',
  maxHeight: '185px',
  overflowY: 'auto',
  marginTop: '-12px',
  marginRight: '-8px',
  background: `white`,
  borderRadius: '6px',
  border: `1px solid var(--neutral-5, #F3F5F7)`,
  boxShadow: `0px 0px 20px 0px rgba(0, 0, 0, 0.10), 0px 0px 2px 0px rgba(0, 0, 0, 0.09)`,
};

export const ParticipantsTableCellButton: SxProps = {
  minHeight: 24,
  height: 24,
  marginLeft: '10px',
  fontFamily: fonts.regular,
  fontSize: 12,
  backgroundColor: '#FFF',
  '--Button-gap': '6px',
  ':hover': {
    backgroundColor: '#FFF',
  },
};

export const LastNoteCreatorStyled = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
}));

export const NoteCreatorDividerStyled = styled('div')(() => ({
  width: 2,
  height: 2,
  background: colors.neutral[20],
  borderRadius: 2,
}));

export const FollowUpContentStyles: SxProps = {
  justifyContent: 'space-between',
  width: '100%',
  height: 43,
  cursor: 'pointer',
  alignItems: 'center',
  position: 'relative',
};

export const FollowUpButtonStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'date',
})(({ date }: { date?: boolean }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '12px',
}));

export const AddIconStyles: SxProps = {
  '--Icon-color': colors.neutral[50],
  mr: '6px',
};

export const FollowUpButtonTextStyled = styled(Typography)(() => ({
  fontSize: '14px',
  cursor: 'pointer',
  alignSelf: 'center',
}));

export const FollowUpDateContainerStyled = styled('div')(({ isDate }: { isDate: boolean }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  cursor: 'pointer',
  color: !isDate ? colors.neutral['50'] : colors.neutral['80'],
}));

export const FollowUpOverdueStyled = styled(Typography)(() => ({
  fontSize: '10px',
  lineHeight: '10px',
  marginTop: '1px',
  marginLeft: '2px',
}));

export const EndIconsContainer = styled('div')(() => ({
  height: 24,
  zIndex: 1,
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'right',
  transition: 'width .35s ease-in-out',
  position: 'absolute',
  right: 0,
}));

export const EndIconButtonStyles: SxProps = {
  borderRadius: '0px',
  minHeight: '24px !important',
  width: '24px',
  '&:hover': { backgroundColor: 'transparent' },
  border: '0px transparent',
};

export const renderFollowupCellContentStyles: SxProps = {
  minWidth: '100%',
  minHeight: '100%',
  display: 'flex',
  alignItems: 'center',
  p: '4px',
};

export const tooltipStyle = {
  root: {
    sx: {
      backgroundColor: 'white',
      boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1), 0px 0px 20px rgba(0, 0, 0, 0.08)',
      borderRadius: '6px',
    },
  },
};

export const detailsPageTooltipStyle = {
  root: {
    sx: {
      padding: '6px 12px',
    },
  },
};
