import { Stack } from '@mui/joy';
import { useRef, useEffect, useState } from 'react';
import { IconsSvg } from 'assets/icons';
import { colors } from 'theme/colors';
import ChatChip from 'components/ui-library/chat/Chip/ChatChip';
import AddEditPropertyValue from 'components/Chats/ChatTableV2/EditProperty/AddEditPropertyValue/AddEditPropertyValue';
import { EditPropertyOptionItemProps } from 'components/Chats/ChatTableV2/EditProperty/EditPropertyOptionItem/interface';

const EditIcon = IconsSvg.ic_chevron_right;
const DNDIcon = IconsSvg.ic_grid_vertical;

const EditPropertyOptionItem = ({ editingOptionValue, refetch }: EditPropertyOptionItemProps) => {
  const { oid, color, value, icon } = editingOptionValue.option;
  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setElement(ref.current);
  }, [ref]);

  const { newValueTempId } = editingOptionValue;

  return (
    <div ref={ref}>
      <AddEditPropertyValue
        key={oid.oid}
        propertyValue={editingOptionValue}
        refetch={refetch}
        anchorEl={newValueTempId ? element : null}
        setAnchorEl={setElement}
      >
        <Stack direction="row" sx={EditPropertyOptionItemStyles.propertyContainer} spacing={1}>
          <Stack direction="row" sx={EditPropertyOptionItemStyles.dragAndDropButton} spacing={0.5}>
            <DNDIcon widths={10} height={10} stroke={colors.neutral[50]} />
            <ChatChip chipColor={color} icon={icon} sx={EditPropertyOptionItemStyles.chip}>
              {value}
            </ChatChip>
          </Stack>
          <EditIcon stroke={colors.neutral[50]} width={10} height={10} />
        </Stack>
      </AddEditPropertyValue>
    </div>
  );
};

const EditPropertyOptionItemStyles = {
  propertyContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px',
    cursor: 'pointer',
    overflow: 'hidden',
  },
  dragAndDropButton: {
    width: '100%',
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    width: '100%',
    maxWidth: 'fit-content',
    overflow: 'hidden',
  },
};
export default EditPropertyOptionItem;
