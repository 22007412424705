import EditProperty from 'components/Chats/ChatTableV2/EditProperty/EditProperty';
import { ConversationKanbanCard } from 'components/Kanban/ConversationKanbanCard';
import { KanbanBoard, KanbanColumn } from 'components/Kanban/KanbanBoard';
import CellMenuButton from 'components/ui-library/chat/CellMenu/СellMenuButton/CellMenuButton';
import Translations from 'const/translations/en';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMenuNavigation } from 'services/hooks/useMenuNavigation';
import { usePropertyValue } from 'services/hooks/usePropertyValue';
import { Conversation } from 'services/models/domain/conversation';
import { EntityType } from 'services/models/domain/entityTypes';
import { EntityView } from 'services/models/domain/entityViews';
import { pipelineConversationsState } from 'services/store/slices/pipelineConversationsSlice';
import { kanbanColumnsForConversations } from 'services/utils/conversationPageUtils';

export interface KanbanViewProps {
  entityView: EntityView;
  onEndReached: (column: KanbanColumn<Conversation>) => void;
}

const propertyOidsForConversation = (conversation: Conversation, propertyDefinitionOid: string): string[] => {
  const selectedValues = conversation.properties
    .map((p) => {
      if (
        p &&
        (p.type === 'OPTION' || p.type === 'TENANT_USER') &&
        p.propertyDefinitionOid.oid === propertyDefinitionOid
      ) {
        return p.oid.oid;
      }
      return undefined;
    })
    .filter((p) => !!p) as string[];
  return selectedValues;
};

export const KanbanView = (props: KanbanViewProps) => {
  const { groupByPropertyDefinitionOid } = props.entityView;
  const groupByPropertyDefinition = useMemo(() => {
    return props.entityView.propertyDefinitions.find((pd) => pd.oid.oid === groupByPropertyDefinitionOid);
  }, [groupByPropertyDefinitionOid, props.entityView.propertyDefinitions]);

  const restPropertyDefinitions = useMemo(
    () =>
      props.entityView.propertyDefinitions
        .filter((pd) => pd.visible)
        .filter((pd) => pd.oid.oid != groupByPropertyDefinitionOid),
    [props.entityView]
  );

  const { conversations, paginationInfos } = useSelector(pipelineConversationsState);

  const { handleUpdatePropertyOption } = usePropertyValue();

  const { isEditNav, handleEditClick, handleBackClick } = useMenuNavigation();

  const onEndReached = (column: KanbanColumn<Conversation>) => {
    props.onEndReached(column);
  };

  const onEntityChangeColumn = (fromId: string, toId: string, entityId: string) => {
    if (groupByPropertyDefinition) {
      const conversation = conversations.find((c) => c.oid.oid === entityId);
      if (!conversation) {
        return;
      }

      const updateEntity = {
        entityType: EntityType.Conversation,
        id: entityId,
        propertyOids: propertyOidsForConversation(conversation, groupByPropertyDefinition.oid.oid),
      };
      const sanitizeUndefined = (a: string) => (a === 'undefined' ? [] : [a]);
      handleUpdatePropertyOption(
        groupByPropertyDefinition,
        updateEntity,
        sanitizeUndefined(fromId),
        sanitizeUndefined(toId),
        fromId === 'undefined'
      );
    }
  };
  let kanbanColumns: KanbanColumn<Conversation>[] = [];
  if (groupByPropertyDefinition) {
    kanbanColumns = kanbanColumnsForConversations(conversations, groupByPropertyDefinition, paginationInfos);
  }
  return (
    <KanbanBoard
      columns={kanbanColumns}
      onEntityChangeColumn={onEntityChangeColumn}
      onEndReached={onEndReached}
      renderCard={(entity, index) => {
        return (
          <ConversationKanbanCard
            entity={entity}
            index={index}
            propertyDefinitionsToShow={restPropertyDefinitions}
            propertyDefinitions={props.entityView.propertyDefinitions}
          />
        );
      }}
      handleHeaderMenuClose={handleBackClick}
      renderEditColumnHeader={() => {
        return isEditNav ? (
          <EditProperty property={groupByPropertyDefinition!} refetch={async () => {}} />
        ) : (
          <CellMenuButton
            title={Translations.CHAT_STATUS_PROPERTY_EDIT}
            icon="ic_edit_column"
            onClick={handleEditClick}
          />
        );
      }}
    />
  );
};
