import { IconsSvg } from 'assets/icons';
import { Divider, Typography, FormLabel, Stack } from '@mui/joy';
import { Box, FormControl } from '@mui/material';
import { colors } from 'theme/colors';
import Translations from 'const/translations/en';
import UIButton from 'components/ui-library/Button/UIButton';
import UIInput from 'components/ui-library/Input/UIInput';
import CellMenuButton from 'components/ui-library/chat/CellMenu/СellMenuButton/CellMenuButton';
import { useState } from 'react';
import { ColumnVisibilityControlStyles } from 'components/Chats/ChatTableV2/TableHeader/ColumnVisibilityControl/styles';

interface ColumnVisibilityControlEditProps {
  propertyDefinitionOid: string;
  initialName: string;
  navigateBack: () => void;
  onEditPropertyDefinition: (oid: string, name: string) => void;
}

export default function ColumnVisibilityControlEdit({
  navigateBack,
  onEditPropertyDefinition,
  initialName,
  propertyDefinitionOid,
}: ColumnVisibilityControlEditProps) {
  const [propertyName, setPropertyName] = useState(initialName);
  const isPropertyEditted = !!propertyName && propertyName !== initialName;

  const onSave = () => {
    if (isPropertyEditted) {
      onEditPropertyDefinition(propertyDefinitionOid, propertyName);
    }
  };

  return (
    <>
      <CellMenuButton title={Translations.CANCEL} icon="ic_arrow_left" onClick={navigateBack} />
      <Divider sx={ColumnVisibilityControlStyles.divider} />
      <Stack direction={'column'} spacing={2} sx={ColumnVisibilityControlStyles.container}>
        <FormControl sx={{ gap: '6px' }}>
          <FormLabel>
            <Typography level="micro" sx={ColumnVisibilityControlStyles.text}>
              {Translations.NAME}
            </Typography>
          </FormLabel>
          <UIInput
            placeholder={Translations.CHAT_TABLE_PROPERTY_NAME}
            type="text"
            value={propertyName}
            onChange={(e) => setPropertyName(e.target.value)}
            sx={ColumnVisibilityControlStyles.textInput}
            onKeyDown={(e) => e.stopPropagation()}
            autoFocus
          />
        </FormControl>
      </Stack>
      <Divider sx={ColumnVisibilityControlStyles.divider} />
      <Box sx={ColumnVisibilityControlStyles.container}>
        <UIButton
          fullWidth
          size="sm"
          sx={ColumnVisibilityControlStyles.mainButton}
          onClick={onSave}
          disabled={!isPropertyEditted}
        >
          <IconsSvg.ic_check stroke={colors.white} width="14" height="14" />
          <Typography level="tiny">{Translations.SAVE}</Typography>
        </UIButton>
      </Box>
    </>
  );
}
