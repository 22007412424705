import { Box, Typography } from '@mui/joy';
import Translations from 'const/translations/en';
import { SearchBarSx, emptyBoxSx, headerTypoSx, rootBoxSx } from 'page/chats/ChatsTableFilter/styles';
import { useSelector } from 'react-redux';
import { sideNavState } from 'services/store/slices/sideNavSlice';
import { entityFilter } from 'services/store/slices/entityFilterSlice';
import {
  EntityFilterItem,
  EntityFilterType,
  FavoriteEntityFilter,
  FilterIdentifier,
  FilterOption,
  SearchTextEntityFilter,
} from 'services/models/domain/entityFilter';
import { EntityType } from 'services/models/domain/entityTypes';
import { useEffect, useMemo, useState } from 'react';
import { PageView } from 'page/chats/interface';
import { colors } from 'theme/colors';
import { SxProps } from '@mui/material';
import UIChip from 'components/ui-library/Chip/UIChip';
import { IconsSvg } from 'assets/icons';
import FilterToolBar from 'page/chats/ChatTableFilterBar/FilterToolBar';
import { useEntityFilter } from 'services/hooks/useEntityFilter';
import { getFilterForFilterOption } from 'services/utils/chatTableFilterUtils';
import { useFilterOptions } from 'services/hooks/useFilterOptions';
import { debounce } from 'lodash';
import { LocalStorageValues } from 'services/utils/interface';
import { DraftEntityView, EntityView, ViewType } from 'services/models/domain/entityViews';
import { useNavigationSidebar } from 'services/hooks/useNavigationSidebar';
import { useEntityViews } from 'services/hooks/useEntityViews';
import { PROPERTY_ICONS } from 'components/Chats/ChatTableV2/EditProperty/AddEditPropertyValue/constants';
import {
  ContainerBoxStyles,
  GridIconStyled,
  GridSectionStyles,
  MenuSx,
} from 'components/Chats/ChatTableV2/EditProperty/AddEditPropertyValue/styles';
import CellMenu from 'components/ui-library/chat/CellMenu/CellMenu';
import { getIcon } from 'page/chats/ChatV2Properties';
import { useFlags } from 'launchdarkly-react-client-sdk';
import UIButton from 'components/ui-library/Button/UIButton';
import { entityViewsState } from 'services/store/slices/entityViewsSlice';
import { useNavigationSidebarFilters } from 'services/hooks/useNavigationSidebarFilters';
import _ from 'lodash';
import { CHAT_RECORDS } from 'services/utils/dashboardHeaderUtils';
import { fonts } from 'theme/fonts';
import { useConversations } from 'services/hooks/useConversations';
import { PageViewToggle } from 'components/Chats/ChatTableV2/TableHeader/PageViewToggle';
import { authState } from 'services/store/slices/authSlice';
import AnalyticKeys, { trackAction } from 'services/utils/analytics';
import SearchBar from 'components/SearchBar';
import { useExportConversations } from 'services/hooks/useExportConversations';

export interface ChatTableFilterBarProps {
  currentPageView: PageView;
  onPageViewChanged: (pageView: PageView) => void;
}

export const ChatTableFilterBar = (props: ChatTableFilterBarProps) => {
  const flags = useFlags();
  const { duplicateEntityView, updateDraftEntityView, publishEntityView, deleteEntityViewFromBackend, setEditMode } =
    useEntityViews();
  const { entityViews, editMode } = useSelector(entityViewsState);
  const { addEntityFilterItem, removeEntityFilterItem } = useEntityFilter();
  const { selectConversationView } = useNavigationSidebarFilters(editMode);
  const { user } = useSelector(authState);
  const { selectedItem } = useSelector(sideNavState);
  const { filterItems } = useSelector(entityFilter);
  const pageView = (localStorage.getItem(LocalStorageValues.PageView) as PageView) || PageView.Table;
  const { deleteEntityViewFromState, updateEntityViewDetails, getEntityViewByOid } = useEntityViews();
  const { filterOptions } = useFilterOptions();
  const { setSelectedNavigationItem, clearSelectedNavigationItem } = useNavigationSidebar();

  const conversationFilterItems = filterItems.filter((fi) => fi.entityType === EntityType.Conversation);
  const searchTextFilterItem = conversationFilterItems.find((fi) => fi.filter.type === EntityFilterType.SearchText);
  const searchTextFilter = searchTextFilterItem?.filter as SearchTextEntityFilter | undefined;

  let chipItems = conversationFilterItems.filter((fi) => fi.filter.type !== EntityFilterType.SearchText);
  const favoriteFilterItem = conversationFilterItems.find((fi) => fi.filter.type === EntityFilterType.Favorite);
  const favoriteFilter = favoriteFilterItem?.filter as FavoriteEntityFilter | undefined;

  const [searchString, setSearchString] = useState<string | undefined>(searchTextFilter?.searchText);
  const [title, setTitle] = useState<string>();
  const [icon, setIcon] = useState<string>('DollarFile');
  const [searchActive, setSearchActive] = useState<boolean>(false);
  const [saveMenuOpen, setSaveMenuOpen] = useState<boolean>(false);
  const [haveFiltersChanged, setHaveFiltersChanged] = useState<boolean>(false);
  const [presetFilters, setPresetFilters] = useState<EntityFilterItem[] | undefined>(undefined);
  const { setCreateChatModalState } = useConversations();

  const { exportConversations, canExportConversations } = useExportConversations();

  const selectedEntityView = useMemo(() => {
    return selectedItem.id ? getEntityViewByOid(selectedItem.id) : undefined;
  }, [entityViews.views, selectedItem.id]);

  if (pageView === PageView.Kanban) {
    chipItems = chipItems.filter((fi) => fi.filterOptionIdentifier !== FilterIdentifier.Status);
  }

  useEffect(() => {
    if (selectedEntityView?.type === ViewType.Draft) {
      updateDraftEntityView(selectedItem?.id ?? '', title ?? '', icon);
    }
  }, [title, icon]);

  useEffect(() => {
    setTitle(selectedItem.title);
    setIcon(selectedItem.icon);
    if (selectedItem.id) {
      setHaveFiltersChanged(false);
      setPresetFilters(
        selectedEntityView?.type === ViewType.Draft ? undefined : selectedEntityView?.criteria?.filterItems
      );
    }
    setEditMode(selectedEntityView?.type === ViewType.Draft);
  }, [selectedItem, selectedEntityView]);

  useEffect(() => {
    if (editMode) {
      setPresetFilters(undefined);
    }
  }, [editMode]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!editMode && presetFilters) {
        setHaveFiltersChanged(checkHaveFiltersChanged(pageView, chipItems, presetFilters));
      } else {
        setHaveFiltersChanged(false);
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [chipItems]);

  const setSearchValue = (value: string) => {
    setSearchString(value);
    debounce(() => {
      addEntityFilterItem(
        {
          uniqueId: 'search',
          type: EntityFilterType.SearchText,
          searchText: value,
        },
        EntityType.Conversation,
        FilterIdentifier.Search
      );
    }, 600)();

    if (value.length > 0) {
      trackAction(AnalyticKeys.SEARCHED_CHAT, {
        organizationId: user?.actingAsMemberOfOrganization.oid,
        organizationName: user?.actingAsMemberOfOrganization.name,
        searchTerm: value,
        favoritesFilterEnabled: favoriteFilter?.favorite,
      });
    }
  };

  const checkHaveFiltersChanged = (
    pageView: PageView,
    currentFilters: EntityFilterItem[],
    referenceFilters: EntityFilterItem[]
  ) => {
    const filterOutStatus = (filters: EntityFilterItem[]) =>
      filters.filter((fi) => fi.filterOptionIdentifier !== FilterIdentifier.Status);
    if (pageView === PageView.Kanban) {
      const currentFiltersExcludingStatus = filterOutStatus(currentFilters);
      const referenceFiltersExcludingStatus = filterOutStatus(referenceFilters);
      return !_.isEqual(currentFiltersExcludingStatus, referenceFiltersExcludingStatus);
    } else {
      return !_.isEqual(currentFilters, referenceFilters);
    }
  };

  const renderPageViewToggleButtons = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <PageViewToggle currentPageView={props.currentPageView} onPageViewChanged={props.onPageViewChanged} />
      </Box>
    );
  };

  const onSelectFilterOption = (filterOption: FilterOption) => {
    const entityFilter = getFilterForFilterOption(filterOption);
    if (entityFilter && 'value' in filterOption) {
      addEntityFilterItem(entityFilter, EntityType.Conversation, filterOption.identifier);
    } else {
      const existing = chipItems.find((item) => item.filter.uniqueId === filterOption.uniqueId);
      if (existing) {
        removeEntityFilterItem(existing.filter.uniqueId);
      }
    }
  };

  const onDuplicateExisitingView = () => {
    if (flags?.customViews && selectedItem.id && selectedEntityView?.type !== ViewType.Draft) {
      duplicateEntityView(selectedItem.id, selectedEntityView as EntityView);
    }
  };

  const onSave = () => {
    if (selectedItem.id) {
      if (selectedEntityView?.type === ViewType.Draft) {
        setEditMode(false);
        publishEntityView(title ?? '', icon, selectedEntityView as DraftEntityView, false);
      } else {
        setSaveMenuOpen(true);
      }
    }
  };

  const onSaveAsNew = () => {
    setSaveMenuOpen(false);
    if (selectedItem.id && selectedEntityView?.type !== ViewType.Draft) {
      setEditMode(false);
      const draftView = duplicateEntityView(selectedItem.id, selectedEntityView as EntityView);
      publishEntityView(title ?? '', icon, draftView, true);
    }
  };

  const onRemoveFilterItem = (filterItem: EntityFilterItem) => {
    removeEntityFilterItem(filterItem.filter.uniqueId);
  };

  const onDiscard = () => {
    if (selectedItem.id) {
      if (selectedEntityView?.type === ViewType.Draft) {
        deleteEntityViewFromState(selectedItem.id);
        clearSelectedNavigationItem();
      } else {
        selectedEntityView && selectConversationView(selectedEntityView);
        setSelectedNavigationItem(selectedItem);
      }
      setEditMode(false);
      setHaveFiltersChanged(false);
      setTitle(selectedItem.title);
      setIcon(selectedItem.icon);
    }
  };

  const onUpdateEntityView = () => {
    if (selectedEntityView?.type === ViewType.Custom && selectedItem.id) {
      setEditMode(false);
      setSaveMenuOpen(false);
      updateEntityViewDetails(selectedItem.id, title || '', icon);
    }
  };

  const renderFilterToolBar = () => {
    return (
      <FilterToolBar
        filterOptions={filterOptions}
        activeFilters={
          pageView === PageView.Kanban
            ? chipItems.filter(
                (fi) =>
                  fi.filterOptionIdentifier !== FilterIdentifier.Status &&
                  fi.filterOptionIdentifier !== FilterIdentifier.Archived
              )
            : chipItems.filter((fi) => fi.filterOptionIdentifier !== FilterIdentifier.Archived)
        }
        presetFilters={presetFilters}
        onSelectFilterOption={onSelectFilterOption}
        onRemoveFilterItem={onRemoveFilterItem}
      />
    );
  };

  const renderSearchBar = (focused: boolean) => {
    return (
      <SearchBar
        onChange={setSearchValue}
        value={searchString || ''}
        placeholder={Translations.CHATS_SEARCH_PLACEHOLDER}
        sx={SearchBarSx}
        onClear={() => {
          setSearchValue('');
          setSearchActive(false);
        }}
        onBlur={() => {
          if (searchString === '' || searchString === undefined) {
            setSearchActive(false);
          }
        }}
        autofocus={focused}
      />
    );
  };

  const renderSecondaryButtons = () => {
    return (
      <Box display="flex" sx={styles.secondaryButtonsContainer}>
        {selectedItem.id === CHAT_RECORDS && (
          <>
            {renderSearchBar(false)}
            <Box sx={emptyBoxSx} />
          </>
        )}

        {renderPageViewToggleButtons()}
        <Box sx={emptyBoxSx} />
        {renderFilterToolBar()}
      </Box>
    );
  };

  const renderSaveMenu = () => {
    return (
      <CellMenu
        onClose={() => setSaveMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={saveMenuOpen}
        anchorComponent={
          <UIChip
            id="save-button"
            variant={'solid'}
            color={'primary'}
            size="md"
            sx={styles.saveButton(haveFiltersChanged && !editMode)}
            onClick={onSave}
          >
            <Typography level="tiny" sx={{ color: colors.white, marginRight: '2px' }}>
              {`Save`}
            </Typography>
            {(selectedEntityView?.type === ViewType.Custom ||
              (haveFiltersChanged && selectedEntityView?.type !== ViewType.Draft)) && (
              <IconsSvg.ic_chevron_down width={14} height={14} stroke={colors.white} style={{ marginTop: '2px' }} />
            )}
          </UIChip>
        }
        sx={styles.uiChip}
      >
        {selectedEntityView?.type === ViewType.Custom &&
          renderSideButton('ic_check', 14, onUpdateEntityView, 'Save', styles.button)}
        {renderSideButton('ic_duplicate', 14, onSaveAsNew, 'Save as new smart list', styles.button)}
      </CellMenu>
    );
  };

  const renderEntityViewMenu = () => {
    return (
      <CellMenu
        onClose={() => {}}
        anchorComponent={renderSideButton('ic_dots', 14, () => {})}
        sx={styles.uiChip}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {
          <Box id="delete-button">
            {renderSideButton(
              'ic_trash',
              14,
              () => {
                if (selectedEntityView && 'oid' in selectedEntityView && selectedEntityView?.type === ViewType.Custom) {
                  deleteEntityViewFromBackend(selectedEntityView.oid.oid, selectedEntityView.title);
                }
              },
              'Delete smart list',
              styles.deleteButton,
              { color: 'red', marginLeft: '5px' },
              'red'
            )}
          </Box>
        }
      </CellMenu>
    );
  };

  const renderViewActionButtons = () => {
    return (
      <Box display={'flex'} sx={{ alignSelf: 'center', justifyContent: 'flex-end' }}>
        <UIChip
          id="discard-button"
          variant={'solid'}
          color={'primary'}
          size="md"
          sx={{
            ...styles.saveButton(false),
            backgroundColor: colors.neutral[5],
            border: `1px solid ${colors.neutral[20]}`,
          }}
          onClick={() => onDiscard()}
        >
          <IconsSvg.ic_trash width={14} height={14} stroke={colors.neutral[50]} />
          <Typography level="tiny" sx={{ color: colors.neutral[50], marginLeft: '5px' }}>
            {`Discard`}
          </Typography>
        </UIChip>
        {renderSaveMenu()}
      </Box>
    );
  };

  const renderIconsSelector = () => {
    return (
      <Box sx={ContainerBoxStyles}>
        <Box sx={GridSectionStyles}>
          {PROPERTY_ICONS.map((icon, i) => {
            if (icon === null) {
              return <GridIconStyled key={i} isActive={false} onClick={() => setIcon(icon)}></GridIconStyled>;
            }
            const Icon = IconsSvg[icon];
            return (
              <GridIconStyled key={i} isActive={false} onClick={() => setIcon(icon)}>
                <Icon width={14} height={14} />
              </GridIconStyled>
            );
          })}
        </Box>
      </Box>
    );
  };

  const renderEditableHeader = () => {
    const Icon = getIcon(icon);
    return (
      <Box sx={{ ...rootBoxSx, marginBottom: '15px' }}>
        <Box display={'flex'} flexDirection={'column'} sx={styles.editableHeader}>
          <Box sx={styles.headerContainer}>
            <Box sx={styles.iconAndTextContainer}>
              <CellMenu
                anchorComponent={
                  <Icon
                    width={20}
                    height={20}
                    stroke={colors.neutral[80]}
                    style={{ marginTop: '4px', marginLeft: '2px', marginRight: '2px' }}
                  />
                }
                sx={styles.iconSelector}
              >
                {renderIconsSelector()}
              </CellMenu>
            </Box>
            <div style={styles.textInputContainer}>
              <input
                value={title}
                autoFocus
                onChange={(event) => setTitle(event.target.value)}
                style={styles.textInput}
              />
              <span
                style={{
                  gridArea: '1 / 1 / 2 / 2',
                  visibility: 'hidden',
                  border: 'none',
                }}
              >
                {title}
              </span>
            </div>
            {renderViewActionButtons()}
          </Box>
          {renderSecondaryButtons()}
        </Box>
      </Box>
    );
  };

  const renderSideButton = (
    icon: string,
    iconSize: number,
    onClick: () => void,
    title?: string,
    sx?: SxProps,
    titleSx?: SxProps,
    stroke?: string
  ) => {
    const Icon = getIcon(icon);
    return (
      <UIButton
        size="xs"
        sx={{
          backgroundColor: colors.navigationBarBackground,
          border: `1px solid ${colors.neutral[15]}`,
          height: '32px',
          width: 'fit-content',
          marginLeft: '8px',
          whiteSpace: 'nowrap',
          '&:hover': { backgroundColor: colors.neutral[10], border: `1px solid ${colors.neutral[20]}` },
          ...sx,
        }}
        onClick={onClick}
      >
        <Icon width={iconSize} height={iconSize} stroke={stroke ?? colors.neutral[50]} />
        {title && (
          <Typography level="tiny" sx={{ color: colors.neutral[50], marginLeft: '5px', display: 'inline', ...titleSx }}>
            {title}
          </Typography>
        )}
      </UIButton>
    );
  };

  const renderSearchButton = () => {
    if (searchActive) {
      return renderSearchBar(true);
    } else if (!haveFiltersChanged) {
      return <Box id="search-button">{renderSideButton('ic_search', 14, () => setSearchActive(true), 'Search')}</Box>;
    }
    return <></>;
  };

  const renderConversationButtons = () => {
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        sx={{ justifyContent: 'flex-end', alignItems: 'center', alignSelf: 'center' }}
      >
        {canExportConversations && (
          <Box id="export-chat-button">
            {renderSideButton(
              'ic_copy',
              15,
              () => {
                exportConversations(selectedEntityView as EntityView);
              },
              `${Translations.EXPORT_CONVERSATIONS}`
            )}
          </Box>
        )}
        <Box id="create-chat-button">
          {renderSideButton(
            'ic_plus',
            15,
            () => setCreateChatModalState(true),
            `${Translations.CREATE} ${Translations.CHAT}`
          )}
        </Box>
      </Box>
    );
  };

  const renderHeaderButtons = () => {
    const entityActionsVisibility = !haveFiltersChanged;
    return (
      <Box
        display={'flex'}
        flexDirection={'row'}
        sx={{ justifyContent: 'flex-end', alignItems: 'center', alignSelf: 'center' }}
      >
        {canExportConversations && (
          <Box id="export-chat-button">
            {renderSideButton(
              'ic_copy',
              15,
              () => {
                exportConversations(selectedEntityView as EntityView);
              },
              `${Translations.EXPORT_CONVERSATIONS}`
            )}
          </Box>
        )}
        {entityActionsVisibility ? (
          <Box id="entity-actions" display={'flex'}>
            {
              <>
                <Box id="duplicate-button" display={'flex'}>
                  {renderSearchButton()}
                  {renderSideButton('ic_duplicate', 14, onDuplicateExisitingView, 'Duplicate')}
                </Box>
                {selectedEntityView?.type === ViewType.Custom && (
                  <>
                    <Box id="edit-button">{renderSideButton('ic_edit', 14, () => setEditMode(true), 'Edit')}</Box>
                    {renderEntityViewMenu()}
                  </>
                )}
              </>
            }
          </Box>
        ) : (
          <Box id="view-actions">{selectedEntityView && renderViewActionButtons()}</Box>
        )}
      </Box>
    );
  };

  const renderHeader = () => {
    const Icon = selectedItem.icon ? getIcon(selectedItem.icon) : IconsSvg.ic_chat;
    const title = selectedItem.title || '';
    return (
      <>
        <Box sx={{ ...rootBoxSx, marginBottom: '15px' }}>
          <Box display={'flex'} sx={{ marginTop: '30px', justifyContent: 'space-between' }}>
            <Box display={'flex'} sx={{ height: '32px', alignContent: 'flex-end', marginBottom: '10px' }}>
              <Icon
                width={24}
                height={24}
                stroke={colors.neutral[80]}
                style={{ alignSelf: 'flex-end', marginBottom: '3px' }}
              />
              <Typography sx={headerTypoSx}>
                {selectedItem.title === Translations.FOLLOW_UPS_DUE ? Translations.FOLLOW_UPS : title}
              </Typography>
            </Box>
            {selectedItem.id === CHAT_RECORDS || !selectedItem ? renderConversationButtons() : renderHeaderButtons()}
          </Box>
          {renderSecondaryButtons()}
        </Box>
      </>
    );
  };
  return <>{editMode ? renderEditableHeader() : renderHeader()}</>;
};

const styles = {
  toggleButtonGroup: {
    height: '28px',
    p: '1px',
    backgroundColor: colors.neutral[5],
    border: `1px solid ${colors.neutral[20]}`,
    borderRadius: '6px',
    gap: '4px',
  },
  IconChip: {
    alignSelf: 'center',
    justifyContent: 'center',
    borderRadius: '0px',
    marginRight: '8px',
    fontWeight: 200,
    height: '32px',
    width: '32px',
    border: `1px solid ${colors.primary[5]}`,
    fontSize: '14px',
  },
  title: {
    marginTop: '1px',
    marginLeft: '10px',
    fontFamily: fonts.regular,
    color: colors.neutral[80],
    fontSize: '18px',
    fontWeight: 900,
    marginBottom: '10px',
    borderBottom: `1px solid ${colors.neutral[20]}`,
    width: '160px',
  },
  filterChipButton: {
    alignSelf: 'center',
    borderRadius: '6px',
    marginRight: '8px',
    fontWeight: 200,
    height: '28px',
    border: `1px solid ${colors.primary[5]}`,
    fontSize: '14px',
  },
  saveButton: (haveFiltersChanged: boolean) => ({
    backgroundColor: haveFiltersChanged ? colors.primary[50] : colors.neutral[90],
    alignSelf: 'center',
    borderRadius: '6px',
    height: '28px',
    marginLeft: '10px',
  }),
  editableHeader: {
    marginTop: '30px',
    border: `1px solid ${colors.neutral[20]}`,
    justifyContent: 'flex-start',
    padding: '15px',
    borderRadius: '16px',
  },
  button: {
    backgroundColor: colors.white,
    border: `1px solid ${colors.white}`,
    alignSelf: 'center',
    borderRadius: '6px',
    height: '28px',
    marginLeft: '0px',
    justifyContent: 'flex-start',
    width: '100%',
    '&:hover': { backgroundColor: colors.neutral[5], border: `1px solid ${colors.neutral[5]}` },
  },
  uiChip: {
    '& .MuiPaper-root': {
      margin: '0px',
      marginRight: '20px',
    },
    '.MuiMenu-list': {
      padding: '2px',
      boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1), 0px 0px 20px rgba(0, 0, 0, 0.08)',
      justifyContent: 'flex-start',
    },
  },
  deleteButton: {
    alignSelf: 'center',
    borderRadius: '6px',
    height: '28px',
    marginLeft: '0px',
    backgroundColor: colors.trash,
    justifyContent: 'flex-start',
  },
  textInputContainer: {
    display: 'inline-grid',
    flexGrow: 1,
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    justifyItems: 'start',
    padding: 8,
    border: 'none',
    borderRadius: 4,
    width: 'auto',
    marginBottom: '2px',
  },
  textInput: {
    gridArea: '1 / 1 / 2 / 2',
    width: '100%',
    padding: 0,
    border: 'none',
    outline: 'none',
    fontSize: '24px',
    fontFamily: fonts.regular,
    fontWeight: 900,
    textDecoration: 'underline',
    textDecorationColor: colors.neutral[15],
    textUnderlineOffset: '5px',
    textDecorationThickness: '2px',
  },
  iconSelector: {
    ...MenuSx,
    maxHeight: 'calc(100vh - 150px)',
    '& .MuiPopover-paper': {
      marginTop: '2px',
      borderRadius: '6px',
      paddingX: '8px',
      backgroundColor: colors.neutral[5],
    },
  },
  headerContainer: { display: 'flex', width: '100%' },
  iconAndTextContainer: {
    display: 'flex',
    alignSelf: 'center',
    border: `1px solid ${colors.primary[5]}`,
    borderRadius: '4px',
    paddingLeft: '2px',
    paddingRight: '2px',
    marginBottom: '4px',
  },
  secondaryButtonsContainer: {
    width: '100%',
    flexWrap: 'wrap',
    '& > *': {
      marginTop: '10px',
    },
  },
};
