import LoadingScreen from 'components/LoadingScreen';
import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import useLoginRequestOrigins from 'services/hooks/useLoginRequestOrigins';
import { RouterPaths, useNavigation } from 'services/hooks/useNavigation';
import { authState } from 'services/store/slices/authSlice';
import { LocalStorageValues } from 'services/utils/interface';

interface Props {
  children: ReactNode;
}

const AuthGuard: React.FC<Props> = ({ children }) => {
  const { isAuthenticated, isInitialized, user, isNameStepCompleted } = useSelector(authState);
  const { sendTokenToChromeExtension } = useLoginRequestOrigins();
  const navigate = useNavigation();

  const { pathname } = useLocation();
  const token = localStorage.getItem(LocalStorageValues.AuthToken);

  const [requestedLocation, setRequestedLocation] = useState<string | null>('');

  useEffect(() => {
    if (isAuthenticated && !user?.fullName && !isNameStepCompleted) {
      navigate(RouterPaths.Setup);
    }
  }, [isNameStepCompleted, user, isAuthenticated]);

  useEffect(() => {
    if (token && isAuthenticated && isInitialized) {
      sendTokenToChromeExtension(token);
    }
  }, [isInitialized]);

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} replace />;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated && !token) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to={RouterPaths.Login} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
