import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { defaultMetadata } from 'services/commandbar/metadata';
import { setDefaultTrackEvents } from 'services/commandbar/trackEvents';
import { useCommandbarCallbacks } from 'services/commandbar/useCommandbarCallbacks';
import { useCommandbarRecords } from 'services/commandbar/useCommandbarRecords';
import { authState } from 'services/store/slices/authSlice';

export function useCommandBar() {
  const { user, isFirstTime } = useSelector(authState);
  const flags = useFlags();
  const navigate = useNavigate();
  const addCallbacks = useCommandbarCallbacks();
  const addRecords = useCommandbarRecords();

  const routerFunc = (newUrl: string) => navigate(newUrl);

  useEffect(() => {
    // Command bar boot
    if (flags.commandbar && window.CommandBar) {
      const loggedInUserId = user?.oid;
      const metadata = defaultMetadata(user, isFirstTime);

      window.CommandBar.boot(loggedInUserId, metadata, {
        canOpenEditor: false,
      });

      window.CommandBar.addRouter(routerFunc);
      //default trackers states
      setDefaultTrackEvents();
      //callbacks
      addCallbacks();
      //default records
      addRecords();

      return () => window.CommandBar.shutdown();
    }
  }, []);
}
