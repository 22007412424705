import {
  PublishUpsertFolderEventInput,
  PublishUpsertConversationEventInput,
  usePublishUpsertFolderEventMutation,
  usePublishUpsertConversationEventMutation,
} from 'services/models/api/generated';

export const usePublishEvents = () => {
  const [upsertFolderToQueueMutation] = usePublishUpsertFolderEventMutation();
  const [upsertConversationToQueueMutation] = usePublishUpsertConversationEventMutation();

  const addFolderToQueue = async (folderItem: PublishUpsertFolderEventInput) => {
    const { data } = await upsertFolderToQueueMutation({
      variables: {
        input: folderItem,
      },
    });
    return data;
  };

  const addConversationToQueue = async (conversation: PublishUpsertConversationEventInput) => {
    const { data } = await upsertConversationToQueueMutation({
      variables: {
        input: conversation,
      },
    });
    return data;
  };

  return { addFolderToQueue, addConversationToQueue };
};
