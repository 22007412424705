import NProgress from 'nprogress';
import { useEffect, useMemo } from 'react';
import { m } from 'framer-motion';
import { styled } from '@mui/joy/styles';
import { Avatar, AvatarGroup } from '@mui/joy';

function ProgressBar() {
  NProgress.configure({
    showSpinner: false,
  });

  useMemo(() => {
    NProgress.start();
  }, []);

  useEffect(() => {
    NProgress.done();
  }, []);

  return null;
}

const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.appBody,
}));

export default function LoadingScreen({ ...other }) {
  return (
    <>
      <ProgressBar />

      <RootStyle {...other}>
        <m.div
          animate={{
            scale: [1, 0.9, 0.9, 1, 1],
            opacity: [1, 0.48, 0.48, 1, 1],
          }}
          transition={{
            duration: 2,
            ease: 'easeInOut',
            repeatDelay: 1,
            repeat: Infinity,
          }}
        >
          <AvatarGroup
            sx={{
              '--AvatarGroup-gap': '-7px',
              '--Avatar-size': '26px',
              '--Avatar-ringSize': '4px',
            }}
          >
            {[1, 2, 3].map((item) => (
              <Avatar
                component={m.div}
                animate={{
                  scale: [1, 1.2, 1.2, 1, 1],
                  rotate: [0, 270, 270, 0, 0],
                  opacity: [1, 0.25, 0.25, 0.25, 1],
                  borderRadius: ['25%', '25%', '50%', '50%', '25%'],
                }}
                transition={{
                  ease: 'linear',
                  duration: 3.2,
                  repeat: Infinity,
                }}
                src={require('assets/icons/logo.png')}
                size="sm"
                key={item}
              />
            ))}
          </AvatarGroup>
        </m.div>
      </RootStyle>
    </>
  );
}
