import { from } from '@apollo/client';
import { EntityType } from 'services/models/domain/entityTypes';
import { ConversationType, EmptyFilterType } from 'services/models/domain/entityViews';
import { DateRangeType } from 'services/models/api/generated';

export enum FilterIdentifier {
  IndividualOrGroup = '1:1 or Group',
  ChatMembers = 'Chat Members',
  Folder = 'Folders',
  LatestMessage = 'Latest Message',
  DateAdded = 'Date Added',
  AddedBy = 'Added By',
  Favorite = 'Favorite',
  Archived = 'Archived',
  FollowUps = 'Follow-Ups',
  Status = 'Status',
  Category = 'Category',
  Owner = 'Owner',
  EmptyFilterType = 'EmptyFilterType',
  NoProperty = 'NoProperty',
  Search = 'Search',
  Property = 'Property',
}

export type EntityFilterItem = {
  entityType: EntityType;
  id: string;
  filter: EntityFilter;
  filterOptionIdentifier: FilterIdentifier;
};

export enum DateRangeField {
  DateAdded = 'DATE_ADDED',
  LastFollowupDate = 'LAST_FOLLOWUP_DATE',
  LastMessageDate = 'LAST_MESSAGE_DATE',
}

export type EntityFilter = { uniqueId: string } & (
  | FavoriteEntityFilter
  | ChatMemberEntityFilter
  | AddedByUserEntityFilter
  | PropertyEntityFilter
  | DateRangeEntityFilter
  | FolderEntityFilter
  | ConversationTypeEntityFilter
  | EmptyEntityFilter
  | FollowUpsAssignedEntityFilter
  | SearchTextEntityFilter
  | ArchivedEntityFilter
);

function getEnumName(enumObj: typeof EntityFilterType, enumValue: EntityFilterType): string {
  return enumObj[enumValue];
}

export const generateFilterId = (entityFilterType: EntityFilterType, identifier: FilterIdentifier): string => {
  return `${getEnumName(EntityFilterType, entityFilterType)} Filter - ${identifier}`;
};

export enum EntityFilterType {
  Favorite,
  ChatMember,
  AddedByUser,
  FollowUpsAssignedToUser,
  Property,
  DateRange,
  Folder,
  ConversationType,
  EmptyFilterType,
  SearchText,
  Archived,
}

export type FavoriteEntityFilter = {
  favorite: boolean;
  type: EntityFilterType.Favorite;
};

export type ArchivedEntityFilter = {
  archived: boolean;
  type: EntityFilterType.Archived;
};

export type ChatMemberEntityFilter = {
  participantOids: string[];
  type: EntityFilterType.ChatMember;
};

export type AddedByUserEntityFilter = {
  userOids: string[];
  type: EntityFilterType.AddedByUser;
};

export type PropertyEntityFilter = {
  propertyDefinitionOid: string;
  propertyValueOids: string[];
  noValue: boolean;
  type: EntityFilterType.Property;
};

export type DateRangeEntityFilter = {
  onField: DateRangeField;
  from?: number;
  to?: number;
  days?: number;
  type: EntityFilterType.DateRange;
};

export type FolderEntityFilter = {
  folderOids: string[];
  type: EntityFilterType.Folder;
};

export type ConversationTypeEntityFilter = {
  conversationType: ConversationType;
  type: EntityFilterType.ConversationType;
};

export type EmptyEntityFilter = {
  emptyFilterType: EmptyFilterType;
  type: EntityFilterType.EmptyFilterType;
};

export type FollowUpsAssignedEntityFilter = {
  followUpsAssignedTo: string[];
  dateRangeId: string;
  from?: number;
  to?: number;
  onField: DateRangeField;
  noAssignee: boolean;
  type: EntityFilterType.FollowUpsAssignedToUser;
};

export type SearchTextEntityFilter = {
  searchText: string;
  type: EntityFilterType.SearchText;
};

export type FilterOptionValue =
  | DateRangeOptionValue
  | FavoriteOptionValue
  | ConversationTypeOptionValue
  | FolderOptionValue
  | PropertyOptionValue
  | ChatMemberOptionValue
  | AddedByUserOptionValue
  | EmptyFilterTypeOptionValue
  | FollowUpsAssignedToUserOptionValue;

type DateRangeOptionValue = {
  from?: Date;
  to?: Date;
  days?: number;
};
type FavoriteOptionValue = {
  favorite: boolean;
};
type ConversationTypeOptionValue = {
  conversationType: ConversationType;
};
type FolderOptionValue = {
  folderOids: string[];
};
type PropertyOptionValue = {
  propertyDefinitionOid: string;
  propertyValueOids: string[];
  noPropertyDefinitionOid?: string;
};
type ChatMemberOptionValue = {
  participantOids: string[];
};
type AddedByUserOptionValue = {
  userOids: string[];
};
type EmptyFilterTypeOptionValue = {
  emptyFilterType: EmptyFilterType;
};
type FollowUpsAssignedToUserOptionValue = {
  followUpsAssignedTo: string[];
  dateRangeId: string;
  onField?: DateRangeField;
  from?: Date;
  to?: Date;
  noFollowupAssigned: boolean;
};

export type FilterOption = {
  identifier: FilterIdentifier;
  uniqueId: string;
  icon: string;
  title: string;
  showHeader?: boolean;
  showFooter?: boolean;
} & ({ subItems: FilterOption[][] } | { value: FilterOptionValue });

export enum PartyType {
  Organization = 'ORGANIZATION',
  User = 'USER',
}
