import { useContext } from 'react';
import { TgSyncContext } from 'contexts/TgSync';

function useTgSyncContext() {
  const context = useContext(TgSyncContext);

  if (!context) throw new Error('Tg sync context must be use inside TgSyncProvider');

  return context;
}

export default useTgSyncContext;
