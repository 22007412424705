import Telegram from 'assets/images/link-telegram.svg';

export const chat = {
  data: {
    conversations: {
      status: {
        isSuccess: true,
        mainResponseCode: null,
        userMessage: null,
        developerMessage: null,
        stackTrace: null,
      },
      data: {
        conversations: [
          {
            oid: {
              oid: 'd6fad467-f1d7-4850-8a44-1d5b82b74133',
            },
            conversationRecordOid: {
              oid: 'bb764f18-a133-480c-ab26-b9e6431a8935',
            },
            internalConversationId: '-1001812525477',
            favorit: false,
            chatLink: 'https://t.me/+O1dtKkBOa3ExZjZk',
            createdAt: {
              utcTimeStampAsString: '2022-11-24T00:44:37.224Z',
            },
            name: '3RM-TEST GROUP',
            description: null,
            lastMessageAt: null,
            participants: [
              {
                internalParticipantId: '1230731584',
                name: 'Timothy',
                firstName: 'Timothy',
                lastName: 'Ofie',
                userHandle: 'boxerbuzz',
                userOid: {
                  oid: 'fa4184be-7ae1-4986-95c7-2b34a9cb6dea',
                },
              },
            ],
            notes: [
              {
                oid: '7ff7ca3d-53c8-4afc-a39f-57efbf29adbd',
                createdByUserOid: {
                  oid: '6906cbed-e4f5-4c36-b5c2-a34e261ec4c4',
                },
                text: 'some things to take note of',
              },
              {
                oid: '9e469c12-e648-453a-b6ea-bc5b08d933c7',
                createdByUserOid: {
                  oid: '70922da5-8a5b-45ab-8b4b-6252cd485eb7',
                },
                text: "please remember we'd need to talk about budget and duration for our upcoming project",
              },
              {
                oid: 'c2a75e51-5d0d-4991-8f31-0b1f86dd9561',
                createdByUserOid: {
                  oid: '70922da5-8a5b-45ab-8b4b-6252cd485eb7',
                },
                text: 'some notes, to keep in mind during next follow up',
              },
            ],
            nextFollowup: {
              oid: 'ea217ac5-8c19-4373-ab37-0e6dd1da3e17',
              dueAt: {
                utcTimeStampAsString: '2022-11-26T04:36:58.110Z',
              },
            },
            followups: [
              {
                oid: 'ea217ac5-8c19-4373-ab37-0e6dd1da3e17',
              },
              {
                oid: 'd33ddc32-e5f3-4ee7-a356-348f6f074669',
              },
            ],
          },
          {
            oid: {
              oid: 'd6fad467-f1d7-4850-8a44-1d5b82b74133',
            },
            conversationRecordOid: {
              oid: '3e249888-8f85-4d83-8c9f-b8ec52bb66a4',
            },
            internalConversationId: '-1001812525477',
            favorit: false,
            chatLink: 'https://t.me/+O1dtKkBOa3ExZjZk',
            createdAt: {
              utcTimeStampAsString: '2022-11-25T04:36:45.321Z',
            },
            name: '3RM-TEST GROUP',
            description: null,
            lastMessageAt: null,
            participants: [
              {
                internalParticipantId: '1230731584',
                name: 'Timothy',
                firstName: 'Timothy',
                lastName: 'Ofie',
                userHandle: 'boxerbuzz',
                userOid: {
                  oid: 'fa4184be-7ae1-4986-95c7-2b34a9cb6dea',
                },
              },
            ],
            notes: [],
            nextFollowup: null,
            followups: [],
          },
        ],
      },
    },
  },
};

export const participants = [
  { id: 1, name: 'Timothy Ofie', handle: '@Boxerbuzz', avatar: `${require('assets/users/avatar-1.png')}` },
  { id: 2, name: 'Jan Rosen', handle: '@janrosen', avatar: `${require('assets/users/avatar-2.png')}` },
  { id: 3, name: 'Jacksonita', handle: '@Jacksonrodriguez', avatar: `${require('assets/users/avatar-3.png')}` },
  { id: 4, name: 'AK @ 3RM', handle: '@AK', avatar: `${require('assets/users/avatar-4.png')}` },
  { id: 5, name: 'Ryan Gilly', handle: '@~Ryan~', avatar: `${require('assets/users/avatar-1.png')}` },
  { id: 6, name: 'Jocelyn Lubin', handle: '@jocelynlubin', avatar: `${require('assets/users/avatar-2.png')}` },
];

export const notes = [
  { id: 1, timestamp: '14m ago', name: 'Jackson Rodriguez', comment: '‘Jocelyn is in Bogota!’' },
  {
    id: 1,
    timestamp: '24m ago',
    name: 'You',
    comment: '‘Asked Jocelyn whether she’d be at rAAVE Bogota, she said no’',
  },
  {
    id: 1,
    timestamp: 'Wednesday',
    name: 'Jan Rosen',
    comment: '‘Reached out through Aleo’s Discord and booked an intro call for Monday’',
  },
  {
    id: 1,
    timestamp: 'Monday',
    name: 'AK',
    comment: '‘Reached out through AK’s Discord and booked an intro call for Wednesday’',
  },
];

export const steps = [
  {
    label: 'Link Telegram with 3RM',
    image: Telegram,
    background: '#29aaec',
  },
];

export const colors = [
  '#545960',
  '#AD0F0F',
  '#D4840C',
  '#99B201',
  '#1B9E06',
  '#08C9BD',
  '#086CC9',
  '#3A08C9',
  '#7408C9',
  '#C508C9',
];

export default { chat, participants, notes, steps, colors };
