import { Box, Checkbox, Typography } from '@mui/joy';
import { colors } from 'theme/colors';
import { useEffect, useState } from 'react';
import CellMenuOption from 'components/ui-library/chat/CellMenu/CellMenuOption/CellMenuOption';
import { CheckboxSx, NameSx, OptionSx, UserBoxSx } from 'components/Chats/Filters/PropertyFilter/styles';
import { IconsSvg } from 'assets/icons';

export interface UserFilterMenuProps {
  selected: boolean;
  title: string;
  onChange: (selected: boolean) => void;
}

export const NoOptionFilterMenu = (props: UserFilterMenuProps) => {
  const [selected, setSelected] = useState<boolean>(props.selected);
  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  return (
    <Box>
      <CellMenuOption
        key={1}
        sx={OptionSx}
        onClick={() => {
          setSelected(!selected);
          props.onChange(!selected);
        }}
      >
        <Box sx={{ ...UserBoxSx, marginBottom: '5px', display: 'flex', alignItems: 'center' }}>
          <IconsSvg.ic_border_none height={16} width={16} stroke={colors.neutral[50]} style={{ marginTop: '1px' }} />
          <Typography level="tiny" sx={NameSx}>
            {props.title}
          </Typography>
        </Box>
        <Checkbox sx={{ ...CheckboxSx }} checked={selected} />
      </CellMenuOption>
    </Box>
  );
};
