enum AppLinks {
  howToAddBotLink = 'https://support.3rm.co/en/collections/3724823-telegram-bot',
  howToCreateFolderLink = 'https://support.3rm.co/en/articles/8225988-create-telegram-folders',
  howToConnectTelegramLink = 'https://support.3rm.co/en/articles/8226221-connecting-3rm-to-telegram',
  howToSetTelegramBot = 'https://support.3rm.co/en/articles/6755307-how-to-set-up-the-telegram-bot',
  chromeExtensionLink = 'https://chrome.google.com/webstore/detail/3rm-%E2%80%93-telegram-crm-for-we/foiddakkfjegihlfbgbgipcnhcbhmcbl',
  telegramGroupLink = 'https://t.me/+DgVDhfLJOxNlODI5',
  blogLink = 'https://3rm.co/blog',
  twitterLink = 'https://twitter.com/3rm_co',
  batchMessageLink = 'https://batch.3rm.co',
  privacyPolicy = 'https://www.3rm.co/privacy-policy',
  termsOfUse = 'https://3rm.co/terms-of-use',
  bookACall = 'https://calendly.com/3rm/hi',
  supportEmail = 'support@3rm.co',
}

export default AppLinks;
