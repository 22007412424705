import { UpsertFolderInput } from 'services/clients/TelegramClient';
import * as domain from 'services/models/domain/folder';
import { User } from 'services/models/domain/user';

export const telegramFoldersEqualToFolders = (
  telegramFolders?: UpsertFolderInput[],
  folders?: domain.Folder[]
): boolean => {
  if (!telegramFolders || !folders) {
    return false;
  }
  const telegramFolderExternalIds = telegramFolders.map((f) => f.externalId);
  const folderExternalIds = folders.map((f) => f.externalId);

  const allTelegramFolderExist = telegramFolderExternalIds.reduce(
    (acc, a) => acc && folderExternalIds.includes(a),
    true
  );

  return allTelegramFolderExist;
};

export const updateUserInFolders = (folder: domain.Folder[], user: User): domain.Folder[] => {
  return folder.map((f) => {
    if (f.user.oid.oid === user?.oid) {
      return {
        ...f,
        user: {
          ...f.user,
          fullName: user?.fullName,
          profilePicture: user?.profileImageUrl,
        },
      };
    }
    return f;
  });
};
