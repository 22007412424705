import 'simplebar/src/simplebar.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { StyledEngineProvider, CssVarsProvider } from '@mui/joy/styles';
import { CssBaseline } from '@mui/joy';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import 'theme/styles/index.css';
import theme from 'theme/index';
import App from 'App';
import reportWebVitals from 'reportWebVitals';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { PostHogProvider } from 'posthog-js/react';
import { LicenseInfo } from '@mui/x-license-pro';
import * as Commandbar from 'commandbar';

Commandbar.init(process.env.REACT_APP_COMMAND_BAR as string);

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY as string);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.7,
});

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENTSIDE_ID ?? '',
  });

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

  const postHogOptions = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  };

  root.render(
    <React.StrictMode>
      <LDProvider>
        <HelmetProvider>
          <StyledEngineProvider injectFirst>
            <CssVarsProvider disableTransitionOnChange theme={theme}>
              <BrowserRouter>
                <CssBaseline />
                {/* Wrap your App component in the PostHogProvider */}
                <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={postHogOptions}>
                  <App />
                </PostHogProvider>
              </BrowserRouter>
            </CssVarsProvider>
          </StyledEngineProvider>
        </HelmetProvider>
      </LDProvider>
    </React.StrictMode>
  );
})();

reportWebVitals();
