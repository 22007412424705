import { Box, Divider, IconButton, ListDivider, Sheet } from '@mui/joy';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import { InputAdornment, Stack, TextField } from '@mui/material';

import Icons from 'assets/icons';
import { SnackBarVariantEnum } from 'components/SnackBar/SnackBar';
import Translations from 'const/translations/en';
import {
  InviteLinkBoxStyles,
  InviteWrapperStyled,
  ModalBodySheetStyles,
  ModalBoxContentStyles,
  ModalContentStackStyles,
} from 'page/modal/InviteTeamModal/styles';
import { ModalStyle } from 'page/modal/styles';
import { useSelector } from 'react-redux';
import useSnackbarContext from 'services/hooks/useSnackbar';
import { authState } from 'services/store/slices/authSlice';
import 'theme/styles/scroll-bar.css';

interface InviteTeamModalProps {
  isOpen: boolean;
  setIsOpen: Function;
}

const InviteTeamModal = ({ isOpen, setIsOpen }: InviteTeamModalProps) => {
  const { setSnackbarOpenStatus } = useSnackbarContext();
  const { user } = useSelector(authState);

  const email = user?.email;
  const domain = '@' + email?.split('@')[1];

  const inviteLink = () => {
    return `${window.location.origin}/register`;
  };

  const copyToClipboard = (e: React.MouseEvent<HTMLButtonElement>) => {
    navigator.clipboard.writeText(inviteLink());
    handleSnackBar(Translations.COPIED_TO_CLIPBOARD, SnackBarVariantEnum.success, Icons.ic_confetti);
  };

  const handleSnackBar = (message: string, variant: SnackBarVariantEnum, icon: string) => {
    setSnackbarOpenStatus(true, message, variant, icon);
    setTimeout(() => {
      setSnackbarOpenStatus(false, '', SnackBarVariantEnum.info);
    }, 5000);
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} component="div" sx={ModalStyle} role="dialog">
      <Sheet sx={{ ...ModalBodySheetStyles, maxWidth: '540px', maxHeight: '620px' }}>
        {' '}
        <>
          <Box sx={ModalBoxContentStyles}>
            <Typography id="modal-title" level="large+" textColor="inherit">
              Invite teammates
            </Typography>
            <ModalClose />
          </Box>
          <Divider sx={{ backgroundColor: 'var(--joy-palette-info-outlinedBorder)' }} />
          <Stack gap={1} sx={ModalContentStackStyles}>
            <>
              <Sheet variant="plain" sx={{ bgcolor: 'background.componentBg', borderRadius: 'sm' }}>
                <Box sx={{ gap: 3, p: '12px 16px 12px 16px', mb: '.1rem' }}>
                  <Typography
                    level="regular"
                    sx={{
                      color: 'secondary.dark',
                    }}
                  >
                    Link
                  </Typography>
                  <Typography level="regular" sx={{ color: '#797F87', mt: '8px' }}>
                    {Translations.INVITE_TEAM_MESSAGE}
                  </Typography>
                  <Typography level="regular" sx={{ color: '#545960' }}>
                    {Translations.INVITE_TEAM_MESSAGE_2} {domain} {Translations.INVITE_TEAM_MESSAGE_3}.
                  </Typography>
                </Box>
                <ListDivider component="div" />
                <Stack gap={1} sx={{ p: '4px 0px 12px 0px' }}>
                  <InviteWrapperStyled>
                    <TextField
                      variant="outlined"
                      //fullWidth
                      max-width="100%"
                      value={inviteLink()}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={copyToClipboard}
                              sx={{
                                background: 'transparent',
                              }}
                            >
                              <img src={Icons.ic_copy} alt="ic_copy" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={InviteLinkBoxStyles}
                    />
                    <Typography level="micro" fontSize="12px" sx={{ color: 'info.main', p: '0px 16px 0px 16px' }}>
                      {Translations.INVITE_TEAM_WARNING}
                    </Typography>
                  </InviteWrapperStyled>
                </Stack>
              </Sheet>
            </>
          </Stack>
        </>
      </Sheet>
    </Modal>
  );
};

export default InviteTeamModal;
