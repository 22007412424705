import { Skeleton } from '@mui/material';
import { Box, useTheme } from '@mui/joy';

const ParticipantPlaceholder = () => {
  const theme = useTheme();
  return (
    <Box sx={{ display: 'flex', p: '8px 16px', justifyContent: 'space-between' }}>
      <Skeleton variant="text" sx={{ fontSize: theme.typography.regular, width: '50%', mr: '10px' }} />
      <Skeleton variant="text" sx={{ fontSize: theme.typography.regular, width: '30%', mr: '10px' }} />
    </Box>
  );
};

export default ParticipantPlaceholder;
