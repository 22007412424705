import React from 'react';
import Spinner from 'components/ui-library/Spinner/Spinner';
import { UIButtonProps } from 'components/ui-library/Button/interface';
import { StyledButton } from 'components/ui-library/Button/styled';
import { getSpinnerColor } from 'components/ui-library/Button/getSpinnerColor';

const UIButton = (props: UIButtonProps) => {
  const { variant, color, rounded, loading, children, size } = props;
  const buttonSize = size && size != 'xs' ? size : undefined;
  const spinnerBackground = getSpinnerColor(variant, color);

  return (
    <StyledButton
      {...props}
      size={buttonSize}
      rounded={rounded}
      buttonSize={size}
      loading={loading}
      isLoading={loading}
    >
      {loading ? <Spinner spinnerBackground={spinnerBackground}>{children}</Spinner> : children}
    </StyledButton>
  );
};

export default UIButton;
