import { GridCellProps } from '@mui/x-data-grid-pro';
import { EntityPropertyDefinition, EntityViewField } from 'services/models/domain/entityViews';
import { Conversation } from 'services/models/domain/conversation';
import { EntityDetailsType } from 'services/store/slices/sidebarSlice';

export enum FollowupPages {
  initPage,
  calendarPage,
  assigneePage,
}

type CustomPropertyValue = {
  value: string;
  propertyType: string;
  customProperty: boolean;
};

export type ChatGridCellProps = GridCellProps<{
  value: string | CustomPropertyValue;
}>;

export type Properties = EntityPropertyDefinition[];
export type Conversations = Conversation[];

export type ChatTableProps = {
  loading: boolean;
  properties: Properties;
  entityViewFields: EntityViewField[];
  channelProperty: EntityPropertyDefinition | undefined;
  conversations: Conversations;
  refetchConversations: () => void;
  refetchEntityView: () => void;
  onUnmount: (positionY: number) => void;
  metadata?: TableViewMetadata;
  openSidebar: (entityOid: string, entityType: EntityDetailsType) => void;
};

export type TableViewMetadata = {
  scrollPositionY: number;
};
