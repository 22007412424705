import { Stack, Box, Typography, useTheme } from '@mui/joy';
import Option from '@mui/joy/Option';
import { Pagination, FormControl, useMediaQuery } from '@mui/material';

import {
  StyledSelect,
  RootBoxSx,
  DropdownBoxSx,
  DropdownStackSx,
  DropdownTypoSx,
  PagesStackSx,
  ResultsCountSx,
  DotSpacerStyle,
} from 'components/PagePagination/styles';
import Translations from 'const/translations/en';

export const PET_PAGE_OPTIONS = [25, 50, 100, 200];
export const DEFAULT_PER_PAGE = 50;

export type PaginationProps = {
  setLimitPerPage: (limit: number) => void;
  currentPage: number;
  onPageChange: (page: number) => void;
  pagesCount: number;
  limitPerPage: number;
  totalResultsCount: number;
};

const PagePagination = ({
  pagesCount,
  onPageChange,
  setLimitPerPage,
  currentPage,
  limitPerPage,
  totalResultsCount,
}: PaginationProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box sx={RootBoxSx}>
      <Box sx={DropdownBoxSx}>
        <FormControl>
          <Stack spacing={1} direction="row" sx={DropdownStackSx}>
            <StyledSelect
              value={limitPerPage}
              theme={theme}
              onChange={(_, value) => {
                value && setLimitPerPage(Number(value));
              }}
              size="sm"
            >
              {!matches && (
                <Typography level="micro" letterSpacing="sm" sx={DropdownTypoSx}>
                  {Translations.RESULTS_PER_PAGE}
                </Typography>
              )}

              {PET_PAGE_OPTIONS.map((limit) => (
                <Option key={limit} value={limit}>
                  {limit}
                </Option>
              ))}
            </StyledSelect>

            {matches && (
              <Typography level="micro" component="div">
                {Translations.RESULTS_PER_PAGE}
              </Typography>
            )}
          </Stack>
        </FormControl>
        <span style={DotSpacerStyle} />
        <Typography level="micro" sx={ResultsCountSx}>
          {matches ? `${totalResultsCount} in total` : totalResultsCount}
        </Typography>
      </Box>

      {pagesCount > 1 && (
        <Stack sx={PagesStackSx}>
          <Pagination
            count={pagesCount}
            page={currentPage}
            onChange={(_, value) => onPageChange(value)}
            shape="rounded"
            size={!matches ? 'small' : undefined}
          />
        </Stack>
      )}
    </Box>
  );
};

export default PagePagination;
