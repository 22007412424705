import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContactDetails } from 'services/models/domain/contact';
import { Conversation } from 'services/models/domain/conversation';
import * as domain from 'services/models/domain/entityViews';
import { RootState } from 'services/store/AppStore';
import { EntityDetailsType } from './sidebarSlice';
import { removePropertiesFromConversation } from 'services/utils/conversationUtils';

type EntityDetails = ContactDetails | Conversation;

export type AddPropertyToConversationPayload = {
  conversationOid: string;
  propertyValue: domain.PropertyValue;
};

export type RemovePropertyFromConversationPayload = {
  conversationOid: string;
  propertyValue: domain.PropertyValue;
};

export interface EntityDetailsState {
  detailsEntityOid: string | undefined;
  detailsEntity: EntityDetails | undefined;
  detailsLoading: boolean;
  detailsEntityType: EntityDetailsType | undefined;
}

const initialState: EntityDetailsState = {
  detailsEntityOid: undefined,
  detailsEntity: undefined,
  detailsLoading: true,
  detailsEntityType: undefined,
};

const detailsSlice = createSlice({
  name: 'detailsPage',
  initialState,
  reducers: {
    setDetailsEntityOid: (
      state,
      action: PayloadAction<{ oid: string | undefined; type: EntityDetailsType | undefined }>
    ) => {
      if (state.detailsEntityOid !== action.payload.oid) {
        state.detailsEntity = undefined;
      }
      state.detailsEntityOid = action.payload.oid;
      state.detailsEntityType = action.payload.type;
    },
    setDetailsEntity: (state, action: PayloadAction<EntityDetails | undefined>) => {
      state.detailsEntity = action.payload;
    },
    setDetailsLoading: (state, action: PayloadAction<boolean>) => {
      state.detailsLoading = action.payload;
      if (action.payload) {
        state.detailsEntity = undefined;
      }
    },
    resetDetailsEntity: (state) => {
      state.detailsEntityOid = undefined;
      state.detailsEntity = undefined;
      state.detailsLoading = true;
    },
    addPropertyForConversation: (state, action: PayloadAction<AddPropertyToConversationPayload>) => {
      if (state.detailsEntityOid === action.payload.conversationOid) {
        const newProperties = [...(state.detailsEntity as Conversation).properties, action.payload.propertyValue];
        (state.detailsEntity as Conversation).properties = newProperties;
      }
    },
    removePropertyForConversation: (state, action: PayloadAction<RemovePropertyFromConversationPayload>) => {
      if (state.detailsEntityOid === action.payload.conversationOid) {
        const newProperties = removePropertiesFromConversation(
          state.detailsEntity as Conversation,
          action.payload.propertyValue
        );
        (state.detailsEntity as Conversation).properties = newProperties;
      }
    },
  },
});

export const {
  setDetailsEntityOid,
  setDetailsEntity,
  setDetailsLoading,
  resetDetailsEntity,
  addPropertyForConversation,
  removePropertyForConversation,
} = detailsSlice.actions;
export const detailsState = (state: RootState) => state.detailsPage;
export default detailsSlice.reducer;
