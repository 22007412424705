import React from 'react';
import { Button } from '@mui/joy';
import { SxProps } from '@mui/joy/styles/types';
import Spinner from 'components/ui-library/Spinner/Spinner';

interface Props {
  children: React.ReactNode;
  variant?: 'plain' | 'outlined' | 'soft' | 'solid' | undefined;
  fullWidth?: boolean;
  loading?: boolean;
  [rest: string]: any;
  sx?: SxProps;
  disabled?: boolean;
}

const AppButton: React.FC<Props> = ({ children, variant, fullWidth, loading = false, sx, ...rest }) => (
  <Button
    variant={variant ? variant : 'solid'}
    fullWidth={fullWidth ? true : false}
    sx={{
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: 'primary.main',
        opacity: '0.8',
      },
      '&:disabled': {
        backgroundColor: 'primary.main',
        opacity: '0.8',
      },
      ...sx,
    }}
    {...rest}
  >
    {loading ? <Spinner /> : children}
  </Button>
);

export default AppButton;
