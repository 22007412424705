import { styled } from '@mui/joy';
import { SxProps } from '@mui/material';
import UIChip from 'components/ui-library/Chip/UIChip';
import { colors } from 'theme/colors';

export const MenuSx: SxProps = {
  maxHeight: '80%',
};

export const FilterChip = styled(UIChip)(() => ({
  alignSelf: 'center',
  borderRadius: '50px',
  fontWeight: 200,
  '& svg': {
    stroke: colors.neutral[50],
  },
}));

export const ContainerSx: SxProps = {
  height: 'fit-content',
};

export const OptionSx: SxProps = {
  '& .JoyListItemDecorator-root': {
    width: '100%',
    minWidth: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};

export const CheckboxSx: SxProps = {
  '--Icon-color': colors.white,
  '& .JoyCheckbox-checkbox.Joy-checked': {
    background: colors.primary[50],
    border: 'none !important',
  },
  '& svg': {
    color: `${colors.white} `,
  },
};

export const UserBoxSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  width: '100%',
  cursor: 'pointer',
};

export const NameSx: SxProps = {
  color: 'secondary.dark',
  lineHeight: '16px',
  letterSpacing: '-0.005em',
  textOverflow: 'ellipsis',
  maxWidth: '150px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

export const ChipButtonSx: SxProps = {
  minHeight: '30px',
  fontSize: '14px',
  lineHeight: '14px',
  height: '30px',
};
