import { batch, useDispatch, useSelector } from 'react-redux';
import {
  OptionPropertyValue,
  PropertyDefinitionDataType,
  PropertyValue,
  TenantUserPropertyValue,
  ValuePropertyValue,
} from 'services/models/domain/entityViews';
import { EntityPropertyDefinition } from 'services/models/domain/entityViews';
import * as conversationSlice from 'services/store/slices/conversationsSlice';
import * as pipelineConversationSlice from 'services/store/slices/pipelineConversationsSlice';
import * as sidebarEntityDetailsSlice from 'services/store/slices/sidebarEntityDetailsSlice';
import * as gql from 'services/models/api/generated';
import _ from 'lodash';
import { EntityType } from 'services/models/domain/entityTypes';
import * as detailsSlice from 'services/store/slices/detailsSlice';
import { GridApiPro, useGridApiContext } from '@mui/x-data-grid-pro';
import { conversationsState } from 'services/store/slices/conversationsSlice';
import { arePropertyValueIdsEqual } from 'services/utils/propertyUtils';
import { LocalStorageValues } from 'services/utils/interface';
import { useSafeGridApiContext } from './useSafeGridApiContext';

export type EntityWithProperties = {
  propertyOids?: string[];
  id: string;
  entityType: EntityType;
};

export function usePropertyValue() {
  const dispatch = useDispatch();
  const [setProperty] = gql.useSetPropertyForEntityMutation();
  const [setBatchProperty] = gql.useSetBatchPropertyForEntitiesMutation();
  const { conversations } = useSelector(conversationsState);
  const apiRef = useSafeGridApiContext();

  const updateBatchSelectedValues = async (
    propValueIds: string[],
    entityIds: string[],
    entityType: EntityType,
    propertyDefinitionOid: string
  ) => {
    let input: gql.SetBatchPropertyForEntitiesMutationVariables['input'] = {
      entityOids: entityIds,
      entityType: entityType as unknown as gql.EntityType,
      propertyDefinitionOid: propertyDefinitionOid,
    } as gql.SetBatchPropertyForEntitiesMutationVariables['input'];

    input.referenceValues = propValueIds.map((value) => {
      return {
        valueOid: value,
      };
    });

    await setBatchProperty({
      variables: { input },
    });
  };

  const updateSelectedValues = async (
    propValueIds: string[],
    entityId: string,
    entityType: EntityType,
    propertyDefinitionOid: string
  ) => {
    // Input without referenceValue would mean
    // unsetting values

    let input: gql.SetPropertyForEntityMutationVariables['input'] = {
      entityOid: entityId,
      entityType: entityType as unknown as gql.EntityType,
      propertyDefinitionOid: propertyDefinitionOid,
      //  'as' is because of wrong types generation for optional fields
    } as gql.SetPropertyForEntityMutationVariables['input'];

    input.referenceValues = propValueIds.map((value) => {
      return {
        valueOid: value,
      };
    });

    await setProperty({
      variables: { input },
      onCompleted: (res) => {
        const isSuccess = res?.setPropertyForEntity?.status.isSuccess;
      },
      onError: () => {},
    });
  };

  // TODO (AWE): HANDLE MULTI VALUE
  const handleUpdatePropertyOption = async (
    propertyDefinition: EntityPropertyDefinition,
    entity: EntityWithProperties,
    fromPropValueIds: string[],
    toPropValueIds: string[],
    switchedToNoStatus?: boolean
  ) => {
    const fromPropertyValues = propertyDefinition.values.filter((propertyValue) => {
      return (
        (propertyValue.type === PropertyDefinitionDataType.Option ||
          propertyValue.type === PropertyDefinitionDataType.TenantUser) &&
        fromPropValueIds.includes(propertyValue.oid.oid)
      );
    }) as unknown as (TenantUserPropertyValue | OptionPropertyValue)[];

    if (switchedToNoStatus) {
      dispatch(pipelineConversationSlice.updateNoStatusPaginationInfo());
    }

    batch(() => {
      fromPropertyValues.forEach((property) => {
        dispatch(
          pipelineConversationSlice.removePropertyForConversation({
            conversationOid: entity.id,
            propertyValue: property,
          })
        );
        dispatch(
          conversationSlice.removePropertyForConversation({
            conversationOid: entity.id,
            propertyValue: property,
          })
        );
        dispatch(
          sidebarEntityDetailsSlice.removePropertyForConversation({
            conversationOid: entity.id,
            propertyValue: property,
          })
        );
        dispatch(
          detailsSlice.removePropertyForConversation({
            conversationOid: entity.id,
            propertyValue: property,
          })
        );
      });

      if (propertyDefinition.name === 'Status' && toPropValueIds.length === 0) {
        dispatch(
          pipelineConversationSlice.addPropertyForConversation({
            conversationOid: entity.id,
            propertyValue: undefined,
          })
        );
      }

      if (!!toPropValueIds.length) {
        const toPropertyValues = propertyDefinition.values.filter((propertyValue) => {
          return (
            (propertyValue.type === PropertyDefinitionDataType.Option ||
              propertyValue.type === PropertyDefinitionDataType.TenantUser) &&
            toPropValueIds.includes(propertyValue.oid.oid)
          );
        });

        toPropertyValues.forEach((toPropertyValue) => {
          dispatch(
            conversationSlice.addPropertyForConversation({
              conversationOid: entity.id,
              propertyValue: toPropertyValue,
            })
          );
          dispatch(
            pipelineConversationSlice.addPropertyForConversation({
              conversationOid: entity.id,
              propertyValue: toPropertyValue,
            })
          );
          dispatch(
            sidebarEntityDetailsSlice.addPropertyForConversation({
              conversationOid: entity.id,
              propertyValue: toPropertyValue,
            })
          );
          dispatch(
            detailsSlice.addPropertyForConversation({
              conversationOid: entity.id,
              propertyValue: toPropertyValue,
            })
          );
        });
      }
    });

    await updateSelectedValues(toPropValueIds, entity.id, entity.entityType, propertyDefinition.oid.oid);
  };

  const handleUpdatePropertyValue = async (
    entityOid: string,
    entityType: EntityType,
    propertyDefinitionOid: string,
    fromValue?: string,
    toValue?: string
  ) => {
    const createPropertyValue = (value?: string) =>
      ({
        value: toValue ?? '',
        propertyDefinitionOid: { oid: propertyDefinitionOid },
        type: PropertyDefinitionDataType.Value,
      }) as ValuePropertyValue;

    const deleteProperty = createPropertyValue(fromValue);
    batch(() => {
      dispatch(
        pipelineConversationSlice.removePropertyForConversation({
          conversationOid: entityOid,
          propertyValue: deleteProperty,
        })
      );
      dispatch(
        conversationSlice.removePropertyForConversation({
          conversationOid: entityOid,
          propertyValue: deleteProperty,
        })
      );
      dispatch(
        sidebarEntityDetailsSlice.removePropertyForConversation({
          conversationOid: entityOid,
          propertyValue: deleteProperty,
        })
      );
      dispatch(
        detailsSlice.removePropertyForConversation({
          conversationOid: entityOid,
          propertyValue: deleteProperty,
        })
      );

      if (!!toValue) {
        const addPropertyValue = createPropertyValue(toValue);
        dispatch(
          conversationSlice.addPropertyForConversation({
            conversationOid: entityOid,
            propertyValue: addPropertyValue,
          })
        );
        dispatch(
          pipelineConversationSlice.addPropertyForConversation({
            conversationOid: entityOid,
            propertyValue: addPropertyValue,
          })
        );
        dispatch(
          sidebarEntityDetailsSlice.addPropertyForConversation({
            conversationOid: entityOid,
            propertyValue: addPropertyValue,
          })
        );
        dispatch(
          detailsSlice.addPropertyForConversation({
            conversationOid: entityOid,
            propertyValue: addPropertyValue,
          })
        );
      }
    });

    await setProperty({
      variables: {
        input: {
          entityOid: entityOid,
          entityType: entityType,
          propertyDefinitionOid: propertyDefinitionOid,
          values: toValue
            ? [
                {
                  value: toValue,
                },
              ]
            : [],
        },
      },
    });
  };

  const handleUpdatePropertyOptionForBatchEntities = async (
    propertyDefinition: EntityPropertyDefinition,
    updatedEntity: {
      id: string;
      entityType: EntityType;
    },
    fromPropValueIds: string[],
    toPropValueIds: string[]
  ) => {
    const updatedEntityValuesIds = conversations
      .find((c) => c.oid.oid === updatedEntity.id)
      ?.properties.map((propertyValue) => {
        if ('oid' in propertyValue && propertyValue.propertyDefinitionOid.oid === propertyDefinition.oid.oid) {
          return propertyValue.oid.oid;
        }
      });

    if (updatedEntityValuesIds && arePropertyValueIdsEqual(updatedEntityValuesIds, toPropValueIds)) {
      return;
    }
    const toPropertyValues = propertyDefinition.values.filter((propertyValue) => {
      return (
        propertyValue &&
        (propertyValue.type === 'OPTION' || propertyValue.type === 'TENANT_USER') &&
        toPropValueIds.includes(propertyValue.oid.oid)
      );
    });

    const entities = apiRef
      ? Array.from(apiRef.current.getSelectedRows().keys()).map((entityId) => {
          return { id: entityId.toString() };
        })
      : [];
    const propertyValues =
      propertyDefinition.kind === 'SINGLE_VALUE'
        ? propertyDefinition.values
            .filter(
              (propertyValue) =>
                (propertyValue?.type === 'OPTION' || propertyValue?.type === 'TENANT_USER') && 'oid' in propertyValue
            )
            .map((propertyValue) => 'oid' in propertyValue && propertyValue.oid.oid)
        : [];

    const oldPropertyValueIds = [...fromPropValueIds, ...propertyValues];

    const oldPropertyValues = propertyDefinition.values.filter(
      (propertyValue) =>
        (propertyValue.type === PropertyDefinitionDataType.Option ||
          propertyValue.type === PropertyDefinitionDataType.TenantUser) &&
        oldPropertyValueIds.includes(propertyValue.oid.oid)
    ) as (TenantUserPropertyValue | OptionPropertyValue)[];

    batch(() => {
      entities.forEach((entity) => {
        oldPropertyValues.forEach((property) => {
          if (!property) {
            return;
          }
          dispatch(
            pipelineConversationSlice.removePropertyForConversation({
              conversationOid: entity.id,
              propertyValue: property,
            })
          );
          dispatch(
            conversationSlice.removePropertyForConversation({
              conversationOid: entity.id,
              propertyValue: property,
            })
          );
          dispatch(
            sidebarEntityDetailsSlice.removePropertyForConversation({
              conversationOid: entity.id,
              propertyValue: property,
            })
          );
          dispatch(
            detailsSlice.removePropertyForConversation({
              conversationOid: entity.id,
              propertyValue: property,
            })
          );
        });

        if (toPropValueIds.length === 0) {
          dispatch(
            pipelineConversationSlice.addPropertyForConversation({
              conversationOid: entity.id,
              propertyValue: undefined,
            })
          );
        }

        toPropertyValues.forEach((toPropertyValue) => {
          dispatch(
            conversationSlice.addPropertyForConversation({
              conversationOid: entity.id,
              propertyValue: toPropertyValue,
            })
          );
          dispatch(
            pipelineConversationSlice.addPropertyForConversation({
              conversationOid: entity.id,
              propertyValue: toPropertyValue,
            })
          );
          dispatch(
            sidebarEntityDetailsSlice.addPropertyForConversation({
              conversationOid: entity.id,
              propertyValue: toPropertyValue,
            })
          );
          dispatch(
            detailsSlice.addPropertyForConversation({
              conversationOid: entity.id,
              propertyValue: toPropertyValue,
            })
          );
        });
      });
    });
    await updateBatchSelectedValues(
      toPropValueIds,
      entities.map((e) => e.id),
      updatedEntity.entityType,
      propertyDefinition.oid.oid
    );
  };

  const handleUpdatePropertyValueBatchEntities = async (
    entityOids: string[],
    entityType: EntityType,
    propertyDefinitionOid: string,
    fromValue?: string,
    toValue?: string
  ) => {
    const createPropertyValue = (value?: string) =>
      ({
        value: toValue ?? '',
        propertyDefinitionOid: { oid: propertyDefinitionOid },
        type: PropertyDefinitionDataType.Value,
      }) as ValuePropertyValue;
    const deleteProperty = createPropertyValue(fromValue);
    batch(() => {
      entityOids.forEach((entityOid) => {
        dispatch(
          pipelineConversationSlice.removePropertyForConversation({
            conversationOid: entityOid,
            propertyValue: deleteProperty,
          })
        );
        dispatch(
          conversationSlice.removePropertyForConversation({
            conversationOid: entityOid,
            propertyValue: deleteProperty,
          })
        );
        dispatch(
          sidebarEntityDetailsSlice.removePropertyForConversation({
            conversationOid: entityOid,
            propertyValue: deleteProperty,
          })
        );
        dispatch(
          detailsSlice.removePropertyForConversation({
            conversationOid: entityOid,
            propertyValue: deleteProperty,
          })
        );
      });

      entityOids.forEach((entityOid) => {
        const addPropertyValue = createPropertyValue(toValue);
        dispatch(
          conversationSlice.addPropertyForConversation({
            conversationOid: entityOid,
            propertyValue: addPropertyValue,
          })
        );
        dispatch(
          pipelineConversationSlice.addPropertyForConversation({
            conversationOid: entityOid,
            propertyValue: addPropertyValue,
          })
        );
        dispatch(
          sidebarEntityDetailsSlice.addPropertyForConversation({
            conversationOid: entityOid,
            propertyValue: addPropertyValue,
          })
        );
        dispatch(
          detailsSlice.addPropertyForConversation({
            conversationOid: entityOid,
            propertyValue: addPropertyValue,
          })
        );
      });
    });
    await setBatchProperty({
      variables: {
        input: {
          entityOids: entityOids,
          entityType: entityType,
          propertyDefinitionOid: propertyDefinitionOid,
          values: toValue
            ? [
                {
                  value: toValue,
                },
              ]
            : [],
        },
      },
    });
  };

  return {
    handleUpdatePropertyOption,
    handleUpdatePropertyValue,
    handleUpdatePropertyOptionForBatchEntities,
    handleUpdatePropertyValueBatchEntities,
  };
}
