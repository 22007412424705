import * as gql from 'services/models/api/generated';
import {
  ChannelType,
  Conversation,
  ConversationParticipant,
  LinkDescriptionType,
  OriginTypes,
} from 'services/models/domain/conversation';
import * as domain from 'services/models/domain/entityViews';
import { Folder } from 'services/models/domain/folder';
import { Followup } from 'services/models/domain/followUps';
import { User } from 'services/models/domain/user';
import { mapNoteFromApi, updateUserInNote } from './noteUtils';
import { updateUserInFolders } from './folderUtils';
import { updateUserInValue } from './entityViewUtils';
import { ChannelWebLinks } from 'services/constants';
import Translations from 'const/translations/en';

export const updateConversations = (existing: Conversation[], updates: Conversation[]): Conversation[] => {
  const updatesMap = new Map();
  updates.forEach((conversation) => {
    updatesMap.set(conversation.oid.oid, conversation);
  });
  const existingMap = new Map();
  existing.forEach((conversation) => {
    existingMap.set(conversation.oid.oid, conversation);
  });
  const updatedExisting = existing.map((conversation) => {
    const update = updatesMap.get(conversation.oid.oid);
    if (update) {
      return update;
    }
    return conversation;
  });

  const updatesWithExisting = updates.filter((conversation) => {
    const existingConversation = existingMap.get(conversation.oid.oid);
    return !existingConversation;
  });

  return [...updatedExisting, ...updatesWithExisting];
};

export const updateIndividualChatName = (conversation: gql.Conversation, user: User) => {
  const isIndividualChat = conversation.conversationType === domain.ConversationType.Individual;
  const { participants } = conversation;

  // Use the name of the person outside my org as the title of the chat if there is one
  if (isIndividualChat && participants?.length > 1) {
    const isFirstParticipantFromMyOrg = user?.actingAsMemberOfOrganization.listOfUsersInOrganization.some(
      (u) => u.oid === participants[0].userOid?.oid
    );
    const mainParticipantIndex = isFirstParticipantFromMyOrg ? 1 : 0;
    const chatNameToShow = participants[mainParticipantIndex].name || participants[mainParticipantIndex].firstName;

    if (chatNameToShow) {
      return { ...conversation, name: chatNameToShow };
    }
  }

  return conversation;
};

export const mapPropertyValueToApi = (propertyValue: domain.PropertyValue): gql.PropertyValue | undefined => {
  if (propertyValue.type === 'OPTION') {
    const value: gql.OptionPropertyValue = {
      color: propertyValue.color || '',
      description: propertyValue.description || '',
      icon: propertyValue.icon || '',
      oid: { oid: propertyValue.oid.oid, __typename: 'OID' },
      ordering: propertyValue.ordering,
      propertyDefinitionOid: { oid: propertyValue.propertyDefinitionOid.oid, __typename: 'OID' },
      value: propertyValue.value,
      __typename: 'OptionPropertyValue',
    };
    return value;
  }
  if (propertyValue.type === 'TENANT_USER') {
    const value: gql.TenantUserPropertyValue = {
      avatar: propertyValue.avatar || '',
      firstName: propertyValue.firstName || '',
      fullName: propertyValue.fullName,
      lastName: propertyValue.lastName || '',
      oid: { oid: propertyValue.oid.oid, __typename: 'OID' },
      propertyDefinitionOid: { oid: propertyValue.propertyDefinitionOid.oid, __typename: 'OID' },
      username: propertyValue.username || '',
      __typename: 'TenantUserPropertyValue',
    };
    return value;
  }
  return undefined;
};

export const mapConversationToDomain = (conversation: gql.Conversation, chatLinkVisibility?: boolean): Conversation => {
  const folders: Folder[] = conversation.folder?.map(mapFolderToDomain) ?? [];
  return {
    oid: { oid: conversation.oid.oid },
    conversationRecordOid: { oid: conversation.conversationRecordOid.oid },
    name: conversation.name,
    conversationType: conversation.conversationType as domain.ConversationType,
    folders: folders,
    chatLink: chatLinkVisibility ? conversation.chatLink : undefined,
    conversationOrigin: conversation.conversationOrigin as OriginTypes,
    createdAt: {
      utcTimeStampAsString: conversation.createdAt.utcTimeStampAsString,
    },
    favorit: conversation.favorit,
    followups: conversation.followups?.map(mapFollowUpToDomain) ?? [],
    internalConversationId: conversation.internalConversationId,
    isArchived: conversation.isArchived,
    lastMessageAt: conversation.lastMessageAt,
    lastMessageSenderParticipantOid: { oid: conversation.lastMessageSenderParticipantOid },
    nextFollowup: conversation.nextFollowup ? mapFollowUpToDomain(conversation.nextFollowup) : undefined,
    notes: conversation.notes?.map(mapNoteFromApi) ?? [],
    participants: conversation.participants?.map(mapParticipantToDomain) ?? [],
    userIsParticipant: conversation.userIsParticipant,
    properties:
      (conversation.properties
        .map(mapPropertyValueToDomain)
        .filter((pv) => pv !== undefined) as domain.PropertyValue[]) ?? [],
    lastModifiedAt: conversation.lastModifiedAt,
    lastModifiedBy: { oid: conversation.lastModifiedBy },
  };
};

export const mapFolderToDomain = (folder: gql.Folder): Folder => {
  return {
    folderName: folder.folderName,
    oid: { oid: folder.oid.oid },
    synced: folder.synced,
    user: {
      fullName: folder.user.fullName,
      oid: { oid: folder.user.oid },
      profilePicture: folder.user.profileImageUrl,
    },
    externalId: folder.externalId,
  };
};

export const mapParticipantToDomain = (participant: gql.ConversationParticipant): ConversationParticipant => {
  return {
    firstName: participant.firstName,
    internalParticipantId: participant.internalParticipantId,
    lastName: participant.lastName,
    name: participant.name,
    oid: { oid: participant.oid.oid },
    userHandle: participant.userHandle,
    userOid: participant.userOid ? { oid: participant.userOid.oid } : undefined,
  };
};

export const mapFollowUpToDomain = (followup: gql.Followup): Followup => {
  return {
    assignedToUser: followup.assignedToUser,
    createdByUserOid: followup.createdByUserOid?.oid,
    dueAt: { utcTimeStampAsString: followup.dueAt?.utcTimeStampAsString },
    oid: followup.oid,
  };
};

export const mapPropertyValueToDomain = (propertyValue: gql.PropertyValue): domain.PropertyValue | undefined => {
  if (!propertyValue?.__typename) {
    return undefined;
  }
  if (propertyValue.__typename === 'OptionPropertyValue') {
    return {
      color: propertyValue.color,
      description: propertyValue.description,
      icon: propertyValue.icon,
      oid: { oid: propertyValue.oid.oid },
      ordering: propertyValue.ordering,
      propertyDefinitionOid: { oid: propertyValue.propertyDefinitionOid.oid },
      type: 'OPTION',
      value: propertyValue.value,
    };
  }
  if (propertyValue.__typename === 'TenantUserPropertyValue') {
    return {
      avatar: propertyValue.avatar,
      firstName: propertyValue.firstName,
      fullName: propertyValue.fullName,
      lastName: propertyValue.lastName,
      oid: { oid: propertyValue.oid.oid },
      propertyDefinitionOid: { oid: propertyValue.propertyDefinitionOid.oid },
      type: 'TENANT_USER',
      username: propertyValue.username,
    };
  }
  if (propertyValue.__typename === 'ValuePropertyValue') {
    return {
      propertyDefinitionOid: { oid: propertyValue.propertyDefinitionOid.oid },
      type: 'VALUE',
      value: propertyValue.value,
    };
  }
};

export const isValidLink = (link: string): boolean => {
  const regex = new RegExp(
    '^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$'
  );
  return regex.test(link);
};

export const isValidEmail = (email: string): boolean => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return regex.test(email);
};

export const getChannelFromLink = (link: string) => {
  const channelEntry = Object.entries(ChannelWebLinks).find(([_channel, webLinks]) =>
    webLinks.some((webLink) => link.includes(webLink))
  );
  return !!channelEntry ? channelEntry[0] : undefined;
};

export const getLinkDescription = (origin: string, telegramChatLink?: string, link?: string, channelType?: string) => {
  if (origin === OriginTypes.TelegramSync && telegramChatLink) {
    return { type: LinkDescriptionType.OpenTelegramTooltip };
  } else if (origin === OriginTypes.TelegramSync && !telegramChatLink) {
    return { type: LinkDescriptionType.NoTelegramChatLink };
  } else if (link && channelType === ChannelType.Email) {
    return `Email: ${link}`;
  } else if (link && channelType) {
    return `Open in ${channelType}`;
  } else if (link) {
    return Translations.TOOLTIP_OPEN_LINK;
  } else {
    return { type: LinkDescriptionType.NoLinkEntered };
  }
};

export const removePropertiesFromConversation = (
  conversation: Conversation,
  propertyValue: domain.PropertyValue
): domain.PropertyValue[] => {
  return conversation.properties.filter((p) => {
    if (!p) {
      return false;
    }
    if (p.type === domain.PropertyDefinitionDataType.Option) {
      return p.propertyDefinitionOid.oid !== propertyValue.propertyDefinitionOid.oid;
    }
    if (p.type === domain.PropertyDefinitionDataType.TenantUser) {
      return (
        p.propertyDefinitionOid.oid !== propertyValue.propertyDefinitionOid.oid &&
        p.propertyDefinitionOid.oid !== propertyValue.propertyDefinitionOid.oid
      );
    }
    if (p.type === domain.PropertyDefinitionDataType.Value) {
      return p.propertyDefinitionOid.oid !== propertyValue.propertyDefinitionOid.oid;
    }
    return true;
  });
};

export const updateUserInConversation = (conversation: Conversation, user: User): Conversation => {
  return {
    ...conversation,
    nextFollowup:
      conversation.nextFollowup && conversation.nextFollowup.assignedToUser.oid === user.oid
        ? {
            ...conversation.nextFollowup,
            assignedToUser: {
              ...conversation.nextFollowup.assignedToUser,
              profileImageUrl: user.profileImageUrl,
              fullName: user.fullName,
              firstName: user.firstName,
            },
          }
        : conversation.nextFollowup,
    notes: conversation.notes?.map((note) => updateUserInNote(note, user)),
    properties: conversation.properties.map((property) => {
      return updateUserInValue(property, user);
    }),
    folders: updateUserInFolders(conversation.folders, user),
  };
};
