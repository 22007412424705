export const colors = {
  neutral: {
    0: '#F8F9FA',
    5: '#F3F5F7',
    10: '#F0F4F6',
    15: '#E6EBEF',
    20: '#DCE3E8',
    30: '#C0C9D0',
    40: '#9EA6AF',
    50: '#787F88',
    60: '#545960',
    70: '#363A3F',
    80: '#212327',
    90: '#141618',
    100: '#0F1012',
  },
  primary: {
    0: '#F8FCFF',
    5: '#E2F0FD',
    10: '#B6DFFE',
    15: '#8DC3F7',
    20: '#7EC4FC',
    30: '#54AEF7',
    40: '#3798F0',
    50: '#247AE5',
    60: '#1762D4',
    70: '#0E50BC',
    80: '#083E98',
    90: '#042968',
    100: '#02122E',
  },
  white: '#FFFFFF',
  black: '#000000',
  success: '#03C32C',
  warning: '#DB2042',
  border: '#F4F6F8',
  blue: '#2477E5',
  trash: {
    background: '#FDF4F5',
    border: '#FADFE3',
  },
  typography: '#3C7ADE',
  kanbanBackground: '#FFFFFF',
  navigationBarBackground: '#F9FAFB',
  cellHoverBg: `rgba(251,252,255,0.99)`,
  telegramLogo: '#2AABEE',
  transparent: '#FFFFFF00',
  inputCaret: '#267FE8',
  modalBackground: '#0F1012',
};
