import { Box, Typography } from '@mui/joy';
import { headerStyles } from 'page/dashboard/header/styles';
interface Props {
  title: string;
  onClick: Function | any;
  icon: string | JSX.Element;
  isNew?: boolean;
}

export function NewTag() {
  return (
    <Box sx={headerStyles.newTagBox}>
      <Typography sx={headerStyles.newTagText}>NEW</Typography>
    </Box>
  );
}

export default function ProfileItem({ title, onClick, icon, isNew }: Props) {
  return (
    <Box
      sx={{
        p: 1,
        flexDirection: 'row',
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'space-between',
        alignContent: 'center',
        alignItems: 'center',
        color: 'var(--joy-palette-info-plainBg)',

        '&:hover': {
          background: 'var(--joy-palette-info-outlinedBorder)',
          //border: '0.5px solid #ECF0F3',
          borderRadius: ' 8px',
          color: 'var(--joy-palette-primary-solidColor)',
          transition: 'all 0.5s ease',
        },
      }}
      onClick={() => onClick()}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {typeof icon === 'string' ? <img src={icon} alt={'ic_user'} height={18} width={18} /> : <>{icon}</>}
        <Typography level="tiny" textColor="inherit" sx={{ textAlign: 'left', px: 1 }}>
          {title}
        </Typography>
      </Box>
      {isNew && <NewTag />}
    </Box>
  );
}
