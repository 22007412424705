import { useTheme } from '@mui/joy/styles';
import { Breakpoint } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function useResponsive(
  query: string,
  key?: number | Breakpoint,
  start?: number | Breakpoint,
  end?: number | Breakpoint
) {
  const theme = useTheme();

  const mediaUp = useMediaQuery(theme.breakpoints.up(key ?? 'lg'));

  const mediaDown = useMediaQuery(theme.breakpoints.down(key ?? 'lg'));

  const mediaBetween = useMediaQuery(theme.breakpoints.between(start ?? 'lg', end ?? 'lg'));

  const mediaOnly = useMediaQuery(theme.breakpoints.only(key as Breakpoint));

  if (query === 'up') {
    return mediaUp;
  }

  if (query === 'down') {
    return mediaDown;
  }

  if (query === 'between') {
    return mediaBetween;
  }

  if (query === 'only') {
    return mediaOnly;
  }
  return null;
}
