import { useApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import * as gql from 'services/models/api/generated';
import {
  UpdateUserInput,
  UserPropertyCategoryType,
  UserResponse,
  useGetUserDynamicPropertiesLazyQuery,
  useGetUserLazyQuery,
  useSignoutUserMutation,
  useUpdateUserMutation,
} from 'services/models/api/generated';
import { LoginResultParam } from 'services/models/domain/auth';
import { User } from 'services/models/domain/user';
import { authState, setError, setInitialize, setLogin, setLogout, setUser } from 'services/store/slices/authSlice';
import AnalyticKeys, { identifyUser, trackAction } from 'services/utils/analytics';
import { setAuthToken } from 'services/utils/helpers';
import { LocalStorageValues } from 'services/utils/interface';
import parseStateProperty from 'services/utils/statePropertyParser';
import { mapGqlUserToDomainUser, mapUserToDomain } from 'services/utils/userUtils';
import { useTelegramClient } from './useTelegramClient';

const useAuth = () => {
  const dispatch = useDispatch();
  const [getUser, { data: _user }] = useGetUserLazyQuery();
  const [signOut] = useSignoutUserMutation();
  const [updateUser] = useUpdateUserMutation();
  const [getMetaData] = useGetUserDynamicPropertiesLazyQuery();
  const { establishConnection } = useTelegramClient();
  const client = useApolloClient();

  const {
    user,
    isFirstTime,
    email,
    isAuthenticated,
    isInitialized,
    isNameStepCompleted,
    isInviteStepCompleted,
    isSigninCompleted,
  } = useSelector(authState);
  const ldClient = useLDClient();

  const initialize = async () => {
    try {
      const accessToken = localStorage.getItem(LocalStorageValues.AuthToken);
      if (accessToken) {
        setAuthToken(accessToken);
        await getStateData();
        const result = await getUser();
        const user = result.data?.user;
        const isSuccess = user?.status.isSuccess;

        if (!isSuccess) {
          setAuthToken(null);
          dispatch(setInitialize({ isAuthenticated: false, user: null, isInitialized: false, authorized: false }));
          return;
        }

        if (user?.data != null) {
          const temp = mapGqlUserToDomainUser(user?.data?.user as gql.User);
          temp.actingAsMemberOfOrganization &&
            ldClient?.identify({ key: temp?.actingAsMemberOfOrganization.oid, name: temp.name, email: temp.email });
          dispatch(
            setInitialize({
              isAuthenticated: true,
              isInitialized: true,
              authToken: accessToken,
              authorized: true,
              user: temp,
            })
          );
          identifyUser(temp?.oid ?? '', {
            displayName: temp?.name,
            organizationId: temp?.actingAsMemberOfOrganization.oid,
            organizationName: temp?.actingAsMemberOfOrganization.name,
            email: temp.email,
            userId: temp.oid,
          });

          Sentry.setUser({
            id: temp?.oid,
            username: temp.fullName,
          });
          Sentry.setContext('user', {
            organizationOid: temp?.actingAsMemberOfOrganization.oid,
            userOid: temp?.oid,
            id: temp?.oid,
          });

          window.analytics?.group(temp.actingAsMemberOfOrganization.oid, {
            id: temp.actingAsMemberOfOrganization.oid,
            name: temp.actingAsMemberOfOrganization.name,
            userId: temp.oid,
          });

          establishConnection();
        }
      } else {
        dispatch(setInitialize({ isAuthenticated: false, isInitialized: true, authorized: false, user: null }));
      }
    } catch (err) {
      console.error(err);
      setAuthToken(null);
      dispatch(setInitialize({ isAuthenticated: false, isInitialized: true, authorized: false, user: null }));
    }
  };

  const getStateData = async () => {
    const meta = await getMetaData({
      variables: {
        filter: {
          propertyCategoryType: UserPropertyCategoryType.UserState,
          propertyId: [
            'TELEGRAM_CONNECT_PRESENTED_IN_ONBOARDING',
            'PASSED_NAME_ONBOARDING_STEP',
            'PASSED_INVITE_TEAM_ONBOARDING_STEP',
          ],
        },
      },
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    });

    if (meta.data?.getUserDynamicProperties.status.isSuccess) {
      const isFirstTime: boolean =
        parseStateProperty({
          props: meta.data?.getUserDynamicProperties.data.find(
            (value) => value.propertyId === 'TELEGRAM_CONNECT_PRESENTED_IN_ONBOARDING'
          ),
        }) ?? true;
      const isNameStepCompleted = parseStateProperty({
        props: meta.data?.getUserDynamicProperties.data.find(
          (value) => value.propertyId === 'PASSED_NAME_ONBOARDING_STEP'
        ),
      });

      const isInviteStepCompleted = parseStateProperty({
        props: meta.data?.getUserDynamicProperties.data.find(
          (value) => value.propertyId === 'PASSED_INVITE_TEAM_ONBOARDING_STEP'
        ),
      });

      dispatch(
        setUser({
          isFirstTime,
          isNameStepCompleted,
          isInviteStepCompleted,
        })
      );
    }
  };

  async function login(res: LoginResultParam) {
    client.resetStore();
    if (res.status?.isSuccess) {
      setAuthToken(res.data?.authToken ?? null);

      await getStateData();

      const result = await getUser();

      const data = result.data?.user;
      const isSuccess = data?.status.isSuccess;

      if (isSuccess) {
        const user = mapGqlUserToDomainUser(data.data.user as gql.User);
        ldClient?.identify({ key: user.actingAsMemberOfOrganization.oid, name: user.name, email: user.email });
        dispatch(
          setLogin({
            isAuthenticated: true,
            user: user ?? undefined,
            authToken: res.data?.authToken ?? '',
            authorized: true,
            isSigninCompleted: true,
          })
        );
        identifyUser(user?.oid ?? '', {
          displayName: user?.name,
          organizationId: user?.actingAsMemberOfOrganization.oid,
          organizationName: user?.actingAsMemberOfOrganization.name,
          email: user.email,
          userId: user.oid,
        });

        window.analytics?.group(user.actingAsMemberOfOrganization.oid, {
          id: user.actingAsMemberOfOrganization.oid,
          name: user.actingAsMemberOfOrganization.name,
          userId: user.oid,
        });
      } else {
        dispatch(setError({ error: 'Could not fetch your personal data', isAuthenticated: false }));
        setAuthToken(null);
      }
    } else {
      dispatch(setError({ error: res.status?.userMessage, isAuthenticated: false }));
      setAuthToken(null);
    }
  }

  const logout = async () => {
    await signOut().then((response) => {
      if (response.data?.signout.status.isSuccess) {
        setAuthToken(null);
        dispatch(setLogout());
        client.resetStore();
        trackAction(AnalyticKeys.LOGGED_OUT, {
          organizationId: user ? user.actingAsMemberOfOrganization.oid : '',
          organizationName: user?.actingAsMemberOfOrganization.name ?? '',
        });
      }
    });
  };

  const fetchUser = async () => {
    const res = await getUser({ fetchPolicy: 'network-only' });
    const user = res.data?.user?.data?.user;

    if (user) {
      user && updateUserState(mapUserToDomain(user as gql.User));
    }

    return res.data?.user as UserResponse | null;
  };

  const updateUserState = (user: User) => {
    dispatch(setUser({ ...user, isFirstTime: false, isNameStepCompleted: true, isInviteStepCompleted: true }));
  };

  const updateEmail = (email: string) => {
    if (email) {
      dispatch(setUser({ ...user, email, isFirstTime: false, isNameStepCompleted: true, isInviteStepCompleted: true }));
    }
    return email;
  };

  const updateUserName = async (name: string) => {
    await updateUser({ variables: { input: { fullName: name } as UpdateUserInput } }).then(async (response) => {
      if (user) {
        updateUserState({ ...user, fullName: name });
      } else {
        await fetchUser();
      }
    });
  };

  const updateUserNameAndImage = async (name: string, profileImageUrl?: string) => {
    await updateUser({
      variables: { input: { fullName: name, profileImageUrl: profileImageUrl } as UpdateUserInput },
    }).then(async (response) => {
      if (response.data?.updateUser.status.isSuccess) {
        await fetchUser();
      }
    });
  };

  return {
    login,
    logout,
    fetchUser,
    updateEmail,
    updateUserName,
    updateUserNameAndImage,
    isFirstTime,
    email,
    isAuthenticated,
    isInitialized,
    isNameStepCompleted,
    isInviteStepCompleted,
    getStateData,
    initialize,
    isSigninCompleted,
  };
};

export default useAuth;
