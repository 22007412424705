import { useDispatch, useSelector } from 'react-redux';
import {
  addEntityView,
  entityViewsState,
  removeEntityView,
  updateEditMode,
  updateEntityView,
} from 'services/store/slices/entityViewsSlice';
import { mapFilterItemsToEntityViewCriteria, mapNavigationItemToDraftEntityView } from 'services/utils/entityViewUtils';
import { DraftEntityView, EntityView } from 'services/models/domain/entityViews';
import { entityFilter } from 'services/store/slices/entityFilterSlice';
import {
  ViewType,
  useCreateEntityViewMutation,
  useDeleteEntityViewMutation,
  useUpdateEntityViewMutation,
  EntityViewField,
} from 'services/models/api/generated';
import { useNavigationSidebar } from 'services/hooks/useNavigationSidebar';
import AnalyticKeys, { trackAction } from 'services/utils/analytics';
import * as Sentry from '@sentry/react';
import { RootState } from 'services/store/AppStore';
import { uniqueId } from 'lodash';

export function useEntityViews() {
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch();
  const { entityViews } = useSelector(entityViewsState);
  const { filterItems } = useSelector(entityFilter);
  const [createEntityViewMutation] = useCreateEntityViewMutation();
  const [updateEntityViewMutation] = useUpdateEntityViewMutation();
  const [deletedEntityViewMutation] = useDeleteEntityViewMutation();
  const { setSelectedNavigationItem, clearSelectedNavigationItem } = useNavigationSidebar();

  const createEntityView = (id: string, title: string, icon: string, useExistingFilters: boolean) => {
    const draftEntityView: DraftEntityView = mapNavigationItemToDraftEntityView(
      { id, title, icon },
      entityViews.propertyDefinitions
    );
    dispatch(addEntityView({ ...draftEntityView, criteria: { filterItems: useExistingFilters ? filterItems : [] } }));
    setSelectedNavigationItem({ id, title, icon });
  };

  const duplicateEntityView = (id: string, view: EntityView) => {
    const { title, icon } = view;
    const newId = `${id}-${uniqueId()}`;

    const draftEntityView: DraftEntityView = mapNavigationItemToDraftEntityView(
      { id: newId, title: view.title, icon: view.icon ?? '' },
      entityViews.propertyDefinitions
    );

    const updatedDraftView: DraftEntityView = {
      ...draftEntityView,
      propertyDefinitions: view.propertyDefinitions,
      visibleEntityFields: view.visibleEntityFields,
    };

    dispatch(
      addEntityView({
        ...updatedDraftView,
        criteria: { filterItems: filterItems },
      })
    );
    setSelectedNavigationItem({ id: newId, title, icon: icon ?? '' });
    return updatedDraftView;
  };

  const updateDraftEntityView = (id: string, title: string, icon: string) => {
    const draftEntityView = entityViews.views.find((view) => 'tempOid' in view && view.tempOid.oid === id);
    if (!!draftEntityView) {
      dispatch(
        updateEntityView({
          oid: { oid: id },
          updatedEntityView: {
            ...draftEntityView,
            title,
            icon,
          },
        })
      );
      setSelectedNavigationItem({ id, title, icon });
    }
  };

  const deleteEntityViewFromState = (oid: string) => {
    dispatch(removeEntityView({ identifier: oid }));
  };

  const publishEntityView = (title: string, icon: string, draftView: DraftEntityView, duplicate: boolean) => {
    const updatedDraft: DraftEntityView = { ...draftView, title, icon };
    publishEntityViewToBackend(updatedDraft, duplicate);
  };

  const publishEntityViewToBackend = async (draftView: DraftEntityView, duplicate: boolean) => {
    const filterCriteria = mapFilterItemsToEntityViewCriteria(filterItems);
    const propertyDefinitionOids = draftView.propertyDefinitions.filter((pd) => pd.visible).map((pd) => pd.oid.oid);
    const newEntityView = {
      title: draftView.title,
      icon: draftView.icon,
      criteria: filterCriteria,
      entityType: draftView.entityType,
      entityViewFields: draftView.visibleEntityFields as EntityViewField[],
      propertyDefinitionOids,
    };
    const tempUpdatePayload = { ...draftView, type: ViewType.Custom, criteria: { filterItems } };
    dispatch(
      updateEntityView({
        oid: draftView.tempOid,
        updatedEntityView: tempUpdatePayload,
      })
    );
    setSelectedNavigationItem({ id: draftView.tempOid.oid, title: draftView.title, icon: draftView.icon });
    await createEntityViewMutation({
      variables: {
        input: {
          ...newEntityView,
          criteria: filterCriteria,
          showAmountEntities: false,
        },
      },
    })
      .then(async (response) => {
        if (response.data?.createEntityView.status.isSuccess) {
          const entityViewOid = response.data?.createEntityView.data.oid;
          trackAction(AnalyticKeys.ADDED_SMART_LIST, {
            organizationId: user?.actingAsMemberOfOrganization.oid,
            organizationName: user?.actingAsMemberOfOrganization.name,
            newOrDuplicate: duplicate ? 'duplicate' : 'new',
            smartListName: draftView.title,
            oid: entityViewOid.oid,
          });
          dispatch(
            updateEntityView({
              oid: entityViewOid,
              updatedEntityView: tempUpdatePayload,
            })
          );
          setSelectedNavigationItem({ id: entityViewOid.oid, title: draftView.title, icon: draftView.icon });
        } else {
          dispatch(removeEntityView({ identifier: draftView.tempOid.oid }));
        }
      })
      .catch((error) => {
        Sentry.setContext('entityViewsContext', {
          oid: draftView.tempOid?.oid,
          title: draftView.title,
          userOid: user?.oid,
        });
        Sentry.captureException(`Error adding smart list: ${error}`);
      });
  };

  const updateEntityViewDetails = async (oid: string, title: string, icon: string) => {
    const filterCriteria = mapFilterItemsToEntityViewCriteria(filterItems);
    const view = getEntityViewByOid(oid) as EntityView;
    dispatch(
      updateEntityView({
        oid: { oid: oid },
        updatedEntityView: { ...view, title, icon, criteria: { filterItems } },
      })
    );
    setSelectedNavigationItem({ id: oid, title, icon });
    await updateEntityViewMutation({
      variables: {
        input: {
          oid: oid,
          title: title,
          icon: icon,
          criteria: filterCriteria,
        },
      },
    })
      .then(async (response) => {
        if (response.data?.updateEntityView.status.isSuccess) {
          dispatch(updateEditMode(false));
          trackAction(AnalyticKeys.EDITED_SMART_LIST, {
            organizationId: user?.actingAsMemberOfOrganization.oid,
            organizationName: user?.actingAsMemberOfOrganization.name,
            smartListName: title,
            oid: oid,
          });
        } else {
          dispatch(updateEntityView({ oid: { oid: oid }, updatedEntityView: view }));
        }
      })
      .catch((error) => {
        Sentry.setContext('entityViewsContext', {
          oid: oid,
          title: title,
          userOid: user?.oid,
        });
        Sentry.captureException(`Error updating smart list: ${error}`);
      });
  };

  const deleteEntityViewFromBackend = async (oid: string, title: string) => {
    dispatch(removeEntityView({ identifier: oid }));
    clearSelectedNavigationItem();
    await deletedEntityViewMutation({
      variables: {
        input: {
          oid: oid,
        },
      },
    })
      .then(async (response) => {
        if (response.data?.deleteEntityView.status.isSuccess) {
          dispatch(removeEntityView({ identifier: oid }));
          clearSelectedNavigationItem();
          trackAction(AnalyticKeys.DELETED_SMART_LIST, {
            organizationId: user?.actingAsMemberOfOrganization.oid,
            organizationName: user?.actingAsMemberOfOrganization.name,
            smartListName: title,
            oid: oid,
          });
        }
      })
      .catch((error) => {
        Sentry.setContext('entityViewsContext', {
          oid: oid,
          title: title,
          userOid: user?.oid,
        });
        Sentry.captureException(`Error deleting smart list: ${error}`);
      });
  };

  const setEditMode = (mode: boolean) => {
    dispatch(updateEditMode(mode));
  };

  const getEntityViewByOid = (oid: string): EntityView | DraftEntityView | undefined => {
    const entityView = entityViews.views.find((ev) => {
      if ('oid' in ev) {
        return ev.oid.oid === oid;
      }
      if ('tempOid' in ev) {
        return ev.tempOid.oid === oid;
      }
      return false;
    });
    return entityView;
  };

  return {
    createEntityView,
    duplicateEntityView,
    updateDraftEntityView,
    deleteEntityViewFromState,
    getEntityViewByOid,
    publishEntityView,
    updateEntityViewDetails,
    deleteEntityViewFromBackend,
    setEditMode,
  };
}
