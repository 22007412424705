import { Button } from '@mui/joy';
import { styled } from '@mui/material';
import { colors } from 'theme/colors';

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'rounded' && prop !== 'buttonSize' && prop !== 'isLoading',
})(({ rounded, buttonSize, isLoading }: { rounded?: boolean; buttonSize?: string; isLoading?: boolean }) => ({
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  borderRadius: rounded ? '100px' : undefined,
  padding: buttonSize === 'xs' ? '9px 8px' : undefined,
  fontSize: buttonSize === 'xs' ? '13px' : undefined,
  lineHeight: buttonSize === 'xs' ? '12px' : undefined,
  height: buttonSize === 'sm' ? '24px' : 'auto',
  minHeight: buttonSize === 'xs' ? '32px' : undefined,
  '&:disabled': !isLoading
    ? {
        color: colors.neutral[40],
        borderColor: 'var(--joy-palette-primary-outlinedBorder)',
        backgroundColor: 'var(--joy-palette-primary-outlinedBorder)',
      }
    : undefined,
}));
