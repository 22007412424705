import { IconsSvg } from 'assets/icons';
import Translations from 'const/translations/en';
import { Badge, NavigationItem } from 'page/dashboard/header/SideNav';
import { TgSyncStatus } from 'page/modal/TgConnectModal/interface';
import { DraftEntityView, EntityView } from 'services/models/domain/entityViews';
import { colors } from 'theme/colors';
import { formatTranslation } from './helpers';

export const iconMapping: { [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>> } = {
  chevron: IconsSvg.ic_chevron_down,
  chats: IconsSvg.ic_chat,
  contacts: IconsSvg.ic_contact,
  star: IconsSvg.ic_star,
  reports: IconsSvg.ic_reports,
  message: IconsSvg.ic_message_smiley,
  telegram: IconsSvg.ic_brand_telegram,
  calendar: IconsSvg.ic_calendar,
  chromeExtension: IconsSvg.ic_chrome,
  inviteTeammates: IconsSvg.ic_invite_teammates,
  network: IconsSvg.ic_network,
  briefcase: IconsSvg.ic_briefcase,
  message_dialog: IconsSvg.ic_message_dialog,
  warning: IconsSvg.ic_warning_triangle,
  clock: IconsSvg.ic_clock_active,
  open_link: IconsSvg.ic_open_link,
  telegram_filled: IconsSvg.ic_telegram,
  confetti: IconsSvg.ic_confetti,
  heartHandShake: IconsSvg.ic_heart_handshake,
  userGroups: IconsSvg.ic_user_groups,
  dollarFile: IconsSvg.ic_dollar_file,
  properties: IconsSvg.ic_list_details,
  search: IconsSvg.ic_search,
};

export const SEARCH_BAR = 'search_commandbar';
export const CHAT_RECORDS = 'chats';
export const CONTACT_RECORDS = 'contacts';
export const REPORT_RECORDS = 'reports';
export const BATCH_MESSAGE = 'batch_message';
export const CHROME_EXTENSION = 'chrome_extension';
export const INVITE_TEAMMATES = 'invite_teammates';
export const CONNECT_TELEGRAM = 'connect_telegram';
export const CREATE_CHAT = 'create_chat';
export const ADD_CUSTOM_VEW = 'add_custom_view';
export const DETAILS_PAGE = 'details_page';

export const entityViewsToNavigationItems = (entityViews: (EntityView | DraftEntityView)[]): NavigationItem[] => {
  const viewItems: NavigationItem[] = entityViews.map((view) => {
    return {
      icon: view.icon || '',
      id: 'oid' in view ? view.oid.oid : view.tempOid.oid,
      title: view.title,
      badge:
        view.amountEntities && view.amountEntities > 0
          ? { color: colors.blue, text: ` ${view.amountEntities.toString()} ` }
          : undefined,
      children: [],
    };
  });
  return viewItems;
};

export const defaultNavigationItems: NavigationItem[] = [
  {
    title: 'Telegram',
    icon: 'telegram',
  },
];

export const GENERAL_ITEMS: NavigationItem[] = [
  {
    id: SEARCH_BAR,
    icon: 'search',
    //when platform depricated use -  window.navigator.userAgentData.platform - currently don't exist on navigator in TS
    title: formatTranslation(
      Translations.NAVIGATION_ITEM_SEARCHBAR,
      window.navigator.platform.includes('Mac')
        ? Translations.SEARCHBAR_MAC_COMMAND
        : Translations.SEARCHBAR_WIN_COMMAND
    ),
  },
];

export const RECORD_ITEMS: NavigationItem[] = [
  {
    icon: 'chats',
    title: Translations.NAVIGATION_ITEM_CHATS,
    id: CHAT_RECORDS,
    isTeamNamePrefixed: true,
  },
  {
    icon: 'network',
    title: Translations.NAVIGATION_ITEM_NETWORK,
    id: CONTACT_RECORDS,
    isTeamNamePrefixed: true,
  },
  {
    id: REPORT_RECORDS,
    title: Translations.NAVIGATION_ITEM_REPORTS,
    icon: 'reports',
    badge: {
      text: Translations.NAVIGATION_ITEM_BADGE_NEW,
      color: colors.primary[50],
    },
  },
];

const mapTelegramStatusToBadge = (tgStatus: string): { tgBadge: Badge | undefined; title: string } => {
  let status;
  switch (tgStatus) {
    case TgSyncStatus.CONNECT: {
      status = {
        tgBadge: { color: 'red', text: Translations.NAVIGATION_ITEM_BADGE_EXCLAMATION },
        title: TgSyncStatus.CONNECT,
      };
      break;
    }
    case TgSyncStatus.DISCONNECTED: {
      status = {
        tgBadge: { color: 'red', text: Translations.NAVIGATION_ITEM_BADGE_EXCLAMATION },
        title: TgSyncStatus.DISCONNECTED,
      };
      break;
    }
    case TgSyncStatus.CONNECTED_NOT_SYNCED: {
      status = {
        tgBadge: { color: colors.blue, text: Translations.NAVIGATION_ITEM_BADGE_TODO },
        title: TgSyncStatus.CONNECTED_NOT_SYNCED,
      };
      break;
    }
    case '': {
      status = { tgBadge: undefined, title: TgSyncStatus.CONNECTED };
      break;
    }
    default: {
      status = { tgBadge: undefined, title: tgStatus };
    }
  }
  return status;
};

export const configItems = (tgStatus: string): NavigationItem[] => {
  const { tgBadge, title } = mapTelegramStatusToBadge(tgStatus);
  return [
    {
      icon: 'telegram_filled',
      title: title,
      id: CONNECT_TELEGRAM,
      badge: tgBadge,
    },
    {
      icon: 'chromeExtension',
      title: Translations.NAVIGATION_ITEM_CHROME_EXTENSION,
      id: CHROME_EXTENSION,
      secondaryIcon: 'open_link',
    },
    {
      icon: 'inviteTeammates',
      title: Translations.NAVIGATION_ITEM_TEAMMATES,
      id: INVITE_TEAMMATES,
    },
  ];
};

export const COMMS_ITEMS: NavigationItem[] = [
  {
    icon: 'message_dialog',
    title: Translations.NAVIGATION_ITEM_BATCH_MSG,
    id: BATCH_MESSAGE,
    secondaryIcon: 'open_link',
  },
];

export const DETAILS_PAGE_ITEM = {
  id: DETAILS_PAGE,
  icon: '',
  title: Translations.NAVIGATION_ITEM_DETAILS_PAGE,
};

export const ADD_NEW_CUSTOM_VIEW = {
  id: ADD_CUSTOM_VEW,
  icon: 'ic_plus',
  title: Translations.NAVIGATION_SIDEBAR_BUTTON_ADD_SMARTLIST,
  badge: {
    text: Translations.NAVIGATION_ITEM_BADGE_NEW,
    color: colors.primary[50],
  },
};
