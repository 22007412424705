import { SvgIcon, Tooltip, Typography } from '@mui/joy';
import Translations from 'const/translations/en';
import { DoubleLineTooltipContents } from 'components/TooltipContents';
import { ReactComponent as TelegramIcon } from 'assets/icons/ic_telegram.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/ic_link.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/ic_check.svg';
import { DetailsIconStyle, TelegramIconStyle } from 'components/Kanban/styles';
import { colors } from 'theme/colors';
import { useCopyLink } from 'services/hooks/useCopyLink';
import { EntityDetailsType } from 'services/store/slices/sidebarSlice';

interface DetailsHeaderDoubleIconsProps {
  telegramLink: string | undefined;
  noLinkPrimaryMessage: string;
  noLinkSecondaryMessage: string;
  copyLinkMessage: string;
  entityOid: string | undefined;
  entityDetailsType: EntityDetailsType;
  disabled?: boolean;
}

export default function DetailsHeaderDoubleIcons({
  telegramLink,
  noLinkPrimaryMessage,
  noLinkSecondaryMessage,
  copyLinkMessage,
  entityOid,
  entityDetailsType,
  disabled,
}: DetailsHeaderDoubleIconsProps) {
  const { linkCopied, copyLink } = useCopyLink();

  return (
    <>
      <Tooltip
        title={
          telegramLink ? (
            Translations.OPEN_TELEGRAM_TOOLTIP
          ) : (
            <DoubleLineTooltipContents primaryText={noLinkPrimaryMessage} secondaryText={noLinkSecondaryMessage} />
          )
        }
        arrow
        placement="top"
      >
        <SvgIcon
          component={TelegramIcon}
          sx={TelegramIconStyle(disabled ? false : !!telegramLink)}
          onClick={
            !disabled
              ? () => {
                  if (telegramLink) {
                    window.open(telegramLink, '_blank');
                  }
                }
              : undefined
          }
          inheritViewBox
        />
      </Tooltip>
      <Tooltip
        title={
          linkCopied ? (
            <Typography level="micro" sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <CheckIcon stroke={colors.neutral[0]} width={12} height={12} />
              {Translations.LINK_COPIED}
            </Typography>
          ) : (
            copyLinkMessage
          )
        }
        arrow
        placement="top"
        enterDelay={400}
        enterTouchDelay={400}
        enterNextDelay={400}
      >
        <SvgIcon
          component={LinkIcon}
          sx={DetailsIconStyle}
          onClick={!disabled ? () => copyLink(entityDetailsType, entityOid) : undefined}
        />
      </Tooltip>
    </>
  );
}
