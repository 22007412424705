import { Typography, Box } from '@mui/joy';
import { useState } from 'react';
import { SxProps } from '@mui/material';
import SideButtonsPair from 'components/SideButtonsPair';

interface TitleProps {
  title: JSX.Element | string;
  leftComponent: {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>;
    hoveredIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>;
    description?: JSX.Element | string;
    stroke?: string;
    css: SxProps | undefined;
    link: string | undefined;
    showFilledIcon: boolean;
  };
  rightComponent: {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>;
    description?: string;
    css: SxProps | undefined;
    link: string | (() => void);
  };
}

export default function TitleCard({ title, leftComponent, rightComponent }: TitleProps) {
  const [showButton, setShowButton] = useState(false);
  const [showFilledIcon, setShowFilledIcon] = useState(false);

  const getIconComponent = () => {
    if (showFilledIcon) {
      return leftComponent.icon;
    }
    return leftComponent.hoveredIcon || leftComponent.icon;
  };

  const renderButtons = () => {
    return <SideButtonsPair leftComponent={leftComponent} rightComponent={rightComponent} />;
  };

  const renderTitle = () => {
    if (typeof title === 'string') {
      return (
        <Typography level="tiny" sx={styles.title}>
          {title}
        </Typography>
      );
    }
    return title;
  };

  return (
    <Box sx={{ ...styles.container }} onMouseOver={() => setShowButton(true)} onMouseLeave={() => setShowButton(false)}>
      <Box sx={styles.conversationContainer}>
        {renderTitle()}
        {showButton && <Box sx={styles.buttonContainer}>{renderButtons()}</Box>}
      </Box>
    </Box>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    position: 'relative',
    width: '100%',
    cursor: 'default',
  },
  title: {
    level: 'tiny',
    color: 'secondary.dark',
    cursor: 'default',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  buttonContainer: {
    background: 'linear-gradient(to right, transparent 5%, white 15%)',
    position: 'absolute',
    right: 0,
    paddingLeft: '30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
  },
  conversationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: '1 1',
    position: 'relative',
    width: '100%',
  },
};
