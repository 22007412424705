import { Sheet } from '@mui/joy';
import { PartyType } from 'services/models/api/generated';
import { Conversation } from 'services/models/domain/conversation';
import { detailsPageInnerContainerStyle } from 'page/details/styles';
import { EntityType } from 'services/models/domain/entityTypes';
import NotesDetailsCard from 'components/Details/NotesDetailsCard';
import FollowupDetailsCard from 'components/Details/FollowupDetailsCard';
import MembersDetialsCard from 'components/Details/MembersDetailsCard';
import PropertiesDetailsCard from 'components/Details/PropertiesDetailsCard';
import FoldersDetailsCard from 'components/Details/FoldersDetailsCard';

interface ChatSidebarBodyProps {
  loading: boolean;
  conversation: Conversation;
  partyScopeFilter: PartyType;
  setPartyScopeFilter: React.Dispatch<React.SetStateAction<PartyType>>;
}

export default function ChatSidebarBody({
  loading,
  conversation,
  partyScopeFilter,
  setPartyScopeFilter,
}: ChatSidebarBodyProps) {
  const followUp = conversation.nextFollowup;
  const notes = conversation?.notes ?? [];
  const folders = conversation?.folders ?? [];

  return (
    <Sheet variant="soft" sx={detailsPageInnerContainerStyle}>
      <FollowupDetailsCard conversation={conversation} followUp={followUp} loading={loading} />

      <PropertiesDetailsCard conversation={conversation} loading={loading} />

      <NotesDetailsCard
        entityOid={conversation?.conversationRecordOid.oid}
        entityType={EntityType.ConversationRecord}
        notes={notes}
        setPartyScopeFilter={setPartyScopeFilter}
        partyScopeFilter={partyScopeFilter}
        loading={loading}
      />

      <MembersDetialsCard participants={conversation?.participants} loading={loading} />
      <FoldersDetailsCard folders={folders} loading={loading} />
    </Sheet>
  );
}
