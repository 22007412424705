import { NoteRecord } from 'services/commandbar/recordTypes';

export const mappingOid = (record: { oid: { oid: string } }): any => ({ ...record, id: record.oid.oid });

export const isJson = (noteText: string) => {
  try {
    return !!JSON.parse(noteText);
  } catch (e) {
    return false;
  }
};

export const parseNote = (input: any) =>
  input.map((note: any) => {
    if (note?.text) return note.text;
    return note?.children ? parseNote(note.children) : false;
  });

export const mappingNoteData = (note: any): NoteRecord => ({
  name: note.entity.name,
  text: isJson(note.text) ? parseNote(JSON.parse(note.text)).join(', ') : note.text,
  entityId: note.entity.oid.oid,
  entityType: note.entityType,
});
