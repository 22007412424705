import { Box, ListDivider, Typography } from '@mui/joy';

interface Props {
  title: string;
  children?: React.ReactNode;
  level?: 'regular' | 'regular+';
}

export default function DetailsCardHeader({ title, children, level }: Props) {
  const typographyLevel = level || 'regular+';
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: 3,
          p: '12px',
          alignContent: 'center',
          alignItems: 'center',
          height: '44px',
        }}
      >
        <Typography level={typographyLevel} sx={{ color: 'text.primary' }}>
          {title}
        </Typography>

        <Box sx={{ flexGrow: 1 }} />
        {children}
      </Box>
      <ListDivider component="div" />
    </>
  );
}
