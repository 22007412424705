import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'services/store/AppStore';
import { Note } from 'services/models/domain/note';
import { ContactDetails } from 'services/models/domain/contact';

export type UpdateContact = { oid: { oid: string } } & { notes: Note[] };

export interface ContactsState {
  contacts: ContactDetails[];
  searchText: string | undefined;
  loading: boolean;
  error: string | null;
  pagesCount: number;
  currentPage: number;
  limitPerPage: number;
  totalNumberOfContacts: number;
}

const initialState: ContactsState = {
  contacts: [],
  searchText: undefined,
  loading: true,
  error: null,
  pagesCount: 0,
  currentPage: 1,
  limitPerPage: 50,
  totalNumberOfContacts: 0,
};

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setContacts: (
      state,
      action: PayloadAction<{ contacts: ContactDetails[]; pagesCount: number; totalNumberOfContacts: number }>
    ) => {
      state.contacts = [...action.payload.contacts.filter((contact) => contact.associatedConversations.length !== 0)];
      state.pagesCount = action.payload.pagesCount;
      state.loading = false;
      state.totalNumberOfContacts = action.payload.totalNumberOfContacts;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
      state.currentPage = 1;
    },
    clearSearchText: (state) => {
      state.searchText = undefined;
      state.currentPage = 1;
    },
    clearContacts: (state) => {
      return { ...initialState };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
      if (action.payload) {
        state.error = null;
        state.contacts = [];
      }
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setLimitPerPage: (state, action: PayloadAction<number>) => {
      state.limitPerPage = action.payload;
      state.currentPage = 1;
    },
    updateContact: (state, action: PayloadAction<UpdateContact>) => {
      const index = state.contacts.findIndex((contact) => contact.oid.oid === action.payload.oid.oid);
      if (index !== -1) {
        state.contacts[index] = {
          ...state.contacts[index],
          notes: [...action.payload.notes],
        };
      }
    },
    updateContacts: (state, action: PayloadAction<ContactDetails>) => {
      const index = state.contacts.findIndex((contact) => contact.oid.oid === action.payload.oid.oid);
      if (index !== -1) {
        state.contacts[index] = {
          ...state.contacts[index],
          notes: [...action.payload.notes],
        };
      } else {
        state.contacts = [...state.contacts, action.payload];
      }
    },
  },
});

export const {
  setContacts,
  clearContacts,
  setLoading,
  setLimitPerPage,
  setPage,
  setError,
  setSearchText,
  updateContacts,
  clearSearchText,
  updateContact,
} = contactsSlice.actions;

export const contactsState = (state: RootState) => state.contacts;
export const contact = (state: RootState, participantOid: string | undefined) =>
  state.contacts.contacts.find((contact) => contact.participantOid.oid === participantOid);
export default contactsSlice.reducer;
