import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContactDetails } from 'services/models/domain/contact';
import { Conversation } from 'services/models/domain/conversation';
import * as domain from 'services/models/domain/entityViews';
import { RootState } from 'services/store/AppStore';
import { EntityDetailsType } from './sidebarSlice';
import { removePropertiesFromConversation } from 'services/utils/conversationUtils';

type EntityDetails = ContactDetails | Conversation;

export type AddPropertyToConversationPayload = {
  conversationOid: string;
  propertyValue: domain.PropertyValue;
};

export type RemovePropertyFromConversationPayload = {
  conversationOid: string;
  propertyValue: domain.PropertyValue;
};

export interface EntityDetailsState {
  entityOid: string | undefined;
  entityType: EntityDetailsType | undefined;
  entity: EntityDetails | undefined;
  loading: boolean;
}

const initialState: EntityDetailsState = {
  entityOid: undefined,
  entityType: undefined,
  entity: undefined,
  loading: true,
};

const sidebarEntityDetailsSlice = createSlice({
  name: 'sidebarEntityDetails',
  initialState,
  reducers: {
    setSidebarEntityOid: (
      state,
      action: PayloadAction<{ oid: string | undefined; type: EntityDetailsType | undefined }>
    ) => {
      if (state.entityOid !== action.payload.oid) {
        state.entity = undefined;
      }
      state.entityOid = action.payload.oid;
      state.entityType = action.payload.type;
    },
    setEntity: (state, action: PayloadAction<EntityDetails | undefined>) => {
      state.entity = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
      if (action.payload) {
        state.entity = undefined;
      }
    },
    addPropertyForConversation: (state, action: PayloadAction<AddPropertyToConversationPayload>) => {
      if (state.entityOid === action.payload.conversationOid) {
        const newProperties = [...(state.entity as Conversation).properties, action.payload.propertyValue];
        (state.entity as Conversation).properties = newProperties;
      }
    },
    removePropertyForConversation: (state, action: PayloadAction<RemovePropertyFromConversationPayload>) => {
      if (state.entityOid === action.payload.conversationOid) {
        const newProperties = removePropertiesFromConversation(
          state.entity as Conversation,
          action.payload.propertyValue
        );
        (state.entity as Conversation).properties = newProperties;
      }
    },
  },
});

export const { setSidebarEntityOid, setEntity, setLoading, addPropertyForConversation, removePropertyForConversation } =
  sidebarEntityDetailsSlice.actions;
export const sidebarEntityDetailsState = (state: RootState) => state.sidebarEntityDetails;
export default sidebarEntityDetailsSlice.reducer;
