import { useCallback, useEffect } from 'react';
import { ApolloClient, InMemoryCache, createHttpLink, ApolloProvider, defaultDataIdFromObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useLocation } from 'react-router-dom';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { TgSyncProvider } from 'contexts/TgSync';
import { getTimeZone } from 'services/utils/helpers';
import Router from 'router/Router';
import ScrollToTop from 'services/utils/scrollToTop';
import MotionLazyContainer from 'components/MotionLazyContainer';
import { OnboardingProvider } from 'contexts/Onboarding';
import { Provider } from 'react-redux';
import store from 'services/store/AppStore';
import { EventBusProvider } from 'contexts/EventBusContext';
import { LocalStorageValues } from 'services/utils/interface';
import { TgConnectModalProvider } from 'contexts/TgConnectModalContext';
import { AppContext, AppContextInitialState } from 'services/store/AppContext';
import { useBeforeUnload } from 'react-router-dom';
import SnackBarWithAction from 'components/SnackBar/SnackBar';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_SERVER_BASE_URL,
});

export const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem(LocalStorageValues.AuthToken);
  return {
    headers: {
      ...headers,
      'api-key': process.env.REACT_APP_API_KEY,
      Authorization: `Bearer ${token ?? ''}`,
      timezone: getTimeZone(),
    },
  };
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${process.env.REACT_APP_SUBSCRIPTION_BASE_URL}`,
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  authLink
);

const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    if (typeof responseObject.oid === 'string') {
      return `${responseObject.__typename}:${responseObject.oid}`;
      // @ts-ignore
    } else if (typeof responseObject.oid === 'object' && typeof responseObject.oid?.oid === 'string') {
      // @ts-ignore
      return `${responseObject.__typename}:${responseObject.oid.oid}`;
    }

    return defaultDataIdFromObject(responseObject);
  },
  typePolicies: {
    Followup: {
      keyFields: ['oid'],
    },
  },
});

const client = new ApolloClient({
  link: splitLink.concat(httpLink),
  cache,
  defaultOptions: {
    mutate: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});

function App() {
  const location = useLocation();

  useBeforeUnload(
    useCallback(() => {
      localStorage.removeItem('GramJs:apiCache');
    }, [])
  );

  useEffect(() => {
    // window.analytics.page(location.pathname);
  }, [location]);

  return (
    <ApolloProvider client={client}>
      <AppContext.Provider value={AppContextInitialState}>
        <Provider store={store}>
          <EventBusProvider>
            <TgSyncProvider>
              <OnboardingProvider>
                <MotionLazyContainer>
                  <TgConnectModalProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <ScrollToTop />
                      <Router />
                      <SnackBarWithAction />
                    </LocalizationProvider>
                  </TgConnectModalProvider>
                </MotionLazyContainer>
              </OnboardingProvider>
            </TgSyncProvider>
          </EventBusProvider>
        </Provider>
      </AppContext.Provider>
    </ApolloProvider>
  );
}

export default App;

declare global {
  interface Window {
    analytics: any;
  }
}
