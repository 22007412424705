import { Typography } from '@mui/joy';
import { PageLinkStyle, textEllipse } from 'components/Chats/ChatTableV2/TableCell/customCells/cellsContent/styles';
import { CSSProperties, useState } from 'react';
import { Link } from 'react-router-dom';
import { NavigationPath } from 'services/hooks/useNavigation';

interface PageLinkProps {
  to: NavigationPath;
  children: JSX.Element | string;
  openInNewTab?: boolean;
  sx?: CSSProperties;
}
export default function PageLink({ to, children, openInNewTab = false, sx }: PageLinkProps) {
  const [hover, setHover] = useState(false);

  return (
    <Link
      to={to}
      style={{ ...PageLinkStyle(hover), ...sx }}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      target={openInNewTab ? '_black' : '_self'}
      replace
    >
      {typeof children === 'string' ? <Typography sx={textEllipse}>{children}</Typography> : children}
    </Link>
  );
}
