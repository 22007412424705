import { Skeleton } from '@mui/material';
import { Box, useTheme } from '@mui/joy';

const NotePlaceholder = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        alignItems: 'flex-start',
        display: 'grid',
        p: '10px 16px',
        gridTemplateColumns: 'minmax(110px, 50px) 30px 1fr',
        cursor: 'pointer',
        borderBottom: '1px dashed #ECF0F3',
      }}
    >
      <Skeleton variant="text" sx={{ fontSize: theme.typography.regular, width: '90%', mr: '10px' }} />
      <Skeleton variant="circular" height={21} width={21} sx={{ marginLeft: 'auto', marginRight: 'auto' }} />
      <Box>
        <Box sx={{ display: 'flex' }}>
          <Skeleton variant="text" sx={{ fontSize: theme.typography.regular, width: '30%', mr: '10px' }} />
          <Skeleton
            variant="rectangular"
            sx={{ width: '15px', height: '15px', alignSelf: 'center', borderRadius: '4px' }}
          />
        </Box>
        <Skeleton variant="text" sx={{ fontSize: '.5rem', width: '80%', mr: '10px' }} />
      </Box>
    </Box>
  );
};

export default NotePlaceholder;
