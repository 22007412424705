import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, IconButton } from '@mui/joy';
import Typography from '@mui/joy/Typography';
import { Stack, SxProps } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import { QRCodeSVG } from 'qrcode.react';

import { useContext, useEffect, useState } from 'react';
import { useBoolean, useCounter, useInterval } from 'react-use';
import Icons from 'assets/icons';
import Translations from 'const/translations/en';
import 'theme/styles/scroll-bar.css';
import { TgConnectSteps, TgLinkContext, useTgLinkContext } from 'services/hooks/useTgLinkAccount';
import UIButton from 'components/ui-library/Button/UIButton';
import FormControl from 'components/ui-library/FormControl/UIFormControl';
import UIInput from 'components/ui-library/Input/UIInput';
import TgFolderSync from 'page/modal/TgConnectModal/TgFolderSync/TgFolderSync';

import {
  CustomDividerStyles,
  FormInputRounded,
  FormInputTextStyles,
  LinkStyles,
  ManualLoginStyles,
  MuiTelInputStyles,
  QrCodeBoxStyles,
  QrCodeInstructionStyles,
  ResendButtonStyles,
  TelInputWrapperStyled,
  TwoFactorStyles,
} from 'page/modal/tg-link/styles';
import { useSelector } from 'react-redux';
import { userTgState } from 'services/store/slices/userTgSlice';
import CircularProgress from '@mui/joy/CircularProgress';

/**
 * Component that displays the Telegram login process, or folder sync settings when the user is already connected to Telegram
 */
export function TelegramLoginOrSyncFolders() {
  const { formStep, needPasswordFor2FA } = useContext(TgLinkContext)!;
  const [showQrCode, setShowQrCode] = useState(true);
  const { isLoadingClient } = useSelector(userTgState);

  const tgFolderSynced = formStep === TgConnectSteps.FOLDER_SYNC;
  if (isLoadingClient) {
    return (
      <Box sx={{ alignItems: 'stretch', alignSelf: 'stretch', marginLeft: '32px', marginRight: '32px' }}>
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress color="neutral" size="sm" />
        </Box>
      </Box>
    );
  }
  if (needPasswordFor2FA) {
    return (
      <Stack gap={1} sx={ManualLoginStyles}>
        <OnboardingModalTelegramEnter2FAPassword />
      </Stack>
    );
  }
  if (tgFolderSynced) {
    return (
      <Box sx={{ alignItems: 'stretch', alignSelf: 'stretch', marginLeft: '32px', marginRight: '32px' }}>
        <TgFolderSync />
      </Box>
    );
  }
  return showQrCode ? (
    <OnboardingModalQRPage switchToPhoneLogin={() => setShowQrCode(false)} />
  ) : (
    <OnboardingModalManualLoginPage switchToQrLogin={() => setShowQrCode(true)} />
  );
}

/**
 * QR code login flow
 */
function OnboardingModalQRPage({ switchToPhoneLogin }: { switchToPhoneLogin: () => void }) {
  const { connectWithQr, qrCode } = useContext(TgLinkContext)!;

  useEffect(() => {
    connectWithQr()
      .then()
      .catch((e) => {});
  }, []);

  return (
    <>
      <Stack gap={2}>
        <Box sx={QrCodeBoxStyles}>
          <div>
            <QRCodeSVG
              width={160}
              height={160}
              value={qrCode || ''}
              imageSettings={{
                src: Icons.ic_telegram_color,
                height: 24,
                width: 24,
                excavate: true,
              }}
            />
          </div>{' '}
          <Typography sx={QrCodeInstructionStyles}>
            {Translations.ONBOARDING_INSTRUCTION_1}
            <br />
            {Translations.ONBOARDING_INSTRUCTION_2}
            <br />
            {Translations.ONBOARDING_INSTRUCTION_3}
          </Typography>
        </Box>
      </Stack>
      <Box sx={CustomDividerStyles}>OR</Box>
      <Stack gap={2}>
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography sx={LinkStyles} onClick={switchToPhoneLogin}>
            {Translations.PHONE_NUMBER_CONNECT}
          </Typography>
        </Box>
      </Stack>
    </>
  );
}

/**
 * Legacy manual login flow
 */
function OnboardingModalManualLoginPage({ switchToQrLogin }: { switchToQrLogin: () => void }) {
  return (
    <>
      <Stack gap={1} sx={ManualLoginStyles}>
        <OnboardingModalTelegramEnterPhoneNumber />
      </Stack>
      <Box sx={CustomDividerStyles}>OR</Box>
      <Box sx={QrCodeBoxStyles}>
        <Typography sx={LinkStyles} onClick={switchToQrLogin}>
          {Translations.QR_CODE_CONNECT}
        </Typography>
      </Box>
    </>
  );
}

function OnboardingModalTelegramEnterPhoneNumber() {
  const { formStep, phoneNumber, setPhoneNumber, otpCode, setOtpCode, sendOTP, connectWithPhoneNumber, isWrongOtp } =
    useTgLinkContext();

  const { countdown, toggleCounterRunning, isCounterRunning } = useResendCounter();
  const isConnectStep = formStep === TgConnectSteps.CONNECT;

  function handleSendOtp() {
    setOtpCode('');
    toggleCounterRunning(true);
    sendOTP().then(() => {
      if (isConnectStep) {
        toggleCounterRunning(false);
      }
    });
  }

  function handleLoginWithPhoneNumber() {
    connectWithPhoneNumber().then(() => {
      console.debug('Connected by phoneNumber successfully');
    });
  }

  function ResendCodeButton({ sx, text }: { sx: SxProps; text: string }) {
    return (
      <UIButton size="sm" sx={sx} onClick={handleSendOtp} disabled={isCounterRunning || phoneNumber.length === 0}>
        {isCounterRunning ? `Resend in ${countdown < 10 ? `0${countdown}` : countdown}` : text}
      </UIButton>
    );
  }

  return (
    <>
      <TelInputWrapperStyled>
        <MuiTelInput
          size="small"
          fullWidth
          sx={MuiTelInputStyles}
          value={phoneNumber}
          onChange={setPhoneNumber}
          disabled={false}
          placeholder={'Phone number'}
          defaultCountry="US"
        />
        {isConnectStep && <ResendCodeButton sx={ResendButtonStyles} text="Resend" />}
      </TelInputWrapperStyled>
      {!isConnectStep && <ResendCodeButton sx={FormInputRounded} text="Send code" />}
      {isConnectStep && (
        <>
          <FormControl size="sm" underText={isWrongOtp} error={!!isWrongOtp}>
            <UIInput
              size="sm"
              placeholder={Translations.TELEGRAM_LOGIN_CODE}
              onChange={(e) => setOtpCode(e.target.value)}
              value={otpCode}
              sx={FormInputTextStyles}
            />
          </FormControl>
          <UIButton size="sm" onClick={() => handleLoginWithPhoneNumber()} loading={false}>
            {Translations.CONNECT}
          </UIButton>
        </>
      )}
    </>
  );
}

function OnboardingModalTelegramEnter2FAPassword() {
  const { hint, password, setPassword, connectWithPasswordTg } = useTgLinkContext();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  function handleConnect() {
    setIsLoading(true);
    connectWithPasswordTg()
      .then(handleResponse)
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handleResponse(error: string | undefined) {
    if (error) {
      setError(error);
    }
  }

  return (
    <Stack gap={1}>
      <FormControl size="sm" underText={error} error={!!error}>
        <UIInput
          size="sm"
          placeholder={Translations.TELEGRAM_2FA_HELPER}
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          disabled={isLoading}
          sx={{ height: '36px' }}
          endDecorator={
            <IconButton
              aria-label="toggle password visibility"
              onMouseDown={() => setShowPassword(true)}
              onMouseUp={() => setShowPassword(false)}
              variant="plain"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          }
        />
      </FormControl>
      {hint && <Typography sx={TwoFactorStyles}>Hint: {hint}</Typography>}
      <Typography sx={TwoFactorStyles}>{Translations.TELEGRAM_2FA_HELPER_MESSAGE}</Typography>
      <UIButton size="sm" onClick={handleConnect} loading={isLoading} disabled={isLoading}>
        {Translations.CONNECT}
      </UIButton>
    </Stack>
  );
}

export function useResendCounter(countdownStart: number = 59, countdownStep: number = 1000) {
  const [countdown, { dec, reset }] = useCounter(countdownStart, countdownStart, 0);
  const [isCounterRunning, toggleCounterRunning] = useBoolean(false);

  useEffect(() => {
    if (countdown === 0) {
      toggleCounterRunning(false);
      reset();
    }
  }, [countdown, toggleCounterRunning]);

  useInterval(dec, isCounterRunning ? countdownStep : null);

  return {
    countdown,
    isCounterRunning,
    toggleCounterRunning,
  };
}
