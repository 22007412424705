import AppLinks from 'const/links';
import { useTgConnectModalControl } from 'contexts/TgConnectModalContext';
import {
  CallbackArgsOpenChatSidebar,
  CallbackArgsOpenContactSidebar,
  CallbackArgsOpenNoteSidebar,
  NoteType,
} from 'services/commandbar/recordTypes';
import { useConversations } from 'services/hooks/useConversations';
import { useSidebar } from 'services/hooks/useSidebar';
import { EntityDetailsType } from 'services/store/slices/sidebarSlice';
import { openOutsideLink } from 'services/utils/helpers';

export enum CallbackTags {
  OpenChatSidebar = 'openChatSidebar',
  OpenContactSidebar = 'openContactSidebar',
  OpenNoteSidebar = 'openNoteSidebar',
  OpenCreateChatModal = 'openCreateChatModal',
  OpenTelegramConnectModal = 'openTelegramConnectModal',
  OpenChromeExtensionLink = 'openChromeExtensionLink',
  OpenTelegramGroupLink = 'openTelegramGroupLink',
  OpenTwitterLink = 'openTwitterLink',
}

export const useCommandbarCallbacks = () => {
  const { openSidebar } = useSidebar();
  const { setCreateChatModalState } = useConversations();
  const { toggleTgConnectModal } = useTgConnectModalControl();

  return () => {
    window.CommandBar.addCallback(CallbackTags.OpenChatSidebar, (args: CallbackArgsOpenChatSidebar, _context) => {
      openSidebar(args.record.id, EntityDetailsType.chatDetails);
    });

    window.CommandBar.addCallback(CallbackTags.OpenContactSidebar, (args: CallbackArgsOpenContactSidebar, _context) => {
      openSidebar(args.record.id, EntityDetailsType.contactDetails);
    });

    window.CommandBar.addCallback(CallbackTags.OpenNoteSidebar, (args: CallbackArgsOpenNoteSidebar, _context) => {
      switch (args.record.entityType) {
        case NoteType.Contact: {
          openSidebar(args.record.entityId, EntityDetailsType.contactDetails);
          break;
        }
        case NoteType.Conversation: {
          openSidebar(args.record.entityId, EntityDetailsType.chatDetails);
          break;
        }
      }
    });

    window.CommandBar.addCallback(CallbackTags.OpenCreateChatModal, (_args, _context) => {
      setCreateChatModalState(true);
    });

    window.CommandBar.addCallback(CallbackTags.OpenTelegramConnectModal, (_args, _context) => {
      toggleTgConnectModal(true);
    });

    window.CommandBar.addCallback(CallbackTags.OpenChromeExtensionLink, (_args, _context) => {
      openOutsideLink(AppLinks.chromeExtensionLink);
    });

    window.CommandBar.addCallback(CallbackTags.OpenTelegramGroupLink, (_args, _context) => {
      openOutsideLink(AppLinks.telegramGroupLink);
    });

    window.CommandBar.addCallback(CallbackTags.OpenTwitterLink, (_args, _context) => {
      openOutsideLink(AppLinks.twitterLink);
    });
  };
};
