import { SelectedPropertyValue } from 'components/PropertiesList';
import { useDispatch, useSelector } from 'react-redux';
import { useSidebar } from 'services/hooks/useSidebar';
import {
  ConversationChannel,
  IConversationPropertiesInput,
  useCreateConversationMutation,
  useEditConversationMutation,
  useSetConversationArchiveStateMutation,
} from 'services/models/api/generated';
import { Conversation, ConversationOrigin, ConversationType } from 'services/models/domain/conversation';
import { EntityPropertyDefinition, OptionPropertyValue } from 'services/models/domain/entityViews';
import { authState } from 'services/store/slices/authSlice';
import {
  addConversations,
  conversationsState,
  removedArchivedConversation,
} from 'services/store/slices/conversationsSlice';
import { detailsState, setDetailsEntity } from 'services/store/slices/detailsSlice';
import { setCreateConversationModalState } from 'services/store/slices/manualConversationSlice';
import { setEntity, sidebarEntityDetailsState } from 'services/store/slices/sidebarEntityDetailsSlice';
import { EntityDetailsType } from 'services/store/slices/sidebarSlice';
import { getChannelFromLink } from 'services/utils/conversationUtils';
import {
  groupSelectedPropertyValuesByPropertyDefinition,
  mapSelectedPropertyValuesToProperties,
} from 'services/utils/propertyUtils';

export const useConversations = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(authState);
  const [createConversationMutation] = useCreateConversationMutation();
  const [editConversationMutation] = useEditConversationMutation();
  const [setConversationArchiveStateMutation] = useSetConversationArchiveStateMutation();
  const { entity } = useSelector(sidebarEntityDetailsState);
  const { detailsEntity } = useSelector(detailsState);
  const { conversations } = useSelector(conversationsState);
  const { openSidebar } = useSidebar();

  const setCreateChatModalState = (openModal: boolean, conversation?: Conversation) => {
    dispatch(setCreateConversationModalState({ openModal, conversation }));
  };

  const setArchiveStateForSelectedConversations = async (selectedOids: string[], archive: boolean) => {
    const selectedConversations = conversations.filter((conversation) =>
      selectedOids.includes(conversation.oid.oid || conversation.conversationRecordOid.oid)
    );

    if (selectedConversations.length === 0) {
      return;
    }

    dispatch(removedArchivedConversation(selectedConversations));

    const response = await setConversationArchiveStateMutation({
      variables: {
        input: {
          conversationRecordOids: selectedConversations.map((conversation) => conversation.conversationRecordOid.oid),
          archived: archive,
        },
      },
    });

    if (response && !response.data?.setConversationArchiveState.isSuccess) {
      dispatch(
        addConversations({
          conversations: [...selectedConversations.map((conversation) => ({ ...conversation, isArchived: false }))],
          newConversationCount: selectedConversations.length,
        })
      );
    }
  };

  const setConversationArchiveState = async (conversation: Conversation, archived: boolean) => {
    const response = await setConversationArchiveStateMutation({
      variables: {
        input: {
          conversationRecordOids: [conversation.conversationRecordOid.oid],
          archived,
        },
      },
    });
    if (response && response.data?.setConversationArchiveState.isSuccess) {
      if (archived) {
        dispatch(removedArchivedConversation([conversation]));
      } else {
        dispatch(
          addConversations({ conversations: [{ ...conversation, isArchived: false }], newConversationCount: 1 })
        );
      }
    }
  };

  const setChannelFromLink = (propertyDefinition: EntityPropertyDefinition, link?: string) => {
    if (!link) {
      return;
    }
    if (!propertyDefinition) return;

    const propertyValues = propertyDefinition.values as OptionPropertyValue[];
    const channelType = getChannelFromLink(link);
    if (channelType) {
      const valueOid = propertyValues.find((pv) => pv.value === channelType)?.oid.oid ?? '';
      return valueOid ? { oid: valueOid, propertyDefinitionOid: propertyDefinition.oid.oid } : undefined;
    }
  };

  const editConversation = async (
    conversation: Conversation,
    origin: ConversationOrigin,
    title: string,
    properties: SelectedPropertyValue[],
    propertyDefinition: EntityPropertyDefinition,
    link?: string
  ) => {
    const mappedProperties = groupSelectedPropertyValuesByPropertyDefinition(properties);
    const propertiesInput: IConversationPropertiesInput[] = Object.keys(mappedProperties).map(
      (propertyDefinitionOid) => {
        return {
          propertyDefinitionOid,
          propertyValueOids: mappedProperties[propertyDefinitionOid],
        };
      }
    );
    const response = await editConversationMutation({
      variables: {
        input: {
          title: title,
          origin: origin,
          conversationOid: conversation.oid.oid,
          url: link,
          metaData: {
            properties: propertiesInput,
          },
        },
      },
    });
    if (response && response.data?.editConversation.isSuccess) {
      const updatedProperties =
        properties.length !== 0 ? mapSelectedPropertyValuesToProperties(properties, propertyDefinition) : [];
      const updatedConversation = { ...conversation, name: title, chatLink: link || '', properties: updatedProperties };
      dispatch(addConversations({ conversations: [updatedConversation] }));
      if (detailsEntity?.oid.oid === conversation.oid.oid) {
        dispatch(setDetailsEntity(updatedConversation));
      }
      if (entity?.oid.oid === conversation.oid.oid) {
        dispatch(setEntity(updatedConversation));
      }
    } else {
      throw new Error(`Error editing conversation`);
    }
  };

  const createConversation = async (
    title: string,
    properties: SelectedPropertyValue[],
    propertyDefinition: EntityPropertyDefinition,
    link?: string
  ) => {
    const mappedProperties = groupSelectedPropertyValuesByPropertyDefinition(properties);
    const propertiesInput: IConversationPropertiesInput[] = Object.keys(mappedProperties).map(
      (propertyDefinitionOid) => {
        return {
          propertyDefinitionOid,
          propertyValueOids: mappedProperties[propertyDefinitionOid],
        };
      }
    );

    const response = await createConversationMutation({
      variables: {
        input: {
          title: title,
          channelProviderType: ConversationChannel.Manual,
          origin: ConversationOrigin.Manual,
          metaData: {
            properties: propertiesInput,
            url: link,
          },
        },
      },
    });
    if (response && response.data?.createConversation?.data) {
      const { conversationOid, conversationRecordOid } = response.data?.createConversation?.data;
      if (user) {
        const newConversation: Conversation = {
          conversationRecordOid: conversationRecordOid,
          oid: conversationOid,
          createdAt: {
            utcTimeStampAsString: new Date().toISOString(),
          },
          conversationType: ConversationType.Group, // TODO (AWE): Check
          description: '',
          favorit: false,
          folder: [],
          folders: [],
          followups: [],
          name: title,
          notes: [],
          participants: [],
          properties:
            properties.length !== 0 ? mapSelectedPropertyValuesToProperties(properties, propertyDefinition) : [],
          userIsParticipant: true,
          // @ts-ignore: TODO (AWE): Improve model
          nextFollowup: undefined,
          // @ts-ignore: TODO (AWE): Improve model
          internalConversationId: undefined,
          // @ts-ignore: TODO (AWE): Improve model
          lastMessageSenderParticipantOid: undefined,
          // @ts-ignore: TODO (AWE): Improve model
          lastMessageAt: undefined,
          // @ts-ignore: TODO (AWE): Improve model
          chatLink: '',
          lastModifiedBy: { oid: user.oid },
        };

        dispatch(addConversations({ conversations: [newConversation], newConversationCount: 1 }));
        openSidebar(newConversation.oid.oid, EntityDetailsType.chatDetails);
      }
    } else {
      throw new Error(`Error creating new conversation`);
    }
  };

  return {
    createConversation,
    setChannelFromLink,
    setConversationArchiveState,
    editConversation,
    setCreateChatModalState,
    setArchiveStateForSelectedConversations,
  };
};
