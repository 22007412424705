import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'services/store/AppStore';
import { Folder } from 'services/models/domain/folder';

export interface FoldersState {
  folders: Folder[];
}

const initialState: FoldersState = {
  folders: [],
};

const foldersSlice = createSlice({
  name: 'foldersSlice',
  initialState,
  reducers: {
    setFolders: (state, action: PayloadAction<Folder[]>) => {
      state.folders = [...action.payload];
    },
  },
});

export const { setFolders } = foldersSlice.actions;

export const foldersState = (state: RootState) => state.folders;

export const foldersReducer = foldersSlice.reducer;
