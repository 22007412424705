import { GridCellProps, GridCell } from '@mui/x-data-grid-pro';
import ContactCellContent from 'components/TableCells/ContactCell/contactCellContent';

export default function ContactCell(props: GridCellProps) {
  const { value, ...rest } = props;

  return (
    <GridCell {...rest}>
      <ContactCellContent
        oid={value.oid}
        name={value.name}
        link={value.link}
        handle={value.handle}
        avatar={value.avatar}
        onClick={value.onClick}
      />
    </GridCell>
  );
}
