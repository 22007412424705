import { Box, CircularProgress, Divider, List, Stack, Typography } from '@mui/joy';
import TgFolderItem from 'page/modal/TgConnectModal/TgFolderSync/TgFolderItem/TgFolderItem';
import Icons from 'assets/icons';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userTgState } from 'services/store/slices/userTgSlice';
import { colors } from 'theme/colors';
import AppButton from 'components/Buttons/Button';

import { useFlags } from 'launchdarkly-react-client-sdk';
import Translations from 'const/translations/en';
import {
  listItemEmpty,
  NoFoldersInstructionBoxStyles,
  NoFoldersInstructionTextStyle,
} from 'page/modal/TgConnectModal/TgFolderSync/TgFolderItem/styles';
import { Folder } from 'services/models/domain/folder';
import { ReactComponent as LoaderIcon } from 'assets/icons/clean-svg/ic_loader.svg';
import { useTgConnectModalControl } from 'contexts/TgConnectModalContext';
import { useTelegramSync } from 'services/hooks/useTelegramSync';
import { TgSyncStatus } from 'page/modal/TgConnectModal/interface';

const TgFolderSync = () => {
  const {
    isOnDemandSyncActive,
    isOnClientSyncActive,
    folders,
    initialFolders,
    status,
    isLoadingClient,
    telegramFolders,
  } = useSelector(userTgState);
  const flags = useFlags();
  const { toggleTgConnectModal } = useTgConnectModalControl();
  const { setFolderShouldSync, syncFolders, hasUnsyncedChanges } = useTelegramSync();
  const [isSyncRunning, setIsSyncRunning] = useState(isOnDemandSyncActive || isOnClientSyncActive);
  const [hasChanges, setHasChanges] = useState(hasUnsyncedChanges());
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setHasChanges(hasUnsyncedChanges());
    if (flags.clientSync) {
      setIsSyncRunning(isOnClientSyncActive);
    } else {
      setIsSyncRunning(isOnDemandSyncActive);
    }
    const foldersLoaded = !telegramFolders || telegramFolders.length !== initialFolders.length;
    setIsLoading((isLoadingClient && status === TgSyncStatus.CONNECTED) || foldersLoaded);
  }, [
    hasUnsyncedChanges(),
    isOnDemandSyncActive,
    isOnClientSyncActive,
    isLoadingClient,
    status,
    telegramFolders,
    initialFolders,
  ]);

  const onSyncButtonClicked = async () => {
    if (flags.clientSync) {
      if (isOnClientSyncActive) {
        return;
      }
      syncFolders();
      toggleTgConnectModal(false);
    }
  };

  const updateFolderState = (folder: Folder) => {
    setFolderShouldSync(folder);
  };

  if (isLoading) {
    return (
      <Box sx={{ textAlign: 'center' }}>
        <CircularProgress color="neutral" size="sm" />
      </Box>
    );
  }

  return (
    <>
      <List>
        {folders.length > 0 ? (
          folders.map((folder) => (
            <TgFolderItem
              key={folder.oid.oid}
              folder={folder}
              updateFolder={updateFolderState}
              active={!isOnDemandSyncActive}
              initialSyncState={initialFolders.find((f) => f.oid.oid === folder.oid.oid)?.synced ?? false}
            />
          ))
        ) : (
          <Stack spacing={2} sx={listItemEmpty}>
            <img src={Icons.ic_no_folder} alt="" />

            <Typography level="tiny" sx={{ color: '#797F87' }}>
              {Translations.NO_TELEGRAM_FOLDERS}
            </Typography>

            <Box sx={NoFoldersInstructionBoxStyles}>
              <Typography sx={NoFoldersInstructionTextStyle}>{Translations.NO_TELEGRAM_FOLDERS_INSTRUCTION}</Typography>
            </Box>
          </Stack>
        )}
      </List>

      {(hasChanges || isSyncRunning) && (
        <>
          <Divider sx={{ backgroundColor: 'var(--joy-palette-info-outlinedBorder)' }} />
          <Box sx={{ p: 1.5 }}>
            <AppButton
              fullWidth
              disabled={isSyncRunning}
              onClick={() => onSyncButtonClicked()}
              sx={{
                minHeight: '32px',
                backgroundColor: `${!isSyncRunning ? colors.neutral[100] : colors.neutral[15]} !important`,
                color: `${!isSyncRunning ? colors.neutral[0] : colors.neutral[40]} !important`,
                borderColor: `${!isSyncRunning ? colors.neutral[100] : colors.neutral[15]} !important`,
              }}
            >
              {!flags.clientSync && (isSyncRunning ? `${Translations.SYNCING}` : `${Translations.CONFIRM_SYNC}`)}
              {isSyncRunning && (
                <LoaderIcon stroke={colors.neutral[50]} height={'15px'} width={'15px'} style={{ marginRight: '8px' }} />
              )}
              {flags.clientSync &&
                (isSyncRunning ? `${Translations.SYNCING}` : `${Translations.CONFIRM_SYNC_CLIENT_SIDE}`)}
            </AppButton>
          </Box>
        </>
      )}
    </>
  );
};

export default TgFolderSync;
