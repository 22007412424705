import { MouseEvent, useState } from 'react';

export function useMenuState() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleCellClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return {
    open,
    anchorEl,
    handleCellClick,
    handleMenuClose,
    setAnchorEl,
  };
}
