import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'services/store/AppStore';
import { Note } from 'services/models/api/generated';
import * as domain from 'services/models/domain/entityViews';
import { removePropertiesFromConversation, updateConversations } from 'services/utils/conversationUtils';
import _ from 'lodash';
import { Conversation } from 'services/models/domain/conversation';
import { Followup } from 'services/models/domain/followUps';

export interface ConversationsDetailsPayload {
  conversations: Conversation[];
  totalConversations: number;
  pagesCount: number;
  currentPage?: number;
}

export type AddPropertyToConversationPayload = {
  conversationOid: string;
  propertyValue: domain.PropertyValue;
};

export type RemovePropertyFromConversationPayload = {
  conversationOid: string;
  propertyValue: domain.PropertyValue;
};

export type ClearPropertyForConversationPayload = {
  conversationOid: string;
  propertyDefinitionOid: string;
};

export type UpdatePropertiesPayload = {
  propertyValues: domain.PropertyValue[];
};

export type AddConversationsPayload = {
  conversations: Conversation[];
  newConversationCount?: number;
};

export type UpdateConversationPayload = { oid: { oid: string } } & (
  | { followups: Followup[]; nextFollowup: Followup }
  | { nextFollowup?: Followup }
  | { notes: Note[] }
  | { favorite: boolean }
);

export enum SortConversationsByPropertyType {
  LastFollowupDate = 'LAST_FOLLOWUP_DATE',
  LastMessageDate = 'LAST_MESSAGE_DATE',
  DateAdded = 'DATE_ADDED',
}

export type SortConversationsPayload = {
  sortOnProperty?: SortConversationsByPropertyType;
};

export interface ConversationsState {
  conversations: Conversation[] | [];
  loading: boolean;
  error: string | null;
  totalConversations: number;
  pagesCount: number;
  currentPage: number | undefined;
  limitPerPage: number;
  selectedConversations: Conversation[];
  propertiesSelectorOpen?: boolean;
}

const initialState: ConversationsState = {
  conversations: [],
  loading: true,
  error: null,
  totalConversations: 0,
  pagesCount: 0,
  currentPage: undefined,
  limitPerPage: 50,
  selectedConversations: [],
  propertiesSelectorOpen: false,
};

const conversationsSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
      if (action.payload) {
        state.error = null;
        state.conversations = [];
      }
    },
    setConversationsDetails: (state, action: PayloadAction<ConversationsDetailsPayload>) => {
      state.conversations = action.payload.conversations;
      state.totalConversations = action.payload.totalConversations;
      state.pagesCount = action.payload.pagesCount;
      state.currentPage = action.payload.currentPage;
      state.loading = false;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setLimitPerPage: (state, action: PayloadAction<number>) => {
      state.limitPerPage = action.payload;
      state.currentPage = 1;
    },
    updateConversation: (state, action: PayloadAction<UpdateConversationPayload>) => {
      const index = state.conversations.findIndex(
        (conversation) =>
          conversation.oid.oid === action.payload.oid.oid ||
          conversation.conversationRecordOid.oid === action.payload.oid.oid
      );
      if (index !== -1) {
        if ('followups' in action.payload) {
          state.conversations[index] = {
            ...state.conversations[index],
            followups: action.payload.followups,
            nextFollowup: action.payload.nextFollowup,
          };
        }
        if ('nextFollowup' in action.payload) {
          state.conversations[index] = {
            ...state.conversations[index],
            // @ts-ignore
            nextFollowup: action.payload.nextFollowup,
          };
        }
        if ('notes' in action.payload) {
          state.conversations[index] = {
            ...state.conversations[index],
            notes: action.payload.notes,
          };
        }
        if ('favorite' in action.payload) {
          state.conversations[index] = {
            ...state.conversations[index],
            favorit: action.payload.favorite,
          };
        }
      }
    },
    addConversations: (state, action: PayloadAction<AddConversationsPayload>) => {
      state.conversations = updateConversations(state.conversations, action.payload.conversations);
      state.totalConversations = action.payload.newConversationCount
        ? state.totalConversations + action.payload.newConversationCount
        : state.totalConversations;
    },
    updateConversationProperties: (state, action: PayloadAction<UpdatePropertiesPayload>) => {
      const propertiesMap = new Map<string, domain.PropertyValue>();
      action.payload.propertyValues.forEach((propertyValue) => {
        if (propertyValue && propertyValue.type === 'OPTION') {
          propertiesMap.set(propertyValue.oid.oid, propertyValue);
        }
      });
      state.conversations = state.conversations.map((conversation, index) => {
        // @ts-ignore
        const properties: PropertyValue[] = conversation.properties.map((prop) => {
          if (prop && prop.type === 'OPTION') {
            const updatedProperty = propertiesMap.get(`${prop.oid.oid}`);
            if (updatedProperty) {
              return updatedProperty;
            }
          }
          return prop;
        });
        return {
          ...conversation,
          properties: properties,
        };
      });
    },
    addPropertyForConversation: (state, action: PayloadAction<AddPropertyToConversationPayload>) => {
      const index = state.conversations.findIndex((c) => c.oid.oid === action.payload.conversationOid);
      if (index >= 0) {
        const newProperties = [...state.conversations[index].properties, action.payload.propertyValue];
        state.conversations[index] = {
          ...state.conversations[index],
          properties: newProperties,
        };
      }
    },
    removePropertyForConversation: (state, action: PayloadAction<RemovePropertyFromConversationPayload>) => {
      const index = state.conversations.findIndex((c) => c.oid.oid === action.payload.conversationOid);
      if (index >= 0) {
        const newProperties = removePropertiesFromConversation(
          state.conversations[index],
          action.payload.propertyValue
        );
        state.conversations[index] = {
          ...state.conversations[index],
          properties: newProperties,
        };
      }
    },
    clearPropertyForConversation: (state, action: PayloadAction<ClearPropertyForConversationPayload>) => {
      const index = state.conversations.findIndex((c) => c.oid.oid === action.payload.conversationOid);
      if (index >= 0) {
        const newProperties = state.conversations[index].properties.filter((p) => {
          if (!p) {
            return false;
          }
          if (p.type === 'OPTION') {
            return p.propertyDefinitionOid.oid !== action.payload.propertyDefinitionOid;
          }
          if (p.type === 'TENANT_USER') {
            return p.propertyDefinitionOid.oid !== action.payload.propertyDefinitionOid;
          }
          return true;
        });
        state.conversations[index] = {
          ...state.conversations[index],
          properties: newProperties,
        };
      }
    },
    sortConversations: (state, action: PayloadAction<SortConversationsPayload>) => {
      if (action.payload.sortOnProperty) {
        const sortedConversations = _.sortBy(state.conversations, (p) => {
          if (p.nextFollowup) {
            return new Date(p.nextFollowup?.dueAt.utcTimeStampAsString);
          }
          return new Date(0);
        }).reverse();
        state.conversations = sortedConversations;
      }
    },
    removedArchivedConversation: (state, action: PayloadAction<Conversation[]>) => {
      state.conversations = state.conversations.filter((conversation) => {
        return !action.payload.some((archivedConversation) => archivedConversation.oid.oid === conversation.oid.oid);
      });
      state.totalConversations = state.totalConversations - action.payload.length;
    },
    setPropertiesSelectorOpen: (state, action: PayloadAction<boolean>) => {
      state.propertiesSelectorOpen = action.payload;
    },
  },
});

export const {
  setLoading,
  setConversationsDetails,
  setError,
  setPage,
  updateConversation,
  setLimitPerPage,
  addPropertyForConversation,
  removePropertyForConversation,
  clearPropertyForConversation,
  updateConversationProperties,
  addConversations,
  sortConversations,
  removedArchivedConversation,
  setPropertiesSelectorOpen,
} = conversationsSlice.actions;

export const conversationsState = (state: RootState) => state.conversationsDetails;

export default conversationsSlice.reducer;
