import { useContext } from 'react';
import { OnboardingContext } from 'contexts/Onboarding';

function useOnboardingContext() {
  const context = useContext(OnboardingContext);

  if (!context) throw new Error('Onboarding context must be use inside Onboarding Provider');

  return context;
}

export default useOnboardingContext;
