import React, { createContext, useContext, useCallback, useReducer, useEffect, useRef } from 'react';
import * as events from '@3rm-co/event-bus';
import { SubscriptionMessage, SubscriptionResponseMessage, UpdatedUserMessage } from '@3rm-co/event-bus';
import { SyncUserStatusMessage } from '@3rm-co/event-bus';
// import { useSelector } from 'react-redux';
// import { authState } from 'services/store/slices/authSlice';
// import { RECONNECT_TIMEOUT_MS, useTelegramClient } from 'services/hooks/useTelegramClient';
// import * as tgSync from 'services/store/slices/userTgSlice';
// import { useStringSession } from 'services/hooks/useStringSession';
// import { useDispatch } from 'react-redux';
// import { TgSyncStatus } from 'page/modal/TgConnectModal/interface';

export type ValidMessageToSend = SubscriptionMessage | events.AuthTokenMessage;
// 8 minutes
const KEEP_ALIVE_INTERVAL_MS = 8 * 60 * 1000;
interface EventBusContextValue {
  eventMessages: events.Event<ConsumableEvents>[] | null;
  sendMessage: (message: events.Event<ValidMessageToSend>) => void;
  sendAcquireTelegramSessionMessage: () => void;
  sendLockTelegramSessionMessage: () => void;
  onEventReceived: (callback: (message: ConsumableEvents) => void) => void;
  isConnected: boolean;
}

interface EventBusProviderProps {
  children: React.ReactNode;
}

const EventBusContext = createContext<EventBusContextValue>({
  eventMessages: null,
  sendMessage: () => {},
  sendAcquireTelegramSessionMessage: () => {},
  sendLockTelegramSessionMessage: () => {},
  onEventReceived: () => {},
  isConnected: false,
});

export type ConsumableEvents =
  | events.Event<SubscriptionResponseMessage>
  | events.Event<UpdatedUserMessage>
  | events.Event<SyncUserStatusMessage>
  | events.Event<events.AcquireTelegramSessionResponseMessage>;

export const useEventBusContext = () => useContext(EventBusContext);
export default EventBusContext;

const initialState = {
  eventMessages: [],
  webSocketEventBus: null,
  eventCallbacks: [],
  isConnected: false,
};

const enum HANDLERS {
  SET_WEBSOCKET_EVENT_BUS = 'SET_WEBSOCKET_EVENT_BUS',
  SET_IS_CONNECTED = 'SET_IS_CONNECTED',
  SET_EVENT_CALLBACKS = 'SET_EVENT_CALLBACKS',
}

const reducer = (state: any, action: { type: any; payload: any }) => {
  switch (action.type) {
    case 'SET_WEBSOCKET_EVENT_BUS':
      return { ...state, webSocketEventBus: action.payload };
    case 'SET_IS_CONNECTED':
      return { ...state, isConnected: action.payload };
    case 'SET_EVENT_CALLBACKS':
      return { ...state, eventCallbacks: action.payload };
    default:
      return state;
  }
};

export const EventBusProvider: React.FC<EventBusProviderProps> = ({ children }) => {
  // const { reestablishConnection } = useTelegramClient();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { eventMessages, webSocketEventBus, isConnected } = state;
  const eventCallbacksRef = useRef<((message: ConsumableEvents) => void)[]>([]);
  // const { user } = useSelector(authState);
  // const { setStringSession } = useStringSession();
  // const dispatchRedux = useDispatch();

  // const handleAcquireTelegramSessionResponse = useCallback(
  //   (event: events.Event<events.AcquireTelegramSessionResponseMessage>) => {
  //     if (event.message.error === 'Ignore') {
  //       dispatchRedux(tgSync.setLoadingClient(false));
  //       dispatchRedux(tgSync.setUserStatus(TgSyncStatus.CONNECTED));
  //       return;
  //     }
  //     const { session, lastSyncedAt } = event.message.data ?? {
  //       session: null,
  //       lastSyncedAt: null,
  //     };
  //     setStringSession(session ?? null);
  //     lastSyncedAt && dispatchRedux(tgSync.setLastSyncedAt(new Date(lastSyncedAt).getTime()));
  //     reestablishConnection(RECONNECT_TIMEOUT_MS);
  //   },
  //   []
  // );

  // useEffect(() => {
  //   // send initial message
  //   if (isConnected && user?.oid) {
  //     sendAcquireTelegramSessionMessage();
  //     // sendSubscriptionMessage(user.oid);
  //     onEventReceived((event: any) => {
  //       if (
  //         validateEvent<events.AcquireTelegramSessionResponseMessage>(event) &&
  //         event.message.action === events.ACTIONS.telegram.ACQUIRE_SESSION_RESPONSE
  //       ) {
  //         handleAcquireTelegramSessionResponse(event);
  //       }
  //     });
  //   }
  // }, [isConnected, user]);
  useEffect(() => {
    const initWebSocketConnection = () => {
      const webSocketEventBus = new events.WebsocketEventBus({ url: process.env.REACT_APP_WEB_SOCKET_URL as string });
      dispatch({ type: HANDLERS.SET_WEBSOCKET_EVENT_BUS, payload: webSocketEventBus });
      return webSocketEventBus;
    };

    const wsEventBus = initWebSocketConnection();

    const updateIsConnected = () => {
      dispatch({ type: HANDLERS.SET_IS_CONNECTED, payload: wsEventBus['websocket']?.readyState === WebSocket.OPEN });
    };

    wsEventBus['websocket'].addEventListener('open', updateIsConnected);

    wsEventBus.consumeEvents(async (event: events.Event<events.SubscriptionResponseMessage>) => {
      eventCallbacksRef.current.forEach((callback) => callback(event));
    });

    // send keep alive every 8 minutes
    const keepAliveEvent = {
      message: {
        action: events.ACTIONS.common.KEEP_ALIVE,
      },
      metadata: {},
    };

    const intervalId = setInterval(() => {
      if (wsEventBus['websocket']?.readyState === WebSocket.OPEN) {
        wsEventBus.sendEvent(keepAliveEvent);
      }
    }, KEEP_ALIVE_INTERVAL_MS);

    return () => {
      wsEventBus['websocket'].removeEventListener('open', updateIsConnected);
      clearInterval(intervalId);
    };
  }, []);

  const sendMessage = useCallback(
    (message: events.Event<ValidMessageToSend>) => {
      if (webSocketEventBus) {
        webSocketEventBus.sendEvent(message);
      }
    },
    [webSocketEventBus]
  );

  const sendAcquireTelegramSessionMessage = useCallback(() => {
    // send message for telegram token
    const telegramTokenRequestMessage: events.Event<events.AuthTokenMessage> = {
      message: {
        action: events.ACTIONS.telegram.ACQUIRE_SESSION_REQUEST,
        authToken: localStorage.getItem('authToken') ?? '',
      },
      metadata: {},
    };
    sendMessage(telegramTokenRequestMessage);
  }, [sendMessage]);

  const sendLockTelegramSessionMessage = useCallback(() => {
    const lockTelegramSessionMessage: events.Event<events.AuthTokenMessage> = {
      message: {
        action: events.ACTIONS.telegram.LOCK_SESSION_REQUEST,
        authToken: localStorage.getItem('authToken') ?? '',
      },
      metadata: {},
    };
    sendMessage(lockTelegramSessionMessage);
  }, [sendMessage]);

  // const sendSubscriptionMessage = useCallback(
  //   (userOid: string) => {
  //     // subscribe
  //     const subscribeMessage: events.Event<SubscriptionMessage> = {
  //       metadata: {},
  //       message: {
  //         updateTypes: [events.UpdateType.USER_UPDATES, events.UpdateType.SYNC_USER_STATUS_UPDATES],
  //         userOid: { oid: userOid },
  //         authToken: localStorage.getItem('authToken') ?? '',
  //       },
  //     };
  //     sendMessage(subscribeMessage);
  //   },
  //   [sendMessage, user]
  // );

  const onEventReceived = useCallback((newcallback: (message: ConsumableEvents) => void) => {
    eventCallbacksRef.current = [...eventCallbacksRef.current, newcallback];
  }, []);

  return (
    <EventBusContext.Provider
      value={{
        eventMessages,
        sendMessage,
        onEventReceived,
        isConnected,
        sendAcquireTelegramSessionMessage,
        sendLockTelegramSessionMessage,
      }}
    >
      {children}
    </EventBusContext.Provider>
  );
};
