import { UITableProps } from 'components/ui-library/Table/interface';
import { StyledDataGrid } from 'components/ui-library/Table/styles';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useEffect, useLayoutEffect, useState } from 'react';
import useResponsive from 'services/hooks/useResponsive';
import { STICKY_COLUMNS } from 'components/Chats/ChatTableV2/constants';

const initialTableState = {
  pinnedColumns: {
    left: STICKY_COLUMNS.map((column) => column.field),
  },
};

type ScrollProps = {
  onUnmount?: (y: number) => void;
  offsetY?: number;
};

const UITable = (props: UITableProps & ScrollProps) => {
  const largerThanStickyColumnBreakpoint = useResponsive('up', 'stickyColumnBreakpoint');
  const apiRef = useGridApiRef();
  const [showCheckBoxes, setShowCheckBoxes] = useState(true);

  useEffect(() => {
    if (location.pathname.includes('contact')) {
      setShowCheckBoxes(false);
    } else {
      setShowCheckBoxes(true);
    }
  }, [location.pathname]);

  let currentY: number | undefined = undefined;

  useEffect(() => {
    if (apiRef.current) {
      apiRef.current.subscribeEvent('scrollPositionChange', (e) => {
        if (e.top === 0 && currentY === undefined) {
          apiRef.current.scroll({ top: props.offsetY });
        }
        if (props.onUnmount && e.top !== currentY) {
          currentY = e.top;
        }
      });
    }
  }, [apiRef, apiRef.current]);

  useLayoutEffect(() => {
    return () => {
      const scrollPosition = apiRef?.current?.getScrollPosition();
      if (props.onUnmount && scrollPosition) {
        props.onUnmount(scrollPosition.top);
      }
    };
  }, []);

  return (
    <StyledDataGrid
      {...props}
      checkboxSelection={showCheckBoxes}
      disableVirtualization
      disableRowSelectionOnClick
      disableDensitySelector
      disableColumnSelector
      disableColumnReorder
      showCellVerticalBorder
      showColumnVerticalBorder
      columnHeaderHeight={40}
      disableColumnMenu
      apiRef={apiRef}
      initialState={largerThanStickyColumnBreakpoint ? initialTableState : {}}
    />
  );
};

export default UITable;
