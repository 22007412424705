import Icons from 'assets/icons';
import NoData from 'components/NoData';
import ChatSidebarBody from 'components/Sidebar/ChatSidebarBody';
import ChatSidebarHeader from 'components/Sidebar/ChatSidebarHeader';
import Translations from 'const/translations/en';
import { useEffect, useState } from 'react';
import { useFetchSidebarEntityDetails } from 'services/hooks/useFetchSideBarEntityDetails';
import * as Domain from 'services/models/domain/conversation';
import { PartyType } from 'services/models/domain/entityFilter';

export default function SidebarChatDetails() {
  const [partyScopeFilter, setPartyScopeFilter] = useState<PartyType>(PartyType.Organization);

  const { entity, loading } = useFetchSidebarEntityDetails(partyScopeFilter);
  const [conversation, setConversation] = useState<Domain.Conversation>();
  useEffect(() => {
    if (entity) {
      setConversation(entity as Domain.Conversation);
    }
  }, [entity]);

  return (
    <>
      <ChatSidebarHeader conversation={conversation} loading={loading} />
      {conversation && (
        <ChatSidebarBody
          loading={loading}
          conversation={conversation}
          partyScopeFilter={partyScopeFilter}
          setPartyScopeFilter={setPartyScopeFilter}
        />
      )}
      {!loading && !conversation && (
        <NoData
          title={<img src={Icons.ic_no_chat} alt="" />}
          titleSx={{ color: 'info.main' }}
          message={Translations.CHAT_NOT_FOUND}
        />
      )}
    </>
  );
}
