import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../AppStore';

export interface TableEntitiesNavigationState {
  currentIdx: number | undefined;
  entityOid: string | undefined;
  entityOidNavigationList: string[];
  hasNextList: boolean;
  hasPreviousList: boolean;
  fetchList: boolean;
  outsideOfNavigation: boolean;
}

const initialState: TableEntitiesNavigationState = {
  currentIdx: undefined,
  entityOid: undefined,
  entityOidNavigationList: [],
  hasNextList: false,
  hasPreviousList: false,
  fetchList: false,
  outsideOfNavigation: false,
};

const tableEntitiesNavigationSlice = createSlice({
  name: 'tableEntitiesNavigation',
  initialState,
  reducers: {
    setEntityOids: (
      state,
      action: PayloadAction<{ entityOids: string[]; hasNextList: boolean; hasPreviousList: boolean }>
    ) => {
      const { entityOids, hasNextList, hasPreviousList } = action.payload;
      state.entityOidNavigationList = entityOids;
      state.hasNextList = hasNextList;
      state.hasPreviousList = hasPreviousList;
    },
    setTableNavigationEntityOid: (state, action: PayloadAction<{ entityOid: string | undefined }>) => {
      const { entityOid } = action.payload;
      if (!entityOid) {
        state.currentIdx = undefined;
        state.entityOid = undefined;
        state.outsideOfNavigation = false;
        return;
      }

      if (!state.entityOidNavigationList.length) {
        state.entityOid = entityOid;
        state.outsideOfNavigation = true;
        return;
      }

      const foundIdx = state.entityOidNavigationList.findIndex((oid) => oid === entityOid);
      if (foundIdx >= 0) {
        state.currentIdx = foundIdx;
        state.outsideOfNavigation = false;
      } else {
        state.currentIdx = undefined;
        state.outsideOfNavigation = true;
      }
      state.entityOid = entityOid;
    },
    goNextEntity: (state) => {
      if (!state.entityOidNavigationList.length || state.currentIdx === undefined) {
        console.warn('No entities for navigation provided or index not set!');
        return;
      }
      const nextIdx =
        state.currentIdx !== state.entityOidNavigationList.length - 1 ? state.currentIdx + 1 : state.currentIdx;
      if (nextIdx !== state.currentIdx) {
        state.currentIdx = nextIdx;
        state.entityOid = state.entityOidNavigationList[nextIdx];
      }
    },
    goPreviousEntity: (state) => {
      if (!state.entityOidNavigationList.length || state.currentIdx === undefined) {
        console.warn('No entities for navigation provided or index not set!');
        return;
      }
      const nextIdx = state.currentIdx !== 0 ? state.currentIdx - 1 : state.currentIdx;
      if (nextIdx !== state.currentIdx) {
        state.currentIdx = nextIdx;
        state.entityOid = state.entityOidNavigationList[nextIdx];
      }
    },
    toggleFetchList: (state) => {
      state.fetchList = !state.fetchList;
    },
    goFirstEntity: (state) => {
      if (!state.entityOidNavigationList.length) {
        console.warn('No entities for navigation provided!');
        return;
      }
      state.currentIdx = 0;
      state.entityOid = state.entityOidNavigationList[0];
    },
    goLastEntity: (state) => {
      if (!state.entityOidNavigationList.length) {
        console.warn('No entities for navigation provided!');
        return;
      }
      const lastIdx = state.entityOidNavigationList.length - 1;
      state.currentIdx = lastIdx;
      state.entityOid = state.entityOidNavigationList[lastIdx];
    },
  },
});

export const {
  setEntityOids,
  setTableNavigationEntityOid,
  goNextEntity,
  goPreviousEntity,
  toggleFetchList,
  goFirstEntity,
  goLastEntity,
} = tableEntitiesNavigationSlice.actions;
export const tableEntitiesNavigationState = (state: RootState) => state.tableEntitiesNavigation;
export const isTableNavigationStart = ({ tableEntitiesNavigation }: RootState) =>
  tableEntitiesNavigation.currentIdx === 0;
export const isTableNavigationEnd = ({ tableEntitiesNavigation }: RootState) =>
  tableEntitiesNavigation.currentIdx === tableEntitiesNavigation.entityOidNavigationList.length - 1;

export default tableEntitiesNavigationSlice.reducer;
