import { Input } from '@mui/joy';
import { styled } from '@mui/material';
import { colors } from 'theme/colors';

export const StyledInput = styled(Input, {
  shouldForwardProp: (prop) => prop !== 'inputSize' && prop !== 'rounded',
})(({ rounded, inputSize }: { rounded?: boolean; inputSize?: string }) => ({
  borderRadius: rounded ? '100px' : undefined,
  padding: inputSize === 'xs' ? '2px 8px' : undefined,
  fontSize: inputSize === 'xs' ? '12px' : undefined,
  lineHeight: inputSize === 'xs' ? '12px' : undefined,
  height: inputSize === 'xs' ? '24px' : undefined,
  minHeight: 'auto',
  '&:disabled': {
    color: colors.neutral[40],
    borderColor: 'var(--joy-palette-primary-outlinedBorder)',
    backgroundColor: 'var(--joy-palette-primary-outlinedBorder)',
  },
  '--Input-focusedHighlight': 'transparent',
  '&.Joy-focused': {
    borderColor: '#BDDEFB',
  },
}));
