import { Box, Button, CircularProgress, Stack, Typography } from '@mui/joy';
import Snackbar from '@mui/material/Snackbar';
import {
  ActionButtonStyle,
  SnackbarWithActionContainerStyle,
  SnackbarWithActionContentStyle,
  SnackbarWithActionTextStyle,
  SnackbarWithEmojiAndSubTextContainerStyle,
  SnackbarWithEmojiAndSubTextEmojiStyle,
  SnackbarWithEmojiAndSubTextInnerContainerStyle,
} from 'components/SnackBar/styles';
import { useSelector } from 'react-redux';
import { RootState } from 'services/store/AppStore';

export enum SnackBarVariantEnum {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
  sync = 'sync',
  greenSuccess = 'greenSuccess',
}

export default function SnackBarWithAction() {
  const { open, text, subtext, variant, icon, actionMessage, emoji, backgroundColor, mainTextColor, subTextColor } =
    useSelector((state: RootState) => state.snackbar);

  const renderWithEmoji = () => {
    return (
      <Box sx={{ ...SnackbarWithEmojiAndSubTextContainerStyle, backgroundColor }}>
        <Box sx={SnackbarWithEmojiAndSubTextEmojiStyle}>{emoji}</Box>
        <Box sx={SnackbarWithEmojiAndSubTextInnerContainerStyle}>
          <Typography level="micro+" sx={{ color: mainTextColor }}>
            {text}
          </Typography>
          <Typography level="micro" sx={{ color: subTextColor }}>
            {subtext}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderWithAction = () => {
    return (
      <Stack direction="row" spacing={1} sx={{ ...SnackbarWithActionContainerStyle }}>
        {variant === SnackBarVariantEnum.sync ? (
          <CircularProgress color="info" size="sm" />
        ) : (
          icon && <img src={icon} alt="" height="14.3" width="14.3" />
        )}
        <Stack direction="column" spacing={0} sx={{}}>
          <Typography level="micro+" sx={SnackbarWithActionTextStyle(variant)}>
            {text}
          </Typography>
          {subtext && (
            <Typography level="legalMicro" sx={SnackbarWithActionTextStyle(variant)}>
              {subtext}
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      autoHideDuration={4000}
      message={emoji ? renderWithEmoji() : renderWithAction()}
      ContentProps={{
        sx: SnackbarWithActionContentStyle(variant),
      }}
      action={
        actionMessage && (
          <Button sx={ActionButtonStyle(variant)} onClick={() => {}}>
            {actionMessage}
          </Button>
        )
      }
    />
  );
}
