import { SxProps } from '@mui/material';
import { colors } from 'theme/colors';
import { fonts } from 'theme/fonts';

export const ModalBoxContentStyles: SxProps = {
  flexDirection: 'column',
  display: 'flex',
  padding: '8px 0',
  px: 2,
  py: 1.5,
  gap: '16px',
  alignContent: 'center',
  alignItems: 'center',
};

export const ModalBodySheetStyles: SxProps = {
  width: 'min(100% - 64px, 450px)',
  maxWidth: '600px',
  borderRadius: '8px',
  gap: '16px',
  outline: 'none',
  paddingBottom: '12px',
  '--joy-shape-borderRadius': '6px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'center',
};

export const ModalCloseBoxStyles: SxProps = {
  ...ModalBoxContentStyles,
  justifyContent: 'center',
  alignItems: 'center',
  gap: 108,
  padding: '16px, 16px, 12px, 16px',
  px: 2,
  height: '48px',
};

export const LogoBoxStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 2,
  width: '320px',
  justifyContent: 'center',
  overflowX: 'auto',
  gap: '16px',
};

export const DividerStyles: SxProps = {
  backgroundColor: colors.neutral[10],
};

export const PrivacyDataLinkStyles: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: fonts.regular,
  fontSize: '12px',
  color: '#797F87',
  textDecoration: 'none',
  padding: '0px, 16px, 24px, 16px',
};
