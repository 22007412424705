import { Box } from '@mui/joy';

interface DetailsFooterProps {
  children: React.ReactNode;
}

export default function DetailsFooter({ children }: DetailsFooterProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 3,
        px: '16px',
        py: '8px',
        alignContent: 'center',
        alignItems: 'center',
        height: '44px',
        borderTop: '1px solid var(--joy-palette-info-softBorder)',
      }}
    >
      {children}
    </Box>
  );
}
