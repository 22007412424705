import { Sheet } from '@mui/joy';
import { ReactNode } from 'react';
import { colors } from 'theme/colors';
import DetailsCardHeader from 'components/Details/DetailsCardHeader';

interface DetailsCardProps {
  children: ReactNode;
  headerChildren?: ReactNode;
  title: string;
  level?: 'regular' | 'regular+';
}

export default function DetailsCard({ children, title, headerChildren, level }: DetailsCardProps) {
  return (
    <Sheet variant="plain" sx={styles.container}>
      <DetailsCardHeader title={title} level={level}>
        {headerChildren}
      </DetailsCardHeader>
      {children}
    </Sheet>
  );
}

const styles = {
  container: {
    bgcolor: 'background.componentBg',
    borderRadius: '8px',
    border: `1px solid ${colors.neutral[10]}`,
    boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.05)',
  },
};
