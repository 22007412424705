import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as gql from 'services/models/api/generated';
import { EntityType } from 'services/models/domain/entityTypes';
import { FeatureFlags } from 'services/models/domain/featureFlags';
import { AppContext } from 'services/store/AppContext';
import { CONVERSATION_PAGINATION_KEY } from 'services/store/persistence/models/ConversationPaginationItem';
import { authState } from 'services/store/slices/authSlice';
import { IChatFilters, chatFilters } from 'services/store/slices/conversationsFilterSlice';
import {
  addConversations,
  conversationsState,
  setConversationsDetails,
  setLoading,
  setPage,
} from 'services/store/slices/conversationsSlice';
import { entityFilter } from 'services/store/slices/entityFilterSlice';
import { userTgState } from 'services/store/slices/userTgSlice';
import {
  mapConversationToDomain,
  updateIndividualChatName,
  updateUserInConversation,
} from 'services/utils/conversationUtils';
import { mapFilterItemsToConversationQuery } from 'services/utils/entityFilterUtils';

export function useFetchConversations() {
  const { user } = useSelector(authState);
  const context = useContext(AppContext);
  const filterForm = useSelector(chatFilters);
  const entityFilterData = useSelector(entityFilter);
  const flags = useFlags<FeatureFlags>();

  const { conversationsSynced } = useSelector(userTgState);
  // const { filterItems } = entityFilterData;
  const conversationFilterItems = entityFilterData?.filterItems.filter(
    (f) => f.entityType === EntityType.Conversation || f.entityType === EntityType.ContactRecord
  );

  const dispatch = useDispatch();
  const conversationStateData = useSelector(conversationsState);
  const currentPage = conversationStateData?.currentPage;
  const limitPerPage = conversationStateData?.limitPerPage;
  const [initial, setInitial] = useState(true);

  useEffect(() => {
    const conversationPaginationItem = context.conversationPaginationPersistence.getItem(CONVERSATION_PAGINATION_KEY);
    if (conversationPaginationItem && conversationPaginationItem.currentPage !== currentPage) {
      dispatch(setPage(conversationPaginationItem.currentPage));
    }
    setInitial(false);
  }, []);

  const generateInput = (limit: number, pageNumber: number) => {
    const query = mapFilterItemsToConversationQuery(conversationFilterItems);
    let search = '';
    if (query.input.search) {
      search = query.input.search;
    }
    if (filterForm?.input.search !== '') {
      search = filterForm?.input.search;
    }
    const mappedFilterForm: IChatFilters = {
      ...filterForm,
      input: {
        search: search,
        sortOn: filterForm?.input.sortOn,
        filterOn: query.input.filterOn,
        dateRange: query.input.dateRange,
      },
    };
    const filter = {
      ...filterForm,
      ...mappedFilterForm,
    };
    return {
      input: {
        ...filter.input,
        pageRequest: {
          limit: limit,
          pageNumber: pageNumber,
        },
      },
      criteria: { ...filter.criteria },
    };
  };

  const { data, loading, refetch } = gql.useChatsPageCoversationsQuery({
    variables: {
      ...generateInput(limitPerPage, currentPage || 0),
      // @ts-ignore Workaround to trigger a reload instead of resetting the propertyDefinition
      // r: filterForm.reload,
      // re: reload
    },
    initialFetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    // TODO (AWE): Check if sync is running
    pollInterval: 600000,
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  });

  useEffect(() => {
    if (!loading && data?.conversations?.data && user) {
      const gqlConversations = data?.conversations?.data.conversations as gql.Conversation[];
      const conversationsWithNameUpdate = gqlConversations.map((conversation) =>
        updateIndividualChatName(conversation, user)
      );
      const conversations =
        conversationsWithNameUpdate.map((conversation) =>
          mapConversationToDomain(conversation, flags.chatLinkVisibility)
        ) || [];
      const updatedConversations = conversations.map((conversation) => {
        return updateUserInConversation(conversation, user);
      });
      dispatch(
        setConversationsDetails({
          totalConversations: data?.conversations?.data?.pageInfo.totalNumberOfFilteredConversations || 0,
          pagesCount: data?.conversations?.data?.pageInfo.numberOfPages || 0,
          currentPage: currentPage,
          conversations: [],
        })
      );
      dispatch(
        addConversations({
          conversations: updatedConversations,
        })
      );
    }
  }, [data, loading, user]);

  useEffect(() => {
    if (initial) {
      return;
    }
    dispatch(setLoading(loading));
  }, [loading]);

  useEffect(() => {
    if (initial) {
      return;
    }
    refetch(generateInput(limitPerPage, currentPage || 0));
    if (currentPage !== undefined) {
      dispatch(setLoading(loading));
      context.conversationPaginationPersistence.setItem(CONVERSATION_PAGINATION_KEY, {
        currentPage: currentPage,
      });
    }
  }, [currentPage]);

  useEffect(() => {
    if (initial) {
      return;
    }
    refetch(generateInput(limitPerPage, currentPage || 0));
  }, [entityFilterData?.filterItems]);

  useEffect(() => {
    if (initial) {
      return;
    }
    refetch(generateInput(limitPerPage, 0));
  }, [limitPerPage]);

  useEffect(() => {
    if (conversationsSynced > 0) {
      refetchConversations();
    }
  }, [conversationsSynced]);

  const refetchConversations = async () => {
    const { data } = await refetch(generateInput(limitPerPage, 0));
    if (data?.conversations?.data && user) {
      const gqlConversations = data?.conversations?.data.conversations as gql.Conversation[];
      const conversationsWithNameUpdate = gqlConversations.map((conversation) =>
        updateIndividualChatName(conversation, user)
      );
      const conversations =
        conversationsWithNameUpdate.map((conversation) =>
          mapConversationToDomain(conversation, flags.chatLinkVisibility)
        ) || [];
      dispatch(
        setConversationsDetails({
          totalConversations: data?.conversations?.data?.pageInfo.totalNumberOfFilteredConversations || 0,
          pagesCount: data?.conversations?.data?.pageInfo.numberOfPages || 0,
          currentPage: currentPage,
          conversations: [],
        })
      );
      dispatch(
        addConversations({
          conversations: conversations,
        })
      );
    }
  };
}
