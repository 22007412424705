import { Avatar } from '@mui/joy';
import { alpha, SxProps, lighten } from '@mui/material';
import { colors } from '_mocks';
import { SyntheticEvent } from 'react';

interface Props {
  index: number;
  imgUrl?: string;
  userInitials?: string;
  size?: number;
  sx?: SxProps;
  solid?: boolean;
  onError?: (e: SyntheticEvent<HTMLImageElement>) => void;
}

const CustomAvatar = ({ index, userInitials, size = 16, sx, solid = false, imgUrl, onError }: Props) => (
  <Avatar
    sx={{
      //backgroundColor: alpha(`${colors[index]}`, 0.1),
      backgroundColor: !solid ? alpha(colors[index], 0.1) : lighten(colors[index], 0.9),
      color: `${colors[index]}`,
      '--Avatar-size': `${size}px`,
      fontSize: `${size / 2}px`,
      border: `1px solid rgba(255, 255, 255, 0.12)`,
      ...sx,
    }}
    componentsProps={{
      img: {
        onError: onError,
      },
    }}
    src={imgUrl}
  >
    {userInitials}
  </Avatar>
);

export default CustomAvatar;
