import { DateRangeInput, EmptyFilterType, FilterConversationsOnPropertyInput } from 'services/models/api/generated';
import { EntityFilterItem, EntityFilterType } from 'services/models/domain/entityFilter';

export const mapFilterItemsToFilterOnQuery = (
  entityFilterItems: EntityFilterItem[]
): FilterConversationsOnPropertyInput => {
  return entityFilterItems?.reduce<FilterConversationsOnPropertyInput>((result, item) => {
    const { filter } = item;
    if ('favorite' in filter) {
      return {
        ...result,
        favorite: filter.favorite,
      };
    }
    if ('archived' in filter) {
      return {
        ...result,
        archived: filter.archived,
      };
    }
    if ('participantOids' in filter) {
      return {
        ...result,
        usersAreParticipants: filter.participantOids,
      };
    }
    if ('userOids' in filter && !('dateRange' in filter)) {
      return {
        ...result,
        addedByUsers: filter.userOids,
      };
    }
    if ('propertyValueOids' in filter && 'propertyDefinitionOid' in filter) {
      if (filter.noValue) {
        return {
          ...result,
          noPropertyValues: [
            ...(result?.noPropertyValues || []),
            { propertyDefinitionOid: filter.propertyDefinitionOid },
          ],
        };
      } else {
        return {
          ...result,
          propertyValues: [
            ...(result.propertyValues || []),
            ...filter.propertyValueOids.map((valueOid) => {
              return {
                oid: valueOid,
                propertyDefinitionOid: filter.propertyDefinitionOid,
              };
            }),
          ],
        };
      }
    }
    if ('folderOids' in filter) {
      return {
        ...result,
        folderOids: filter.folderOids,
      };
    }

    if ('conversationType' in filter) {
      return {
        ...result,
        conversationType: filter.conversationType,
      };
    }
    if ('emptyFilterType' in filter) {
      return {
        ...result,
        emptyFilterType: EmptyFilterType.Followup,
      };
    }

    if ('followUpsAssignedTo' in filter) {
      if (!filter.noAssignee) {
        return {
          ...result,
          followUpAssignedTo: filter.followUpsAssignedTo,
          emptyFilterType: undefined,
        };
      } else {
        // TODO: NEED TO PASS IN EMPTY ARRAY FOR BACKEND TO HANDLE NO FOLLOW UPS SET
        return {
          ...result,
          followUpAssignedTo: [],
          emptyFilterType: EmptyFilterType.Followup,
        };
      }
    }
    return result;
  }, {});
};

export const mapFilterItemsToDateRange = (entityFilterItems: EntityFilterItem[]): DateRangeInput[] => {
  const result: DateRangeInput[] = [];
  if (!entityFilterItems) {
    return result;
  }
  for (const item of entityFilterItems!) {
    const { filter } = item;
    if (filter.type === EntityFilterType.DateRange) {
      result.push({
        field: filter.onField,
        from: filter.from ? { utcTimeStampAsString: new Date(filter.from).toISOString() } : undefined,
        to: filter.to ? { utcTimeStampAsString: new Date(filter.to).toISOString() } : undefined,
      });
    }
    if (filter.type === EntityFilterType.FollowUpsAssignedToUser && !filter.noAssignee) {
      result.push({
        field: filter.onField,
        from: filter.from ? { utcTimeStampAsString: new Date(filter.from).toISOString() } : undefined,
        to: filter.to ? { utcTimeStampAsString: new Date(filter.to).toISOString() } : undefined,
      });
    }
  }
  return result;
};

export const mapFilterItemsToConversationQuery = (entityFilterItems: EntityFilterItem[]) => {
  const conversationQuery = mapFilterItemsToFilterOnQuery(entityFilterItems);
  const dateRange = mapFilterItemsToDateRange(entityFilterItems);
  const filters = {
    favorite: conversationQuery?.favorite,
    archived: conversationQuery?.archived || false,
    addedByUsers: conversationQuery?.addedByUsers,
    usersAreParticipants: conversationQuery?.usersAreParticipants,
    folderOids: conversationQuery?.folderOids,
    emptyFilterType: conversationQuery?.emptyFilterType,
    propertyValues: conversationQuery?.propertyValues,
    noPropertyValues: conversationQuery?.noPropertyValues,
    followUpAssignedTo: conversationQuery?.followUpAssignedTo,
    conversationType: conversationQuery?.conversationType,
  };

  let searchText;
  if (entityFilterItems) {
    const search = entityFilterItems.find((p) => p.filter.type === EntityFilterType.SearchText);
    searchText = search?.filter.type === EntityFilterType.SearchText ? search.filter.searchText : undefined;
  } else {
    searchText = undefined;
  }

  return {
    input: {
      filterOn: filters,
      dateRange: dateRange.length > 0 ? dateRange : undefined,
      search: searchText,
    },
  };
};
