import { GridCellProps, GridCell } from '@mui/x-data-grid-pro';
import LastModifiedCellContent from '../cellsContent/LastModified/LastModifiedCellContent';

export default function LastModifiedCell(props: GridCellProps) {
  const { value, ...rest } = props;
  return (
    <GridCell {...rest}>
      <LastModifiedCellContent
        lastModifiedTime={value.lastModifiedTime}
        lastModifiedByUserName={value.lastModifiedByUserName}
      />
    </GridCell>
  );
}
