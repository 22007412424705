import React, { useRef, useState } from 'react';
import { Box, Divider, Grid, Stack } from '@mui/joy';
import AppButton from 'components/Buttons/Button';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { canvasPreview } from 'services/utils/canvasPreview';
import styles from 'page/modal/styles';
import Compressor from 'compressorjs';
import { useGetAvatarUploadSignedUrlLazyQuery } from 'services/models/api/generated';

interface Props {
  userOid: string;
  imgSrc: string;
  onCancel: () => void;
  onSave: (avatarKey: string) => void;
  onError: () => void;
}

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const AVATAR_BASE_URL = process.env.REACT_APP_AVATAR_BASE_URL as string;

const AvatarCropper = ({ imgSrc, onCancel, onSave, onError, userOid }: Props) => {
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [loading, setLoading] = useState<boolean>(false);
  const [getAvatarUploadSignedUrl] = useGetAvatarUploadSignedUrlLazyQuery({
    nextFetchPolicy: 'network-only',
  });

  const aspect = 1;
  const imgRef = useRef<HTMLImageElement | null>(null);
  const blobUrlRef = useRef('');

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = getContainedSize(e.currentTarget);
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  function getContainedSize(img: HTMLImageElement) {
    var ratio = img.naturalWidth / img.naturalHeight;
    var width = img.height * ratio;
    // eslint-disable-next-line prefer-destructuring
    var height = img.height;
    if (width > img.width) {
      // eslint-disable-next-line prefer-destructuring
      width = img.width;
      height = img.width / ratio;
    }
    return { width, height };
  }

  const uploadFile = async (file: Blob, signedUrl: string, uploadKey: string) => {
    try {
      const response = await fetch(signedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      });
      if (response.status === 200) {
        onSave(`${AVATAR_BASE_URL}${uploadKey}`);
      } else {
        onError();
      }
    } catch (error) {
      onError();
    } finally {
      setLoading(false);
    }
  };

  async function uploadToS3Bucket() {
    if (imgRef.current && completedCrop) {
      try {
        setLoading(true);
        const blob = await canvasPreview(imgRef.current, completedCrop, 1, 0);
        const { data: signedUrlData } = await getAvatarUploadSignedUrl({ variables: { input: { userOid: userOid } } });

        new Compressor(blob, {
          quality: 0.2,
          success: async (compressedResult) => {
            blobUrlRef.current = URL.createObjectURL(compressedResult);

            const signedUrl = signedUrlData?.getAvatarUploadSignedUrl.data.signedUrl;
            const uploadKey = signedUrlData?.getAvatarUploadSignedUrl.data.uploadKey;
            if (signedUrl && uploadKey) {
              await uploadFile(compressedResult, signedUrl, uploadKey);
            }
          },
        });
      } catch (error) {
        // TODO: error ignored review
      }
    }
  }

  return (
    <>
      <Grid container sx={styles.cropperContainer}>
        <Grid xs={12} md={12} lg={12} gap={2} sx={{ padding: { xs: '0 8px', md: '0' } }}>
          <Box display="flex" justifyContent="center" alignItems="center" sx={styles.cropperBackground}>
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => {
                setCompletedCrop(c);
              }}
              aspect={aspect}
              minHeight={50}
              minWidth={50}
              maxHeight={1024}
              maxWidth={1024}
            >
              <img
                ref={imgRef}
                alt="avatar"
                src={imgSrc}
                style={{ maxWidth: '100%', maxHeight: '240px', objectFit: 'contain' }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ border: '1px solid var(--joy-palette-primary-plainBorder)' }} />
      <Grid container sx={styles.buttonsGrid}>
        <Box sx={{ paddingY: 1, paddingX: -1 }}>
          <Stack direction="row" sx={styles.buttonStack}>
            <AppButton
              loading={false}
              onClick={async () => {
                onCancel();
              }}
              sx={styles.cancelButton}
            >
              Cancel
            </AppButton>
            <AppButton
              loading={loading}
              onClick={async () => {
                await uploadToS3Bucket();
              }}
              sx={{ minWidth: ' 120px', minHeight: '32px' }}
            >
              Save changes
            </AppButton>
          </Stack>
        </Box>
      </Grid>
    </>
  );
};

export default AvatarCropper;
