import { styled, SxProps, Theme } from '@mui/material';
import { colors } from 'theme/colors';
import { fonts } from 'theme/fonts';

export const ModalBoxContentStyles: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  padding: '16px',
  '& > *': {
    flex: 1,
    textAlign: 'left',
  },
};

export const ModalBodySheetStyles: SxProps = {
  width: '100%',
  maxHeight: '90%',
  maxWidth: '540px',
  borderRadius: '8px',
  outline: 'none',
  '--joy-shape-borderRadius': '6px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export const TelInputWrapperStyled = styled('div')({
  position: 'relative',
  width: '66%',
  borderRadius: '4px',
});

export const TelInputBtnStyles: SxProps = {
  fontSize: '12px',
  paddingLeft: '8px',
  paddingRight: '8px',
  borderRadius: '4px',
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  width: '100%',
  maxHeight: '12px',
  minHeight: '24px',
  marginTop: '35px',
  fontWeight: 100,
  fontFamily: fonts.regular,
};

export const TelInputCounterBtnStyles: SxProps = {
  ...TelInputBtnStyles,
  backgroundColor: '#ECF0F3',
  color: colors.neutral[60],
  ':hover': {
    backgroundColor: '#ECF0F3',
    color: colors.neutral[60],
  },
};

export const ModalContentStackStyles: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
};

export const MuiTelInputStyles: SxProps<Theme> = {
  width: '100%',
  backgroundColor: 'var(--joy-palette-primary-plainBorder)',
  '.MuiOutlinedInput-root': {
    paddingLeft: '4px',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#E6EBEE',
  },
  '.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B6D5FE',
  },
  '.MuiInputBase-input': {
    height: '19px',
    fontSize: '14px',
    fontFamily: fonts.regular,
  },
  '.MuiTelInput-Flag img': {
    width: '18px',
    height: '13.4px',
  },
};

export const CustomDividerStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  height: '24px',
  fontSize: '12px',
  fontFamily: fonts.regular,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
  marginBottom: '12px',
  color: colors.neutral[40],
  alignSelf: 'stretch',

  '&::before, &::after': {
    content: '""',
    flex: 1,
    height: '1px',
    borderBottom: '1px dashed',
    borderColor: colors.neutral[10],
    maxWidth: '184.5px',
  },

  '&::before': {
    marginRight: '8px',
  },

  '&::after': {
    marginLeft: '8px',
  },
};
export const LinkStyles: SxProps = {
  cursor: 'pointer',
  textDecoration: 'underline',
  marginBottom: '8px',
};
export const QrCodeBoxStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '12px 12px 0px 12px',
};
export const QrCodeInstructionStyles: SxProps = {
  alignSelf: 'stretch',
  color: colors.neutral[60],
  textAlign: 'center',
  fontSize: '14px',
  fontFamily: fonts.regular,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
  margin: '24px',
};
export const DividerStyles: SxProps = {
  backgroundColor: colors.neutral[10],
  margin: '0px 0px 0px 0px',
};

export const PrivacyDataStyles: SxProps = {
  fontFamily: fonts.regular,
  fontSize: '12px',
  color: '#797F87',
  textDecoration: 'none',
  padding: '4px 4px 4px 12px',
  alignSelf: 'stretch',
};

export const PrivacyDataLinkStyles: SxProps = {
  display: 'inline',
  fontFamily: fonts.regular,
  fontSize: '12px',
  color: '#797F87',
  textDecoration: 'underline',
  padding: '4px 0px 4px 0px',
};
