import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useChatDetailLazyQuery } from 'services/models/api/generated';
import { conversationsState } from 'services/store/slices/conversationsSlice';
import { setEntity, setLoading, sidebarEntityDetailsState } from 'services/store/slices/sidebarEntityDetailsSlice';
import {
  mapConversationToDomain,
  updateIndividualChatName,
  updateUserInConversation,
} from 'services/utils/conversationUtils';
import * as gql from 'services/models/api/generated';
import { PartyType } from 'services/models/domain/entityFilter';
import { contactsState } from 'services/store/slices/contactsSlice';
import { mapContactToDomain } from 'services/utils/contactUtils';
import { EntityDetailsType } from 'services/store/slices/sidebarSlice';
import { authState } from 'services/store/slices/authSlice';
import { FeatureFlags } from 'services/models/domain/featureFlags';
import { useFlags } from 'launchdarkly-react-client-sdk';

export function useFetchSidebarEntityDetails(partyScopeFilter: PartyType) {
  const dispatch = useDispatch();
  const { user } = useSelector(authState);
  const { entity, entityOid, entityType, loading } = useSelector(sidebarEntityDetailsState);
  const { conversations } = useSelector(conversationsState);
  const { contacts } = useSelector(contactsState);
  const [fetchContact, { data: contactData, loading: contactLoading }] = gql.useContactLazyQuery();

  const [fetchConversation, { data: conversationData, loading: conversationLoading }] = useChatDetailLazyQuery();
  const flags = useFlags<FeatureFlags>();
  useEffect(() => {
    if (entityOid && entityType === EntityDetailsType.chatDetails) {
      fetchConversationDetails(entityOid);
    }

    if (entityOid && entityType === EntityDetailsType.contactDetails) {
      fetchContactDetails(entityOid);
    }
  }, [entityOid, user]);

  useEffect(() => {
    const loading = contactLoading || conversationLoading;
    dispatch(setLoading(loading));
  }, [contactLoading, conversationLoading]);

  useEffect(() => {
    if (
      !contactLoading &&
      contactData?.contact?.data?.contact &&
      user &&
      entityType === EntityDetailsType.contactDetails
    ) {
      const newContact = contactData.contact?.data?.contact as gql.Contact;
      if (newContact) {
        const mappedContact = mapContactToDomain(newContact as gql.Contact);
        dispatch(setEntity(mappedContact));
      }
    }
  }, [contactData, contactLoading]);

  const fetchConversationDetails = async (oid: string) => {
    const localConversation = conversations.find((c) => c.oid.oid === oid);
    if (localConversation) {
      const updatedConversation = user ? updateUserInConversation(localConversation, user) : localConversation;
      dispatch(setEntity(updatedConversation));
    } else {
      fetchConversation({
        variables: {
          input: {
            oid: oid,
          },
          criteria: {
            partyScope: partyScopeFilter,
          },
        },
      });
    }
  };

  const fetchContactDetails = async (oid: string | undefined) => {
    if (oid) {
      const localContact = contacts.find((c) => c.oid.oid === oid || c.participantOid.oid === oid);
      if (localContact) {
        dispatch(setEntity(localContact));
      } else {
        await fetchContact({
          variables: {
            input: {
              oid: oid,
            },
          },
        });
      }
    }
  };

  useEffect(() => {
    if (!conversationLoading && conversationData && user && entityType === EntityDetailsType.chatDetails) {
      const newConversation = conversationData.conversation?.data?.conversation as gql.Conversation;
      if (newConversation) {
        const conversationWithNameUpdate = updateIndividualChatName(newConversation, user);
        const domainConversation = mapConversationToDomain(conversationWithNameUpdate, flags.chatLinkVisibility);
        dispatch(setEntity(domainConversation));
      }
    }
  }, [conversationData, conversationLoading]);

  return {
    entity,
    loading,
  };
}
