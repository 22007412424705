import { useDispatch, useSelector } from 'react-redux';
import { NavigationItem } from 'page/dashboard/header/SideNav';
import { setNavigationItems, setSelectedItem, sideNavState } from 'services/store/slices/sideNavSlice';
import {
  CHAT_RECORDS,
  CONTACT_RECORDS,
  DETAILS_PAGE_ITEM,
  entityViewsToNavigationItems,
  REPORT_RECORDS,
} from 'services/utils/dashboardHeaderUtils';
import { setPage } from 'services/store/slices/conversationsSlice';
import { useContext, useEffect } from 'react';
import { AppContext } from 'services/store/AppContext';
import { entityViewsState } from 'services/store/slices/entityViewsSlice';
import { EntityView } from 'services/models/domain/entityViews';
import _ from 'lodash';
import AnalyticKeys, { trackAction } from 'services/utils/analytics';
import { authState } from 'services/store/slices/authSlice';
import { RouterPaths } from './useNavigation';
export const NAVIGATION_ITEM_KEY = 'NAVIGATION_ITEM';

export const useNavigationSidebar = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(authState);
  const context = useContext(AppContext);
  const { entityViews } = useSelector(entityViewsState);
  const { navigationItems, selectedItem } = useSelector(sideNavState);

  const setSelectedNavigationItem = (newSelectedItem: NavigationItem) => {
    if (newSelectedItem.id) {
      dispatch(setSelectedItem({ selectedItem: newSelectedItem }));
      context.navigationItemPersistence.setItem(NAVIGATION_ITEM_KEY, newSelectedItem);
      dispatch(setPage(0));
    }
  };

  const trackCustomViewSelected = (selectedItem: NavigationItem) => {
    if (user && user.actingAsMemberOfOrganization) {
      trackAction(AnalyticKeys.OPENED_SMART_LIST, {
        organizationId: user?.actingAsMemberOfOrganization.oid,
        organizationName: user?.actingAsMemberOfOrganization.name,
        smartListName: selectedItem.title,
        oid: selectedItem.id,
      });
    }
  };

  useEffect(() => {
    const selectedItem = context.navigationItemPersistence.getItem(NAVIGATION_ITEM_KEY);
    if (location.pathname.match(/\/details\/(chat|contact)\/.+/g)) {
      setSelectedNavigationItem(DETAILS_PAGE_ITEM);
    } else if (!location.pathname.match(RouterPaths.Chats) || !selectedItem?.id) {
      clearSelectedNavigationItem();
    }
  }, []);

  useEffect(() => {
    const items = entityViewsToNavigationItems(entityViews.views as EntityView[]);
    dispatch(setNavigationItems({ navigationItems: items }));

    if (!!items.length && !!location.pathname.match(RouterPaths.Chats)) {
      const storedSelectedItem = context.navigationItemPersistence.getItem(NAVIGATION_ITEM_KEY);
      const navigationItem = items.find((item) => item.id === storedSelectedItem?.id);
      if (!!navigationItem) {
        setSelectedNavigationItem(navigationItem);
      } else {
        clearSelectedNavigationItem();
      }
    }
  }, [entityViews]);

  useEffect(() => {
    const items = entityViewsToNavigationItems(entityViews.views as EntityView[]);
    const updated = items.map((item) => {
      if (item.id === selectedItem.id) {
        return selectedItem;
      }
      return item;
    });
    if (!_.isEqual(updated, navigationItems)) {
      dispatch(setNavigationItems({ navigationItems: updated }));
    }
  }, [selectedItem]);

  const clearSelectedNavigationItem = () => {
    if (location.pathname.match('/contact')) {
      dispatch(
        setSelectedItem({
          selectedItem: {
            icon: 'network',
            title: `${user?.actingAsMemberOfOrganization.name}'s Contacts`,
            id: CONTACT_RECORDS,
          },
        })
      );
      return;
    }
    if (location.pathname.match('/reports')) {
      dispatch(
        setSelectedItem({
          selectedItem: {
            icon: 'reports',
            title: `Reports`,
            id: REPORT_RECORDS,
          },
        })
      );
      return;
    }
    dispatch(
      setSelectedItem({
        selectedItem: {
          icon: 'chats',
          title: `${user?.actingAsMemberOfOrganization.name}'s Chats`,
          id: CHAT_RECORDS,
        },
      })
    );
  };

  const setChatsNavigationItem = () => {
    dispatch(
      setSelectedItem({
        selectedItem: {
          icon: 'chats',
          title: `${user?.actingAsMemberOfOrganization.name}'s Chats`,
          id: CHAT_RECORDS,
        },
      })
    );
  };

  return {
    setSelectedNavigationItem,
    clearSelectedNavigationItem,
    trackCustomViewSelected,
    setChatsNavigationItem,
  };
};
