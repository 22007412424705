import { useEffect, useState } from 'react';
import { ChromeExtensionClient, ChromeExtensionMessageType } from 'services/clients/ChromeExtensionClient';

export function useChromeExtenstionStatus() {
  const chromeExtensionClient = new ChromeExtensionClient();
  const [isChromeExtensionInstalled, setIsChromeExtensionInstalled] = useState(false);

  useEffect(() => {
    if (chromeExtensionClient.isChromeRuntime()) {
      chromeExtensionClient.sendMessage({ type: ChromeExtensionMessageType.InstallCheck }).then((response) => {
        setIsChromeExtensionInstalled(!!response?.success);
      });
    }
  }, []);

  return { isChromeExtensionInstalled };
}
