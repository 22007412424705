import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'services/store/AppStore';
import { Oid, OptionPropertyValue, PropertyValue } from 'services/models/api/generated';

export const DEFAULT_NEW_OPTION = {
  color: '#FFFFFF',
  value: '',
  description: '',
  icon: 'ic_check',
  oid: {
    oid: '',
  },
  type: 'OPTION',
};

export interface StateOptionPropertyValue {
  option: OptionPropertyValue;
  newValueTempId: string | null;
}

export type InitialState = StateOptionPropertyValue[];

const initialState: InitialState = [];

const chatPropertyValueEditSlice = createSlice({
  name: 'chatPropertyValueEdit',
  initialState,
  reducers: {
    setPropertyValues: (state, action: PayloadAction<OptionPropertyValue[]>) => {
      const newState = action.payload.map((option) => {
        return {
          option: option,
          newValueTempId: null,
        };
      });
      return newState;
    },
    editPropertyValue: (state, action: PayloadAction<OptionPropertyValue>) => {
      return state.map((optionValue) => {
        if (optionValue.option.oid.oid === action.payload.oid.oid) {
          return {
            ...optionValue,
            option: action.payload,
          };
        }
        return optionValue;
      });
    },
    deletePropertyValue: (state, action: PayloadAction<string>) => {
      return state
        .filter((optionValue) => optionValue.option.oid.oid !== action.payload)
        .map((optionValue, index) => {
          return { ...optionValue, option: { ...optionValue.option, ordering: index + 1 } };
        });
    },
    addPropertyValue: (state, action: PayloadAction<{ propertyDefinitionOid: Oid; tempId: string }>) => {
      const { propertyDefinitionOid, tempId } = action.payload;

      const newOption: OptionPropertyValue = {
        ...DEFAULT_NEW_OPTION,
        propertyDefinitionOid,
        ordering: state.length + 1,
      };

      state.push({
        newValueTempId: tempId,
        option: newOption,
      });
    },
    setOidByTempId: (state, action: PayloadAction<{ tempId: string; oid: string }>) => {
      const { tempId, oid } = action.payload;

      return state.map((optionValue) => {
        if (optionValue.newValueTempId === tempId) {
          return {
            ...optionValue,
            option: {
              ...optionValue.option,
              oid: {
                oid,
              },
            },
          };
        }

        return optionValue;
      });
    },
    updateOrdering: (state, action: PayloadAction<{ updatedValues: PropertyValue[] }>) => {
      const { updatedValues } = action.payload;

      const updatedOrderingMap: Map<string, number> = new Map();
      updatedValues.forEach((v) => {
        if ('ordering' in v) {
          updatedOrderingMap.set(v.oid.oid, v.ordering);
        }
      });

      return state.map((pv) => {
        const updatedOrdering = updatedOrderingMap.get(pv.option.oid.oid);
        if (updateOrdering !== undefined) {
          return {
            ...pv,
            option: {
              ...pv.option,
              ordering: updatedOrdering!,
            },
          };
        } else {
          return pv;
        }
      });
    },
  },
});

export const {
  setPropertyValues,
  editPropertyValue,
  deletePropertyValue,
  addPropertyValue,
  setOidByTempId,
  updateOrdering,
} = chatPropertyValueEditSlice.actions;

export const chatPropertyValueEdit = (state: RootState) => state.chatPropertyValueEdit;

export default chatPropertyValueEditSlice.reducer;
