import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { List, Option, Select, Stack } from '@mui/joy';
import DetailsCard from 'components/Details/DetailsCard';
import AddNoteItem from 'components/NotesItem/AddNoteItem';
import NotesItem, { NoteState } from 'components/NotesItem/NotesItem';
import Translations from 'const/translations/en';
import NotePlaceholder from 'page/details/placeholder/NotePlaceholder';
import {
  noteListStyle,
  notesSelectorListItemStyle,
  notesSelectorListStyle,
  notesSelectorStyle,
} from 'page/details/styles';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Note, PartyType } from 'services/models/api/generated';
import { EntityType } from 'services/models/domain/entityTypes';
import { Note as DomainNote } from 'services/models/domain/note';
import { authState } from 'services/store/slices/authSlice';

interface NotesDetailsProps {
  entityOid: string;
  entityType: EntityType;
  notes: DomainNote[];
  setPartyScopeFilter: React.Dispatch<React.SetStateAction<PartyType>>;
  partyScopeFilter: PartyType;
  loading?: boolean;
}

export default function NotesDetailsCard({
  entityOid,
  entityType,
  notes,
  setPartyScopeFilter,
  partyScopeFilter,
  loading,
}: NotesDetailsProps) {
  const { user } = useSelector(authState);

  const [noteState, setNoteState] = useState<NoteState>({
    isEditing: false,
    selectedNote: null,
    shouldHideOthers: false,
  });

  const notesToShow = useMemo(() => {
    return notes?.filter(
      (n: DomainNote) => partyScopeFilter === PartyType.Organization || user?.oid === n.createdByUserOid.oid
    );
  }, [notes, partyScopeFilter, user]);

  const renderNotesFilter = useCallback(() => {
    return (
      <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }}>
        <Select
          sx={notesSelectorStyle}
          size="sm"
          variant="outlined"
          value={partyScopeFilter}
          indicator={<KeyboardArrowDownRoundedIcon fontSize="small" />}
          onChange={(e, newValue) => setPartyScopeFilter(newValue ?? PartyType.Organization)}
          componentsProps={{ listbox: { sx: notesSelectorListStyle } }}
        >
          <Option value={PartyType.Organization} sx={notesSelectorListItemStyle}>
            {Translations.ALL_NOTES}
          </Option>
          <Option value={PartyType.User} sx={notesSelectorListItemStyle}>
            {Translations.WRITTEN_BY_ME}
          </Option>
        </Select>
      </Stack>
    );
  }, [partyScopeFilter, noteState]);

  return (
    <DetailsCard title="Notes" headerChildren={renderNotesFilter()}>
      {loading && <NotePlaceholder />}
      {!loading && entityOid && (
        <List sx={noteListStyle}>
          <AddNoteItem
            setNoteState={setNoteState}
            state={noteState}
            entityType={entityType}
            entityOid={entityOid}
            key={'new_item_key'}
            autoFocus={false}
          />
          {notesToShow?.map((n: DomainNote) => (
            <NotesItem
              note={n as Note}
              key={n.oid}
              state={noteState}
              entityOid={entityOid}
              entityType={entityType}
              setNoteState={setNoteState}
            />
          ))}
        </List>
      )}
    </DetailsCard>
  );
}
