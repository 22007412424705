import { configureStore } from '@reduxjs/toolkit';
import chatFiltersReducer from 'services/store/slices/conversationsFilterSlice';
import chatPropertyValueEditReducer from 'services/store/slices/conversationPropertiesSlice';
import { Action } from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';
import userTgReducer from 'services/store/slices/userTgSlice';
import contactsReducer from 'services/store/slices/contactsSlice';
import conversationsReducer from 'services/store/slices/conversationsSlice';
import pipelineConversationsReducer from 'services/store/slices/pipelineConversationsSlice';
import { entityViewsReducer } from 'services/store/slices/entityViewsSlice';
import { foldersReducer } from 'services/store/slices/foldersSlice';
import sidebarReducer from 'services/store/slices/sidebarSlice';
import sideNavReducer from 'services/store/slices/sideNavSlice';
import { PageDetailsReducer } from 'services/store/slices/pageDetailsSlice';
import { EntityFilterReducer } from 'services/store/slices/entityFilterSlice';
import detailsPageReducer from 'services/store/slices/detailsSlice';
import manualConversationReducer from 'services/store/slices/manualConversationSlice';
import snackbarReducer from 'services/store/slices/snackbarSlice';
import { authReducer } from 'services/store/slices/authSlice';
import sidebarEntityDetailsReducer from 'services/store/slices/sidebarEntityDetailsSlice';
import tableEntitiesNavigationReducer from 'services/store/slices/tableEntitiesNavigationSlice';

const store = configureStore({
  reducer: {
    chatFilters: chatFiltersReducer,
    chatPropertyValueEdit: chatPropertyValueEditReducer,
    contacts: contactsReducer,
    conversationsDetails: conversationsReducer,
    sidebarEntityDetails: sidebarEntityDetailsReducer,
    entityViews: entityViewsReducer,
    folders: foldersReducer,
    pipelineConversations: pipelineConversationsReducer,
    sidebar: sidebarReducer,
    sideNav: sideNavReducer,
    userTg: userTgReducer,
    pageDetails: PageDetailsReducer,
    entityFilter: EntityFilterReducer,
    detailsPage: detailsPageReducer,
    manualConversation: manualConversationReducer,
    snackbar: snackbarReducer,
    auth: authReducer,
    tableEntitiesNavigation: tableEntitiesNavigationReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
