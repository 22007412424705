import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'services/store/AppStore';

export enum Page {
  CONVERSATION = 'CONVERSATION',
  CONTACTS = 'CONTACTS',
}

export interface PageDetailsState {
  [key: string]: {
    scrollPosition: number;
  };
}

const initialState: PageDetailsState = {
  [Page.CONTACTS]: { scrollPosition: 0 },
};

const PageDetailsSlice = createSlice({
  name: 'PageDetailsSlice',
  initialState,
  reducers: {
    setScrollPosition: (state, action: PayloadAction<{ key: Page; scrollPosition: number }>) => {
      const { key, scrollPosition } = action.payload;
      if (!state[key]) {
        state[key] = { scrollPosition: 0 };
      }
      state[key].scrollPosition = scrollPosition;
    },
  },
});

export const { setScrollPosition } = PageDetailsSlice.actions;

export const pageDetails = (state: RootState, key: Page) => state.pageDetails[key];

export const PageDetailsReducer = PageDetailsSlice.reducer;
