import DetailsCard from 'components/Details/DetailsCard';
import GroupedFolderList from 'components/GroupedFolderList';
import Translations from 'const/translations/en';
import ParticipantPlaceholder from 'page/details/placeholder/ParticipantPlaceholder';
import { Folder } from 'services/models/domain/folder';

interface FoldersDetailsCardProps {
  folders: Folder[];
  loading: boolean;
}

export default function FoldersDetailsCard({ folders, loading }: FoldersDetailsCardProps) {
  return (
    <DetailsCard title={Translations.FOLDERS}>
      {loading && <ParticipantPlaceholder />}
      {!loading && <GroupedFolderList folder={folders} />}
    </DetailsCard>
  );
}
