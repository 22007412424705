import { Box, Divider, Sheet, Stack } from '@mui/joy';
import Typography from '@mui/joy/Typography';
import { Backdrop, Drawer, SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import Icons, { IconsSvg } from 'assets/icons';
import CustomAvatar from 'components/CustomAvatar';
import PopOver from 'components/PopOver';
import NavigationItemChip from 'components/SideNav/NavigationItemChip';
import NavigationSection from 'components/SideNav/NavigationSection';
import AppLinks from 'const/links';
import Translations from 'const/translations/en';
import { useTgConnectModalControl } from 'contexts/TgConnectModalContext';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { uniqueId } from 'lodash';
import ProfileItem from 'page/dashboard/header/components/ProfileItem';
import { headerStyles } from 'page/dashboard/header/styles';
import EntityViewsModal from 'page/modal/EntityViewsModal';
import InviteTeamModal from 'page/modal/InviteTeamModal/InviteTeamModal';
import ProfileModal from 'page/modal/ProfileModal';
import SettingsModal from 'page/modal/SettingsModal';
import { TgSyncStatus } from 'page/modal/TgConnectModal/interface';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { openSpotlight } from 'services/commandbar/actions';
import { commandbarTrackerEvents } from 'services/commandbar/trackEvents';
import useAuth from 'services/hooks/useAuth';
import { useConversationFilters } from 'services/hooks/useConversationFilters';
import { useEntityViews } from 'services/hooks/useEntityViews';
import { useFetchEntityViews } from 'services/hooks/useFetchEntityViews';
import { useFetchFolders } from 'services/hooks/useFetchFolders';
import { RouterPaths, useNavigation } from 'services/hooks/useNavigation';
import { useNavigationSidebar } from 'services/hooks/useNavigationSidebar';
import { useNavigationSidebarFilters } from 'services/hooks/useNavigationSidebarFilters';
import { FeatureFlags } from 'services/models/domain/featureFlags';
import { authState } from 'services/store/slices/authSlice';
import { entityViewsState } from 'services/store/slices/entityViewsSlice';
import { sideNavState } from 'services/store/slices/sideNavSlice';
import { userTgState } from 'services/store/slices/userTgSlice';
import {
  ADD_NEW_CUSTOM_VIEW,
  BATCH_MESSAGE,
  CHROME_EXTENSION,
  COMMS_ITEMS,
  CONNECT_TELEGRAM,
  GENERAL_ITEMS,
  INVITE_TEAMMATES,
  RECORD_ITEMS,
  SEARCH_BAR,
  configItems,
} from 'services/utils/dashboardHeaderUtils';
import { avatarFromName, formatTranslation, generateColorFromOid, openOutsideLink } from 'services/utils/helpers';
import { colors } from 'theme/colors';
import { Flex2 } from 'theme/flex';

export enum ItemType {
  VIEW,
  RECORD,
  ACTION,
  LINK,
}

export type NavigationItem = {
  id?: string;
  title: string;
  icon: string;
  badge?: Badge;
  secondaryIcon?: string;
  isTeamNamePrefixed?: boolean;
};

export type Badge = {
  text: string;
  color: string;
};

interface SideNavProps {
  openDrawer: boolean;
  setOpenDrawer: (open: boolean) => void;
}

export function SideNav({ openDrawer, setOpenDrawer }: SideNavProps) {
  const featureFlags = useFlags<FeatureFlags>();
  const navigate = useNavigation();
  const { user } = useSelector(authState);
  const { fetchUser, logout } = useAuth();
  const { setSelectedNavigationItem, trackCustomViewSelected } = useNavigationSidebar();
  const { createEntityView, getEntityViewByOid, setEditMode, deleteEntityViewFromState } = useEntityViews();
  const [openProfile, setOpenProfile] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openInvite, setOpenInvite] = useState(false);
  const [openEntityViewsModal, setOpenEntityViewsModal] = useState<{ item: NavigationItem | undefined; open: boolean }>(
    { item: undefined, open: false }
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { selectedItem, navigationItems } = useSelector(sideNavState);
  const { editMode } = useSelector(entityViewsState);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [counter, setCounter] = useState(1);
  const { toggleTgConnectModal } = useTgConnectModalControl();
  const { status: tgConnectionStatus } = useSelector(userTgState);
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const DrawerIcon = openDrawer ? IconsSvg.ic_chevrons_left : IconsSvg.ic_chevrons_right;

  const { refetch } = useFetchFolders();
  useConversationFilters();
  useFetchEntityViews();

  // TODO: (Joseph) Check if we need this hook called again
  useNavigationSidebarFilters(editMode);

  useEffect(() => {
    fetchUser();
    refetch(5000);
  }, []);

  useEffect(() => {
    let tempCounter = counter - navigationItems.length;
    setCounter(tempCounter <= 0 ? 1 : tempCounter);
  }, [navigationItems]);

  useEffect(() => {
    if (featureFlags.commandbar) {
      if (tgConnectionStatus === TgSyncStatus.DISCONNECTED) {
        commandbarTrackerEvents.telegramStatusDisconnected(true);
      } else {
        commandbarTrackerEvents.telegramStatusDisconnected(false);
      }
    }
  }, [tgConnectionStatus]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onGeneralSelected = (item: NavigationItem) => {
    if (item.id === SEARCH_BAR) openSpotlight();
  };
  const onRecordSelected = (item: NavigationItem) => {
    if (editMode) {
      setOpenEntityViewsModal({ item, open: true });
    } else {
      setSelectedNavigationItem(item);
    }
  };

  const onViewSelected = (item: NavigationItem) => {
    if (editMode && item.id !== selectedItem.id) {
      setOpenEntityViewsModal({ item, open: true });
    } else {
      setSelectedNavigationItem(item);
      trackCustomViewSelected(item);
    }
  };

  const onConfigSelected = (item: NavigationItem) => {
    if (item.id === CHROME_EXTENSION) {
      openOutsideLink(AppLinks.chromeExtensionLink);
    }
    if (item.id === INVITE_TEAMMATES) {
      setOpenInvite(true);
    }
    if (item.id === CONNECT_TELEGRAM) {
      toggleTgConnectModal(true);
    }
  };

  const onCommSelected = (item: NavigationItem) => {
    if (item.id === BATCH_MESSAGE) {
      openOutsideLink(AppLinks.batchMessageLink);
    }
  };

  const onCustomViewSelected = (item: NavigationItem) => {
    if (!editMode) {
      createEntityView(item.id || '', item.title, item.icon, false);
      setCounter(counter + 1);
    } else {
      setOpenEntityViewsModal({ item, open: true });
    }
  };

  const recordItemsWithTitles = useMemo(
    () =>
      RECORD_ITEMS.map((item) => ({
        ...item,
        title: item.isTeamNamePrefixed
          ? formatTranslation(
              Translations.NAVIGATION_SIDEBAR_ORGANIZATION_ITEM_TITLE,
              user?.actingAsMemberOfOrganization.name,
              item.title
            )
          : item.title,
      })),
    [RECORD_ITEMS]
  );

  const createNewCustomView = () => {
    const newCustomViewItem = {
      id: uniqueId(),
      icon: 'dollarFile',
      title: formatTranslation(Translations.NAVIGATION_SIDEBAR_SMARTLIST_ITEM_DEFAULT, counter),
    };
    onCustomViewSelected(newCustomViewItem);
  };

  const setEntityViewOpen = () => setOpenEntityViewsModal({ ...openEntityViewsModal, open: false });

  const onEntityViewDiscard = () => {
    setEditMode(false);
    const selectedEntityView = getEntityViewByOid(selectedItem.id || '');
    if (openEntityViewsModal.item && selectedEntityView && 'tempOid' in selectedEntityView) {
      deleteEntityViewFromState(selectedEntityView.tempOid.oid);
      setSelectedNavigationItem(openEntityViewsModal.item);
    }
  };

  return (
    <>
      <Drawer
        id="navigation-sidebar"
        variant="persistent"
        open={true}
        sx={SideNavStyles.navigationDrawer(theme, openDrawer, isMobile)}
      >
        <Stack direction="row" sx={SideNavStyles.navigationHeader(openDrawer)}>
          {openDrawer && (
            <img src={Icons.ic_logo} alt={Translations.NAVIGATION_SIDEBAR_LOGO_ALT} width="28px" height="28px" />
          )}
          <DrawerIcon
            width={22}
            height={22}
            style={SideNavStyles.drawerIcon}
            stroke={colors.neutral[80]}
            onClick={() => setOpenDrawer(!openDrawer)}
          />
        </Stack>

        <Stack direction="column" sx={SideNavStyles.navigationBody}>
          <Stack direction="column" spacing={2} sx={SideNavStyles.itemsSection}>
            {featureFlags.commandbar ? (
              <NavigationSection
                title=""
                navigationItems={GENERAL_ITEMS}
                isOpen={openDrawer}
                selectedId={selectedItem.id}
                onClick={onGeneralSelected}
              />
            ) : (
              <Box sx={SideNavStyles.sectionSpacing} />
            )}
            <NavigationSection
              title={Translations.TEAM}
              navigationItems={recordItemsWithTitles}
              isOpen={openDrawer}
              selectedId={selectedItem.id}
              onClick={onRecordSelected}
            />
            <NavigationSection
              title={Translations.VIEWS}
              navigationItems={navigationItems}
              isOpen={openDrawer}
              selectedId={selectedItem.id}
              onClick={onViewSelected}
              endBox={
                <NavigationItemChip item={ADD_NEW_CUSTOM_VIEW} isOpen={openDrawer} onClick={createNewCustomView} />
              }
            />

            <NavigationSection
              title={Translations.CONFIG}
              navigationItems={configItems(tgConnectionStatus)}
              isOpen={openDrawer}
              selectedId={selectedItem.id}
              onClick={onConfigSelected}
            />
            <NavigationSection
              title={Translations.COMM}
              navigationItems={COMMS_ITEMS}
              isOpen={openDrawer}
              selectedId={selectedItem.id}
              onClick={onCommSelected}
            />
          </Stack>

          <Stack direction="column" sx={SideNavStyles.userSection}>
            <Divider sx={{ ...headerStyles.divider, marginBottom: '11px' }} />
            <Box sx={SideNavStyles.avatarContainer(openDrawer)} onClick={handlePopoverOpen}>
              <Box sx={Flex2}>
                <CustomAvatar
                  userInitials={avatarFromName(user?.fullName ?? '')}
                  index={generateColorFromOid(user?.oid ?? '')}
                  imgUrl={user?.profileImageUrl}
                  size={28}
                  solid={true}
                  sx={SideNavStyles.avatar}
                />
                {openDrawer && (
                  <Typography level="tiny" sx={SideNavStyles.userSectionTitle}>
                    {user?.fullName || user?.name}
                  </Typography>
                )}
              </Box>
              <Box sx={SideNavStyles.userSectionActionIcon}>
                <img src={Icons.ic_dots} alt="ic_chevron_dots" width={22} height={22} />
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Drawer>
      <PopOver
        id="over-popover"
        arrow="left-bottom"
        disableArrow={false}
        sx={headerStyles.popOver}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={handleClose}
      >
        <Sheet variant="plain" sx={{ minWidth: '180px', backgroundColor: 'primary.light', p: 1 }}>
          <ProfileItem
            title={Translations.NAVIGATION_USER_MENU_ITEM_PROFILE}
            icon={Icons.ic_user}
            onClick={() => {
              setOpenProfile(true);
              handleClose();
            }}
          />
          {featureFlags?.showUserNotificationSettings ? (
            <ProfileItem
              title={Translations.NAVIGATION_USER_MENU_ITEM_SETTINGS}
              icon={Icons.ic_settings}
              onClick={() => {
                setOpenSettings(true);
                handleClose();
              }}
            />
          ) : null}
          <Divider sx={headerStyles.divider} />
          <ProfileItem
            title={Translations.NAVIGATION_USER_MENU_ITEM_BLOG}
            icon={Icons.ic_book}
            onClick={() => {
              openOutsideLink(AppLinks.blogLink);
              handleClose();
            }}
          />
          <ProfileItem
            title={Translations.NAVIGATION_USER_MENU_ITEM_X}
            icon={Icons.ic_twitter}
            onClick={() => {
              openOutsideLink(AppLinks.twitterLink);
              handleClose();
            }}
          />
          <ProfileItem
            title={Translations.NAVIGATION_USER_MENU_ITEM_TELEGRAM}
            icon={Icons.ic_friends}
            onClick={() => {
              openOutsideLink(AppLinks.telegramGroupLink);
              handleClose();
            }}
          />
          <Divider sx={headerStyles.divider} />
          <ProfileItem
            title={Translations.NAVIGATION_USER_MENU_ITEM_SIGN_OUT}
            icon={Icons.ic_logout}
            onClick={async () => {
              await logout();
              navigate(RouterPaths.Login);
            }}
          />
        </Sheet>
      </PopOver>
      <Backdrop sx={headerStyles.backdrop} open={open} />
      <ProfileModal isOpen={openProfile} setIsOpen={setOpenProfile} />
      <InviteTeamModal isOpen={openInvite} setIsOpen={setOpenInvite} />
      <SettingsModal isOpen={openSettings} setIsOpen={setOpenSettings} />
      <EntityViewsModal
        isOpen={openEntityViewsModal.open}
        setIsOpen={setEntityViewOpen}
        onDiscard={onEntityViewDiscard}
      />
    </>
  );
}

export default withLDConsumer()(SideNav);

const openTransitions = (theme: Theme) => ({
  width: { sm: '200px', md: '232px' },
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closeTransitions = (theme: Theme, isMobile: boolean) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: isMobile ? '0px' : `calc(${theme.spacing(7)} + 1px)`,
});

const SideNavStyles = {
  navigationDrawer: (theme: Theme, isOpen: boolean, isMobile: boolean) => ({
    '& .MuiDrawer-paper': {
      ...(isOpen ? openTransitions(theme) : closeTransitions(theme, isMobile)),
      borderRight: `${colors.neutral['10']} 1px solid`,
      backgroundColor: colors.navigationBarBackground,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: '30px 0 15px 10px',
      overflow: 'hidden',
    },
  }),
  navigationHeader: (isOpen: boolean) => ({
    justifyContent: isOpen ? 'space-between' : 'flex-start',
    marginLeft: '5px',
    width: '100%',
    paddingBottom: '5px',
  }),
  drawerIcon: {
    marginTop: '2px',
    marginRight: '15px',
    cursor: 'pointer',
  },
  sectionSpacing: {
    height: '16px',
  },
  navigationBody: {
    justifyContent: 'space-between',
    flex: '1 1',
    overflow: 'hidden',
  },
  itemsSection: {
    alignItems: 'flex-start',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  userSection: {
    alignItems: 'flex-start',
  },
  userAction: {
    mr: '2px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '10px',
  },
  popOver: {
    minWidth: '180px',
    backgroundColor: 'primary.light',
    p: 1,
  },
  avatarContainer: (openDrawer: boolean): SxProps => {
    return {
      marginLeft: openDrawer ? '8px' : '5px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    };
  },
  avatar: {
    marginTop: '0px',
    cursor: 'pointer',
  },
  userSectionTitle: {
    marginLeft: '10px',
    marginTop: '4px',
    cursor: 'pointer',
  },
  userSectionActionIcon: {
    marginRight: '20px',
    marginTop: '2px',
    cursor: 'pointer',
  },
};
