import { Box, Divider, ListDivider, Sheet } from '@mui/joy';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import { Link, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { colors } from 'theme/colors';
import 'theme/styles/scroll-bar.css';

import Icons from 'assets/icons';
import { ReactComponent as BotIcon } from 'assets/icons/ic_bot.svg';
import { ReactComponent as NoBotIcon } from 'assets/icons/ic_no_bot.svg';
import UIButton from 'components/ui-library/Button/UIButton';
import Translations from 'const/translations/en';
import useFlags from 'launchdarkly-react-client-sdk/lib/useFlags';
import ConnectBot from 'page/modal/TgConnectModal/ConnectBot/ConnectBot';
import { TgBotTestState, TgConnectSteps, TgModalState, TgSyncModalProps } from 'page/modal/TgConnectModal/interface';
import {
  DividerStyles,
  ModalBodySheetStyles,
  ModalBoxContentStyles,
  ModalContentStackStyles,
  PrivacyDataLinkStyles,
  PrivacyDataStyles,
} from 'page/modal/TgConnectModal/styles';
import { TelegramLoginOrSyncFolders } from 'page/modal/tg-link/TgLink';
import { useSelector } from 'react-redux';
import { useChatBot } from 'services/hooks/useChatBot';
import { TgLinkContext, useTgLinkAccount } from 'services/hooks/useTgLinkAccount';
import { authState } from 'services/store/slices/authSlice';
import { ModalStyle } from '../styles';
import AppLinks from 'const/links';
import { createMailLink } from 'services/utils/helpers';

export default function TgConnectModal({ open, onClose }: TgSyncModalProps) {
  const tgLinkAccount = useTgLinkAccount();
  const { sendBotMessage } = useChatBot();
  const flags = useFlags();
  const { user } = useSelector(authState);

  const { formStep, isBotConnected, needPasswordFor2FA, snackbarOpen, snackbarText, snackbarVariant } = tgLinkAccount;

  const [tgFolderSynced, setTgFolderSynced] = useState(false);

  useEffect(() => {
    setTgFolderSynced(formStep === TgConnectSteps.FOLDER_SYNC);
  }, [formStep]);

  const tgSyncIsOn = !!flags?.telegramSyncConnectAndSyncEnabled;

  const [modalState, setModalState] = useState(TgModalState.INIT);
  const [buttonState, setButtonState] = useState(TgBotTestState.INIT);

  const handleSendTestMessage = () => {
    setButtonState(TgBotTestState.LOADING);
    sendBotMessage(Translations.TG_BOT_TEST_MESSAGE).then((res) => {
      if (res) {
        setButtonState(TgBotTestState.SUCCESS);
      } else {
        setButtonState(TgBotTestState.ERROR);
      }
    });
  };
  return (
    <Modal open={open} onClose={onClose} sx={ModalStyle} id="tg-connect-modal">
      <Sheet sx={ModalBodySheetStyles}>
        <>
          <Box sx={ModalBoxContentStyles}>
            {modalState === TgModalState.BOT && (
              <Box onClick={() => setModalState(TgModalState.INIT)} sx={{ cursor: 'pointer' }}>
                <img src={Icons.ic_chevron_left} alt="ic_arrow_left" style={{ marginRight: '15.5px' }} />
              </Box>
            )}

            <Typography id="modal-title" level="large+" textColor="inherit" fontSize="18px">
              Telegram connection settings
            </Typography>

            <ModalClose />
          </Box>
          <Divider sx={{ backgroundColor: 'var(--joy-palette-info-outlinedBorder)' }} />
          <Stack
            gap={1}
            sx={{
              ...ModalContentStackStyles,
              background: modalState === TgModalState.BOT ? '#fff' : '#F1F4F6',
              borderRadius: '0 0 10px 10px',
              padding: modalState === TgModalState.BOT ? '0' : '12px 16px 16px 16px',
              maxHeight: '2000px',
              overflowX: 'auto',
            }}
          >
            {tgSyncIsOn && (
              <>
                {modalState === TgModalState.INIT && (
                  <Sheet variant="plain" sx={{ bgcolor: 'background.componentBg', borderRadius: 'sm' }}>
                    <Box sx={{ gap: 3, p: '12px 16px 12px 16px' }}>
                      <Typography
                        level="regular"
                        sx={{
                          color: 'secondary.dark',
                          mb: '8px',
                        }}
                      >
                        {needPasswordFor2FA ? Translations.TELEGRAM_ALMOST_THERE : Translations.TELEGRAM_FOLDER_SYNC}
                      </Typography>
                      <Typography level="tiny" sx={{ color: '#72777E' }}>
                        {tgFolderSynced
                          ? Translations.TELEGRAM_MESSAGE
                          : needPasswordFor2FA
                            ? Translations.TELEGRAM_2FA_MESSAGE
                            : Translations.TELEGRAM_MESSAGE}
                      </Typography>
                    </Box>

                    <Divider sx={DividerStyles} />

                    <Stack spacing={1} sx={{ alignItems: 'center', marginTop: '8px', marginBottom: '16px' }}>
                      <TgLinkContext.Provider value={tgLinkAccount}>
                        <TelegramLoginOrSyncFolders />
                        <Divider sx={DividerStyles} />
                        <Typography level="tiny" sx={PrivacyDataStyles}>
                          {`${Translations.TELEGRAM_SECURITY} `}
                          <Link
                            href="/3RM – Privacy & Data.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={PrivacyDataLinkStyles}
                          >
                            {Translations.LEARN_MORE}
                          </Link>
                        </Typography>
                      </TgLinkContext.Provider>
                    </Stack>
                  </Sheet>
                )}
              </>
            )}
            <Sheet variant="plain" sx={{ bgcolor: 'background.componentBg', borderRadius: 'sm' }}>
              <Box sx={{ gap: 3, p: '12px 16px 12px 16px' }}>
                <Typography level="regular" sx={{ color: 'secondary.dark', mb: '4px' }}>
                  Telegram Bot
                </Typography>
                <Typography level="tiny" sx={{ color: colors.neutral['50'] }}>
                  {Translations.TELEGRAM_SUMMARY}
                </Typography>
              </Box>
              <ListDivider component="div" />

              <Stack
                spacing={2}
                sx={{
                  margin: '24px 16px 24px 16px',
                  justifyContent: 'center',
                  alignItems: 'normal',
                  textAlign: 'center',
                }}
              >
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Box display={'flex'} alignItems={'center'}>
                    {buttonState === TgBotTestState.ERROR ? <NoBotIcon stroke={colors.warning} /> : <BotIcon />}
                    <Typography
                      level="tiny"
                      sx={{ color: colors.neutral['50'], marginBottom: '-3px', marginLeft: '8px' }}
                    >
                      {Translations.TG_MODAL_TEST_BOT_BOT_INFO}
                    </Typography>
                  </Box>

                  <UIButton
                    size="sm"
                    type="submit"
                    rounded
                    loadingPosition="end"
                    loading={buttonState === TgBotTestState.LOADING}
                    onClick={() => handleSendTestMessage()}
                    style={
                      buttonState === 'SUCCESS'
                        ? {
                            backgroundColor: colors.success,
                            borderColor: colors.success,
                          }
                        : buttonState === 'ERROR'
                          ? {
                              backgroundColor: colors.warning,
                              borderColor: colors.warning,
                            }
                          : {}
                    }
                  >
                    {buttonState === TgBotTestState.SUCCESS && <img src={Icons.ic_check} alt="" />}
                    {buttonState === TgBotTestState.SUCCESS
                      ? Translations.TG_MODAL_TEST_BOT_CONNECTION_SUCCESS
                      : buttonState === TgBotTestState.ERROR
                        ? Translations.TG_MODAL_TEST_BOT_CONNECTION_ERROR
                        : Translations.TG_MODAL_TEST_BOT_CONNECTION}
                  </UIButton>
                </Box>
                <Box style={{ textAlign: 'left' }}>
                  {buttonState === TgBotTestState.ERROR && (
                    <>
                      <Typography level="tiny" sx={{ color: colors.neutral['50'], marginBottom: '6px' }}>
                        {Translations.TG_MODAL_TEST_BOT_ERROR_MANUAL_CONNECTION}{' '}
                        <a href={user?.chatBotLinkInfo.link ?? ''}>
                          {Translations.TG_MODAL_TEST_BOT_ERROR_MANUAL_CONNECTION_CTA}
                          {'.'}
                        </a>
                      </Typography>
                      <Typography level="tiny" sx={{ color: colors.neutral['50'] }}>
                        {Translations.TG_MODAL_TEST_BOT_ERROR_CONTACT_INFO}
                        <a href={createMailLink(AppLinks.supportEmail)}> {AppLinks.supportEmail}</a>.
                      </Typography>
                    </>
                  )}
                </Box>
              </Stack>
            </Sheet>
            {modalState === TgModalState.BOT && <ConnectBot />}
          </Stack>
        </>
      </Sheet>
    </Modal>
  );
}
