import { useDispatch, useSelector } from 'react-redux';
import * as gql from 'services/models/api/generated';
import * as domain from 'services/models/domain/folder';
import { useEffect, useRef } from 'react';
import { setFolders } from 'services/store/slices/foldersSlice';
import { mapFolderToDomain } from 'services/utils/conversationUtils';
import * as tgSync from 'services/store/slices/userTgSlice';
import * as Sentry from '@sentry/react';
import { telegramFoldersEqualToFolders, updateUserInFolders } from 'services/utils/folderUtils';
import { authState } from 'services/store/slices/authSlice';
import { useTelegramSync } from './useTelegramSync';

export const useFetchFolders = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(authState);
  const { initialFolders, telegramFolders } = useSelector(tgSync.userTgState);
  const { syncFolders } = useTelegramSync();

  const isInitialising = useRef(false);

  useEffect(() => {
    isInitialising.current = !telegramFoldersEqualToFolders(telegramFolders, initialFolders);
  }, [telegramFolders, initialFolders]);

  const [fetchOrgFolders, { data, loading, error }] = gql.useGetOrgFoldersLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        filterOn: { forOrg: true, synced: true },
      },
    },
  });

  const [fetchTgFolders, { data: tgFolderData, loading: tgLoading }] = gql.useGetTgFolderLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {},
    },
  });

  useEffect(() => {
    if (error) {
      console.error(`Error fetching folders: ${error.message}`);
    }
    if (data && !loading) {
      const responseFolders = data!.folders?.data?.folders as gql.Folder[];
      if (!responseFolders) {
        return;
      }
      const folders: domain.Folder[] = responseFolders.map(mapFolderToDomain);
      const updatedFolders = user ? updateUserInFolders(folders, user) : folders;
      dispatch(setFolders(updatedFolders));
    }
  }, [data, error, loading, user]);

  useEffect(() => {
    const onFolderQueryResult = async () => {
      if (!tgLoading && tgFolderData) {
        const responseFolders = tgFolderData.folders?.data?.folders as gql.Folder[];
        if (!responseFolders) {
          return;
        }
        const folders: domain.Folder[] = responseFolders.map(mapFolderToDomain);
        dispatch(tgSync.setInitialFolders(folders));
        dispatch(tgSync.setFolders(folders));
        syncFolders({ domainFolders: folders });
      }
    };
    onFolderQueryResult();
  }, [tgFolderData, tgLoading]);

  const refetch = async (pollInterval?: number, retry?: number) => {
    await fetchOrgFolders();
    await fetchTgFolders();
    if (pollInterval) {
      while (isInitialising.current) {
        if (retry && retry > 5) {
          Sentry.setContext('syncContext', {
            telegramFolders: telegramFolders?.length,
            folders: initialFolders.length,
          });
          Sentry.captureException(new Error(`Tried refetching 5 times.`));
        }
        await new Promise((res) => setTimeout(res, pollInterval));
        await refetch(pollInterval, (retry || 0) + 1);
      }
    }
  };
  return {
    refetch: refetch,
  };
};
