import { UIChipProps } from 'components/ui-library/Chip/interface';
import { StyledChip } from 'components/ui-library/Chip/styled';

const UIChip = (props: UIChipProps) => {
  const { size, children, ...rest } = props;
  const chipSize = size && size != 'xs' ? size : undefined;

  return (
    <StyledChip size={chipSize} chipSize={size} {...rest}>
      {children}
    </StyledChip>
  );
};

export default UIChip;
