import { useDispatch, useSelector } from 'react-redux';
import Translations from 'const/translations/en';
import { RootState } from 'services/store/AppStore';
import { DEFAULT_SORT_FIELD, setFieldValue, setReload } from 'services/store/slices/conversationsFilterSlice';
import AnalyticKeys, { trackAction } from 'services/utils/analytics';
import CellMenuButton from 'components/ui-library/chat/CellMenu/СellMenuButton/CellMenuButton';
import { getSortPropertyTypeByField } from 'services/utils/conversationTableUtils';
import { SortOptionsProps } from 'components/Chats/ChatTableV2/TableHeader/SortOptions/interface';
import { SortDirectionType, SortConversationsOnPropertyInput } from 'services/models/api/generated';
import { authState } from 'services/store/slices/authSlice';

const SortOptions = ({
  sortFieldName,
  closeMenu,
  isSortingActive,
  isCustomProperty,
  propertyOid,
}: SortOptionsProps) => {
  const { user } = useSelector(authState);
  const dispatch = useDispatch();
  const filterForm = useSelector((state: RootState) => state.chatFilters);
  const { sortOnProperties } = filterForm.input.sortOn;

  const sortStatePath = 'input.sortOn.sortOnProperties';
  const sortByField = getSortPropertyTypeByField(sortFieldName);
  const sortByProperyOid = sortOnProperties[0].propertyDefinitionOid;

  const onSortClick = (direction: SortDirectionType) => {
    const value: SortConversationsOnPropertyInput[] = [];

    if (isCustomProperty && propertyOid) {
      value[0] = {
        propertyDefinitionOid: propertyOid,
        sortDirection: direction,
      };
    } else {
      value[0] = {
        sortConversationsOnPropertyType: sortByField || DEFAULT_SORT_FIELD,
        sortDirection: direction,
      };
    }
    const { sortDirection, sortConversationsOnPropertyType, propertyDefinitionOid } = sortOnProperties[0];
    const isSameDirection = sortDirection === direction;
    const isSameField = sortConversationsOnPropertyType && sortConversationsOnPropertyType === sortByField;
    const isSameProperyOid = propertyDefinitionOid && propertyDefinitionOid === sortByProperyOid;

    if (!isSameField || !isSameProperyOid) {
      dispatch(setFieldValue({ path: sortStatePath, value }));
      dispatch(setReload({ reload: true }));

      trackAction(AnalyticKeys.SORTED_CHAT, {
        organizationId: user?.actingAsMemberOfOrganization.oid,
        organizationName: user?.actingAsMemberOfOrganization.name,
        sortColumn: sortByField,
        sortDirection: direction,
      });
    }
    closeMenu();
  };

  return (
    <>
      <CellMenuButton
        title={Translations.TABLE_HEADER_SORT_ASC}
        icon="ic_sort_arrow_up"
        onClick={() => onSortClick(SortDirectionType.Asc)}
      />
      <CellMenuButton
        title={Translations.TABLE_HEADER_SORT_DES}
        icon="ic_sort_arrow_down"
        onClick={() => onSortClick(SortDirectionType.Desc)}
      />
    </>
  );
};

export default SortOptions;
