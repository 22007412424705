import { Tooltip } from '@mui/joy';

import Icons from 'assets/icons';
import { FavoriteCellContentProps } from 'components/Chats/ChatTableV2/TableCell/customCells/cellsContent/Favorite/interface';

export default function FavoriteCellContent({ favorite, handleRemoveFav, handleAddFav }: FavoriteCellContentProps) {
  return (
    <Tooltip title="Favorite" arrow onClick={() => (favorite ? handleRemoveFav() : handleAddFav())}>
      {favorite ? (
        <img src={Icons.ic_star} width={15} height={15} alt="star" style={{ cursor: 'pointer' }} />
      ) : (
        <img src={Icons.ic_star_filled} width={15} height={15} alt="star" style={{ cursor: 'pointer' }} />
      )}
    </Tooltip>
  );
}
