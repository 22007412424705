import { Typography } from '@mui/joy';
import DetailsCard from 'components/Details/DetailsCard';
import Translations from 'const/translations/en';
import SinglePlaceholder from 'page/details/placeholder/SinglePlaceholder';
import { colors } from 'theme/colors';
import { fonts } from 'theme/fonts';

interface BioDetailsCardProps {
  bio: string;
  loading?: boolean;
}

export default function BioDetailsCard({ bio, loading }: BioDetailsCardProps) {
  return (
    <DetailsCard title={`${Translations.TELEGRAM} ${Translations.BIO}`}>
      {loading ? <SinglePlaceholder /> : <Typography sx={styles.bio}>{`${bio}`}</Typography>}
    </DetailsCard>
  );
}

const styles = {
  bio: {
    color: `${colors.neutral['70']}`,
    fontSize: '14px',
    fontFamily: fonts.regular,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    lineHeight: '18px',
    letterSpacing: '-0.14px',
    padding: `8px 16px`,
  },
};
