export const BUTTON_COLORS = {
  solid: {
    primary: {
      border: '#141618',
      text: '#FFF',
      background: '#141618',
      hover: {
        border: '#0F1012',
        text: '#FFF',
        background: '#0F1012',
      },
    },
    warning: {
      border: '#DB2042',
      text: '#FFF',
      background: '#DB2042',
      hover: {
        border: '',
        text: '',
        background: '',
      },
    },
  },
  soft: {
    primary: {
      border: '#E7EBEF',
      text: '#545960',
      background: '#F3F5F7',
      hover: {
        border: '#DDE3E7',
        text: '#363A3F',
        background: '#F1F4F6',
      },
    },
    info: {
      border: '#DBECFD',
      text: '#247AE5',
      background: '#F8FCFF',
      hover: {
        border: '#BDDEFB',
        text: '#1762D4',
        background: '#DBECFD',
      },
    },
  },
  plain: {
    primary: {
      border: 'transparent',
      text: '#797F87',
      background: 'transparent',
      hover: {
        border: 'transparent',
        text: '#545960',
        background: 'transparent',
      },
    },
    warning: {
      border: 'transparent',
      text: '#DB2042',
      background: 'transparent',
      hover: {
        border: 'transparent',
        text: '#DB2042',
        background: 'transparent',
      },
    },
  },
  outlined: {
    primary: {
      border: '#E7EBEF',
      text: '#797F87',
      background: 'transparent',
      hover: {
        border: '#DDE3E7',
        text: '#545960',
        background: 'transparent',
      },
    },
  },
};
