import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPropertiesSelectorOpen } from 'services/store/slices/conversationsSlice';

export const EDIT_NAVS = {
  MENU: 'MENU',
  EDIT: 'EDIT',
};

export const useMenuNavigation = () => {
  const [nav, setNav] = useState(EDIT_NAVS.MENU);
  const dispatch = useDispatch();

  const setPropertiesSelectorState = (open: boolean) => {
    dispatch(setPropertiesSelectorOpen(open));
  };

  const handleEditClick = () => {
    setNav(EDIT_NAVS.EDIT);
  };

  const handleBackClick = () => {
    setNav(EDIT_NAVS.MENU);
  };

  const isEditNav = nav === EDIT_NAVS.EDIT;

  return {
    handleEditClick,
    handleBackClick,
    isEditNav,
    setPropertiesSelectorState,
  };
};
