import { DynamicUserProperty, UserPropertyValueType } from 'services/models/api/generated';

interface Props {
  props?: DynamicUserProperty;
}

export default function parseStateProperty({ props }: Props): any {
  if (props) {
    const { value, propertyValueType } = props;

    if (propertyValueType === UserPropertyValueType.String) {
      return value;
    }

    if (propertyValueType === UserPropertyValueType.Number) {
      return Number(value);
    }

    if (propertyValueType === UserPropertyValueType.Boolean) {
      return JSON.parse(value);
    }

    return value;
  }
  return null;
}
