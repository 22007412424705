import { useEffect, useMemo, useRef } from 'react';
import { Box, Divider } from '@mui/joy';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

import Translations from 'const/translations/en';

import {
  setPropertyValues,
  chatPropertyValueEdit,
  addPropertyValue,
  setOidByTempId,
  StateOptionPropertyValue,
} from 'services/store/slices/conversationPropertiesSlice';
import { EditPropertiesProps } from 'components/Chats/ChatTableV2/EditProperty/interface';
import { ContainerBoxStyles, DividerSx, PropertiesBoxStyles } from 'components/Chats/ChatTableV2/EditProperty/styles';
import CellMenuButton from 'components/ui-library/chat/CellMenu/СellMenuButton/CellMenuButton';
import EditPropertyOptionItem from 'components/Chats/ChatTableV2/EditProperty/EditPropertyOptionItem/EditPropertyOptionItem';
import { useUpdatePropertyOptionOrder } from 'services/hooks/useUpdatePropertyOptionOrder';
import { DEFAULT_NEW_OPTION_FIELDS } from 'components/Chats/ChatTableV2/constants';
import {
  OptionPropertyValue,
  useAddPropertyOptionValueMutation,
  AddPropertyOptionValueInput,
} from 'services/models/api/generated';
import { updatePropertyValues } from 'services/store/slices/entityViewsSlice';
import { updateConversationProperties } from 'services/store/slices/conversationsSlice';
import { PropertyValue } from 'services/models/domain/entityViews';
import _ from 'lodash';

const propertyOptionsSorter = (opt1: StateOptionPropertyValue, opt2: StateOptionPropertyValue) =>
  opt1.option.ordering - opt2.option.ordering;
const EditProperty = ({ handleBackClick, property, refetch }: EditPropertiesProps) => {
  const definitionValues = property.values as OptionPropertyValue[];
  const [addPropertyOption] = useAddPropertyOptionValueMutation();
  const { handleDragEnd } = useUpdatePropertyOptionOrder(refetch, property);
  const dispatch = useDispatch();
  const propertyOptions = useSelector(chatPropertyValueEdit);
  const orderedPropertyOptions = useMemo(() => [...propertyOptions].sort(propertyOptionsSorter), [propertyOptions]);
  const orderedPropertyOptionsRef = useRef<StateOptionPropertyValue[]>();
  orderedPropertyOptionsRef.current = orderedPropertyOptions;
  useEffect(() => {
    dispatch(setPropertyValues(definitionValues));
    // Only sync updated values when closing(unmounting) the edit modal
    return () => {
      const propertyValues = orderedPropertyOptionsRef.current?.map((po) => po.option) as PropertyValue[];
      const isValuesUpdated = !_.isEqual(propertyValues, definitionValues);
      if (propertyValues.length > 0 && isValuesUpdated) {
        dispatch(
          updateConversationProperties({
            propertyValues: propertyValues,
          })
        );
        dispatch(
          updatePropertyValues({
            propertyValues: propertyValues,
            propertyDefinitionOid: property.oid,
          })
        );
        dispatch(setPropertyValues([]));
      }
    };
  }, []);
  const handleAdd = async () => {
    const tempId = `${Date.now()}`;
    dispatch(addPropertyValue({ propertyDefinitionOid: property.oid, tempId }));
    const input: AddPropertyOptionValueInput = {
      ...DEFAULT_NEW_OPTION_FIELDS,
      ordering: propertyOptions.length + 1,
      propertyDefinitionOid: property.oid.oid,
    };
    await addPropertyOption({
      variables: { input },
      onCompleted: (res) => {
        const isSuccess = res?.addPropertyOptionValue.status;
        if (isSuccess) {
          const oid = res.addPropertyOptionValue.data.addedOid;
          dispatch(setOidByTempId({ tempId, oid }));
          refetch();
        }
      },
    });
  };

  return (
    <Box sx={ContainerBoxStyles}>
      {handleBackClick && (
        <CellMenuButton
          title={Translations.CHAT_TABLE_PROPERTY_EDIT_BACK}
          icon="ic_arrow_left"
          onClick={handleBackClick}
        />
      )}
      <Divider sx={DividerSx} />
      <DragDropContext onDragEnd={handleDragEnd(orderedPropertyOptions.map((o: StateOptionPropertyValue) => o.option))}>
        <Droppable droppableId="propertyOptions">
          {(droppableProvider) => (
            <Box sx={PropertiesBoxStyles} ref={droppableProvider.innerRef} {...droppableProvider.droppableProps}>
              {orderedPropertyOptions.map((optionValue) => (
                <Draggable
                  index={optionValue.option.ordering}
                  key={optionValue.newValueTempId || optionValue.option.oid.oid}
                  draggableId={`${optionValue.newValueTempId || optionValue.option.oid.oid}`}
                >
                  {(draggableProvider) => (
                    <Box
                      ref={draggableProvider.innerRef}
                      {...draggableProvider.draggableProps}
                      {...draggableProvider.dragHandleProps}
                    >
                      <EditPropertyOptionItem
                        // Prefer temporary Id for new added values
                        // to match the React key and keep AddEditPropertyValue open
                        key={optionValue.newValueTempId || optionValue.option.oid.oid}
                        editingOptionValue={optionValue}
                        refetch={refetch}
                      />
                    </Box>
                  )}
                </Draggable>
              ))}

              {droppableProvider.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <Divider sx={DividerSx} />
      <CellMenuButton title={Translations.CHAT_TABLE_PROPERTY_ADD} icon="ic_plus" onClick={handleAdd} />
    </Box>
  );
};

export default EditProperty;
