import { useSendBotMessageLazyQuery } from 'services/models/api/generated';

export const useChatBot = () => {
  const [sendBotMessageLazyQuery, { data, loading }] = useSendBotMessageLazyQuery({
    initialFetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    fetchPolicy: 'network-only',
  });

  const sendBotMessage = async (message: string) => {
    const { data } = await sendBotMessageLazyQuery({
      variables: {
        input: {
          message,
        },
      },
    });
    return data?.sendBotMessage?.data?.status;
  };

  return { sendBotMessage, data, loading };
};
