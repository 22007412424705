import { Box } from '@mui/joy';
import { Backdrop } from '@mui/material';
import { TableCell } from 'components/Chats/ChatTableV2/TableCell/TableCell';
import { ChatTableProps } from 'components/Chats/ChatTableV2/interface';
import { ChatTableBoxStyles, ChatTableStyles } from 'components/Chats/ChatTableV2/styles';
import UITable from 'components/ui-library/Table/UITable';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ChatPlaceholder from 'page/chats/placeholder/ChatPlaceholder';
import { BackdropStyle } from 'page/modal/styles';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FeatureFlags } from 'services/models/domain/featureFlags';
import { authState } from 'services/store/slices/authSlice';
import { generateColumns, generateRows } from 'services/utils/generateConversationTableDataUtils';

function CustomNoRowsOverlay() {
  return null;
}

export type CloseHandler = (e: React.MouseEvent<HTMLElement>) => void;

const ChatTable = ({
  loading,
  conversations,
  properties,
  entityViewFields,
  channelProperty,
  refetchConversations,
  refetchEntityView,
  metadata,
  onUnmount,
  openSidebar,
}: ChatTableProps) => {
  const { user } = useSelector(authState);
  const [onCloseHandler, setOnCloseHandler] = useState<CloseHandler | undefined>(undefined);
  const handleBackdropClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (onCloseHandler) {
      onCloseHandler(e);
    }
    setOnCloseHandler(undefined);
  };
  const flags = useFlags<FeatureFlags>();
  const batchRefetch = useCallback(async () => {
    await Promise.all([refetchEntityView(), refetchConversations()]);
  }, [refetchEntityView, refetchConversations]);
  const columns = useMemo(
    () => generateColumns(entityViewFields, properties, batchRefetch, flags),
    [properties, batchRefetch, entityViewFields]
  );
  const [isOpen, setIsOpen] = useState(false);
  const rows = useMemo(() => {
    return generateRows(
      conversations,
      (closeHandler) => {
        setOnCloseHandler(() => closeHandler);
        const hasHandler = !!closeHandler;
        setIsOpen(hasHandler);
      },
      properties,
      batchRefetch,
      refetchConversations,
      openSidebar,
      flags,
      user?.actingAsMemberOfOrganization.listOfUsersInOrganization || [],
      channelProperty
    );
  }, [conversations, onCloseHandler, properties, batchRefetch, refetchConversations, user]);

  return (
    <Box sx={ChatTableBoxStyles(conversations.length > 0 || loading)}>
      <UITable
        sx={ChatTableStyles(conversations.length > 0 || loading)}
        columns={columns}
        rows={rows}
        hideFooter={true}
        loading={loading}
        sortingMode="server"
        slots={{
          loadingOverlay: ChatPlaceholder,
          cell: TableCell,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        onUnmount={onUnmount}
        offsetY={metadata?.scrollPositionY}
        flags={flags}
      />
      <Backdrop open={isOpen} sx={BackdropStyle} onClick={(e) => handleBackdropClick(e)} />
    </Box>
  );
};

export default ChatTable;
