import { useState } from 'react';
import { RouterPaths } from 'services/hooks/useNavigation';
import { EntityDetailsType } from 'services/store/slices/sidebarSlice';

export function useCopyLink() {
  const [linkCopied, setLinkCopied] = useState(false);

  const copyLink = (type: EntityDetailsType, oid: string | undefined) => {
    let pathname: string;
    switch (type) {
      case EntityDetailsType.chatDetails:
        pathname = RouterPaths.createDetailsChatPath(oid ?? '');
        break;
      case EntityDetailsType.contactDetails:
        pathname = RouterPaths.createDetailsContactPath(oid ?? '');
        break;
      default:
        pathname = '';
    }

    const currentUrl = new URL(window.location.href);
    const baseUrl = `${currentUrl.protocol}//${currentUrl.host}`;
    const modifiedUrl = `${baseUrl}${pathname}`;
    navigator.clipboard.writeText(modifiedUrl);
    setLinkCopied(true);
  };

  return {
    linkCopied,
    copyLink,
  };
}
