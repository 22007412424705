import { styled } from '@mui/joy/styles';
import { SxProps } from '@mui/material';
import { GridCell } from '@mui/x-data-grid-pro';

export const StyledFavoriteCell = styled(GridCell)(() => ({
  padding: '6px 0px 6px 16px !important',
  borderRight: 'none !important',
}));

export const StyledEmptyCell = styled(GridCell)(() => ({
  padding: '6px 0px 6px 16px !important',
  borderRight: 'none !important',
}));

export const FlexColumn: SxProps = {
  display: 'flex',
  flexDirection: 'column',
};

export const FlexRow: SxProps = {
  display: 'flex',
  flexDirection: 'row',
};

export const CellText: SxProps = {
  color: 'secondary.dark',
  fontSize: '14px',
  lineHeight: '18px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

export const CellSecondText: SxProps = {
  ...CellText,
  color: 'info.main',
};
