import { GridCellProps } from '@mui/x-data-grid-pro';
import { GridCellEditable } from 'components/Chats/ChatTableV2/TableCell/styles';
import NotesCellContent from 'components/Chats/ChatTableV2/TableCell/customCells/cellsContent/Notes/NotesCellContent';

export default function NotesCell(props: GridCellProps) {
  const { value, ...rest } = props;
  const { notes, entityType, entityOid, id, setOnCloseHandler } = value;

  return (
    <GridCellEditable {...rest}>
      <NotesCellContent
        id={id}
        notes={notes}
        entityType={entityType}
        entityOid={entityOid}
        setOnCloseHandler={setOnCloseHandler}
      />
    </GridCellEditable>
  );
}
