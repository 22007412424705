import React from 'react';
import 'components/ui-library/Spinner/styles.css';

function Spinner({
  children,
  spinnerBackground,
}: {
  children?: React.ReactNode | undefined;
  spinnerBackground?: string | undefined;
}) {
  const background = spinnerBackground ? spinnerBackground : '#fff';
  const style = { background: background };
  return (
    <div className="center">
      <div>{children ? children : null}</div>
      <div className="spinner">
        <div className="bar1" style={style}></div>
        <div className="bar2" style={style}></div>
        <div className="bar3" style={style}></div>
        <div className="bar4" style={style}></div>
        <div className="bar5" style={style}></div>
        <div className="bar6" style={style}></div>
        <div className="bar7" style={style}></div>
        <div className="bar8" style={style}></div>
        <div className="bar9" style={style}></div>
      </div>
    </div>
  );
}

export default Spinner;
