import { styled } from '@mui/joy/styles';

import UIButton from 'components/ui-library/Button/UIButton';
import { colors } from 'theme/colors';

export const UIButtonStyled = styled(UIButton)(({ disabled }: { disabled: Boolean }) => ({
  '& p': {
    color: disabled ? colors.neutral['40'] : colors.white,
  },
}));
