import { useState } from 'react';
import { Box, List, ListItem, Typography } from '@mui/joy';
import CustomAvatar from 'components/CustomAvatar';
import DetailsCard from 'components/Details/DetailsCard';
import dayjs from 'dayjs';
import { format } from 'date-fns';
import FollowupPlaceholder from 'page/details/placeholder/FollowupPlaceholder';
import { emptyFollowUpStateStyle, followUpContainerStyle } from 'page/details/styles';
import { Followup } from 'services/models/domain/followUps';
import { avatarFromName, generateColorFromOid } from 'services/utils/helpers';
import Icons from 'assets/icons';
import FollowupActions from 'components/Details/FollowupComponent/FollowupActions';
import { useFollowups } from 'services/hooks/useFollowups';
import { Conversation } from 'services/models/domain/conversation';
import Translations from 'const/translations/en';

interface FollowupDetailsCardProps {
  conversation: Conversation;
  followUp: Followup | undefined;
  loading: boolean;
}

export default function FollowupDetailsCard({ conversation, followUp, loading }: FollowupDetailsCardProps) {
  const [isActionsModalOpen, setActionsModalOpen] = useState(false);

  const dateJs = dayjs(followUp?.dueAt.utcTimeStampAsString);
  const assignee = followUp?.assignedToUser;
  const { updateFollowup } = useFollowups(conversation);

  return (
    <DetailsCard title={Translations.FOLLOWUP_REMINDER}>
      {loading && <FollowupPlaceholder />}
      {!loading && (
        <List sx={{ '--List-decorator-size': '48px', p: 0, m: 0 }}>
          <Box sx={followUpContainerStyle}>
            {followUp && (
              <ListItem>
                <CustomAvatar
                  userInitials={avatarFromName(assignee?.fullName ?? assignee?.firstName ?? '')}
                  size={20}
                  index={generateColorFromOid(assignee?.oid ?? '')}
                  imgUrl={assignee?.profileImageUrl}
                  sx={{ mr: '6px' }}
                />
                {
                  <Typography level="tiny" sx={{ color: 'secondary.main' }}>
                    {assignee?.fullName}{' '}
                    <Typography sx={{ color: 'info.main', ml: 0.5 }}>
                      {dateJs ? `(${format(dateJs?.toDate()!, 'MMM dd')})` : ''}
                    </Typography>
                  </Typography>
                }
              </ListItem>
            )}
            {!followUp && (
              <ListItem sx={emptyFollowUpStateStyle} onClick={() => setActionsModalOpen(true)}>
                <img src={Icons.ic_calendar_plus} alt="plus" height={14} width={14} />
                <Typography level="tiny" sx={{ color: 'info.dark' }}>
                  {Translations.ADD_FOLLOWUP_REMINDER}
                </Typography>
              </ListItem>
            )}
            <FollowupActions
              className="showActions"
              onFollowUpUpdated={updateFollowup}
              conversation={conversation!}
              open={isActionsModalOpen}
              handlePopupOpen={() => setActionsModalOpen(true)}
              handlePopupClose={() => setActionsModalOpen(false)}
            />
          </Box>
        </List>
      )}
    </DetailsCard>
  );
}
