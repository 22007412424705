import { Typography, Box } from '@mui/joy';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { TimeAgo } from 'services/utils/helpers';
import { CellText, CellSecondText } from 'components/Chats/ChatTableV2/TableCell/customCells/cellsContent/styles';
import { LatestMessageCellContentProps } from 'components/Chats/ChatTableV2/TableCell/customCells/cellsContent/LatestMessage/interface';

export default function LatestMessageCellContent({
  lastestMessageTime,
  lastMessageSenderParticipantOid,
  participants,
}: LatestMessageCellContentProps) {
  const flags = useFlags();

  const lastMessageSender = participants.find((p) => p.oid.oid === lastMessageSenderParticipantOid);
  const senderName = lastMessageSender?.name ?? lastMessageSender?.firstName;

  return (
    <Box>
      {flags.lastMessageSender ? (
        <>
          <Typography level="tiny" sx={CellText}>
            {senderName}
          </Typography>
          <Typography level="tiny" sx={CellSecondText}>
            {lastestMessageTime && TimeAgo({ toDate: lastestMessageTime })}
          </Typography>
        </>
      ) : (
        <>
          <Typography level="tiny" sx={CellText}>
            {lastestMessageTime && TimeAgo({ toDate: lastestMessageTime })?.split(',')[1]}
          </Typography>
          <Typography level="tiny" sx={CellSecondText}>
            {lastestMessageTime ? TimeAgo({ toDate: lastestMessageTime }).split(',')[0].trimStart() : '_'}
          </Typography>
        </>
      )}
    </Box>
  );
}
