import { Typography } from '@mui/joy';
import { GridCellProps, GridCell } from '@mui/x-data-grid-pro';

import NameCell from 'components/Chats/ChatTableV2/TableCell/customCells/Name/NameCell';
import FavoriteCell from 'components/Chats/ChatTableV2/TableCell/customCells/Favorite/FavoriteCell';
import LatestMessageCell from 'components/Chats/ChatTableV2/TableCell/customCells/LatestMessage/LatestMessageCell';
import NotesCell from 'components/Chats/ChatTableV2/TableCell/customCells/Notes/NotesCell';
import FollowUpCell from 'components/Chats/ChatTableV2/TableCell/customCells/FollowUp/FollowUpCell';

import { CHATS_DEFAULT_COLUMN_NAMES } from 'components/Chats/ChatTableV2/constants';
import { CellText, StyledEmptyCell } from 'components/Chats/ChatTableV2/TableCell/customCells/styles';
import { GridCellEditable } from 'components/Chats/ChatTableV2/TableCell/styles';

import { CONTACTS_STATIC_COLUMN_NAMES } from 'page/contacts/constants';
import ContactCell from 'components/TableCells/ContactCell/ContactCell';
import AssociatedChatsCell from 'components/TableCells/AssociatedConversations/AssociatedChatsCell';
import BioCell from 'components/TableCells/Bio/BioCell';
import { FolderCell } from 'components/Chats/ChatTableV2/TableCell/customCells/Folder/FolderCell';
import { Folder } from 'services/models/domain/folder';
import { DateAddedCell } from 'components/Chats/ChatTableV2/TableCell/customCells/DateAdded/DateAddedCell';
import PropertyTypeAhead from 'components/Chats/ChatTableV2/TableCell/customCells/PropertyTypeahead/PropertyTypeahead';
import MultiSelectCell from './customCells/MultiSelect/MultiSelectCell';
import LastModifiedCell from './customCells/LastModified/LastModifiedCell';
import { ValueProperty } from './customCells/ValueProperty/ValueProperty';
import { PropertyDefinitionDataType } from 'services/models/domain/entityViews';

// TODO (AWE): Fill with complete type
export type TableCellProps = {
  folder: Folder[];
} & any;

export const TableCell = (props: GridCellProps<TableCellProps>) => {
  const { value, field } = props;
  const isCustomProperty = typeof value === 'object' && value.customProperty;
  const COLUMN_NAMES = { ...CHATS_DEFAULT_COLUMN_NAMES, ...CONTACTS_STATIC_COLUMN_NAMES };

  if (!isCustomProperty) {
    switch (field) {
      case COLUMN_NAMES.Favorite:
        return <FavoriteCell {...props} />;
      case COLUMN_NAMES.Name:
        return <NameCell {...props} />;
      case COLUMN_NAMES.CONTACT:
        return <ContactCell {...props} />;
      case COLUMN_NAMES.ASSOCIATED_CHATS:
        return <AssociatedChatsCell {...props} />;
      case COLUMN_NAMES.LatestMessage:
        return <LatestMessageCell {...props} />;
      case COLUMN_NAMES.LastModified:
        return <LastModifiedCell {...props} />;
      case COLUMN_NAMES.Folder:
        return <FolderCell {...props} folder={value.folder} />;
      case COLUMN_NAMES.DateAdded:
        return <DateAddedCell {...props} />;
      case COLUMN_NAMES.Note:
        return <NotesCell {...props} />;
      case COLUMN_NAMES.NOTES:
        return <NotesCell {...props} />;
      case COLUMN_NAMES.FollowUp:
        return <FollowUpCell {...props} />;
      case COLUMN_NAMES.BIO:
        return <BioCell {...props} />;
      case COLUMN_NAMES.EMPTY:
        return <StyledEmptyCell {...props} />;
      case COLUMN_NAMES.VisibilityControl:
        return <StyledEmptyCell {...props} />;
      case COLUMN_NAMES.SelectionColumn:
        return (
          <MultiSelectCell
            {...props}
            conversation={props.conversation}
            updatedSelectedConversation={props.updatedSelectedConversation}
          />
        );
      default:
        return (
          <GridCell>
            <Typography level="tiny" sx={CellText}>
              {`${props.value || ''}`}
            </Typography>
          </GridCell>
        );
    }
  }

  const { value: displayValue, propertyDefinition, entityId } = value;
  switch (propertyDefinition.dataType) {
    case PropertyDefinitionDataType.Option:
    case PropertyDefinitionDataType.TenantUser:
      return (
        <GridCellEditable {...props}>
          <PropertyTypeAhead values={displayValue} entityId={entityId} propertyDefinition={propertyDefinition} />
        </GridCellEditable>
      );
    case PropertyDefinitionDataType.Value:
      return (
        <GridCellEditable {...props}>
          <ValueProperty
            propertyDefinition={propertyDefinition}
            propertyValue={{
              propertyDefinitionOid: propertyDefinition.oid,
              type: PropertyDefinitionDataType.Value,
              value: displayValue[0],
            }}
            entityId={entityId}
          />
        </GridCellEditable>
      );
    default:
      return (
        <GridCell {...props}>
          <Typography level="tiny" sx={CellText}>
            {`${value.value || `Select a ${props.field}`}`}
          </Typography>
        </GridCell>
      );
  }
};
