import { useSelector } from 'react-redux';
import useAuth from 'services/hooks/useAuth';
import { ConversationParticipant, ConversationType } from 'services/models/domain/conversation';
import { RootState } from 'services/store/AppStore';
import { authState } from 'services/store/slices/authSlice';

export function useTelegramChatLink(
  link: string | undefined,
  type: string | undefined,
  participants: ConversationParticipant[]
) {
  const { user } = useSelector(authState);
  // Below logic is to temporarily show the logged-in users' 1:1 chat links on the frontend
  // until we have the backend to prepare and send them which would be a more proper implementation
  let telegramChatLink: string | undefined = link;

  if (type === ConversationType.Individual) {
    const userIndex = participants.map((p) => p.userOid?.oid).findIndex((oid) => oid === user?.oid);
    const isUserChatParticipant = userIndex >= 0;
    if (isUserChatParticipant) {
      telegramChatLink = `https://web.telegram.org/a/#${participants[userIndex === 0 ? 1 : 0]?.internalParticipantId}`;
    }
  }
  return { telegramChatLink };
}
