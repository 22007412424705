import { useState } from 'react';
import { Box, Modal, ModalDialog, Stack, Tooltip, Typography } from '@mui/joy';
import useResponsive from 'services/hooks/useResponsive';
import {
  CellSecondText,
  CellText,
  EmptyCellText,
  LastNoteCreatorStyled,
  NoteCreatorDividerStyled,
  NotesBoxStyles,
  NotesCellBox,
  NotesInnerBoxStyles,
  NotesTooltipStyles,
} from 'components/Chats/ChatTableV2/TableCell/customCells/cellsContent/styles';
import NotesList from 'page/modal/NotesModal/NotesList';
import { NotesTime } from 'services/utils/helpers';
import Icons from 'assets/icons';
import { NotesCellContentProps } from 'components/Chats/ChatTableV2/TableCell/customCells/cellsContent/Notes/interface';
import { descendantToHtml, stringToDescendant } from '@3rm-co/ui-library';
import parse from 'html-react-parser';
import { colors } from 'theme/colors';
import { ModalStyle } from 'page/modal/styles';

export default function NotesCellContent({
  notes,
  id,
  entityOid,
  entityType,
  setOnCloseHandler,
}: NotesCellContentProps) {
  const isDesktop = useResponsive('up', 'sm');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const [stayOpen, setStayOpen] = useState(false);

  const handlePopUpOpen = (event: React.MouseEvent<HTMLElement>) => {
    // event.stopPropagation();
    setStayOpen(true);
    setOnCloseHandler((e) => handlePopUpClose(e));
    setAnchorEl(event.currentTarget);
  };

  const handleMobilePopUpOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setStayOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePopUpClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setStayOpen(false);
    setOnCloseHandler(undefined);
    setAnchorEl(null);
  };

  const lastNoteUser = notes[0]?.user;
  const lastNoteCreatedAt = notes[0]?.createdAt.utcTimeStampAsString;

  const noteInDescendant = stringToDescendant(notes[0]?.text ?? '');
  const lastNoteText = parse(`<div class="rte-text">${descendantToHtml({ children: noteInDescendant })}</div>`);
  const hasNotes = notes.length > 0;

  return (
    <Box onClick={isDesktop ? handlePopUpOpen : handleMobilePopUpOpen} sx={NotesCellBox}>
      {isDesktop ? (
        <Tooltip
          open={open || stayOpen}
          variant="plain"
          title={
            <NotesList
              id={id}
              entityType={entityType}
              entityOid={entityOid}
              handleClose={handlePopUpClose}
              notes={notes}
            />
          }
          placement="left"
          arrow
          componentsProps={NotesTooltipStyles}
        >
          <Box sx={NotesBoxStyles}>
            {hasNotes && (
              <>
                <Box sx={NotesInnerBoxStyles}>
                  <Box sx={CellText}>{lastNoteText}</Box>
                  <LastNoteCreatorStyled>
                    {lastNoteUser?.fullName && (
                      <Typography level="micro" sx={CellSecondText}>
                        {lastNoteUser?.fullName}
                      </Typography>
                    )}
                    {lastNoteUser?.fullName && <NoteCreatorDividerStyled />}
                    <Typography level="tiny" sx={CellSecondText}>
                      {lastNoteCreatedAt ? NotesTime({ toDate: lastNoteCreatedAt }) : ''}
                    </Typography>
                  </LastNoteCreatorStyled>
                </Box>
                <Typography level="tiny" sx={{ color: colors.neutral[50], flexShrink: 0 }}>
                  {notes.length > 1 ? '+' + (notes.length - 1).toString() : ''}
                </Typography>
              </>
            )}
            {!hasNotes && (
              <Stack direction="row" spacing={1} id={'notes-hover-message'} sx={{ display: 'none' }}>
                <img src={Icons.ic_add_notes} width={16} height={16} alt="" />
                <Typography sx={EmptyCellText} level="tiny">
                  Add a note
                </Typography>
              </Stack>
            )}
          </Box>
        </Tooltip>
      ) : (
        <Box sx={NotesBoxStyles}>
          <Typography level="tiny" sx={CellText}>
            {hasNotes ? notes[0].text : '_'}{' '}
          </Typography>
          {notes.length > 1 ? (
            <Typography level="tiny" sx={CellSecondText}>
              + {notes.length - 1} others
            </Typography>
          ) : null}
        </Box>
      )}

      {!isDesktop && (
        <Modal open={open || stayOpen} onClose={handlePopUpClose}>
          <ModalDialog sx={ModalStyle}>
            <NotesList
              id={id}
              entityType={entityType}
              entityOid={entityOid}
              handleClose={handlePopUpClose}
              notes={notes}
            />
          </ModalDialog>
        </Modal>
      )}
    </Box>
  );
}
