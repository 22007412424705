import React, { createContext, useReducer, useCallback, useMemo, useEffect, useContext } from 'react';
import TgConnectModal from 'page/modal/TgConnectModal/tgConnectModal';
import useAuth from 'services/hooks/useAuth';
import useTgSyncContext from 'services/hooks/useTgSync';
import { ConsumableEvents, useEventBusContext } from 'contexts/EventBusContext';
import * as tgSync from 'services/store/slices/userTgSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'services/store/AppStore';

export type TgConnectModalState = {
  isModalOpen: boolean;
};

interface TgConnectModalContextValue {
  toggleTgConnectModal: (toggle: boolean) => void;
  isModalOpen: boolean;
}

interface TgConnectModalProviderProps {
  children: React.ReactNode;
}

const initialState: TgConnectModalState = {
  isModalOpen: false,
};

const HANDLERS = {
  TOGGLE: 'TOGGLE',
} as const;

type ActionPayload = {
  [key in keyof TgConnectModalState]: TgConnectModalState[key];
};

type Action = {
  type: keyof typeof HANDLERS;
  payload: ActionPayload;
};

export const reducer = (state: TgConnectModalState, action: Action) => {
  switch (action.type) {
    case HANDLERS.TOGGLE:
      return {
        ...state,
        isModalOpen: action.payload.isModalOpen,
      };
    default:
      return state;
  }
};

const TgConnectModalContext = createContext<TgConnectModalContextValue>({
  ...initialState,
  toggleTgConnectModal: (isOpen: boolean) => {},
});

export const TgConnectModalProvider: React.FC<TgConnectModalProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchAction = useDispatch();
  const { fetchUser } = useAuth();
  const user = useSelector((state: RootState) => state.auth.user);
  const { tgSynced } = useTgSyncContext();
  const { initialFolders } = useSelector(tgSync.userTgState);

  const { onEventReceived } = useEventBusContext();

  const toggleTgConnectModal = useCallback(
    (isOpen: boolean) => {
      dispatch({
        type: 'TOGGLE',
        payload: {
          isModalOpen: isOpen,
        },
      });
    },
    [dispatch]
  );
  // ? Why do we need all these
  useEffect(() => {
    async function fetchUserData() {
      await fetchUser();
    }
    fetchUserData();
    if (user?.chatBotLinkInfo?.isLinked) toggleTgConnectModal(false);

    onEventReceived((event: ConsumableEvents) => {
      const message = event.message;
      if ('userOid' in message && message.userOid.oid === user?.oid) {
        fetchUserData();
      }
    });
  }, []);

  const stateMemo = useMemo(
    () => ({
      ...state,
      toggleTgConnectModal,
      tgSynced,
    }),
    [state, toggleTgConnectModal, tgSynced]
  );

  return (
    <TgConnectModalContext.Provider value={stateMemo}>
      {children}
      <TgConnectModal
        open={state.isModalOpen}
        onClose={() => {
          dispatchAction(tgSync.setFolders(initialFolders));
          toggleTgConnectModal(false);
        }}
      />
    </TgConnectModalContext.Provider>
  );
};

export const useTgConnectModalControl = () => {
  const context = useContext(TgConnectModalContext);
  if (!context) {
    throw new Error('useTgConnectModalControl must be used within a TgConnectModalProvider');
  }
  return context;
};
