import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'services/store/AppStore';
import * as domain from 'services/models/domain/entityViews';
import { removePropertiesFromConversation, updateConversations } from 'services/utils/conversationUtils';
import { Conversation } from 'services/models/domain/conversation';

export interface ConversationsDetailsPayload {
  conversations: Conversation[];
}

export type UpdatePaginationPayload = {
  propertyValueOid?: string;
  total?: number;
};

export type AddPropertyToConversationPayload = {
  conversationOid: string;
  propertyValue?: domain.PropertyValue;
};

export type RemovePropertyFromConversationPayload = {
  conversationOid: string;
  propertyValue: domain.PropertyValue;
};

export type UpdatePropertiesPayload = {
  propertyValues: domain.PropertyValue[];
};

export type AddConversationsPayload = {
  conversations: Conversation[];
};

export interface ConversationsState {
  conversations: Conversation[] | [];
  loading: boolean;
  error: string | null;
  // TODO (AWE): Check for alternatives
  paginationInfos: { [id: string]: PaginationInfo };
}

export type PaginationInfo = {
  total: number;
};

const initialState: ConversationsState = {
  conversations: [],
  loading: true,
  error: null,
  paginationInfos: {},
};

const pipelineConversations = createSlice({
  name: 'pipelineConversations',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
      if (action.payload) {
        state.error = null;
        state.conversations = [];
      }
    },
    setConversations: (state, action: PayloadAction<ConversationsDetailsPayload>) => {
      state.conversations = action.payload.conversations;
    },
    updatePagination: (state, action: PayloadAction<UpdatePaginationPayload>) => {
      if (action.payload.total !== undefined) {
        state.paginationInfos[action.payload.propertyValueOid || 'undefined'] = {
          total: action.payload.total,
        };
      } else {
        delete state.paginationInfos[action.payload.propertyValueOid || 'undefined'];
      }
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    updateConversation: (state, action: PayloadAction<Conversation>) => {
      const index = state.conversations.findIndex((conversation) => conversation.oid.oid === action.payload.oid.oid);
      if (index !== -1) {
        state.conversations[index] = {
          ...state.conversations[index],
          notes: [...(action.payload.notes ?? [])],
          followups: [...(action.payload.followups ?? [])],
          lastMessageAt: action.payload.lastMessageAt,
          favorit: action.payload.favorit,
          nextFollowup: action.payload.nextFollowup,
          participants: [...(action.payload.participants ?? [])],
          properties: [...(action.payload.properties ?? [])],
        };
      }
    },
    addConversations: (state, action: PayloadAction<AddConversationsPayload>) => {
      state.conversations = updateConversations(state.conversations, action.payload.conversations);
    },
    updateConversationProperties: (state, action: PayloadAction<UpdatePropertiesPayload>) => {
      const propertiesMap = new Map<string, domain.PropertyValue>();
      action.payload.propertyValues.forEach((propertyValue) => {
        if (propertyValue && propertyValue.type === 'OPTION') {
          propertiesMap.set(propertyValue.oid.oid, propertyValue);
        }
      });
      state.conversations = state.conversations.map((conversation, index) => {
        // @ts-ignore
        const properties: domain.PropertyValue[] = conversation.properties.map((prop) => {
          if (prop && prop.type === 'OPTION') {
            const updatedProperty = propertiesMap.get(`${prop.oid.oid}`);
            if (updatedProperty) {
              return updatedProperty;
            }
          }
          return prop;
        });
        return {
          ...conversation,
          properties: properties,
        };
      });
    },
    addPropertyForConversation: (state, action: PayloadAction<AddPropertyToConversationPayload>) => {
      const index = state.conversations.findIndex((c) => c.oid.oid === action.payload.conversationOid);
      if (index >= 0) {
        let propertyValueOid = undefined;
        if (action.payload.propertyValue && 'oid' in action.payload.propertyValue) {
          propertyValueOid = action.payload.propertyValue.oid.oid;
        }
        if (action.payload.propertyValue) {
          const propertyValue = action.payload.propertyValue;
          if (propertyValue) {
            const newProperties = [...state.conversations[index].properties, propertyValue];
            state.conversations[index] = {
              ...state.conversations[index],
              properties: newProperties,
            };
          }
        }
        const pagination = state.paginationInfos[propertyValueOid || 'undefined'];
        if (pagination) {
          state.paginationInfos[propertyValueOid || 'undefined'] = {
            total: pagination.total + 1,
          };
        }
      }
    },
    removePropertyForConversation: (state, action: PayloadAction<RemovePropertyFromConversationPayload>) => {
      const index = state.conversations.findIndex((c) => c.oid.oid === action.payload.conversationOid);
      if (index >= 0) {
        const newProperties = removePropertiesFromConversation(
          state.conversations[index],
          action.payload.propertyValue
        );
        state.conversations[index] = {
          ...state.conversations[index],
          properties: newProperties,
        };
        const propertyValueOid =
          action.payload.propertyValue.type === domain.PropertyDefinitionDataType.Value
            ? action.payload.propertyValue.propertyDefinitionOid.oid
            : action.payload.propertyValue.oid.oid;
        const pagination = state.paginationInfos[propertyValueOid || 'undefined'];
        if (pagination) {
          state.paginationInfos[propertyValueOid || 'undefined'] = {
            total: pagination.total - 1,
          };
        }
      }
    },
    updateNoStatusPaginationInfo: (state) => {
      const pagination = state.paginationInfos['undefined'];
      if (pagination) {
        state.paginationInfos['undefined'] = {
          total: pagination.total - 1,
        };
      }
    },
  },
});

export const {
  setLoading,
  setConversations,
  setError,
  updateConversation,
  addPropertyForConversation,
  removePropertyForConversation,
  updateConversationProperties,
  addConversations,
  updatePagination,
  updateNoStatusPaginationInfo,
} = pipelineConversations.actions;

export const pipelineConversationsState = (state: RootState) => state.pipelineConversations;

export default pipelineConversations.reducer;
