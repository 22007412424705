import { PixelCrop } from 'react-image-crop';

const TO_RADIANS = Math.PI / 180;

export const canvasPreview = (
  image: HTMLImageElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0,
  maxDimension = 512
): Promise<Blob> => {
  const canvas1 = document.createElement('canvas');
  const ctx = canvas1.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;

  // Calculate new dimensions to fit within the maximum dimension
  const newWidth = Math.floor(crop.width * scaleX);
  const newHeight = Math.floor(crop.height * scaleY);
  const aspectRatio = newWidth / newHeight;

  let finalWidth, finalHeight;
  if (newWidth > newHeight) {
    finalWidth = Math.min(newWidth, maxDimension);
    finalHeight = finalWidth / aspectRatio;
  } else {
    finalHeight = Math.min(newHeight, maxDimension);
    finalWidth = finalHeight * aspectRatio;
  }

  canvas1.width = finalWidth * pixelRatio;
  canvas1.height = finalHeight * pixelRatio;

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = (crop.width * scaleX) / 2;
  const centerY = (crop.height * scaleY) / 2;

  ctx.save();

  ctx.translate(centerX, centerY);

  ctx.rotate(rotateRads);

  ctx.scale(scale, scale);

  ctx.translate(-centerX, -centerY);
  ctx.drawImage(image, cropX, cropY, newWidth, newHeight, 0, 0, finalWidth, finalHeight);

  ctx.restore();
  return new Promise((resolve, _reject) => {
    canvas1.toBlob(
      (blob: Blob | null) => {
        if (blob) {
          resolve(blob);
        }
      },
      'image/png',
      0.3
    );
  });
};
