import Drawer from '@mui/material/Drawer';
import { SxProps } from '@mui/material';

export enum DrawerAnchor {
  LEFT = 'left',
  RIGHT = 'right',
}

interface DetailsDrawerProps {
  isOpen: boolean;
  anchor: 'bottom' | 'left' | 'right' | 'top';
  children: JSX.Element;
  style: SxProps;
  onClose: () => void;
}

export default function DetailsDrawer({ isOpen, anchor, children, style, onClose }: DetailsDrawerProps) {
  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <Drawer anchor={anchor} open={isOpen} sx={style} onClose={onClose} variant="persistent">
        {children}
      </Drawer>
    </div>
  );
}
