import { GridColDef, GridColumnHeaderParams, GridRowModel } from '@mui/x-data-grid-pro';

import { CloseHandler } from 'components/Chats/ChatTableV2/ChatTable';
import TableHeader from 'components/Chats/ChatTableV2/TableHeader/TableHeader';
import { CHATS_DEFAULT_COLUMN_NAMES, DEFAULT_COLUMNS, STICKY_COLUMNS } from 'components/Chats/ChatTableV2/constants';
import { Conversations, Properties } from 'components/Chats/ChatTableV2/interface';
import { OptionPropertyValue } from 'services/models/api/generated';
import { EntityType } from 'services/models/domain/entityTypes';
import {
  EntityPropertyDefinition,
  EntityViewField,
  PropertyDefinitionDataType,
  PropertyDefinitionKind,
  PropertyValue,
} from 'services/models/domain/entityViews';
import { OtherUserInOrganization } from 'services/models/domain/user';
import { EntityDetailsType } from 'services/store/slices/sidebarSlice';

function addRenderHeader(column: GridColDef) {
  return {
    ...column,
    renderHeader: (params: GridColumnHeaderParams) => params.colDef && <TableHeader {...params} />,
  };
}

function getPropertyColumn(property: EntityPropertyDefinition, batchRefetch: () => Promise<void>): GridColDef {
  // TODO (AWE): Find better criteria for width
  let width = property.dataType === PropertyDefinitionDataType.TenantUser ? 170 : 144;
  if (property.kind === PropertyDefinitionKind.MultiValue) {
    width = 260;
  }
  return {
    field: property.oid.oid,
    headerName: property.name,
    sortable: property.dataType !== PropertyDefinitionDataType.Value,
    hideable: true,
    flex: 1,
    maxWidth: width,
    width: width,
    minWidth: width,
    renderHeader: (params: GridColumnHeaderParams) => {
      return params.colDef && <TableHeader {...params} property={property} refetch={batchRefetch} />;
    },
  };
}

export const generateColumns = (
  entityViewFields: EntityViewField[],
  properties: Properties,
  batchRefetch: () => Promise<void>,
  flags: any
): GridColDef[] => {
  const propertyColumns: GridColDef[] = properties.map((prop) => getPropertyColumn(prop, batchRefetch));
  const stickyColumns: GridColDef[] = STICKY_COLUMNS.map((column) => addRenderHeader(column));
  const defaultColumns: GridColDef[] = entityViewFields
    .map((field) => DEFAULT_COLUMNS[field])
    .map((column) => addRenderHeader(column));
  const columnVisibilityControlMenu = addRenderHeader(
    DEFAULT_COLUMNS[CHATS_DEFAULT_COLUMN_NAMES.VisibilityControl]
  ) as GridColDef;

  const columns = [...stickyColumns, ...defaultColumns, ...propertyColumns, columnVisibilityControlMenu];

  return columns;
};

export const generateRows = (
  conversations: Conversations,
  setOnCloseHandler: (closeHandler?: CloseHandler) => void,
  propertyDefinitions: Properties,
  batchRefetch: () => Promise<void>,
  refetchConversations: () => void,
  openSidebar: (entityOid: string, entityType: EntityDetailsType) => void,
  flags: any,
  users: OtherUserInOrganization[],
  channelProperty?: EntityPropertyDefinition
): GridRowModel[] => {
  // Generate the table rows for our static columns, like
  // Follow Up, Latest Message, etc.
  const channelPropertyDefinitionOid = channelProperty?.oid.oid;
  const rows = conversations.map((conversation) => {
    const openContactDetails = (contactOid: string) => {
      openSidebar(contactOid, EntityDetailsType.contactDetails);
    };
    const openConversationDetails = (conversationOid: string) => {
      openSidebar(conversationOid, EntityDetailsType.chatDetails);
    };
    const channelProperty = conversation.properties?.find(
      (p) => p.propertyDefinitionOid.oid === channelPropertyDefinitionOid
    ) as OptionPropertyValue | undefined;
    const conversationRow: GridRowModel = {
      id: conversation.oid.oid,
      Favorite: { conversation, refetch: refetchConversations },
      Name: {
        id: conversation.oid.oid,
        name: conversation.name,
        participants: conversation.participants,
        onClick: () => openConversationDetails(conversation.oid.oid),
        conversations: conversation,
        onParticipantLinkClicked: openContactDetails,
        link: conversation.chatLink,
        channelType: channelProperty?.value,
        origin: conversation.conversationOrigin,
      },
      SelectionColumn: { conversation },
      LatestMessage: {
        lastMessageAt: conversation.lastMessageAt?.utcTimeStampAsString,
        lastMessageSenderParticipantOid: conversation.lastMessageSenderParticipantOid,
        participants: conversation.participants,
      },
      LastModified: {
        lastModifiedTime: conversation.lastModifiedAt?.utcTimeStampAsString,
        lastModifiedByUserName: users.find((u) => u.oid === conversation.lastModifiedBy.oid)?.fullName,
      },
      DateAdded: {
        createdAt: conversation.createdAt?.utcTimeStampAsString,
      },
      FollowUp: {
        conversation: conversation,
        refetch: refetchConversations,
        setOnCloseHandler,
      },
      Note: {
        notes: conversation.notes,
        entityType: EntityType.ConversationRecord,
        entityOid: conversation.conversationRecordOid.oid,
        id: conversation.oid.oid,
        setOnCloseHandler,
      },
      Folder: {
        folder: conversation.folders,
        setOnCloseHandler,
      },
    };

    const getPropertyValueOids = (
      propertyValues: PropertyValue[],
      propertyDefinition: EntityPropertyDefinition
    ): string[] => {
      return propertyValues
        .map((pv) => {
          if (
            pv &&
            (pv.type === PropertyDefinitionDataType.Option || pv.type === PropertyDefinitionDataType.TenantUser) &&
            pv.propertyDefinitionOid.oid === propertyDefinition.oid.oid
          ) {
            return pv.oid.oid;
          }
          if (
            pv &&
            pv.type === PropertyDefinitionDataType.Value &&
            pv.propertyDefinitionOid.oid === propertyDefinition.oid.oid
          ) {
            return pv.value;
          }
          return undefined;
        })
        .filter((pv) => !!pv) as string[]; // Assume its a string array because we filtered out other types
    };

    // Creating empty values when no property settled for Conversation
    // so we can still render the Cell component with dropdown
    // and fullfilling propertiesByIds for easier access to property by id
    propertyDefinitions.forEach((propertyDefinition) => {
      conversationRow[propertyDefinition.oid.oid] = {
        entityId: conversation.oid.oid,
        value: getPropertyValueOids(conversation.properties, propertyDefinition),
        propertyDefinition: propertyDefinition,
        customProperty: true,
        refetch: refetchConversations,
        batchRefetch,
      };
    });

    return conversationRow;
  });

  return rows;
};
