import { GridCellProps, GridCell } from '@mui/x-data-grid-pro';
import { Box, Typography } from '@mui/material';

export default function BioCell(props: GridCellProps) {
  const { value, ...rest } = props;
  return (
    <GridCell {...rest}>
      <Box sx={styles.container}>
        <Typography>{value.bio ?? ''}</Typography>
      </Box>
    </GridCell>
  );
}

const styles = {
  container: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
};
