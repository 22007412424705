import { useDispatch } from 'react-redux';
import {
  EntityFilter,
  EntityFilterItem,
  FilterIdentifier,
  generateFilterId,
} from 'services/models/domain/entityFilter';
import { addEntityFilter, removeEntityFilter } from 'services/store/slices/entityFilterSlice';
import { EntityType } from 'services/models/domain/entityTypes';

export const useEntityFilter = () => {
  const dispatch = useDispatch();

  const addEntityFilterItem = (
    entityFilter: EntityFilter,
    entityType: EntityType,
    filterIdentifier: FilterIdentifier
  ) => {
    const entityFilterItem: EntityFilterItem = {
      entityType: entityType,
      filter: entityFilter,
      id: generateFilterId(entityFilter.type, filterIdentifier),
      filterOptionIdentifier: filterIdentifier,
    };
    dispatch(
      addEntityFilter({
        filterItem: entityFilterItem,
      })
    );
  };

  const removeEntityFilterItem = (filterUniqueId: string) => {
    dispatch(
      removeEntityFilter({
        filterUniqueId: filterUniqueId,
      })
    );
  };

  return {
    addEntityFilterItem,
    removeEntityFilterItem,
  };
};
