import React, { useEffect } from 'react';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DateView } from '@mui/x-date-pickers';

import { FollowupCalendarProps } from 'page/modal/followUpModals/FollowUpCalendar/interface';
import dayjs from 'dayjs';

const FollowupCalendar = ({ value, handleValue }: FollowupCalendarProps) => {
  const [date, setDate] = React.useState<Date | undefined>(value);
  const [view, setView] = React.useState<DateView>('day');

  useEffect(() => {
    setDate(value);
  }, [value]);

  const dayJsValue = dayjs(date);
  return (
    <DateCalendar
      value={dayJsValue}
      onChange={(value) => {
        if (value) {
          const utcValue = value.utc();
          value.utcOffset();
          const dateValue = new Date(value.unix() * 1000);
          setDate(dateValue);
          handleValue(dateValue);
        } else {
          setDate(undefined);
          handleValue(undefined);
        }
      }}
      onViewChange={(value) => setView(value)}
      onYearChange={() => setView('day')}
      view={view}
      disablePast
    />
  );
};

export default FollowupCalendar;
