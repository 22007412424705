import { Suspense, lazy, useEffect } from 'react';
import DashboardLayout from 'page/dashboard/DashboardLayout';
import { Navigate, useRoutes } from 'react-router-dom';
import LoadingScreen from 'components/LoadingScreen';
import AuthLayout from 'page/auth/AuthLayout';
import AuthGuard from 'router/gaurds/AuthGuard';
import GuestGuard from 'router/gaurds/GuestGuard';
import { RouterPaths } from 'services/hooks/useNavigation';
import useAuth from 'services/hooks/useAuth';
import { useTelegramClient } from 'services/hooks/useTelegramClient';

const Loadable = (Component: any) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

export default function Router() {
  const { initialize } = useAuth();
  useEffect(() => {
    initialize();
  }, []);

  return useRoutes([
    {
      path: '/',
      element: (
        <GuestGuard>
          <AuthLayout />
        </GuestGuard>
      ),
      children: [
        { path: '/login', element: <Login /> },
        { path: '/register', element: <Register /> },
        { index: true, element: <Navigate to={RouterPaths.Register} replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'chats', element: <Chats /> },
        { path: 'contacts', element: <Contacts /> },
        { path: 'reports', element: <ReportsPage /> },
        {
          path: 'details',
          element: <Details />,
          children: [
            { path: 'chat/:chatId', element: <DetailsChat /> },
            { path: 'contact/:contactId', element: <DetailsContact /> },
          ],
        },
        { path: '*', element: <Navigate to={RouterPaths.Chats} replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <AuthLayout />
        </AuthGuard>
      ),
      children: [{ path: '/setup', element: <Setup /> }],
    },
  ]);
}

const Chats = Loadable(lazy(() => import('page/chats/ChatPage')));
const Contacts = Loadable(lazy(() => import('page/contacts/Contacts')));
const ReportsPage = Loadable(lazy(() => import('page/reports/ReportsPage')));
const Details = Loadable(lazy(() => import('page/details/DetailsPage')));
const DetailsChat = Loadable(lazy(() => import('page/details/DetailsPages/DetailsChatPage')));
const DetailsContact = Loadable(lazy(() => import('page/details/DetailsPages/DetailsContactPage')));
const Login = Loadable(lazy(() => import('page/auth/Login/Login')));
const Register = Loadable(lazy(() => import('page/auth/Register/Register')));
const Setup = Loadable(lazy(() => import('page/auth/Setup/Setup')));
