import { colors } from 'theme/colors';
import Translations from 'const/translations/en';
import { SnackBarVariantEnum } from 'components/SnackBar/SnackBar';
import { LocalStorageValues } from 'services/utils/interface';
import { ChromeExtensionClient, ChromeExtensionMessageType } from 'services/clients/ChromeExtensionClient';
import useSnackbarContext from './useSnackbar';

export default function useLoginRequestOrigins() {
  const chromeExtensionClient = new ChromeExtensionClient();
  const { setSnackbarOpenStatus } = useSnackbarContext();

  const sendTokenToChromeExtension = (authToken: string) => {
    const message = {
      type: ChromeExtensionMessageType.Login,
      [LocalStorageValues.AuthToken]: authToken,
    };
    if (chromeExtensionClient.isChromeRuntime()) {
      chromeExtensionClient.sendMessage(message).then((response) => {
        if (!response || !response.success) {
          return;
        }
        setSnackbarOpenStatus(
          true,
          Translations.CHROM_EXTENSION_LOGIN_SUCCESS,
          SnackBarVariantEnum.info,
          undefined,
          Translations.CHROME_EXTENSION_LOGIN_DESC,
          undefined,
          '🥳',
          colors.neutral[80],
          colors.white,
          colors.neutral[30]
        );
        setTimeout(() => {
          setSnackbarOpenStatus(false, '', SnackBarVariantEnum.info);
        }, 3000);
      });
    }
  };

  return {
    sendTokenToChromeExtension,
  };
}
