import { Box, List, ListItem, ListItemDecorator, Sheet, Typography } from '@mui/joy';
import * as React from 'react';

import { alpha } from '@mui/material';
import CustomAvatar from 'components/CustomAvatar';
import { useSelector } from 'react-redux';
import { OrganizationalUser } from 'services/models/domain/user';
import { authState } from 'services/store/slices/authSlice';
import { avatarFromName, generateColorFromOid } from 'services/utils/helpers';
import 'theme/styles/calendar.css';

interface Props {
  assignee: OrganizationalUser | null;
  width?: string;
  onSelectAssignee: Function | any;
  maxHeight?: string | undefined;
}

const AssignFollowUp: React.FC<Props> = ({ assignee, width, onSelectAssignee, maxHeight }) => {
  const { user } = useSelector(authState);

  const rawListUsers = user?.actingAsMemberOfOrganization?.listOfUsersInOrganization ?? [];

  const [users, setUsers] = React.useState<OrganizationalUser[]>([]);

  React.useEffect(() => {
    const rebuiltArray = [
      ...rawListUsers.filter((x: OrganizationalUser) => (x.fullName || x.firstName) && x.oid === user?.oid),
      ...rawListUsers.filter((x: OrganizationalUser) => (x.fullName || x.firstName) && x.oid !== user?.oid),
    ];
    setUsers(rebuiltArray);
  }, [user]);

  return (
    <Sheet
      variant="plain"
      sx={{
        width: width ? width : '220px',
        borderRadius: '8px',
        border: '1px solid var(--joy-palette-primary-plainBorder)',
        maxHeight: maxHeight || '400px',
        overflow: 'auto',
        overflowX: 'hidden',
        backgroundColor: 'primary.light',
      }}
    >
      <Box>
        <List
          sx={{
            '--List-decorator-size': '35px',
            '--List-item-paddingLeft': '1rem',
            '--List-item-paddingRight': '0.75rem',
          }}
          id="assignee-list"
          role="list"
        >
          {users?.map((value, idx) => {
            return (
              <ListItem
                id={`assignee-item-${idx}`}
                role="listitem"
                key={value.oid}
                sx={{
                  '&:hover': { opacity: 1, background: 'var(--joy-palette-primary-solidActiveBg)' },
                  cursor: 'pointer',
                  alignItems: 'center',
                  background:
                    value.oid === assignee?.oid ? 'var(--joy-palette-primary-solidActiveBg)' : 'primary.light',
                  height: '36px',
                  p: '0px',
                  position: 'relative',
                }}
                onClick={() => onSelectAssignee(value)}
              >
                {value.oid === assignee?.oid && (
                  <Box
                    sx={{
                      backgroundColor: alpha('#1769D4', 0.7),
                      height: '24px',
                      width: '2.5px',
                      position: 'absolute',
                      zIndex: 9,
                      borderRadius: '0px 1px 1px 0px',
                    }}
                  />
                )}
                <ListItemDecorator sx={{ alignSelf: 'center', ml: '15px' }}>
                  <CustomAvatar
                    index={generateColorFromOid(value?.oid ?? '')}
                    size={26}
                    userInitials={avatarFromName(value.firstName ?? value.fullName)}
                    imgUrl={value?.profileImageUrl}
                  />
                </ListItemDecorator>

                <Typography
                  level="tiny"
                  sx={{
                    color: value.oid === assignee?.oid ? '#1769D4 !important' : '#797F87',
                  }}
                >
                  {value?.fullName ?? value.firstName}
                </Typography>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Sheet>
  );
};

export default AssignFollowUp;
