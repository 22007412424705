import { PROPERTY_ICONS } from 'components/Chats/ChatTableV2/EditProperty/AddEditPropertyValue/constants';
import Translations from 'const/translations/en';

export enum SmartListOptionType {
  RecentlyActive,
  Sorting,
  GoingCold,
}

export type SmartListOptionItem = {
  type: SmartListOptionType;
  title: string;
  text: string;
  icon: (typeof PROPERTY_ICONS)[number];
  emoji: string;
};

export const smartListOptions: SmartListOptionItem[] = [
  {
    type: SmartListOptionType.RecentlyActive,
    title: Translations.SMART_LIST_OPTION_RECENTLY_ACTIVE_TITLE,
    text: Translations.SMART_LIST_OPTION_RECENTLY_ACTIVE_TEXT,
    icon: 'ic_alarm',
    emoji: '⏰',
  },
  {
    type: SmartListOptionType.Sorting,
    title: Translations.SMART_LIST_OPTION_SORTING_TITLE,
    text: Translations.SMART_LIST_OPTION_SORTING_TEXT,
    icon: 'ic_circle_dotted',
    emoji: '🌈',
  },
  {
    type: SmartListOptionType.GoingCold,
    title: Translations.SMART_LIST_OPTION_GOING_COLD_TITLE,
    text: Translations.SMART_LIST_OPTION_GOING_COLD_TEXT,
    icon: 'ic_cactus',
    emoji: '🥶',
  },
];
