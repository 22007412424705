import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'services/store/AppStore';
import { NavigationItem } from 'page/dashboard/header/SideNav';

export interface SideNavState {
  selectedItem: NavigationItem;
  navigationItems: NavigationItem[];
}

const initialState: SideNavState = {
  selectedItem: {
    icon: '',
    title: '',
  },
  navigationItems: [],
};

const sideNavSlice = createSlice({
  name: 'sideNav',
  initialState,
  reducers: {
    setSelectedItem: (state, action: PayloadAction<{ selectedItem: NavigationItem }>) => {
      state.selectedItem = action.payload.selectedItem;
    },
    setNavigationItems: (state, action: PayloadAction<{ navigationItems: NavigationItem[] }>) => {
      state.navigationItems = action.payload.navigationItems;
    },
  },
});

export const { setSelectedItem, setNavigationItems } = sideNavSlice.actions;
export const sideNavState = (state: RootState) => state.sideNav;
export default sideNavSlice.reducer;
