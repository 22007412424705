import { Tooltip, Typography } from '@mui/joy';
import DetailsFooter from 'components/Details/DetailsFooter';
import Translations from 'const/translations/en';

function ParticipantInfo() {
  return (
    <DetailsFooter>
      <Typography level="micro" sx={{ color: 'info.dark' }}>
        {Translations.PARTICIPANT_INFO_MESSSAGE}
        {'  '}
        <Tooltip
          arrow
          placement="top"
          title={
            <Typography level="legalMicro" sx={{ color: 'white' }}>
              {Translations.PARTICIPANT_TOOLTIP}
            </Typography>
          }
          componentsProps={{
            root: {
              sx: { width: '255px', p: '6px 8px', borderRadius: '4px', backgroundColor: 'secondary.dark' },
            },
            arrow: {
              sx: {
                position: 'relative',
                '&::before': {
                  backgroundColor: 'secondary.dark',
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  width: 8,
                  height: 8,
                  top: -1,
                  transform: 'rotate(45deg)',
                },
                '&::after': {
                  backgroundColor: 'secondary.dark',
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  width: 8,
                  height: 8,
                  top: -1,
                  transform: 'rotate(45deg)',
                },
              },
            },
          }}
        >
          <Typography sx={{ textDecoration: 'underline', textDecorationStyle: 'dashed' }}>
            {Translations.PARTICIPANT_HERE_WHY}
          </Typography>
        </Tooltip>
      </Typography>
    </DetailsFooter>
  );
}

export default ParticipantInfo;
