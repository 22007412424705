import { alpha, SxProps, Theme } from '@mui/material';
import { SnackBarVariantEnum as SnackVariant } from 'components/SnackBar/SnackBar';
import { colors } from 'theme/colors';
import { fonts } from 'theme/fonts';

export const SnackbarContentStyle = (variant: string): SxProps<Theme> => {
  return {
    color: variant === SnackVariant.error ? 'error.main' : 'success.main',
    backgroundColor: variant === SnackVariant.error ? alpha('#DB2042', 0.1) : 'rgba(23, 135, 77, 0.05)',
    minWidth: 'auto !important',
    height: 'auto',
    padding: ' 0 13px',
    borderRadius: '20px',
    boxShadow: 'none',
    border: variant === 'error' ? `1px solid ${alpha('#DB2042', 0.3)}` : '1px solid #fff',
    maxWidth: 'fit-content',
  };
};

export const SnackbarWithActionContentStyle = (variant: string): SxProps<Theme> => {
  return {
    color:
      variant === SnackVariant.error || variant === SnackVariant.sync || variant === SnackVariant.greenSuccess
        ? 'primary.light'
        : '#545960',
    backgroundColor:
      variant === SnackVariant.error
        ? 'error.main'
        : variant === SnackVariant.sync
          ? 'neutral.80'
          : variant === SnackVariant.greenSuccess
            ? colors.blue
            : 'primary.light',
    minWidth: 'auto !important',
    height: 'auto',
    padding: variant === SnackVariant.sync ? '0px 16px 0px 11px' : '4px 16px 4px 11px',
    marginBottom: variant === SnackVariant.sync || variant === SnackVariant.greenSuccess ? '48px' : 0,
    borderRadius: variant === SnackVariant.sync || variant === SnackVariant.greenSuccess ? '24px' : '8px',
    boxShadow: variant === SnackVariant.error ? 'none' : '0px 0px 6px rgba(0, 0, 0, 0.07)',
    border: variant === SnackVariant.error ? `1px solid rgba(219, 32, 66, 0.15)` : '1px solid #fff',
    maxWidth: 'fit-content',
  };
};

export const SnackbarWithActionContainerStyle: SxProps = {
  paddingLeft: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const SnackbarWithActionTextStyle = (variant: string): SxProps => {
  return {
    paddingLeft: variant === SnackVariant.sync ? '8px' : '0px',
    color:
      variant === SnackVariant.error || variant === SnackVariant.sync || variant === SnackVariant.greenSuccess
        ? 'primary.light'
        : '#545960',
  };
};

export const ActionButtonStyle = (variant: string): SxProps => {
  return {
    backgroundColor: variant === SnackVariant.error ? 'primary.light' : '#141618',
    color: variant === SnackVariant.error ? 'error.main' : 'primary.light',
    minHeight: '24px',
    minWidth: '29px',
    fontSize: '12px',
    lineHeight: '16px',
    padding: '4px 8px',
    fontWeight: 400,
    fontFamily: fonts.regular,
    border: 'error' ? '1px solid rgba(219, 32, 66, 0.15)' : 'none',
    ':hover': {
      backgroundColor: variant === SnackVariant.error ? 'primary.light' : '#141618',
      color: variant === SnackVariant.error ? 'error.main' : 'primary.light',
      border: 'error' ? '1px solid rgba(219, 32, 66, 0.15)' : 'none',
    },
  };
};

export const SnackbarWithEmojiAndSubTextContainerStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '6px 16px',
  borderRadius: '24px',
  boxShadow: '0px 8px 24px 0px #00000066',
};

export const SnackbarWithEmojiAndSubTextEmojiStyle: SxProps = {
  fontSize: '18px',
};

export const SnackbarWithEmojiAndSubTextInnerContainerStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
};
