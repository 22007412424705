import { SxProps } from '@mui/material';
import React from 'react';
import { colors } from 'theme/colors';
import { Flex1 } from 'theme/flex';
import { fonts } from 'theme/fonts';

export const KanbanBoardStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  fontFamily: fonts.regular,
  backgroundColor: colors.kanbanBackground,
  overflowX: 'auto',
  flex: '1 1 auto',
  height: '100%',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};
export const KanbanBoardHeaderStyle: SxProps = {
  width: '100%',
  minHeight: '42px',
};

export const EmptyWidth: SxProps = {
  display: 'flex',
  flex: '0 0 auto',
  borderTop: `1px solid ${colors.neutral[5]}`,
  borderBottom: `1px solid ${colors.neutral[5]}`,
  height: '40px',
  width: '23px',
  backgroundColor: colors.white,
};

export const KanbanBoardContainerStyle: SxProps = {
  flexDirection: 'row',
  flex: '1 1 auto',
  height: '100%',
  display: 'flex',
  marginTop: '-42px',
};

export const KanbanColumnStyle = (isDraggingOver: boolean): SxProps => {
  return {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px',
    width: '236px',
    height: '100%',
    // flex: '1 1 auto',
  };
};

export const KanbanColumnHeaderStyle = (isHeaderMenuOpen: boolean): SxProps => {
  return {
    display: 'flex',
    flexDirection: 'row',
    borderRight: `1px solid ${colors.neutral[5]}`,
    borderTop: `1px solid ${colors.neutral[5]}`,
    borderBottom: `1px solid ${colors.neutral[5]}`,
    backgroundColor: isHeaderMenuOpen ? colors.neutral[0] : colors.white,
    alignItems: 'center',
    height: '40px',
    paddingLeft: '10px',
    paddingRight: '10px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.neutral[0],
    },
    width: '236px',
  };
};

export const KanbanHeaderMenuStyle: SxProps = {
  marginTop: '3px',
  marginLeft: '3px',
};

export const KanbanColumnContainerStyle = (isDraggingOver: boolean, hovered: boolean): SxProps => {
  return {
    display: 'flex',
    flexDirection: 'row',
    // Reduce by column heider height
    height: 'calc(100% - 40px)',
    flex: '1 1',
    width: '236px',
    overflow: 'hidden',
  };
};

export const KanbanColumnInnerContainerStyle = (
  isDraggingOver: boolean,
  hovered: boolean,
  isDraggingOverOrigin: boolean
): SxProps => {
  return {
    border: isDraggingOver
      ? isDraggingOverOrigin
        ? `2px dashed ${colors.neutral[30]}`
        : `2px dashed ${colors.primary['20']}`
      : '0px',
    borderRadius: '6px',
    display: 'flex',
    flex: '1 1 auto',
    marginRight: '1px',
    marginLeft: '1px',
    height: '100%',
    overflowY: hovered ? 'scroll' : 'hidden',
    overflowX: 'hidden',
    width: '236px',
    paddingBottom: '100px',
    marginBottom: '100px',
    justifyContent: 'center',
  };
};

export const KanbanColumnContentStyle = (isDraggingOver: boolean, hovered: boolean): SxProps => {
  return {
    // TODO (AWE): Rather use flex
    marginTop: '5px',
    marginBottom: '0px',
    display: 'flex',
    marginRight: hovered ? '0px' : '5px',
    marginLeft: '5px',
    flex: 1,
  };
};

export const KanbanColumnDividerStyle: React.CSSProperties = {
  minWidth: '1px',
  border: 'none',
  backgroundImage: `linear-gradient(to bottom, ${colors.neutral[30]} 3px, transparent 3px)`,
  backgroundPosition: 'left top',
  backgroundSize: '1px 8.175px',
  backgroundRepeat: 'repeat-y',
  backgroundClip: 'content-box',
};

export const KanbanCardContainerStyle = (isDragging: boolean): React.CSSProperties => {
  return {
    transform: isDragging ? 'rotate(-2deg)' : 'none',
    transitionDuration: '50ms',
    flex: '1 1',
    display: 'flex',
    width: '226px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: '5px',
    paddingRight: '5px',
  };
};

export const KanBanCardStyle: SxProps = {
  backgroundColor: colors.white,
  border: `1px solid ${colors.neutral[5]}`,
  borderRadius: '6px',
  flex: '1 1',
  display: 'flex',
  mx: '5px',
  my: '8px',
  boxShadow: `0px 0px 2px 0px rgba(0, 0, 0, 0.07), 0px 0px 8px 0px rgba(0, 0, 0, 0.04)`,
};

export const KanbanCardHeaderStyle: React.CSSProperties = {
  ...Flex1,
  borderBottom: `1px solid ${colors.neutral[5]}`,
  padding: '10px',
  position: 'relative',
  alignItems: 'center',
};

export const EntityTitleStyle: SxProps = {
  marginBottom: '5px',
  maxWidth: '184px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

export const EntitySubTitleStyle: SxProps = {
  color: colors.neutral[50],
};

export const DottedLineStyle: React.CSSProperties = {
  height: '1px',
  border: 'none',
  backgroundImage: `linear-gradient(to right, ${colors.neutral[20]} 1px, transparent 1px)`,
  backgroundPosition: 'left top',
  backgroundSize: '4px 1px',
  backgroundRepeat: 'repeat-x',
  backgroundClip: 'content-box',
  marginTop: '2px',
  marginBottom: '2px',
};

export const LinkIconsContainerStyle: React.CSSProperties = {
  background: 'linear-gradient(to right, transparent 5%, white 15%)',
  paddingLeft: '30px',
  marginRight: '10px',
  position: 'absolute',
  right: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: '100%',
};

export const DetailsIconStyle: SxProps = {
  color: 'white',
  fontSize: '2rem',
  border: `1px solid ${colors.neutral['15']}`,
  borderRadius: '20%',
  marginLeft: '4.75px',
  cursor: 'pointer',
  padding: '5px',
  '&:hover': {
    '& path': {
      stroke: colors.neutral[80],
    },
  },
  display: 'flex',
};

export const TelegramIconStyle = (isLinkAvailable: boolean): SxProps => {
  return {
    color: 'white',
    fontSize: '2rem',
    border: `1px solid ${colors.neutral['15']}`,
    borderRadius: '20%',
    padding: '5px',
    '#telegram_logo_circle': {
      fill: colors.neutral[50],
    },
    cursor: isLinkAvailable ? 'pointer' : 'unset',
    '&:hover': isLinkAvailable
      ? {
          '& #telegram_logo_circle': {
            fill: colors.telegramLogo,
          },
        }
      : {},
    '& #telegram_logo_circle': isLinkAvailable
      ? {}
      : {
          fill: colors.neutral[30],
        },
  };
};

export const ButtonSx: SxProps = {
  maxWidth: '136px',
  height: '24px',
  minHeight: '24px',
  border: `1px solid ${colors['neutral']['10']}`,
  padding: '5px 8px 5px 8px',
  '&:hover': {
    border: `1px solid ${colors['neutral']['10']}`,
  },
};

export const MenuSx: SxProps = {
  '& .MuiPaper-root': {
    maxHeight: '320px',
    marginTop: '-5px',
  },
};

export const MenuItemSx: SxProps = {
  '&:hover': {
    background: 'transparent',
  },
  padding: '7px 8px',
};

export const KanbanCardContentSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  padding: '10px',
};

export const SelectedMenuItemSx: SxProps = {
  ...MenuItemSx,
  borderBottom: `1px solid ${colors['neutral']['10']}`,
  alignItems: 'space-between',
};

export const CloseIconSx: SxProps = {
  position: 'absolute',
  right: 0,
};

export const UserBoxSx: SxProps = {
  width: '100%',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  cursor: 'pointer',
};

export const CategoriesBoxSx: SxProps = {
  width: 'fit-content',
  height: '24px',
  minHeight: '24px',
  borderRadius: '8px',
  border: `1px solid ${colors.neutral[10]}`,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
};

export const NameSx: SxProps = {
  color: 'palette.text.secondary',
  lineHeight: '16px',
  letterSpacing: '-0.005em',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

export const CategoriesTextSx: SxProps = {
  color: 'text.primary',
  maxWidth: '120px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  px: '8px',
};

export const KanbanCardItemSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: '24px',
  '& .JoyBox-root': {
    width: 'fit-content',
  },
};

export const AvatarSx: SxProps = {
  border: `1px solid ${colors.neutral[5]}`,
};

export const ButtonNameSx: SxProps = {
  color: 'secondary.dark',
  lineHeight: '16px',
  letterSpacing: '-0.005em',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

export const AnchorSx: SxProps = {
  width: 'fit-content',
};
