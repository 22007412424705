import { IconsSvg } from 'assets/icons';
import Translations from 'const/translations/en';
import {
  PropertyDefinitionCategoryOption,
  PropertyDefinitionDataType,
  PropertyDefinitionKind,
} from 'services/models/domain/entityViews';
import { User } from 'services/models/domain/user';
import { mapUserToTenantUser } from 'services/utils/userUtils';

export const AvailableCategories: PropertyDefinitionCategoryOption[] = Object.values(PropertyDefinitionCategoryOption);

export const getPropertyDefinitionIcon = (
  propertyCategory: PropertyDefinitionCategoryOption
): keyof typeof IconsSvg => {
  switch (propertyCategory) {
    case PropertyDefinitionCategoryOption.Text:
      return 'ic_abc';
    case PropertyDefinitionCategoryOption.Status:
      return 'ic_status_change';
    case PropertyDefinitionCategoryOption.Teammates:
      return 'ic_user';
    case PropertyDefinitionCategoryOption.SingleSelect:
      return 'ic_circle_check';
    case PropertyDefinitionCategoryOption.MultiSelect:
      return 'ic_three_circles';
    default:
      return 'ic_abc';
  }
};

export const getPropertyDefinitionCategory = (propertyCategory: PropertyDefinitionCategoryOption): string | null => {
  switch (propertyCategory) {
    case PropertyDefinitionCategoryOption.Status:
      return 'STATUS';
    default:
      return null;
  }
};

export const getPropertyDefinitionName = (propertyCategory: PropertyDefinitionCategoryOption): string => {
  switch (propertyCategory) {
    case PropertyDefinitionCategoryOption.Text:
      return Translations.TEXT_CHIP_TEXT;
    case PropertyDefinitionCategoryOption.Status:
      return Translations.STATUS_CHIP_TEXT;
    case PropertyDefinitionCategoryOption.Teammates:
      return Translations.TEAMMATES_CHIP_TEXT;
    case PropertyDefinitionCategoryOption.SingleSelect:
      return Translations.SINGLE_SELECT_CHIP_TEXT;
    case PropertyDefinitionCategoryOption.MultiSelect:
      return Translations.MULTI_SELECT_CHIP_TEXT;
    default:
      return Translations.TEXT_CHIP_TEXT;
  }
};

export const getPropertyDefinitionDataType = (propertyCategory: PropertyDefinitionCategoryOption) => {
  switch (propertyCategory) {
    case PropertyDefinitionCategoryOption.Text:
      return PropertyDefinitionDataType.Value;
    case PropertyDefinitionCategoryOption.Teammates:
      return PropertyDefinitionDataType.TenantUser;

    default:
      return PropertyDefinitionDataType.Option;
  }
};

export const getPropertyDefinitionKind = (propertyCategory: PropertyDefinitionCategoryOption) => {
  switch (propertyCategory) {
    case PropertyDefinitionCategoryOption.MultiSelect:
      return PropertyDefinitionKind.MultiValue;
    default:
      return PropertyDefinitionKind.SingleValue;
  }
};

export const getPropertyDefinitionInitialValues = (
  propertyCategory: PropertyDefinitionCategoryOption,
  propertyDefinitionOid: string,
  user?: User | null
) => {
  switch (propertyCategory) {
    case PropertyDefinitionCategoryOption.Teammates:
      return !!propertyDefinitionOid && !!user
        ? user.actingAsMemberOfOrganization.listOfUsersInOrganization.map((user) =>
            mapUserToTenantUser(user, propertyDefinitionOid)
          )
        : [];
    default:
      return [];
  }
};
