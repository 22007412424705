import { Box, useTheme } from '@mui/joy';
import { Skeleton } from '@mui/material';
import { colors } from 'theme/colors';

const ChatPlaceholder = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        height: '56px',
        width: 'auto',
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderBottom: `1px solid ${colors.border}`,
        borderTop: `1px solid ${colors.border}`,
      }}
    >
      <Box sx={{ width: '340px', display: 'flex', alignItems: 'center', p: '10px 16px 10px 8px' }}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          sx={{ width: '12px', height: '12px', ml: '8px', borderRadius: '4px' }}
        />
        <Box sx={{ ml: '30px', width: '270px' }}>
          <Skeleton animation="wave" variant="text" sx={{ width: '163px', fontSize: theme.typography.micro }} />
          <Skeleton animation="wave" variant="text" sx={{ width: '108px', fontSize: theme.typography.micro }} />
        </Box>
      </Box>
      <Box sx={{ width: '200px', p: '10px 16px 10px 8px' }}>
        <Skeleton animation="wave" variant="text" sx={{ fontSize: theme.typography.micro, width: '60px' }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: theme.typography.micro, width: '80px' }} />
      </Box>
      <Box sx={{ width: '220px', display: 'flex', alignItems: 'center', p: '10px 16px 10px 8px' }}>
        <Skeleton animation="wave" variant="circular" sx={{ width: '20px', height: '20px', mr: '8px' }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: theme.typography.micro, width: '66px' }} />
      </Box>
      <Box sx={{ width: '180px', p: '10px 16px 10px 8px', display: 'flex', alignItems: 'center' }}>
        <Skeleton animation="wave" variant="text" sx={{ fontSize: theme.typography.micro, width: '54px', mr: '8px' }} />
        <Skeleton animation="wave" variant="rectangular" sx={{ width: '12px', height: '12px', borderRadius: '3px' }} />
      </Box>
      <Box sx={{ width: '600px', p: '10px 16px 10px 8px' }}>
        <Skeleton animation="wave" variant="text" sx={{ fontSize: theme.typography.micro }} />
        <Skeleton animation="wave" variant="text" sx={{ width: '120px', fontSize: theme.typography.micro }} />
      </Box>
    </Box>
  );
};

export default ChatPlaceholder;
