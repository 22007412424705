import React, { useState } from 'react';
import { Tooltip, IconButton, Sheet, Box, Typography, Stack, Divider, styled } from '@mui/joy';
import Icons from 'assets/icons';

const NotesActionRootStyle = styled('div')(({ theme }) => ({
  height: 40,
  zIndex: 99,
  opacity: 0,
  // margin: 'auto',
  display: 'flex',
  position: 'absolute',
  // alignItems: 'center',
  top: theme.spacing(1),
  right: theme.spacing(1),
  bottom: theme.spacing(1),
  justifyContent: 'right',
  padding: theme.spacing(0, 0.75),
  transition: 'width .35s ease-in-out',
  margin: 0,
  alignItems: 'flex-start',
}));

interface Props {
  [others: string]: any;
  handleEdit: () => void;
  handleDelete: () => Promise<void>;
}

export default function NotesActions({ handleDelete, handleEdit, ...others }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <NotesActionRootStyle {...others}>
        {/*@ts-ignore*/}
        <Tooltip
          open={open}
          variant="plain"
          title={
            <Sheet variant="outlined" sx={{ borderRadius: '8px' }}>
              <Stack sx={{ borderRadius: '8px' }}>
                <Box
                  sx={{
                    m: '5px',
                    px: 1,
                    py: 0.5,
                    border: '1px solid transparent',
                    flexDirection: 'row',
                    display: 'flex',
                    cursor: 'pointer',
                    justifyContent: 'start',
                    alignContent: 'center',
                    alignItems: 'center',
                    color: 'var(--joy-palette-info-plainBg)',
                    '&:hover': {
                      background: 'var(--joy-palette-info-outlinedBorder)',
                      border: '1px solid #ECF0F3',
                      borderRadius: ' 4px',
                      color: 'var(--joy-palette-primary-solidColor)',
                    },
                  }}
                  onClick={() => {
                    handleEdit();
                    handleClose();
                  }}
                >
                  <Typography
                    level="tiny"
                    startDecorator={<img src={Icons.ic_edit} alt="" width="15" height="15" />}
                    sx={{ fontSize: '13px' }}
                  >
                    Edit
                  </Typography>
                </Box>
                <Divider sx={{ '--Divider-lineColor': 'var(--joy-palette-info-plainBorder)' }} />
                <Box
                  sx={{
                    m: '5px',
                    px: 1,
                    py: 0.5,
                    border: '1px solid transparent',
                    flexDirection: 'row',
                    display: 'flex',
                    cursor: 'pointer',
                    justifyContent: 'start',
                    alignContent: 'center',
                    alignItems: 'center',
                    color: 'error.main',
                    '&:hover': {
                      background: 'rgba(219, 32, 66, 0.05)',
                      border: '1px solid rgba(219, 32, 66, 0.05)',
                      borderRadius: ' 4px',
                      color: 'var(--joy-palette-primary-solidColor)',
                    },
                  }}
                  onClick={() => {
                    handleClose();
                    handleDelete();
                  }}
                >
                  <Typography
                    level="tiny"
                    startDecorator={<img src={Icons.ic_trash} alt="" width="15" height="15" />}
                    sx={{ color: 'error.main', fontSize: '13px' }}
                  >
                    Delete
                  </Typography>
                </Box>
              </Stack>
            </Sheet>
          }
          placement="bottom"
          arrow
          componentsProps={{
            root: {
              sx: {
                width: '150px',
                height: '64px',
                borderRadius: '8px',
                p: 0,
                pr: '20px',
                mr: '20px',
                zIndex: 15000,
              },
            },
            arrow: {
              sx: {},
            },
          }}
        >
          <IconButton
            onClick={handlePopoverOpen}
            size="sm"
            variant="plain"
            color="primary"
            sx={{
              border: '1px solid transparent',
              borderRadius: 15,
              minHeight: 20,
              minWidth: 20,
              ml: '2px',
              '&:hover': {
                background: 'var(--joy-palette-info-outlinedBorder)',
                border: '1px solid #E6EBEE',
                borderRadius: '4px',
              },
            }}
            aria-owns={open ? 'over-popover' : undefined}
            aria-haspopup="true"
          >
            <img src={Icons.ic_dots} width={18} height={18} alt="" />
          </IconButton>
        </Tooltip>
      </NotesActionRootStyle>
    </>
  );
}
