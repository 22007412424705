import { useState } from 'react';
import 'theme/styles/scroll-bar.css';

import { NotesContainer } from 'page/modal/NotesModal/styles';
import { NotesModalProps } from 'page/modal/NotesModal/interface';
import AddNoteItem from 'components/NotesItem/AddNoteItem';
import NotesItem, { NoteState } from 'components/NotesItem/NotesItem';
import { Note } from 'services/models/domain/note';

const NotesList = ({ id, entityType, entityOid, notes, handleClose }: NotesModalProps) => {
  const [noteState, setNoteState] = useState<NoteState>({
    isEditing: false,
    selectedNote: null,
    shouldHideOthers: false,
  });

  return (
    <NotesContainer>
      <AddNoteItem
        setNoteState={setNoteState}
        state={noteState}
        entityType={entityType}
        entityOid={entityOid}
        key={'new_item_key'}
        handleClose={handleClose}
      />
      {notes.map((n: Note) => (
        <NotesItem
          key={n.oid}
          note={n}
          entityType={entityType}
          entityOid={entityOid}
          state={noteState}
          setNoteState={setNoteState}
        />
      ))}
    </NotesContainer>
  );
};

export default NotesList;
