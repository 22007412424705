import { NavigateOptions, useNavigate } from 'react-router-dom';

export enum NavigationPath {
  Auth = '/auth',
  Login = '/login',
  Register = '/register',
  Setup = '/setup',
  Chats = '/chats',
  Contacts = '/contacts',
  Reports = '/reports',
  DetailsChat = '/details/chat',
  DetailsContact = '/details/contact',
}

export const RouterPaths = {
  ...NavigationPath,
  createDetailsChatPath: (chatId: string) => `${RouterPaths.DetailsChat}/${chatId}` as NavigationPath,
  createDetailsContactPath: (contactId: string) => `${RouterPaths.DetailsContact}/${contactId}` as NavigationPath,
};

export const useNavigation = () => {
  const navigate = useNavigate();

  return (path: NavigationPath | number, state?: NavigateOptions) => {
    if (typeof path === 'number') navigate(path);
    else navigate(path, { state });
  };
};
