import { GridApiPro, useGridApiContext as originalUseGridApiContext } from '@mui/x-data-grid-pro';

export function useSafeGridApiContext() {
  let apiRef: React.MutableRefObject<GridApiPro> | null = null;
  try {
    apiRef = originalUseGridApiContext();
  } catch (error) {
    apiRef = null;
  }
  return apiRef;
}
