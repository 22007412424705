import { Box, ListItem, ListItemContent, ListItemDecorator, Stack, Typography } from '@mui/joy';
import { useMemo, useState } from 'react';
import CustomAvatar from 'components/CustomAvatar';
import { avatarFromName, generateColorFromOid, mapToLabel, sortUsers } from 'services/utils/helpers';
import { EntityType } from 'services/models/domain/entityTypes';
import { useNotesEntity } from 'services/hooks/useNotesEntity';
import { RichTextInputArea, emptyMessage, descendantToString } from '@3rm-co/ui-library';
import { Descendant } from 'slate';
import Translations from 'const/translations/en';
import { NoteState } from 'components/NotesItem/NotesItem';
import { useSelector } from 'react-redux';
import { authState } from 'services/store/slices/authSlice';

interface Props {
  setNoteState: React.Dispatch<React.SetStateAction<NoteState>>;
  state: NoteState;
  entityType: EntityType;
  entityOid: string;
  handleClose?: (event: React.MouseEvent<HTMLElement>) => void;
  autoFocus?: boolean;
}

export default function AddNoteItem({ state, setNoteState, entityType, entityOid, handleClose, autoFocus }: Props) {
  const { user } = useSelector(authState);
  const [newNoteText, setNewNoteText] = useState<Descendant[]>(emptyMessage);

  const { addNewNote } = useNotesEntity(entityOid, entityType);

  const onAddNewNote = () => {
    addNewNote(descendantToString(newNoteText));
    setNoteState({ ...state, isEditing: false, shouldHideOthers: false });
  };

  const cancelButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    setNoteState({ ...state, isEditing: false, shouldHideOthers: false });
    handleClose && handleClose(e);
  };

  const isCancelButtonExist = !!handleClose;

  const orgUsers = user?.actingAsMemberOfOrganization?.listOfUsersInOrganization;
  const sortedOrgUsers = useMemo(
    () => (orgUsers?.length && user?.oid ? sortUsers(orgUsers, user.oid).map(mapToLabel) : []),
    [orgUsers, user?.oid]
  );

  return (
    <ListItem
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: '8px',
        padding: '8px 12px 8px 16px',
        width: '100%',
      }}
    >
      <ListItemDecorator sx={{ flexShrink: 0 }}>
        <CustomAvatar
          index={generateColorFromOid(user?.oid ?? '')}
          size={16}
          userInitials={avatarFromName(user?.fullName ?? user?.firstName ?? '')}
          imgUrl={user?.profileImageUrl}
        />
      </ListItemDecorator>
      <ListItemContent sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '4px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography level="micro" sx={{ color: 'secondary.dark' }}>
            {user?.fullName}
          </Typography>
          <Box
            sx={{
              mx: '4px',
              borderRadius: '50%',
              width: '2px',
              height: '2px',
              background: 'var(--joy-palette-info-plainBorder)',
            }}
          />
          <Typography level="micro" sx={{ color: 'info.main' }}>
            {Translations.JUST_NOW}
          </Typography>
        </Box>
        <Stack direction={'column'} sx={{ justifyContent: 'space-between', alignItems: 'center', display: 'block' }}>
          <RichTextInputArea
            loadedValue={newNoteText}
            setValue={setNewNoteText}
            listOfLabels={sortedOrgUsers}
            placeholderText={Translations.NOTES_PLACEHOLDER}
            className={'parent-focused'}
            onCancelClick={isCancelButtonExist ? cancelButtonClick : undefined}
            onSaveClick={onAddNewNote}
            onSaveEnterHotkey={onAddNewNote}
            autoFocus={autoFocus}
            showToolbar
          />
        </Stack>
      </ListItemContent>
    </ListItem>
  );
}
