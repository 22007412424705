import { Box } from '@mui/joy';
import { FolderIcon, FolderIconImage } from 'components/FolderImage/style';

export const FolderImage = (props: { image?: string; width?: number; height?: number }) => {
  return (
    <Box sx={{ marginRight: '8px' }}>
      <Box sx={{ ...FolderIcon, width: props.width, height: props.height }}>
        {!!props.image && <img src={props.image} alt="ic_profile_picture" style={FolderIconImage} />}
      </Box>
    </Box>
  );
};
