import { useSelector } from 'react-redux';
import {
  EntityFilterItem,
  EntityFilterType,
  FilterIdentifier,
  FilterOption,
} from 'services/models/domain/entityFilter';
import {
  ConversationType,
  EntityPropertyDefinition,
  PropertyDefinitionDataType,
} from 'services/models/domain/entityViews';
import { foldersState } from 'services/store/slices/foldersSlice';
import { DateRangeField } from 'services/models/api/generated';
import { entityViewsState } from 'services/store/slices/entityViewsSlice';
import Translations from 'const/translations/en';
import { getFormattedDateRange, getDateRangeIdentifiers } from 'services/utils/dateUtils';
import { generateDateRangeFilterValue } from 'services/utils/chatTableFilterUtils';
import { LocalStorageValues } from 'services/utils/interface';
import { PageView } from 'page/chats/interface';
import { authState } from 'services/store/slices/authSlice';
import { getChatTypeIcon, getChatTypeName } from 'services/utils/nameCellUtils';

export const useFilterOptions = () => {
  const pageView = (localStorage.getItem(LocalStorageValues.PageView) as PageView) || PageView.Table;
  const { entityViews } = useSelector(entityViewsState);
  const { folders } = useSelector(foldersState);
  const { user } = useSelector(authState);
  const users = user?.actingAsMemberOfOrganization.listOfUsersInOrganization || [];
  // TODO (AWE): Make it cleaner
  let propertyDefinitions: EntityPropertyDefinition[] = [];
  if (entityViews.views.length > 0) {
    propertyDefinitions = entityViews.propertyDefinitions;
  }

  const dateRanges = generateDateRangeFilterValue();

  const getLabelForFilterItem = (filterItem: EntityFilterItem): { prefix?: string; title: string } => {
    const { filter } = filterItem;
    if (filter.type === EntityFilterType.Favorite) {
      return {
        title: Translations.FAVORITE_CHIP_TEXT,
      };
    }
    if (filter.type === EntityFilterType.DateRange) {
      let prefix = '';
      switch (filter.onField) {
        case DateRangeField.DateAdded: {
          prefix = Translations.DATE_ADDED;
          break;
        }
        case DateRangeField.LastFollowupDate: {
          prefix = Translations.FOLLOW_UPS_DUE;
          break;
        }
        case DateRangeField.LastMessageDate: {
          prefix = Translations.LATEST_MESSAGE;
          break;
        }
      }
      const title = filter.days
        ? getFormattedDateRange(undefined, undefined, filter.days)
        : getFormattedDateRange(
            filter.from ? new Date(filter.from) : undefined,
            filter.to ? new Date(filter.to) : undefined
          );

      return {
        prefix: `${prefix}${prefix ? ': ' : ''}`,
        title: title || '',
      };
    }
    if (filter.type === EntityFilterType.Folder) {
      if (filter.folderOids.length > 0) {
        return {
          title: `${filter.folderOids.length} selected`,
        };
      }
      return {
        title: `No folder selected`,
      };
    }

    if (filter.type === EntityFilterType.ConversationType) {
      const { filter } = filterItem;
      if ('conversationType' in filter) {
        return {
          title: getChatTypeName(filter.conversationType),
        };
      }
    }
    if (filter.type === EntityFilterType.Property) {
      const propertyDefinition = propertyDefinitions.find((pd) => pd.oid.oid === filter.propertyDefinitionOid);
      if (propertyDefinition) {
        const prefix = propertyDefinition.name;
        const propertyValueNames = filter.propertyValueOids
          .map((pvOid) => {
            const propertyValue = propertyDefinition.values.find((v) => 'oid' in v && v.oid.oid === pvOid);
            if (propertyValue?.type === 'OPTION') {
              return propertyValue.value;
            }
            if (propertyValue?.type === 'TENANT_USER') {
              return propertyValue.fullName;
            }
          })
          .filter((a) => !!a) as string[];
        return {
          prefix: `${prefix}:`,
          title: filter.noValue ? 'None' : propertyValueNames.join(', '),
        };
      }
    }
    if (filter.type === EntityFilterType.ChatMember) {
      const usernames = filter.participantOids
        .map((userOid) => users.find((u) => u.oid === userOid))
        .filter((a) => !!a)
        .map((u) => u?.fullName) as string[];
      return {
        prefix: 'Chat Members:',
        title: usernames.join(', '),
      };
    }
    if (filter.type === EntityFilterType.AddedByUser) {
      const usernames = filter.userOids
        .map((userOid) => users.find((u) => u.oid === userOid))
        .filter((a) => !!a)
        .map((u) => u?.fullName) as string[];
      return {
        prefix: 'Added By:',
        title: usernames.join(', '),
      };
    }
    if (filter.type === EntityFilterType.FollowUpsAssignedToUser) {
      const usernames = filter.followUpsAssignedTo
        .map((userOid) => users.find((u) => u.oid === userOid))
        .filter((a) => !!a)
        .map((u) => u?.fullName) as string[];

      let dateRange = dateRanges.find((dr) => dr.id === filter.dateRangeId)?.value;
      if (!dateRange) {
        dateRange = getDateRangeIdentifiers(
          filter.from ? new Date(filter.from) : undefined,
          filter.to ? new Date(filter.to) : undefined
        );
      }
      const title = filter.noAssignee ? `None` : `${usernames.join(', ')} - ${dateRange}`;
      return {
        prefix: 'Follow-Ups:',
        title: title,
      };
    }
    if (filter.type === EntityFilterType.EmptyFilterType) {
      return {
        prefix: 'Follow-Ups: ',
        title: 'None',
      };
    }
    return { title: '' };
  };

  const getIconForFilterItem = (filterItem: EntityFilterItem): string => {
    const { filter } = filterItem;
    switch (filterItem.filter.type) {
      case EntityFilterType.Favorite: {
        return 'ic_star';
      }
      case EntityFilterType.DateRange: {
        if ('onField' in filter) {
          switch (filter.onField) {
            case DateRangeField.LastMessageDate: {
              return 'ic_clock';
            }
            case DateRangeField.DateAdded: {
              return 'ic_clock';
            }
          }
        }
        return 'ic_calendar';
      }
      case EntityFilterType.Folder: {
        return 'ic_folders';
      }
      case EntityFilterType.Property: {
        switch (filterItem.filterOptionIdentifier) {
          case FilterIdentifier.Status: {
            return 'ic_status_change';
          }
          case FilterIdentifier.Owner: {
            return 'ic_crown';
          }
          case FilterIdentifier.Property: {
            return 'ic_crown';
          }
          case FilterIdentifier.Category: {
            return 'ic_category';
          }
          default:
            return 'ic_status_change';
        }
      }
      case EntityFilterType.ConversationType: {
        if ('conversationType' in filter) {
          if (filter.conversationType === ConversationType.Group) {
            return 'ic_user_groups';
          } else {
            return 'ic_heart_handshake';
          }
        }
        return '';
      }
      case EntityFilterType.ChatMember: {
        return 'ic_users';
      }
      case EntityFilterType.AddedByUser: {
        return 'ic_invite_teammates';
      }
      case EntityFilterType.FollowUpsAssignedToUser: {
        return 'ic_message_dialog';
      }
      case EntityFilterType.EmptyFilterType: {
        return 'ic_message_dialog';
      }
      default: {
        return '';
      }
    }
  };

  const options: FilterOption[][] = [
    [
      {
        identifier: FilterIdentifier.IndividualOrGroup,
        title: '1:1 or Group',
        icon: 'ic_square_circle',
        uniqueId: 'individual-or-group',
        subItems: [
          [
            {
              identifier: FilterIdentifier.IndividualOrGroup,
              title: getChatTypeName(ConversationType.Individual),
              icon: getChatTypeIcon(ConversationType.Individual),
              value: {
                conversationType: ConversationType.Individual,
              },
              uniqueId: '1:1',
            },
            {
              identifier: FilterIdentifier.IndividualOrGroup,
              title: getChatTypeName(ConversationType.Group),
              icon: getChatTypeIcon(ConversationType.Group),
              value: {
                conversationType: ConversationType.Group,
              },
              uniqueId: 'group',
            },
          ],
        ],
      },
      {
        identifier: FilterIdentifier.ChatMembers,
        icon: 'ic_users',
        title: `Chat Members`,
        uniqueId: 'chat-members',
        subItems: [
          [
            {
              icon: 'ic_users',
              title: 'Chat Members',
              identifier: FilterIdentifier.AddedBy,
              value: {
                // TODO (AWE): Proper handling
                participantOids: users.map((u) => u.oid),
              },
              uniqueId: 'chat-members',
            },
          ],
        ],
      },
      {
        identifier: FilterIdentifier.Folder,
        icon: 'ic_folders',
        title: `Folders`,
        uniqueId: 'folders',
        subItems: [
          [
            {
              icon: 'ic_folders',
              identifier: FilterIdentifier.Folder,
              value: {
                folderOids: folders.map((f) => f.oid.oid),
              },
              title: 'Folders',
              uniqueId: 'folders',
            },
          ],
        ],
      },
      {
        identifier: FilterIdentifier.LatestMessage,
        icon: 'ic_clock',
        title: `Latest Message`,
        subItems: [],
        uniqueId: 'latest-message',
      },
    ],
    [
      {
        identifier: FilterIdentifier.DateAdded,
        title: 'Date Added',
        icon: 'ic_calendar',
        subItems: [],
        uniqueId: 'date-added',
      },
      {
        identifier: FilterIdentifier.AddedBy,
        icon: 'ic_invite_teammates',
        title: `Added by`,
        uniqueId: 'added-by',
        subItems: [
          [
            {
              icon: 'ic_invite_teammates',
              title: 'Added by',
              identifier: FilterIdentifier.AddedBy,
              value: {
                // TODO (AWE): Proper handling
                userOids: users.map((u) => u.oid),
              },
              uniqueId: 'added-by',
            },
          ],
        ],
      },
    ],
    [
      {
        identifier: FilterIdentifier.Favorite,
        title: 'Favorite',
        icon: 'ic_star',
        value: { favorite: true },
        uniqueId: 'favorite',
      },
      {
        identifier: FilterIdentifier.FollowUps,
        icon: 'ic_message',
        title: `Follow-Ups`,
        subItems: [],
        uniqueId: 'follow-ups',
      },
    ],
    propertyDefinitions.reduce<FilterOption[]>((acc, pd) => {
      if (pd.dataType === PropertyDefinitionDataType.Value) {
        return acc;
      }
      const filterOption = {
        identifier: FilterIdentifier.Property,
        icon: pd.icon || 'ic_circle_dotted',
        title: pd.name,
        uniqueId: pd.oid.oid,
        subItems: [
          [
            {
              identifier: FilterIdentifier.Property,
              icon: pd.icon || 'ic_circle_dotted',
              title: pd.name,
              value: {
                propertyDefinitionOid: pd.oid.oid,
                propertyValueOids: [],
              },
              uniqueId: pd.oid.oid,
            },
          ],
        ],
      };
      acc.push(filterOption);
      return acc;
    }, []),
  ];

  const filterOutStatus = (options: FilterOption[][]) =>
    options.map((group) => group.filter((option) => option.title !== 'Status'));

  return {
    filterOptions: pageView === PageView.Kanban ? filterOutStatus(options) : options,
    getLabelForFilterItem,
    getIconForFilterItem,
  };
};
