import { EntityFilterItem } from 'services/models/domain/entityFilter';
import { EntityType } from 'services/models/domain/entityTypes';
import { IconsSvg } from 'assets/icons';

export type ValuePropertyValue = {
  value: string;
  propertyDefinitionOid: { oid: string };
  type: 'VALUE';
};

export type OptionPropertyValue = {
  oid: { oid: string };
  value: string;
  description?: string;
  ordering: number;
  color?: string;
  icon?: string;
  propertyDefinitionOid: { oid: string };
  type: 'OPTION';
};

export type TenantUserPropertyValue = {
  oid: { oid: string };
  firstName?: string;
  lastName?: string;
  username?: string;
  fullName: string;
  avatar?: string;
  propertyDefinitionOid: { oid: string };
  type: 'TENANT_USER';
};

export type DateRangeFilterValue = {
  id: string;
  value: string;
  color?: string;
  icon?: string;
  from?: number;
  to?: number;
};

export enum DefaultProperties {
  Status,
  Owner,
  Categories,
}

export enum PropertyDefinitionDataType {
  Option = 'OPTION',
  TenantUser = 'TENANT_USER',
  Value = 'VALUE',
}

export type PropertyValue = ValuePropertyValue | OptionPropertyValue | TenantUserPropertyValue;

export enum PropertyDefinitionKind {
  SingleValue = 'SINGLE_VALUE',
  MultiValue = 'MULTI_VALUE',
}

export enum PropertyDefinitionCategoryOption {
  Text = 'Text',
  Status = 'Status',
  Teammates = 'Teammates',
  SingleSelect = 'SingleSelect',
  MultiSelect = 'MultiSelect',
}

export type EntityPropertyDefinition = {
  oid: { oid: string };
  dataType: PropertyDefinitionDataType;
  description?: string;
  name: string;
  kind: PropertyDefinitionKind;
  values: PropertyValue[];
  icon: keyof typeof IconsSvg;
  visible: boolean;
  category: string | undefined;
  ordering: number;
  organizationOid: { oid: string } | undefined;
  isCustom: boolean;
};

export enum ViewDisplayType {
  Table = 'table',
  Kanban = 'kanban',
}

export type EntityViewCriteriaFilter = {
  favorite?: boolean;
  archived?: boolean;
  properties?: {
    propertyDefinitionOid: string;
    propertyValueOid: string;
  }[];
  folder?: string[];
  followUpAssignedTo?: string;
  conversationType?: ConversationType;
};

export enum DateRangeField {
  DATE_ADDED = 'DATE_ADDED',
  LAST_FOLLOWUP_DATE = 'LAST_FOLLOWUP_DATE',
}

export enum ConversationType {
  Group = 'GROUP',
  Individual = 'INDIVIDUAL',
}

export type LastDaysRange = {
  days: number;
  type: 'LastDaysRange';
};

export type TodayDateRange = {
  days: number;
  type: 'TodayDateRange';
};

export enum SortField {
  DateAdded = 'DATE_ADDED',
  LastFollowupDate = 'LAST_FOLLOWUP_DATE',
}

export enum EmptyFilterType {
  Followup = 'FOLLOWUP',
}

export enum SortDirectionType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type EntityViewSort = {
  field: SortField;
  direction: SortDirectionType;
};

export type DateRangeFilter = (LastDaysRange | TodayDateRange) & { onField: DateRangeField };

export type EntityViewCriteria = {
  filterItems: EntityFilterItem[];
  sort?: EntityViewSort;
};

export enum ViewType {
  Default = 'Default',
  Custom = 'Custom',
  Draft = 'Draft',
}

export type TableViewColumnOrder = {
  entityType: TableViewColumnEntity;
  oid: string | EntityViewField;
}[];

export type EntityView = {
  displayTypes: ViewDisplayType[];
  groupByPropertyDefinitionOid?: string;
  criteria?: EntityViewCriteria;
  title: string;
  icon?: string;
  amountEntities?: number;
  entityType: EntityType;
  oid: { oid: string };
  type: ViewType;
  propertyDefinitions: EntityPropertyDefinition[];
  visibleEntityFields: EntityViewField[];
  tableColumnOrder: TableViewColumnOrder;
};

export type DraftEntityView = {
  tempOid: { oid: string };
  title: string;
  icon: string;
  criteria?: EntityViewCriteria;
  displayTypes?: ViewDisplayType[];
  entityType: EntityType;
  amountEntities?: number;
  type: ViewType;
  propertyDefinitions: EntityPropertyDefinition[];
  visibleEntityFields: EntityViewField[];
  tableColumnOrder: TableViewColumnOrder;
};

export type EntityViews = {
  views: (EntityView | DraftEntityView)[];
  propertyDefinitions: EntityPropertyDefinition[];
  groupByPropertyDefinitionOid: string | undefined;
  visibleEntityFields: EntityViewField[];
  tableColumnOrder: TableViewColumnOrder;
  allVisiblePropertyDefinitionOids: string[];
};

export enum EntityViewField {
  DateAdded = 'DateAdded',
  Favorite = 'Favorite',
  Folder = 'Folder',
  FollowUp = 'FollowUp',
  LatestMessage = 'LatestMessage',
  LastModified = 'LastModified',
  Name = 'Name',
  None = 'None',
  Note = 'Note',
}

export enum TableViewColumnEntity {
  Default = 'Default',
  Property = 'Property',
}
