import { IconsSvg } from 'assets/icons';
import { StyledChip } from 'components/ui-library/chat/Chip/styled';
import { DEFAULT_CHIP_COLOR, HEX_REGEXP } from 'components/ui-library/chat/Chip/constants';
import { ChatChipProps, ChatChipStyle } from 'components/ui-library/chat/Chip/interface';
import { colors } from 'theme/colors';

function renderIcon(icon: string | undefined, color: string | undefined) {
  const Icon = icon && IconsSvg[icon as keyof typeof IconsSvg];

  if (Icon) {
    return <Icon stroke={color} width={14} height={14} />;
  }

  return null;
}

const isHex = (color: string) => HEX_REGEXP.test(color);

const ChatChip = (props: ChatChipProps) => {
  const { children, chipColor, icon, ...rest } = props;
  const color = chipColor && isHex(chipColor) ? chipColor : DEFAULT_CHIP_COLOR;
  const isWhite = color === colors.white;
  const iconColor = isWhite ? colors.neutral[50] : color;

  return (
    <StyledChip
      size="sm"
      variant="soft"
      chipColor={color}
      startDecorator={renderIcon(icon, iconColor)}
      isWhite={isWhite}
      chipStyle={props.chipStyle || ChatChipStyle.Round}
      {...rest}
    >
      {children}
    </StyledChip>
  );
};

export default ChatChip;
