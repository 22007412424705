import { Typography, Box, Tooltip } from '@mui/joy';
import { ReactComponent as SidebarIcon } from 'assets/icons/ic_sidebar.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/ic_telegram.svg';
import { CellSecondText, textEllipse } from 'components/Chats/ChatTableV2/TableCell/customCells/cellsContent/styles';
import TitleCard from 'components/TitleCard';
import { DetailsIconStyle, TelegramIconStyle } from 'components/Kanban/styles';
import { EntityDetailsType } from 'services/store/slices/sidebarSlice';
import { useSidebar } from 'services/hooks/useSidebar';
import Translations from 'const/translations/en';
import { DoubleLineTooltipContents } from 'components/TooltipContents';
import { Stack } from '@mui/material';
import PageLink from 'components/PageLink';
import { RouterPaths } from 'services/hooks/useNavigation';
import { AssociatedConversation } from 'services/models/domain/contact';
interface AssociatedConversationProps {
  associatedConversations: AssociatedConversation[];
}

export default function AssociatedConversationCell({ associatedConversations }: AssociatedConversationProps) {
  const { openSidebar } = useSidebar();
  if (!associatedConversations.length) {
    return null;
  }
  const { conversationOid, title, chatLink } = associatedConversations[0];
  return (
    <Box sx={styles.container}>
      <TitleCard
        title={
          <Stack direction="column" component="span" sx={{ overflow: 'hidden' }}>
            <PageLink to={RouterPaths.createDetailsChatPath(conversationOid.oid)} openInNewTab>
              {title}
            </PageLink>
            {associatedConversations.length > 1 && (
              <Tooltip
                title={
                  <Box component="span" sx={styles.tooltipContainer}>
                    {associatedConversations.map((chat: AssociatedConversation) => (
                      <Box key={chat.conversationOid.oid} sx={styles.AssociationContainer}>
                        <TitleCard
                          title={
                            <PageLink to={RouterPaths.createDetailsChatPath(chat.conversationOid.oid)} openInNewTab>
                              {chat.title}
                            </PageLink>
                          }
                          leftComponent={{
                            icon: TelegramIcon,
                            css: TelegramIconStyle(!!chat.chatLink),
                            link: chat.chatLink,
                            description: chat.chatLink ? (
                              Translations.OPEN_TELEGRAM_TOOLTIP
                            ) : (
                              <DoubleLineTooltipContents
                                primaryText={Translations.NO_CHAT_LINK_TOOLTIP_PRIMARY_MESSAGE}
                                secondaryText={Translations.NO_CHAT_LINK_TOOLTIP_SECONDARY_MESSAGE}
                              />
                            ),
                            showFilledIcon: true,
                          }}
                          rightComponent={{
                            icon: SidebarIcon,
                            description: Translations.OPEN_SIDEBAR_TOOLTIP,
                            css: DetailsIconStyle,
                            link: () => openSidebar(chat.conversationOid.oid, EntityDetailsType.chatDetails),
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                }
                placement="bottom"
                enterDelay={400}
                enterTouchDelay={400}
                enterNextDelay={400}
                componentsProps={{
                  root: {
                    sx: {
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                    },
                  },
                }}
              >
                <Typography level="tiny" sx={{ ...CellSecondText, cursor: 'default' }}>
                  {`+${associatedConversations.length - 1} more`}
                </Typography>
              </Tooltip>
            )}
          </Stack>
        }
        leftComponent={{
          icon: TelegramIcon,
          css: TelegramIconStyle(!!chatLink),
          link: chatLink,
          description: chatLink ? (
            Translations.OPEN_TELEGRAM_TOOLTIP
          ) : (
            <DoubleLineTooltipContents
              primaryText={Translations.NO_CHAT_LINK_TOOLTIP_PRIMARY_MESSAGE}
              secondaryText={Translations.NO_CHAT_LINK_TOOLTIP_SECONDARY_MESSAGE}
            />
          ),
          showFilledIcon: true,
        }}
        rightComponent={{
          icon: SidebarIcon,
          description: Translations.OPEN_SIDEBAR_TOOLTIP,
          css: DetailsIconStyle,
          link: () => openSidebar(conversationOid.oid, EntityDetailsType.chatDetails),
        }}
      />
    </Box>
  );
}

const styles = {
  container: {
    width: '230px',
  },
  tooltipContainer: {
    width: '275px',
    maxHeight: '175px',
    overflowY: 'auto',
    marginTop: '-12px',
    marginRight: '-8px',
    cursor: 'default',
    background: `white`,
    borderRadius: '6px',
    border: `1px solid var(--neutral-5, #F3F5F7)`,
    boxShadow: `0px 0px 20px 0px rgba(0, 0, 0, 0.10), 0px 0px 2px 0px rgba(0, 0, 0, 0.09)`,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    p: '8px',
  },
  AssociationContainer: {
    width: '100%',
    backgroundColor: 'white',
    p: '7px 8px',
    color: 'secondary.dark',
    fontSize: '14px',
  },
};
