import { DraftEntityView, EntityView, SortField } from 'services/models/domain/entityViews';
import { useDispatch, useSelector } from 'react-redux';
import * as conversationFilterSlice from 'services/store/slices/conversationsFilterSlice';
import * as gql from 'services/models/api/generated';
import { clearEntityFilters, setEntityFilters } from 'services/store/slices/entityFilterSlice';
import { EntityType } from 'services/models/domain/entityTypes';
import { useEffect } from 'react';
import { sideNavState } from 'services/store/slices/sideNavSlice';
import { CHAT_RECORDS, CONTACT_RECORDS, REPORT_RECORDS } from 'services/utils/dashboardHeaderUtils';
import { useEntityViews } from 'services/hooks/useEntityViews';
import { NavigationPath, RouterPaths, useNavigation } from 'services/hooks/useNavigation';

export const useNavigationSidebarFilters = (editMode: boolean) => {
  const navigate = useNavigation();
  const dispatch = useDispatch();

  const { selectedItem } = useSelector(sideNavState);
  const { getEntityViewByOid } = useEntityViews();

  useEffect(() => {
    if (selectedItem.id) {
      selectEntityView(selectedItem.id);
    }
  }, [selectedItem]);

  const selectEntityView = (oid: string) => {
    const entityView = getEntityViewByOid(oid);
    // !to be removed based on requirement
    if (entityView?.entityType === EntityType.Conversation) {
      selectConversationView(entityView);
    }
    // ! need to update backend to have contact in their entity type as Contact
    if (entityView?.entityType === EntityType.ContactRecord) {
      selectContactView(entityView);
    }

    if (oid === CHAT_RECORDS) {
      selectConversationView(undefined);
    }
    if (oid === CONTACT_RECORDS) {
      selectContactView(undefined);
    }
    if (oid === REPORT_RECORDS) {
      selectReportsView();
    }
  };

  const selectConversationView = (entityView?: EntityView | DraftEntityView) => {
    if (window.location.pathname.includes('/chats')) {
      navigate(window.location.pathname as NavigationPath, { replace: true });
    } else {
      navigate(RouterPaths.Chats, { replace: true });
    }

    const criteria = entityView?.criteria;
    if (criteria?.filterItems && criteria.filterItems.length > 0) {
      dispatch(setEntityFilters({ filterItems: criteria?.filterItems }));
    } else if (!editMode) {
      dispatch(clearEntityFilters());
      dispatch(conversationFilterSlice.clearFilter());
    }

    if (criteria?.sort) {
      let sortByField;
      switch (criteria.sort.field) {
        case SortField.DateAdded: {
          sortByField = gql.SortConversationsOnPropertyType.DateAdded;
          break;
        }
        case SortField.LastFollowupDate: {
          sortByField = gql.SortConversationsOnPropertyType.LastFollowupDate;
          break;
        }
      }
      if (sortByField) {
        dispatch(
          conversationFilterSlice.handleSortByField({
            sortByField: gql.SortConversationsOnPropertyType.DateAdded,
            sortDirection: criteria.sort.direction,
          })
        );
      }
    }
  };

  const selectContactView = (entityView?: EntityView | DraftEntityView) => {
    navigate(RouterPaths.Contacts, { replace: true });
  };

  const selectReportsView = () => {
    navigate(RouterPaths.Reports, { replace: true });
  };

  return {
    selectEntityView,
    selectContactView,
    selectConversationView,
  };
};
