import { useDispatch, useSelector } from 'react-redux';
import { EntityType } from 'services/models/domain/entityTypes';
import { EntityPropertyDefinition, PropertyDefinitionCategoryOption } from 'services/models/domain/entityViews';
import {
  addPropertyDefinition,
  updatePropertyDefinition as updateDefinition,
  deletePropertyDefinition as deleteDefinition,
  entityViewsState,
} from 'services/store/slices/entityViewsSlice';
import * as gql from 'services/models/api/generated';
import {
  getPropertyDefinitionKind,
  getPropertyDefinitionCategory,
  getPropertyDefinitionIcon,
  getPropertyDefinitionDataType,
  getPropertyDefinitionInitialValues,
} from 'services/utils/propertyDefinitionCategoryUtils';
import { usePropertiesData } from 'services/hooks/usePropertiesData';
import { authState } from 'services/store/slices/authSlice';

export const usePropertyDefinitions = () => {
  const dispatch = useDispatch();
  const { addVisiblePropertyDefinitionToView } = usePropertiesData();
  const { user } = useSelector(authState);

  const [createProperty] = gql.useCreatePropertyDefinitionMutation();
  const [updateProperty] = gql.useUpdatePropertyDefinitionMutation();
  const [deleteProperty] = gql.useDeletePropertyDefinitionMutation();

  const { entityViews } = useSelector(entityViewsState);

  /**
   * Create new property definition
   * @param {string} name - name of the new property
   * @param {PropertyDefinitionCategoryOption} category - category of the property
   */
  const createPropertyDefinition = async (name: string, category: PropertyDefinitionCategoryOption) => {
    const input: gql.CreatePropertyDefinitionInput = {
      name,
      description: name,
      dataType: getPropertyDefinitionDataType(category) as gql.PropertyDefinitionDataType,
      kind: getPropertyDefinitionKind(category),
      entityType: EntityType.Conversation,
      category: getPropertyDefinitionCategory(category) as gql.PropertyDefinitionCategory,
      icon: getPropertyDefinitionIcon(category),
      ordering: entityViews.propertyDefinitions.length + 1,
    };

    const response = await createProperty({ variables: { input } });
    const data = response.data?.createPropertyDefinition;
    if (data) {
      const propertyDefinition: EntityPropertyDefinition = {
        oid: { oid: data.oid },
        dataType: getPropertyDefinitionDataType(category),
        name,
        description: name,
        kind: getPropertyDefinitionKind(category),
        values: getPropertyDefinitionInitialValues(category, data.oid, user),
        icon: getPropertyDefinitionIcon(category),
        visible: false,
        category: getPropertyDefinitionCategory(category) ?? undefined,
        ordering: data.ordering,
        organizationOid: user ? { oid: user.actingAsMemberOfOrganization.oid } : undefined,
        isCustom: true,
      };
      dispatch(addPropertyDefinition({ propertyDefinition }));
      addVisiblePropertyDefinitionToView(data.oid);
    }
  };

  /**
   * Update property definition
   * @param {string} oid - propert definition id to update
   * @param {string} updatedName - new property definition name
   */
  const updatePropertyDefinition = async (oid: string, updatedName: string) => {
    const input = {
      propertyDefinitionOid: oid,
      name: updatedName,
      description: updatedName,
      //ordering: Int
    };
    await updateProperty({ variables: { input } });

    dispatch(updateDefinition({ propertyDefinitionOid: oid, updatedName }));
  };

  /**
   * Delete already existing property
   * @param {string} oid - propert definition id to delete
   */
  const deletePropertyDefinition = async (oid: string) => {
    await deleteProperty({ variables: { input: { oid } } });
    dispatch(deleteDefinition({ propertyDefinitionOid: oid }));
  };

  return {
    createPropertyDefinition,
    updatePropertyDefinition,
    deletePropertyDefinition,
  };
};
