import { SxProps } from '@mui/material';
import { colors } from 'theme/colors';

export const ButtonSx: SxProps = {
  width: '100%',
  height: '100%',
  '&:focus-visible': {
    outline: 'none',
  },
};

export const MenuSx: SxProps = {
  '& .MuiPaper-root': {
    maxHeight: '320px',
  },
};

export const MenuItemSx: SxProps = {
  '&:hover': {
    background: `${colors.neutral[0]}`,
  },
  padding: '8px 12px',
};

export const SelectedMenuItemSx: SxProps = {
  ...MenuItemSx,
  borderBottom: `1px solid ${colors['neutral']['10']}`,
  alignItems: 'space-between',
};

export const CloseIconSx: SxProps = {
  position: 'absolute',
  right: 0,
};

export const UserBoxSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  width: '100%',
  cursor: 'pointer',
};

export const NameSx: SxProps = {
  color: 'palette.text.secondary',
  lineHeight: '16px',
  letterSpacing: '-0.005em',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

export const AvatarSx: SxProps = {
  border: `1px solid ${colors.neutral[5]}`,
};

export const ButtonNameSx: SxProps = {
  color: 'secondary.dark',
  lineHeight: '16px',
  letterSpacing: '-0.005em',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};
