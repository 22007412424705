import { DateTime } from 'luxon';
import {
  DateRangeField,
  EntityFilter,
  EntityFilterItem,
  EntityFilterType,
  FilterIdentifier,
  FilterOption,
  FilterOptionValue,
} from 'services/models/domain/entityFilter';
import { DateRangeFilterValue } from 'services/models/domain/entityViews';
import { colors } from 'theme/colors';

export const generateDateRangeFilterValue = (): DateRangeFilterValue[] => [
  {
    id: '1',
    value: 'Overdue',
    icon: 'ic_overdue',
    color: colors.neutral[60],
    to: DateTime.local().toMillis(),
  },
  {
    id: '2',
    icon: 'ic_today',
    value: 'Today',
    color: colors.neutral[60],
    from: DateTime.local().toMillis(),
    to: DateTime.local().toMillis(),
  },
  {
    id: '3',
    value: 'Upcoming',
    icon: 'ic_upcoming',
    color: colors.neutral[60],
    from: DateTime.local().toMillis(),
  },
];

// TODO (AWE): Find better solution to any for value
export const getFilterForFilterOption = (filterOption: FilterOption): EntityFilter | undefined => {
  if ('value' in filterOption) {
    const { value } = filterOption;
    switch (filterOption.identifier) {
      case FilterIdentifier.Favorite:
        if ('favorite' in value) {
          return {
            type: EntityFilterType.Favorite,
            favorite: value.favorite,
            uniqueId: 'favorite',
          };
        }
        break;
      case FilterIdentifier.IndividualOrGroup:
        if ('conversationType' in value) {
          return {
            conversationType: value.conversationType,
            type: EntityFilterType.ConversationType,
            uniqueId: 'individual-or-group',
          };
        }
        break;
      case FilterIdentifier.DateAdded:
        if (('to' in value || 'from' in value || 'days' in value) && !('userOids' in value)) {
          if (value.to || value.from) {
            return {
              type: EntityFilterType.DateRange,
              from: value.from?.getTime(),
              to: value.to?.getTime(),
              days: 'days' in value ? value.days : undefined,
              onField: DateRangeField.DateAdded,
              uniqueId: 'date-added',
            };
          }
        }
        break;
      case FilterIdentifier.LatestMessage:
        if (('to' in value || 'from' in value || 'days' in value) && !('userOids' in value)) {
          if (value.to || value.from) {
            return {
              type: EntityFilterType.DateRange,
              from: value.from?.getTime(),
              days: 'days' in value ? value.days : undefined,
              to: value.to?.getTime(),
              onField: DateRangeField.LastMessageDate,
              uniqueId: 'latest-message',
            };
          }
        }
        break;
      case FilterIdentifier.Folder:
        if ('folderOids' in value && value.folderOids.length > 0) {
          return {
            type: EntityFilterType.Folder,
            folderOids: value.folderOids,
            uniqueId: 'folders',
          };
        }
        break;
      case FilterIdentifier.ChatMembers:
        if ('participantOids' in value && value.participantOids.length > 0) {
          return {
            type: EntityFilterType.ChatMember,
            participantOids: value.participantOids,
            uniqueId: 'chat-members',
          };
        }
        break;
      case FilterIdentifier.AddedBy:
        if ('userOids' in value && value.userOids.length > 0) {
          return {
            type: EntityFilterType.AddedByUser,
            userOids: value.userOids,
            uniqueId: 'added-by',
          };
        }
        break;
      case FilterIdentifier.FollowUps:
        if (('followUpsAssignedTo' in value || 'noFollowupAssigned') && 'dateRangeId' in value) {
          if (value.followUpsAssignedTo.length > 0 || value.noFollowupAssigned) {
            return {
              type: EntityFilterType.FollowUpsAssignedToUser,
              followUpsAssignedTo: value.followUpsAssignedTo || [],
              dateRangeId: value.dateRangeId,
              from: value.from?.getTime(),
              to: value.to?.getTime(),
              onField: DateRangeField.LastFollowupDate,
              noAssignee: value.noFollowupAssigned || false,
              uniqueId: 'follow-ups',
            };
          }
        }
        break;
      case FilterIdentifier.EmptyFilterType:
        if ('emptyFilterType' in value) {
          return {
            type: EntityFilterType.EmptyFilterType,
            emptyFilterType: value.emptyFilterType,
            uniqueId: 'empty-filter-type',
          };
        }
        break;
      case FilterIdentifier.Status:
      case FilterIdentifier.Category:
      case FilterIdentifier.Owner:
      case FilterIdentifier.Property:
        if ('propertyDefinitionOid' in value) {
          if (value.noPropertyDefinitionOid) {
            return {
              type: EntityFilterType.Property,
              propertyDefinitionOid: value.propertyDefinitionOid,
              propertyValueOids: [],
              noValue: true,
              uniqueId: value.propertyDefinitionOid,
            };
          }
          if (value.propertyValueOids.length > 0) {
            return {
              type: EntityFilterType.Property,
              propertyDefinitionOid: value.propertyDefinitionOid,
              propertyValueOids: value.propertyValueOids,
              noValue: false,
              uniqueId: value.propertyDefinitionOid,
            };
          }
        }
        break;
    }
  }
};

export const filterOptionValueFromFilter = (filter: EntityFilter): FilterOptionValue | undefined => {
  switch (filter.type) {
    case EntityFilterType.Favorite: {
      return {
        favorite: filter.favorite,
      };
    }
    case EntityFilterType.ChatMember: {
      return { participantOids: filter.participantOids };
    }
    case EntityFilterType.AddedByUser: {
      return { userOids: filter.userOids };
    }
    case EntityFilterType.FollowUpsAssignedToUser: {
      return {
        followUpsAssignedTo: filter.followUpsAssignedTo,
        dateRangeId: filter.dateRangeId,
        from: filter.from ? new Date(filter.from) : undefined,
        to: filter.to ? new Date(filter.to) : undefined,
        noFollowupAssigned: filter.noAssignee,
      };
    }
    case EntityFilterType.Property: {
      return {
        propertyDefinitionOid: filter.propertyDefinitionOid,
        propertyValueOids: filter.propertyValueOids,
        noPropertyDefinitionOid: filter.noValue ? filter.propertyDefinitionOid : undefined,
      };
    }
    case EntityFilterType.DateRange: {
      if (filter.from || filter.to) {
        return {
          from: filter.from ? new Date(filter.from) : undefined,
          to: filter.to ? new Date(filter.to) : undefined,
          days: filter.days,
        };
      }
      break;
    }
    case EntityFilterType.Folder: {
      return {
        folderOids: filter.folderOids,
      };
    }
    case EntityFilterType.ConversationType: {
      return { conversationType: filter.conversationType };
    }
    case EntityFilterType.EmptyFilterType: {
      return { emptyFilterType: filter.emptyFilterType };
    }
  }
};

export const addValueForFilterOptionWithFilterItem = (
  filterOption: FilterOption,
  filterItem: EntityFilterItem
): FilterOption => {
  const value = filterOptionValueFromFilter(filterItem.filter);
  if (!value) {
    return filterOption;
  }

  return {
    ...filterOption,
    value: value,
  };
};
