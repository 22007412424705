import { Button } from '@mui/joy';
import EditProperty from 'components/Chats/ChatTableV2/EditProperty/EditProperty';
import PropertyChip from 'components/Chats/ChatTableV2/TableCell/customCells/PropertyTypeahead/PropertyChip';
import { MutiRowBox } from 'components/MultiRowBox';
import TypeAheadInput from 'components/TypeAheadInput';
import useFlags from 'launchdarkly-react-client-sdk/lib/useFlags';
import { useEffect, useMemo, useState } from 'react';
import { useSafeGridApiContext } from 'services/hooks/useSafeGridApiContext';
import { useTypeAheadPropertyValues } from 'services/hooks/useTypeAheadPropertyValues';
import {
  EntityPropertyDefinition,
  OptionPropertyValue,
  PropertyDefinitionDataType,
  PropertyDefinitionKind,
  TenantUserPropertyValue,
} from 'services/models/domain/entityViews';
import { FeatureFlags } from 'services/models/domain/featureFlags';
import { colors } from 'theme/colors';
interface PropertyTypeAheadProps {
  propertyDefinition: EntityPropertyDefinition;
  values: string[];
  entityId: string;
}

export default function PropertyTypeAhead({
  propertyDefinition,
  values: initialValueOids,
  entityId,
}: PropertyTypeAheadProps) {
  const { updatePropertyValues, filterOptions, getTextFromOption, createOption, propertiesSelectorOpen } =
    useTypeAheadPropertyValues(entityId, initialValueOids, propertyDefinition);

  const apiRef = useSafeGridApiContext();
  const flags = useFlags<FeatureFlags>();
  const definitionValues = propertyDefinition.values as (OptionPropertyValue | TenantUserPropertyValue)[];
  const selectedProperties = useMemo(() => {
    return definitionValues.filter(({ oid }) => initialValueOids.includes(oid.oid));
  }, [definitionValues, initialValueOids]);

  const isMultiValue = propertyDefinition.kind === PropertyDefinitionKind.MultiValue;
  const isOptionDataType = propertyDefinition.dataType === PropertyDefinitionDataType.Option;
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    const handleCellClick = (params: any) => {
      const selectedIds = apiRef ? Array.from(apiRef.current.getSelectedRows().keys()) : [];
      const isSelected = selectedIds.includes(entityId) && selectedIds.includes(params.id);
      if (isSelected) {
        const columnField = params.field;
        if (columnField === propertyDefinition.oid.oid) {
          setSelected(true);
        }
      } else {
        setSelected(false);
      }
    };
    if (apiRef) {
      apiRef.current.subscribeEvent('cellClick', handleCellClick);
      apiRef.current.subscribeEvent('cellMouseDown', (params: any) => {
        setSelected(params.columnField === propertyDefinition.oid.oid);
      });
    }
  }, []);

  return (
    <TypeAheadInput
      isMultiValue={isMultiValue}
      isEditable={isOptionDataType}
      anchorComponent={(handleOpen) => {
        return (
          <Button
            id="property-menu-button"
            aria-haspopup="true"
            variant="plain"
            sx={
              selected && flags.batchPropertiesSelection && propertiesSelectorOpen
                ? { ...style.cellButton, border: `2px solid ${colors.primary[50]}`, borderRadius: 0 }
                : style.cellButton
            }
            onClick={handleOpen}
          >
            <MutiRowBox maxLines={2} showMultiLines={isMultiValue}>
              {selectedProperties.map((sp) => (
                <PropertyChip key={sp.oid.oid} propertyValue={sp} isMultiValue={isMultiValue} />
              ))}
            </MutiRowBox>
          </Button>
        );
      }}
      options={definitionValues}
      initialValues={selectedProperties}
      EditItemComponent={
        isOptionDataType
          ? (handleBackClick) => (
              <EditProperty
                handleBackClick={handleBackClick}
                property={propertyDefinition}
                refetch={() => Promise.resolve()}
              />
            )
          : undefined
      }
      renderItemChip={(propertyValue, tagProps) => {
        if (typeof propertyValue === 'string') {
          return <span>{propertyValue}</span>;
        }
        return (
          <PropertyChip
            key={propertyValue.oid.oid}
            propertyValue={propertyValue}
            tagProps={tagProps}
            isMultiValue={isMultiValue}
          />
        );
      }}
      getTextFromOption={getTextFromOption}
      onAutocompleteClose={updatePropertyValues}
      filterOptions={filterOptions}
      createOption={createOption}
      onClose={() => setSelected(false)}
    />
  );
}

const style = {
  cellButton: {
    width: '100%',
    height: '100%',
    '&:focus-visible': {
      outline: 'none',
    },
    padding: '9px 10px',
    borderRadius: '0px',
  },
};
