import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Box, Typography } from '@mui/joy';
import UIChip from 'components/ui-library/Chip/UIChip';
import { colors } from 'theme/colors';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import FilterDivider from 'components/FilterDivider';
import { ToggleButtonActiveSx, ToggleButtonInactiveSx } from 'page/chats/ChatsTableFilter/styles';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { IconsSvg } from 'assets/icons';
import { getDateRangeString } from 'services/utils/dateUtils';
import { useEffect } from 'react';
import UIButton from 'components/ui-library/Button/UIButton';
import { ApplyFilterButton } from 'page/chats/ChatTableFilterBar/FilterOptions/ApplyFilterButton';
import _ from 'lodash';
import { date } from 'yup';

type ShortcutItem = {
  id: string;
  label: string;
  days: number;
  onClick: () => void;
};

interface FilterDateSelectorProps {
  title: string;
  dateRange?: {
    from?: Date;
    to?: Date;
  };
  onDatesSelected: (value: DateRange<DateTime>, days?: number) => void;
  onClose: () => void;
}

enum ToggleButtonValue {
  Before = 'Before',
  Since = 'Since',
  On = 'On',
}

const FilterDateSelector = ({ title, onDatesSelected, onClose, dateRange }: FilterDateSelectorProps) => {
  const calculateDateRange = () => {
    let dateTimeRange: DateRange<DateTime> = [null, null];
    if (dateRange) {
      dateTimeRange = [
        dateRange.from ? DateTime.fromJSDate(dateRange.from) : null,
        dateRange.to ? DateTime.fromJSDate(dateRange.to) : null,
      ];
    }
    return dateTimeRange;
  };

  const [value, setValue] = React.useState<DateRange<DateTime>>([null, null]);
  const [initialValue, setInitialValue] = React.useState<DateRange<DateTime>>([null, null]);
  const [selectedShortcut, setSelectedShortcut] = React.useState<ShortcutItem | undefined>(undefined);
  const [toggleButton, setToggleButton] = React.useState<string>(ToggleButtonValue.Since as string);
  const dateRangeDefined = React.useMemo(() => {
    const dateRangeDefined = getDateRangeString(calculateDateRange());
    return dateRangeDefined;
  }, [dateRange]);

  useEffect(() => {
    const calculatedDateRange = calculateDateRange();
    setInitialValue(calculatedDateRange);
    setValue(calculatedDateRange);
    if (dateRangeDefined) {
      const from = calculatedDateRange[0]?.startOf('day');
      const to = calculatedDateRange[1]?.endOf('day');
      if (from && to && from.hasSame(to, 'day')) {
        changeToggleButtonValue(ToggleButtonValue.On);
      }
      if (from && !to) {
        changeToggleButtonValue(ToggleButtonValue.Since);
      } else if (!from && to) {
        changeToggleButtonValue(ToggleButtonValue.Before);
      }
    }
  }, []);

  useEffect(() => {
    setValue(calculateDateRange());
  }, [dateRange]);

  const generateShortcutItem = (id: string, label: string, days: number) => ({
    id,
    label,
    days,
    onClick: () => {
      setValue([DateTime.local().minus({ days }), DateTime.local()]);
      onDatesSelected([DateTime.local().minus({ days }), DateTime.local()], days);
    },
  });

  const shortcutsItems: ShortcutItem[] = [
    generateShortcutItem('1', 'Past 24h', 1),
    generateShortcutItem('2', 'Past 7d', 7),
    generateShortcutItem('3', 'Past 14d', 14),
    generateShortcutItem('4', 'Past 30d', 30),
    generateShortcutItem('5', 'Past 3m', 90),
  ];

  const renderUIChipShortcutsItem = (item: ShortcutItem) => (
    <Box>
      <UIChip
        variant={selectedShortcut?.id === item.id ? 'soft' : 'outlined'}
        color={selectedShortcut?.id === item.id ? 'info' : 'primary'}
        size="md"
        sx={styles.chipStyles}
        onClick={() => {
          setSelectedShortcut(item);
          item.onClick();
        }}
      >
        <Typography
          level="tiny"
          sx={{
            color: selectedShortcut?.id === item.id ? colors.primary[50] : colors.neutral[50],
            width: '75px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {item.label}
        </Typography>
      </UIChip>
    </Box>
  );

  const changeToggleButtonValue = (button: ToggleButtonValue | null) => {
    if (button) {
      switch (button) {
        case ToggleButtonValue.Before:
          setToggleButton(ToggleButtonValue.Before);
          setValue([null, value[1] ?? value[0]]);
          onDatesSelected([null, value[1] ?? value[0]]);
          return;
        case ToggleButtonValue.On:
          setToggleButton(ToggleButtonValue.On);
          if (value[0] || value[1]) {
            const selectedDate = value[0] || value[1] || DateTime.local();
            const from: DateTime = selectedDate.startOf('day');
            const to: DateTime = selectedDate.endOf('day');
            setValue([from, to]);
            onDatesSelected([from, to]);
          }
          return;
        case ToggleButtonValue.Since:
          setToggleButton(ToggleButtonValue.Since);
          setValue([value[1] ?? value[0], null]);
          onDatesSelected([value[1] ?? value[0], null]);
          return;
      }
    }
  };

  const renderToggleButton = (button: ToggleButtonValue, icon?: string) => {
    const Icon = icon ? IconsSvg[icon as keyof typeof IconsSvg] : undefined;

    return (
      <ToggleButton
        value={button}
        aria-label="left aligned"
        sx={{
          ...(button === toggleButton ? ToggleButtonActiveSx : ToggleButtonInactiveSx),
          width: '200px',
        }}
      >
        {Icon && (
          <Icon width={12} height={12} stroke={colors.neutral[50]} style={{ marginTop: '2px', marginRight: '5px' }} />
        )}
        <Typography level="micro">{`${button} ${dateRangeDefined}`}</Typography>
      </ToggleButton>
    );
  };

  const renderToggleButtons = () => {
    return (
      <Box sx={styles.toggleButtonContainer}>
        <ToggleButtonGroup
          value={toggleButton}
          exclusive
          onChange={(e, button) => changeToggleButtonValue(button as ToggleButtonValue)}
          aria-label="text alignment"
          size="small"
          sx={styles.toggleButtonGroup}
        >
          {renderToggleButton(ToggleButtonValue.Before, 'ic_before')}
          {renderToggleButton(ToggleButtonValue.On)}
          {renderToggleButton(ToggleButtonValue.Since, 'ic_since')}
        </ToggleButtonGroup>
      </Box>
    );
  };

  return (
    <Box>
      <Typography level="tiny" sx={{ p: 2, color: colors.neutral[50] }}>
        {title}
      </Typography>
      <Box sx={{ ...styles.boxMargin, display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
        {shortcutsItems.map(renderUIChipShortcutsItem)}
      </Box>
      <FilterDivider />
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DateRangeCalendar
          value={value}
          onChange={(newValue) => {
            if (selectedShortcut) {
              setSelectedShortcut(undefined);
            }
            setToggleButton(ToggleButtonValue.Since);
            setValue(newValue);
            onDatesSelected(newValue);
          }}
        />
      </LocalizationProvider>

      {dateRangeDefined && (
        <>
          <FilterDivider />
          {renderToggleButtons()}
        </>
      )}
      {!_.isEqual(initialValue, value) && <ApplyFilterButton onClick={onClose} sx={styles.closeButton} />}
    </Box>
  );
};

export default FilterDateSelector;

const styles = {
  chipStyles: {
    alignSelf: 'center',
    borderRadius: '6px',
    marginRight: '8px',
    fontWeight: 200,
  },
  boxMargin: {
    marginX: '10px',
  },
  toggleButtonGroup: {
    height: '30px',
    mt: '20px',
    width: '95%',
    p: '1px',
    backgroundColor: colors.neutral[5],
    border: `1px solid ${colors.neutral[20]}`,
    borderRadius: '6px',
    gap: '4px',
  },
  toggleButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '20px',
    marginTop: '-20px',
    justifyContent: 'center',
  },
  closeButton: { width: 'calc(100% - 23px)', margin: '12px' },
};
