import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_PER_PAGE, POLL_INTERVAL } from 'services/constants';
import * as gql from 'services/models/api/generated';
import { chatFilters, IChatFilters } from 'services/store/slices/conversationsFilterSlice';
import {
  addConversations,
  setConversations,
  setLoading,
  updatePagination,
} from 'services/store/slices/pipelineConversationsSlice';
import { EntityPropertyDefinition } from 'services/models/domain/entityViews';
import { useApolloClient } from '@apollo/client';
import { ChatsPageCoversationsDocument, ChatsPageCoversationsQuery } from 'services/models/api/generated';
import {
  mapConversationToDomain,
  mapPropertyValueToApi,
  updateIndividualChatName,
  updateUserInConversation,
} from 'services/utils/conversationUtils';
import { Conversation } from 'services/models/domain/conversation';
import _ from 'lodash';
import { entityFilter } from 'services/store/slices/entityFilterSlice';
import { EntityType } from 'services/models/domain/entityTypes';
import { mapFilterItemsToConversationQuery } from 'services/utils/entityFilterUtils';
import { userTgState } from 'services/store/slices/userTgSlice';
import { RootState } from 'services/store/AppStore';
import { authState } from 'services/store/slices/authSlice';
import { sideNavState } from 'services/store/slices/sideNavSlice';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from 'services/models/domain/featureFlags';

export function useFetchPipelineConversations(pageSize: number, propertyDefinition?: EntityPropertyDefinition) {
  const { user } = useSelector(authState);
  const { selectedItem } = useSelector(sideNavState);
  const filterForm = useSelector(chatFilters);
  const { filterItems } = useSelector(entityFilter);
  const { conversationsSynced } = useSelector(userTgState);
  const conversationFilterItems = filterItems.filter(
    (f) => f.entityType === EntityType.Conversation || f.entityType === EntityType.ContactRecord
  );

  const dispatch = useDispatch();
  const [initial, setInitial] = useState(true);
  const flags = useFlags<FeatureFlags>();

  const generateInput = (limit: number, pageNumber: number, filterForm: IChatFilters) => {
    return {
      input: {
        ...filterForm.input,

        pageRequest: {
          limit: limit,
          pageNumber: pageNumber,
        },
      },
      criteria: { ...filterForm.criteria },
    };
  };

  useEffect(() => {
    setInitial(false);
  }, []);

  const client = useApolloClient();

  const loadConversationsByPropertyDefinition = async (reload?: boolean): Promise<Conversation[]> => {
    if (!propertyDefinition) {
      return [];
    }

    const oids = propertyDefinition.values
      .map((pv) => {
        if (pv && 'oid' in pv) {
          return pv.oid.oid;
        }
        return undefined;
      })
      .filter((pv) => !!pv) as string[];
    const withNoStatus = [undefined, ...oids];

    if (reload) {
      dispatch(
        setConversations({
          conversations: [],
        })
      );
    }

    const fetchF = withNoStatus.map(async (oid) => {
      if (reload) {
        dispatch(
          updatePagination({
            total: undefined,
            propertyValueOid: oid,
          })
        );
      }
      return await loadConversationsByPropertyValue(0, oid);
    });

    const conversations = await Promise.all(fetchF);
    const conversationsFlat = conversations.flat();
    return conversationsFlat;
  };

  const loadConversationsByPropertyValue = async (
    offset: number,
    propertyValueOid?: string
  ): Promise<Conversation[]> => {
    if (!propertyDefinition) {
      return [];
    }
    const existingPropertyValuesFilter =
      filterForm.input.filterOn.propertyValues?.filter((p) => p.propertyDefinitionOid !== propertyDefinition.oid.oid) ||
      [];

    let propertyValues: gql.FilterOnPropertyValue[] = existingPropertyValuesFilter;
    let noPropertyalues: gql.NoPropertyValue[] = [];
    if (propertyValueOid) {
      propertyValues = [
        ...propertyValues,
        {
          oid: propertyValueOid,
          propertyDefinitionOid: propertyDefinition.oid.oid,
        },
      ];
    } else {
      noPropertyalues = [
        {
          propertyDefinitionOid: propertyDefinition.oid.oid,
        },
      ];
    }

    const query = mapFilterItemsToConversationQuery(conversationFilterItems);
    let search = '';
    if (query.input.search) {
      search = query.input.search;
    }
    if (filterForm.input.search !== '') {
      search = filterForm.input.search;
    }

    const mappedFilterForm: IChatFilters = {
      ...filterForm,
      input: {
        search: search,
        sortOn: filterForm.input.sortOn,
        filterOn: {
          ...query.input.filterOn,
          propertyValues: [
            ...propertyValues,
            ...(query.input.filterOn.propertyValues?.filter(
              (p) => p.propertyDefinitionOid !== propertyDefinition.oid.oid
            ) ?? []),
          ],
          noPropertyValues: [
            ...noPropertyalues,
            ...(query.input.filterOn.noPropertyValues?.filter(
              (p) => p.propertyDefinitionOid !== propertyDefinition.oid.oid
            ) ?? []),
          ],
        },
        dateRange: query.input.dateRange,
      },
    };

    const filterMapped = mappedFilterForm;
    const page = offset < pageSize ? 0 : Math.round(offset / pageSize);
    const variables = generateInput(pageSize, page, filterMapped);

    const { data } = await client.query<ChatsPageCoversationsQuery>({
      query: ChatsPageCoversationsDocument,
      variables: variables,
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    });

    const conversations = (data?.conversations?.data.conversations as gql.Conversation[]) || [];

    // TODO (AWE): Workaround because sometimes we receive empty properties
    const propertyValue = propertyDefinition.values.find((pv) => 'oid' in pv && pv.oid.oid === propertyValueOid);
    const mappedPropertyValue = propertyValue ? mapPropertyValueToApi(propertyValue) : undefined;
    const fixedConversations = conversations.map((conversation) => {
      if (conversation.properties.length === 0 && mappedPropertyValue) {
        return {
          ...conversation,
          properties: [mappedPropertyValue],
        };
      }
      return conversation;
    });
    const conversationsWithNameUpdate = user
      ? fixedConversations.map((conversation) => updateIndividualChatName(conversation, user))
      : fixedConversations;
    const domainConversations: Conversation[] = conversationsWithNameUpdate.map((conversation) =>
      mapConversationToDomain(conversation, flags.chatLinkVisibility)
    );
    const updatedConversations = domainConversations.map((conversation) => {
      return updateUserInConversation(conversation, user!);
    });
    dispatch(
      updatePagination({
        propertyValueOid: propertyValueOid,
        total: data?.conversations.data.pageInfo.totalNumberOfFilteredConversations || conversations.length,
      })
    );
    return updatedConversations;
  };

  const isLoading = () => {
    return false;
  };
  const loading = isLoading();

  useEffect(() => {
    dispatch(setLoading(loading));
  }, [loading]);

  useEffect(() => {
    if (conversationsSynced > 0) {
      loadConversationsByPropertyValue(0, undefined);
    }
  }, [conversationsSynced]);

  useEffect(() => {
    let cancelled = false;
    if (initial) {
      return;
    }

    const fetchData = async () => {
      if (!cancelled) {
        const convos = await loadConversationsByPropertyDefinition(true);
        dispatch(
          setConversations({
            conversations: convos,
          })
        );
      }
    };

    _.debounce(fetchData, 200)();

    return () => {
      cancelled = true;
    };
  }, [filterItems, propertyDefinition, selectedItem]);

  return {
    loadConversationsByPropertyDefinition,
    loadConversationsByPropertyValue,
  };
}
