import { Box, Checkbox, Typography } from '@mui/joy';

import { generateColorFromOid, getUsersInitials } from 'services/utils/helpers';
import CustomAvatar from 'components/CustomAvatar';
import CellMenuOption from 'components/ui-library/chat/CellMenu/CellMenuOption/CellMenuOption';
import ChatChip from 'components/ui-library/chat/Chip/ChatChip';
import { CheckboxSx, NameSx, OptionSx, UserBoxSx } from 'components/Chats/Filters/PropertyFilter/styles';
import { PropertyFilterItemProps } from 'components/Chats/Filters/PropertyFilter/PropertyFilterItem/interface';

const PropertyFilterItem = ({ propertyValue, onSelect, onUnselect, isChecked }: PropertyFilterItemProps) => {
  const handleChange = () => {
    const nextChecked = !isChecked;
    if (nextChecked) {
      onSelect(propertyValue);
    } else {
      onUnselect(propertyValue);
    }
  };

  if (propertyValue.type === 'OPTION') {
    const { oid, color, icon, value } = propertyValue;

    return (
      <CellMenuOption key={oid.oid} sx={OptionSx} onClick={handleChange}>
        <ChatChip chipColor={color} icon={icon}>
          {value}
        </ChatChip>
        <Checkbox sx={CheckboxSx} checked={isChecked} />
      </CellMenuOption>
    );
  }

  if (propertyValue.type === 'TENANT_USER') {
    const { oid, firstName, fullName, username, avatar } = propertyValue;
    const userInitials = getUsersInitials(username, fullName);

    return (
      <>
        {(firstName || fullName) && (
          <CellMenuOption key={oid.oid} sx={OptionSx} onClick={handleChange}>
            <Box sx={UserBoxSx}>
              <CustomAvatar
                index={generateColorFromOid(oid.oid ?? '')}
                size={16}
                userInitials={userInitials}
                imgUrl={avatar}
              />
              <Typography level="tiny" sx={NameSx}>
                {fullName ?? firstName}
              </Typography>
            </Box>
            <Checkbox sx={CheckboxSx} checked={isChecked} />
          </CellMenuOption>
        )}
      </>
    );
  }

  return null;
};

export default PropertyFilterItem;
